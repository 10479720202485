import styled from "styled-components";
import * as ColorScheme from "./ColorScheme";

export const Heading = styled.label`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 0.1px;
  color: ${ColorScheme.charcoal_gray};
`
export const BodyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0px;
  color: ${ColorScheme.medium_gray};
`

export const NoGroupsMessage = styled.p`
  display:block;
  width: 100%;
  margin-top: 3rem;
  border: 1px solid ${ColorScheme.medium_gray};
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
`

export const LandingContainer = styled.div`
  display: flex;
  justify-content: space-around;
`


export const LandingLhs = styled.div`
  width: 429px;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const LandingRhs = styled.div`
  width: 486px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 2rem;
`

export const AssumptionsLinkRow = styled.div`
  display: flex;
  align-items: flex-start;
`

export const TableDownloadLink = styled.div`
  width: 165px;
  height: 50px;
  background-color: ${props => !props.disabled ? ColorScheme.blue_green : ColorScheme.blue_grey};
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
  transition: all ease-in .2s;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled && "default"};
  :hover {
    background-color: ${props => !props.disabled && ColorScheme.dark_turquoise};
    cursor: ${props => !props.disabled ? "pointer" : "default"};
    transition: all ease-in .2s;
    pointer-events: ${props => props.disabled && "none"};
  }
`

export const DownloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
`

export const DropdownWrapper = styled.form`
  margin-top: 32px;
  width: 100%;
`

export const LoadingWrapper = styled.div`
  width: 12rem;
  height: 12rem;
  margin: auto;
`

export const PdfDownloadContainer = styled.div`
  position: relative;
`

export const PdfMessageToolTip = styled.div`
  padding: 8px;
  border: 1px solid #979a9e;
  box-shadow: 2px 2px 4px #47484a;
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 14px;
  display: inline-block;
  color: #47484a;
  position: absolute;
  bottom: 65px;
`

export const ChartWrapper = styled.div`
  height: 800px;
  width: 800px;
  position: absolute;
`