import React from "react";
export default function GraphLegend(props) {
  if (props.selectedGraph === "monthly")
    return (
      <>
        <div className="nrel-graph-legend" id="monthly">
          <div className="legend-item">
            <div className="nrel-peak-kw" id="monthly" />{" "}
            <p className="nrel-legend-text">Peak Monthly kW Demand</p>
          </div>
        </div>
        <div className="nrel-legend-footnote">
          {/* '++' Indicates peak kW demand occured multiple times during the month */}
        </div>
      </>
    );
  else
    return (
      <>
        <div className="nrel-graph-legend" id="daily">
          <div className="legend-item">
            <div className="nrel-peak-kw" id="top" />{" "}
            <p className="nrel-legend-text">Day(s) with highest kW demand</p>
          </div>
          <div className="legend-item">
            <div className="nrel-peak-kw" id="other" />{" "}
            <p className="nrel-legend-text">Daily demand for all other days</p>
          </div>
        </div>
        <div className="nrel-legend-footnote">
          {/* '++' Indicates peak kW demand occured multiple times during the month */}
        </div>
      </>
    );
}
