import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import React, { useState, useMemo } from "react";
import IdlingMap from "./idling-components/idling-map";
import IdlingDetailTable from "./idling-components/idling-detail-table";
import IdlingWeeklySummaryTable from "./idling-components/idling-weekly-summary-table";
import IdlingSummaryTable from "./idling-components/idling-summary-table";
import IdlingSummaryPanels from "./idling-components/idling-summary-panels";
import IdlingControls from "./idling-components/idling-controls";
import VehicleDetail from "./idling-components/idling-vehicle-detail";
import IncidentDetail from "./idling-components/idling-incident-detail";
import Loading from "./idling-components/idling-loading";
import Header from './idling-components/idling-header';
import { getFuelType } from "./idling-components/idling-table-helper-functions";
import "react-datepicker/dist/react-datepicker.css";
import "../component-css/idling-stylesheets/swt-idling.css";
import { processApiResponse } from "./idling-components/utils/ConformUnits";

function _formatDateParamsForAPI(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

const MIN_DATE = "2021-01-01";
const INITIAL_DAY_WINDOW = 7;
const ADDITIONAL_FIELDS_GROUP = "ADDITIONAL FIELDS";
const EVENT_LIMIT = 15000;


const stringSort = (rowA, rowB, columnId) => {
  const a = rowA.values[columnId].toLowerCase();
  const b = rowB.values[columnId].toLowerCase();
  return a > b ? 1 : -1;
}

const dateTimeSort = (rowA, rowB, columnId) => {
  const a = new Date(rowA.values[columnId])
  const b = new Date(rowB.values[columnId])
  return a > b ? 1 : -1;
}

const summaryTableColumns = [
  {Header: "Asset ID", accessor: "asset_id", sortType: stringSort},
  {Header: "Year", accessor: "year", sortType: "basic"},
  {Header: "Make", accessor: "make", sortType: "basic"},
  {Header: "Model", accessor: "model", sortType: "basic"},
  {Header: "Incidents", accessor: "incidents", sortType: "basic"},
  {Header: "Idling Duration \n(Minutes)", accessor: "total_duration", sortType: "basic"},
  {Header: "Idling Duration \n(%)", accessor: "pct_idle_duration", sortType: "basic"},
  {Header: "Fuel Type", accessor: "fuel_type", sortType: "basic"},
]

const detailTableColumns = [
  {Header: "Asset ID", accessor: "asset_id", sortType: stringSort},
  {Header: "Year", accessor: "year", sortType: "basic"},
  {Header: "Make", accessor: "make", sortType: "basic"},
  {Header: "Model", accessor: "model", sortType: "basic"},
  {Header: "Date/Time", accessor: "local_start", sortType: dateTimeSort},
  {Header: "Idling Duration \n(Minutes)", accessor: "duration_minutes", sortType: "basic"},
  {Header: "Location \n(Lat/Lon)", accessor: "event_location", sortType: "basic"},
  {Header: "Fuel Type", accessor: "fuel_type", sortType: "basic"},
]

const singleVehicleTableColumns = [
  {Header: "Date/Time", accessor: "local_start", sortType: dateTimeSort},
  {Header: "Idling Duration \n(Minutes)", accessor: "duration_minutes", sortType: "basic"},
]

const csvDetailHeaders = singleVehicleTableColumns.map(c => {return{label: c.Header, key: c.accessor}});

const minDurations = [
  {id: 0, name: "0+"},
  {id: 2, name: "2+"},
  {id: 5, name: "5+"},
  {id: 10, name: "10+"},
  {id: 20, name: "20+"},
  {id: 30, name: "30+"},
  {id: 40, name: "40+"},
  {id: 50, name: "50+"},
  {id: 60, name: "60+"},
];

export default function Idling(props) {
  const { user, apiURL, dbName, dbDisplayName } = props;
  const [minDuration, _setMinDuration] = useState(5);
  const [minDurationReduced, _setMinDurationReduced] = useState(false);
  const [summaryTableSortCol, setSummaryTableSortCol] = useState('incidents');
  const [summaryTableDesc, setSummaryTableDesc] = useState(true);
  const [detailTableSortCol, setDetailTableSortCol] = useState('local_start');
  const [detailTableDesc, setDetailTableDesc] = useState(true);
  const [weeklyTableSortCol, setWeeklyTableSortCol] = useState('total');
  const [weeklyTableDesc, setWeeklyTableDesc] = useState(true);
  const [events, _setEvents] = useState(undefined);
  const [activeHours, _setActiveHours] = useState(undefined);
  const [group, _setGroup] = useState({id:"", name:""});
  const [selectedGroups, _setSelectedGroups] = useState([]);
  const [department, _setDepartment] = useState({id:"", name:""});
  const [vehicleClasses, _setVehicleClasses] = useState([]);
  const [selectedVehicleClasses, _setSelectedVehicleClasses] = useState([]);
  const [matchingVehicles, setMatchingVehicles] = useState(undefined);
  const [groups, _setGroups] = useState([]);
  const [departments, _setDepartments] = useState([]);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [incidentParent, setIncidentParent] = useState(undefined);
  const [childVehicleCount, _setChildVehicleCount] = useState(0);
  const { geotabIntegrated, geotabGroups, geotabDepartments } = props;
  const [isLoading, _setIsLoading] = useState(false);
  const [filteredEvents, _setFilteredEvents] = useState([]);
  const req = useMemo(() => {
    return { user: user,
             dbName: dbName,
             group: group,
             groups: selectedGroups.map((g) => {return g.id}),
             apiURL: apiURL, 
             beginDate: beginDate,
             endDate: endDate,
             minDuration: minDuration,
             selectedVehicleClasses: selectedVehicleClasses,
            }
  }, [user, dbName, group, selectedGroups, apiURL, beginDate, endDate, minDuration, selectedVehicleClasses]);


  function _recursiveChildrenFetch(req, group, cb){
    const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${group.id}&depth=1`;
    fetch(url, {
      headers: { Authorization: `Bearer ${req.user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if(cb && data && data.data.length > 0){
          group.children = data.data.filter((g) => g.depth > 0);
          if(cb)cb(group);
        }
      })
  }

  useMemo(() => {
    const afGroup = groups.filter((g)=> g.name===ADDITIONAL_FIELDS_GROUP);

    if(!afGroup || afGroup.length < 1 || afGroup[0].children)return;
    const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${afGroup[0].id}&depth=1`;
    fetch(url, {
      headers: { Authorization: `Bearer ${req.user.token}` },
    })
    .then((resp) => resp.json())
    .then((data) => {
      if(data && data.data.length > 0){
        const children = data.data.filter((g) => g.depth > 0);
        children.forEach((child) => {
          _recursiveChildrenFetch(req, child, function(){});
        });
        const grps = JSON.parse(JSON.stringify(groups));
        const idx = groups.findIndex(g => g.name === ADDITIONAL_FIELDS_GROUP);
        grps[idx].children = children;
        _setGroups(grps);
      }
      else{
        _setGroups([]);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req.apiURL, req.dbName, req.user.token, groups]);

  useMemo(() => {
    if(vehicleClasses.length > 0)return;

    fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {
      headers: { Authorization: `Bearer ${req.user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if(data && data.data.length > 0){
          let arr = data.data.map((e) => {
            if(e.vehicle_class === '')e.vehicle_class = 'Unknown';
            if(e.vehicle_class === null)e.vehicle_class = 'Undefined';
            return e.vehicle_class
          });
          const idx = arr.indexOf('Unknown');
          if(idx > -1){
            const o = arr[idx];
            arr.splice(idx, 1);
            arr.push(o);
          }
          _setVehicleClasses(arr);
        }
        else{
          _setVehicleClasses([]);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req.apiURL, req.dbName, req.user.token]);

  useMemo(() => {
    // filter events against search bar, vehicle classes, minimum duration
    if(!events || events.length < 1 || !matchingVehicles){
      _setFilteredEvents([]);
      return
    }
    const vins = matchingVehicles?.values.map((v) => {return v.vin});
    // eslint-disable-next-line
    const filtered = events.filter((e) => {
      if (vins.indexOf(e.vin) > -1 && (selectedVehicleClasses.length === 0 || selectedVehicleClasses.indexOf(e.vehicle_class) > -1) && (e.duration_minutes >= minDuration))return e;
    })
    _setFilteredEvents(filtered);
  }, [events, selectedVehicleClasses, matchingVehicles, minDuration])

  useMemo(() => {

      if(req.beginDate || req.endDate)return;
      fetch(`${req.apiURL}getBounds?dbName=${req.dbName}`, {
        headers: { Authorization: `Bearer ${req.user.token}` },
      })
        .then((resp) => resp.json())
        .then((data) => {
          //return the last day of data and 30 days prior as bounds
          if(data && data.data.length > 0){
            const e = new Date(data.data[0].max);
            const b = new Date();
            if(b > e){
              //data has stopped flowing, set end to last day and begin to window before that
              setEndDate(e);
              const c = new Date(data.data[0].max)
              c.setDate(c.getDate()-INITIAL_DAY_WINDOW);
              setBeginDate(c);
            }
            else{
              b.setDate(b.getDate()-INITIAL_DAY_WINDOW);
              setBeginDate(b);
              setEndDate(e);
            }
            
          }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req.apiURL, req.dbName, req.user.token]);
  
  useMemo(() => {
      _setIsLoading(true);
      _setMinDurationReduced(false);
      if(!req.beginDate || !req.endDate)return;
      let groupQuery = `group=${group?.id}`;
      if(typeof req.groups !== 'undefined' && req.groups.length > 0){
        groupQuery = `groups=${req.groups}`;
      }
      const request = `${req.apiURL}getIdlingEvents?dbName=${req.dbName}&start=${_formatDateParamsForAPI(req.beginDate)}&stop=${_formatDateParamsForAPI(req.endDate)}&${groupQuery}&limit=${EVENT_LIMIT}&minDurationSeconds=${minDuration*60}`;
      const request2 = `${req.apiURL}getVehicleActiveHours?dbName=${req.dbName}&start=${_formatDateParamsForAPI(req.beginDate)}&stop=${_formatDateParamsForAPI(req.endDate)}&${groupQuery}`;
      Promise.all([
        fetch(request, {headers: { Authorization: `Bearer ${req.user.token}` }}),
        fetch(request2, {headers: { Authorization: `Bearer ${req.user.token}` }})
      ])
          .then(([eventsResp, hoursResp]) => 
            Promise.all([eventsResp.json(), hoursResp.json()])
            )
          .then(([eventsData, activeHours]) => {
              // add fuel type to events
              // NOTE could be done once per vehicle vs each event
              _setActiveHours(activeHours.data);
              return eventsData.data.map((d) => {
                d.asset_id = d.asset_id ? d.asset_id : d.user_defined_vin;
                d.asset_id = d.asset_id ? d.asset_id : d.vin;
                d.fuel_type = getFuelType(d);
                d.event_location = `${d.latitude}, ${d.longitude}`;
                d = processApiResponse(user.userSettings, d)
                return d;
              })
          })
          .then((events) => {
            _setEvents(events);
            _setIsLoading(false);
          })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req.apiURL, req.user.token, req.dbName, req.beginDate, req.endDate, selectedGroups, minDurationReduced]);

  const summarizedEvents = (data) => {
    if (!data || data.length === 0 || typeof data[0].vin === "undefined") return [];
    // summarize single events to be groups by vin
    let arr = [];
    let vin = data[0].vin;
    let incidents = 0;
    let totalDuration = 0;
    let activeHours = getActiveHours(vin);
    for(let i=0;i<data.length;i++){
      const v = data[i];
      if(vin === v.vin){
        incidents++;
        totalDuration += data[i].duration_minutes;
      }
      else{
        const v0 = data[i-1]
        arr.push({'vin': `${vin}`,
          'user_defined_vin': v0.user_defined_vin,
          'asset_id': v0.asset_id,
          'beginDate': beginDate,
          'endDate': endDate,
          'incidents': incidents,
          'year': v0.year,
          'make': v0.make,
          'model': v0.model,
          'vehicle_class': v0.vehicle_class,
          'fuel_type': v0.fuel_type,
          'total_duration': totalDuration,
          'pct_idle_duration': calcPctIdle(totalDuration, activeHours)
        });
        // update attrs and counts
        vin = v.vin;
        incidents = 1;
        totalDuration = data[i].duration_minutes;
        activeHours = getActiveHours(vin);
      }
      if(i === data.length -1){
        arr.push({'vin': `${vin}`,
        'user_defined_vin': v.user_defined_vin,
        'asset_id': v.asset_id,
        'incidents': incidents,
        'year': v.year,
        'make': v.make,
        'model': v.model,
        'vehicle_class': v.vehicle_class,
        'fuel_type': v.fuel_type,
        'total_duration': totalDuration,
        'pct_idle_duration': calcPctIdle(totalDuration, activeHours)
      });
      };
    }
    return arr;
  }

  const calcPctIdle = (idleMinutes, activeHours) => {
    if(activeHours) return ((idleMinutes/60)/activeHours)*100;
    else return null;
  }

  const getActiveHours = (vin) => {
    let hours = 0;
    let vcl = activeHours.filter((a) => {return (a.vin === vin)});
    if(vcl.length > 0) hours = vcl[0]['total_hours'];
    return hours
  }

  const handleDepartments = (depts) =>{
    const d = depts.slice();
    d.unshift({id: null, name: "Not Selected"});
    _setDepartments(d);
  }

  useMemo(() => {

    const handleGroups = (groups) => {
      _setGroup(groups[0]);
      _setGroups(groups);
    };

    if(!geotabIntegrated){
      _getGroups(req, handleGroups);

    } else {
      const params = {};
      params.group = {setter: function(group){_setGroup(group);}};
      params.geotabGroups = {groups: geotabGroups, setter: _setGroups};
      params.geotabDepartments = {groups: geotabDepartments, setter: handleDepartments};
      //params.additionalFields = {groups: geotabAdditionalFields, setter: _setAdditionalFields};
      _getGroups(req, handleGroups, params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req.user, req.dbName, req.apiURL]);

  const handleGroupChange = (gid) => {
    const idx = groups.findIndex(g => g.id === gid);
    const group = groups[idx];
    _getGroupChildrenVehicleCount(req, group, function(count){_setChildVehicleCount(count)});
    _setGroup(group);
    _setDepartment({id:null, name:"Not Selected"});
    _setSelectedGroups([]);
  }

  const handleSelectedGroupsChange = (gidObj) => {
    // this is for the additional fields modal
    const sg = gidObj.map((g) => {return g.id});
    let group = {name: "Multiple Groups", id: sg.toString()};
    if(sg.length === 1){
      const n = groups.filter((g) => g.id === sg[0]);
      group = n[0]; 
    }
    if(sg.length < 1)group = groups[0];
    _setGroup(group);
    _setSelectedGroups(gidObj);
  }

  const handleDepartmentChange = (gid) =>{
    let idx = groups.findIndex(g => g.id === gid);
    if(idx < 0)idx = 0;
    const group = groups[idx];
    _setGroup(group);
    _setDepartment(group);
    _setSelectedGroups([]);
  }

  const handleSummaryTableSort = (col, desc) => {
    const a = summaryTableColumns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setSummaryTableSortCol(a[0].accessor);
    setSummaryTableDesc(d);
  }

  const handleDetailTableSort = (col, desc) => {
    const a = detailTableColumns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setDetailTableSortCol(a[0].accessor);
    setDetailTableDesc(d);
  }

  const handleWeeklyTableSort = (columns, col, desc) => {
    const a = columns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setWeeklyTableSortCol(a[0].accessor);
    setWeeklyTableDesc(d);
  }

  const handleMinDurationChange = (md) => {
    if(parseInt(md) < parseInt(minDuration))_setMinDurationReduced(true);
    _setMinDuration(md);
  };

  const IdlingLink  = ({id, label}) =>{
    return <NavLink to={`/${id}`} id={id}>{label}</NavLink>;
  };

  let basename = "";
  let appWrapper = "idling-wrapper";
  if (geotabIntegrated)appWrapper = "idling-geotab-wrapper";


  let dateStr = "";
  if(beginDate && endDate)dateStr = `${beginDate.getMonth()+1}/${beginDate.getDate()}/${beginDate.getFullYear()} - ${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}`;
    
  const csvSummaryFilename = `sawatch-labs-idling-incidents-summary-${dbDisplayName.replaceAll(" ", "-")}-${(new Date().toLocaleDateString().replaceAll('/', '-'))}`;
  const csvDetailTableFilename = `sawatch-labs-idling-incidents-detail-${dbDisplayName.replaceAll(" ", "-")}-${(new Date().toLocaleDateString().replaceAll('/', '-'))}`;
  
  if(!beginDate || !endDate || !events || groups.length < 1 || !group || (departments.length < 1 && geotabIntegrated))return(<Loading/>);
  if(childVehicleCount){};//silencing the warning of an unused value, which we may use in the future
  return (
    <div className={`${appWrapper} ${isLoading?" loading":""}`} id="idling-root">
        <Route
          path = "/idling"
          render={(props) => (
            <Redirect to={{ pathname: `/idling/summarytable` }} />
          )}
        />
        <Switch>
          <Route
            path="/idling/incidentdetail/:vin/:ts"
            render={(props) => (
              <IncidentDetail 
                parent={incidentParent}
                events={filteredEvents}
                group={group}     
                dateStr={dateStr}
                req={req}   
                dbDisplayName={dbDisplayName}   
              />
            )}
          />
          <Route
            path="/idling/vehicledetail/:vin"
            render={(props) => (
            <VehicleDetail
              basename={basename}
              events={filteredEvents}
              group={group}
              columns={singleVehicleTableColumns}
              beginDate={beginDate}
              endDate={endDate}
              setBeginDate={function(data){setBeginDate(data);}} 
              setEndDate={function(data){setEndDate(data);}}
              minDurations={minDurations}
              minDuration={minDuration}
              vehicleClasses={selectedVehicleClasses}
              handleMinDurationChange={handleMinDurationChange}
              sortCol={detailTableSortCol}
              desc={detailTableDesc}
              handleSort={handleDetailTableSort}
              csvDetailHeaders={singleVehicleTableColumns}
              dateStr={dateStr}
              setIncidentParent={setIncidentParent}
              dbName={dbName}
              dbDisplayName={dbDisplayName}
            />)}
          />
          <Route
            path="/idling"
            render={() => (
              <div>
                <Header subHeader={group.name !== null ? group.name : group.id} />
                {EVENT_LIMIT <= filteredEvents.length && <p>{`THE SELECTED FILTERS HAVE MORE THAN ${EVENT_LIMIT} EVENTS AND CANNOT BE FULLY DISPLAYED`}</p>}
                {EVENT_LIMIT <= filteredEvents.length && <p>{`NARROW YOUR SELECTED DATES, INCREASE THE DURATION OR SELECT A SMALLER GROUP`}</p>}
                <div className="idling-top-panels">
                  <IdlingSummaryPanels
                    events={filteredEvents}
                  />
                  <IdlingControls
                    additionalFieldsGroup={ADDITIONAL_FIELDS_GROUP}
                    minDurations={minDurations}
                    minDuration={minDuration}
                    groups={groups}
                    selectedGroups={selectedGroups}
                    departments={departments}
                    fuelGroups={null}
                    group={group}
                    department={department}
                    events={events}
                    vehicleClasses={vehicleClasses}
                    selectedVehicleClasses={selectedVehicleClasses}
                    updateSelectedClasses={function(data){_setSelectedVehicleClasses(data);}}
                    beginDate={beginDate}
                    endDate={endDate}
                    handleMinDurationChange={handleMinDurationChange}
                    handleGroupChange={handleGroupChange}
                    handleSelectedGroupsChange={handleSelectedGroupsChange}
                    handleDepartmentChange={handleDepartmentChange}
                    minDate={new Date(Date.parse(MIN_DATE))}
                    setBeginDate={function(data){setBeginDate(data);}} 
                    setEndDate={function(data){setEndDate(data);}}
                    geotabIntegrated={geotabIntegrated}
                    setMatchingVehicles={setMatchingVehicles}
                  />
                </div>
                <nav className="idling-button-wrapper">
                  <IdlingLink className="idling-tab-button" id={'idling/summarytable'} label={'Summary Table'}/>
                  <IdlingLink id={'idling/detailtable'} label={'Detail Table'}/>
                  <IdlingLink id = {'idling/weeklysummary'} label={'Summary By Week'}/>
                  <IdlingLink id = {'idling/map'} label={'Map'}/>

                  <div className='idling-nav-empty-tab'> </div>
                </nav>
                <div className="idling-tab-wrapper">
                  <Switch>
                    <Route path="/idling/summarytable" render={(props) => (
                    <IdlingSummaryTable 
                        columns={summaryTableColumns} 
                        data={summarizedEvents(filteredEvents)}
                        filter={matchingVehicles ? matchingVehicles?.searchTerm : ""}
                        sortCol={summaryTableSortCol}
                        desc={summaryTableDesc}
                        handleSort={handleSummaryTableSort}
                        beginDate={beginDate}
                        endDate={endDate}
                        group={group}
                        vehicleClasses={selectedVehicleClasses}
                        minDuration={minDuration}
                        tableType={'Summary'}
                        csvFilename={csvSummaryFilename}
                        dbDisplayName={dbDisplayName}
                    />
                    )}/>  
                    
                    <Route path="/idling/detailtable" render={(props) => (<IdlingDetailTable
                        parent={'detailtable'}
                        setIncidentParent={setIncidentParent}
                        columns={detailTableColumns} 
                        data={filteredEvents}
                        filter={matchingVehicles ? matchingVehicles?.searchTerm : ""}
                        sortCol={detailTableSortCol}
                        desc={detailTableDesc}
                        handleSort={handleDetailTableSort}
                        beginDate={beginDate}
                        endDate={endDate}
                        group={group}
                        vehicleClasses={selectedVehicleClasses}
                        minDuration={minDuration}
                        tableType={'Detail'}
                        dbName={dbName}
                        dbDisplayName={dbDisplayName}
                        csvFilename={csvDetailTableFilename}
                    /> )}/>
                    <Route path="/idling/weeklysummary" 
                      render={((props) => (<IdlingWeeklySummaryTable
                        events={filteredEvents}
                        filter={matchingVehicles ? matchingVehicles?.searchTerm : ""}
                        group={group}
                        columns={detailTableColumns}
                        beginDate={beginDate}
                        endDate={endDate}
                        setBeginDate={function(data){setBeginDate(data);}} 
                        setEndDate={function(data){setEndDate(data);}}
                        minDurations={minDurations}
                        minDuration={minDuration}
                        vehicleClasses={selectedVehicleClasses}
                        handleMinDurationChange={handleMinDurationChange}
                        sortCol={weeklyTableSortCol}
                        desc={weeklyTableDesc}
                        handleSort={handleWeeklyTableSort}
                        csvDetailHeaders={csvDetailHeaders}
                        dateStr={dateStr}
                        dbName={dbName}
                        dbDisplayName={dbDisplayName}
                      />))} />
                    <Route path="/idling/map" render={(props) => (<IdlingMap
                     events={filteredEvents} 
                     filter={matchingVehicles ? matchingVehicles?.searchTerm : ""}
                     isSummary={true}
                     beginDate={beginDate}
                     endDate={endDate}
                     group={group}
                     vehicleClasses={selectedVehicleClasses}
                     dbName={dbName}
                     dbDisplayName={dbDisplayName}
                     minDuration={minDuration} /> )}/>
                    
                  </Switch>
                </div>
              </div>
            )}
          />
        </Switch>
    </div>
  );
}

// ** PRIVATE FUNCTIONS **

function _filterAgainstGeotabRes(data, geotabObj){
  if(!geotabObj || !geotabObj.groups || geotabObj.groups.length < 1)return [];
  const allowedGeotab = geotabObj.groups.map((g) => {return g.id});
  allowedGeotab.push("swt-vehicles");
  const allowedGroups = [];
  data.forEach((g) => {
    if(allowedGeotab.indexOf(g.id) > -1){
      const f = geotabObj.groups[allowedGeotab.indexOf(g.id)];
      if(f)g.children = f.children;
      else g.children = [];
      allowedGroups.push(g);
    }
  });
  //if(allowedGroups.length < 1)allowedGroups.push({"id": "0", "name": "Not Available", "children": []});
  return allowedGroups;
}

function _getGroupChildrenVehicleCount(req, group, cb){
  const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${group.id}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const parentVINs = group.vehicles.map((v) => {return v.vin});
        const childVINs = []
        data.data.forEach((g)=>{
          g.vehicles.forEach((v) =>{
            if(parentVINs.indexOf(v) < 0)childVINs.push(v);
          })
        });
        cb(childVINs.length);
      }
    });
}

function _getGroups(req, cb, params){
  const url = `${req.apiURL}getGroups?dbName=${req.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const idx = data.data.findIndex(g => g.id === "swt-vehicles");
        if(idx > -1) {
          const obj = data.data[idx];
          data.data.splice(idx, 1);
          data.data.unshift(obj);
        };
        if(params){
          const g0 = _filterAgainstGeotabRes(data.data, params.geotabGroups);
          const g1 = _filterAgainstGeotabRes(data.data, params.geotabDepartments);
          params.geotabGroups.setter(g0);
          params.group.setter(g0[0]);
          params.departments.setter(g0);
          params.geotabDepartments.setter(g1);
        }
        else{
          cb(data.data);
        }
      }
      else{
        if(cb)cb([]);
      }
    });
}


