import React from 'react'
import { StatView } from '@sawatchlabs/swt-components'
import { FormatSummaryData } from './UtilityFunctions';

export default function AltFuelReductions({ emissionsData, displayInLbs }) {
    const units = displayInLbs ? "Lbs" : "Tons";
    let ghgSaved = displayInLbs ? emissionsData.ghgLbsSaved : emissionsData.ghgTonsSaved;
    let CO2Saved = displayInLbs ? emissionsData.CO2LbsSaved : emissionsData.CO2TonsSaved;

    return (
        <div className="swt-emit-landing-boxes-wrapper">
            <StatView values={{value: FormatSummaryData('ghgSaved', ghgSaved), caption:`GHG ${units} Saved`, subCaption: "(Includes GHG from kWh gen.)"}} />
            <StatView values={{value: FormatSummaryData('co2Saved', CO2Saved), caption:`Carbon Dioxide ${units} Saved`, subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('coLbsSaved', emissionsData.coLbsSaved), caption:"Carbon Monoxide Lbs Saved", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('noxLbsSaved', emissionsData.noxLbsSaved), caption:"Nitrous Oxides Lbs Saved", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('pm10GramsSaved', emissionsData.pm10GramsSaved), caption:"Particulate Matter 10\u00B5m in Grams Saved", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('pm25GramsSaved', emissionsData.pm25GramsSaved), caption:"Particulate Matter 2.5\u00B5m in Grams Saved", subCaption: "(Tailpipe)"}}/>
        </div>
    )
}
