import React from 'react';

export default function PermissionError() {
  return (
    <div data-testid="permissionError-testid">
      <h2 className="swt-ionev-error-header">Permission Error</h2>
      <p className="swt-ionev-error-text">Only users with admin permissions can view this page.</p>
      <p className="swt-ionev-error-text">Please contact us for more information.</p>
    </div>
  );
}
