import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./tab";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }
  onClickTabItem = (tab) => {
    if (this.props.deactive !== true) {
      this.props.activeTab
        ? this.props.setActiveTab(tab)
        : this.setState({ activeTab: tab });
    }
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
    } = this;
    let activeTab = this.state.activeTab;
    if (this.props.activeTab) activeTab = this.props.activeTab;
    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                deactive={this.props.deactive}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
