import styled from "styled-components";
import * as ColorScheme from './ColorScheme';

export const PageWrapper = styled.div`
  text-align: center;
  padding-top: 64px;
  /* @TODO: This is a less than ideal workaround so that we don't need to adjust all of the products' CSS to the new menu bar. 
  The following line should be deleted, and the subsequent line uncommented, once all products' CSS is overhauled. -NK 3/29/24 */
  margin-left: 64px; 
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: ${ColorScheme.dark_gray};
  padding-bottom: 90px; // ensure scrolling content is not hidden by the footer if the window is too short
`

export const InfoBubble = styled.div`
  box-sizing: border-box;
  padding: 5px;
  width: 325px;
  height: 100px;
  border: 2px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export const InfoBubbleTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
`

export const InfoBubbleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  font-size: 18px;
  user-select: none;
  cursor: default;
`

export const ProductsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ProductsTitle = styled.div`
  text-align: center;
  font-size: ${props => props.fontSize};
  font-weight: bold;
  cursor: default;
`

export const LandingIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: flex-start;
  width: 665px;
  gap: ${props => props.gap};
  flex-wrap: wrap;
`

export const LandingIconRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${ColorScheme.sawatch_blue};
    .landing-icon {
      width: ${props=> props.wrapperLength};
      height: ${props=> props.wrapperLength}
    }
  }
`

export const LandingIconHoverContainer = styled.div`
  width: ${props => props.wrapperLength};
  height: ${props => props.wrapperLength};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const LandingIcon = styled.img`
  width: ${props => props.iconLength};
  height: ${props => props.iconLength};
  box-shadow: 0px 0px ${props => props.shadowSize} rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.borderRadius};
`

export const LandingIconText = styled.div`
  text-align: center;
  font-size: ${props => props.fontSize};
  max-width: ${props => props.width};
`

export const Bold = styled.span`
  font-weight: bold;
`

export const LandingFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${ColorScheme.white};
  text-align: center;
  font-size: 15px;
  line-height: 1.2rem;
`

export const Link = styled.a`
  text-decoration: none;
  color: ${ColorScheme.dark_aqua};
  &:hover {
      text-decoration: underline;
  }
`

export const UnderlineLink = styled(Link)`
  text-decoration: underline;
`

export const TriangleWrapper = styled.div`
  width: 20px;
  height: 10px;
`

export const TcoValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`