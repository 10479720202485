import React, {useRef, useEffect} from "react";
import Chart from "react-chartjs-2";
import Loading from "../dashboard-components/swt-loading";
//import { useHistory } from "react-router-dom"; 
import * as S from '../../../styles/core-styles/DevTools-styles';


const SOC_COLOR = "rgba(255, 99, 97, 1)";
const TRIP_COLOR = "rgba(0, 0, 0, 1)";
const TRIP_HANDLE_COLOR = "rgba(90, 90, 90, 0.6)";
const CHARGE_COLOR = "rgba(255, 166, 0, 1)";
const CHARGE_HANDLE_COLOR = "rgba(250, 160, 0, 0.6)";
const MODELED_CHARGE_COLOR = "rgba(0, 180, 255, 1)";
const MODELED_HANDLE_COLOR = "rgba(0, 180, 255, 0.6)"
//const SOC_READ_COLOR = "rgba(45, 45, 45, 0.7)";
//const KWH_READ_COLOR = "rgba(0, 128, 0, 0.8)";

//const CHARGE_LINE_OPACITY = 0.5;
//const CHARGE_LINE_STROKE_COLOR = "transparent";
//const CHARGE_LINE_ZINDEX = 9;

//const SOC_STROKE_WIDTH = 4;
//const SOC_STROKE_OPACITY = 0.75;
//const SOC_ZINDEX = 8;
//const SOC_CURVE_ALPHA = 0.2;

//const SOC_READ_FILL = "transparent";
//const SOC_READ_ZINDEX = 7;
//const SOC_READ_POINT_SIZE = 1;

//const KWH_READ_LINE_OPACITY = 1;
//const KWH_READ_ZINDEX = 7;

//const TRIP_LINE_OPACITY = 0.3;
//const TRIP_LINE_ZINDEX = 10;
const TRIP_SELECTED_MARKER_RADIUS = 7;
const TRIP_SELECTED_MARKER_COLOR = 'rgba(252, 115, 3, 0.8)';
const TRIP_SELECTED_MARKER_LINE = 2;

export default function ChargeGraph(props) {
  //const history = useHistory();
  //const handleOnClick = useCallback((e) => history.push(`/dev-tools/trip-map/${e}`), [history]);

  const setSelectedTrips = props.setSelectedTrips;
  const selectedTrips = useRef([]);

  //rather than use state and props to handle the selectedTrip list, we're using a useRef. 
  //this is because ChartJS is using a canvas DOM element and when the plugins get passed to it,
  //they become attached to the actual DOM, and we can't directly update the selectedTrip list there. 
  useEffect(() => {
    selectedTrips.current = props.selectedTrips;
  }, [props]);

  const timeseriesTicks = (timeseries) => {
    //chartJS wants a tick for every data point it shows, so we're making a lot of ticks.
    const bounds = {start: props.beginDate.getTime()/1000/60, stop: props.endDate.getTime()/1000/60};
    const ticks = [];
    const st = new Set();
    st.add(bounds.start);
    st.add(bounds.stop);
    let i = bounds.start+15;
    //increment selected date range by every fifteen minutes.
    while(i<=bounds.stop){
      st.add(i);
      i+=15;
    }
    timeseries.forEach((ts)=>{
      ts.forEach((d)=>{
        //time series data comes in converted to minutes past epoch
        if(d.hasOwnProperty('x'))st.add(d.x);
        if(d.hasOwnProperty('x0'))st.add(d.x0);
        if(d.hasOwnProperty('x1'))st.add(d.x1);
      });
    });
    let arr = [...st];
    arr = arr.sort(function(a,b){
      return a < b ? -1 : a > b ? 1 : 0;
    });
    arr.forEach((d)=>{
      ticks.push(d);
    })
    return ticks;
  }

  const formatData = () => {
    const socData = [];
    const tripData = [];
    const tripHandles = [];
    const chargeEventData = [];
    const chargeEventHandles = [];
    const modeledChargeEventData = [];
    const modeledChargeEventHandles = [];

    if(props.trips)props.trips.forEach((t) => {
      let i = parseInt(t.x0.getTime()/1000/60);
      tripHandles.push({x: i+1, y: 0});//set one minute after trip start
      while(i <= t.x1.getTime()/1000/60){
        tripData.push({x: i, y:100});
        i+=1;
      }
    });
    if(props.socArray)props.socArray.forEach((d) => {
      socData.push({x: parseInt(d.x.getTime()/1000/60), y: d.y})
    });
    if(props.chargeArray)props.chargeArray.forEach((c)=>{
      const modeled = c.modeled ? true : false;
      let i = parseInt(c.x0.getTime()/1000/60);
      if(!modeled)chargeEventHandles.push({x: i+1, y: 100});//set one minute after trip start
      else modeledChargeEventHandles.push({x: i+1, y: 100});//set one minute after trip start
      while(i <= c.x1.getTime()/1000/60){
        if(!modeled)chargeEventData.push({x: i, y:100, modeled: modeled, kwh: c.kwh});
        else modeledChargeEventData.push({x: i, y:100, modeled: modeled, kwh: c.kwh});
        i+=1;
      }
    });
    const labels = timeseriesTicks([tripData, socData, chargeEventData, modeledChargeEventData]);
    return {
              labels: labels,
              datasets: [
                {
                  label: 'Trips',
                  type: 'bar',
                  data: tripData,
                  backgroundColor: TRIP_COLOR,
                  hoverBorderRadius: 5
                },
                {
                  label: 'Trip Handles',
                  type: 'line',
                  data: tripHandles,
                  backgroundColor: TRIP_HANDLE_COLOR,
                  rotation: 1
                },
                {
                  label: 'SoC',
                  type: 'line',
                  data: socData,
                  fill: false,
                  borderColor: SOC_COLOR,
                  backgroundColor: SOC_COLOR,
                  radius: 1,
                  borderWidth: 1
                },
                {
                  label: 'Charge Event',
                  type: 'bar',
                  data: chargeEventData,
                  backgroundColor: CHARGE_COLOR,
                  hoverBorderRadius: 5,
                },
                {
                  label: 'Charge Event Handles',
                  type: 'line',
                  data: chargeEventHandles,
                  backgroundColor: CHARGE_HANDLE_COLOR,
                  borderWidth: 0,
                  rotation: 2
                },
                {
                  label: 'Modeled Charge Event',
                  type: 'bar',
                  data: modeledChargeEventData,
                  backgroundColor: MODELED_CHARGE_COLOR,
                  hoverBorderRadius: 5
                },
                {
                  label: 'Modeled Event Handles',
                  type: 'line',
                  data: modeledChargeEventHandles,
                  backgroundColor: MODELED_HANDLE_COLOR,
                  borderWidth: 0,
                  rotation: 3
                },
              ]
    };
  }

  const tripSelectedCircle = (ctx, x, y) => {
    ctx.beginPath();
    ctx.arc(x, y, TRIP_SELECTED_MARKER_RADIUS, 0, 2 * Math.PI, false);
    ctx.fillStyle = TRIP_SELECTED_MARKER_COLOR;
    ctx.fill();
    ctx.lineWidth = TRIP_SELECTED_MARKER_LINE;
    ctx.strokeStyle = TRIP_SELECTED_MARKER_COLOR;
    ctx.stroke();
  }

  //unused but keeping stubbed in for later
  const plugins = (data) => {
    return [
      eventHandler(),
      tripsPlugin()
    ]
  }

  const eventHandler = () => {
    return {
      id: 'eventHandler',
      beforeEvent: (chart, args, pluginOptions) => {
        const event = args.event;
        if (event.type === 'click') {
          const el = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
          if(!el || el.length < 1 || el[0].element.options.rotation !== 1)return;
          const idx = el[0].index;
          const selectedIndex = selectedTrips.current.indexOf(idx);
          if(selectedIndex < 0){
            selectedTrips.current.push(idx);
            const arr = selectedTrips.current.map((t) => {return t})//another dumb copy
            setSelectedTrips(arr);
          }
          else{
            const arr = selectedTrips.current.filter((i) => i !== idx);
            setSelectedTrips(arr);
          }
        }
      }
    }
  };

  const tripsPlugin = () => {
    return {
      id: 'tripsBars',
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        let meta = chart.getDatasetMeta(1);//1 is the trip handles
        if(meta.hidden || meta.data.length < 1)return;
        meta.data.forEach((t, idx)=>{
          const selectedIndex = selectedTrips.current.indexOf(idx);
          if(selectedIndex > -1)tripSelectedCircle(ctx, t.x, t.y);
        });
      },
      afterDatasetsUpdate: (chart) => {
        //do stuff?
      }
    }
  };

  const chargeEventLabelText = (chart) => {
    const ts = parseInt(chart.label)*1000*60;
    const d = new Date(ts);
    const ce = props.chargeArray.find((ce) => ce.x0 <= d && ce.x1 >= d);
    if(!ce)return ["No charge event found"];
    const res = [];
    res.push(`${ce.modeled ? 'Modeled ' : ''}Charge Event`);
    res.push(`Mins: ${parseInt(ce.duration/60)}`);
    res.push(`kWh: ${ce.kwh.toFixed(1)}`);
    res.push(`Charger Level: ${ce.chargerLevel}`);
    res.push(`KW Draw: ${(ce.kwh/(ce.duration/3600)).toFixed(1)}`);
    res.push(`Regen Reason: ${(ce.regenReason === null || ce.regenReason === '') ? '-' : ce.regenReason}`);
    return res;
  }

  const tripLabelText = (chart) => {
    const ts = parseInt(chart.label)*1000*60;
    const d = new Date(ts);
    const trip = props.trips.find((t)=>t.x0 <= d && t.x1 >= d);
    if(!trip)return ["No trip found"];
    const res = [];
    res.push(`Trip`);
    res.push(`Mins: ${trip.duration ? parseInt(trip.duration) : "-"}`);
    res.push(`KM: ${trip.km ? trip.km.toFixed(1) : "-"}`);
    res.push(`KM/kWh: ${trip.km && trip.blendedKwh ? (trip.km/trip.blendedKwh).toFixed(1) : "-"}`);
    res.push(`Blended kWh: ${trip.blendedKwh ? trip.blendedKwh.toFixed(1) : "-"}`);
    res.push(`Snr kWh: ${trip.snrKwh && typeof trip.snrKwh === "number" ? trip.snrKwh.toFixed(1) : "-"}`);
    res.push(`Syn kWh: ${trip.snrKwh ? trip.synKwh.toFixed(1) : "-"}`);
    return res;
  }

  const title = (charts) => {
    if(charts && charts.length > 0){
      const ts = parseInt(charts[0].label)*1000*60;
      const d = new Date(ts).toUTCString();
      return d;
    }
  }

  const label = (chart) => {
    if(chart){
      if(chart.dataset.label === 'SoC')return `${chart.dataset.label}: ${chart.formattedValue}`;
      const k = chart.dataset.data[chart.dataIndex]
      if(k.kwh){
        return(`${chart.dataset.label} - kWh: ${k.kwh}`);
      }
      let res = [""];
      if(chart.dataset.label === 'Trip Handles')res = tripLabelText(chart);
      if(chart.dataset.label === 'Charge Event Handles')res = chargeEventLabelText(chart);
      if(chart.dataset.label === 'Modeled Event Handles')res = chargeEventLabelText(chart);

      return res;
    }
    return "";
  }

  const options = () => {
    const opts = {
      maintainAspectRatio: false,
      scales: {
        y:{
          display: true,
        },
        x:{
          display: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            // convert epoch minutes to human dates
            callback: function(value, idx) {
                const l = this.getLabelForValue(value);
                const d = new Date(l*60*1000);
                return `${d.getMonth()+1}-${d.getDate()} ${d.getUTCHours()}:${d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes()}`;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          callbacks: {
            title: title,
            label: label,
            footer: ()=>{return ""}
          }
        }
      },
    }
    return opts;
  }

  if(!props.trips)return(<Loading/>)
  const chartData = formatData();
  return (
    <S.DevToolsChartContainer>
      <Chart
        data={chartData}
        options={options()}
        plugins={plugins(chartData.datasets)}
      />
    </S.DevToolsChartContainer>
  )
}
