import React, { useState, useMemo, useEffect, useCallback } from "react";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import { formatDateTimeUTC } from "../UtilityFunctions";

const LOW_SPACE_THRESHOLD = 15; //space left on db storage in pct
const CONNECTIONS_SWAMPED_THRESHOLD = 0.35; // workers over max_connects ratio below which is a problem
const PROBLEM_COLOR = "#e76f51";
const NOMINAL_COLOR = "#2a9d8f";

export default function ControllerStatusTable(props){

    const { apiURL, user } = props;
    const [controllers, setControllers] = useState(null);
    const [now, setNow] = useState(new Date());

    const boolSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.bool;
        const b = rowB.values[columnId].props.bool;
        return a > b ? 1 : -1;
    },[]);

    const statusSort = useMemo(() => (rowA, rowB, columnId) =>{
        const a = rowA.values[columnId].props.status;
        const b = rowB.values[columnId].props.status;
        return a > b ? 1 : -1;
    }, []);

    const ControllerStatus = (workers, max_connections, space) => {
        const low_space = space < LOW_SPACE_THRESHOLD ? true : false;
        const connects_swamped = workers/max_connections > CONNECTIONS_SWAMPED_THRESHOLD ? true : false;
        if(low_space || connects_swamped)return(<S.CDRStatusMarker status={0}  backgroundColor={PROBLEM_COLOR} />);
        //if(some medium prob level)return(<S.CDRStatusMarker status={1} backgroundColor="#e9c46a" />);
        return(<S.CDRStatusMarker status={2} backgroundColor = {NOMINAL_COLOR} />);
    }

    const columns = [
        {Header: "Status", accessor: "status", sortType: statusSort, width: 100},
        {Header: "Name", accessor: "name", sortType:"basic", width: 120},
        {Header: "IP Address", accessor: "address", sortType:"basic", width: 200},
        {Header: "Space", accessor: "space", sortType:"basic", width: 150},
        {Header: "Workers", accessor: "workers", sortType:"basic", width: 150},
        {Header: "Max Connections", accessor: "max_connections", sortType:"basic", width: 150},
        {Header: "Monitor", accessor: "monitor", sortType:boolSort, width: 120},
        {Header: "Accept New", accessor: "retired", sortType:boolSort, width: 130},
        {Header: "Updated", accessor: "updated"},
    ];

    const BoolTableCell = useCallback((props) =>
    <input
        className="swt-admin-table-input"
        type="checkbox"
        id={props.id} 
        name={props.name}
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        onChange={props.handleOnClick}
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
        disabled={typeof props.bool === "undefined" ? true : false}
    />,[]);

    useEffect(() => {
        const url = `${apiURL}getControllerStatus`;
        fetch(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
            .then((resp) => {
                return resp.json();
            })
            .then((data) => {
                if (data.status === "error")
                    alert("There was a server error during your request.");
                else
                    setControllers(data.data);
            })
            .catch((error) => console.error("Error: " + error));
    }, [apiURL, user.token, now]);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
          }, 10000);
          return () => clearInterval(interval);
    }, []);
    
    const mappedControllers = useMemo(() => {
        if(!controllers)return null;
        if(controllers.length < 1)return [];

        const conts = JSON.parse(JSON.stringify(controllers)); 

        return conts.filter((c) => {
            c.updated = formatDateTimeUTC(c.updated);
            c.status = ControllerStatus(c.workers, c.max_connections, c.space);
            c.retired = <BoolTableCell id={c.name} accessor={"retired"} bool={c.retired ? false : true} handleOnClick={function(el){}}/>;
            c.monitor = <BoolTableCell id={c.name} accessor={"monitor"} bool={c.monitor} handleOnClick={function(el){}}/>;
            return c;
        })
    }, [controllers]);

    return(
        <div>
            {!mappedControllers && <Loading />}
            {mappedControllers && 
            <AdminTableView 
                columns={columns}
                data={mappedControllers}
                sortBy={{id: "status", desc: false}}
                stickyCols={2}
            />
            }

        </div>
    )
}