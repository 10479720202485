import React, { ReactNode, FunctionComponent } from "react"
import * as CS from "../../styles/ezio-styles/Controls-styles"

export const formatOptionLabel = (option: any, { context, selectValue }: any) => {
    const isSelected = selectValue.map((item: any) => item.value).includes(option.value);
    if (context === "menu") {

        return (
            <CS.OptionWrapper className={isSelected ? "selectedOption" : ""}>
                <CS.BulletPoint>{isSelected ? "• " : ""}</CS.BulletPoint>{option.label}
            </CS.OptionWrapper>)
    }
    else {
        return (<div>{`${option.label}`}</div>)
    }

}

export function scrollToSelected() {
    setTimeout(() => {
        const selectedEl = document.getElementsByClassName("selectedOption")[0];
        if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, 15);
};



export const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
        }}
        {...props}
    />
);

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: 'absolute',
                zIndex: 2,
            }}
            {...props}
        />
    );
};

interface DropdownProps {
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
    readonly dropdownId: string;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
    children,
    isOpen,
    target,
    onClose,
    dropdownId
}) => (
    <div id={dropdownId} style={{ position: 'relative', width: "100%" }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);




