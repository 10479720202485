import React, { useState } from "react";
import ServerDemandGraph from "./ServerDemandGraphs";

export default function DashboardAnalytics(props) {
  const [demandDropDowns, setDemandDropDowns] = useState({});
  return (
      <ServerDemandGraph
        user={props.user}
        secrets={props.secrets}
        apiUrl={props.apiUrl}
        dropDowns={demandDropDowns}
        setDropDowns={(v) => setDemandDropDowns(v)}
      />
  );
}
