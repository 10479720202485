import React from 'react';
import ReactDOM from 'react-dom';
import {  BrowserRouter as Router, } from "react-router-dom";
import './component-css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'


const secrets = require("./settings");
const version = secrets.dashboardVersion.split(".");
const basename = `/v${version[0]}`;

// Enable posthog if we want to capture web traffic for this env
if(secrets.trafficAnalysisEnabled) posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, { api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST});

ReactDOM.render(<Router basename={basename}>
                    {secrets.trafficAnalysisEnabled === true ? // Posthog provider tag can be used to fine tune in the future, e.g. tracking user info
                        <PostHogProvider client={posthog}> 
                            <App version={version} basename={basename} posthog={posthog}/>
                        </PostHogProvider>
                    :
                        <App version={version} basename={basename}/>
                    }
                </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
