import React, {useState} from 'react';
import styled from 'styled-components';

const TooltipIcon = styled.img`
  height: 12px;
  width: 12px;
  padding-left: 2px;
  display: inline-block;
`

const TooltipContainer = styled.div`
  padding: 8px;
  margin-left: 10px;
  border: 1px solid #979a9e;
  box-shadow: 2px 2px 4px #47484a;
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 13px;
  display: inline-block;
  color: #47484a;
  position: absolute;
`
const TooltipText = styled.p`
  margin: 0;
`


export default function InfoTooltip({textType}) {
    const [tooltipShown, setTooltipShown] = useState(false)
    let tooltipText;
    

    //Different text variables that will be inserted into the popup
    const detailTableText = 'The table below provides the fuel type, vehicle miles travelled, and emissions for each of the vehicles. The emission ratio is the total emissions per mile for each vehicle. You can change the reporting period by adjusting the date range in the top right.';
    const ghgEmissionReductionsDetailsText = 'The table below provides the fuel type, vehicle miles travelled, and emission reductions for each alternative fuel vehicle. You can change the reporting period by adjusting the date range in the top right.';

    if(textType === 'detailTable') tooltipText = detailTableText;
    if(textType === 'ghgEmissionReductionDetails') tooltipText = ghgEmissionReductionsDetailsText;
    

    return (
        <span>
        <TooltipIcon src="https://images.sawatchlabs.com/info-icon.png" alt="Infomation Icon" onMouseOver={() => setTooltipShown(true)} onMouseOut={() => setTooltipShown(false)} />
        {tooltipShown &&
            <TooltipContainer>
              <TooltipText>
                {tooltipText}
              </TooltipText>
            </TooltipContainer>}
        </span>
    )
}