import React, { useState } from "react";
import { preventNonIntegers, preventPasteNonIntegers, formatDecimal } from "../UtilityFunctions";
import * as S from "../../../../styles/core-styles/AdminTools-styles";

const ALPHANUMERIC_REGEX = "^[\\w\\-\\(\\) ]*$" //Prevents all special characters except for " - _ ( ) "
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters '_' , '-' , '()' are also allowed.";

export default function UpfitsCreationModal(props) {
  const { setShowModal, addUpfit, userSettings, dbSettings } = props;
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [weight, setWeight] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    const prefix = Math.random().toString(36).slice(8);
    let upft = {
      id: `${prefix}_${name.replaceAll(" ", "_")}`,
      name: name,
      price: price,
      weight: weight,
    };
    
    // Get's passed to vcl check to make sure all necessary fields are complete
    if (upft !== false) {
      addUpfit(upft)
      setShowModal(false);
    }
  }

  return (
    <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={() => setShowModal(false)}>Close</S.ModalCloseButton>
        <S.ModalHeader>Add Upfit</S.ModalHeader>
        <S.ModalForm onSubmit={handleSubmit}>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Name:</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              type='text'
              pattern={ALPHANUMERIC_REGEX}
              title={INPUT_ERROR_MESSAGE}
              onChange={(e) => setName(e.target.value)}
              value={name ? name : ""}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*MSRP ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              type="number"
              onChange={(e) => setPrice(formatDecimal(e.target.value, 4))}
              onKeyDown={(e) => preventNonIntegers(e)}
              onPaste={(e) => preventPasteNonIntegers(e)}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 5999"}
              value={price === null ? '' : price}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Weight ({userSettings.weight_labels.shortPlural}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              type="number"
              onChange={(e) => setWeight(formatDecimal(e.target.value, 4))}
              onKeyDown={(e) => preventNonIntegers(e)}
              onPaste={(e) => preventPasteNonIntegers(e)}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 2500"}
              value={weight === null ? '' : weight}
            />
          </S.ModalFormInputWrapper>
      <S.ModalFormButtonWrapper>
        <S.ModalSaveButton type='submit'>Save</S.ModalSaveButton>
      </S.ModalFormButtonWrapper>
        </S.ModalForm>
      </S.ModalContainer>
    </S.ModalDisplay>
  );
}