import * as S from "../../styles/ezio-styles/InvalidDetailPage-styles";

export default function InvalidDetailPage() {return(
  <S.DisplayLayout>
    <S.MessageTitle>
      Error!
    </S.MessageTitle>
    <S.Message>
      You cannot view a location detail page when your selected location is set to All Locations. Please update your location filter or <S.HomeLink to="/ezio/">return to the home page</S.HomeLink>.
    </S.Message>
  </S.DisplayLayout>
)}