import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import shiftedLogo from '../../images/shifted_long.png';
import homeSelectedImg from '../../images/home_selected.png';
import homeUnselectedImg from '../../images/home_unselected.png';
import homeHoverImg from '../../images/home_hover.png';
import dataSelectImg from '../../images/data_selected.png';
import dataUnselectedImg from '../../images/data_unselected.png';
import dataHover from '../../images/data_hover.png';
import mapSelectImg from '../../images/map_selected.png';
import mapUnselectedImg from '../../images/map_unselected.png';
import mapHoverImg from '../../images/map_hover.png';
import vehicleSelectImg from '../../images/vehicle_selected.png';
import vehicleUnselectedImg from '../../images/vehicle_unselected.png';
import vehicleHoverImg from '../../images/vehicle_hover.png';

export default function NavMenu(props) {
  const { disabled } = props;
  const [selectedNavItem, setSelectedNavItem] = React.useState('');
  //const location = !disabled ? useLocation() : null;
  const location = useLocation();

  useEffect(() => {
    if(disabled || !location)return;
    switch (location.pathname) {
      case '/shifted/landing':
        setSelectedNavItem('landing');
        break;
      case '/shifted/dataVisualization':
        setSelectedNavItem('data');
        break;
      case '/shifted/map':
        setSelectedNavItem('map');
        break;
      case '/shifted/vehicles':
        setSelectedNavItem('vehicle');
        break;
      default:
        setSelectedNavItem('vehicle');
        break;
    }
  }, [location, disabled]);

  const homeIcon = () => {
    return (selectedNavItem === 'landing' && !disabled)
      ? homeSelectedImg
      : homeUnselectedImg
  }

  const dataSelectedIcon = () => {
    return selectedNavItem === 'data' 
      ? dataSelectImg 
      : dataUnselectedImg
  }

  const mapSelectedIcon = () => {
    return selectedNavItem === 'map' 
      ? mapSelectImg
      : mapUnselectedImg
  }

  const vehicleSelectedIcon = () => { 
    return selectedNavItem === 'vehicle'
      ? vehicleSelectImg
      : vehicleUnselectedImg
  }

  return (
    <header className="sftd-nav_bg">
      <Link exact="true" className="navbar__link" to="/shifted/landing">
        {/* <h1 className="sftd-product_name">ShiftED</h1> */}
        <img
          alt="ShiftED logo"
          src={shiftedLogo}
          className="sftd-ttl-image"
        ></img>
      </Link>
      <p className="sftd-client-name">{props.displayName}</p>
      <div className="sftd-navigation-icon-wrapper">
        <Link exact="true" className="navbar_link_home" to="/shifted/landing">
          <img
            className="sftd-nav-image"
            id="home"
            alt="Home Nav Button"
            src={
              homeIcon()
            }
            onMouseOver={e =>
              (e.currentTarget.src = homeHoverImg)
            }
            onMouseOut={e =>
              (e.currentTarget.src = homeIcon())
            }
            onClick={e =>
              (e.currentTarget.src = homeSelectedImg)
            }
          />
        </Link>
        <Link
          exact="true"
          className="navbar_link_data"
          to="/shifted/dataVisualization"
        >
          <img
            className="sftd-nav-image"
            id="dataPage"
            alt="Data Visualization Nav Button"
            src={
              dataSelectedIcon()
            }
            onMouseOver={e =>
              e.currentTarget.src = dataHover
            }
            onMouseOut={e =>
              e.currentTarget.src = dataSelectedIcon()
            }
            onClick={e =>
              (e.currentTarget.src = dataSelectImg)
            }
          />
        </Link>
        <Link exact="true" className="navbar_link_map" to="/shifted/map">
          <img
            className="sftd-nav-image"
            id="map"
            alt="map"
            src={
              mapSelectedIcon()
            }
            onMouseOver={e =>
              e.currentTarget.src = mapHoverImg
            }
            onMouseOut={e =>
              e.currentTarget.src = mapSelectedIcon()
            }
            onClick={e =>
              e.currentTarget.src = mapSelectImg
            }
          />
        </Link>
        <Link
          exact="true"
          className="navbar_link_vehicle"
          to="/shifted/vehicles"
        >
          <img
            className="sftd-nav-image"
            id="vehicles"
            alt="vehicles Nav Button"
            src={
              vehicleSelectedIcon()
            }
            onMouseOver={e =>
              e.currentTarget.src = vehicleHoverImg
            }
            onMouseOut={e =>
              e.currentTarget.src = vehicleSelectedIcon()
              
            }
            onClick={e =>
              e.currentTarget.src = vehicleSelectImg
            }
          />
        </Link>
      </div>
    </header>
  );
}
