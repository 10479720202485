import React from "react";

export default function OnHoverExplanation(props) {
  const { id, mouseCoords } = props;

  function copyDecider() {
    switch (id) {
      // Table
      case "ezEVRec":
        return {
          title: "ezEV Recommendation",
          message:
            "The result of the ezEV analysis which analyzes the actual driving of your current vehicle, comparing the economics and operational impacts of conducting that driving in a new ICE versus a new EV, using all EV models listed on the settings tab. If an EV is identified that meets the operational and economic requirements of the vehicle, it will receive an ezEV Recommendation for an EV model.",
        };
      case "ezEVScore":
        return {
          title: "ezEV Score",
          message: "Placeholder - ezEV Score based on ezEV Analysis",
        };
      case "ReasonOfFailure":
        return {
          title: "Reason",
          message:
            "This column identifies the metric(s) that did not meet the minimum requirements for an EV recommendation. It may be possible that minor operational changes would make a vehicle a good fit for replacement with an EV.",
        };
      // Ezev table selectors
      case "failed":
        return {
          title: "No Current Match",
          message:
            "Based on the analysis and existing EV models, there is not currently an EV that meets the operational and economic requirements of this vehicle. ",
        };
      case "Best in Class":
        return {
          title: "EV Matches within Vehicle Class",
          message:
            "This table lists the only the vehicles for which an EV within the same vehicle class (e.g. sedan, SUV) has been identified as a good match.",
        };
      case "recommendations":
        return {
          title: "ezEV Recommendations",
          message:
            "The result of the ezEV analysis which analyzes the actual driving of your current vehicle, comparing the economics and operational impacts of conducting that driving in a new ICE versus a new EV, using all EV models listed on the settings tab. If an EV is identified that meets the operational and economic requirements of the vehicle, it will receive an ezEV Recommendation for an EV model.",
        };
      case "all results":
        return {
          title: "All Vehicles",
          message: " This table lists all vehicles included in the analysis. ",
        };
      // Peak Demand Graph Hovers
      case "selectPercent":
        return {
          title: "Total Fleet Electrification Target",
          message:
            "Select the fleetwide electrification target to identify how the charging demand at each location will change based on the fleetwide electrification target. For example, if you select 40%, the chart shows you the projected charging demand for the vehicles at that location that fall in the top 40% of all fleet vehicles. This is the top 40% of vehicles across the entire fleet, not specifically at this location. Click on a month to see the daily charging demand curves and on which days the peak demand would occur.",
        };
      case "selectLocation":
        return {
          title: "Locations",
          message: "Select a location to see the projected peak charging demand.",
        };
      case "allVehicles":
        return {
          title: "All Vehicles",
          message:
            "All Vehicles includes all vehicles included in the analysis.",
        };
      case "ezEvRecommendations":
        return {
          title: "ezEV Recommendations",
          message:
            "ezEV Recommendations includes only those vehicles identified as a good fit for replacement with an EV based on current available models.",
        };
      case "chargeDemandInfo":
        return {
          title: "Charge Demand",
          message:
            "The projected location at which the vehicle will charge is determined by where each vehicle parks at then end of the driving for the day. It is not dependent on where existing charging infrastructure may be located but is meant to identify where charging will most likely be needed to support your driver’s based on their current driving and parking behavior. Some of these separate locations may be near enough to each other that you may determine it appropriate to group charging infrastructure from multiple locations at a single location to simplify installation and reduce costs.",
        };
      case "vehiclePricePremium":
        return {
          title: "Vehicle Price Premium",
          message:
            "Change this slider to see how increasing the EV cost premium that you are comfortable allowing impacts the number of vehicles that can be replaced by an EV. Setting this at 5% means that you are comfortable paying up to 5% more over the lifetime of the vehicle when compared to the costs of an ICE. This includes the purchase price of the vehicle and the operating costs.",
        };
      case "mapDescription":
        return {
          title: "Map Locations",
          message:
            "The projected location at which the vehicle will charge is determined by where each vehicle parks at then end of the driving for the day. It is not dependent on where existing charging infrastructure may be located but is meant to identify where charging will most likely be needed to support your driver’s based on their current driving and parking behavior. Some of these separate locations may be near enough to each other that you may determine it appropriate to group charging infrastructure from multiple locations at a single location to simplify installation and reduce costs.",
        };

      default:
        return { title: "", message: "" };
    }
  }
  const onHoverMessage = copyDecider();
  if (onHoverMessage.message === "") return <></>;
  return (
    <div
      className="nrel-onHover-explanation"
      id={id}
      // NOTE: These coordinates are set to the dashboard not as stand alone!
      style={{ top: mouseCoords[1] - 475, left: mouseCoords[0] - 90 }}
    >
      <p className="nrel-onHover-title">{onHoverMessage.title}</p>
      <p className="nrel-onHover-message">{onHoverMessage.message}</p>
    </div>
  );
}
