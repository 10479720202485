import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import UpdateModal from './speedn-update-modal';
import Header from "./speedn-header";
import { tableDateFormat } from '../speedn-components/speedn-csv-helper-functions';
import { vehicleIdDisplay } from "./speedn-table-helper-functions";
import SpeednMap from "./speedn-map";

function KMPH2MPH(kmph){
  return kmph*0.621371;
}

export default function IncidentDetail(props){
  const { vin, ts } = useParams();
  const { parent, dbDisplayName } = props;
  const events = props.events.filter((e)=> { if(e.vin === vin && e.ts === ts)return e; else return null; });
  const returnUrl = `/speedn/${parent}/${vin}`;
  const [incidentContext, _setIncidentContext] = useState();
  const [validityChange, _setValidityChange] = useState(false);
  const [valid, _setValid] = useState(events[0].valid);
  const [validReason, _setValidReason] = useState(events[0].valid_reason);
  //const [dismissedReason, _setDismissedReason] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [highTS, _setHighTS] = useState();
  const [event, _setEvent] = useState(events[0])
  const history = useHistory();

  const handleCheckChange = (e) => {
    if(!validityChange)_setValidityChange(true);
    if(!valid)_setValidReason("");
    _setValid(valid ? false : true);
  }

  const handleReasonChange = function(e){
    _setValidReason(e.target.value);
  }

  const handleUpdateClick = function(){
    //event.valid_reason = dismissedReason;
    //if(dismissed === false)_setDismissalChange(false);
    event.valid = valid;
    event.valid_text = valid ? "Valid" : "Dismissed";
    event.valid_reason = validReason;
    _setEvent(event);
    _updateSpeedingIncident(props.req, event, function(res){
      setModalOpen(true);
    });
  }

  if(typeof event === "undefined"){
    return(<div><br/><br/><br/><p className="speedin-holdon">No Data</p></div>)
  }
  else{
    if(typeof incidentContext === 'undefined'){
      _getSpeedingIncidentContext(props.req, event, function(coords, reason, highTS){
        _setIncidentContext(coords);
        //let d = (event.valid === false || event.valid === "X") ? true : false;
        //_setDismissed(d);
        _setHighTS(highTS);
        //if(reason)_setDismissedReason(reason);
      });
    };
  }
  const handleOnClick = () => {
    history.push(returnUrl);
  }

  const returnHome = () => {
    const url = `/speedn/summarytable`;
    history.push(url);
  }

  return(
    <>
      <Header subHeader={vehicleIdDisplay(event)} />
        <div className="speedn-subHeader-wrapper">
          <div>
          <button 
            className="speedn-return-btn"
            onClick={handleOnClick}>
            Back
          </button>

          <button 
            className="speedn-return-btn"
            onClick={returnHome}>
            Home
          </button>
        </div>
          <h3 className="speedn-subHeader">{`${event.year} ${event.make} ${event.model}`}</h3>
        </div>
          <p className="speedn-explain-text">Red circle indicates the point where max speed was reached.</p>
          <p className="speedn-explain-text">Black circles represent additional GPS readings during the event.</p>

          <div className='speedn-top-panels'>
            <div>
              <UpdateModal 
                isOpen={modalOpen}
                closeModal={function(){setModalOpen(false);}}>
              </UpdateModal>

              <SpeednMap
                events={[event]}
                supplementalData={incidentContext}
                zoom={15}
                center={[event.longitude, event.latitude]}
                isIncident={true}
                isSummary={false}
                highTS={highTS}
                dbDisplayName={dbDisplayName}
              />
            </div>

            <div className='speedn-incident-content-text'>
              <div>Incident Start: <span className='speedn-incident-text-light'>{tableDateFormat(event.local_start)}</span></div>
              <div>Incident Stop: <span className='speedn-incident-text-light'>{tableDateFormat(event.local_stop)}</span></div>
              <div>Incident Location: <span className='speedn-incident-text-light'>{event.eventLocation}</span></div>
              <div>Vehicle Max Speed: <span className='speedn-incident-text-light'>{event.mph}</span></div>
              <div>Posted Speed: <span className='speedn-incident-text-light'>{event.postedMph} mph</span></div>
              <div>Excess Speed Above Posted Speed: <span className='speedn-incident-text-light'>{event.excessMph}</span></div>
              <div>Dismiss This Incident:<input className='speedn-dismissed-checkbox' type="checkbox" checked={valid ? false : true} onChange={handleCheckChange}/></div>
              <div>
              {(!valid || validityChange) && 
                <div className="speedn-text-flex-wrapper">
                  <label htmlFor="dismissed-text">Reason for Dismissing:</label>
                  <textarea 
                    className="speedn-dismissed-textarea"
                    type="text"
                    name='dismissed-text'
                    onChange={handleReasonChange}
                    value={validReason}
                  ></textarea> 
                  <button
                      style={{margin: '10px auto'}}
                      className='speedn-return-btn'
                      onClick={handleUpdateClick}>
                      Update
                    </button>
                </div>
              }
              </div>
            </div>
          </div>
    </>
  )
}

function _updateSpeedingIncident(req, event, cb){
  const body = {dbName: req.dbName, pkid: event.pkid, valid: event.valid, valid_reason: event.valid_reason};
  const url = `${req.apiURL}updateSpeedingEvent`;
  fetch(url, {
    method: "POST",
    headers: { 
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${req.user.token}`,
      },
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(data => {if(cb)cb(data)});
}

function _getSpeedingIncidentContext(req, event, cb){
  const url = `${req.apiURL}getSpeedingIncidentContext?dbName=${req.dbName}&pkid=${event.pkid}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const coords = data.data[0].coords;
        const dr = data.data[0].valid_reason;
        let ts = "";
        if(coords !== null){
          const idx = coords.findIndex((c) => event.latitude === c.latitude && event.longitude === c.longitude)
          
          //swap the timestamp in here so the chain of tokens makes sense.
          if(idx > -1 && typeof event !== "undefined"){
            const d = new Date(coords[idx].dateTime)
            d.setHours(d.getHours() + (event.utc_offset/60))
            ts = _parseDateString(d.toISOString());
          }

          delete coords[idx];

          coords.forEach((c) => {
            let d = new Date(c.dateTime);
            d.setHours(d.getHours() + (event.utc_offset/60));
            c.ts = _parseDateString(d.toISOString());
            c.mph = parseInt(KMPH2MPH(c.kmph));
            c.asset_id = event.asset_id;
          })
          cb(coords, dr, ts);
        }
      }
      else{
        if(cb)cb([]);
      }
    });
}

function _parseDateString(ds){
  let arr = ds.split('T');
  let d1 = arr[1].split('.');
  return `${arr[0]} ${d1[0]}`;

}

