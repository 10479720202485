import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormatSummaryData } from "./UtilityFunctions";


const emissionPercentage = (total, ghgAmount) => {
    let parsedTotal
    let parsedAmount
    if(total || total === 0) parsedTotal = Number(total)
    if(ghgAmount || total === 0) parsedAmount = Number(ghgAmount)
    let emissionPercentage = ((parsedAmount / parsedTotal) * 100);
    return Math.round(emissionPercentage * 10) / 10;
}

function formatLegendLabels (data, total) {
    return data.labels.map((label, idx) => {
        return `${label} (${emissionPercentage(total, data.data[idx].toFixed(1))}%)`
    })
}

const options = (props) =>{
    const opts = {
        animation: {
            duration: 0,
        },
        plugins: {
            active: {
                mode: "dataset",
                intersect: true,
            },
            title: {
                display: props.title ? true : false,
                text: props.title ? props.title : 'PIE CHART!',
                position: 'top',
                align: 'center',
                font: {
                    size: 17
                }
            },
            legend:{
                display: true,
                position: 'bottom',
                align: 'center',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        //the tool teep needs totalForPercentage to have a single digit to the right of the decimal
                        //or the % hover will be off.
                        return [
                            [`${context.label.split(' (')[0]}:`],
                            [`${emissionPercentage(props.totalForPercentage.toFixed(1), context.parsed)}% of total`],
                            [`${context.formattedValue} ${props.displayUnits}`],
                        ]
                    },
                }
            }
        },
        layout: {
            padding: 0
        },
        maintainAspectRatio: false,
        responsive: false,
    }
    return opts;
}

export default function EmissionsPieChart(props){
    Chart.defaults.font.family = "'Arimo','Open Sans','HelveticaNeue','Helvetica Neue','Arial','sans-serif'";
    //"rgba(250, 98, 28, 1)" - orange
    //rgba(19, 46, 87, 1) - dark blue
    //rgba(29, 132, 150, 1) - lighter blue
    const COLORS = props.COLORS;

    function checkForSmallValue(context) {
        const index = context.dataIndex
        const value = context.dataset.data[index]
        const percent = emissionPercentage(props.totalForPercentage.toFixed(1), value)
        if (percent < 11) {
            return true
        } else {
            return false
        }
    }

    let formattedData = props.data.data.map((item) => FormatSummaryData('pieGraphData', item))
    const graphData = function(){
        return({
          labels: formatLegendLabels(props.data, props.totalForPercentage.toFixed(1)),
          datasets: [
              {
                data: formattedData,
                datalabels: {
                    formatter: (value) => {
                        let label = emissionPercentage(props.totalForPercentage.toFixed(1), value)
                        if(isNaN(label)) {
                            return null;
                        } else {
                            return `${label}%`
                        }
                    },
                    display: props.displaySliceLabels ? props.displaySliceLabels : false,
                    color: 'white',
                    align: function(context) {
                        return checkForSmallValue(context) ? 'end' : 'center'
                    },
                    padding: function(context) {
                        if(checkForSmallValue(context)) {
                            return ({
                                left: 5,
                                bottom: 20
                            })
                        }
                    },
                    rotation: function(ctx) {
                        if(checkForSmallValue(ctx)) {
                        const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                        const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                        const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] /2) /sum *360);
                        return rotation < 180 ? rotation-90 : rotation+90;
                        } else {
                            return 0
                        }
                      },
                    font: {
                        weight: 'bold',
                        size: '16px'
                    }
                },
                backgroundColor: props.data.barColor ? props.data.barColor : [COLORS[0], COLORS[1], COLORS[2], COLORS[3], COLORS[4]],
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 1)',
              },
            ],
        });
    }

    const id = props.id ? props.id : 'defaultPieChartId';
        
        return(
            <Pie
                ref={props.graphRef ? props.graphRef : null}
                id={id}
                data={graphData()}
                width={400}
                height={350}
                plugins={[ChartDataLabels]}
                options={options(props)}
            />
        )
}
