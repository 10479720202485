import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import UserSettings from './UserSettings';
import NavMenu from './NavMenu';
import DataVizPage from './DataVizPage';
import MapPage from './MapPage';
import LandingPage from './LandingPage';
import VehiclePagesCntrlr from './VehiclePagesCntrlr';
import { vehicleIdDisplay } from './DataFormatter';


class Shifted extends React.Component {
  constructor(props) {
    super(props);
    var apiURL = props.secrets.productionApi;
    if (props.secrets.devState !== 'production') apiURL = props.secrets.devApi;
    this.apiHost = this.props.apiHost ? this.props.apiHost : apiURL;
    this.basename = props.basename;
    this.fleetStart = '';
    this.fleetStop = '';
    this.sortedCycles = {};
    this.nonServicedCycles = 0; // only goes to one component
    this.dcVisualization = 86400;
    this.DEFAULT_DAYS_WINDOW = (24*60*60*1000)*(365/2)
    this.TIME_UNTIL_ZOMBIE = (24*60*60*1000)*2 //two days and then vehicle is unavailable and at unknown location
    this.displayName = this.props.dbDisplayName;
    this.state = {
      start: '',
      stop: '',
      startTime: '24:00',
      stopTime: '12:00',
      segmentTimeDelta: 86400,
      vehicleClasses: [],
      selectedLocIds: [],
      allLocations: [], // now all locations, not mutated
      vehicles: [],
      // selected vcl ids?
      dutySegments: [], // an array of ts for each segment
      dutyCycles: [], // og cycles
      vehicleUtil: [],
      fullCapacity: [],
      selectedDays: this.allDays(),
      filteredTotals: {
        avg_days: 0,
        avg_trips: 0,
        avg_kms: 0,
        avg_dcs: 0,
        avg_hrs: 0
      },
      filteredVclTotals: [],
      loading: true,
      totalVehicleCount: 0
    };
    // An object to track changes for update button
    this.settingChangeTracker = {
      initial: true,
      allLoctions: false,
      classes: false,
      deselectAll: false,
      selectedLocIds: false,
      selectedDays: false,
      segmentTimeDelta: false
    };
  }

  componentDidMount = () => {
    this.getVehicleClasses();
    this.getDateBounds();
    this.getLocations();
    if (this.state.selectedLocIds.length > 0) this.getTotals();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.dutyCycles !== prevState.dutyCycles) {
      this.updateVehicleUtil();
    }
    if (this.state.vehicles !== prevState.vehicles) {
      this.updateVehicleUtil();
      // this.getDutyCycles(); // don't want to do this when selected/not changes, only when filters change
    }
    if (this.state.vehicleUtil !== prevState.vehicleUtil) {
      this.checkCapacity();
    }
    if (
      this.settingChangeTracker.initial &&
      this.state.selectedLocIds.length > 0
    ) {
      this.updateSettings();
    }
  }


  getVehicleClasses = () => {
    const db = this.props.dbName;
    fetch(`${this.apiHost}getVehicleClassesPresent?dbName=${db}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        const arr = [];
        data.data.forEach((c) => {if(typeof c.vehicle_class !== 'undefined' && c.vehicle_class !== null && c.vehicle_class.length > 0)arr.push({vehicleClass: c.vehicle_class, selected: true})});
        this.setState({ vehicleClasses: arr});
      })
      .catch(error => console.error('Error: ' + error));
  };

  updateUtilSegments = () => {
    this.dutySegments(); // relies on date and segmentTimeDelta
    this.getDutyCycles(); // TEMPORARY
    // can do if callback then run it?
  };

  getDateBounds = () => {
    fetch(`${this.apiHost}getBounds?clientId=${this.props.dbName}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        const bounds = data['data'][0];
        const ed = Date.parse(bounds.max);
        let bd = Date.parse(bounds.min);
        bd = Math.max(bd, (ed-this.DEFAULT_DAYS_WINDOW));
        const startDate = new Date(bd).toISOString();
        this.setState({ start: startDate, stop: bounds.max });
        this.fleetStart = bounds.min;
        this.fleetStop = bounds.max;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  _setDefaultLocations = data => {
    const locs = data.sort((x, y) => (x.count < y.count ? 1 : -1));
    let arr = [];
    //prepopulate w/ 10 locations
    for (var i = 0; i < 15; i++)
      locs[i] !== undefined && arr.push(locs[i].parking_location);
    return arr;
  };

  getLocations = () => {
    // what does this need to respect? nothing for now, moved to LHS
    // do we want this to respect date bounds?! Currently doesn't
    var arr = [];
    fetch(
      `${this.apiHost}getDutyCycleLocations?clientId=${this.props.dbName}`,
      {
        headers: { Authorization: `Bearer ${this.props.user.token}` }
      }
    )
      .then(res => res.json())
      .then(data => {
        let selected = this.state.selectedLocIds;
        if (!selected || selected.length < 1)
          selected = this._setDefaultLocations(data.data);
        var ids = [];
        data.data.map(l => {
          let obj = {
            parking_location: l.parking_location,
            count: l.count,
            unfilteredCount: l.count,
            address: l.address,
            latitude: l.latitude,
            longitude: l.longitude,
            selected: selected.includes(l.parking_location) ? true : false
          };
          ids.push(l.parking_location);
          return arr.push(obj);
        });
        var combinedLocations = this.createCombinedGroup(arr);
        this.setState({
          allLocations: combinedLocations,
          selectedLocIds: selected
        });
        // this.settingChangeTracker.location = true;
        // selectedLocIds: ids
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  getTotals = () => {
    const locIds = this.state.selectedLocIds;
    const classes = [];
    this.state.vehicleClasses.forEach((c) => {if(c.selected)classes.push(c.vehicleClass)});
    const dow = this.selectedDays();
    Promise.all([
      fetch(
        `${this.apiHost}getFilteredAverages?clientId=${this.props.dbName}&start=${this.state.start}&stop=${this.state.stop}&locations=[${locIds}]&classes='{${classes}}'&dow=[${dow}]`,
        {
          headers: { Authorization: `Bearer ${this.props.user.token}` }
        }
      ),
      fetch(
        `${this.apiHost}getFilteredSums?clientId=${this.props.dbName}&start=${this.state.start}&stop=${this.state.stop}&locations=[${locIds}]&classes='{${classes}}'&dow=[${dow}]`,
        {
          headers: { Authorization: `Bearer ${this.props.user.token}` }
        }
      ),
      fetch(
        `${this.apiHost}getTotalsByVin?clientId=${this.props.dbName}&start=${this.state.start}&stop=${this.state.stop}&locations=[${locIds}]&classes='{${classes}}'&dow=[${dow}]`,
        {
          headers: { Authorization: `Bearer ${this.props.user.token}` }
        }
      )
    ])
      .then(([res1, res2, res3]) => {
        return Promise.all([res1.json(), res2.json(), res3.json()]);
      })
      .then(([data1, data2, data3]) => {
        if (data1 && data2 && data3) {
          let locAverages = { ...data1.data[0], ...data2.data[0] };
          let vclData = data3.data;
          if (locAverages.avg_days !== null)
            this.setState({
              filteredTotals: locAverages
            });
          if (vclData.length > 0)
            this.setState({
              filteredVclTotals: vclData
            });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  createCombinedGroup = data => {
    // Make into EP: 'totals'?
    var park_loc = [];
    var counter = 0;
    // eslint-disable-next-line no-unused-vars
    var count = 0;
    // eslint-disable-next-line no-unused-vars
    var lat = 0;
    // eslint-disable-next-line no-unused-vars
    var long = 0;
    data.map(l => {
      park_loc.push(l.parking_location);
      counter += 1;
      count += parseInt(l.count);
      lat += parseInt(l.latitude);
      long += parseInt(l.longitude);
      return counter;
    });

    var combined = data;
    return combined;
  };

  fetchVehicles = () => {
    // added start and end dates & optional class param
    // fetches all that matches filters
    // somehow add days of week param - v2
    // mark selected like loc? array of ids/vins
    if (this.state.start === '') return; // for now, return out if datebounds not yet added
    const locIds = this.state.selectedLocIds;
    const classes = [];
    this.state.vehicleClasses.forEach((c) => {if(c.selected)classes.push(c.vehicleClass)});
    fetch(
      `${this.apiHost}getDutyCycleVehicles?clientId=${this.props.dbName}&start=${this.state.start}&stop=${this.state.stop}&locations=[${locIds}]&classes='{${classes}}'`,
      {
        headers: { Authorization: `Bearer ${this.props.user.token}` }
      }
    )
      .then(res => res.json())
      .then(data => {
        let arr = data.data.map(v => {
          return {
            vin: v.vin,
            assetID: vehicleIdDisplay(v),
            selected: true,
            year: v.year,
            make: v.make,
            model: v.model,
            vehicle_class: v.vehicle_class,
            homebase: v.parking_location,
            lastSeen: v.last_seen,
            address: v.address,
            odomKM: v.odometer_km
          };
        });
        this.setState({ vehicles: arr });
        if (data.data.length > this.state.totalVehicleCount)
          this.setState({ totalVehicleCount: data.data.length });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  dutySegments = () => {
    // convert timestamp to start of day
    var start_day = new Date(new Date(this.state.start).setHours(0, 0, 0, 0));
    var start = Date.parse(start_day);
    let segments = new Array(this.segmentSize());
    const days = this.selectedDays();
    if (segments.length > 0) {
      for (var i = 0; i < segments.length; i++) {
        var unadjusted_v =
          start + i * this.state.segmentTimeDelta * 1000 + 0 * 1000 * 60; // NOT set to 5 mins after the period
        var v = start + i * this.state.segmentTimeDelta * 1000 + 0 * 1000 * 60; //set to 5 mins after the period
        let d = new Date(0);
        d.setUTCSeconds(v / 1000);
        let temp_d = new Date(0);
        temp_d.setUTCSeconds(unadjusted_v / 1000);
        if (days.indexOf(d.getDay()) > -1) segments[i] = v;
        else segments[i] = -1; // if deselected day, ts -1
      }
      this.setState({ dutySegments: segments });
    } else return;
  };

  segmentSize = () => {
    if (!this.state.start || !this.state.stop) return;
    // convert timestamp to start of day
    var startOfDay = new Date(new Date(this.state.start).setHours(0, 0, 0, 0));
    var start = Date.parse(startOfDay);
    var stop = Date.parse(this.state.stop);
    return start >= stop
      ? console.error('out of range') // needs to do something. Breaks badly
      : parseInt((stop - start) / 1000 / this.state.segmentTimeDelta);
  };

  getDutyCycles = () => {
    // og fetchDutyCyclesForLoc
    // every vehicle at selected loc that matches class
    // group, days of week and hours in day v2
    const classes = [];
    this.state.vehicleClasses.forEach((c) => {if(c.selected)classes.push(c.vehicleClass)});
    const url = `${this.apiHost}getDutyCycles?clientId=${this.props.dbName}&locations=[${this.state.selectedLocIds}]&start=${this.state.start}&stop=${this.state.stop}&classes='{${classes}}'`;
    fetch(url, {
      headers: { Authorization: `Bearer ${this.props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ dutyCycles: this.sortDutyCycles(data.data) });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  sortDutyCycles = dutyCycles => {
    var sortedCycles = {};
    dutyCycles.map(dc => {
      // myObj.hasOwnProperty('key') faster?
      if (sortedCycles[dc.vin]) {
        var cycles = sortedCycles[dc.vin];
        cycles.push(dc);
      } else sortedCycles[dc.vin] = [dc];
      return dc;
    });
    // return sortedCycles;
    this.sortedCycles = sortedCycles;
    return dutyCycles;
  };

  updateSettings = () => {
    this.setState({ loading: true });
    if (
      this.settingChangeTracker.initial &&
      !this.settingChangeTracker.deselectAll
    ) {
      this.fetchVehicles();
      this.dutySegments();
      if (this.state.selectedLocIds.length > 0) this.getTotals();
      this.settingChangeTracker = this.resetTracker();
    }
    if (
      this.settingChangeTracker.selectedLocIds ||
      this.settingChangeTracker.allLocations ||
      this.settingChangeTracker.vehicleClasses
    ) {
      this.fetchVehicles();
      this.dutySegments();
      this.getDutyCycles();
      if (this.state.selectedLocIds.length > 0) this.getTotals();
      this.settingChangeTracker = this.resetTracker();
    }
    if (this.settingChangeTracker.selectedDays) {
      this.updateUtilSegments();
      if (this.state.selectedLocIds.length > 0) this.getTotals();
      this.settingChangeTracker = this.resetTracker();
    }
    if (this.settingChangeTracker.segmentTimeDelta) {
      // keeps visualization from updating until action button is clicked
      this.dcVisualization = this.state.segmentTimeDelta;
      this.updateUtilSegments();
      if (this.state.selectedLocIds.length > 0) this.getTotals();
      this.settingChangeTracker = this.resetTracker();
    }
    if (
      this.settingChangeTracker.deselectAll &&
      !this.settingChangeTracker.allLocations
    ) {
      this.setState({
        selectedLocIds: [],
        filteredVclTotals: [],
        vehicles: [],
        dutySegments: [],
        dutyCycles: [],
        vehicleUtil: [],
        fullCapacity: []
      });
      this.settingChangeTracker = this.resetTracker();
    }
    this.updateUtilSegments();
    // Resetting change tracker
  };

  handleSettingsChange = (value, type) => {
    this.setState({ [type]: value });
    this.settingChangeTracker[type] = true;
  }; // replaces handleTimeSegmentChange, handleWeekdayChange &
  // handleClassRadioBtn, handleTimeChange

  handleDateChange = (e, type) => {
    this.settingChangeTracker.selectedDays = true;
    e !== null ? this.setState({ [type]: e.toISOString() }) : (e = null);
  }; // replaces handleEndDateChange and something else?

  handleVehicleSelect = e => {
    // flow through vin key val structure here?
    if (!e.vin) return;
    let vcls = this.state.vehicles;
    var updatedVcls = vcls.map(v => {
      if (v.vin === e.vin) {
        v.selected = !v.selected;
      }
      return v;
    });
    this.setState({ vehicles: updatedVcls });
  };

  resetFilters = () => {
    let locs = this.state.allLocations.map(l => {
      l['selected'] = false;
      return l;
    });
    this.setState({
      selectedDays: this.allDays(),
      allLocations: locs,
      start: this.fleetStart, // this isn't updating on reset
      stop: this.fleetStop,
      startTime: '08:00',
      stopTime: '15:00',
      segmentTimeDelta: 86400,
      selectedLocIds: [],
      vehicles: [],
      dutySegments: [],
      dutyCycles: [],
      vehicleUtil: [],
      fullCapacity: [],
      showDetails: false,
      filteredTotals: { dc_ct: 0, kms: 0, trip_ct: 0, days: 0 },
      filteredVclTotals: []
    });
  };

  updateVehicleUtil = () => {
    var vcls = this.state.vehicles;
    let arr = [];
    let totalSelectedUtil = 0;
    let totalFilteredUtil = 0;
    let selectedCt = 0;
    if (
      typeof vcls !== 'undefined' &&
      vcls.length > 0 &&
      this.state.dutyCycles.length > 0
    ) {
      for (var i = 0; i < vcls.length; i++) {
        const vu = this.singleVclUtil(vcls[i]);
        arr.push(vu);
        if (vu.selected) {
          totalSelectedUtil += vu.avgUtilization;
          selectedCt += 1;
        }
        totalFilteredUtil += vu.avgUtilization;
      }
    }
    this.avgTotalUtil = {
      // putting both in here for now so we can see our options
      // regardlelss of which keeping consider moving. Only need on
      // select/deselect
      avgSelectedUtil: totalSelectedUtil / selectedCt,
      avgFilteredUtil: totalFilteredUtil / vcls.length
    };
    this.setState({ vehicleUtil: arr });
    // vehicleUtil duplication of 'vehicle' objs but with utilization metrics
  };

  singleVclUtil = vcl => {
    // could only update utilization if w/in class, otherwise doesn't matter
    // when singe vehicle added / removed, not nec when filters change
    // but also worth thinking about changing / limiting based on which filters change
    var segments = this.state.dutySegments;
    let dcs = 0;
    var isStart = false;
    var prevCycleState = -1;
    var util = [];
    for (var i = 0; i < segments.length; i++) {
      var utilSeg = { ts: segments[i], value: 0, dc: null, isStart: false };
      var tod = this.testOnDuty(
        vcl,
        segments[i],
        segments[i] + this.state.segmentTimeDelta * 1000
      );
      isStart = prevCycleState === -1 && tod ? true : false;
      if (tod > -1) {
        utilSeg.value = 1;
        utilSeg.dc = tod;
        utilSeg.isStart = isStart;
      }
      prevCycleState = tod;
      dcs += utilSeg.value;
      util.push(utilSeg);
    }
    // to copy of existing vcl object, add utilz and dc results
    var vclUtil = vcl;
    vclUtil['totalDCs'] = dcs;
    vclUtil['avgUtilization'] = dcs /= segments.length;
    vclUtil['utilization'] = util;
    return vclUtil;
  };

  // this was sep function, exported for use in tests
  testOnDuty(vcl, seg_start, seg_stop) {
    // var cycles = this.state.dutyCycles;
    // const dcs2 = cycles.filter(dc => dc.vin === vin); // get all cycles for vin
    const vin = vcl.vin;
    const lastSeen = Date.parse(vcl.lastSeen)+this.TIME_UNTIL_ZOMBIE;
    if(lastSeen <= seg_start)return 0;

    const dcs = this.sortedCycles[vin];
    if (!dcs || dcs.length < 1)return;

    for (let i = 0; i < dcs.length; i++) {
      const dc_start = Date.parse(dcs[i].utc_start);
      const dc_stop = Date.parse(dcs[i].utc_stop);
      if (dc_stop < seg_start) continue; //def in past
      if (dc_start > seg_stop) continue; // def in future
      if (dc_start >= seg_start && dc_stop <= seg_stop)
        return dcs[i].duty_cycle; //def in segment
      if (dc_start >= seg_start && dc_stop >= seg_stop)
        return dcs[i].duty_cycle; //continues from segment
      if (dc_start <= seg_start && dc_stop >= seg_stop)
        return dcs[i].duty_cycle; //spans segment
      if (dc_start <= seg_start && dc_stop <= seg_stop)
        return dcs[i].duty_cycle; //ends within segment
    }
    return -1;
  }

  checkCapacity = () => {
    if (this.state.vehicleUtil.length < 1) return;
    // temporary ^ only call this fcnt once vehicle util done
    var dutySegments = this.state.dutySegments;
    this.nonServicedCycles = 0;
    let fullCapacity = [];
    let fcState = false;
    // get selected vehicles
    const selectedVcls = this.state.vehicleUtil.filter(v => {
      return v.selected;
    });
    //outer loop of all duty segments in timeframe (array of integers)
    for (var i = 0; i < dutySegments.length; i++) {
      //inner loop of all vehicle util objs
      let vclsOnDuty = 0;
      let startsInSegment = 0;
      let vclsOut = 0;
      for (var c = 0; c < this.state.vehicleUtil.length; c++) {
        // for every time segment, loop over vehicleUtil
        // and check how many on duty, compare to how many selected
        // add to applicable total tracker
        const vu = this.state.vehicleUtil[c]; // current vehicle
        if (vu.utilization[i].value > 0) vclsOnDuty++;
        if (vu.utilization[i].isStart) startsInSegment++;
        if (vu.utilization[i].value > 0 && !vu.utilization[i].isStart)
          vclsOut++;
      }
      // if less vcls on duty than selected, not at or over capacity
      if (vclsOnDuty < selectedVcls.length) {
        fcState = false;
      }
      // if more vcls on duty than selected, at or over capacity
      if (vclsOnDuty >= selectedVcls.length || fcState === true) {
        fcState = true;
        var obj = {
          dc: i, // can we get rid of this
          capacity: parseFloat(vclsOnDuty / selectedVcls.length),
          segment_ts: dutySegments[i]
        };
        fullCapacity.push(obj);

        if (vclsOnDuty > selectedVcls.length && startsInSegment) {
          // if greater than, over 100
          var avail = selectedVcls.length - vclsOut;
          if (avail >= 0 && startsInSegment > avail) {

            this.nonServicedCycles += startsInSegment - avail;
          } else {

            this.nonServicedCycles += startsInSegment;
          }
        }
      }
    }
    this.setState({ fullCapacity: fullCapacity, loading: false });
  };

  allDays = () => {
    return {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true
    };
  };

  resetTracker = () => {
    return {
      initial: false,
      allLoctions: false,
      vehicleClasses: false,
      deselectAll: false,
      selectedLocIds: false,
      selectedDays: false,
      segmentTimeDelta: false
    };
  };

  capacitySegments = (hoursAtCapacity, hoursAboveCapacity) => {
    switch (this.state.segmentTimeDelta) {
      case 3600:
        return {
          atCapacity: hoursAtCapacity / 1,
          aboveCapacity: hoursAboveCapacity / 1
        };
      case 21600:
        return {
          atCapacity: hoursAtCapacity / 6,
          aboveCapacity: hoursAboveCapacity / 6
        };
      case 28800:
        return {
          atCapacity: hoursAtCapacity / 8,
          aboveCapacity: hoursAboveCapacity / 8
        };
      case 43200:
        return {
          atCapacity: hoursAtCapacity / 12,
          aboveCapacity: hoursAboveCapacity / 12
        };
      case 86400:
        return {
          atCapacity: hoursAtCapacity / 24,
          aboveCapacity: hoursAboveCapacity / 24
        };
      default:
        return null;
    }
  };

  selectedDays = () => {
    let arr = [];
    if (
      typeof this.state.selectedDays !== 'undefined' &&
      this.state.selectedDays !== null
    ) {
      const sd = this.state.selectedDays;
      // make this one line reusable (loop)
      // for classses too - format state to array type thing
      if (sd.sun) arr.push(0);
      if (sd.mon) arr.push(1);
      if (sd.tue) arr.push(2);
      if (sd.wed) arr.push(3);
      if (sd.thu) arr.push(4);
      if (sd.fri) arr.push(5);
      if (sd.sat) arr.push(6);
    }
    return arr;
  };

  updateState = (value, item) => {
    this.setState({ item, value });
  };

  render() {
    // MOVE THIS TO ITS OWN COMPONENT ?
    const calculateCapacitySegments = () => {
      var over100Capacity = 0;
      var at100Capacity = 0;
      this.state.fullCapacity.map(v => {
        if (v.capacity > 1) over100Capacity += 1;
        else if (v.capacity === 1) at100Capacity += 1;
        return '';
      });
      return { over100: over100Capacity, at100: at100Capacity };
    };
    let capacity = calculateCapacitySegments();
    let hoursAtCapacity = Math.round(
      (capacity.at100 * this.state.segmentTimeDelta) / 3600
    );
    let hoursAboveCapacity = Math.round(
      (capacity.over100 * this.state.segmentTimeDelta) / 3600
    );
    let segmentCapacity = this.capacitySegments(
      hoursAtCapacity,
      hoursAboveCapacity
    );
    let totalHours =
      Math.round(this.state.dutySegments.length * this.state.segmentTimeDelta) /
      3600;
    const calculateCurrentAverage = () => {
      let utls = [];
      this.state.vehicleUtil.map(v => {
        return utls.push(v.avgUtilization);
      });
      const ttl = utls.reduce((a, b) => a + b, 0);
      return Math.round((ttl / this.state.vehicleUtil.length) * 100);
    };

    let selectedLocations = this.state.allLocations.filter(
      l => l.selected === true
    );
    let selectedVehicles = this.state.vehicleUtil.filter(
      v => v.selected === true
    );
    // END ------>
    return (
      <div className="sftd-application">
        <Router basename={this.basename}>
          <NavMenu displayName={this.displayName} />
          <UserSettings
            totalVehicleCount={this.state.totalVehicleCount}
            settingChangeTracker={this.settingChangeTracker}
            updateSettings={this.updateSettings}
            dateStart={this.state.start}
            selectedLocIds={this.state.selectedLocIds}
            dateEnd={this.state.stop}
            selectedDays={this.state.selectedDays}
            startTime={this.state.startTime}
            stopTime={this.state.stopTime}
            segmentTimeDelta={this.state.segmentTimeDelta}
            locations={this.state.allLocations}
            vehicleClasses={this.state.vehicleClasses}
            handleVehicleClassesChange={(arr)=>{this.setState({ vehicleClasses: arr})}}
            showDetails={this.state.showDetails}
            resetFilters={this.resetFilters}
            handleShowDetail={this.handleShowDetail}
            handleSettingsChange={this.handleSettingsChange}
            handleDateChange={this.handleDateChange}
            handleLocationChange={this._handleLocationChange}
            fleetStart={this.fleetStart}
            fleetStop={this.fleetStop}
            avgTotalUtil={this.avgTotalUtil}
            selectedVehicles={selectedVehicles}
            selectedLocations={selectedLocations}
            currentAverage={calculateCurrentAverage()}
            hoursAtCapacity={hoursAtCapacity}
            hoursAboveCapacity={hoursAboveCapacity}
            nonServicedCycles={this.nonServicedCycles}
            totalHours={totalHours}
            vehicleUtil={this.state.vehicleUtil}
          />
          <Switch>
            <Route
              path="/shifted"
              exact
              render={props => (
                <Redirect to={{ pathname: '/shifted/landing' }} />
              )}
            />
            <Route
              path="/shifted/landing"
              exact
              render={props => (
                <LandingPage
                  vehicleUtil={this.state.vehicleUtil}
                  filteredTotals={this.state.filteredTotals}
                  filteredVclTotals={this.state.filteredVclTotals}
                  selectedLocIds={this.state.selectedLocIds}
                />
              )}
            />
            <Route
              path="/shifted/dataVisualization"
              exact
              render={props => (
                <DataVizPage
                  fullCapacity={this.state.fullCapacity}
                  segmentTimeDelta={this.dcVisualization}
                  capacitySegments={segmentCapacity}
                  dutySegments={this.state.dutySegments}
                  vehicleUtil={this.state.vehicleUtil}
                  handleVehicleSelect={this.handleVehicleSelect}
                  loading={this.state.loading}
                  avgTotalUtil={this.avgTotalUtil}
                  selectedVehicles={selectedVehicles}
                  selectedLocations={selectedLocations}
                  currentAverage={calculateCurrentAverage()}
                  hoursAtCapacity={hoursAtCapacity}
                  hoursAboveCapacity={hoursAboveCapacity}
                  nonServicedCycles={this.nonServicedCycles}
                  totalHours={totalHours}
                  locations={this.state.allLocations}
                />
              )}
            ></Route>
            <Route
              path="/shifted/map"
              exact
              render={props => (
                <MapPage
                  locations={this.state.allLocations}
                  handleLocTableToggleClick={this.handleLocTableToggleClick}
                  selectedLocIds={this.state.selectedLocIds}
                  vehicles={this.state.vehicles}
                  settingChangeTracker={this.settingChangeTracker}
                  handleSettingsChange={this.handleSettingsChange}
                />
              )}
            ></Route>
            <Route
              path="/shifted/vehicles"
              exact
              render={props => (
                <VehiclePagesCntrlr
                  updateState={this.updateState}
                  settingChangeTracker={this.settingChangeTracker}
                  apiHost={this.apiHost}
                  user={this.props.user}
                  start={this.state.start}
                  stop={this.state.stop}
                  dbName={this.props.dbName}
                  vehicleUtil={this.state.vehicleUtil}
                  handleVehicleSelect={this.handleVehicleSelect}
                  avgTotalUtil={this.avgTotalUtil}
                  selectedDays={this.state.selectedDays}
                />
              )}
            ></Route>
            <Route
              path="/shifted/singleVehicle"
              exact
              render={props => (
                <VehiclePagesCntrlr
                  settingChangeTracker={this.settingChangeTracker}
                  vehicleUtil={this.state.vehicleUtil}
                  showDetails={this.state.showDetails}
                  handleDetail={this.handleDetail}
                  apiHost={this.apiHost}
                  user={this.props.user}
                  start={this.state.start}
                  stop={this.state.stop}
                  fullCapacity={this.state.fullCapacity}
                  dbName={this.props.dbName}
                  selectedDays={this.state.selectedDays}
                  ttldutyCycles={this.state.dutyCycles.length}
                  selectedLocIds={this.state.selectedLocIds}
                />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Shifted;
