import React, { useMemo, useCallback } from "react";
import styled from "styled-components/macro";
import { Bar } from "react-chartjs-2";

const GraphWrapper = styled.div`
  width: 65%;
  border: 2px solid rgb(187, 187, 187);
  border-radius: 2px;
  margin: 10px;
`;

//const SummaryTitle = styled.div`
//    font-family: Arial, Helvetica, sans-serif;
//    font-size: 20px;
//    font-weight: 500;
//`;

const NoDataText = styled.div`
  font-size: 18px;
  text-align: center;
`;


export default function WeeklyBarGraph(props){

    const { events, beginDate, endDate } = props;
  
    const vinSet = new Set(events.map((r) => {return r.vin}));
    const vins = [...vinSet];

    const getWeekNumber = function(date){
      let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };

    const sortByDate = function( a, b ) {
      if ( a.ts < b.ts )return -1;
      if ( a.ts > b.ts )return 1;
      return 0;
    }

    const weeksInRange = useCallback((begin, end) => {
      const woys = [];
      if(begin === null || end === null)return [];
      let d = new Date(begin.getTime());
      while(d <= end){
        const wn = getWeekNumber(d);
        woys.push(wn);
        d.setDate(d.getDate() + 1);
      }
      const woySet = new Set(woys);
      return [...woySet];
    }, []);
    
    const wir = useMemo(() => {
      if(beginDate === null || endDate === null)return [];
      else{
        const w = weeksInRange(beginDate, endDate);
        return w;
      }
    }, [beginDate, endDate, weeksInRange]);

    const compileIntoWeeks = function (evts) {
        function getWeek(ts){
          if(typeof ts === "undefined")return 0;
          let d = Date.parse(ts)
          d = new Date(d);
          return getWeekNumber(d);
        }

        const maxWeek = Math.max(0, getWeek(endDate));
        const minWeek = Math.max(0, getWeek(beginDate));
        const we = []
        let i = minWeek;
        while(i <= maxWeek){
          we.push({woy: i, incidents: 0, durations: 0, offRoute: 0})
          i++;
        }
        let arr = evts.sort(sortByDate);
        arr.forEach((a) => {
          const woy = getWeek(a.utc_start);
          we.forEach((w) => {if(w.woy === woy){
              if(a.location === 11)a.route_duration.minutes = 3;//handling rotten robbie loc 
              w.incidents++;
              w.durations += parseInt(Math.min(a.duration, 1200)/60);
              w.offRoute += parseInt(Math.min(10, a.route_duration.minutes))
            }
          });
        });
        return we;
    }

    const graphData = {
      labels: wir,
      datasets: []
    };
   // #023047 - dark blue
   // #FFB703
    const o = {
      label: 'Fueling Events', 
      data: new Array(wir.length).fill(0),
      backgroundColor: "#023047",
      fill: false}

    const d = {
      label: 'Minutes Fueling',
      data: new Array(wir.length).fill(0),
      backgroundColor: "#FFB703",
      fill: false
    }

    const r = {
      label: 'Minutes Travel To Event',
      data: new Array(wir.length).fill(0),
      backgroundColor: "#8ECAE6",
      fill: false
    }

    vins.forEach((v, idx) => {
      const f = events.filter((e) => v === e.vin);
      const weeklyEvents = compileIntoWeeks(f);
      weeklyEvents.forEach((e, i) =>{
        o.data[i] += e.incidents;
        d.data[i] += (e.durations);
        r.data[i] += e.offRoute;
      });
      if(idx === vins.length - 1){
        graphData.datasets.push(o);
        graphData.datasets.push(d);
        graphData.datasets.push(r);
      }
    });

    if(typeof events === "undefined" || events.length < 1 || events === null || typeof graphData === 'undefined'){
        return(<GraphWrapper><br/><br/><br/><NoDataText>No Data</NoDataText></GraphWrapper>)
    }

    return(
      <GraphWrapper>
        <Bar
          id="graph"
          data={graphData}
          options={
            {
              type: "bar",
              maintainAspectRatio: false
            }
          }

        />
      </GraphWrapper>
    )
}