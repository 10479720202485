import React from "react";
import { Line } from "react-chartjs-2";


export default function ServerGraph(props){
    const options={
        elements: {
            point: {
                hitRadius: 20,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins:{
            legend: { display: true },
            title: { text: `Time of Day`, display: false }
        },
        scales: {
            y:  {
                ticks: {
                    suggestedMax: 10,
                    suggestedMin: 0,
                },
                title: {
                    display: true,
                    text: props.datasetLabel ? props.datasetLabel : "Units",
                },
              },
            x:  {
                display: true,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 15,
                    // convert epoch minutes to human dates
                    callback: function(value, idx) {
                        const l = this.getLabelForValue(value);
                        const d = new Date(l);
                        return `${d.getUTCMonth()+1}-${d.getUTCDate()} ${d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours()}:${d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes()}`;
                    }
                },
                title: {
                    display: true,
                    text: "UTC",
                    padding: {top: 0, left: 0, right: 0, bottom: 0}
                }
            }
        }
    }
    if(!props.graphData)return(<></>);
    return(
        <Line
            id="demand-graph"
            data={props.graphData}
            options={options}
        />
    );
}