import React from "react";

export default function GetStarted(props) {
  const { dashType } = props;
  if (dashType === "ste")
    return (
      <>
        <h3 className="home-header">Project Overview</h3>
        <p className="project-about">
          As state fleets are increasing adoption of electric vehicles (EVs),
          many fleet and facility managers are looking to better understand the
          potential impact that the increased charging demand will have on their
          electricity demand. This project was undertaken to project what the
          charging demand could be at increasing levels of fleet electrification
          based on the actual driving of vehicles today. The participating state
          fleets provided the project team with access to telematics data from
          their vehicles. The team analyzed the detailed driving for each
          vehicle to determine when an EV could meet the operational and
          economic needs of each vehicle. Based on that analysis, the projected
          charging demand at each location and increasing levels of fleet
          electrification was determined. This analysis identifies peak demand
          at each location, seasonal demand differences, and how increasing
          levels of electrification will impact peak demand at each location.
          Fleet and facility managers can use this analysis to plan
          infrastructure to meet near- and long-term charging demand.
        </p>
        <div className="home-wrapper">
          <div className="about-section">
            <h4 className="nrel-sub-header">What can you do with this tool?</h4>
            <p className="nrel-tool-list-describer">
              This tool is designed to allow you to:
            </p>
            <ul className="nrel-tool-list">
              <li>
                Identify and prioritize vehicles to meet fleet electrification
                goals
              </li>
              <li>Determine the locations where EVSE will be needed</li>
              <li>
                Determine the potential impacts that EV charging will have on
                your electricity demand at each parking location at varying
                levels of fleet electrification
              </li>
              <li>
                Use this information to move to planning and implementation
                stages
              </li>
            </ul>
          </div>
          <div className="about-section">
            <h4 className="nrel-sub-header">
              How were the vehicles analyzed and scored?
            </h4>
            <p className="nrel-homeTab-description">
              The minute-by-minute driving was analyzed for each vehicle to
              determine if an EV will meet each vehicles’ needs, every day of
              the year, using telematics data. This detailed analysis provides
              not only an economic comparison with an internal combustion engine
              vehicle (ICE) but also assesses the energy needs for each vehicle
              each day to determine how many days a vehicle would need midday
              charging to complete the rest of their trips. This operational
              analysis is critically important to understand if an EV will meet
              your drivers’ needs. Each vehicle was compared with all EV models
              as identified as available to the fleet for purchase (see Settings
              Tab for specifics).
              <br />
              <br />
              Each vehicle was scored on four components using 170+ attributes
              and second-by-second driving data and provided with an overall
              ezEV Score. Vehicles receiving a score of 85 or better are
              determined to be a good fit for replacement with the recommended
              EV. The ezEV Score is a weighted average of the four following
              components below.
            </p>
            <ul className="nrel-tool-list">
              <li>
                <span>Energy:</span> The daily driving was analyzed to determine
                the energy consumption for each fuel type (gas and electric) to
                determine how much energy is needed to complete all driving each
                day. Each day was then assessed to determine if an EV could meet
                the driving needs and only need to be charged at night. If the
                vehicle would require midday charging more frequently than about
                3 days each month, it will not receive a passing ezEV score and
                “Energy” will be listed in the reason column.
              </li>
              <li>
                <span>Economics:</span> The total cost of ownership (TCO) of
                each vehicle is analyzed, comparing the TCO if the vehicle’s
                driving was conducted in an EV and if it was conducted in a new
                ICE. These are compared over the vehicle lifetime. For any
                vehicle where the TCO for the best fit EV would exceed 5% more
                than the TCO in an ICE, the vehicle will not receive a passing
                ezEV score and “Economics” will be listed in the reason column.
              </li>
              <li>
                <span>Parking Location Frequency:</span> The location frequency
                of extended parking periods (typically overnight) for each
                vehicle is analyzed, identifying how often the vehicle parks at
                each location to determine if they will have regular access to
                charging at a single location. Vehicles that park at a single
                location for at least 75% of their extended parking periods will
                receive a passing score. If not, “Parking” will be listed in the
                reason column.
              </li>
              <li>
                <span>Data Availability:</span> While up to 12 months of data
                was collected for these vehicles, some did not have sufficient
                data available. For vehicles with very little data , “Not Enough
                Trip Data” is identified in the reason column to indicate that
                there was insufficient data available to accurately assess the
                vehicle’s driving.
              </li>
            </ul>
          </div>
          <div className="about-section">
            <h4 className="nrel-sub-header">
              How is this customized to my fleet?
            </h4>
            <p className="nrel-homeTab-description">
              Each fleet provided the following data and values which were input
              into Sawatch Labs’ model. The custom values are provided in the
              “Settings” tab in the tool.
            </p>
            <ul className="nrel-tool-list">
              <li>
                Telematics data for every vehicle analyzed, providing
                minute-by-minute driving data
              </li>
              <li>The purchase price for EVs and ICEs</li>
              <li>Gas price</li>
              <li>Electricity rate</li>
              <li>GHG emissions intensity for electricity generation</li>
              <li>Maintenance costs for EVs and ICEs</li>
              <li>Vehicle lifecycle</li>
              <li>Insurance</li>
              <li>Social cost of carbon</li>
            </ul>
          </div>
        </div>
        <div className="about-section" id="note">
          <h4 className="nrel-sub-header">Note:</h4>
          <p className="nrel-homeTab-description">
            This work was conducted under contract to the National Renewable
            Energy Laboratory, operated by Alliance for Sustainable Energy, LLC,
            for the U.S. Department of Energy (DOE) under Contract No.
            DE-AC36-08GO28308. Funding provided by U.S. Department of Energy
            Office of Energy Efficiency and Renewable Energy Vehicle
            Technologies Office. The views expressed herein do not necessarily
            represent the views of the DOE or the U.S. Government.
          </p>
        </div>
      </>
    );
  else
    return (
      <>
        <h3 className="home-header">Project Overview</h3>
        <p className="project-about">
          Universities around the country are adopting sustainability targets
          that include goals for fleet operations. This project was undertaken
          to help universities and other campus fleets determine what role
          electric vehicles (EVs) can play in meeting their net-zero energy and
          other sustainability goals. The participating universities provided
          the project team with access to telematics data from their vehicles.
          The team analyzed the detailed driving for each vehicle to determine
          when an EV could meet the operational and economic needs of each
          vehicle.
        </p>
        <div className="home-wrapper">
          <div className="about-section">
            <h4 className="nrel-sub-header">
              {" "}
              What can you do with this tool?
            </h4>
            <p className="nrel-tool-list-describer">
              This tool is designed to allow you to:
            </p>
            <ul className="nrel-tool-list">
              <li>
                Identify and prioritize vehicles to meet fleet electrification
                goals
              </li>
              <li>
                Determine how changing inputs could impact the role that EVs can
                play in meeting your fleet electrification goals
              </li>
              <li>
                Determine the potential impacts that EV charging will have on
                your electricity demand at each parking location at varying
                levels of fleet electrification
              </li>
              <li>
                Use this information to move to planning and implementation
                stages
              </li>
            </ul>
          </div>
          <div className="about-section">
            <h4 className="nrel-sub-header">
              How were the vehicles analyzed and scored?
            </h4>
            <p className="nrel-homeTab-description">
              The minute-by-minute driving was analyzed for each vehicle to
              determine if an EV will meet each vehicles’ needs, every day of
              the year, using telematics data. This detailed analysis provides
              not only an economic comparison with an internal combustion engine
              vehicle (ICE) but also assesses the energy needs for each vehicle
              each day to determine how many days a vehicle would need midday
              charging to complete the rest of their trips. This operational
              analysis is critically important to understand if an EV will meet
              your drivers’ needs. Each vehicle was compared with all EV models
              as identified as available to the fleet for purchase (see Settings
              Tab for specifics).
              <br />
              <br />
              Each vehicle was scored on four components using 170+ attributes
              and second-by-second driving data and provided with an overall
              ezEV Score. Vehicles receiving a score of 85 or better are
              determined to be a good fit for replacement with the recommended
              EV. The ezEV Score is a weighted average of the four following
              components below.
            </p>
            <ul className="nrel-tool-list">
              <li>
                <span>Energy:</span> The daily driving was analyzed to determine
                the energy consumption for each fuel type (gas and electric) to
                determine how much energy is needed to complete all driving each
                day. Each day was then assessed to determine if an EV could meet
                the driving needs and only need to be charged at night. If the
                vehicle would require midday charging more frequently than about
                3 days each month, it will not receive a passing ezEV score and
                “Energy” will be listed in the reason column.
              </li>
              <li>
                <span>Economics:</span> The total cost of ownership (TCO) of
                each vehicle is analyzed, comparing the TCO if the vehicle’s
                driving was conducted in an EV and if it was conducted in a new
                ICE. These are compared over the vehicle lifetime. For any
                vehicle where the TCO for the best fit EV would exceed 5% more
                than the TCO in an ICE, the vehicle will not receive a passing
                ezEV score and “Economics” will be listed in the reason column.
              </li>
              <li>
                <span>Parking Location Frequency:</span> The location frequency
                of extended parking periods (typically overnight) for each
                vehicle is analyzed, identifying how often the vehicle parks at
                each location to determine if they will have regular access to
                charging at a single location. Vehicles that park at a single
                location for at least 75% of their extended parking periods will
                receive a passing score. If not, “Parking” will be listed in the
                reason column.
              </li>
              <li>
                <span>Data Availability:</span> While up to 12 months of data
                was collected for these vehicles, some did not have sufficient
                data available. For vehicles with very little data , “Not Enough
                Trip Data” is identified in the reason column to indicate that
                there was insufficient data available to accurately assess the
                vehicle’s driving.
              </li>
            </ul>
          </div>
          <div className="about-section">
            <h4 className="nrel-sub-header">
              How is this customized to my fleet?
            </h4>
            <p className="nrel-homeTab-description">
              Each fleet provided the following data and values which were input
              into Sawatch Labs’ model. The custom values are provided in the
              “Settings” tab in the tool.
            </p>
            <ul className="nrel-tool-list">
              <li>
                Telematics data for every vehicle analyzed, providing
                minute-by-minute driving data
              </li>
              <li>The purchase price for EVs and ICEs</li>
              <li>Gas price</li>
              <li>Electricity rate</li>
              <li>GHG emissions intensity for electricity generation</li>
              <li>Maintenance costs for EVs and ICEs</li>
              <li>Vehicle lifecycle</li>
              <li>Insurance</li>
              <li>Social cost of carbon</li>
            </ul>
          </div>
        </div>
        <div className="about-section" id="note">
          <h4 className="nrel-sub-header">Note:</h4>
          <p className="nrel-homeTab-description">
            This work was conducted under contract to the National Renewable
            Energy Laboratory, operated by Alliance for Sustainable Energy, LLC,
            for the U.S. Department of Energy (DOE) under Contract No.
            DE-AC36-08GO28308. Funding provided by U.S. Department of Energy
            Office of Energy Efficiency and Renewable Energy Vehicle
            Technologies Office. The views expressed herein do not necessarily
            represent the views of the DOE or the U.S. Government.
          </p>
        </div>
      </>
    );
}
