import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import Search from "./search";
import { processApiResponse } from "../../utility-components/ConformUnits";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { roundObjValues } from "../UtilityFunctions";
import { cdrVehicleClassesTableColumns } from "./TableHelpers";

const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

export default function SuperAdminVehicleClassesTable(props){

    const { apiURL, user } = props;
    const [vehicleClasses, setVehicleClasses] = useState(null);
    const [matchingClasses, setMatchingClasses] = useState(null);

    const skipPageResetRef = useRef(false);

    const columns = cdrVehicleClassesTableColumns(user.userSettings);

    const BoolTableCell = useCallback((props) =>
    <input
        className="swt-admin-table-input"
        type="checkbox"
        id={props.id} 
        name={props.name}
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        onChange={props.handleOnClick}
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
        disabled={props.disabled ? true : false}
    />,[]);

    useEffect(() => {
        const url = `${apiURL}getVehicleClasses`;
        fetch(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
            .then((resp) => {
                return resp.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    data.data.map((v) => {
                        v = processApiResponse(user.userSettings,v, false)
                        v = roundObjValues(v)
                        return v
                    })
                    setVehicleClasses(data.data);
                }
                else {
                    alert(SERVER_ERRORED_MESSAGE);
                }
            })
            .catch((error) => console.error("Error: " + error));
    }, [apiURL, user.token, user.userSettings]);

    
    const mappedVehicleClasses = useMemo(() => {
        if(!vehicleClasses || !matchingClasses) return null;
        if(vehicleClasses.length < 1)return [];

        const classes = JSON.parse(JSON.stringify(vehicleClasses));
        const matchingVehicleClasses = matchingClasses.map((c) => { return c.vehicle_class})


        return classes.filter((c) => {
            if(matchingVehicleClasses.indexOf(c.vehicle_class) < 0) return null;

            c.is_ld = <BoolTableCell id={c.vehilce_class} accessor={"is_ld"} bool={c.is_ld} disabled/>;
            c.vehicle_class = <div style={{whiteSpace: "nowrap"}} label={c.vehicle_class} title={c.vehicle_class}>{c.vehicle_class}</div>
            return c;
        })
    }, [vehicleClasses, matchingClasses]);

    return(
        <div>
            <S.TableHeaderContainer>
                <S.AdminTableExplainTextPrimary>List of all vehicle classes.</S.AdminTableExplainTextPrimary>
                <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                <S.SearchContainer>
                    <Search allValues={vehicleClasses} setMatchingValues={setMatchingClasses} skipPageResetRef={skipPageResetRef} disabled={vehicleClasses ? false : true}/>
                </S.SearchContainer>
            </S.TableHeaderContainer>
            {!mappedVehicleClasses && <Loading />}
            {mappedVehicleClasses && 
            <AdminTableView 
                columns={columns} 
                data={mappedVehicleClasses}
                skipPageResetRef={skipPageResetRef}
                stickyCols={1}
                noDataMessage={'No vehicle classes to display.'}
            />
            }
        </div>
    )
}