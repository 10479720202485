import React, { useEffect, useState } from "react";

import VehicleDropdown from "../dashboard-components/swt-vehicle-dropdown";
import Loading from "../dashboard-components/swt-loading";
import ChargeGraph from "./ChargeGraph";
import * as S from '../../../styles/core-styles/DevTools-styles';

const SOC_BUFFER = (1000*60*2); //milliseconds before and after trip where SoC reads are valid

export default function ChargeEventController(props) {

  const { beginDate, endDate, selectedVIN, vehicles, user, apiURL, db } = props;
  const [evs, setEvs] = useState(null);
  const [socArray, setSocArray] = useState(null);
  const [chargeArray, setChargeArray] = useState(null);
  const [kwhArray, setKwhArray] = useState(null);
  const [trips, setTrips] = useState(null);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedKm, setSelectedKm] = useState(0);
  const [selectedBlendedKwh, setSelectedBlendedKwh] = useState(0);
  const [selectedSnrKwh, setSelectedSnrKwh] = useState(0);
  const [selectedSynKwh, setSelectedSynKwh] = useState(0);
  const [selectedSoCDelta, setSelectedSoCDelta] = useState(0);

  useEffect(() => {
    let evs = [];
    let arr = [];
    vehicles.forEach((v) => {
      if (validVehicle(v)) arr.push(v);
      if (v.is_bev || v.is_phev) evs.push(v);
    });
    evs.sort(function(a, b){
      if (a.asset_id < b.asset_id)return -1;
      if (a.asset_id > b.asset_id)return 1;
      return 0;
    })
    setEvs(evs);
  }, [vehicles])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${apiURL}getChargeEvents?dbName=${db}&vin=${selectedVIN}&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}&modeled=true`;
    fetch(url, {
      signal,
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error") {
          window.alert('Error in getting API Charge Event data.')
          return console.error("Error in getting charge event data");
        }
        else {
          const arr = [];
          if(data && data.data){
            data.data.forEach((ce) =>{
              const o = {
                x0: new Date(ce.utc_start),
                x1: new Date(ce.utc_stop),
                y: 100,
                display: true,
                kwh: ce.kwh,
                duration: ce.duration,
                chargeId: ce.charge_id,
                modeled: ce.modeled,
                chargerLevel: ce.charger_level,
                regenReason: ce.regen_reason
              }
              arr.push(o);             
            });
          }
          setChargeArray(arr);
        }
      });
      return () => controller.abort();
  }, [apiURL, beginDate, db, endDate, selectedVIN, user.token]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${apiURL}getVehiclekWh?dbName=${db}&vin=${selectedVIN}&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}`;
    fetch(url, {
      signal,
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error") {
          alert("There was a server error during your request")
        }
        else {
          let arr = [];
          let bl = null;
          for (let i = 0; i < data.data.length; i++) {
            let d = new Date(data.data[i].ts);
            if(i === 0){
              bl = data.data[i];
              continue;
            }
            let delta = Math.abs(Math.min(data.data[i].snr_read - bl.snr_read, 100));
            let d0 = new Date(d.getTime() + 10000);
            let obj0 = { x: d, x0: d0, y: 1, y0: delta};
            if(d instanceof Date && !isNaN(d))arr.push(obj0)
            bl = data.data[i];
          }
          setKwhArray(arr);
        }
      });
      return () => controller.abort();
  },[apiURL, beginDate, endDate, db, selectedVIN, user.token]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${apiURL}getVehicleSoc?dbName=${db}&vin=${selectedVIN}&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}`
    fetch(url, {
      signal,
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error") {
          alert("Error in getting SOC data");
          return console.error("Error in getting SOC data");
        }
        else {
          let arr = [];
          for (let i = 0; i < data.data.length; i++) {
            let d = new Date(data.data[i].ts);
            let obj = { x: d, y: data.data[i].snr_read, size: 1 };
            arr.push(obj);
          }
          setSocArray(arr);
        }
      });
      return () => controller.abort();
  },[apiURL, db, selectedVIN, beginDate, endDate, user.token]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${props.apiURL}getTrips?ident=${props.db}&vin=${selectedVIN}&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}`;
    fetch(`${url}`, {
      signal,
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error" || !data || !data.data)
          return console.error("Error in the getting Trips API request");
        const r = [];
        data.data.forEach((v)=>{
          let obj = {
            id: v.trip_id,
            x0:  new Date(v.utc_start),
            x1: new Date(v.utc_stop),
            y: 100,
            display: true, 
            synKwh: v.syn_kwh,
            snrKwh: v.snr_kwh ? v.snr_kwh : "Not Reported",
            blendedKwh: v.blended_kwh,
            km: v.km,
            pctElec: v.pct_elec,
          }
          obj.duration = (obj.x1.getTime() - obj.x0.getTime())/1000/60;
          r.push(obj);
        })
        setTrips(r);
      });
      return () => controller.abort();
  },[selectedVIN, props.user.token, props.db, props.apiURL, beginDate, endDate]);

  const validVehicle = (v) => {
    if(v &&
        typeof v.vin !== "undefined" &&
        v.vin !== "undefined" &&
        v.year > 0 &&
        v.make !== "undefined" &&
        v.model !== "undefined")return true;
    return false;
  }
  const vehicle = vehicles.find((v)=>v.vin === selectedVIN);

  const calcSOCDelta = (tripArr) => {
    let ts0 = new Date().getTime();
    let ts1 = 0;
    let socd = 0;
    if(trips){
      tripArr.forEach((t) => {
        ts0 = Math.min(t.x0.getTime(), ts0);
        ts1 = Math.max(t.x1.getTime(), ts1);
      });
      const soc = socArray.filter((s) => s.x.getTime() >= (ts0 - SOC_BUFFER) && s.x.getTime() <= (ts1 + SOC_BUFFER));
      if(soc.length > 1)socd = soc[0].y - soc[soc.length-1].y;
    }
    setSelectedSoCDelta(socd);
  }

  useEffect(() => {
    if(trips && trips.length > 0 && selectedTrips.length > 0){
      const arr = trips.filter((t, idx) => selectedTrips.indexOf(idx) > -1);
      if(arr)calcSOCDelta(arr);
      else setSelectedSoCDelta(0);
      setSelectedKm(arr.reduce((a, c) => a + c.km, 0));
      setSelectedBlendedKwh(arr.reduce((a, c) => a + c.blendedKwh, 0));
      setSelectedSnrKwh(arr.reduce((a, c) => a + c.snrKwh, 0));
      setSelectedSynKwh(arr.reduce((a, c) => a + c.synKwh, 0));
      //setSelectedKm(arr.reduce((a, c) => a + c.km, 0));
    }
    if(trips && selectedTrips.length === 0){
      setSelectedKm(0);
      setSelectedBlendedKwh(0);
      setSelectedSnrKwh(0);
      setSelectedSynKwh(0);
      setSelectedSoCDelta(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips, selectedTrips]);

  if(!vehicle)return(<></>);

  return (
    <>
    <S.DevToolsContentContainer>
      <S.DevToolsResponsiveChargeToolsContainer>
        <S.DevToolsResponsiveChartControls>
          <S.DevToolsInputWrapper>
            <S.DevToolsInputLabel>Select Vehicle:</S.DevToolsInputLabel>
              <VehicleDropdown
                handleChange={(e) => props.setSelectedVIN(e.target.value)}
                vehicles={evs}
                selectedVIN={selectedVIN}
              />
            </S.DevToolsInputWrapper>

          <S.DevToolsInputWrapper>
            <S.DevToolsInputLabel>Select Date Range:</S.DevToolsInputLabel>
          <S.DevToolsResponsiveDatepickerContainer>
            <S.DevToolsDatepickers
              selected={beginDate}
              onChange={(date) => props.setBeginDate(date)}
              selectsStart
              startDate={beginDate}
              endDate={endDate}
              showMonthDropdown
              useShortMonthInDropdown
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, -7],
                  },
                },
              ]}
            />
            <S.DevToolsDatepickers
              selected={endDate}
              onChange={(date) => props.setEndDate(date)}
              selectsEnd
              endDate={endDate}
              showMonthDropdown
              useShortMonthInDropdown
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, -7],
                  },
                },
              ]}
            />
            </S.DevToolsResponsiveDatepickerContainer>
          </S.DevToolsInputWrapper>
        </S.DevToolsResponsiveChartControls>
        <S.DevToolsChartContainer>
          {(trips === null || socArray === null) ? (
            <Loading />
          ) : (
            <ChargeGraph
              vehicles={evs}
              evs={evs}
              socArray={socArray}
              chargeArray={chargeArray}
              trips={trips}
              kwhArray={kwhArray}
              beginDate={beginDate}
              endDate={endDate}
              selectedTrips={selectedTrips}
              setSelectedTrips={setSelectedTrips}
            />
          )}
      </S.DevToolsChartContainer>
      </S.DevToolsResponsiveChargeToolsContainer>
    </S.DevToolsContentContainer>
      <S.DevToolsChartExplorerTextContainer>
        <S.DevToolsChartExplorerTextColumn>
          <S.DevToolsChartExplorerText>Trips: {selectedTrips.length}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>YMM: {vehicle.year} {vehicle.make} {vehicle.model}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Battery kWh: {vehicle.battery_kwh}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>KM in Selection: {selectedKm.toFixed(1)}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>KM/Blended kWh: {selectedBlendedKwh ? (selectedKm/selectedBlendedKwh).toFixed(1) : 0}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>SoC Delta %: {selectedSoCDelta.toFixed(1)}</S.DevToolsChartExplorerText>        
        </S.DevToolsChartExplorerTextColumn>
        <S.DevToolsChartExplorerTextColumn>
          <S.DevToolsChartExplorerText>Implied Battery Capacity(blended): {selectedSoCDelta ? (selectedBlendedKwh/(selectedSoCDelta/100)).toFixed(1) : 0}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Implied Battery Capacity(sensor): {selectedSoCDelta ? (selectedSnrKwh/(selectedSoCDelta/100)).toFixed(1) : 0}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Implied Battery Capacity(modeled): {selectedSoCDelta ? (selectedSynKwh/(selectedSoCDelta/100)).toFixed(1) : 0}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Blended kWh in Selection: {selectedBlendedKwh.toFixed(1)}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Sensor kWh in Selection: {selectedSnrKwh.toFixed ? selectedSnrKwh.toFixed(1) : "-"}</S.DevToolsChartExplorerText>
          <S.DevToolsChartExplorerText>Modeled kWh in Selection: {selectedSynKwh.toFixed(1)}</S.DevToolsChartExplorerText>        
        </S.DevToolsChartExplorerTextColumn>
      </S.DevToolsChartExplorerTextContainer>

      <S.DevToolsActionButton onClick={()=> {setSelectedTrips([])}}>Clear Selection</S.DevToolsActionButton>
    </>
  );
}

function formatAPIDate(d) {
  return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
}