import React, { useState, useMemo, useEffect } from "react";
import { Route, Switch, NavLink, Redirect, BrowserRouter as Router } from "react-router-dom";
import Landing from "./fueln-components/Landing";
import WeeklyEventsView from "./fueln-components/WeeklyEventsView";
import MapView from "./fueln-components/MapView";
import DetailTableView from "./fueln-components/DetailTableView";
import Assumptions from "./fueln-components/Assumptions";
import  Loading  from '../components/fueln-components/Loading';
import "../component-css/fueln-stylesheets/swt-fueln.css";
import "mapbox-gl/dist/mapbox-gl.css";
import * as S from "../styles/fueln-styles/Containers";

type ApiRequest = {
  apiURL: string;
  dbName: string;
  beginDate: any;
  endDate: any;
  user: any;
  group: any;
  groups: any;
  selectedVehicleClasses: string[];
}

export default function Fueln({apiURL, dbName, user, basename}: any){
  const [beginDate, setBeginDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [events, setEvents] = useState(null);
  const [stats, setStats] = useState(null);
  const [group, _setGroup] = useState(null);
  const [groups, _setGroups] = useState(null);
  const [vehicleClasses, _setVehicleClasses] = useState([]);
  const [selectedVehicleClasses, setSelectedVehicleClasses] = useState(["null"]);

  const req = useMemo(() => {
    return {
      user: user,
      group: group,
      groups: groups,
      apiURL: apiURL,
      dbName: dbName,
      beginDate: beginDate,
      endDate: endDate,
      selectedVehicleClasses: selectedVehicleClasses
    }
  }, [dbName, apiURL, beginDate, endDate, user, group, groups, selectedVehicleClasses]);

  useEffect(() => {
    _getBounds(req, function(bounds: any){setBeginDate(bounds.beginDate);setEndDate(bounds.endDate);});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (vehicleClasses.length < 1) _getVehicleClasses(req, function (data: any) { setSelectedVehicleClasses(data); _setVehicleClasses(data.slice()) });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(beginDate && endDate)_getFuelingEvents(req, function(d: any){setEvents(d)});
    // eslint-disable-next-line
  }, [req]);

  useEffect(() => {
    if(beginDate && endDate)_getFuelStats(req, function(d: any){setStats(d)});
    // eslint-disable-next-line
  }, [req]);
  
  useEffect(() => {
    const handleGroups = (groups: any) => { 
      if(groups.length > 0)_setGroup(groups[0].id); 
      _setGroups(groups) 
    };
    _getGroups(req, handleGroups);
    // eslint-disable-next-line
  },[]);

  const FuelnLink = (props: any) => {
    return <NavLink to={`/${props.id}`} id={props.id}>{props.label}</NavLink>;
  };

  if(events === null || endDate === null || beginDate === null)return(<S.LoadingContainer><Loading /></S.LoadingContainer>);

  return(
    <Router basename={basename}>
      <S.NavContainer className={"swt-fueln-nav-element"}>
        <FuelnLink id={'fueln/landing'} label={'Summary'} />
        <FuelnLink id={'fueln/weekly'} label={'Weekly Summary'} />
        <FuelnLink id={'fueln/map'} label={'Map View'} />
        <FuelnLink id={'fueln/detail'} label={'Detail View'} />
        <FuelnLink id={'fueln/assumptions'} label={'Assumptions and Notes'} />
      </S.NavContainer>
    <S.AppContainer>
      <Switch>
          <Route exact path="/fueln">
            <Redirect to={`/fueln/landing`} />
          </Route>
          <Route exact path="/fueln/landing">
            <Landing 
              beginDate = {beginDate}
              endDate = {endDate}
              setBeginDate = {setBeginDate}
              setEndDate = {setEndDate}
              events = {events}
              stats = {stats}
              groups = {groups}
              group = {group}
              handleGroupChange = {_setGroup}
              vehicleClasses = {vehicleClasses}
              selectedVehicleClasses = {selectedVehicleClasses}
              setSelectedVehicleClasses = {setSelectedVehicleClasses}
            />
          </Route>
          <Route exact path="/fueln/assumptions">
            <Assumptions />
          </Route>
          <Route exact path="/fueln/weekly">
            <WeeklyEventsView 
              beginDate = {beginDate}
              endDate = {endDate}
              setBeginDate = {setBeginDate}
              setEndDate = {setEndDate}
              events = {events}
              groups = {groups}
              group = {group}
              handleGroupChange = {_setGroup}
              vehicleClasses = {vehicleClasses}
              selectedVehicleClasses = {selectedVehicleClasses}
              setSelectedVehicleClasses = {setSelectedVehicleClasses}
            />
          </Route>
          <Route exact path="/fueln/map">
            <MapView 
              beginDate = {beginDate}
              endDate = {endDate}
              setBeginDate = {setBeginDate}
              setEndDate = {setEndDate}
              events = {events}
              groups = {groups}
              group = {group}
              handleGroupChange = {_setGroup}
              vehicleClasses = {vehicleClasses}
              selectedVehicleClasses = {selectedVehicleClasses}
              setSelectedVehicleClasses = {setSelectedVehicleClasses}
            />
          </Route>
          <Route exact path="/fueln/detail">
            <DetailTableView 
              beginDate = {beginDate}
              endDate = {endDate}
              setBeginDate = {setBeginDate}
              setEndDate = {setEndDate}
              events = {events}
              stats = {stats}
              groups = {groups}
              group = {group}
              handleGroupChange = {_setGroup}
              vehicleClasses = {vehicleClasses}
              selectedVehicleClasses = {selectedVehicleClasses}
              setSelectedVehicleClasses = {setSelectedVehicleClasses}
            />
          </Route>
      </Switch>
    </S.AppContainer>
  </Router>
  );
}

function _filterForSelectedGroup(req: ApiRequest, data: any){
  let arr: any[] = [];
  if (!req.group || !req.groups)arr = data;
  else {
    const g = req.groups.filter((r: any) => {return r.id === req.group});
    const vins = g[0].vehicles.map((v: any) => {return v.vin});
    data.forEach((e: any) => {
      if(vins.indexOf(e.vin) > -1){arr.push(e);}
    });
  }
  return arr;
}

function _filterForSelectedClasses(req: ApiRequest, data: any) {
  let arr = [];

  //if the component returned a "false" empty list(call has not finished but nothing is selected)
  if (req.selectedVehicleClasses[0] === "null") {
    arr = data;
  }
  //if there are zero or more selections
  else {
    data.forEach((e: any) => {
      if (e.vehicle_class === null || e.vehicle_class === '') e.vehicle_class = "Undefined";
      if (req.selectedVehicleClasses.indexOf(e.vehicle_class) > -1) {
        arr.push(e);
      }
    });
  }
  return arr;
}

function _getFuelingEvents(req: ApiRequest, cb: any) {
  fetch(`${req.apiURL}getFuelEventsForDates?dbName=${req.dbName}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}&`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {

      if (data && data.data.length > 0) {
        let arr = data.data;
        arr = _filterForSelectedGroup(req, arr);
        arr = _filterForSelectedClasses(req, arr);
        if (cb) cb(arr);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getFuelStats(req: ApiRequest, cb: any){
  //this is a duplication... the above function was written predating
  //the fuel events table
  fetch(`${req.apiURL}getFuelEvents?dbName=${req.dbName}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}&`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {

      if (data && data.data.length > 0) {
        const d = data.data;
        let arr = _filterForSelectedClasses(req, d);
        arr = _filterForSelectedGroup(req, arr);
        if (cb) cb(arr);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getBounds(req: ApiRequest, cb: any) {

  fetch(`${req.apiURL}getBounds?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      //return the last day of data and 60 days prior as bounds
      if (data && data.data.length > 0) {
        const e = new Date(data.data[0].max);
        let b = new Date('2021-08-11'); //using for the demo
        //let b = new Date(data.data[0].max);
        //b.setDate(b.getDate() - 90);
        if (cb) cb({ beginDate: b, endDate: e });
      }
    });
}

function _getVehicleClasses(req: ApiRequest, cb: any) {
  fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        let arr = data.data.map((e: any) => {
          if (e.vehicle_class === '') e.vehicle_class = 'Unknown';
          if (e.vehicle_class === null) e.vehicle_class = 'Undefined';
          return e.vehicle_class
        });
        const idx = arr.indexOf('Unknown');
        if (idx > -1) {
          const o = arr[idx];
          arr.splice(idx, 1);
          arr.push(o);
        }
        if (cb) cb(arr);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getGroups(req: ApiRequest, cb: any) {
  const url = `${req.apiURL}getGroups?dbName=${req.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        const idx = data.data.findIndex((g: any) => g.id === "GroupCompanyId");
        if (idx > -1) {
          const obj = data.data[idx];
          data.data.splice(idx, 1);
          obj.name = "All Vehicles";
          data.data.unshift(obj);
        };
        cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _formatDateParams(date: any) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}