import React from 'react'
import { StatView } from '@sawatchlabs/swt-components'
import {FormatSummaryData} from './UtilityFunctions';

export default function Detail({ emissionsData }) {
    return (
        <div className="swt-emit-landing-boxes-wrapper">
            <StatView values={{value: FormatSummaryData('vehicles', emissionsData.vehicles), caption:"Total Vehicles"}} />
            <StatView values={{value: FormatSummaryData('miles', emissionsData.miles), caption:"Miles"}}  />
            <StatView values={{value: FormatSummaryData('mpg', emissionsData.mpg), caption:"Average MPG"}} />
            <StatView values={{value: FormatSummaryData('ice', emissionsData.ice), caption:"Gasoline Vehicles"}} />
            <StatView values={{value: FormatSummaryData('diesel', emissionsData.diesel), caption:"Diesel Vehicles"}} />
            <StatView values={{value: FormatSummaryData('ev', emissionsData.bev + emissionsData.phev), caption:"Electric Vehicles"}}/>
        </div>
    )
}
