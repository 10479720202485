import React from "react"
import * as S from '../../styles/fueln-styles/StatBox-styles'

type StatBoxProps = {
    value: number | string | undefined,
    subvalue?: string | undefined,
    subtitle: string | undefined
    hoverHighlight?: boolean
}

export default function StatBox({ value, subtitle, subvalue, hoverHighlight = true }: StatBoxProps) {
    return (

        <S.StatBoxOuter highlight={hoverHighlight}>
            <S.StatBoxValue>{value}<S.StatBoxSubvalue>{subvalue}</S.StatBoxSubvalue></S.StatBoxValue>
            <S.StatBoxCaption>{subtitle}</S.StatBoxCaption>
        </S.StatBoxOuter>
    )
}