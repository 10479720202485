import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setClassName } from './TableHelperFunctions';
import { useTable, useSortBy, usePagination } from 'react-table';
import arrowDown from '../../images/arrow_desc_blk.png';
import arrowUp from '../../images/arrow_asc_blk.png';
import arrowUnsel from '../../images/arrow_unselected_gry.png';
import checkboxSelectedHoverImg from '../../images/checkbox_selected_hover.png';
import checkboxUnSelectedHoverImg from '../../images/checkbox_unselected_hover.png';

function Table({
  columns,
  data,
  handleClick,
  setMapCenter,
  handleToggleClick,
  sortType
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
        sortBy: [sortType]
      }
    },
    useSortBy,
    usePagination
  );
  const [locTableToggle, setLocTableToggle] = React.useState('selected');
  const [vclTableToggle, setVclTableToggle] = React.useState('selected');

  useEffect(() => {
    let selected = !data.some(row => row.selected === 'unselected');
    if (selected) {
      // eslint-disable-next-line no-sequences
      return setLocTableToggle('selected'), setVclTableToggle('selected');
    } else {
      // eslint-disable-next-line no-sequences
      return setLocTableToggle('unselected'), setVclTableToggle('unselected');
    }
  }, [data]);

  const checkBoxHoverIcon = (value) => {
    return value === 'selected' 
      ? checkboxSelectedHoverImg 
      : checkboxUnSelectedHoverImg 
  }

  const toggleCheckBoxHoverIcon = (value) => {
    if(value === 'vcl'){
      return vclTableToggle === 'selected' 
        ? checkboxSelectedHoverImg
        : checkboxUnSelectedHoverImg
    }
    else{
      return locTableToggle === 'selected' 
        ? checkboxSelectedHoverImg
        : checkboxUnSelectedHoverImg
    }
  }

  function renderSpecialtyCells(cell, row) {
    if (cell.column.id === 'selected') {
      let value = cell.render('Cell');
      let v = value.props.cell.value;
      return (
        <td
          className={setClassName(cell, row)}
          {...cell.getCellProps()}
          onClick={() => handleClick(row.original.uid, row.original)}
        >
          <img
            src={checkBoxHoverIcon(v)}
            alt={`${v}`}
            className="sftd-table-radio"
          />
        </td>
      );
    }
    if (cell.column.id === 'btn')
      return (
        <td className={setClassName(cell, row)} {...cell.getCellProps()}>
          <Link
            exact="true"
            className="table-link"
            to={{
              pathname: '/shifted/singleVehicle',
              // Creating an object to be consumed by single vcl page
              state: {
                vin: row.original.uid
              }
            }}
          >
            {cell.render('Cell')}
            {/* CAR ICON */}
            {/* <div className="sftd-vcl-btn" /> */}
            <p className="sftd-singleVcl-details">Details</p>
          </Link>
        </td>
      );
  }

  function addPagination(data) {
    if (data.length > 0) {
      if (data[0].type === 'vehicle') {
        return (
          <div className="pagination">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="sftd-prvs-btn"
            >
              {'<'}
            </button>
            <span className="sftd-pagination-txt">
              {pageIndex + 1} of {pageOptions.length}
            </span>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="sftd-next-btn"
            >
              {'>'}
            </button>
          </div>
        );
      }
    }
  }

  return (
    <>
      <table {...getTableProps()} className="sftd-table">
        <thead className="sftd-table-header">
          {headerGroups.map(headerGroup => (
            <tr key="header" className="sftd-table-text">
              {headerGroup.headers.map(column => (
                <th key={column.id} className={column.id}>
                  <div {...column.getSortByToggleProps()}>
                    {column.Header === 'loc' || column.Header === 'vcl' ? (
                      <img
                        onClick={() => {
                          handleToggleClick(column);
                          column.Header === 'vcl'
                            ? setVclTableToggle(
                                vclTableToggle === 'unselected'
                                  ? 'selected'
                                  : 'unselected'
                              )
                            : setLocTableToggle(
                                locTableToggle === 'unselected'
                                  ? 'selected'
                                  : 'unselected'
                              );
                        }}
                        src={toggleCheckBoxHoverIcon(column.Header)}
                        alt={`table toggle`}
                        className="sftd-table-radio"
                      />
                    ) : (
                      column.render('Header')
                    )}
                    <span className="swt-sort-arrows">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={arrowDown}
                            className="swt-sort-arrow-desc"
                            alt="Column sorted in descending order"
                          />
                        ) : (
                          <img
                            src={arrowUp}
                            className="swt-sort-arrow-asc"
                            alt="Column sorted in ascending order"
                          />
                        )
                      ) : (
                        <img
                          src={arrowUnsel}
                          className="swt-sort-arrow-both"
                          alt="Unsorted column"
                        />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="sftd-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="sftd-table-row"
                id={row.original.uid}
                {...row.getRowProps()}
                // onClick={() =>
                //   row.original.type === 'location'
                //     ? setMapCenter(row.original)
                //     : handleClick(row.original.uid, row.original)
                // }
              >
                {row.cells.map(cell => {
                  if (
                    cell.column.id === 'btn' ||
                    cell.column.id === 'selected'
                  ) {
                    return renderSpecialtyCells(cell, row);
                  } else {
                    return (
                      <td
                        className={setClassName(cell, row)}
                        onClick={() =>
                          row.original.type === 'location'
                            ? setMapCenter(row.original)
                            : handleClick(row.original.uid, row.original)
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 29 ? addPagination(data) : null}
    </>
  );
}

export default class SftdTable extends Component {
  handleClick = (address, row) => {
    var param;
    if (row.type === 'location') param = row.uid;
    if (row.type === 'vehicle') param = row;
    this.props.handleChange(param);
  };

  render() {
    return (
      <Table
        columns={this.props.columns}
        data={this.props.data}
        handleClick={this.handleClick}
        setMapCenter={this.props.setMapCenter}
        handleToggleClick={this.props.handleToggleClick}
        sortType={this.props.sortType}
      />
    );
  }
}
