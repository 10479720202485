function convertToShortDate(date) {
  var currentDate = new Date(date);
  return currentDate.toLocaleDateString("en-us");
}

const installHeaders = [
  {
    Header: "Department Name:",
    accessor: "displayGroupName",
    sortType: "basic",
  },
  {
    Header: "Target Start Date",
    accessor: "targetStartDate",
    sortType: "basic",
  },
  {
    Header: "Target End Date",
    accessor: "targetEndDate",
    sortType: "basic",
  },
  {
    Header: "Total Vehicles",
    accessor: "targetInstalls",
    sortType: "basic",
  },
  {
    Header: "# Installed",
    accessor: "installed",
    sortType: "basic",
  },
  {
    Header: "# Heartbeats",
    accessor: "heartbeatsShow",
    sortType: "basic",
  },
  {
    Header: "# No Shows",
    accessor: "noShow",
    sortType: "basic",
  },
  {
    Header: "# Pending",
    accessor: "pendingInstallsTable",
    sortType: "basic",
  },
  {
    Header: "First Install",
    accessor: "displayStart",
    sortType: "basic",
  },
  {
    Header: "Latest Install",
    accessor: "displayEnd",
    sortType: "basic",
  },
];

const heartbeatTableHeaders = [
  {
    Header: "Asset ID",
    accessor: "assetId",
    sortType: "basic",
  },
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    sortType: "basic",
  },
  {
    Header: "VIN",
    accessor: "vin",
    sortType: "basic",
  },
  {
    Header: "Installer Company",
    accessor: "installerCompany",
    sortType: "basic",
  },
  {
    Header: "Installer Name",
    accessor: "installerName",
    sortType: "basic",
  },
  {
    Header: "Date Installed",
    accessor: "dateInstalledShow",
    sortType: "basic",
  },
  {
    Header: "Heartbeat",
    accessor: "heartbeatsShow",
    sortType: "basic",
  },
  {
    Header: "Possible Isssues",
    accessor: "possibleIssues",
    sortType: "basic",
  },
];

const heartbeatCsvHeaders = [
  { label: "Asset ID", key: "assetId" },
  { label: "Serial Number", key: "serialNumber" },
  { label: "VIN", key: "vin" },
  { label: "Installer Company", key: "installerCompany" },
  { label: "Installer Name", key: "installerName" },
  { label: "Date Installed", key: "dateInstalledShow" },
  { label: "Possible Issues", key: "possibleIssues" },
  { label: "Heartbeat", key: "heartbeatsShow" },
];
export default function StandardizeTableData(tableData, target) {
  if (target === "fleet") {
    tableData.forEach((v) => {
      const installedDevices =
        v.installedApi > v.installedUser ? v.installedApi : v.installedUser;
      const pendingInstalls =
        isNaN(v.targetInstalls - installedDevices) ||
        v.targetInstalls - installedDevices < 0
          ? 0
          : v.targetInstalls - installedDevices;
      v["displayGroupName"] =
        v.groupNameUser === null ? v.groupName : v.groupNameUser;
      v["installed"] = installedDevices;
      v["pendingInstallsTable"] = pendingInstalls;
      v["heartbeatsShow"] = v.installedApi - v.missingHeartbeats;
      v["displayStart"] = convertToShortDate(v.startDate);
      v["displayEnd"] = convertToShortDate(v.lastInstall);
      v["targetStartDate"] =
        v.targetStart === null ? "None" : convertToShortDate(v.targetStart);
      v["targetEndDate"] =
        v.targetEnd === null ? "None" : convertToShortDate(v.targetEnd);
    });
    return { data: tableData, columns: installHeaders };
  }
  if (target === "group") {
    tableData.forEach((v) => {
      v["dateInstalledShow"] = convertToShortDate(v.installDateUtc);
      v["heartbeatsShow"] =
        v.heartbeat !== null ? convertToShortDate(v.heartbeat) : "None";
    });
    return { data: tableData, columns: heartbeatTableHeaders };
  }
  if (target === "heartbeatsCsv") {
    let columns = heartbeatTableHeaders;
    if (target === "heartbeatsCsv") columns = heartbeatCsvHeaders;
    return { data: tableData, columns: columns };
  }
}
