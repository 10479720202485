import React from 'react';
import OutsideAlerter from './OutsideClickNotifier';
export default function ClassSelector(props) {
  const [showModal, setShowModal] = React.useState(false);
  const { vehicleClasses  } = props;

  function handleChange(i) {
    if (i === 'all') {
      handleAllSelector();
    }
    vehicleClasses.forEach((c) => {
      if(c.vehicleClass === i.vehicleClass)c.selected = c.selected ? false : true;
    });
    props.handleClassRadioBtn(vehicleClasses, 'vehicleClasses');
  }

  function handleAllSelector() {
    vehicleClasses.forEach((c) => {
      c.selected = true;
    });
    props.handleClassRadioBtn(vehicleClasses, 'vehicleClasses');
  }

  function all() {
    let checked = true;
    vehicleClasses.forEach((c) => {
      if(c.selected !== true)checked = false;
    });
    return checked;
  }

  const VehicleClassInput = (c) =>{
    return(
      <div key={c.vehicleClass}>
        <input
          className="sftd-radio-custom"
          id={`vcl_${c.vehicleClass}`}
          key={c.vehicleClass}
          type="radio"
          checked={c.selected}
          value={c.vehicleClass}
          onClick={() => handleChange(c)}
          onChange={function(e){}}
        />
        <label id="label">{c.vehicleClass}</label>
        <br />
      </div>
    )
  }

  return (
    <OutsideAlerter handleClick={() => setShowModal(false)}>
      <div
        className="sftd-class-selector"
        id={showModal ? 'clicked' : 'unclicked'}
      >
        <h3
          onClick={() => setShowModal(!showModal)}
          className="sftd-class-title"
        >
          Class
        </h3>
        {showModal ? (
          <div className="sftd-class-modal">
            <form onChange={silenceLinter}>
              {
                vehicleClasses.map((c) => {
                  return VehicleClassInput(c);
                })
              }
              <input
                className="sftd-radio-custom"
                id="all"
                key="all"
                type="radio"
                checked={all()}
                value={'all'}
                onClick={() => handleChange('all')}
                onChange={function(e){}}
              />
              <label id="label">All</label>
            </form>
          </div>
        ) : null}
      </div>
    </OutsideAlerter>
  );
  function silenceLinter() {
    return null;
  }
}
