import React, {useState, useEffect, useRef} from "react";
import TableView from "./speedn-table-view";
import { tableDateFormat } from "./speedn-csv-helper-functions";
import { vehicleIdDisplay } from "./speedn-table-helper-functions";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { Marker } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';


const DEFAULT_MAP_STYLE = "mapbox://styles/mapbox/streets-v11";
const SATELLITE_MAP_STYLE = "mapbox://styles/mapbox/satellite-v9";
const MIN_ZOOM = 2;
const MAX_ZOOM = 18;
const INIT_ZOOM = 10;



export default function SpeednMap(props){

  const { events, filter, isSummary, beginDate, endDate, group, vehicleClasses, excessSpeed, minSpeed, minDuration, dbDisplayName } = props;
  const secrets = require('../../settings.json');
  mapboxgl.accessToken = secrets.mapboxKey;

  const mapContainer = useRef(null);
  const map = useRef(null)
  const [showSatellite, setShowSatellite] = useState(false)
  const [zoom, setZoom] = useState(props.zoom ? props.zoom : INIT_ZOOM)
  const [pitch, setPitch] = useState(0);
  const [bearing, setBearing] = useState(0);
  const [markers, setMarkers] = useState([])

  const bins = [
    {min: 80, max: 89},
    {min: 90, max: 99},
    {min: 100, max: 1000}
  ];

  const ranges = [
    {min: 10, max: 15},
    {min: 16, max: 20},
    {min: 21, max: 25},
    {min: 26, max: 30},
    {min: 31, max: 1000}
  ]

  const tableColumns = [
    {Header: "Speed Range\n(mph)", accessor: "mph", sortType: "basic"},
    {Header: "10-15\n(mph)", accessor: "10", sortType: "basic"},
    {Header: "16-20\n(mph)", accessor: "16", sortType: "basic"},
    {Header: "21-25\n(mph)", accessor: "21", sortType: "basic"},
    {Header: "26-30\n(mph)", accessor: "26", sortType: "basic"},
    {Header: "Over 30\n(mph)", accessor: "31", sortType: "basic"},
    {Header: "Total", accessor: "total", sortType: "basic"},
  ];

  let supplementalData = props.supplementalData;
 
  useEffect(() => {
    if(!events || events.length < 1 ) return;
    let mapStyle = showSatellite ? SATELLITE_MAP_STYLE : DEFAULT_MAP_STYLE;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      customAttribution: '',
      attributionControl: false,
      zoom: zoom,
      center: _getMapCenter(events),
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      pitch: pitch,
      bearing: bearing
    })

    map.current.addControl(new mapboxgl.NavigationControl())
    map.current.addControl(new mapboxgl.ScaleControl({maxWidth: 100, unit: 'imperial'}));

    if(map)map.current.on('style.load', () => {
        addTerrain(map)
        addSky(map)
      })
    return () => map.current.remove()
// eslint-disable-next-line
},[showSatellite])


useEffect(() => {
  if(map.current) {
      map.current.on('move', () => {
          setZoom(map.current.getZoom().toFixed(2));
          setPitch(map.current.getPitch());
          setBearing(map.current.getBearing());
      })
  }        
})

useEffect(() => {
  if(map.current && events && events.length > 0) {
    let newMarkers = []
    //Remove old markers before adding new markers.
    if(markers.length > 0) {
      markers.map((m) => {
        return m.remove()
      })
    }
      const speedBounds = _getSpeedBounds(events);
      events.forEach((e) => {
      let cords = [e.longitude, e.latitude]
      let mapMarker = addMarker(map, cords, e, interpolateColor("#fbd72b", "#f9484a", (e.excess / speedBounds.max)), 'eventData', '15px')
      newMarkers.push(mapMarker)
    })
    setMarkers(newMarkers)
  }
  if(supplementalData && supplementalData.length > 0) {
    supplementalData.forEach((e) => {
      let cords = [e.longitude, e.latitude]
      addMarker(map, cords, e, '#000000', 'supplementalData', '10px')
    })
  }
    // eslint-disable-next-line
},[events, supplementalData, showSatellite])

  function eventsHistogram(events){
    const arr = [];
    const totals = {mph: 'Totals', total: 0}
    bins.forEach((b) =>{
      const o = {total: 0};
      if(b.max < 100)o.mph = `${b.min}-${b.max}`;
      else o.mph = `${b.min}`;
      const eventsInBin = events.filter((f) => parseInt(f.mph) >= b.min && parseInt(f.mph) <= b.max);
      ranges.forEach((r)=>{
        if(typeof r.total === 'undefined')r.total = 0;
        const a = eventsInBin.filter((f) => parseInt(f.excessMph) >= r.min && parseInt(f.excessMph) <= r.max);
        o[`${r.min}`] = a.length;
        o.total += a.length;
        r.total += a.length;
      });
      arr.unshift(o);
    });
    ranges.forEach((r) => {totals[`${r.min}`] = r.total;totals.total += r.total});
    arr.unshift(totals);
    return arr;
  }

  function _getSpeedBounds(events){
      Array.min = function(array){
        return Math.min.apply( Math, array );
      }
      Array.max = function(array){
        return Math.max.apply( Math, array );
      }
      const r = events.map(pl => {return pl.excess});
      return {min: Array.min(r), max: Array.max(r)};
  }

  function _getMapCenter(events){
    if(events.length < 1) return
    let lat = 0;
    let lon = 0;
    events.forEach((d) => {
      lat += d.latitude;
      lon += d.longitude;
    });
    return [lon/events.length, lat/events.length]
  }


  let cn = "speedn-map-container"
  if (props.isDetail)cn = "speedn-detail-map-container";
  if (props.isIncident)cn = "speedn-incident-map-container";

  let tableData = [];
  let incidentCountCsvFilename = `sawatch-labs-speeding-incident-count-report-${dbDisplayName.replaceAll(" ", "-")}-${(new Date()).toLocaleDateString().replaceAll('/', '-')}`;

  if(typeof events === "undefined" || events.length < 1 || events === null){
    return(
    <div>
      <p className="speedn-no-data-msg">No map data to display. Try adjusting date range or filters.</p>
      {isSummary &&
        <div className = "speedn-above-80-table">
          <TableView 
            columns={tableColumns}
            data={tableData}
            filter={filter}
            beginDate={beginDate}
            endDate={endDate}
            group={group}
            vehicleClasses={vehicleClasses}
            excessSpeed={excessSpeed}
            minSpeed={minSpeed}
            minDuration={minDuration}
            csvFilename={incidentCountCsvFilename}
            colType={() => {return "speedn-table-header"}}
            highlight={() => {}}
            handleOnClick={() => {}}
            handleSort={() => {}}
            nolink={true}
            dbDisplayName={dbDisplayName}
            tableType={'Speeding Incident Count'}
          />
        </div>
      }
    </div>)
  }
  if(typeof supplementalData === "undefined"){
    supplementalData = [];
  }
  if(isSummary)tableData = eventsHistogram(events);
return(
    <div>
       <div className='speedn-map-wrapper'>
            <div ref={mapContainer} className={cn} />
            <div className="speedn-map-satellite-control">
                <div>Satellite View:</div>
                <input type='checkbox' checked={showSatellite} onChange={() => {setShowSatellite(!showSatellite)}}/>
            </div>
        </div>
      {isSummary &&
        <div className = "speedn-above-80-table">
          <TableView 
            columns={tableColumns}
            data={tableData}
            filter={filter}
            colType={() => {return "speedn-table-header"}}
            highlight={() => {}}
            handleOnClick={() => {}}
            handleSort={() => {}}
            nolink={true}
            beginDate={beginDate}
            endDate={endDate}
            group={group}
            vehicleClasses={vehicleClasses}
            excessSpeed={excessSpeed}
            minSpeed={minSpeed}
            minDuration={minDuration}
            csvFilename={incidentCountCsvFilename}
            dbDisplayName={dbDisplayName}
            tableType={'Speeding Incident Count'}
          />
        </div>
      }
    </div>);
}


function interpolateColor(c0, c1, f){
  c0 = c0.replace('#', '');
  c1 = c1.replace('#', '');
  c0 = c0.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * (1-f))
  c1 = c1.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * f)
  let ci = [0,1,2].map(i => Math.min(Math.round(c0[i]+c1[i]), 255))
  ci = ci.reduce((a,v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
  return(`#${ci}`)
}

const addMarker = (m, cords, data, color, type, markerSize) => {
  const p = new mapboxgl.Popup({offset: [0, 0], className: 'speedn-map-marker-popups'}).setHTML(markerHTML(data, type));
  const el = document.createElement('div');
  el.style.backgroundColor = `${color}50`;
  el.style.borderRadius = "20px";
  el.style.width = markerSize;
  el.style.height = markerSize;
  el.style.borderColor = color;
  el.style.borderWidth = '3px';
  el.style.borderStyle = 'solid';
  el.style.cursor = "Pointer";
  const mark = new Marker(el)
    .setLngLat(cords)
    .setPopup(p)
    .addTo(m.current)
  return mark;
}

const addSky = (map) => {
  if(!map.current.getLayer('sky')){
      map.current.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
              'sky-type': 'atmosphere',
              'sky-atmosphere-sun': [0.0, 0.0],
              'sky-atmosphere-sun-intensity': 5
              }
        });
  };
}

const addTerrain = (map) => {
  if(!map.current.getSource('mapbox-dem')){
      map.current.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': MAX_ZOOM,
          'minzoom': MIN_ZOOM
      });
  }
  // add the DEM source as a terrain layer with exaggerated height
  map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2 });
}

const markerHTML = (data,type) => {
  if (type === 'supplementalData') {
  return (
    `
      <span><b>Asset Id:</b> ${vehicleIdDisplay(data)}</span>
      <br>
      <span><b>Time:</b> ${tableDateFormat(data.ts)}</span>
      <br>
      <span><b>Vehicle Speed:</b> ${data.mph} mph</span>
      <br>
    `
  )
  } else {
    return(
      `
      <span><b>Asset Id:</b> ${vehicleIdDisplay(data)}</span>
      <br>
      <span><b>Time:</b> ${tableDateFormat(data.ts)}</span>
      <br>
      <span><b>Vehicle Speed:</b> ${data.mph} mph</span>
      <br>
      <span><b>Posted Speed:</b> ${data.postedMph} mph</span>
      <br>
      <span><b>Excess Speed:</b> ${data.excessMph} mph</span>
      `
    )
  }
}