//This file is a .js because react-loading has no type library I can use to make it compatible with TS. 
import React from 'react';
import ReactLoading from 'react-loading';

const Loading = (props) => (
    <ReactLoading
        className={'swt-ezio-loading'}
        type={'bars'}
        color={'rgba(182, 174, 174, 0.6)'}
        width="200px"
        viewBox="0 0 2 2"
        height="2"
    />
);
export default Loading;
