import { normalizeString } from './DataFormatter';
export default function selectGraphData(input, type, data) {
  if (!data) return;
  if (type === 'singleVcl') {
    let color = 'rgba(127, 205, 145, 0.9)';
    let hoverColor = 'rgba(109, 173, 124, 0.9)';
    let xData = data.map(d => {
      return normalizeString(d.mnth);
    });
    switch (input) {
      case 'Days':
        let daysData = data.map(d => {
          return parseInt(d.dys_count);
        });
        return {
          labels: xData,
          data: daysData,
          title: 'Days Driven',
          label: 'Vehicle Days',
          xAxis: 'Months',
          yAxis: 'Number of Days Driven',
          color: color,
          hoverColor: hoverColor,
        };
      case 'Trips':
        let trips = data.map(d => {
          return d.trp_count;
        });
        return {
          labels: xData,
          data: trips,
          title: 'Trips Taken',
          label: 'Vehicle Trips',
          xAxis: 'Months',
          yAxis: 'Number of Trips Taken',
          stack: '2',
          color: color,
          hoverColor: hoverColor,
        };
      case 'Miles':
        let milesData = data.map(d => {
          return Math.round(d.mls_count);
        });
        return {
          labels: xData,
          data: milesData,
          title: 'Miles Driven',
          label: 'Vehicle Miles',
          xAxis: 'Months',
          yAxis: 'Number of Miles Driven',
          color: color,
          hoverColor: hoverColor,
        };
      case 'Duty Cycles':
        let dcs = data.map(d => {
          return d.dc_count;
        });
        return {
          labels: xData,
          data: dcs,
          title: 'Number of Active Duty Cycles',
          xAxis: 'Months',
          yAxis: 'Number of Active Duty Cycles',
          label: `Vehicle DC's`,
          color: color,
          hoverColor: hoverColor,
        };
      default:
    }
  }
  if (type === 'landing') {
    var labels = Object.keys(data);
    var values = Object.values(data);
    let color = 'rgb(142, 196, 212, 0.9)';
    let hoverColor = 'rgba(122, 195, 240, 1)';
    switch (input) {
      case 'Days':
        return {
          labels: labels,
          data: values,
          title: 'Days Driven',
          xAxis: 'Total Days Active',
          yAxis: 'Number of Vehicles',
          color: color,
          hoverColor: hoverColor,
          avgLabel: 'Fleet Totals'
        };
      case 'Trips':
        return {
          labels: labels,
          data: values,
          title: 'Trips Taken',
          xAxis: 'Total Trips',
          yAxis: 'Number of Vehicles',
          color: color,
          hoverColor: hoverColor
        };
      case 'Miles':
        return {
          labels: labels,
          data: values,
          title: 'Miles Driven',
          xAxis: 'Total Miles Driven (thousands)',
          yAxis: 'Number of Vehicles',
          color: color,
          hoverColor: hoverColor
        };
      case 'Duty Cycles':
        return {
          labels: labels,
          data: values,
          title: 'Number of Duty Cycles',
          xAxis: 'Total Duty Cycles',
          yAxis: 'Number of Vehicles',
          color: color,
          hoverColor: hoverColor
        };
      default:
    }
  }
}
