import React from 'react';
import logo from '../../images/ic-ionev-1953px-2044px.png';
import NavigationLinks from './NavigationLinks';
import { Link } from "react-router-dom";
import DateSelectors from "./DateSelectors";

class Header extends React.Component {
  render() {
    var activeLink = this.props.activeLink;
    var disableSelector = this.props.loading;
    if(activeLink === "tco-metrics"){
      activeLink = "vcl-metrics";
      disableSelector = true;
    }

    let headerText = this.props.fleetName;
    if (this.props.apiError) headerText = "Error";
    else if (this.props.loading) headerText="Loading";

    // NOTE dropdown will need refactoring when want to use handleChange - also used for map vcl ddown
    return (
      <div className="swt-ionev-header">
        <Link to="/ionev">
          <img src={logo} className="ionev-logo" alt="ionEV logo" />
        </Link>
        <Link className="ionev-header-text-link" to="/ionev">
          <h1 className="ionev-header-text ionev-h1">{headerText}</h1>
        </Link>
       {!this.props.loading &&
        <div className="ionev-header-navigation">
          <NavigationLinks
              activeLink={activeLink}
              user = {this.props.user}
              devState={this.props.devState}
          />
          <div className="ionev-header-selectors">
            <DateSelectors startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            minDate={this.props.fleetStartDate}
                            maxDate={this.props.fleetEndDate}
                            handleChange={this.props.handleChange}
                            disabled={disableSelector}
                            userSettings={this.props.userSettings} />
          </div>
        </div>
      }
      </div>
    );
  }
}

export default Header;

// Group selection
// <div className="ionev-header-group-selector-wrapper">
//   <p className="ionev-header-selector-label">Select Group</p>
//   < DropdownSelector selectorName="ionev-header-group-selector ionev-selector-1" options={[{'id': 0, 'value': 'All Groups'}]} handleChange={this.props.handleChange}/>
// </div>
