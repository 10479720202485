import React, {useState, useRef, useEffect} from "react";
import TableView from "./idling-table-view";
import { tableDateFormat } from "./idling-csv-helper-functions";
import { formatDuration, vehicleIdDisplay } from "./idling-table-helper-functions";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { Marker } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// NOTE left 'black' default etc in here until finalize map marker logic, gradients, colors - RC

const DEFAULT_MAP_STYLE = "mapbox://styles/mapbox/streets-v11";
const SATELLITE_MAP_STYLE = "mapbox://styles/mapbox/satellite-v9";
const MIN_ZOOM = 2;
const MAX_ZOOM = 18;
const INIT_ZOOM = 10;

export default function IdlingMap(props){
  const { events, filter, isSummary, beginDate, endDate, minDuration, dbDisplayName } = props;

  const secrets = require('../../settings.json');
  mapboxgl.accessToken = secrets.mapboxKey;

  const mapContainer = useRef(null);
  const map = useRef(null)
  const [showSatellite, setShowSatellite] = useState(false)
  const [zoom, setZoom] = useState(_getMapRange(events) ? _getMapRange(events) : INIT_ZOOM)
  const [pitch, setPitch] = useState(0);
  const [bearing, setBearing] = useState(0);
  const [markers, setMarkers] = useState([])

  const ranges = [
    {min: 0, max: 2, color: "#b3181e"},
    {min: 2, max: 5, color: "#a1151b"},
    {min: 5, max: 10, color: "#a1151b"},
    {min: 10, max: 20, color: "#901318"},
    {min: 20, max: 30, color: "#901318"},
    {min: 30, max: 40, color: "#7f1115"},
    {min: 40, max: 50, color: "#7f1115"},
    {min: 50, max: 60, color: "#6d0e12"},
    {min: 60, max: 10000, color: "#6d0e12"},
  ]

  const tableColumns = [
    {Header: "Metric", accessor: "metric", sortType: "basic"},
    {Header: "Total", accessor: "total", sortType: "basic"},
    {Header: "0-2 \nminutes", accessor: "0", sortType: "basic"},
    {Header: "2-5 \nminutes", accessor: "2", sortType: "basic"},
    {Header: "5-10 \nminutes", accessor: "5", sortType: "basic"},
    {Header: "10-20 \nminutes", accessor: "10", sortType: "basic"},
    {Header: "20-30 \nminutes", accessor: "20", sortType: "basic"},
    {Header: "30-40 \nminutes", accessor: "30", sortType: "basic"},
    {Header: "40-50 \nminutes", accessor: "40", sortType: "basic"},
    {Header: "50-60 \nminutes", accessor: "50", sortType: "basic"},
    {Header: "60+ \nminutes", accessor: "60", sortType: "basic"},
  ];

  useEffect(() => {
    if(!events || events.length < 1) return;
    let mapStyle = showSatellite ? SATELLITE_MAP_STYLE : DEFAULT_MAP_STYLE;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      customAttribution: '',
      attributionControl: false,
      zoom: zoom,
      center: _getMapCenter(events),
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      pitch: pitch,
      bearing: bearing
    })

    map.current.addControl(new mapboxgl.NavigationControl())
    map.current.addControl(new mapboxgl.ScaleControl({maxWidth: 100, unit: 'imperial'}));

    if(map)map.current.on('style.load', () => {
        addTerrain(map)
        addSky(map)
      })
    return () => map.current.remove()
// eslint-disable-next-line
},[showSatellite])

useEffect(() => {
  if(map.current) {
      map.current.on('move', () => {
          setZoom(map.current.getZoom().toFixed(2));
          setPitch(map.current.getPitch());
          setBearing(map.current.getBearing());
      })
  }
})

useEffect(() => {
  if(map.current && events && events.length > 0) {
    let mapMarkers = []
    //Remove old markers before adding new markers.
    if(markers.length > 0) {
      markers.map((m) => {
        return m.remove()
    })
  }
      events.forEach((e) => {
      let cords = [e.longitude, e.latitude]
      let newMarker = addMarker(map, cords, e, setColor(e.duration_minutes), 'eventData', '15px')
      mapMarkers.push(newMarker)
    })
    setMarkers(mapMarkers)
  }
  // eslint-disable-next-line
},[events, showSatellite])

  function eventsHistogram(events){
    const arr = [];
    const counts = {metric: 'Incident Count', total: 0};
    const totals = {metric: 'Incident Duration (minutes)', total: 0};
    ranges.forEach((r => {
      // select all events in duration range
      const eventsInRange = events.filter((e) => parseInt(Math.round(e.duration_minutes)) >= r.min && parseInt(Math.round(e.duration_minutes)) < r.max);
      // add count and duration to object (w/ key of min range value)
      // Opt 1: sum event durations and round total below
      const eventsDuration = eventsInRange.reduce((total, e) => {return total + parseInt(e.duration_minutes)}, 0);
      // Opt 2: round each event duration and sum
      // const eventsDuration = eventsInRange.reduce((total, e) => {return total + parseInt(Math.round(e.duration_minutes))}, 0);

      // This conditional below is used to display "-" in the table for any columns that are below the minDuration control value instead of displaying a zero.
      counts[`${r.min}`] = r.min < minDuration ? null : eventsInRange.length;
      totals[`${r.min}`] = r.min < minDuration ? null : eventsDuration;
      counts.total += eventsInRange.length;
      totals.total += eventsDuration;
    })
    )
    arr.unshift(counts);
    arr.unshift(totals);
    return arr;
  }

  function setColor(duration){
    duration = Math.round(duration);
    const range = ranges.filter((r) => (duration >= r.min && duration < r.max))
    if(range.length > 0) {
      return range[0].color;
    }
    return "#901318";
  }

  function _getMapCenter(events){
    if(events.length < 1) return
    let lat = 0;
    let lon = 0;
    events.forEach((d) => {
      lat += d.latitude;
      lon += d.longitude;
    });
    return [lon/events.length, lat/events.length]
  }

  function _getMapRange(events){
    let maxLon = -150;
    let minLon = 150;

    events.forEach((d) => {
      maxLon = Math.max(maxLon, d.longitude);
      minLon = Math.min(minLon, d.longitude);
    });
    const delta = maxLon-minLon;
    return parseInt(11-(delta/9));
  }

  let cn = "idling-map-container"
  if (props.isDetail)cn = "idling-detail-map-container";
  if (props.isIncident)cn = "idling-incident-map-container";

  let tableData = [];
  let incidentCountCsvFilename = `sawatch-labs-idling-incidents-by-duration-${dbDisplayName.replaceAll(" ", "-")}-${(new Date().toLocaleDateString().replaceAll('/', '-'))}`;
  

  if(typeof events === "undefined" || events.length < 1 || events === null){
    return(
    <div>
      <p className="idling-no-data-msg">No map data to display. Try adjusting date range or filters.</p>
      {isSummary &&
        <div className = "idling-above-80-table">
          <TableView 
            columns={tableColumns}
            data={tableData}
            filter={filter}
            beginDate={beginDate}
            endDate={endDate}
            group={props.group}
            vehicleClasses={props.vehicleClasses}
            minDuration={minDuration}
            csvFilename={incidentCountCsvFilename}
            colType={() => {return "idling-table-header"}}
            highlight={() => {}}
            handleOnClick={() => {}}
            handleSort={() => {}}
            nolink={true}
            tableType={'Incident Count'}
            dbDisplayName={dbDisplayName}
          />
        </div>
      }
    </div>)
  }
  if(isSummary)tableData = eventsHistogram(events);
  return(
    <div>
      <div className='idling-map-wrapper'>
            <div ref={mapContainer} className={cn} />
            <div className="idling-map-satellite-control">
                <div>Satellite View:</div>
                <input type='checkbox' checked={showSatellite} onChange={() => {setShowSatellite(!showSatellite)}}/>
            </div>
        </div>
 
      {isSummary &&
        <div className = "idling-above-80-table">
          <TableView 
            columns={tableColumns}
            data={tableData}
            filter={filter}
            colType={() => {return "idling-table-header"}}
            highlight={() => {}}
            handleOnClick={() => {}}
            handleSort={() => {}}
            nolink={true}
            beginDate={beginDate}
            endDate={endDate}
            group={props.group}
            vehicleClasses={props.vehicleClasses}
            minDuration={props.minDuration}
            csvFilename={incidentCountCsvFilename}
            tableType={'Incident Count'}
            dbDisplayName={dbDisplayName}
          />
        </div>
      }
    </div>)
}

const addSky = (map) => {
  if(!map.current.getLayer('sky')){
      map.current.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
              'sky-type': 'atmosphere',
              'sky-atmosphere-sun': [0.0, 0.0],
              'sky-atmosphere-sun-intensity': 5
              }
        });
  };
}

const addTerrain = (map) => {
  if(!map.current.getSource('mapbox-dem')){
      map.current.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': MAX_ZOOM,
          'minzoom': MIN_ZOOM
      });
  }
  // add the DEM source as a terrain layer with exaggerated height
  map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2 });
}

const addMarker = (m, cords, data, color, type, markerSize) => {
  const p = new mapboxgl.Popup({offset: [0, 0], className: 'speedn-map-marker-popups'}).setHTML(markerHTML(data));
  const el = document.createElement('div');
  el.style.backgroundColor = `${color}80`;
  el.style.borderRadius = "20px";
  el.style.width = markerSize;
  el.style.height = markerSize;
  el.style.borderColor = color;
  el.style.borderWidth = '3px';
  el.style.borderStyle = 'solid';
  el.style.cursor = "Pointer";
  const mark = new Marker(el)
    .setLngLat(cords)
    .setPopup(p)
    .addTo(m.current)
  return mark;
}

const markerHTML = (data) => {
  return (
    `
      <span><b>Asset Id:</b> ${vehicleIdDisplay(data)}</span>
      <br>
      <span><b>Start Time:</b> ${tableDateFormat(data.local_start)}</span>
      <br>
      <span><b>Duration:</b> ${formatDuration(data.duration_minutes)} ${Math.round(data.duration_minutes) > 1 ? 'minutes' : 'minute'}</span>
      <br>
    `
  )
}

// function interpolateColor(c0, c1, f){
//   c0 = c0.replace('#', '');
//   c1 = c1.replace('#', '');
//   c0 = c0.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * (1-f))
//   c1 = c1.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * f)
//   let ci = [0,1,2].map(i => Math.min(Math.round(c0[i]+c1[i]), 255))
//   ci = ci.reduce((a,v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
//   return(`#${ci}`)
// }