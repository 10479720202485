
import Select from "react-select"
import React, { useEffect, useState } from "react"
import * as CS from "../../styles/ezio-styles/Controls-styles"
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"

type ElectrificationDropdownProps = {
    selectedElectrification: number
    onChange: Function
}

export default function ElectrificationDropdown({ selectedElectrification, onChange }: ElectrificationDropdownProps) {

    const [isOpen, setIsOpen] = useState(false)
    const options: Array<{ value: number, label: string }> = []
    for (let x = 5; x <= 100; x += 5) {
        options.push({
            value: x,
            label: `${x.toString()}%`
        })
    }


    function toggleOpen() {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        scrollToSelected()

    }, [isOpen])
    return (
        <Dropdown
            dropdownId="electrification-dropdown-testid"
            isOpen={isOpen}
            onClose={toggleOpen}
            target={

                <CS.ElectrificationMenuButton onClick={toggleOpen}>
                    <CS.ElectrificationMenuText>
                        {`${selectedElectrification.toString()}% Electrification`}
                    </CS.ElectrificationMenuText>
                    <CS.CustomDownChevron /></CS.ElectrificationMenuButton>
            }
        >
            <Select
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen={isOpen}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                value={
                    {
                        value: selectedElectrification,
                        label: `${selectedElectrification.toString()}% Electrification`
                    }
                }
                onChange={(event) => {
                    setIsOpen(false)
                    onChange(event?.value)
                }}
                options={options}
                styles={
                    {
                        ...CS.SelectStyles,
                        container: (base: any) => {
                            return {
                                ...base,
                                width: "65px",
                                borderStyle: "none"
                            }
                        }
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CS.CustomDownChevron,
                    Control: () => null,
                }
                }
                formatOptionLabel={formatOptionLabel}

            />
        </Dropdown>
    )
}



