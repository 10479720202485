import React, { Component } from 'react';
import Switch from 'react-switch';

export default class MapToggle extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <label>
          <span
            id={
              this.props.showSelected
                ? 'toggle-label-lh-unselected'
                : 'toggle-label-lh-selected'
            }
          >
            {this.props.unselectedText}
          </span>
          <Switch
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={11}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={29}
            onChange={this.props.handleToggleChange}
            checked={this.props.showSelected}
            className="react-switch"
            id="material-switch"
          />
          <span
            id={
              this.props.showSelected
                ? 'toggle-label-rh-selected'
                : 'toggle-label-rh-unselected'
            }
          >
            {this.props.selectedText}
          </span>
        </label>
      </div>
    );
  }
}
