import React, { useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  getMonth,
  addCommas,
  formatDateString,
  dateAsNonTZString
} from "../ionev-components/UtilityFunctions";
import { processApiResponse } from "./utils/ConformUnits";

export default function GraphElectricMilesByMonth(props) {
  const userSettings = props.user.userSettings;
  const longPluralLabel = userSettings.distance_labels.longPlural;
  
  const [graphData, setGraphData] = React.useState({
    data: { elecMiles: [], iceMiles: [], ttlMiles: [] },
    labels: [],
    totals: { ice: 0, ev: 0, ttl: 0 },
  });
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  useEffect(
    () => {
      var totalSummaryUrl = `${props.baseUrl}getTotalActivitySummaryByMonth?`;
      var queries = `&clientId=${props.dbName}&start=${dateAsNonTZString(props.startDate)}&stop=${dateAsNonTZString(props.endDate)}`;
      fetch(`${totalSummaryUrl}${queries}`, {
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.data?.length > 0) {
            // NOTE do not need to worry about elec_km here as different attributes are used
            // so not an issue elec_km is formatted based on elec fuel efficiency user settings
            let processedData = data.data.map((i) => {return processApiResponse(props.user.userSettings, i)})
            createGraphData(processedData);
          }
        });
    },

    [props.startDate, props.endDate]);  // eslint-disable-line react-hooks/exhaustive-deps

  function createGraphData(response) {
    let labels = [];
    let elecMiles = [];
    let iceMiles = [];
    let ttlMiles = [];
    response.forEach((month) => {
      let lbl = formatDateString(userSettings, getMonth(month.month), month.year);
      labels.push(lbl);
      let miles = calculateElectricMiles(month);
      elecMiles.push(miles.elecMiles);
      iceMiles.push(miles.iceMiles);
      ttlMiles.push(miles.ttlMiles);
    });
    let gd = {
      data: { elecMiles: elecMiles, iceMiles: iceMiles, ttlMiles: ttlMiles },
      labels: labels,
      totals: {
        ev: addCommas(Math.round(elecMiles.reduce(reducer))),
        ice: addCommas(Math.round(iceMiles.reduce(reducer))),
        ttl: addCommas(Math.round(ttlMiles.reduce(reducer))),
      },
    };
    setGraphData(gd);
  }

  function calculateElectricMiles(month) {
    let totalMiles = Math.round(month.km_bev + month.km_phev);
    let elecMiles = 0;
    let iceMiles = 0;
    if (month.km_phev === null) month.km_phev = 0;
    if (month.km_bev === null) month.km_bev = 0;
    let phevMiles = month.km_phev;
    let phevMiles_BE = month.km_phev_elec;
    let beMiles = month.km_bev;
    elecMiles = Math.round(beMiles + phevMiles_BE);
    iceMiles = Math.round(phevMiles - phevMiles_BE);
    return { elecMiles: elecMiles, iceMiles: iceMiles, ttlMiles: totalMiles };
  }

  const legendOpts = {
    display: true,
    position: "bottom",
    labels: {
      font: {
        size: 11,
        weight: 'normal',
        },
    }
  };

  const scales = {
    x: {
      display: true,
      ticks: {
        autoSkip: true,
      }
    },
    y:
      {
        stacked: false,
        display: true,
        beginAtZero: true,
      },
  }

  const options = {
    plugins: {
      legend: legendOpts,
      title: {
        display: true,
        text: `Electric ${longPluralLabel}: ${graphData.totals.ev}  Gas ${longPluralLabel}: ${graphData.totals.ice}  Combined ${longPluralLabel}: ${graphData.totals.ttl}`,
        align: 'center',
        font: {
          size: 11,
          weight: 'normal',
          },
      },
    },
    layout: {
      padding: {
        right: 15,
      },
    },
    maintainAspectRatio: false,
    scales: scales
  };

  function determineGraphData(graphLength) {
    if (graphLength > 3) {
      return {
        labels: graphData.labels,
        datasets: [
          {
            stack: "stack1",
            label: `Electric ${longPluralLabel}`,
            data: graphData.data.elecMiles,
            backgroundColor: "rgba(87, 150, 182, .5)",
            borderColor: "rgb(87, 150, 182)",
            fill: true,
            borderWidth: 2,
            yAxisId: 'nullAxis'
          },
          {
            stack: "stack1",
            label: `Gas ${longPluralLabel}`,
            data: graphData.data.iceMiles,
            backgroundColor: "rgba(217, 200, 167, .5)",
            borderColor: "rgb(171, 158, 133)",
            fill: true,
            borderWidth: 2,
            yAxisId: 'nullAxis'
          },
          {
            stack: "stack1",
            label: `Combined ${longPluralLabel}`,
            data: graphData.data.ttlMiles,
            backgroundColor: "rgba(197, 198, 199, .8)",
            borderColor: "rgb(169, 170, 171)",
            fill: true,
            borderWidth: 2,
            yAxisId: 'yAxis'
          },
        ],
      };
    } else {
      return {
        labels: graphData.labels,
        datasets: [
          {
            stack: "stack1",
            label: `Electric ${longPluralLabel}`,
            data: graphData.data.elecMiles,
            backgroundColor: "rgba(87, 150, 182, .5)",
            borderColor: "rgb(87, 150, 182)",
            fill: true,
            borderWidth: 2,
            yAxisId: 'yAxis',
          },
          {
            stack: "stack1",
            label: `Gas ${longPluralLabel}`,
            data: graphData.data.iceMiles,
            backgroundColor: "rgba(217, 200, 167, .5)",
            borderColor: "rgb(171, 158, 133)",
            fill: true,
            borderWidth: 2,
            yAxisId: 'yAxis',
          },
        ],
      };
    }
  }
  function determineGraphType(graphData) {
    if (graphData.length > 3)
      return (
        <Line
          className="swt-ionev-graph-content"
          options={options}
          data={determineGraphData(graphData.length)}
          width={80}
          height={280}
        />
      );
    else {
      return (
        <Bar
          className="swt-ionev-graph-content"
          options={options}
          data={determineGraphData(graphData.length)}
          width={80}
          height={280}
        />
      );
    }
  }

  return (
    <>
      <h4 className="ionev-graph-title" data-testid="fuel-dist-graph-title-testid">
        {longPluralLabel} Driven Per Month Per Fuel
      </h4>
      {graphData.data.ttlMiles.length > 0
        ? determineGraphType(graphData.data.ttlMiles)
        : null}
    </>
  );
}
