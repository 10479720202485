import React, { useEffect } from 'react';
import OutsideAlerter from './OutsideClickNotifier';
import { withRouter } from 'react-router-dom';
function LocationSelector(props) {
  const { locations } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectAllBtn, setSelectAllBtn] = React.useState(false);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (locations.length > 0) {
      const results = locations.filter(i =>
        i.address.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [locations, searchTerm]);

  function SingleVehiclePageHandler() {
    let r = window.confirm(
      `If you would like to update your locations, you must do so from the vehicles page.\r\n${''}\r\n Would you like to proceed?`
    );
    if (r === true) {
      props.history.goBack();
    } else {
      return null;
    }
  }

  function handleLocationChange(locationID) {
    // This is where handleLocationChange in Shifted should be moved
    if (locationID === null) return;
    var updatedLocId;
    var selectAllLocs = [];
    var locations = props.locations.map(loc => {
      if (locationID === 'selectAll') {
        loc.selected = true;
        selectAllLocs.push(loc.parking_location);
        updatedLocId = loc.parking_location;
      }
      if (locationID === 'deselectAll') loc.selected = false;
      if (loc.parking_location === locationID) {
        loc.selected = !loc.selected;
        updatedLocId = loc.parking_location;
      }
      return loc;
    });
    // confirm there are no selected locations
    const allDeselected = !locations.some(loc => loc.selected === true);
    if (locationID === 'deselectAll' || allDeselected) {
      props.handleSettingsChange(locations, 'allLocations');
      props.handleSettingsChange([], 'selectedLocIds');
      props.settingChangeTracker.deselectAll = true;
      props.settingChangeTracker.allLocations = false;
      props.settingChangeTracker.selectedLocIds = false;
    } else {
      var selectedIds = props.selectedLocIds;
      var idx = selectedIds.indexOf(updatedLocId);
      if (idx < 0) selectedIds.push(updatedLocId);
      else selectedIds.splice(idx, 1);
      props.handleSettingsChange(
        locationID === 'selectAll' ? selectAllLocs : selectedIds,
        'selectedLocIds'
      );
      props.handleSettingsChange(locations, 'allLocations');
      props.settingChangeTracker.deselectAll = false;
      props.settingChangeTracker.allLocations = true;
      props.settingChangeTracker.selectedLocIds = true;
    }
  }

  function LocationButtonHandler() {
    if (props.location.pathname !== '/shifted/singleVehicle') {
      setShowModal(!showModal);
    } else {
      SingleVehiclePageHandler();
    }
  }

  function handleAllButtonClick() {
    if (props.location.pathname === '/shifted/singleVehicle') {
      props.clearFilters('singleVcl');
    }
    if (props.location.pathname !== '/shifted/singleVehicle' && !selectAllBtn) {
      window.alert(
        'By deselecting this location you will not have any results until another location is selected.'
      );
    }
    handleLocationChange(selectAllBtn ? 'selectAll' : 'deselectAll');
    setSelectAllBtn(!selectAllBtn);
  }
  const selectedLocs = props.locations.filter(l => l.selected === true);

  return (
    <OutsideAlerter handleClick={() => setShowModal(false)}>
      <div
        className="sftd-location-selector"
        id={showModal ? 'clicked' : 'unclicked'}
      >
        <h3
          className="sftd-location-title"
          onClick={() => LocationButtonHandler()}
        >
          {selectedLocs.length}{' '}
          {selectedLocs.length === 1 ? 'Location' : 'Locations'}
        </h3>
        {showModal && (
          <div className="sftd-locations-modal">
            <p className="sftd-locations-modal-title">Locations</p>
            <div className="sftd-locations-drpdown-wrapper">
              <div className="sftd-search">
                <input
                  className="sftd-searchbar"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                ></input>
              </div>
              <h3 className="sftd-container-ttl" id="search">
                {searchTerm.length > 0 ? 'Search Results' : 'Unselected'}
              </h3>
              <div className="sftd-locations-unselected">
                {searchResults.map(l =>
                  l.address === 'Combined Fleet' ? null : l.selected ===
                    false ? (
                    <div
                      key={l.parking_location}
                      className="item"
                      onClick={() => handleLocationChange(l.parking_location)}
                    >
                      {l.address}
                    </div>
                  ) : null
                )}
              </div>
              <h3 className="sftd-container-ttl" id="selected">
                Selected
              </h3>
              <div className="sftd-locations-selected">
                {selectedLocs.length === 0 ? (
                  <span className="sftd-locations-none">
                    No locations currently selected
                  </span>
                ) : (
                  selectedLocs.map((l, i) => {
                    return (
                      <div
                        key={i}
                        className="item"
                        onClick={() => {
                          if (selectedLocs.length === 1)
                            window.alert(
                              'By deselecting this location you will not have any results until another location is selected.'
                            );
                          handleLocationChange(l.parking_location);
                        }}
                      >
                        {l.address}
                      </div>
                    );
                  })
                )}
              </div>
              {props.locations.length > 0 && props.locations.length < 25 && (
                <button
                  className="sftd-selectAllBtn"
                  onClick={handleAllButtonClick}
                >
                  {selectAllBtn && locations.length !== selectedLocs.length
                    ? 'Select All Locations'
                    : 'Deselect All Locations'}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
}

export default withRouter(LocationSelector);
