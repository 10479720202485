import React, { useReducer } from "react";

export default function HandleExpiredToken({ handleResetPassword }) {

  const [state, dispatch] = useReducer((state, action) => {
    return ({ ...state, ...action })
  }, { email: undefined })

  function handleSubmit(event) {
    event.preventDefault();
    if (state.email !== undefined) {
      handleResetPassword(state.email);
    }
  };

  return (
    <div className="swt-admin-request-token">

      <div className="swt-new-token-wrpr">
        <p className="swt-admin-reset-token-txt">
          Your token has expired. Please enter the email address associated
          with your account to generate a new one.
        </p>
        <form className="swt-auth" onSubmit={handleSubmit}>
          <label className="swt-credential-input-label">
            Username/Email Address
          </label>
          <br />
          <input
            className="swt-credential-input"
            type="text"
            onChange={(e) => dispatch({ email: e.target.value.trim() })}
            value={state.email}
          />
          <br />
          <input type="submit" className="swt-submit-button" />
        </form>
      </div>
    </div>
  );

}
