import React from "react";
import { Bar } from "react-chartjs-2";

export default function SpeednGraph(props){
    const { data }= props;
    const events = data.filter((r) => {return (r.kmph*.621) > 80});
    const vinSet = new Set(events.map((r) => {return r.vin}))
    let dataWrapper = [];

    const sortByIncidentLength = function( a, b ) {
      if ( a.incidents.length > b.incidents.length )return -1;
      if ( a.incidents.length < b.incidents.length )return 1;
      return 0;
    }

    const vins = [...vinSet];

    vins.forEach((v) => {
      const rows = events.filter((r) => { return v === r.vin });
      const l = rows.map((r) => {return `${r.ts}`})
      const incs = rows.map((r) => { return (Math.round(r.kmph * .621))})
      let assetID = '';
      if(rows.length > 0)assetID = rows[0].asset_id;
      dataWrapper.push({assetID: assetID, vin: v, labels: l, incidents: incs});
    });

    dataWrapper = dataWrapper.sort(sortByIncidentLength);
    
    const dataSet = function(data){
      return({
        labels: data.labels,
        vin: data.vin,
        height: Math.max(data.incidents.length * 18, 95),
        datasets: [
          {
            label: data.assetID,
            data: data.incidents,
            backgroundColor: "rgba(255, 0, 0, 0.7)",
            barThickness: 5
          }
        ],
      });
    } 

    const HorizontalBar = function(dataSet){

      return(

      <Bar
        id="graph"
        data={dataSet}
        width={900}
        height={dataSet.height}
        options={{
          type: 'bar',
          indexAxis: 'y',
          plugins: {
            active: {
              mode: "dataset",
              intersect: true,
            },
            legend:{
              display: false
            },
          },
          layout: {
            padding: 0
          },
          maintainAspectRatio: false,
          responsive: false,
          scales: {
            y: {
                suggestedMax: 10,
                suggestedMin: 0,
              },
            x:{
              suggestedMax: 100,
              suggestedMin: 75,
            },
          },
        }}>
      </Bar>);

    }

    return(
      <div>
        <p className="speedn-graph-label">Speeding Incidents Above 80 MPH</p>
        {dataWrapper.map((d) => {
          const data = dataSet(d);
          return (
          <div key={d.vin} className="speedn-hbar-container">
            <div className="speedn-hbar-label">{d.assetID}</div>
            {HorizontalBar(data)}
          </div>)}
        )}
      </div>);
}
