import { useHistory, Route, Switch, NavLink, Redirect } from "react-router-dom";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import SpeednMap from "./speedn-components/speedn-map";
import SpeednDetailTable from "./speedn-components/speedn-detail-table";
import SpeednWeeklySummaryTable from "./speedn-components/speedn-weekly-summary-table";
import SpeednSummaryTable from "./speedn-components/speedn-summary-table";
import SpeednSummaryPanels from "./speedn-components/speedn-summary-panel";
import SpeednGraph from "./speedn-components/speedn-graph";
import SpeednControls from "./speedn-components/speedn-controls";
import VehicleDetail from "./speedn-components/speedn-vehicle-detail";
import IncidentDetail from "./speedn-components/speedn-incident-detail";
import Loading from "./speedn-components/speedn-loading";
import Header from './speedn-components/speedn-header';
import "react-datepicker/dist/react-datepicker.css";
import "../component-css/speedn-stylesheets/swt-speedn.css";

function KMPH2MPH(kmph){
  return kmph*0.621371;
}
function MPH2KMPH(mph){
  return mph/0.621371;
}

const MIN_DATE = "2021-01-02";
const ADDITIONAL_FIELDS_GROUP = "ADDITIONAL FIELDS";
const ALL_VEHICLES_GROUP = "swt-vehicles"

const summaryTableColumns = [
  {Header: "Asset Id", accessor: "asset_id", sortType: "basic"},
  {Header: "Year", accessor: "year", sortType: "basic"},
  {Header: "Make", accessor: "make", sortType: "basic"},
  {Header: "Model", accessor: "model", sortType: "basic"},
  {Header: "Incidents", accessor: "incidents", sortType: "basic"},
]

const detailTableColumns = [
  {Header: "Asset Id", accessor: "asset_id", sortType: "basic"},
  {Header: "Year", accessor: "year", sortType: "basic"},
  {Header: "Make", accessor: "make", sortType: "basic"},
  {Header: "Model", accessor: "model", sortType: "basic"},
  {Header: "Date/Time", accessor: "ts", sortType: "basic"},
  {Header: "Duration\n(Seconds)", accessor: "compiledDuration", sortType: "basic"},
  {Header: 'Location\n(Lat/Lon)', accessor: 'eventLocation', sortType: "basic"},
  {Header: `Speed\n(mph)`, accessor: "mph", sortType: "basic"},
  {Header: "Posted Speed\n(mph)", accessor: "postedMph", sortType: "basic"},
  {Header: "Excess Speed\n(mph)", accessor: "excessMph", sortType: "basic"},
  {Header: "Valid/Invalid", accessor: "valid_text", sortType: "basic"},
  {Header: "Reason\nInvalid", accessor: "valid_reason", sortType: "basic"},
]

const singleVehicleTableColumns = [
  {Header: "Date/Time", accessor: "ts", sortType: "basic"},
  {Header: "Duration\n(Seconds)", accessor: "compiledDuration", sortType: "basic"},
  {Header: "Speed\n(mph)", accessor: "mph", sortType: "basic"},
  {Header: "Posted Speed\n(mph)", accessor: "postedMph", sortType: "basic"},
  {Header: "Excess Speed\n(mph)", accessor: "excessMph", sortType: "basic"},
  {Header: "Valid/Invalid", accessor: "valid_text", sortType: "basic"},
  {Header: "Reason\nInvalid", accessor: "valid_reason", sortType: "basic"},
]

const csvDetailHeaders = singleVehicleTableColumns.map(c => {return{label: c.Header, key: c.accessor}});

const speedRanges = [
  {id: 0, name: "1+"},
  {id: 5, name: "5+"},
  {id: 10, name: "10+"},
  {id: 15, name: "15+"},
  {id: 20, name: "20+"},
  {id: 25, name: "25+"},
  {id: 30, name: "30+"}
];

const minSpeeds = [
  {id: 0, name: "0+"},
  {id: 30, name: "30+"},
  {id: 40, name: "40+"},
  {id: 50, name: "50+"},
  {id: 60, name: "60+"},
  {id: 70, name: "70+"},
  {id: 80, name: "80+"},
  {id: 90, name: "90+"},
  {id: 100, name: "100+"}
];

const minDurations = [
  {id: 0, name: "0+"},
  {id: 5, name: "5+"},
  {id: 15, name: "15+"},
  {id: 30, name: "30+"},
  {id: 60, name: "60+"},
  {id: 120, name: "120+"},
  {id: 300, name: "300+"},
];

export default function Speedn(props) {
  const { user, apiURL, dbName, dbDisplayName } = props;

  const [minSpeed, _setMinSpeed] = useState(80);
  const [minDuration, _setMinDuration] = useState(0);
  const [excessSpeed, _setExcessSpeed] = useState(0);
  const [showDismissed, _setShowDismissed] = useState(false);
  const [summaryTableSortCol, setSummaryTableSortCol] = useState('incidents');
  const [summaryTableDesc, setSummaryTableDesc] = useState(true);
  const [detailTableSortCol, setDetailTableSortCol] = useState('ts');
  const [detailTableDesc, setDetailTableDesc] = useState(true);
  const [weeklyTableSortCol, setWeeklyTableSortCol] = useState('asset_id');
  const [weeklyTableDesc, setWeeklyTableDesc] = useState(true);
  const [events, _setEvents] = useState(undefined);
  const [group, _setGroup] = useState({id:"", name:""});
  const [selectedGroups, _setSelectedGroups] = useState([]);
  const [department, _setDepartment] = useState({id:"", name:""});
  const [vehicleClasses, _setVehicleClasses] = useState([]);
  const [selectedVehicleClasses, _setSelectedVehicleClasses] = useState([]);
  const [matchingVehicles, setMatchingVehicles] = useState({values: []});
  const [groups, _setGroups] = useState([]);
  const [departments, _setDepartments] = useState([]);
  const [apiReqFlag, _setApiReqFlag] = useState(true);
  const [groupReqFlag, _setGroupReqFlag] = useState(true);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [incidentParent, setIncidentParent] = useState(undefined);
  const [childVehicleCount, _setChildVehicleCount] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const history = useHistory();
  const { geotabIntegrated, geotabGroups, geotabDepartments } = props;
  const req = useMemo(() => {
    return { user: user,
             dbName: dbName,
             group: group,
             groups: selectedGroups.map((g) => {return g.id}),
             apiURL: apiURL, 
             beginDate: beginDate,
             endDate: endDate,
             minSpeed: minSpeed,
             minDuration: minDuration,
             excessSpeed: excessSpeed,
             showDismissed: showDismissed,
             selectedVehicleClasses: selectedVehicleClasses,
            }
  }, [user, dbName, apiURL, beginDate, endDate, group, minSpeed, minDuration, excessSpeed, selectedVehicleClasses, showDismissed, selectedGroups]);

  useEffect(() => {
    setTimeout(() => { init(); }, 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = () => {
    _setGroupReqFlag(false);
    const cb = (data) => {_setEvents(data)};
    const ge = (bounds) => _getEvents(req, bounds, cb);
    const gvc = () => {_getVehicleClasses(req, function(data){_setSelectedVehicleClasses(['All Classes']);_setVehicleClasses(data.slice())})};
    _getBounds(req, function(bounds){setBeginDate(bounds.beginDate);setEndDate(bounds.endDate);gvc();ge(bounds);});
  }

  const _getPermittedGroups = useCallback(() => {
    let r = {
      user: user,
      dbName: dbName,
      apiURL: apiURL
    }
    _setGroupReqFlag(true);
    const handleGroups = (groups) => {
      _setGroup(groups[0]);
      _setGroups(groups);
      _setApiReqFlag(false);
      _getAdditionalFieldsGroups(r, groups, (grps)=>{
        _setGroups(grps)
        // setInterval(()=>_setGroups(grps), 500);
      });
    };
    if(!geotabIntegrated){
      _getGroups(r, handleGroups);

    } else {
      const params = {};
      params.group = {setter: function(group){_setGroup(group); _setApiReqFlag(false)}};
      params.geotabGroups = {groups: geotabGroups, setter: _setGroups};
      params.departments = {groups: geotabDepartments, setter: handleDepartments};
      //params.additionalFields = {groups: geotabAdditionalFields, setter: _setAdditionalFields};
      _getGroups(r, handleGroups, params);
    }
    //_getAdditionalFieldsGroups(r, [], _setFuelGroups)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dbName, apiURL]);

  const sortByVin = function( a, b ) {
    if ( a.vin < b.vin )return -1;
    if ( a.vin > b.vin )return 1;
    return 0;
  }
  const addEventLocation = (events) => {
    if(events) {
    events.map((e) => {
      e.eventLocation = `${e.latitude}, ${e.longitude}`
      return e;
    })
  }
  }
  useMemo(() => {
     if(!events || events.length < 1){
      setFilteredEvents([]);
      return
    }
    const vins = matchingVehicles.values.map((v) => {return v.vin});
    // eslint-disable-next-line
    const filtered = events.filter((e) => {if(vins.indexOf(e.vin) > -1)return e})
    setFilteredEvents(filtered);

  },[events, matchingVehicles])

  const compiledEvents = useMemo(() => {
    addEventLocation(events)
    const sumIncidentsByVin = function(data){
      let arr = [];
      let vin = data[0].vin;
      let incidents = 0;
      for(let i=0;i<data.length;i++){
        const v = data[i];
        if(vin === v.vin){
          incidents++;
        }
        else{
          const v0 = data[i-1]
          arr.push({'vin': `${vin}`,
            'asset_id': v0.asset_id,
            'beginDate': beginDate,
            'endDate': endDate,
            'incidents': incidents,
            'year': v0.year,
            'make': v0.make,
            'model': v0.model,
            'vehicle_class': v0.vehicle_class,
            'user_defined_vin': v0.user_defined_vin
          });
          vin = v.vin;
          incidents = 1;
        }
        if(i === data.length -1){
          if(!v.vehicle_class || v.vehicle_class === '')v.vehicle_class = 'Undefined';
          arr.push({'vin': `${vin}`,
          'asset_id': v.asset_id,
          'incidents': incidents,
          'year': v.year,
          'make': v.make,
          'model': v.model,
          'vehicle_class': v.vehicle_class,
          'user_defined_vin': v.user_defined_vin});
        };
      };
      return arr;
    }
    const compileEvents = function(evts) {
      if(evts && evts.length > 0 && typeof evts[0].vin !== "undefined"){
        const d = evts.sort(sortByVin);
        return(sumIncidentsByVin(d));
      }
      return [];
    }
    if(events && events.length < 1)return [];
    return(compileEvents(events));
  }, [events, beginDate, endDate]);

  const _getData = useCallback((props) => {
    if(group !== '' && typeof group !== 'undefined') {
      _setApiReqFlag(true);
      const cb = (data) => {_setEvents(data);};
      _getEvents(req, {beginDate: beginDate, endDate: endDate}, cb);
    }
  }, [req, group, beginDate, endDate]);

  const handleGroupChange = (gid) => {
    const idx = groups.findIndex(g => g.id === gid);
    const group = groups[idx];
    _getGroupChildrenVehicleCount(req, group, function(count){_setChildVehicleCount(count)});
    _setGroup(group);
    _setDepartment({id:null, name:"Not Selected"});
    _setSelectedGroups([]);
    _setApiReqFlag(false);
  }

  const handleSelectedGroupsChange = (gidObj) => {
    const sg = gidObj.map((g) => {return g.id});
    let group = {name: "Multiple Groups", id: sg.toString()};
    if(sg.length === 1){
      const n = groups.filter((g) => g.id === sg[0]);
      group = n[0]; 
    }
    if(sg.length < 1)group = groups[0];
    _setGroup(group);
    _setSelectedGroups(gidObj);
    _setApiReqFlag(false);
  }

  const handleDepartmentChange = (gid) =>{
    let idx = groups.findIndex(g => g.id === gid);
    if(idx < 0)idx = 0;
    const group = groups[idx];
    _setGroup(group);
    _setDepartment(group);
    _setSelectedGroups([]);
    _setApiReqFlag(false);
  }

  const handleDepartments = (depts) =>{
    const d = depts.slice();
    d.unshift({id: null, name: "Not Selected"});
    _setDepartments(d);
  }

  const handleShowDismissedChange = (checked) => {
    _setShowDismissed(checked);
    _setApiReqFlag(false);
  }

  const handleSummaryTableSort = (col, desc) => {
    const a = summaryTableColumns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setSummaryTableSortCol(a[0].accessor);
    setSummaryTableDesc(d);
  }

  const handleDetailTableSort = (col, desc) => {
    const a = detailTableColumns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setDetailTableSortCol(a[0].accessor);
    setDetailTableDesc(d);
  }

  const handleWeeklyTableSort = (columns, col, desc) => {
    const a = columns.filter((c) => {if(col === c.Header)return c;else return null;});
    let d = desc ? false : true;
    if(a.length < 1 || typeof a[0] === "undefined")return;
    setWeeklyTableSortCol(a[0].accessor);
    setWeeklyTableDesc(d);
  }

  const handleExcessSpeedChange = (excessSpeed) => {
    _setExcessSpeed(excessSpeed);
    _setApiReqFlag(false);
  };

  const handleMinSpeedChange = (minSpeed) => {
    _setMinSpeed(minSpeed);
    _setApiReqFlag(false);
  };

  const handleMinDurationChange = (minDuration) => {
    _setMinDuration(minDuration);
    _setApiReqFlag(false);
  };


  if(!groupReqFlag)_getPermittedGroups();
  if(!apiReqFlag)_getData();

  const SpeednLink  = ({id, label}) =>{
    return <NavLink to={`/${id}`} id={id}>{label}</NavLink>;
  };

  //let appWrapper = "speedn";
  let basename = "";
  let appWrapper = "speedn-wrapper";
  if (geotabIntegrated)appWrapper = "speedn-geotab-wrapper";

  let dateStr = "";
  if(beginDate && endDate)dateStr = `${beginDate.getMonth()+1}/${beginDate.getDate()}/${beginDate.getFullYear()} - ${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}`;
  if(!events || groups.length < 1 || !group ||(departments.length < 1 && geotabIntegrated))return(<Loading/>);
  if(!history.location.pathname.includes('/speedn'))history.push('/speedn');

  let dateString = (new Date()).toLocaleDateString().replaceAll('/', '-');
  let csvSummaryFilename = `sawatch-labs-speeding-summary-report-${dbDisplayName.replaceAll(" ", "-")}-${dateString}`;
  let csvDetailTableFilename = `sawatch-labs-speeding-detail-report-${dbDisplayName.replaceAll(" ", "-")}-${dateString}`;
  return (
    <div className={appWrapper} id="speedn-root">
        <Route
          path = "/speedn"
          render={(props) => (
            <Redirect to={{ pathname: `/speedn/summarytable` }} />
          )}
        />
        <Switch>
          <Route
            path="/speedn/incidentdetail/:vin/:ts"
            render={(props) => (
              <IncidentDetail 
                parent={incidentParent}
                events={filteredEvents}
                group={group}     
                dateStr={dateStr}
                req={req}      
                dbDisplayName={dbDisplayName}
              />
            )}
          />
          <Route
            path="/speedn/vehicledetail/:vin"
            render={(props) => (
            <VehicleDetail
              basename={basename}
              events={events}
              group={group}
              columns={singleVehicleTableColumns}
              beginDate={beginDate}
              endDate={endDate}
              setBeginDate={function(data){setBeginDate(data);_setApiReqFlag(false);}} 
              setEndDate={function(data){setEndDate(data);_setApiReqFlag(false);}}
              excessSpeed={excessSpeed}
              showDismissed={showDismissed}
              handleDismissedChange={handleShowDismissedChange}
              speedRanges={speedRanges}
              minDurations={minDurations}
              minSpeeds={minSpeeds}
              minSpeed={minSpeed}
              minDuration={minDuration}
              handleExcessSpeedChange={handleExcessSpeedChange}
              handleMinSpeedChange={handleMinSpeedChange}
              handleMinDurationChange={handleMinDurationChange}
              sortCol={detailTableSortCol}
              desc={detailTableDesc}
              handleSort={handleDetailTableSort}
              csvDetailHeaders={singleVehicleTableColumns}
              dateStr={dateStr}
              setIncidentParent={setIncidentParent}
              dbName={dbName}
              dbDisplayName={dbDisplayName}
            />)}
          />
          <Route 
            path="/speedn"
            render={() => (
              <div>
                <Header subHeader={group.name !== null ? group.name : group.id} />
                <div className="speedn-top-panels">
                  <SpeednSummaryPanels
                    incidents={filteredEvents}
                    group={group}
                    vehicles={matchingVehicles.values}
                    childVehicleCount={childVehicleCount}
                  />
                  <SpeednControls
                    additionalFieldsGroup={ADDITIONAL_FIELDS_GROUP}
                    excessSpeed={excessSpeed}
                    showDismissed={showDismissed}
                    minSpeed={minSpeed}
                    speedRanges={speedRanges}
                    minDurations={minDurations}
                    minDuration={minDuration}
                    minSpeeds={minSpeeds}
                    groups={groups}
                    selectedGroups={selectedGroups}
                    departments={departments}
                    fuelGroups={null}
                    group={group}
                    department={department}
                    vehicleClasses={vehicleClasses}
                    selectedVehicleClasses={selectedVehicleClasses}
                    updateSelectedClasses={function(data){_setSelectedVehicleClasses(data);_setApiReqFlag(false);}}
                    beginDate={beginDate}
                    endDate={endDate}
                    handleMinSpeedChange={handleMinSpeedChange}
                    handleMinDurationChange={handleMinDurationChange}
                    handleExcessSpeedChange={handleExcessSpeedChange}
                    handleGroupChange={handleGroupChange}
                    handleSelectedGroupsChange={handleSelectedGroupsChange}
                    handleDepartmentChange={handleDepartmentChange}
                    handleShowDismissedChange={handleShowDismissedChange}
                    setBeginDate={function(data){setBeginDate(data);_setApiReqFlag(false);}} 
                    minDate={new Date(Date.parse(MIN_DATE))}
                    setEndDate={function(data){setEndDate(data);_setApiReqFlag(false);}}
                    geotabIntegrated={geotabIntegrated}
                    setMatchingVehicles={setMatchingVehicles}
                    compiledEvents={compiledEvents}
                  />
                </div>

                <nav className="speedn-button-wrapper">
                  <SpeednLink className="speedn-tab-button" id={'speedn/summarytable'} label={'Summary Table'}/>
                  <SpeednLink id={'speedn/detailtable'} label={'Detail Table'}/>
                  <SpeednLink id = {'speedn/weeklysummary'} label={'Summary By Week'}/>
                  <SpeednLink id = {'speedn/map'} label={'Map'}/>
                  {/* Empty Tab used to fill the border for this wrapper */}
                  <div className='speedn-nav-empty-tab'> </div>
                </nav>
                <div className="speedn-tab-wrapper">
                  <Switch>
                    <Route path="/speedn/summarytable" render={(props) => (
                    <SpeednSummaryTable 
                        columns={summaryTableColumns} 
                        data={matchingVehicles.values} 
                        filter={matchingVehicles.searchTerm}
                        sortCol={summaryTableSortCol}
                        desc={summaryTableDesc}
                        handleSort={handleSummaryTableSort}
                        beginDate={beginDate}
                        endDate={endDate}
                        group={group}
                        vehicleClasses={selectedVehicleClasses}
                        excessSpeed={excessSpeed}
                        minSpeed={minSpeed}
                        minDuration={minDuration}
                        tableType={'Summary'}
                        dbDisplayName={dbDisplayName}
                        csvFilename={csvSummaryFilename}
                    />
                    )}/>  
                    <Route path="/speedn/detailtable" render={(props) => (<SpeednDetailTable
                        parent={'detailtable'}
                        setIncidentParent={setIncidentParent}
                        columns={detailTableColumns} 
                        data={filteredEvents}
                        filter={matchingVehicles.searchTerm}
                        sortCol={detailTableSortCol}
                        desc={detailTableDesc}
                        beginDate={beginDate}
                        endDate={endDate}
                        group={group}
                        vehicleClasses={selectedVehicleClasses}
                        excessSpeed={excessSpeed}
                        minSpeed={minSpeed}
                        minDuration={minDuration}
                        tableType={'Detail'}
                        dbDisplayName={dbDisplayName}
                        csvFilename={csvDetailTableFilename}
                    /> )}/>
                    <Route path="/speedn/weeklysummary" 
                      render={((props) => (<SpeednWeeklySummaryTable
                        events={filteredEvents}
                        filter={matchingVehicles.searchTerm}
                        group={group}
                        columns={detailTableColumns}
                        beginDate={beginDate}
                        endDate={endDate}
                        setBeginDate={function(data){setBeginDate(data);_setApiReqFlag(false);}} 
                        setEndDate={function(data){setEndDate(data);_setApiReqFlag(false);}}
                        excessSpeed={excessSpeed}
                        showDismissed={showDismissed}
                        handleDismissedChange={handleShowDismissedChange}
                        speedRanges={speedRanges}
                        minDurations={minDurations}
                        minDuration={minDuration}
                        minSpeeds={minSpeeds}
                        minSpeed={minSpeed}
                        handleExcessSpeedChange={handleExcessSpeedChange}
                        handleMinSpeedChange={handleMinSpeedChange}
                        handleMinDurationChange={handleMinDurationChange}
                        sortCol={weeklyTableSortCol}
                        desc={weeklyTableDesc}
                        handleSort={handleWeeklyTableSort}
                        csvDetailHeaders={csvDetailHeaders}
                        vehicleClasses={selectedVehicleClasses}
                        dateStr={dateStr}
                        dbDisplayName={dbDisplayName}
                      />))} />
                    <Route path="/speedn/graph" render={(props) => (<SpeednGraph data={filteredEvents} /> )}/>
                    <Route path="/speedn/map" render={(props) => (<SpeednMap
                     events={filteredEvents}
                     filter={matchingVehicles.searchTerm}
                     isSummary={true}
                     beginDate={beginDate}
                     endDate={endDate}
                     group={group}
                     vehicleClasses={selectedVehicleClasses}
                     excessSpeed={excessSpeed}
                     minSpeed={minSpeed}
                     minDuration={minDuration}
                     dbDisplayName={dbDisplayName}/> )}/>
                  </Switch>
                </div>
              </div>
            )}
          />
        </Switch>
    </div>
  );
}

// ** PRIVATE FUNCTIONS **

function _getBounds(req, cb) {
  if(req.beginDate || req.endDate)return;
  fetch(`${req.apiURL}getBounds?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      //return the last day of data and 30 days prior as bounds
      if(data && data.data.length > 0){
        //override the api bounds are return the last 30 days
        //to handle issues with San Francisco
        const e = new Date();
        const b = new Date();
        b.setDate(b.getDate()-30);
        if(cb)cb({beginDate: b, endDate: e});
      }
    });
}

function _filterAgainstGeotabRes(data, geotabObj){
  if(!geotabObj || !geotabObj.groups || geotabObj.groups.length < 1)return [];
  const allowedGeotab = geotabObj.groups.map((g) => {return g.id});
  allowedGeotab.push("swt-vehicles");
  const allowedGroups = [];
  data.forEach((g) => {
    if(allowedGeotab.indexOf(g.id) > -1){
      const f = geotabObj.groups[allowedGeotab.indexOf(g.id)];
      if(f)g.children = f.children;
      else g.children = [];
      allowedGroups.push(g);
    }
  });
  if(allowedGroups.length < 1)allowedGroups.push({"id": "0", "name": "Not Available", "children": []});
  return allowedGroups;
}

function _getGroupChildrenVehicleCount(req, group, cb){
  const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${group.id}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const parentVINs = group.vehicles.map((v) => {return v.vin});
        const childVINs = []
        data.data.forEach((g)=>{
          g.vehicles.forEach((v) =>{
            if(parentVINs.indexOf(v) < 0)childVINs.push(v);
          })
        });
        cb(childVINs.length);
      }
    });
}

function _getGroups(req, cb, params){
  const url = `${req.apiURL}getGroups?dbName=${req.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const idx = data.data.findIndex(g => g.id === ALL_VEHICLES_GROUP);
        if(idx > -1) {
          const obj = data.data[idx];
          data.data.splice(idx, 1);
          data.data.unshift(obj);
        };
        if(params){
          const g0 = _filterAgainstGeotabRes(data.data, params.geotabGroups);
          const g1 = _filterAgainstGeotabRes(data.data, params.departments);
          //const g2 = _filterAgainstGeotabRes(data.data, params.additionalFields);
          params.geotabGroups.setter(g0);
          params.group.setter(g0[0]);
          params.departments.setter(g1);
          //params.additionalFields.setter(g2);
        }
        else{
          cb(data.data);
        }
      }
      else{
        if(cb)cb([]);
      }
    });
}

function _recursiveChildrenFetch(req, group, cb){
  const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${group.id}&depth=1`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        group.children = data.data.filter((g) => g.depth > 0);
        if(cb)cb(group);
      }
    })
}

function _getAdditionalFieldsGroups(req, groups, cb){

  const afGroup = groups.filter((g)=> g.name===ADDITIONAL_FIELDS_GROUP);
  if(!afGroup || afGroup.length < 0)return;
  const url = `${req.apiURL}getGroupChildrenForGroup?dbName=${req.dbName}&group=${afGroup[0].id}&depth=1`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(cb && data && data.data.length > 0){
        const children = data.data.filter((g) => g.depth > 0);
        children.forEach((child) => {
          _recursiveChildrenFetch(req, child, function(){});
        });
        const grps = JSON.parse(JSON.stringify(groups));
        const idx = groups.findIndex(g => g.name === ADDITIONAL_FIELDS_GROUP);
        grps[idx].children = children;
        if(cb)cb(grps);
      }
      else{
        if(cb)cb([]);
      }
    });
}

function compileDuration(duration){
  let secs = duration.seconds ? duration.seconds : 0;
  secs += duration.minutes ? duration.minutes * 60 : 0;
  secs += duration.milliseconds ? duration.milliseconds / 1000 : 0;
  return parseInt(secs);
}

function _getEvents(req, bounds, cb) {
  if(!req.group.id || req.group_id === ''){if(cb)cb([]);return;}
  const excessKmph = MPH2KMPH(req.excessSpeed);
  const minKmph = MPH2KMPH(req.minSpeed);
  const minDuration = req.minDuration;
  const showDismissed = req.showDismissed;
  if(typeof req.group.id === 'undefined'){cb([]);return;}
  if(!bounds.beginDate || !bounds.endDate){cb([]); return;}
  let r = `${req.apiURL}getSpeedingEvents?dbName=${req.dbName}&start=${_formatDateParams(bounds.beginDate)}&stop=${_formatDateParams(bounds.endDate)}&group=${req.group.id}&excess=${excessKmph}`;
  if(typeof req.groups !== 'undefined' && req.groups.length > 0){
    r = `${req.apiURL}getSpeedingEvents?dbName=${req.dbName}&start=${_formatDateParams(bounds.beginDate)}&stop=${_formatDateParams(bounds.endDate)}&groups=${req.groups}&excess=${excessKmph}`;
  }

  const el = document.getElementById("speedn-root");
  if(el)el.style.opacity = "0.3";

  fetch(r, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => {return resp.json()})
    .then((data) => {
      let arr = [];
      if(req.selectedVehicleClasses.length > 0){
        data.data.forEach((e) => {
          e.compiledDuration = compileDuration(e.duration);
          if(e.vehicle_class === null || e.vehicle_class === '')e.vehicle_class = "Undefined";
          if((req.selectedVehicleClasses.indexOf(e.vehicle_class) > -1) &&
          (e.kmph >= minKmph) &&
          (e.compiledDuration >= minDuration)){
            arr.push(e);
          }
        });
      }
      else{
        data.data.forEach((e)=>{
          e.compiledDuration = compileDuration(e.duration);
        });
        arr = data.data.filter((e) => e.compiledDuration >= minDuration && e.kmph >= minKmph);
      }
      //unit conversion
      arr.forEach((e) =>{
        e.postedMph =  Math.round(parseFloat(KMPH2MPH(e.posted_kmph)));
        e.mph = Math.round(parseFloat(KMPH2MPH(e.kmph)));
        e.excessMph = parseInt(e.mph-e.postedMph);
        e.valid_text = "Valid";
        if(e.valid === false)e.valid_text = "Dismissed";

        //remove fractional seconds

        const ts0 = e.local_start.split(".");
        const ts1 = e.local_stop.split(".");
        e.ts = ts0[0];
        e.local_start = ts0[0]
        e.local_stop = ts1[0]
      });
      if(showDismissed === false){
        arr = arr.filter((a) => {if(a.valid === true || a.valid === null)return a; else return null});
      }
      if(arr && arr.length > 0){
        if(el)el.style.opacity = "1";
        if(cb)cb(arr);
      }
      else{
        if(el)el.style.opacity = "1";
        if(cb)cb([]);
      }
    });
}

function _getVehicleClasses(req, cb) {
  fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if(data && data.data.length > 0){
        let arr = data.data.map((e) => {
          if(e.vehicle_class === '')e.vehicle_class = 'Unknown';
          if(e.vehicle_class === null)e.vehicle_class = 'Undefined';
          return e.vehicle_class
        });
        const idx = arr.indexOf('Unknown');
        if(idx > -1){
          const o = arr[idx];
          arr.splice(idx, 1);
          arr.push(o);
        }
        if(cb)cb(arr);
      }
      else{
        if(cb)cb([]);
      }
    });
}

function _formatDateParams(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

