import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import DropdownSelector from "./speedn-dropdown";
//import {components} from 'react-select';
import AdditionalFieldsModal from "./speedn-additional-fields-modal";
import Search from "../speedn-components/speedn-search";
//import SpeednFuelButtons from "./speedn-fuel-buttons";
import { MultiSelect } from 'react-multi-select-component';


export default function SpeednControls(props){
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [additionalFieldsAvailable, _setAdditionalFieldsAvailable] = useState(false);
  const [selected, setSelected] = useState([]);
  const [updatedVehicleClasses, setUpdatedVehicleClasses] = useState([])
  const { group, groups, selectedGroups, department, departments, minSpeeds, minDurations, speedRanges, beginDate, endDate, setBeginDate, setEndDate, geotabIntegrated, setMatchingVehicles, compiledEvents, selectedVehicleClasses } = props;
  const location = useLocation();
  let excessDropdown = false;
  if(location.pathname === "/speedn/graph")excessDropdown = true;
  const handleShowDismissedChange = function(e){
    props.handleShowDismissedChange(e.target.checked);
  }
  const handleAdditionalFieldsButton = () => {
    const b = isModelOpen ? false : true;
    setIsModalOpen(b);
  }
  
  useEffect(() => {
    setUpdatedVehicleClasses(['All Classes', ...props.vehicleClasses])
  },[props.vehicleClasses])

  useEffect(()=>{
    if(groups){
      const afGroup = groups.filter((g) => g.name === props.additionalFieldsGroup);
      if(afGroup[0] && afGroup.length > 0)_setAdditionalFieldsAvailable(true);
    }
  },[groups, props.additionalFieldsGroup])

  useEffect(() => {
    let arr = []
      selected.forEach((item) => {
        arr.push(item.value)
      })
    props.updateSelectedClasses(arr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selected])

  const customValueRenderer = (selected, _options) => {
    if (selected.length === 1 && selected[0].value === 'All Classes') {
      return <p>All Classes</p>
  } else if (selected.length === 1) {
        return <p>1 Class</p>
    } else if (selected.length > 1 && selected.length < _options.length) {
        return <p>{selected.length} Classes</p>
    }
  };

  const formatSelectValues = (selectedClasses) => {
      let classArr = [];
      if (selectedClasses.length === updatedVehicleClasses.length - 1 || selectedClasses[selectedClasses.length - 1] === 'All Classes' || selectedClasses.length === 0) {
         classArr.push({value: 'All Classes', label: 'All Classes'})
      } else if (selectedClasses.length !== updatedVehicleClasses.length - 1) {
          selectedClasses.filter(word => word !== 'All Classes').map(item => {
            return classArr.push({value: item, label: item})
          })
       } 
      return classArr;
  }

  const handleSelectChange = (e) => {
    if (e.length === 0) {
      return;
    } else {
      if (e[e.length - 1].value === 'All Classes' || e.length === props.vehicleClasses.length) {
      //By default an empty array will return data for all the vehicle classes
       setSelected([])
      } else {
        setSelected(e)
      }
    }
  }

  return(
        <div className="speedn-top-controls-wrapper" id="controls-root">

        <div className="speedn-controls-left">

          <div className="speedn-input-group-wrapper">
            <label className='speedn-controls-label'>Groups:</label>
            {true &&
              <DropdownSelector
                className="speedn-dropdown-group"
                id="groups-dd"
                key="groups-dd"
                disabled={false}
                options={groups}
                defaultValue={group.name}
                handleChange={props.handleGroupChange}
                searchEnabled={true}
              />
            }
          </div>
          {geotabIntegrated &&
          <div className="speedn-input-group-wrapper">
            <label className='speedn-controls-label'>Department List:</label>
            <DropdownSelector
              className='speedn-dropdown-group'
              id="depts-dd"
              key="depts-dd"
              disabled={false}
              options={departments}
              defaultValue={department.id}
              handleChange={props.handleDepartmentChange}
              searchEnabled={false}
            />
          </div>
          }
          {!geotabIntegrated &&
          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Vehicle Classes:</label>
            <MultiSelect
              value={formatSelectValues(selectedVehicleClasses)}
              valueRenderer={customValueRenderer}
              ClearSelectedIcon={null}
              hasSelectAll={false}
              onChange={handleSelectChange}
              options={updatedVehicleClasses.map((item) => {
                return {value: item, label: item}
                })}
                overrideStrings={{
                  "allItemsAreSelected": 'All Classes',
                  "selectSomeItems": 'Select Classes',
                }}
            />
          </div>
        } 
          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Filter:</label>
            <Search
              className="speedn-search-box"
              allValues={compiledEvents}
              setMatchingValues={setMatchingVehicles}
            />
          </div>
            <div className='speedn-radio-group-wrapper'>
              <label>Show Invalidated:</label>
              <input className='speedn-dismissed-checkbox' type="checkbox" checked={props.showDismissed} onChange={handleShowDismissedChange}/>
          </div>
          </div>

          <div className="speedn-controls-right">
          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Date Range:</label>
            <div className="speedn-datepickers-container">
              <DatePicker
                className="speedn-datepicker"
                selected={beginDate}
                onChange={(date) =>{ setBeginDate(date) }}
                selectsStart
                minDate={props.minDate}
                maxDate={new Date()}
                startDate={beginDate}
                endDate={endDate}
                showMonthDropdown
                useShortMonthInDropdown

              />
              <DatePicker
                className="speedn-datepicker"
                selected={endDate}
                onChange={(date) => { setEndDate(date) }}
                selectsEnd
                endDate={endDate}
                minDate={beginDate}
                maxDate={new Date()}
                showMonthDropdown
                useShortMonthInDropdown
              />
            </div>
          </div>
          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Excess Vehicle Speed:</label>
              <DropdownSelector
                disabled={excessDropdown}
                className="speedn-dropdown-small"
                options={speedRanges} 
                handleChange={props.handleExcessSpeedChange}
                defaultValue={props.excessSpeed}
              />
          </div>

          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Minimum Vehicle Speed:</label>
              <DropdownSelector
                disabled={excessDropdown}
                className='speedn-dropdown-small'
                options={minSpeeds} 
                handleChange={props.handleMinSpeedChange}
                defaultValue={props.minSpeed}
              />
          </div>
          <div className='speedn-input-group-wrapper'>
            <label className='speedn-controls-label'>Minimum Duration (Seconds):</label>
              <DropdownSelector
                disabled={excessDropdown}
                className='speedn-dropdown-small'
                options={minDurations} 
                handleChange={props.handleMinDurationChange}
                defaultValue={props.minDuration}
              />
          </div>
          <>
            {/*
            1/24/2024 MH: Disabling AF per Sarah Booth's comment thread in 
            slack sf channelon December 15, 2023
            */}
            {false && additionalFieldsAvailable &&

              <div className="speedn-vehicle-class-btn-wrapper">
                <button 
                  className="speedn-additional-fields-button"
                  onClick={handleAdditionalFieldsButton}>
                    Additional Fields Filter
                </button>
              </div>
            }
            {(groups && groups.length > 0 && additionalFieldsAvailable) &&
              <AdditionalFieldsModal 
                isOpen={isModelOpen}
                groups={groups}
                selectedGroups={selectedGroups}
                geotabIntegrated={geotabIntegrated}
                additionalFieldsAvailable={additionalFieldsAvailable}
                additionalFieldsGroup={props.additionalFieldsGroup}
                handleChange={setIsModalOpen}
                handleSelectedGroupsChange={props.handleSelectedGroupsChange}
              />
            }
          </>
      </div>
      </div>
  )
}
/*
const ValueContainer = (props) => {
  const l = props.getValue().length;
  return (
    <components.ValueContainer {...props}>
      {l > 1 ? (
        <>{!props.selectProps.menuIsOpen && `${l} Classes`}</>
      ) : (
        <>{props.children}</>
      )}
    </components.ValueContainer>
  );
};
*/