import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import CreateUserModal from "./CreateUserModal";
import UpdatePasswordModal from "./swt-update-password";
import UpdateUserGroupsModal from "./UpdateUserGroupsModal";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import {formatDateTimeUTC} from '../UtilityFunctions';
import { Roles, assignUserRole } from "../../utility-components/UserUtils";
import { _boolSort, _stringSort } from "./TableHelpers";

const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";

export default function AdminUsers(props) {
  const { apiURL, db, dbDisplayName, user } = props;

  const [selectedUser, setSelectedUser] = useState(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [userDBs, setUserDBs] = useState([]);

  const [users, setUsers] = useState(([]));
  const [groups, setGroups] = useState([]);
  const [databases, setDatabases] = useState(() => getDatabases());

  const skipPageResetRef = useRef(true);

  function SearchColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <S.AdminTableColumnSearchBar
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search by Email`}
      />
    );
  }

  function _dateSort(rowA, rowB) {
    let a = new Date(rowA.original.original_timestamp);
    let b = new Date(rowB.original.original_timestamp);
    return a > b ? 1 : -1
  }

  const tableColumns = [
    {Header: "Email", accessor: "email" , Filter: SearchColumnFilter, sortType: _stringSort},
    {Header: "Last Login", accessor: "last_login", width: 200, sortType: _dateSort},
    {Header: "Primary Database", accessor: 'primary_db', width: 200, sortType: _stringSort},
    {Header: "Basic User", accessor: "basic_user", width: 100, sortType: _boolSort},
    {Header: "Fleet Admin", accessor: "fleet_admin", width: 100, sortType: _boolSort},
    {Header: "Partner Admin", accessor: "partner_admin", width: 100, sortType: _boolSort},
    {Header: "Super Admin", accessor: "super_admin", width: 100, sortType: _boolSort},
    {Header: "Developer", accessor: "developer", width: 150, sortType: _boolSort},
    {Header: "Update Groups", accessor: "update_groups", disableSortBy: true, canSort: false, width: 180},
    {Header: "Delete User", accessor: "delete_user", disableSortBy: true, canSort: false, width: 180}
  ]

  let hiddenColumns = [];
  switch(user.role) {
    case Roles.FleetAdmin:
      hiddenColumns = ['developer', 'super_admin', 'primary_db'];
      break;
    case Roles.PartnerAdmin:
      hiddenColumns = ['developer', 'super_admin'];
      break;
    case Roles.SuperAdmin:
      hiddenColumns = ['developer'];
      break;
    case Roles.SuperAdminDeveloper:
      hiddenColumns = [];
      break;
    default:
      hiddenColumns = ['developer', 'super_admin', 'partner_admin', 'fleet_admin', 'primary_db'];
  }

const BoolTableCell = useCallback((props) => 
      <input
        className='swt-admin-table-input'
        type="checkbox"
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool} 
        disabled={props.disabled ? true : false}
        email={props.email}
        onChange={props.handleOnClick}
      />,[]);

const TableButton = useCallback((props) => 
  <S.InnerTableButton data-testid={props.data_testid} disabled={props.disabled} color={props.color} onClick={props.handleClick}>
    {props.label}
  </S.InnerTableButton>,[]);

const showUpdateGroupsModal = useCallback((username) => {
  setShowGroupsModal(!showGroupsModal);
  setSelectedUser(username);
}, [showGroupsModal]);

useEffect(() => {
  getUserRecords();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[apiURL, db, user])

useEffect(() => {
  const url = `${props.apiURL}getGroups/${props.db}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${props.user.token}` },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === "error") 
        alert(SERVER_ERRORED_MESSAGE);
      else {
        const obj = data.data;
        obj.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setGroups(obj);
      }
    })
    .catch((err) => console.error(err));
}, [props.apiURL, props.db, props.user])

function getUserRecords () {
  let url = `${apiURL}getUsers/`;
  if (db) url = `${apiURL}getUsers?account=${db}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${user.token}` },
  })
    .then(props.handleFetchErrors)
    .then((res) => res.json())
    .then((json) => {
      if (json.status === "error") 
        alert(SERVER_ERRORED_MESSAGE);
      else {
        let sorted = json.data.sort((a, b) => (a.email > b.email ? -1 : 1));
        getSelectUserDatabases(json.data);
        sorted.forEach((u) => {
          u.role = assignUserRole(u); // Assign each user their role
        })
        if (user.role <= Roles.PartnerAdmin) {
          const filtered = sorted.filter(e => e.role <= Roles.PartnerAdmin) // Filter internal super admins/developers out. Only show basic user, fleet, and partner admins
          setUsers(filtered);
        }
        else {
          setUsers(sorted);
        }
      }
    })
    .catch((err) => console.error("error on getUsers fetch", err));
}
const deleteUser = useCallback((data) => {
  if (data === null || data === undefined)
    console.error("no data for request");
  let url = `${props.apiURL}deleteUser`;
  try {
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") 
          alert(SERVER_ERRORED_MESSAGE);
        else 
          alert('User account has been deleted.')
          getUserRecords();
      });
  } catch (err) {
    console.error("error", err);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[props.apiURL, props.user.token, props.db]);

const deleteUserMembership = useCallback((data) => {
  if (data === null || data === undefined)
    console.error("no data for request");
  data.dbName = props.db;
  let url = `${props.apiURL}deleteUserMembership`;
  try {
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") 
          alert(SERVER_ERRORED_MESSAGE);
        else
          alert('User account has been removed from this database.')
          getUserRecords()
      });
  } catch (err) {
    console.error("error", err);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[props.apiURL, props.user.token, props.db]);

const handleDeleteClick = useCallback((username, userObject) => {
  let answer;
  if (userObject.databases.length === 1) {
    answer = window.confirm(
      `Are you sure you wish to delete the user ${username} from ${dbDisplayName}? This will permanently delete the user account. This action is irreversible.`
    )
  } else {
    answer = window.confirm(
    `Are you sure you wish to delete the user ${username} from ${dbDisplayName}? This will remove the users access to this database.`
  );
  }
  
  if (answer) {
    const d = { username: username };
    if(userObject.databases.length === 1)deleteUser(d);
    else deleteUserMembership(d);
    const updatedUserList = users.filter((u) => u.email !== username);
    setUsers(updatedUserList);
  }
},[deleteUserMembership, deleteUser, users, dbDisplayName]);

const mappedUsers = useMemo(() => {
  if(!users) {return null}
  const ImmutableUserList = JSON.parse(JSON.stringify(users))

  return ImmutableUserList.filter((u) => {
    let deleteDisabled = false;
    let updateDisabled = false;
    if (user.role <= Roles.PartnerAdmin) { // If partner admin and below, enforce button disable rules
      deleteDisabled = user.role <= u.role; // Disable delete buttons for users at and above the logged in user
      updateDisabled = user.role <= u.role && user.email !== u.email; // Disable update buttons for users at and above the logged in user, except for the logged in user
    }
    //orginal_timestamp is used for table sorting.
    u.original_timestamp = JSON.parse(JSON.stringify(u.last_login))
    u.last_login = formatDateTimeUTC(u.last_login);
    u.basic_user = <BoolTableCell disabled={true} accessor={"basic_user"} email={u.email} bool={u.basic_user} handleOnClick={() => {}}/>;
    u.fleet_admin = <BoolTableCell disabled={true} accessor={"fleet_admin"} email={u.email} bool={u.fleet_admin} handleOnClick={() => {}}/>;
    u.partner_admin = <BoolTableCell disabled={true} accessor={"partner_admin"} email={u.email} bool={u.partner_admin} handleOnClick={() => {}}/>;
    u.super_admin = <BoolTableCell disabled={true} accessor={"super_admin"} email={u.email} bool={u.super_admin} handleOnClick={() => {}}/>;
    u.developer = <BoolTableCell disabled={true} accessor={"developer"} email={u.email} bool={u.developer} handleOnClick={() => {}}/>;
    u.update_groups = <TableButton data_testid={"updateGroups-testid"} disabled={updateDisabled} handleClick={() => updateDisabled ? null : showUpdateGroupsModal(u.email)} label={'Update'} />
    u.delete_user = <TableButton data_testid={"deleteUser-testid"} disabled={deleteDisabled} handleClick={() => deleteDisabled ? null : handleDeleteClick(u.email, u)} label={'Delete'} color={'#e01f1f'} />
    return u;
  })
// eslint-disable-next-line react-hooks/exhaustive-deps
},[users, showUpdateGroupsModal, dbDisplayName])


  // Start of API Calls

  function getDatabases() {
    let url = `${props.apiURL}getDatabases`;
    try {
      fetch(url, {
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE);
          else 
            setDatabases(data.data);
        });
    } catch (err) {
      console.error("error", err);
    }
  }

  const getSelectUserDatabases = useCallback(
    (usrs) => {
      if (!usrs) usrs = users;
      if (!usrs || usrs.length < 1) return;
      let completedDBs = [];
      usrs.forEach((u, i) => {
        let url = `${props.apiURL}getDatabasesForUser/${u.email}`;
        try {
          fetch(url, {
            headers: { Authorization: `Bearer ${props.user.token}` },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              if (resp.status === "error")
                alert(SERVER_ERRORED_MESSAGE);
              else {
                let obj = {
                  email: resp.data[0].email,
                  dbs: resp.data.map((db) => {
                    return db.database;
                  }),
                };
                completedDBs.push(obj);
                if (completedDBs.length === usrs.length) setUserDBs(completedDBs);
              }
            });
        } catch (err) { }
      });
    },
    [props.apiURL, props.user.token, users]
  );
  
  const createUserObject = useCallback(() => {
    if (!users || !userDBs || (users.length < 1 && userDBs.length < 1)) return;
    else {
      let arr = users.map((user) => {
        let otherDBs = userDBs.filter((u) => u.email === user.email);
        return {
          admin: user.admin,
          basicUser: user.basic_user,
          fleetAdmin: user.fleet_admin,
          partnerAdmin: user.partner_admin,
          superAdmin: user.super_admin,
          db: user.primary_db,
          developer: user.developer,
          email: user.email,
          lastLogin: user.last_login,
          otherDBs: otherDBs[0] === undefined ? [] : otherDBs[0].dbs,
        };
      });
      setUpdatedUsers(arr);
    }
  }, [userDBs, users]);

  useEffect(() => {
    if (users && userDBs && users.length > 0 && userDBs.length > 0)
      createUserObject();
  }, [users, databases, userDBs, createUserObject]);

  function updatePassword(account, password) {
    let data = { account: account, password: password };
    let url = `${props.apiURL}updateUserPassword/`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error")
          window.alert("There was a server error during your request.");
      });
  }

  function createUser(userData) {
    let url = `${props.apiURL}createUser/`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            window.alert('New user has been added.')
            getUserRecords();
            props.createNewUserPassword(userData.username);
          } else {
            window.alert(SERVER_ERRORED_MESSAGE);
          }
        });
    } catch (err) {
      console.error("err", err);
    }
  }

  // END OF API CALLS



  function createUserHandler(data) {
    createUser(data);
    setShowCreateUserModal(false);
  }
  if (
    !updatedUsers[0] ||
    !updatedUsers[users.length - 1] ||
    !updatedUsers[users.length - 1].otherDBs
  )
    return <Loading />;
  return (
    <>
      <S.TableHeaderContainer>
        <S.AdminTableExplainTextPrimary>List of users with access to {dbDisplayName}.</S.AdminTableExplainTextPrimary>
        <S.AdminTableExplainTextSub>Assign vehicle access by selecting groups. Reach out to your Sawatch account manager to request changes to user access roles.</S.AdminTableExplainTextSub>
        <S.AdminTableExplainList>
          {user.role >= Roles.BasicUser && <S.AdminTableExplainBullets>Basic User: User has view only access and the ability to download reports from the dashboard.</S.AdminTableExplainBullets>}
          {user.role >= Roles.FleetAdmin && <S.AdminTableExplainBullets>Fleet Admin: User has access to view the dashboard, download reports, and adjust limited settings through the Settings Cog.</S.AdminTableExplainBullets>}
          {user.role >= Roles.PartnerAdmin && <S.AdminTableExplainBullets>Partner Admin: User has access to view the dashboard, download report, and adjust multiple settings through the Settings Cog.</S.AdminTableExplainBullets>}
        </S.AdminTableExplainList>
        <S.CtaButton disabled={groups.length === 0} data-testid={"addUserBtn-testid"} onClick={() => setShowCreateUserModal(!showCreateUserModal)}>Add</S.CtaButton>
        
      </S.TableHeaderContainer>
      <AdminTableView 
        columns={tableColumns}
        hiddenColumns={hiddenColumns}
        data={mappedUsers}
        stickyCols={2}
        skipPageResetRef={skipPageResetRef}
        noDataMessage={'No users to display. Click "Add" to create a new user.'}
      />
      <>
        {groups && (
          <CreateUserModal
            user={props.user}
            db={props.db}
            dbDisplayName={dbDisplayName}
            groups={groups}
            show={showCreateUserModal}
            handleClose={() => setShowCreateUserModal(!setShowCreateUserModal)}
            createUser={createUserHandler}
            passwordRules={props.passwordRules}
          />
        )}
      </>
      <>
        {showGroupsModal && (
          <UpdateUserGroupsModal
            db={props.db}
            updateGroups={props.updateGroups}
            saveGroupsChange={props.saveGroupsChange}
            show={showGroupsModal}
            user={props.user}
            apiURL={props.apiURL}
            selectedUser={selectedUser}
            groups={groups}
            showUpdateGroupsModal={showUpdateGroupsModal}
          />
        )}
      </>
      <UpdatePasswordModal
        show={showPasswordModal}
        handleClose={() => setShowPasswordModal(!showPasswordModal)}
        user={selectedUser}
        passwordRules={props.passwordRules}
        updatePassword={updatePassword}
      />
    </>
  );
}
