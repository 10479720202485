import React from 'react';
import { withRouter } from 'react-router-dom';
import UpdateLocationForm from './UpdateLocationForm';
import PermissionError from './PermissionError';
import { Roles } from "../core-components/utility-components/UserUtils";

class UpdateLocationController extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      errors: [],
      pkid: '',
      address: '',
      suburb: '',
      city: '',
      postal: '',
      nickname: '',
      level1: '',
      level2: '',
      dcfast: '',
      landuse: '',
      og_latitude: '',
      og_longitude: '',
      new_latitude: '',
      new_longitude: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.tidyInput = this.tidyInput.bind(this);
    this.handleSelectorChange = this.handleSelectorChange.bind(this);
  }

  componentDidMount(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    var locId = this.props.location.state.locId;
    if(locId){
      var url = `${this.props.base_url}getChargeLocation?`;
      var queries = `&clientId=${this.props.db}&locId=${locId}`;
      fetch(`${url}${queries}`, {headers:{Authorization: `Bearer ${this.props.user.token}`}})
      .then(res => res.json())
      .then(data => {
        var loc = data['data'][0];
        this.setState({
          pkid: (loc.pkid?loc.pkid:""),
          address: (loc.address?loc.address:""),
          suburb: (loc.suburb?loc.suburb:""),
          city: (loc.city?loc.city:""),
          postal: (loc.postal?loc.postal:""),
          nickname: (loc.nickname?loc.nickname:""),
          level1: (loc.level1?loc.level1:""),
          level2: (loc.level2?loc.level2:""),
          dcfast: (loc.dcfast?loc.dcfast:""),
          landuse: (loc.landuse?this.formatLanduse(loc.landuse):"-"),
          og_latitude: (loc.st_y?loc.st_y:""),
          og_longitude: (loc.st_x?loc.st_x:"")
        });
      })
      .catch(error => console.error("Error: " + error));
    }
  }

  handleInputChange(e){
    var name = e.target.name;
    var value = e.target.value;
    this.setState({[name]: value});
  }

  handleSelectorChange(e){
    this.setState({landuse: e.target.value});
  }

  handleMapClick(e){
    var latlng = e.lngLat;
    var loc = [latlng.lat.toFixed(5), latlng.lng.toFixed(5)];
    this.setState({new_latitude: loc[0], new_longitude: loc[1]});
  }

  handleBack(e){
    e.preventDefault();
    if(window.confirm("Are you sure you want to leave this page? Any unsaved changes will be lost.")){
      if(this.props.location.state.fromLoc === "map"){
        this.props.history.push({
          pathname: '/ionev/map',
          state: { selectedVcl: this.props.location.state.selectedVcl, mapType: this.props.location.state.mapType, locUpdated: true }
        });
      } else {
        this.props.history.push({pathname: '/ionev/events-by-location'});
      }
    } else {
      return;
    }
  }

  handleSubmit(e){
    e.preventDefault();
    var valid = this.validateInput();
    if(valid)this.tidyInput();
  }

  formatLanduse(landuse){
    return landuse.charAt(0).toUpperCase() + landuse.slice(1);
  }

  tidyInput(){
    var lat = this.state.new_latitude?parseFloat(this.state.new_latitude):this.state.og_latitude;
    var lon = this.state.new_longitude?parseFloat(this.state.new_longitude):this.state.og_longitude;
    var toSubmit = {'pkid': this.state.pkid, 'nickname': this.state.nickname,
                    'level1': this.state.level1, 'level2': this.state.level2,
                    'dcfast': this.state.dcfast, 'landuse': this.state.landuse.toLowerCase(),
                    'latitude': lat, 'longitude': lon,
                    'address': this.state.address,
                    'address_real': this.state.address};
    // hide address if location is residential
    if(toSubmit.landuse === 'residential'){
      var suburb = (this.state.suburb && this.state.suburb !== "")?this.state.suburb:this.state.city;
      if(this.state.suburb && this.state.suburb !== "") {
        suburb = this.state.suburb;
      } else if(this.state.city && this.state.city !== "") {
        suburb = this.state.city;
      } else {
        suburb = 'USA';
      }
      toSubmit.address = `Residential address in ${suburb}, ${this.state.postal}`;
    }
    // change any empty strings and strings of only spaces to null
    if(!/\S/.test(toSubmit.nickname))toSubmit.nickname = '';
    // replace single quote with escaped single quote
    toSubmit.nickname = toSubmit.nickname.replace(/'/g, "\\'");
    if(!/\S/.test(toSubmit.level1))toSubmit.level1 = null;
    if(!/\S/.test(toSubmit.level2))toSubmit.level2 = null;
    if(!/\S/.test(toSubmit.dcfast))toSubmit.dcfast = null;
    this.submitForm(toSubmit);
  }

  validateInput(){
    var errors = [];
    // validate details
    // check string can be converted to a number
    if(isNaN(this.state.level1))errors.push("Please enter a valid number of Level 1 chargers");
    if(isNaN(this.state.level2))errors.push("Please enter a valid number of Level 2 chargers");
    if(isNaN(this.state.dcfast))errors.push("Please enter a valid number of DC Fast chargers");
    // validate address and nickname
    var nname = this.state.nickname;
    if(nname.includes("*")||nname.includes("\\")||nname.includes("/")||nname.includes(":")||nname.includes("?")||nname.includes('\\"')||nname.includes("|")){
      errors.push("A nickname cannot contain any of the following characters: \\ / : * ? \" < > | ( )");
    }
    if(nname.length > 50)errors.push("A nickname cannot exceed 50 characters");
    var addr = this.state.address;
    if(addr.includes("*")||addr.includes("\\")||addr.includes("/")||addr.includes(":")||addr.includes("?")||addr.includes('\\"')||addr.includes("|")){
      errors.push("An address cannot contain any of the following characters: \\ / : * ? \" < > | ( )");
    }
    this.setState({errors: errors});
    if(errors.length > 0){
      return false;
    } else {
      return true;
    }
  }

  submitForm(toSubmit){
    var data = {location: toSubmit, clientId: this.props.db};
    var url = `${this.props.base_url}updateChargeLocation`;
    fetch(url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.user.token}`
      },
    })
    .then((resp) => {
      if(resp.status === 200){
        window.alert("Charge Location details successfully updated.");
      } else {
        window.alert("ERROR: Charge Location details could NOT be updated. Please try again.");
      }
      if(this.props.location.state.fromLoc === "map"){
        this.props.history.push({
          pathname: '/ionev/map',
          state: { selectedVcl: this.props.location.state.selectedVcl, mapType: this.props.location.state.mapType, locUpdated: true }
        });
      } else {
        this.props.history.push({pathname: '/ionev/events-by-location'});
      }
    })
    .catch((err) => {
      console.error("Error submitting form ", err);
    });
  }

  render(){
    const user = this.props.user;
    return (
      <div>
        {(user.role >= Roles.FleetAdmin) ?
          <div data-testid="locationForm-testid">
            <h2 className="ionev-settings-header ionev-h2">Edit Charge Location</h2>
            <UpdateLocationForm locationDetails={this.state}
                                 handleInputChange={this.handleInputChange}
                                 handleSubmit={this.handleSubmit}
                                 handleMapClick={this.handleMapClick}
                                 handleBack={this.handleBack}
                                 handleSelectorChange={this.handleSelectorChange}
                                 user = {this.props.user}
            />
          </div>
         : 
          <PermissionError/>
        }
      </div>
    );
  }
}

export default withRouter(UpdateLocationController);
