import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => (
    <ReactLoading
        type={'bars'}
        color={'rgba(182, 174, 174, 0.6)'}
        width="2"
        viewBox="0 0 2 2"
        height="2"
    />
);
export default Loading;
