import React, { Suspense } from 'react';
import DropdownSelector from './DropdownSelector';
import checkedBox from '../../images/ic-checkbox-7px.png';
import { Roles } from "../core-components/utility-components/UserUtils";

//code split imports
const EventsMap = React.lazy(() => import('./EventsMap'));
const FleetMap = React.lazy(() => import('./FleetMap'));

class MapContainer extends React.Component {
  constructor(props){
    var dispType = 'events';
    if(props.displayType)dispType = props.displayType;
    super(props);
    this.state = {
      displayType: dispType, // location, events
      showMissedOpFleet: true,
      showMissedOpPub: false,
      showChargeInst: true,
      resetMapFlag: false
    };
    this.setMissedOpFleet = this.setMissedOpFleet.bind(this);
    this.setMissedOpPub = this.setMissedOpPub.bind(this);
    this.setChargeInst = this.setChargeInst.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.clearMap = this.clearMap.bind(this);
    this.resetMap = this.resetMap.bind(this);
  }

  setMissedOpFleet() {
    this.setState({showMissedOpFleet: !this.state.showMissedOpFleet, publicEVSE: {}});
  }

  setMissedOpPub() {
    this.setState({showMissedOpPub: !this.state.showMissedOpPub, publicEVSE: {}});
  }

  setChargeInst() {
    this.setState({showChargeInst: !this.state.showChargeInst});
  }

  setMapType(type) {
    if(this.state.displayType !== type){this.setState({displayType: type});}
  }

  componentDidMount() {
    var mapType = 'all';
    if(this.props.mapType)mapType = this.props.mapType;
    if(this.props.location && this.props.location.state && this.props.location.state.mapType)mapType = this.props.location.state.mapType;
    if (mapType === 'charge-inst'){
      this.setState({showMissedOpFleet: false});
    } else if (mapType === 'missed-op'){
      this.setState({showChargeInst: false});
    }
    if(this.props.displayType && this.props.displayType === 'events'){
      this.setState({displayType: 'events'});
    }
  }

  clearMap() {
    this.props.handleSelectorReset();
    this.setState({publicEVSE: {}, fleetEVSE: {}, showMissedOpFleet: false,
                   showMissedOpPub: false, showChargeInst: false});
  }

  resetMap() {
    this.props.handleSelectorReset();
    this.setState({resetMapFlag: true});
  }


  render() {
    var chargeCt = this.props.locationActivity.reduce((acc, loc) => acc + +loc.charge_event_count, 0);
    var missedCt = this.props.locationActivity.reduce((acc, loc) => acc + +loc.missed_event_count, 0);
    const user = this.props.user;
    return (
      <div className="swt-ionev-map-container">
        <div className="swt-ionev-map-wrapper">
          <div className="swt-ionev-map-header">
            <div className="swt-ionev-map-header-content">
              <div className="swt-ionev-map-header-lhs">
                <div className="swt-ionev-map-type-selector-wrapper">
                  <p className="ionev-map-selector-label">Select Map View</p>
                  <div className="swt-ionev-map-type-selector">
                    <div onClick={() => this.setMapType('location')} className={"ionev-selector-1 ionev-map-type-location" + (this.state.displayType === 'location' ? " selected" : "")}>Location</div>
                    <div onClick={() => this.setMapType('events')} className={"ionev-selector-1 ionev-map-type-events" + (this.state.displayType === 'events' ? " selected" : "")}>Events</div>
                  </div>
                </div>
                <div className="swt-ionev-map-vcl-selector">
                  <p className="ionev-map-selector-label">Select Vehicles</p>
                  < DropdownSelector selectorName="ionev-map-vcl-selector ionev-selector-1" options={this.props.vclDetails} value={this.props.selectedVcl ? this.props.selectedVcl : ""} handleSelectorChange={this.props.handleSelectorChange}/>
                </div>
                { this.state.displayType === 'events' &&
                  <div className="swt-ionev-map-button-clear">
                    <button onClick={() => this.clearMap()} className="swt-ionev-button-clear-map">Clear map</button>
                  </div>
                }
                { this.state.displayType === 'location' &&
                  <div className="swt-ionev-map-button-reset">
                    <button onClick={() => this.resetMap()} className="swt-ionev-button-reset-map">Reset map</button>
                  </div>
                }
                { !(user.role >= Roles.FleetAdmin) &&
                  <div className="swt-ionev-map-asterisk" data-testid="residentialAsterisk-testid">Residential locations are excluded for non-admin users for privacy.</div>
                }
              </div>
              <div className="swt-ionev-map-header-rhs">
                { this.state.displayType === 'events' &&
                <>
                <p className="ionev-map-selector-label">Select Events</p>
                <div className="swt-ionev-map-checkboxes-div">
                  <div className="swt-ionev-map-checkbox-div">
                    <div className={"swt-ionev-map-checkbox " + (this.state.showChargeInst ? "checked" : "")} onClick={this.setChargeInst}><img className="swt-checkbox" src={ checkedBox } alt="Checked box" /></div>
                    <div className="swt-ionev-map-label"><span className="swt-ionev-map-marker charge-inst"></span>Charging Events</div>
                  </div>
                  <div className="swt-ionev-map-checkbox-div">
                    <div className={"swt-ionev-map-checkbox " + (this.state.showMissedOpFleet ? "checked" : "")} onClick={this.setMissedOpFleet}><img className="swt-checkbox" src={ checkedBox } alt="Checked box" /></div>
                    <div className="swt-ionev-map-label"><span className="swt-ionev-map-marker missed-op-fleet"></span>Missed Opportunities - Previously Used EVSE</div>
                  </div>
                  <div className="swt-ionev-map-checkbox-div">
                    <div className={"swt-ionev-map-checkbox " + (this.state.showMissedOpPub ? "checked" : "")} onClick={this.setMissedOpPub}><img className="swt-checkbox" src={ checkedBox } alt="Checked box" /></div>
                    <div className="swt-ionev-map-label"><span className="swt-ionev-map-marker missed-op-pub"></span>Missed Opportunities - Publicly Available EVSE</div>
                  </div>
                </div>
                </>
              }

                <div className="swt-ionev-map-stats swt-ionev-map-label">
                <p className="ionev-map-selector-label">Currently Displaying</p>
                  { this.state.displayType === 'location' &&
                    <>
                      <p className="swt-ionev-map-stats-line">Locations: {this.props.locationActivity.length}</p>
                      <p className="swt-ionev-map-stats-line">Total Events: {chargeCt + missedCt}</p>
                      <p className="swt-ionev-map-stats-line">Charging Events: {chargeCt}</p>
                      <p className="swt-ionev-map-stats-line">Missed Opportunities: {missedCt}</p>
                    </>
                  }
                  { this.state.displayType === 'events' &&
                    <>
                    { this.state.showChargeInst &&
                      <p className="swt-ionev-map-stats-line">Charging Events: {this.props.chargeActivity.length}</p>
                    }
                    { this.state.showMissedOpFleet &&
                      <p className="swt-ionev-map-stats-line">Missed Opportunities - Previously Used EVSE: {this.props.missedOpsFleetActivity.length}</p>
                    }
                    { this.state.showMissedOpPub &&
                      <p className="swt-ionev-map-stats-line">Missed Opportunities - Publicly Available EVSE: {this.props.missedOpsPubActivity.length}</p>
                    }
                    { (!this.state.showChargeInst && !this.state.showMissedOpFleet && !this.state.showMissedOpPub) &&
                      <p>No data. Please select event(s) to display</p>
                    }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          { this.state.displayType === 'location' &&
            <Suspense fallback={<></>}>
              <FleetMap
                history={this.props.history}
                mapCenter={this.props.mapCenter}
                locationActivity={this.props.locationActivity}
                selectedVcl={this.props.selectedVcl}
                resetMapFlag = {this.state.resetMapFlag}
                selectedLoc={this.props.selectedLoc}
                user ={this.props.user}
              />
            </Suspense>
          }
          { this.state.displayType === 'events' &&
            <Suspense fallback={<></>}>
              <EventsMap
                user={this.props.user}
                base_url={this.props.base_url}
                db={this.props.db}
                token={this.props.user.token}
                showChargeEvents={this.state.showChargeInst}
                chargeActivity={this.props.chargeActivity}
                showMissedOpFleet={this.state.showMissedOpFleet}
                missedOpsFleetActivity={this.props.missedOpsFleetActivity}
                showMissedOpPub={this.state.showMissedOpPub}
                missedOpsPubActivity={this.props.missedOpsPubActivity}
                fuelCost={this.props.fuelCost}
                mapCenter={this.props.mapCenter}
              />
            </Suspense>
          }
        </div>
      </div>
    );
  }
}

export default MapContainer;
