import React from 'react';
import styled from "styled-components/macro";


const AssumptionsContainer = styled.div`
    max-width: 1000px;
    margin: 0 50px 100px 0;
`;
const AssumptionsBlock = styled.div`

`;
const AssumptionsText = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgb(116, 120, 125);
`;
const EmailLink = styled.a`
    color: #2E4B7C;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`
const AssumptionsSubHeader = styled.h2`
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 35px;
    color: rgb(74, 74, 74);
    margin: 0;
`

const AssumptionLinkContainer = styled.div`
    padding: 2px 0
`;

const AssumptionLink = styled.a`
    font-size: 15px;
    color: #2E4B7C;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
const AssumptionsTitle = styled.h1`
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    line-height: 35px;
    color: rgb(74, 74, 74);
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export default function Assumptions() {
    return (
        <AssumptionsContainer>
            <AssumptionsTitle>Emissions Report Assumptions</AssumptionsTitle>
            <AssumptionsBlock>
                <AssumptionsText>The emissions calculations for this product are calculated
                    for CO, CO2, NOX, PM2.5, and PM10 based on coefficients publicly
                    available from the EPA and the Argonne National Laboratory’s
                    AFLEET tool.
                    <br />
                </AssumptionsText>
            </AssumptionsBlock>
            <AssumptionsBlock>
                <AssumptionsText>The coefficients are then adjusted to reflect
                    the age and type of the vehicle, producing a value in grams
                    per mile which are then recomposed into grams per gallon of fuel.
                    <br />
                </AssumptionsText>
            </AssumptionsBlock>
            <AssumptionsBlock>
                <AssumptionsText>
                    Results are available for electric, gasoline and diesel-powered
                    vehicles.<br />
                </AssumptionsText>
            </AssumptionsBlock>
            <AssumptionsBlock>
                <AssumptionsText> For further questions, please contact Sawatch Labs
                    at <EmailLink href = "mailto: info@sawatchlabs.com">info@sawatchlabs.com</EmailLink>.
                    <br />
                </AssumptionsText>
            </AssumptionsBlock>
            <br />
            <AssumptionsSubHeader>Sources:</AssumptionsSubHeader>
            <AssumptionLinkContainer>
                <AssumptionLink href="https://nepis.epa.gov/Exe/ZyPDF.cgi?Dockey=P100U8YT.pdf"
                    target="_blank" rel="noopener noreferrer">
                        Greenhouse Gas Emissions from a Typical Passenger Vehicle
                </AssumptionLink>
            </AssumptionLinkContainer>
            <AssumptionLinkContainer>
                <AssumptionLink href="https://www.fueleconomy.gov/feg/pdfs/420r16010.pdf"
                    target="_blank" rel="noopener noreferrer">
                        Light-Duty Automotive Technology, Carbon Dioxide Emissions, and Fuel Economy Trends
                </AssumptionLink>
            </AssumptionLinkContainer>
            <AssumptionLinkContainer>
                <AssumptionLink href="https://greet.es.anl.gov/afleet_tool"
                    target="_blank" rel="noopener noreferrer">
                    Argonne National Laboratory AFLEET Tool
                </AssumptionLink>
            </AssumptionLinkContainer>
        </AssumptionsContainer>
    )
}
