import React from "react";
import * as S from "../../styles/ezio-styles/LandingPage-styles";


type CountDownViewProps = {
    countDown: number
}

export default function CountDownView({ countDown }: CountDownViewProps) {
    return(
        <>
            {countDown > 1 && <S.CountDownText>Processing Data. Seconds Left: {countDown.toFixed(0)}</S.CountDownText>}
        </>
    )
}