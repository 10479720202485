export function setClassName(cell, row) {
  // to create individual classNames in order to set CSS properties
  switch (cell.column.Header) {
    case 'Selected Vehicles':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-sv-selected';
      else return 'sftd-table-cell-sv';
    case 'Location':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-loc-selected';
      else return 'sftd-table-cell-loc';
    case 'loc':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-location-selected';
      else return 'sftd-table-cell-location';
    case 'Total Vehicles':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-tv-selected';
      else return 'sftd-table-cell-tv';
    case 'vcl' || 'loc':
      if (
        row.original.selected === 'selected' ||
        row.original.selected === 'selected'
      )
        return 'sftd-table-cell-sl-selected';
      else return 'sftd-table-cell-sl';
    // Vehicles Table
    case 'Year':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-yr-selected';
      else return 'sftd-table-cell-yr';
    case 'Make':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-mk-selected';
      else return 'sftd-table-cell-mk';
    case 'Model':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-ml-selected';
      else return 'sftd-table-cell-ml';
    case 'Class':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-cl-selected';
      else return 'sftd-table-cell-cl';
    case 'Active':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-au-selected';
      else return 'sftd-table-cell-au';
    case 'Miles':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-ms-selected';
      else return 'sftd-table-cell-ms';
    case 'Days':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-ds-selected';
      else return 'sftd-table-cell-ds';
    case 'Trips':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-ts-selected';
      else return 'sftd-table-cell-ts';
    case 'Duty Cycles':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-dc-selected';
      else return 'sftd-table-cell-dc';
    case 'Hours':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-hr-selected';
      else return 'sftd-table-cell-hr';
    case 'Odometer':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-od-selected';
      else return 'sftd-table-cell-od';
    case 'Homebase':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-hs-selected';
      else return 'sftd-table-cell-hs';
    case 'Asset ID':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-ai-selected';
      else return 'sftd-table-cell-ai';
    case ' ':
      if (row.original.selected === 'selected')
        return 'sftd-table-cell-btn-selected';
      else return 'sftd-table-cell-btn';
    default:
  }
}
