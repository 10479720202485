import React from "react";
import ReactLoading from "react-loading";

const Loading = (props) => (
  <div data-testid={props["test-id"] ? props["test-id"] : "swt-loading-testid"}>
    <ReactLoading
      className={"swt-loading"}
      type={props.type ? props.type : "bars"}
      color={props.color ? props.color : "rgba(182, 174, 174, 0.267)"} 
      viewBox={props.viewBox ? props.viewBox : "0 0 8 8"}
      width={props.width ? props.width : "8"}
      height={props.height ? props.height : "8"}
    />
  </div>
);

export default Loading;