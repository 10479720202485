import React from 'react';
export default function WeekdaySelector(props) {
  function handleWeekdayClick(input) {
    let weekdays = props.selectedDays;
    weekdays[input] = !weekdays[input];
    if (!Object.values(weekdays).includes(true))
      window.alert(
        'There will be no results until a single weekday is selected'
      );
    props.handleWeekdayChange(weekdays);
  }

  function checkWeekdaySelected(input) {
    if (props.selectedDays[input] === true) return 'selected';
    else return 'unselected';
  }
  return (
    <div className="sftd-day-selector">
      <p
        onClick={() => handleWeekdayClick('sun')}
        className="sftd-day"
        id={checkWeekdaySelected('sun')}
      >
        S
      </p>
      <p
        onClick={() => handleWeekdayClick('mon')}
        className="sftd-day"
        id={checkWeekdaySelected('mon')}
      >
        M
      </p>
      <p
        onClick={() => handleWeekdayClick('tue')}
        className="sftd-day"
        id={checkWeekdaySelected('tue')}
      >
        T
      </p>
      <p
        onClick={() => handleWeekdayClick('wed')}
        className="sftd-day"
        id={checkWeekdaySelected('wed')}
      >
        W
      </p>
      <p
        onClick={() => handleWeekdayClick('thu')}
        className="sftd-day"
        id={checkWeekdaySelected('thu')}
      >
        T
      </p>
      <p
        onClick={() => handleWeekdayClick('fri')}
        className="sftd-day"
        id={checkWeekdaySelected('fri')}
      >
        F
      </p>
      <p
        onClick={() => handleWeekdayClick('sat')}
        className="sftd-day"
        id={checkWeekdaySelected('sat')}
      >
        S
      </p>
    </div>
  );
}
