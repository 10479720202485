function AddCommas(value) {
    let formattedValue = value.toString()
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
}

function sortByDate( a, b ) {
    if ( a.localized_date < b.localized_date )return -1;
    if ( a.localized_date > b.localized_date )return 1;
    return 0;
}

export function decideVinDisplay (vcl) {
    if(vcl) {
    if(typeof vcl.user_defined_vin === "undefined" || vcl.user_defined_vin === null || vcl.user_defined_vin === 'null' || !vcl.user_defined_vin) {
      return vcl.vin
    } else {
      return vcl.user_defined_vin
    }
    }
  }
  
  export function vehicleIdDisplay (vcl) {
    if(vcl) {
    if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
    if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
    if (vcl.vin) return vcl.vin
    }
  }

export function formatMonthlyGraphLabels(d) {
    if(!d) return
    let month = d.split('/')[0]
    let year = d.split('/')[1].slice(2)
    return `${month}/${year}`
}

export function FormatData(accessor, value, rowValues) {
    const dollarValues = ['fuelCostSavings'];
    const valuesToSingleDecimal = ['emit', 'idle', 'ghgTons', 'ghgTonsSavings', 'ghgLbs', 'ghgLbsSavings', 'noxLbs', 'noxLbsSavings', 'coLbs', 'coLbsSavings', 'CO2Tons', 'CO2TonsSavings', 'CO2Lbs', 'CO2LbsSavings', 'pm10Grams', 'pm10GramsSavings', 'pm25Grams', 'pm25GramsSavings', 'fuelGallonsSavings']
    const graphDataValues = ['ghg', 'phevGHG', 'bevGHG', 'dieselGHG', 'gasolineGHG', 'dailyTotalEmissions', 'totalDailyEmissionReductions', 'weeklyGHG', 'totalWeeklyEmissionReductions', 'totalMonthlyEmissionReductions', 'weeklyGHGReduction', 'monthlyGHGReduction', 'weeklyBarData', 'monthlyBarData', 'weeklyTotalEmissions', 'monthlyTotalEmissions', 'weeklyGHG', 'monthlyGHG', 'weeklyGHGReduction', 'monthlyGHGReduction', 'weeklyTotalBevGHG', 'monthlyTotalBevGHG', 'weeklyTotalBevGHGReduction' ,'monthlyTotalBevGHGReduction', 'weeklyTotalDieselGHG', 'monthlyTotalDieselGHG', 'weeklyTotalGasolineGHG', 'monthlyTotalGasolineGHG', 'weeklyTotalPhevGHG', 'weeklyTotalPhevGHGReduction', 'monthlyTotalPhevGHGReduction', 'monthlyTotalPhevGHG']
    const valuesToAddCommas = ['miles']
    if (accessor === 'asset_id') {
        return vehicleIdDisplay(rowValues)
    } else if (accessor === 'vin') {
        return decideVinDisplay(rowValues)
    } else if (value === null || value === undefined || value === 'null' || value === '-') {
        return "-"
    } else if (dollarValues.includes(accessor)) {
        value = Math.round(value * 100) / 100;
        return `$${AddCommas(value)}`
    } else if (valuesToAddCommas.includes(accessor)) { 
        return AddCommas(value);
    //React Chart js does not handle values with commas. Need to be returned as a number.
    } else if (graphDataValues.includes(accessor)) {
        value = Math.round(value * 10) / 10;
        return value;
    } else if (valuesToSingleDecimal.includes(accessor)) {
        value = Math.round(value * 10) / 10;
        return AddCommas(value)
    } else {
        return value;
    }
}

export function FormatSummaryData (accessor, value) {
    const valuesToIntegers = ['emissions', 'emissionReductions', 'ghg', 'ghgSaved', 'co2', 'co2Saved','coLbs', 'coLbsSaved', 'noxLbs', 'noxLbsSaved', 'mpg', 'fuelGallonsSaved'];
    const valuesToSingleDecimal = ['pm10Grams', 'pm10GramsSaved', 'pm25Grams', 'pm25GramsSaved'];
    const pieGraphValues = ['pieGraphData']
    const valuesToRoundedDollarValue = ['fuelCostSaved'];

    if (value === null || value === undefined) {
        return '-'
    } else if (valuesToIntegers.includes(accessor)) {
        if (value === 0) {
            return '0'
        } else {
        value = Math.round(value)
        return (value < 1) ? '<1' : AddCommas(value)
        }
    //React Chart js does not handle values with commas. Need to be returned as a number.
    } else if (pieGraphValues.includes(accessor)) {
        value = Math.round(value * 10) / 10
        return value;
    } else if (valuesToSingleDecimal.includes(accessor)) {
        value = Math.round(value * 10) / 10; 
        return AddCommas(value);
    } else if (valuesToRoundedDollarValue.includes(accessor)) {
        value = Math.round(value);
        return `$${AddCommas(value)}`
    } else {
        return AddCommas(value);
    }
}

export function FormatGraphData (obj) {
    const objKeys = Object.keys(obj)
        objKeys.forEach((key) => {
            obj[key] = obj[key].map((item) => {
                return FormatData(key, item)
            })
    })
    return obj
}


export function CompileIntoWeeks(events, key, beginDate, endDate) {
    //this is a port of the compileIntoWeeks function found in the Idling and Speedn apps
    //this adds weekly start date function and takes a "key" as a param so it can be used
    //for both ghg and reducedGHG vals
    const getWeekNumber = function(date){
        let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };
    const getWeekStartDate = function(localized_date){
        let d = Date.parse(localized_date)
        d = new Date(d);
        let sunday = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        const dayNum = sunday.getUTCDay() || 7;
        sunday.setUTCDate(sunday.getUTCDate() + 1 - dayNum);
        return sunday
    }
  
    function getWeek(localized_date){
        if(typeof localized_date === "undefined")return 0;
        let d = Date.parse(localized_date)
        d = new Date(d);
        return getWeekNumber(d);
    }
  
    const refDate = new Date(beginDate);
    let arr = [];
    while(refDate <= endDate){
        const woy = getWeek(refDate);
        if(!arr.find((w)=> w.woy === woy)){
            arr.push({woy: woy, sunday: getWeekStartDate(refDate)})
        }
        refDate.setDate(refDate.getDate() + 1);
    }
    const we = [];
    arr.forEach((a) => {
        const o = {}
        o['woy'] = a.woy;
        o['sunday'] = a.sunday;
        o[key] = 0;
        we.push(o)
    });
  
    arr = events.sort(sortByDate);
    arr.forEach((a) => {
        const woy = getWeek(a.localized_date);
        we.forEach((w) => {if(w.woy === woy)w[key] += a[key]});
    });
    return we;
  }

  export function CompileIntoMonths(events, key, beginDate, endDate){
    const getMonth = function(localized_date){
        let d = Date.parse(localized_date)
        d = new Date(d);
        return `${d.getMonth()+1}/${d.getFullYear()}`;
    }
    const refDate = new Date(beginDate);
    let arr = [];
    while(refDate <= endDate){
        const moy = getMonth(refDate);
        if(!arr.find((m)=> m.moy === moy)){
            arr.push({moy: moy})
        }
        refDate.setDate(refDate.getDate() + 1);
    }
    const months = [];
    arr.forEach((a) => {
        const o = {}
        o['moy'] = a.moy;
        o[key] = 0;
        months.push(o)
    });
    arr = events.sort(sortByDate);
    arr.forEach((a) => {
        const moy = getMonth(a.localized_date);
        months.forEach((m) => {if(m.moy === moy)m[key] += a[key]});
    });
    return months;
  }