import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import emitLogo from '../../images/emit-light.png'

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        backgroundColor: "#12365b",
        height: "66px",
        color: "white",
        alignItems: 'center',
        paddingLeft: 15
    },
    logo: {
        height: 20,
        width: 75,
    },
    headerTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 5
    },
    label:{
        width: "auto",
        fontSize: "21px",
    },
    dbLabel: {
        fontSize: '16px'
    }
})

export default function PDFBrandedHeader(props){
    const dbName = props.dbName ? props.dbName : ''
    return(
        <View style={styles.header}>
            <Image style={styles.logo} src={emitLogo} />
            <View style={styles.headerTextWrapper}>
                <Text style={styles.label}>Emissions Report</Text>
                <Text style={styles.dbLabel}>{dbName}</Text>
            </View>
        </View>
    )
}