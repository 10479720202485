import React from 'react';
import { StatView } from '@sawatchlabs/swt-components';
import { FormatSummaryData } from './UtilityFunctions';

export default function Landing({ emissionsSummary, displayInLbs }) {
    const units = displayInLbs ? "Lbs" : "Tons";
    let ghg = emissionsSummary.ghgTons;
    let co2 = emissionsSummary.CO2Tons;
    if(units === 'Lbs'){
        ghg = emissionsSummary.ghgLbs;
        co2 = emissionsSummary.CO2Lbs;
    }
    return (
        <div className="swt-emit-landing-boxes-wrapper">
            <StatView values={{value: FormatSummaryData('ghg', ghg), caption:`GHG ${units}`, subCaption: "(Includes GHG from kWh gen.)"}}/>
            <StatView values={{value: FormatSummaryData('co2', co2), caption: `Carbon Dioxide ${units}`, subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('coLbs', emissionsSummary.coLbs), caption: "Carbon Monoxide Lbs", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('noxLbs', emissionsSummary.noxLbs), caption: "Nitrous Oxides Lbs", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('pm10Grams', emissionsSummary.pm10Grams), caption: "Particulate Matter 10\u00B5m in Grams", subCaption: "(Tailpipe)"}}/>
            <StatView values={{value: FormatSummaryData('pm25Grams', emissionsSummary.pm25Grams), caption: "Particulate Matter 2.5\u00B5m in Grams", subCaption: "(Tailpipe)"}}/>
        </div>
    )
}
