import React from "react";


export function humanFriendlyMonth(mnth){
  switch(mnth){
    case 0: return 'January';
    case 1: return 'February';
    case 2: return 'March';
    case 3: return 'April';
    case 4: return 'May';
    case 5: return 'June';
    case 6: return 'July';
    case 7: return 'August';
    case 8: return 'September';
    case 9: return 'October';
    case 10: return 'November';
    case 11: return 'December';
    default: return "-";
  }
}

export function humanFriendlyTime(hr){
  if(hr === 0) return '12 AM'
  if(hr === 12) return '12 PM'
  if(hr < 12) return hr
  else{return (hr - 12)}
}

export default function SeasonalDropdown(props) {
  let value = props.value
 if(props.value === null) value = 'placeholder';

  function _daysInMonth() {
    //   Months are starting at 0 index
    switch (parseInt(props.selectedMonth)) {
      case 1:
        return 27;
      case 3:
        return 29;
      case 5:
        return 29;
      case 8:
        return 29;
      case 10:
        return 29;
      default:
        return 30;
    }
  }

  function _selectDropDownData() {
    let options = [];
    if (props.target === "startMonth" || props.target === 'stopMonth') {
      for (let i = 0; i < 12; i++) {
        options.push(
          <option value={i} key={i}>
            {humanFriendlyMonth(i)}
          </option>
        );
      }
      return options;
    }
    if (props.target === "startDayOfMonth" || props.target === "stopDayOfMonth") {
      for (let i = 0; i <= _daysInMonth(); i++) {
        options.push(
          <option value={i} key={i}>
            {i+1}
          </option>
        );
      }
      return options;
    }
    if (props.target === "startHour" || props.target === "stopHour") {
      for (let i = 0; i < 24; i++) {
        options.push(
          <option value={i} key={i}>
            {humanFriendlyTime(i)}
          </option>
        );
      }
      return options;
    }
    if (props.target === "startMinute" || props.target === "stopMinute") {
      for (let i = 0; i < 60; i++) {
        options.push(
          <option value={i} key={i}>
            {i < 10 ? `0${i}` : i}
          </option>
        );
      }
      return options;
    }
  }

  return (
    <select
      aria-label={`seasonal-selector-input-${props.target}`}
      disabled={props.disabled}
      value={value}
      className="swt-seasonal-dropdown"
      id={props.target}
      onChange={props.handleChange}
    >
      <option disabled hidden value = {'placeholder'}> - </option>
      {_selectDropDownData()}
    </select>
  );
}
