import React from "react";
import { Bar } from "react-chartjs-2";
import FormatCell from "./TableHelpers";

export default function GraphKwhTotalUptake(props) {

  const legendOpts = {
    display: true,
    position: "bottom",
    labels: {
      font: {
        size: 11,
        weight: 'normal',
        },
    }
  };
  
  const options = {
    plugins: {
      legend: legendOpts,
    },
    layout: {
      padding: {
        right: 15,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
     }
  };

  function formatGraphData() {
    // truncate long asset ids
    var labels = props.kwhData.map(v => {
      if(v.asset_id.length < 15)return v.asset_id;
      else return `${v.asset_id.slice(0, 15)}...`;
    });
    var kwhs = props.kwhData.map(v => {
      return FormatCell('totalKwhInt', v.kwh, null, null, null);
    });
    return {
      labels: labels,
      datasets: [
        {
          label: "Asset IDs",
          data: kwhs,
          backgroundColor: "rgba(53, 166, 125, 0.6)",
          borderColor: "rgb(53, 166, 125)",
          borderWidth: 2,
        },
      ],
    };
  }

  return (
    <>
      <h4 className="ionev-graph-title">{props.graphTitle}</h4>
      <Bar
        className="swt-ionev-graph-content"
        options={options}
        data={formatGraphData()}
        legend={legendOpts}
        width={80}
        height={280}
      />
    </>
  );
}

export function GraphKwhTotalMissed(props) {
  const legendOpts = {
    display: true,
    position: "bottom",
    labels: {
      font: {
        size: 11,
        weight: 'normal',
        },
    }
  };

  const options = {
    plugins: {
      legend: legendOpts,
    },
    layout: {
      padding: {
        right: 15,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
     }
  };

  function formatGraphData() {
    // truncate long asset ids
    var labels = props.kwhData.map(v => {
      if(v.asset_id.length < 15)return v.asset_id;
      else return `${v.asset_id.slice(0, 15)}...`;
    });
    var kwhs = props.kwhData.map(v => {
      return FormatCell('totalKwhInt', v.kwh, null, null, null);
    });
    return {
      labels: labels,
      datasets: [
        {
          label: "Asset IDs",
          data: kwhs,
          backgroundColor: "rgba(231, 88, 88, 0.6)",
          borderColor: "rgb(231, 88, 88)",
          borderWidth: 2,
        },
      ],
    };
  }

  return (
    <>
      <h4 className="ionev-graph-title">{props.graphTitle}</h4>
      <Bar
        className="swt-ionev-graph-content"
        options={options}
        data={formatGraphData()}
        legend={legendOpts}
        width={80}
        height={280}
      />
    </>
  );
}
