import styled from 'styled-components';
import arrowUnsel from "../../images/ic-arrow-unsort-20px-36px.png"
import arrowUp from "../../images/ic-arrow-asc-20px-36px.png"
import arrowDown from "../../images/ic-arrow-desc-20px-36px.png"

export const StyledTable = styled.div`
max-height: 440px;
font-family: "Arimo", "Roboto";
font-size: 16px;
border-spacing: 0;
width: 100%;

overflow: auto;

    ::-webkit-scrollbar {
    width: 5px;
    //make scrollbar transparent
    }

    ::-webkit-scrollbar-track{
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10825432px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        } 

table{
    border-collapse: collapse;
    max-height: 440px;
    text-align: left;
    width: 100%;
}



 thead {
    position: sticky;
    top: 0;
}

thead > tr{

    background-color: #72afc4;
}
thead th{
    background-color: inherit;
    color: var(--black);
    height: 90px;
    font-size: 16px;
    text-align: start;
    vertical-align: middle;
    padding: 1em 5px;
    font-weight: 700;
  letter-spacing: .16px;
}

td, td > *{
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}



td{
 
    height: 4rem;
    padding: 5px;
    overflow: auto;

    ::-webkit-scrollbar {
    height: 8px;
    //make scrollbar transparent
    }

    ::-webkit-scrollbar-track{
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10825432px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        } 
}


tbody tr:hover{
            color: var(--ezev-bluish);
            text-decoration: underline;
        }
  
  


.swt-ezio-even-row{
  background-color: var(--white);
  .overall{
    background-color: #ddecec;
  }
}

.swt-ezio-odd-row{
  background-color: var(--ezev-light-grey);
  .overall{
    background-color: #d4e3e3;
  }
}


.swt-ezio-search-input{
  width: 8rem;
}

.swt-sort-arrow{
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: right 0 center;
}
.ezev-both{
  background-image: url(${arrowUnsel});
}

.ezev-asc{
  background-image: url(${arrowUp})
}
.ezev-desc{
  background-image: url(${arrowDown});
}

a{
  color:inherit;
  text-decoration: inherit;
  text-decoration-color: var(--ezev-bluish);
}

`
export const SearchBar = styled.div`
.swt-ezev-search-input {
    margin-top: 3px;
    width: 8rem;
    height: 19px;
    background: #FFFFFF;
    border-radius: 5px;
    border:none;
}
`

export const Pagination = styled.div`
margin-top: 20px;
display: flex;
justify-content: center;
color: var(--ezev-battleship-grey);
font-size: 12px;
letter-spacing: .1px;
*{
  padding: 0 5px;
}
input{
  color: var(--ezev-battleship-grey);
  width: 30px !important; 
  font-size: 10px;
  
}
`
export const NoDataMsg = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  white-space: nowrap;
  padding: 10px 0;
  text-align: center;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const PaginationDetails = styled.span`
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
`

export const PaginationButtons = styled.button`
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;

  &:disabled {
    cursor:default;
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(16, 16, 16, 0.2);
  }

  &:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out .2s;
  }

`