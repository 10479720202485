import React from "react";
import "../../../component-css/nrel-dashboard-stylesheets/modal.css";

export default function Modal(props) {
  function selectConfirmationType() {
    if (props.confirm === true) {
      return (
        <div className="swt-modalConfirm">
          <p className="swt-modalConfirmationMessage">
            {props.confirmationMessage
              ? props.confirmationMessage
              : "Are you sure you wish to continue?"}
          </p>
          <button
            className="swt-modalConfirmation"
            id="confirm"
            onClick={props.confirmationHandler}
          >
            Confirm
          </button>
          <button
            className="swt-modalConfirmation"
            id="cancel"
            onClick={() => props.showModalHandler(!props.showModal)}
          >
            Cancel
          </button>
        </div>
      );
    } else {
      return (
        <button
          onClick={() => props.showModalHandler(!props.showModal)}
          className="swt-modalConfirmation"
        >
          Okay
        </button>
      );
    }
  }

  return (
    <div className="swt-modalWrapper">
      <div className="swt-modalContainer">
        <h1 className="swt-modalTitle">{props.title}</h1>
        <p className="swt-modalMessage">{props.message}</p>
        {selectConfirmationType()}
      </div>
    </div>
  );
}
