import React, { useState, useEffect, useMemo, Suspense } from "react";
import Search from '../dashboard-components/admin-components/search';
import { MultiSelectDropdown } from "@sawatchlabs/swt-components";
import * as S from '../../../styles/core-styles/DevTools-styles';

//code-split imports
const DevToolMap = React.lazy(() => import("./DevToolMap"));

const ALL_VEHICLES = {pkid: -1, vin: "all-vehicles-key", asset_id: "All Vehicles", year:"", make:"", model:""}

const heatmapOptions = {
    'id': 'eventsHeatMap',
    'type': 'heatmap',
    'source': 'eventsHeatMap',
    'maxzoom': 12,
    'paint': {
        'heatmap-weight': [ 'interpolate', ['linear'], ['get', 'value'], 10,20,30,40],
        'heatmap-radius': ['interpolate',['linear'],['zoom'],2,8],
    }
}

const parkingMarkerHTML = (event: any) =>{
  return(`      
          <span>Location: ${event.location}</span><br>
          <span>Count: ${event.count}</span><br>
          <span>Address: ${event.address}</span><br>
          <span>Lat/Lon: ${event.latitude}, ${event.longitude}</span><br>`)
}

export type ParkingEvent ={
  id?: string,
  location: string,
  count: number,
  value?: number
}

export type VehicleObj = {
  label: string,
  value: string
}

export type LocationObj = {
  address: string,
  latitude: number,
  longitude: number,
  pkid: number
}

export default function ParkingMap(props: any){

    const [events, setEvents] = useState(Array<ParkingEvent>);
    const [showHeatMap, setShowHeatMap] = useState(false);
    const [showMarkers, setShowMarkers] = useState(true);
    const [showSatellite, setShowSatellite] = useState(false);
    const [matchingEvents, setMatchingEvents] = useState(null || Array);
    const [selectedVehicleList, setSelectedVehicleList] = useState<VehicleObj[]>([{label: 'All Vehicles', value: 'All Vehicles'}])
    const [showEzioLocations, setShowEzioLocations] = useState(false);
    const [ezioLocations, setEzioLocations] = useState(null || Array)
    const { secrets, beginDate, endDate, user, db, apiURL } = props;


    const vehicles = useMemo(() =>{
      const av = props.vehicles.find((v: any) => {return v.vin === ALL_VEHICLES.vin});
      if(typeof av === 'undefined'){
        const vcls = [ALL_VEHICLES, ...props.vehicles];
        vcls.forEach((v) => {v.key = v.vin;});
        return vcls;
      }
      return props.vehicles;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.vehicles]);

    //When Ezio Location control is set to true, fetch ezio locations
    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      if (showEzioLocations) {
        const promises = [
          fetch(`${apiURL}getUngroupedLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}}).then((resp) => resp.json()),
          fetch(`${apiURL}getGroupedLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}}).then((resp) => resp.json())
        ]
        Promise.all(promises)
          .then(([groupedLocationsResp, unGroupedLocationsResp]) => {
            const ezioLocationIds: (number | null)[] = []
            if (groupedLocationsResp.status === "error") {
              alert("Error getting grouped locations.");
            }
            else {
              groupedLocationsResp.data.forEach((loc: LocationObj) => {
                ezioLocationIds.push(loc.pkid)
              })
            }
            if (unGroupedLocationsResp.status === "error") {
              alert("Error getting ungrouped locations.");
            }
            else {
              unGroupedLocationsResp.data.forEach((loc: LocationObj) => {
                ezioLocationIds.push(loc.pkid)
              })
            }
            return setEzioLocations(ezioLocationIds)
          })
      }
      return () => controller.abort();
    },[showEzioLocations, db, apiURL, user.token])

    //Filtered locations for ezio locations control
    const filteredLocations = useMemo(() => {
      if (ezioLocations.length > 0) {
        const filteredEzioLocations = events.filter((e) => {return ezioLocations.includes(e.id)})
        return filteredEzioLocations;
      } else {
        return [];
      }
    },[ezioLocations, events])

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
        const vinList = selectedVehicleList.map((v:VehicleObj) => {return v.value.toString()})
        let url = `${apiURL}getParkingEvents?dbName=${db}&start=${_formatAPIDate(beginDate)}&stop=${_formatAPIDate(endDate)}`;
        if(vinList.includes('All Vehicles') === false && vinList.length === 1)url = `${apiURL}getParkingEvents?dbName=${db}&vin=${vinList}&start=${_formatAPIDate(beginDate)}&stop=${_formatAPIDate(endDate)}`;
        if(vinList.includes('All Vehicles') === false && vinList.length > 1)url = `${apiURL}getParkingEvents?dbName=${db}&vins=${JSON.stringify(vinList)}&start=${_formatAPIDate(beginDate)}&stop=${_formatAPIDate(endDate)}`;
        fetch(url, {
          signal,
          headers: { Authorization: `Bearer ${user.token}` },
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === "error")
              alert("There was a server error during your request.");
            else {
              const events = data.data;
              events.forEach((e: ParkingEvent) => {
                e.id = e.location;
                e.value = e.count;
              })
              setEvents(events);
            }
          });
      return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beginDate, endDate, selectedVehicleList]);

    const handleVehicleChange = (e: VehicleObj[]) => {
      if(e.length === 0) {
        return;
      } else if (e[e.length - 1].value === 'All Vehicles') {
        setSelectedVehicleList([{value: 'All Vehicles', label: 'All Vehicles'}])
      } else {
        const filteredList = e.filter((v: VehicleObj) => {return v.value !== 'All Vehicles'})
        setSelectedVehicleList(filteredList)
      }
    };
    function formatVehiclesOptions (options:any) {
      if (options) {
       return options.map((v: any) => {
          if (v.asset_id === 'All Vehicles') {
            return {label: 'All Vehicles', value: 'All Vehicles'}
          } else {
            return {value: v.vin, label: `${v.asset_id}-${v.year} ${v.make} ${v.model}`}
          }
        })
      }
    }

    return(
            <S.DevToolsContentContainer>
              <S.DevToolsResponsiveTopContainer>
              <S.DevToolsResponsiveControls>
                <S.DevToolsInputWrapper>
                  <S.DevToolsInputLabel>Select Vehicle:</S.DevToolsInputLabel>
                    <MultiSelectDropdown
                      width={'245px'}
                      height={'35px'}
                      borderColor={'#bbb'}
                      ClearSelectedIcon={null}
                      multiSelectionText={'Vehicles'}
                      color={'inherit'}
                      hasSelectAll={false}
                      value={selectedVehicleList}
                      onChange={(e: any) => handleVehicleChange(e)}
                      options={formatVehiclesOptions(vehicles)}          
                    />
                </S.DevToolsInputWrapper>

                <S.DevToolsInputWrapper>
                  <S.DevToolsInputLabel>Select Dates:</S.DevToolsInputLabel>
                    <S.DevToolsDatepickerContainer>
                      <S.DevToolsDatepickers
                          selected={beginDate}
                          onChange={(date: Date) => props.setBeginDate(date)}
                          selectsStart
                          startDate={beginDate}
                          endDate={endDate}
                          showMonthDropdown
                          useShortMonthInDropdown
                          popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [0, -7],
                              },
                            },
                          ]}
                      />
                      <S.DevToolsDatepickers
                          selected={endDate}
                          onChange={(date: Date) => props.setEndDate(date)}
                          selectsEnd
                          endDate={endDate}
                          showMonthDropdown
                          useShortMonthInDropdown
                          popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [0, -7],
                              },
                            },
                          ]}
                      />
                    </S.DevToolsDatepickerContainer>
                  </S.DevToolsInputWrapper>

                  <S.DevToolsInputWrapper>
                    <S.DevToolsInputLabel>Search Locations:</S.DevToolsInputLabel>
                      <Search allValues={showEzioLocations ? filteredLocations : events} setMatchingValues={setMatchingEvents}/>
                  </S.DevToolsInputWrapper>

                  <S.DevToolsInputWrapper>

                  <S.DevToolsInputLabel>
                      <S.DevToolsInputCheckbox 
                        onChange={() => setShowEzioLocations(!showEzioLocations)}
                        checked={showEzioLocations}
                        type='checkbox' />
                      ezIO Locations
                    </S.DevToolsInputLabel>

                    <S.DevToolsInputLabel>
                      <S.DevToolsInputCheckbox 
                        onChange={() => {setShowSatellite(showSatellite ? false : true)}}
                        checked={showSatellite}
                        type='checkbox' />
                      Satellite View
                    </S.DevToolsInputLabel>

                    <S.DevToolsInputLabel>
                      <S.DevToolsInputCheckbox 
                        onChange={() => {setShowMarkers(showMarkers ? false : true)}}
                        checked={showMarkers}
                        type='checkbox'/>
                      Event Markers
                    </S.DevToolsInputLabel>

                    <S.DevToolsInputLabel>
                      <S.DevToolsInputCheckbox 
                        onChange={() => {setShowHeatMap(showHeatMap ? false : true)}}
                        checked={showHeatMap}
                        type='checkbox' />
                      Heat Map
                    </S.DevToolsInputLabel>

                  </S.DevToolsInputWrapper>
                </S.DevToolsResponsiveControls>
                <Suspense fallback={<></>}>
                  <DevToolMap mapboxKey={secrets.mapboxKey}
                              events={matchingEvents}
                              markerHTML={parkingMarkerHTML}
                              showSatellite={showSatellite} 
                              showMarkers={showMarkers} 
                              showHeatMap={showHeatMap} 
                              heatmapOptions={heatmapOptions}/>
                </Suspense>
                </S.DevToolsResponsiveTopContainer>

            </S.DevToolsContentContainer>
            
    )
}

function _formatAPIDate(d: Date){
    return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
}