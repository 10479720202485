import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as ColorScheme from './PdfColors.js'

// Define the styles
const styles = StyleSheet.create({
  statViewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    width: 155,
    height: 90,
    color: 'black',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: 1.5,
    padding: 20,
    borderColor: ColorScheme.dark_gray,
  },
  statViewValueContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  statViewValueTextWrapper: {
    color: ColorScheme.dark_gray,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center'
  },
  statViewValue: {
    fontSize: 22,
    textAlign: 'center'
  },
  statViewSubValue: {
    fontSize: 16,
  },
  statViewCaption: {
    fontSize: 8,
    lineHeight: 1.2,
    color: ColorScheme.medium_gray,
    textAlign: 'center',
  },
  statViewSubCaption: {
    fontSize: 7,
    color: ColorScheme.medium_gray,
    textAlign: 'center',
  }
});

export const PdfStatView = ({value,subvalue,caption,subcaption}) => {
  return (
    <View style={styles.statViewWrapper}>
      <View style={styles.statViewValueContainer}>
        <View style={styles.statViewValueTextWrapper}>
          <Text style={styles.statViewValue}>{value}</Text>
          <Text style={styles.statViewSubValue}>{subvalue}</Text>
        </View>
      </View>
      <Text style={styles.statViewCaption}>{caption}</Text>
      <Text style={styles.statViewSubCaption}>{subcaption}</Text>
    </View>
  )
};