import React, { useState } from "react";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import { MultiSelect } from "react-multi-select-component";
import { formatDecimal, preventNonIntegers, preventPasteNonIntegers } from "../UtilityFunctions";
import {SearchSelect} from '@sawatchlabs/swt-components';

const powertrains = [
  {id: 1, name: "Gasoline", attributes: ["tank", "mpg"], bool: "is_gasoline"},
  {id: 2, name: "Diesel", attributes:["tank", "mpg"], bool: "is_diesel"},
  {id: 3, name: "PHEV", attributes:["tank", "mpg", "battery_kwh", "battery_kwh_usable"], bool: "is_phev"},
  {id: 4, name: "BEV", attributes:["battery_kwh", "battery_kwh_usable"], bool: "is_bev"},
  {id: 5, name: "CNG", attributes:["tank", "mpg"], bool: "is_cng"},
  {id: 6, name: "RNG", attributes:["tank", "gge"], bool: "is_rng"},
  {id: 7, name: "Propane", attributes: ["tank", "mpg"], bool: "is_propane"},
  {id: 8, name: "Hydrogen Fuelcell", attributes:["tank", "gge"], bool: "is_hydrogen_fuelcell"},
  {id: 9, name: "Hydrogen/LION Hybrid", attributes:["tank", "gge"], bool: "is_hydrogen_lion_hybrid"}
]

const ALPHANUMERIC_REGEX = "^[\\w\\-\\(\\) ]*$" //Prevents all special characters except for " - _ ( ) "
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters '_' , '-' , '()' are also allowed.";

export default function CandidateCreationModal(props) {
  const { setShowModal, vehicleClasses, addCandidate, sanitizeData, upfits, dbSettings, userSettings, cdrModal, existingCandidates} = props;
  const duplicate_candidate_error = `The new candidate's YMM cannot be identical to the original candidate's YMM.`;
  const existing_candidate_error = `The new candidate's YMM cannot be identical to another candidate's YMM in ${cdrModal ? `the CDR` : `your database`}.`;
  
  const [evsaSelectOnAdd, setEvsaSelectOnAdd] = useState(true);
  const [ionevSelectOnAdd, setIonevSelectOnAdd] = useState(true);
  const [ymm, setYmm] = useState(null);
  const [year, setYear] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [msrp, setMSRP] = useState(null);
  const [netPrice, setNetPrice] = useState(null);
  const [vehicleClass, setVehicleClass] = useState(null);
  const [powertrainType, setPowertrainType] = useState("");
  const [batteryCapacity, setBatteryCapacity] = useState(null);
  const [usableKwh, setUsableKwh] = useState(null);
  const [mpgCty, setMpgCty] = useState(null);
  const [mpgHwy, setMpgHwy] = useState(null);
  const [isLd, setIsLd] = useState(true);
  const [selectedUpfits, setSelectedUpfits] = useState([]);
  const [cylinders, setCylinders] = useState(null);
  const [displacement, setDisplacement] = useState(null);
  const [expectedResidualPrice, setExpectedResidualPrice] = useState(null);
  const [maintPerKm, setMaintPerKm] = useState(null);
  const [tankLiters, setTankLiters] = useState(null);
  const [existingCandidate, setExistingCandidate] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [existingCandidateImageUrl,setExistingCandidateImageUrl] = useState(null);
  const [useExistingImageUrl, setUseExistingImageUrl] = useState(false);
  const [showInvalidYmmError, setShowInvalidYmmError] = useState(false);
  const [invalidYmmError, setInvalidYmmError] = useState(cdrModal ? existing_candidate_error : duplicate_candidate_error);

  function handlePowertrainChange(powertrain){
    if (powertrain.attributes) {
      //reset battery
      if(powertrain.attributes.indexOf("battery_kwh") < 0){
        setBatteryCapacity(null);
        setUsableKwh(null);
      }
      //reset gas usage
      if(powertrain.attributes.indexOf("mpg") < 0){
        setMpgCty(null);
        setMpgHwy(null);
      }
    }
    setPowertrainType(powertrain);
  }

  function calculateYmm(year,make,model) {
    const ymm = `${year} ${make} ${model}`;
    return ymm;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const prefix = Math.random().toString(36).slice(8);

    let cand = {
      id: cdrModal ? `${prefix}_${make.replace(/ /g,"_")}_${model.replace(/ /g,"_")}_${year ? year.slice(2) : "00"}` : `${prefix}_${ymm.replace(/ /g,"_")}`,
      ymm: ymm === undefined ? null : ymm,
      year: year === undefined ? null : year,
      make: make === undefined ? null : make,
      model: model === undefined ? null : model,
      upfits: selectedUpfits.map((u) => { return u.value }),
      msrp: msrp,
      net_price: netPrice,
      vehicle_class: vehicleClass,
      battery_kwh_usable: usableKwh === undefined ? null : usableKwh,
      battery_kwh: batteryCapacity === undefined ? null : batteryCapacity,
      mpg_c: mpgCty === undefined ? null : mpgCty,
      mpg_h: mpgHwy === undefined ? null : mpgHwy,
      evsa_selected: JSON.parse(evsaSelectOnAdd),
      ionev_selected: JSON.parse(ionevSelectOnAdd),
      is_ld: JSON.parse(isLd),
      is_gasoline: false,
      is_diesel: false,
      is_phev: false,
      is_bev: false,
      is_cng: false,
      is_rng: false,
      is_propane: false,
      is_hydrogen_fuelcell: false,
      is_hydrogen_lion_hybrid: false,
      cyl: cylinders === undefined ? null : cylinders,
      tank_liters: tankLiters === undefined ? null : tankLiters,
      expected_residual_price: expectedResidualPrice === undefined ? null : expectedResidualPrice,
      insurance: insurance === undefined ? null : insurance,
      disp: displacement === undefined ? null : displacement,
      maint_per_km: maintPerKm === undefined ? null : maintPerKm,
      img_url: JSON.parse(useExistingImageUrl) ? existingCandidateImageUrl : null
    };
    cand[powertrainType.bool] = true;
    // Gets passed to vcl check to make sure all necessary fields are complete  
    let vcl = sanitizeData(cand);
    if (vcl) {
      addCandidate(vcl);
      setShowModal(false);
    }
  }

  const handleSelectChange = (e) => {
    setSelectedUpfits(e);
  }

  const customValueRenderer = (selected) => {
    if (selected.length === 0) {
        return <p>None</p>
    }
    else if (selected.length === 1) {
        return <p>1 upfit</p>
    }
    else {
        return <p>{selected.length} upfits</p>
    }
}

function resetForm() {
  setYmm(null);
  setMSRP(null);
  setYear(null);
  setMake(null);
  setModel(null);
  setEvsaSelectOnAdd(true);
  setIonevSelectOnAdd(true);
  setNetPrice(null);
  setVehicleClass(null);
  setPowertrainType("");
  setBatteryCapacity(null);
  setUsableKwh(null);
  setMpgCty(null);
  setMpgHwy(null);
  setIsLd(true);
  setSelectedUpfits([]);
  setCylinders(null);
  setDisplacement(null);
  setExpectedResidualPrice(null);
  setMaintPerKm(null);
  setTankLiters(null);
  setExistingCandidate(null);
  setInsurance(null);
  setExistingCandidateImageUrl(null);
  setShowInvalidYmmError(false);
}

function propogateExistingValues(candidate_id) {
    const candidate = existingCandidates.find((c) => c.id === candidate_id);
    setShowInvalidYmmError(true);
    setInvalidYmmError(duplicate_candidate_error);
    setExistingCandidate(candidate);
    setEvsaSelectOnAdd(candidate.evsa_selected);
    setIonevSelectOnAdd(candidate.ionev_selected);
    setYmm(candidate.ymm);
    setMSRP(candidate.msrp);
    setNetPrice(candidate.net_price);
    setVehicleClass(candidate.vehicle_class);
    setIsLd(candidate.is_ld);
    setMaintPerKm(candidate.maint_per_km);

    let normalizedUpfits = [];
    candidate.upfits.forEach((u) =>normalizedUpfits.push({value: u.id, label: u.name}));  
    setSelectedUpfits(normalizedUpfits);

    let powertrain = null;
    if (candidate.is_diesel) powertrain = 'Diesel';
    if(candidate.is_propane) powertrain = 'Propane';
    if ((candidate.is_ice || candidate.is_gasoline) && !candidate.is_diesel && !candidate.is_propane) powertrain = 'Gasoline';
    if(candidate.is_cng) powertrain = 'CNG';
    if (candidate.is_phev) powertrain = 'PHEV';
    if (candidate.is_bev) powertrain = 'BEV';
    if (powertrain) {
      const pt = powertrains.filter((p)=> p.name === powertrain);
      handlePowertrainChange(pt[0]);
    } else {
      handlePowertrainChange("");
    }

    if (candidate.is_gasoline || candidate.is_diesel || candidate.is_ice || candidate.is_propane || candidate.is_cng || candidate.is_phev) {
      setMpgCty(candidate.mpg_c);
      setMpgHwy(candidate.mpg_h);
    }
    if (candidate.is_bev || candidate.is_phev) {
      setBatteryCapacity(candidate.battery_capacity);
      setUsableKwh(candidate.usable_kwh);
    }

    if (candidate.img_url) {
      setUseExistingImageUrl(true);
      setExistingCandidateImageUrl(candidate.img_url);
    } else {
      setUseExistingImageUrl(false);
      setExistingCandidateImageUrl(null);
    }
}

  return (
    <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={() => setShowModal(false)}>Close</S.ModalCloseButton>
        <S.ModalHeader>Add Candidate</S.ModalHeader>
        <S.ModalForm onSubmit={handleSubmit} autoComplete="off">

        {!cdrModal && <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Duplicate an existing candidate (optional):</S.ModalFormInputLabel>
            <SearchSelect 
              width='279px'
              borderColor='rgb(187, 187, 187)'
              borderRadius='5px'
              arrowPositionFromRight='-6px'
              height='40px'
              optionHeight="45px"
              color='rgb(71, 72, 74)'
              valueTextIndent={'-10px'}
              onChange={(e) => propogateExistingValues(e.value)}
              menuShouldScrollIntoView={false}
              value={existingCandidate ? {label: existingCandidate.ymm, value: existingCandidate.id} : {label: 'Select a candidate'}}
              options={existingCandidates && (existingCandidates.filter((cand)=>cand.evsa_selected || cand.ionev_selected).sort((a,b) => {
                const ymmA = a.ymm.toUpperCase(); // Ignore case during sorting
                const ymmB = b.ymm.toUpperCase();
              
                if (ymmA < ymmB) {
                  return -1;
                }
                if (ymmA > ymmB) {
                  return 1;
                }
                return 0;
              })).map((cand) => {
                return (
                  {label: cand.ymm, value: cand.id}
                );
              })}
            />
          </S.ModalFormInputWrapper>}

          {!cdrModal && 
            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>*Year Make Model:</S.ModalFormInputLabel>
              <S.ModalFormInput
                type='text'
                invalid={showInvalidYmmError} 
                pattern={ALPHANUMERIC_REGEX}
                title={INPUT_ERROR_MESSAGE}
                required
                onChange={(e) => {
                  if (existingCandidate && (e.target.value.toLowerCase() === existingCandidate.ymm.toLowerCase())) {
                    setInvalidYmmError(duplicate_candidate_error);
                    setShowInvalidYmmError(true);
                  } else if (existingCandidates && existingCandidates.some((candidate) => candidate.ymm.toLowerCase() === e.target.value.toLowerCase())) {
                    setInvalidYmmError(existing_candidate_error);
                    setShowInvalidYmmError(true);
                  } else setShowInvalidYmmError(false);
                  setYmm(e.target.value);
                }}
                placeholder={'ex. 2024 Ford Lightning'}
                value={ymm ? ymm : ""}
              />
            </S.ModalFormInputWrapper>
          }
          

          {cdrModal && 
            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>*Year:</S.ModalFormInputLabel>
              <S.ModalFormInput
                invalid={showInvalidYmmError}
                required
                width={'50%'}
                type='number'
                onKeyDown={(el) => preventNonIntegers(el)}
                onPaste={(el) => preventPasteNonIntegers(el)}
                onChange={(e) => {
                  if (existingCandidates && existingCandidates.some((candidate) => calculateYmm(candidate.year,candidate.make,candidate.model).toLowerCase() === calculateYmm(e.target.value,make,model).toLowerCase())) {
                      setShowInvalidYmmError(true);
                    } else setShowInvalidYmmError(false);
                  setYear(e.target.value);
                  }}
                  onWheel={(e) => e.target.blur()}
                  placeholder={'ex. 2022'}
                  min='1980'
                  max='2050'
                  step="1"
                  value={year ? year : ""}
                  />
            </S.ModalFormInputWrapper>
          }

          {cdrModal &&
            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>*Make:</S.ModalFormInputLabel>
              <S.ModalFormInput
                invalid={showInvalidYmmError}
                required
                type="text"
                pattern={ALPHANUMERIC_REGEX}
                title={INPUT_ERROR_MESSAGE}
                onChange={(e) => {
                  if (existingCandidates && existingCandidates.some((candidate) => calculateYmm(candidate.year,candidate.make,candidate.model).toLowerCase() === calculateYmm(year,e.target.value,model).toLowerCase())) {
                    setShowInvalidYmmError(true);
                  } else setShowInvalidYmmError(false);
                  setMake(e.target.value);
                }}
                placeholder={"ex. Ford, Chevrolet, Dodge"}
                value={make ? make : ""}
                />
            </S.ModalFormInputWrapper>
          }

          {cdrModal && 
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Model:</S.ModalFormInputLabel>
            <S.ModalFormInput
              invalid={showInvalidYmmError} 
              required
              type="text"
              pattern={ALPHANUMERIC_REGEX}
              title={INPUT_ERROR_MESSAGE}
              onChange={(e) => {
                setModel(e.target.value);
                if (existingCandidates && existingCandidates.some((candidate) => calculateYmm(candidate.year,candidate.make,candidate.model).toLowerCase() === calculateYmm(year,make,e.target.value).toLowerCase())) {
                  setShowInvalidYmmError(true);
                } else setShowInvalidYmmError(false);
              }}
              placeholder={"ex. F-150, Silverado, Ram 1500"}
              value={model ? model : ""}
              />
            </S.ModalFormInputWrapper>
          }

          {showInvalidYmmError &&
            <S.ModalFormInputWrapper>
                <S.ModalFormError>
                  {invalidYmmError}
                </S.ModalFormError>
            </S.ModalFormInputWrapper>
          }

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Mark ezEV/ezIO Selected?</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  width={'53%'}
                  value={evsaSelectOnAdd}
                  onChange={(e) => setEvsaSelectOnAdd(e.target.value)}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
              </S.ModalFormSelect>
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Mark ionEV Selected?</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  width={'53%'}
                  value={ionevSelectOnAdd}
                  onChange={(e) => setIonevSelectOnAdd(e.target.value)}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
              </S.ModalFormSelect>
            </S.ModalFormInputWrapper>

          
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*MSRP ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              width={'50%'}
              type="number"
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setMSRP(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 19000"}
              value={msrp === null ? '' : msrp}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Net Price ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              type="number"
              width={'50%'}
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setNetPrice(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 19000"}
              value={netPrice === null ? '' : netPrice}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Vehicle Class:</S.ModalFormInputLabel>
            <S.ModalFormSelect
              required
              value={vehicleClass === null ? "" : vehicleClass}
              width={'53%'}
              onChange={(e) => setVehicleClass(e.target.value)}>
              <option value="" key={"PH"} hidden>Select Class</option>
              {vehicleClasses.sort((a,b) => a.localeCompare(b)).map((v) => {
                return (
                <option key={v} value={v}>{v}</option>
                );
              })}
            </S.ModalFormSelect>
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Light Duty:</S.ModalFormInputLabel>
            <S.ModalFormSelect
              value={isLd}
              width={'53%'}
              onChange={(e) => setIsLd(e.target.value)}>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </S.ModalFormSelect>
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Maintenance per {userSettings.distance_labels.shortSingular} ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setMaintPerKm(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 100'}
              value={maintPerKm ? maintPerKm : ""}
            />
          </S.ModalFormInputWrapper>

          {cdrModal &&
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Cylinders:</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setCylinders(e.target.value)}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 6'}
              step="1"
              min="0"
              value={cylinders ? cylinders : ""}
            />
          </S.ModalFormInputWrapper>
          }
          
          {cdrModal &&
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Expected Residual Value ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setExpectedResidualPrice(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 100'}
              value={expectedResidualPrice ? expectedResidualPrice : ""}
            />
          </S.ModalFormInputWrapper>
          }

          {cdrModal &&
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Insurance per Year ({dbSettings.currency_symbol}):</S.ModalFormInputLabel>
            <S.ModalFormInput
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setInsurance(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 100'}
              value={insurance ? insurance : ""}
            />
          </S.ModalFormInputWrapper>
          }

          {cdrModal &&
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Displacement (Liters):</S.ModalFormInputLabel>
            <S.ModalFormInput
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setDisplacement(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 100'}
              value={displacement ? displacement : ""}
            />
          </S.ModalFormInputWrapper>
          }

          {cdrModal &&
          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Tank {userSettings.liquid_volume_labels.longPlural}:</S.ModalFormInputLabel>
            <S.ModalFormInput
              width={'50%'}
              type='number'
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setTankLiters(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={'ex. 100'}
              value={tankLiters ? tankLiters : ""}
            />
          </S.ModalFormInputWrapper>
          }

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Upfits:</S.ModalFormInputLabel>
            <div style={{width: "92%"}}>
                <MultiSelect
                  autosize={true}
                  options={upfits.map((u) => {return {label: u.name, value: u.id}})}
                  value={selectedUpfits}
                  onChange={handleSelectChange}
                  valueRenderer={customValueRenderer}
                  labelledBy="Select"
            />
            </div>      
            </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Powertrain:</S.ModalFormInputLabel>
            <S.ModalFormSelect
              required
              value={powertrainType.name}
              onChange={(e) => {const pt = powertrains.filter((p)=> p.name === e.target.value);handlePowertrainChange(pt[0])}}>
              <option value="" key={"PH"} hidden>Select Powertrain</option>
              {powertrains.sort((a,b) => a.name.localeCompare(b.name)).map((pt) => {
                return(
                  <option id={pt.id} key={pt.id} value={pt.name}>{pt.name}</option>
                    )
               })}
            </S.ModalFormSelect>
          </S.ModalFormInputWrapper>

          {(powertrainType.attributes?.indexOf("battery_kwh") > -1) && (
            <S.ModalFormFlexRow>
            <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>*Battery Capacity:</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              type="number"
              width={'80%'}
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setBatteryCapacity(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 67"}
              value={batteryCapacity === null ? '' : batteryCapacity}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
          <S.ModalFormInputLabel>Usable kWh:</S.ModalFormInputLabel>
          <S.ModalFormInput
            type="number"
            width={'80%'}
            onKeyDown={(el) => preventNonIntegers(el)}
            onPaste={(el) => preventPasteNonIntegers(el)}
            onChange={(e) => setUsableKwh(formatDecimal(e.target.value, 4))}
            onWheel={(e) => e.target.blur()}
            placeholder={"ex. 60"}
            value={usableKwh === null ? '' : usableKwh}
          />
          </S.ModalFormInputWrapper>
          </S.ModalFormFlexRow>
          )}

      {(powertrainType.attributes?.indexOf("mpg") > -1) && (
        <S.ModalFormFlexRow>
          <S.ModalFormInputWrapper>
          <S.ModalFormInputLabel>*{userSettings.liquid_fuel_economy} City:</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              type="number"
              width={'80%'}
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) => preventPasteNonIntegers(el)}
              onChange={(e) => setMpgCty(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 20"}
              value={mpgCty === null ? '' : mpgCty}
            />
          </S.ModalFormInputWrapper>

          <S.ModalFormInputWrapper>
          <S.ModalFormInputLabel>*{userSettings.liquid_fuel_economy} Hwy:</S.ModalFormInputLabel>
            <S.ModalFormInput
              required
              type="number"
              width={'80%'}
              onKeyDown={(el) => preventNonIntegers(el)}
              onPaste={(el) =>  preventPasteNonIntegers(el)}
              onChange={(e) => setMpgHwy(formatDecimal(e.target.value, 4))}
              onWheel={(e) => e.target.blur()}
              placeholder={"ex. 23"}
              value={mpgHwy === null ? '' : mpgHwy}
            />
          </S.ModalFormInputWrapper>
        </S.ModalFormFlexRow>
      )}
      
          {existingCandidateImageUrl && <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Use selected candidate image? (<a href={existingCandidateImageUrl} target="_blank" rel="noreferrer">View Here</a>)</S.ModalFormInputLabel>
            <S.ModalFormSelect
              value={useExistingImageUrl}
              onChange={(e) => setUseExistingImageUrl(e.target.value)}
            >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </S.ModalFormSelect>
          </S.ModalFormInputWrapper>
          }
      <S.ModalFormFlexRow>
        <S.ModalFormButtonWrapper>
          <S.ModalSaveButton 
          type='submit'
          disabled={showInvalidYmmError}
          title = {showInvalidYmmError ? 'You must change the YMM of the candidate first!' : ''}

          >Save</S.ModalSaveButton>
        </S.ModalFormButtonWrapper>
        <S.ModalFormButtonWrapper>
          <S.ModalResetButton
          type = 'reset'
          onClick={() => resetForm()}
          >Reset</S.ModalResetButton>
        </S.ModalFormButtonWrapper>
      </S.ModalFormFlexRow>
        </S.ModalForm>
      </S.ModalContainer>
    </S.ModalDisplay>
  );
}