import React from 'react';
import { useTable, useSortBy, usePagination, useFilters } from "react-table7";
import arrowUp from '../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../images/ic-arrow-unsort-4px-9px.gif';
import { execExcelDownload } from "./idling-csv-helper-functions";
import { DefaultColumnFilter } from './idling-table-filter';
import { formatCellData } from './idling-table-helper-functions';


export default function TableView(props){
  const { columns, data, filter, handleOnClick, handleSort, nolink, endDate, beginDate, csvFilename, tableType, dbDisplayName } = props;

  const cellStyle = (cell) =>{
    if(cell.column.Header === 'Total')return "idling-table-cell-sticky";
    if(nolink === true)return "idling-table-cell";
    return `idling-table-cell idling-link ${props.highlight(cell)}`;
  }

  const colType = (col) => {
    if(col === 'Total'){
      return "idling-table-header-sticky";
    }
    return "idling-table-header";
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  ///Used for Incident Count Table CSV
  /*
  const reverseData = (d) => {
    let reverseArr = []
    d.map((item) => {
      return reverseArr = [item, ...reverseArr]
    })
    return reverseArr;
  }
  */
  

  return(
    <>
    <div className='idling-table-info-wrapper'>
      <div className='idling-explain-text-wrapper'>
        {tableType === 'Incident Count' && <span className='idling-explain-text-2'>Incident count and duration, grouped by event duration</span>}
        {tableType === 'Weekly Summary' && <span className='idling-explain-text-2'>Incident counts that occured throughout each week</span>}
        <br />
        <span className="idling-explain-text">Click a column name to sort the table.</span>
      </div>
        <button onClick={()=>execExcelDownload({
          "tableType": tableType,
          "beginDate": beginDate,
          "endDate": endDate,
          "group": props.group,
          "vehicleClasses": props.vehicleClasses,
          "minDuration": props.minDuration,
          "columns": columns,
          "data": data,
          "filter": filter,
          "dbDisplayName": dbDisplayName,
          "filename": csvFilename + '.xlsx'})} className="idling-download-btn">Download</button>
      </div>
    <div className='table-container'>
      <table {...getTableProps()}
        onClick={function(e){handleSort(columns, e.target.outerText, props.desc)}}
      >
        
        <thead className='table-header-container'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} className={colType(column.Header)}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span className="idling-table-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <img src={ arrowDown } className="idling-table-sort-down-arrow" alt="Column sorted in descending order" />
                          : <img src={ arrowUp } className="idling-table-sort-up-arrow" alt="Column sorted in ascending order" />
                        : <img src={ arrowUnsel } className="idling-table-sort-both-arrow" alt="Unsorted column" />}
                    </span>
                  </div>
                  <div className="table-searchbar">{column.Header === 'Asset ID' ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
  
        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr className='idling-table-row' {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={cellStyle(cell)}
                      onClick={function(){handleOnClick(cell.row.original.vin, cell.row.original.ts)}}
                      {...cell.getCellProps()} id={cell.vin}>
                        {formatCellData(cell)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      {rows.length < 1 
      ?
      <p className="idling-no-data-msg">No data to display. Try adjusting date range or filters.</p> 
      :
      <div className='table-pagination-container'>
        <button
          className="pagination-button"
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </button>
        <span className='pagination-details'>Page {pageIndex + 1} of {pageOptions.length}</span>

        <button
        className="pagination-button"
        onClick={nextPage} 
        disabled={!canNextPage}>
        Next
      </button>
      </div>
      }
    </>
  )    
}

