import React from "react";
import { DevToolsSelectInput } from "../../../styles/core-styles/DevTools-styles";

export default function VehicleDropdown(props) {
  const vehicles = props.vehicles ?? [];
  
  if(props.selectedVIN === null)return null;

  const label = (v) => {
    if(v.asset_id !== "All Vehicles")return `${v.asset_id}-${v.year} ${v.make} ${v.model}`;
    return `${v.asset_id}`;
  }

  return (

    <DevToolsSelectInput
      onChange={props.handleChange}
      value={props.selectedVIN}
    >
      {vehicles.map((v) => {
        return (
          <option key={v.vin} value={v.vin}>
            {label(v)}
          </option>
        );
      })}
    </DevToolsSelectInput>
  );
}
