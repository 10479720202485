import styled from 'styled-components/macro';
import * as ColorScheme from './ColorScheme';

export const DashboardHeader = styled.div`
    position: fixed;
    top: 0;
    z-index: 15000;
    width: 100%;
    text-align: left;
    color: white;
    box-shadow: 0 7px 8px -6px black;
    background: #12365b;
    height: 100%;
    height: 80px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.356);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export const HeaderLogo = styled.img`
    position: absolute;
    top: -3px;
    left: 12px;
    width: 120px;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
`

export const HeaderUserMenuWrapper = styled.div`
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const HeaderUserMenu = styled.div`
    box-sizing: border-box;
    background-color: ${props => props.showCobrandingLogo ? ColorScheme.white : 'none'};
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    &:hover svg {
        fill: ${props => props.showCobrandingLogo ? ColorScheme.darkest_gray : `${ColorScheme.white}D9`}; 
    }
`

export const HeaderUserIcon = styled.div`
    height: 40px; // this element will try to grab extra vertical space if this line is not in place
    svg {
        fill: ${props => props.showCobrandingLogo ? ColorScheme.dark_gray : `${ColorScheme.white}`};
        transition: fill 0.2s ease;
        width: 40px;
        height: 40px;
    }
`

export const HeaderCobrandingLogo = styled.img`
    height: 35px;
    border: ${props => props.demo ? `1px dashed ${ColorScheme.arrow_red}` : 'none'};
`

export const CtaButton = styled.a`
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 10;
    background-color: var(--ezev-bluish);
    color: white;
    text-decoration: none;
    border-radius: 60px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    overflow:hidden;
    width: auto;
    max-width: 40px;
    -webkit-transition: max-width 0.5s; // animating max-width because width needs to be auto, and auto can't be animated
    transition: max-width 0.5s;
    :hover {
        max-width: 300px;
        background-color: var(--ezev-deep-aqua);
    }
`

export const CtaIcon = styled.span`
    font-size: 28px;
    font-weight:bold;
    padding: 0px 12px;
`

export const CtaText = styled.span`
    white-space: nowrap;
    padding-right: 12px;
`