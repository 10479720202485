import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default function InstallToggle(props) {
  const { toggleChange, toggleValue } = props;

  return (
    <div className="install-toggle-wrapper">
      <span>
        Show Group Summary
      </span>
      <Toggle
        //   disabled={props.toggleDisabled}
        defaultChecked={toggleValue}
        onChange={() => toggleChange(!toggleValue)}
        height={10}
        icons={false}
      />
      <span>
        Show Group Vehicle List
      </span>
    </div>
  );
}
