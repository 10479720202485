import React from "react"
import * as S from '../../../styles/core-styles/DevTools-styles';

export default function DropdownSelector(props) {
  let options = [];
  let defaultValue = '';
  let disabled = false;
  if(props.options)options = props.options;
  if(props.defaultValue)defaultValue = props.defaultValue;
  if(props.disabled)disabled = true;

  const findByValue = function(name){
    const idx = options.findIndex((e) => name === e.name);
    if(idx === -1)return null;
    return options[idx];
  }

  const handleGroupChange = (opt, id) => {
    if(opt)props.handleChange(opt.id);
  }

  if(props.searchEnabled && options.length > 0 && typeof options[0] !== 'undefined'){
    return(
      <div>
        <input className={props.className}
          onChange={(e) => handleGroupChange(findByValue(e.target.value))}
          placeholder={options[0].name}
          list={"opts"}/>
        <datalist id="opts">
          {options.map((o) => (
            <option key={o.id} value={o.name}/>
          ))}
        </datalist>
      </div>
    )
  }
  return (
    <S.DevToolsSelectInputSmall
      className={props.className}
      disabled={disabled}
      onChange={(e) => props.handleChange(e.target.value)}
      value={defaultValue}
    >
      {options.length === 0 ? (
        <option disabled defaultValue>
          Loading...
        </option>
      ) : (
        options.map((o) => (
          <option key={o.id} data-key={o.id} value={o.id}>
            {o.name}
          </option>
        ))
      )}
    </S.DevToolsSelectInputSmall>
  );
}