import React from "react";
export default function GroupSelector(props) {
  const createDropdownOptions = () => {
    if (props.vclData === undefined) return null;
    return props.vclData.map((g) => {
      return (
        <option key={g.pkid} value={g.groupId}>
          {g.groupNameUser}
        </option>
      );
    });
  };

  return (
    <select
      className="install-client-group"
      id="select"
      value={props.selectedGroup}
      onChange={(e) => props.setGroup(e.target.value)}
    >
      {createDropdownOptions()}
    </select>
  );
}
