import React from 'react';
import { useTable, useSortBy, usePagination } from "react-table";

export default function TableView(props){
  const { columns, data, colType, handleOnClick, handleSort, nolink } = props;

  const cellStyle = (cell) =>{
    if(nolink === true)return "fueln-table-cell";
    return `fueln-table-cell fueln-link ${props.highlight(cell)}`;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return(
    <div>
      <table {...getTableProps()}
        onClick={function(e){handleSort(columns, e.target.outerText, props.desc)}}
      >
        
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={colType(column.Header)}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}{" "}
                </th>
              ))}
            </tr>
          ))}
        </thead>
  
        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            let cn = "fueln-odd-table-row";
            if(idx % 2 === 0)cn = "fueln-even-table-row";
            return (
              <tr className={cn} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={cellStyle(cell)}
                      onClick={function(){handleOnClick(cell.row.original.vin, cell.row.original.ts)}}
                      {...cell.getCellProps()} id={cell.vin}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
          color="primary"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button color="primary" onClick={nextPage} disabled={!canNextPage}>
          {">"}
      </button>
    </div>
  )    
}

