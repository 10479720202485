import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

const ResetFormContainer = styled.form`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`

function PasswordChange({ passwordRules, handleRequest, cancelRequest }) {

  const [passw, setPassw] = useState("")
  const [currentPassw, setCurrentPassw] = useState("")
  const [confirmField, setConfirmField] = useState("")
  const [passwordColor, setPasswordColor] = useState({ color: "black" })
  const [conformColor, setConformColor] = useState({ color: "red" })
  const [conformMsg, setConformMsg] = useState("")



  //This hook listens for changes to the new password box and displays a message if the password is too short
  useEffect(() => {
    if (passw.length < passwordRules.minLength) {
      setPasswordColor({ color: "red" })
      setConformMsg(`Minimum password length: ${passwordRules.minLength} characters`)

      return;
    } else {
      setConformMsg("")
      setPasswordColor({ color: "black" })
    }
  }, [passw, passwordRules.minLength])

  //This hook listens for changes to the confirm password box and changes the color based on whether it matches the new password
  useEffect(() => {
    if (passw === confirmField) {

      setConformColor({ color: "green" })
    }
    else {
      setConformColor({ color: "red" })
    }
  }, [confirmField, passw])

  //No need for event listeners here since this doesn't modify state. 
  function handleSubmit(event) {
    event.preventDefault();
    if (confirmField !== passw) {
      window.alert("New passwords must match")
    }
    else if (passw.length < passwordRules.minLength) {
      window.alert(conformMsg)
    }
    else {
      handleRequest(currentPassw, passw);
    }
  }

  function handleCancel() {
    cancelRequest();
  }




  return (
    <div className="swt-password-change">
      <ResetFormContainer className="swt-auth" onSubmit={handleSubmit}>
        <p className="swt-credential-input-label">Current Password</p>
        <input
          className="swt-credential-input"
          name="password0"
          type="password"
          value={currentPassw}
          onChange={(event) => { setCurrentPassw(event.target.value); }}
        />
        <p className="swt-credential-input-label">New Password</p>
        <input
          className="swt-credential-input"
          name="password0"
          type="password"
          style={passwordColor}
          value={passw}
          onChange={(event) => { setPassw(event.target.value) }}
        />
        <p id="swt-credential-msg-0" className="swt-credential-msg"></p>
        <p className="swt-credential-input-label">Confirm Password</p>
        <input
          className="swt-credential-input"
          name="password1"
          type="password"
          value={confirmField}
          style={conformColor}
          onChange={(event) => { setConfirmField(event.target.value) }}
        />
        <p id="swt-credential-msg-1" className="swt-credential-msg">
          {conformMsg}
        </p>
        <div className="swt-button-wrapper">
          <button
            className="swt-submit-button"
            type="reset"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="swt-submit-button" type="submit">
            Submit
          </button>
        </div>
      </ResetFormContainer>
    </div>
  );

}

export default PasswordChange;
