import {useMemo,useEffect,useRef, useState} from 'react';
import { useTable, useSortBy, usePagination, useFlexLayout, useFilters } from "react-table";
import arrowUp from '../../../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../../../images/ic-arrow-unsort-4px-9px.gif';
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import TableExplainTextHover from './TableExplainTextHover';

const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      display: 'flex',
    },
  },
]

// Table Notes:
// Table accepts stickyCols prop to control sticky columns.
// stickyCols can be set to 1, 2, or 3 depending on how many columns you want sticky.
// If no stickyCols prop is provided, no sticky columns will be set. 

export default function AdminTableView(props){
  const { columns, data, skipPageResetRef, noDataMessage } = props;
  const [explanationText, setExplanationText] = useState('');
  const [cursorCoords, setCursorCoords] = useState({x: 0, y: 0});
  const [tableScrollPosition, setTableScrollPosition] = useState(0);

  // Hidden columns aren't provided for every usage of this component, and shouldn't need to be
  // Check for truthiness before accessing the prop
  const hiddenColumns = props.hiddenColumns ? props.hiddenColumns : [];           
  let noData = 'No data to display.';
  if (noDataMessage) noData = noDataMessage;

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout
      // This is the default widths for every column
      // width is used for both the flex-basis and flex-grow
      width: 250,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: 0,
        pageSize: 30,
        sortBy: [
          props.sortBy ? props.sortBy : {id: columns[0].accessor, desc: false}
        ],
      },
      manualSortBy: false,
      autoResetSortBy: false,
      autoResetPage: skipPageResetRef ? skipPageResetRef.current : false
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const tableRef = useRef(null);

  useEffect(() => {
    if (typeof tableRef.current.scrollTo === "function") tableRef.current.scrollTo({top:0});
  }, [pageIndex]);

  function handleMouseEnter (e, text, elementId) {
    const elementOffsetLeft = document.getElementById(elementId).offsetLeft;
    const elementOffsetHeight = document.getElementById(elementId).offsetHeight;
    if(text) setExplanationText(text);
    setCursorCoords({x:elementOffsetLeft - tableScrollPosition, y: elementOffsetHeight});
  }

let widths = [];
  return(
    <>
    <S.AdminTableContainer ref={tableRef}>
      <S.AdminTable {...getTableProps()} id='cdr-table' style={{minWidth: props.minWidth ? props.minWidth : 'auto'}} onScroll={() => {
        setExplanationText('');
        setTableScrollPosition(document.getElementById('cdr-table').scrollLeft);
      }}>
        
        <S.AdminTableHeadRow>
        <TableExplainTextHover cursorCoords={cursorCoords} explainText={explanationText} />
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                widths.push(column.width)
                return(
                <S.AdminTableHeader
                  {...column.getHeaderProps(headerProps)}
                  //id needs to be extended with '-col' because #selected is used for dev-tools tab styles and will effect columns with 'Selected' as header
                  id={`${column.id}-col`}
                  styles={column.styles}
                  stickyCols={props.stickyCols}
                  widthsArr={widths}
                  onMouseEnter={(e) => {handleMouseEnter(e, column.explainText, `${column.id}-col`)}}
                  onMouseLeave={() => setExplanationText('')}
                > 
                 <S.AdminTableHeaderLabelWrapper {...column.getSortByToggleProps({ title: undefined})}>
                  <span data-testid={`${column.id}-header-testid`}>{column.render("Header")}</span>{" "}
                  <S.AdminTableSortArrows>
                      {column.canSort === false ?
                      <></>
                      :
                      column.isSorted
                        ? column.isSortedDesc
                          ? <img src={ arrowDown } alt="Column sorted in descending order" />
                          : <S.SortArrowUp src={ arrowUp } alt="Column sorted in ascending order" />
                        : <img src={ arrowUnsel } alt="Unsorted column" />}
                  </S.AdminTableSortArrows>
                  </S.AdminTableHeaderLabelWrapper>
                  <div>{column.Filter ? column.render('Filter') : null}</div>
                </S.AdminTableHeader>
)})}
            </tr>
          ))}
        </S.AdminTableHeadRow>
        
        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <S.AdminTableRow {...row.getRowProps()}
                styles={row.original.styles}
                data-testid={"adminTableRow-testid"}
              >
                {row.cells.map((cell) => {
                  return (
                    <S.AdminTableCell
                      {...cell.getCellProps(cellProps)}
                      id={cell.vin}
                      title={(typeof cell.value === 'object') ? cell.value?.props[cell.column.id] : cell.value?.toString()}
                      styles={cell.row.original.styles}
                      color={row.original?.settings_changed?.includes(cell.column.id) ? 'green' : 'initial'}
                      stickyCols={props.stickyCols}
                      widthsArr={widths}
                      checkbox={cell.value?.props?.hasOwnProperty('bool') ? "true" : "false"}
                      data-testid={`${cell.column.id}-testid`}
                      >
                      {cell.render("Cell")}
                    </S.AdminTableCell>
                  );
                })}
              </S.AdminTableRow>
            );
          })}
        </tbody>
      </S.AdminTable>
      </S.AdminTableContainer>
      {rows.length < 1 
      ?
      <S.TableNoDataMessage>
        {noData}
      </S.TableNoDataMessage> 
      :
      <S.PaginationContainer>
        <S.PaginationButton
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </S.PaginationButton>
        <S.PaginationDetails>Page {pageIndex + 1} of {pageOptions.length}</S.PaginationDetails>
        <S.PaginationButton
          onClick={nextPage} 
          disabled={!canNextPage}>
          Next
        </S.PaginationButton>
      </S.PaginationContainer>
      }
    </>
  )    
}

