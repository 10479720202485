import React from "react";
import ReactLoading from "react-loading";

export default function Loading(props) {
  return (
    <div className = 'install-loading-wrapper'>
      <ReactLoading
        className={"install-loading"}
        type={"cylon"}
        color={"rgba(128, 128, 128, 0.61)"}
        viewBox="0 0 2 2"
        width={props.width ? props.width : "30rem"}
        height={props.height ? props.height : "28rem"}
      />
    </div>
  );
}
