import React, { useEffect, useState, useMemo, Suspense } from "react";
import { ChargeLocation } from "../swt-ezio";
import Table from "./Table";
import * as S from "../../styles/ezio-styles/LocationsDisplay-styles";
import { Redirect } from "react-router-dom";
import { columnsToExcelHeaders, rowsToData } from "../../utils/ezio-utils/ExcelUtils";
import { DateTime } from "luxon";

//code-splitting imports
const LocationsMap = React.lazy(() => import("./LocationsMap"));
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));


type LocationsDisplayProps =
    {
        chargeLocations: Map<string, ChargeLocation>
        viewMode: "map" | "list"
        dbName: string
        forceNavigate: Function
        selectedChargeLocation?: string
        category?: string,
        vehicleClasses?: Array<string>,
        groups?: Array<any>,
        electrification?: number,
        beginDate?: DateTime,
        endDate?: DateTime,
        dbDisplayName: string,
        userSettings: any,
        ALL_LOCATIONS_ID: string
    }   

const tableText = {
    title: "EVSE Locations",
    subtitle: <div>
        This table lists the locations where vehicles are projected to charge. Select a row below to see the location summary. <br />
        If a location is not showing any vehicles or charging data, try adjusting the filters above.
    </div>,
    buttonText: "Map View"
}

const mapText = {
    title: "EVSE Locations",
    subtitle:
        <div>
            This map shows the projected charging locations for your vehicles. Click on the location to view the kW demand at each site.<br />
            If a location is not showing any vehicles or charging data, try adjusting the filters above.
        </div>,
    buttonText: "List View"
}


export default function LocationsDisplay({ chargeLocations, viewMode, dbName, forceNavigate, selectedChargeLocation, category, groups, electrification, vehicleClasses, beginDate, endDate, dbDisplayName, userSettings, ALL_LOCATIONS_ID }: LocationsDisplayProps) {
    const [infoText, setInfoText] = useState(viewMode === "list" ? tableText : mapText);
    //const [mergedLocations, setMergedLocations] = useState<Array<ChargeLocation>>([]);

/*

    //Merge the locations and combine data from the location details endpoint and the location groupings endpoint
    useEffect(() => {
        function getDetailsAboutParsed(location: ChargeLocation) {

            var mergedDetails: ChargeLocation = {
                pkids: location.locationIds,
                address: location.address,
                latitude: location.latitude,
                longitude: location.longitude,
                vehicleCount: 0,
                chargeDurationSeconds: 0,
                chargeDayCount: 0,
                peakKw: 0,
                peakCost: 0,
                evRecommendationCount: 0,
                locationEmpty: true,
                maxVehiclesCharging: 0,
            }
            location.locationIds.forEach((pkid) => {
                if (locationDetails.has(pkid)) {
                    let currDetails: DetailedLocation = locationDetails.get(pkid)!
                    mergedDetails.vehicleCount = mergedDetails.vehicleCount + parseInt(currDetails.vehicleCount)
                    mergedDetails.chargeDurationSeconds += currDetails.chargeDurationSeconds
                    mergedDetails.chargeDayCount = mergedDetails.chargeDayCount + parseInt(currDetails.chargeDayCount)
                    mergedDetails.peakKw += currDetails.peakKw
                    mergedDetails.peakCost += currDetails.peakCost
                    mergedDetails.evRecommendationCount += parseInt(currDetails.evRecommendationCount)
                    mergedDetails.locationEmpty = false
                    mergedDetails.maxVehiclesCharging += parseInt(currDetails.maxVehiclesCharging)
                }

            })
            return (mergedDetails)
        }
        setMergedLocations(locations.map((item) => {
            return (getDetailsAboutParsed(item))
        })
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationDetails])

*/


    const locationsWithSelected = useMemo(() => {
        let r = Array.from(chargeLocations.values());
        return(r.filter((l)=> l.uuid !== ALL_LOCATIONS_ID));
    }, [chargeLocations, ALL_LOCATIONS_ID]);

    useEffect(() => {
        if (viewMode === "list") {
            setInfoText(tableText)
        }
        else {
            setInfoText(mapText)
        }

    }, [viewMode])

    function rowDisabled(row: any) {
        return (row.locationEmpty || (selectedChargeLocation !== ALL_LOCATIONS_ID && row.uuid !== selectedChargeLocation))
    }

    //Blank out the entry with a hyphen if the location had no associated details, otherwise run the accessor
    function formatCell(row: any, accessor: Function) {
        if (rowDisabled(row)) return "-";
        else return accessor(row);
    }

    function valueToLinkCell(cell: any) {
        if (!rowDisabled(cell.row.original)) {
            return (
                <S.CellFillLink onClick={() => forceNavigate(cell.row.original.uuid)} to={`/ezio/locations/${cell.row.original.uuid}`}>{cell.cell.value}</S.CellFillLink>
            )
        }
        else {
            return (
                <S.DisabledCell>{cell.cell.value}</S.DisabledCell>
            )
        }

    }

    const excelDataSort = (a: any, b: any) => {
        // [2] = projected to charge count
        // [3] = ev rec count
        // [1] = address

        // Hyphens cause all sorts of issues with sorting. Replace with spaces, excel will handle and display hyphens
        if(a[2] === "-") a[2] = "";
        if(a[3] === "-") a[3] = "";
        if(b[2] === "-") b[2] = "";
        if(b[3] === "-") b[3] = "";

        // Sort by projected to charge count
        if(a[2] < b[2]) return 1;
        if(a[2] > b[2]) return -1;

        // Secondary sort ev rec count
        if(a[3] < b[3]) return 1;
        if(a[3] > b[3]) return -1;

        // Tertiary address sort
        return a[1] < b[1] ? -1 : 1;
    }

    function AddressColumnFilter({ column: { filterValue, setFilter } }: any) {
        return (
            <input
                className="swt-ezio-search-input"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined)// Set undefined to remove the filter entirely
                }}
                placeholder={`Search`}
            />
        )
    }

    // NOTE need error handling for all columns. Added check for division by zero to totalChargingHours but do NOT want to keep this pattern
    const LocationsTableColumns = useMemo(() => [
        {
            Header: "Location ID",
            simpleHeader: "Location ID",
            accessor: (row: any) => row.uuid,
            Cell: valueToLinkCell,
            id: "locationId",
            showInInterface: false,
            showInDownload: true
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    Location
                </div>), 
            accessor: (row: any) => row.nickname ?? row.address,
            simpleHeader: "Location",
            sortType: "basic",
            Cell: valueToLinkCell,
            Filter: AddressColumnFilter,
            minWidth: 306,
            id: "locationAddress",
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: "Number of Vehicles Charging",
            simpleHeader: "Number of Vehicles Charging",
            accessor: (row: any) => formatCell(row, (row: any) => row.inBoundChargingVehiclesCount),
            Cell: valueToLinkCell,
            id: "chargingVehiclesCount",
            Filter: <div />,
            width: 110,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    EV Rec
                    <br />
                    Count
                </div>
            ),
            simpleHeader: "EV Rec Count",
            id: "evRecommendationCount",
            accessor: (row: any) => formatCell(row, (row: any) => row.evRecommendationCount),
            Cell: valueToLinkCell,
            Filter: <div />,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    Total Charging Hours
                    <br />
                    (Avg per Day)
                </div>
            ), 
            simpleHeader: "Total Charging Hours (Avg per Day)",
            id: "totalAvgChargingHours",
            accessor: (row: any) => formatCell(row, (row: any) => (row.chargeDayCount > 0 ? ((row.chargeDurationSeconds / 3600) / row.chargeDayCount).toFixed(1) : 0)),
            Cell: valueToLinkCell,
            Filter: <div />,
            width: 140,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: "L2 Port Count",
            simpleHeader: "L2 Port Count",
            id: "l2PortCount",
            accessor: (row: any) => formatCell(row, (row: any) => row.drawHistogram[0] + row.drawHistogram[1] + row.drawHistogram[2]),
            Cell: valueToLinkCell,
            Filter: <div />,
            width: 120,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: "DC Port Count",
            simpleHeader: "DC Port Count",
            id: "dcPortCount",
            accessor: (row: any) => formatCell(row, (row: any) => row.drawHistogram[3] + row.drawHistogram[4]),
            Cell: valueToLinkCell,
            Filter: <div />,
            width: 120,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    Peak kW
                    <br />
                    Usage
                </div>
            ),
            simpleHeader: "Peak kW Usage",
            id: "peakKwUsage",
            accessor: (row: any) => formatCell(row, (row: any) => row.peakKw),
            Cell: valueToLinkCell,
            Filter: <div />,
            width: 95,
            showInInterface: true,
            showInDownload: true
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    kW Demand
                    <br />
                    Graph
                </div>
            ),
            simpleHeader: "kW Demand Graph",
            id: "demandGraph",
            accessor: (row: any) => `/ezio/kw-demand-monthly/${row.uuid}`, Cell: (cell: any) => {
                return (rowDisabled(cell.row.original) ? "-" : <S.ViewGraphLink to={cell.value} onClick={() => forceNavigate(cell.row.original.uuid)}>View Graph</S.ViewGraphLink>)
            },
            Filter: <div />,
            showInInterface: true,
            showInDownload: false
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [forceNavigate])

    return (
        <>
            <S.DisplayLayout>
                <S.TitleAndButtonWrapper>
                    <S.TableTitle>
                        {infoText.title}
                    </S.TableTitle>

                    <S.SwapViewButton >
                        <S.ButtonLink to={`/ezio/locations/${viewMode === "list" ? "map" : "list"}`} id={"swap-view-button-testid"}>{infoText.buttonText}</S.ButtonLink>

                    </S.SwapViewButton>
                </S.TitleAndButtonWrapper>
                <S.TableSubtitle>
                    {infoText.subtitle}
                </S.TableSubtitle>
                {viewMode === "list"
                    ? <Table
                        columns={LocationsTableColumns}
                        data={locationsWithSelected}
                        defaultPrimarySort={{columnId:"chargingVehiclesCount",sortDesc:true}}
                        defaultSecondarySort={{columnId:"evRecommendationCount",sortDesc:true}}
                        defaultTertiarySort={{columnId:"locationAddress",sortDesc:false}}
                        getRowProps={(row: any) => {
                            const shouldNotHighlight = rowDisabled(row.original);
                            return ({
                                style: {
                                    color: shouldNotHighlight ? '#74787D' : null,
                                    textDecorationLine: shouldNotHighlight ? 'none' : null,
                                    textShadow: shouldNotHighlight ? 'none' : null,
                                }
                            });
                        }}
                    />
                    : viewMode === "map"
                        ? <Suspense fallback={<div></div>}><LocationsMap chargeLocations={chargeLocations} forceNavigate={forceNavigate} selectedLocationId={selectedChargeLocation} userSettings = {userSettings} ALL_LOCATIONS_ID={ALL_LOCATIONS_ID} /></Suspense>
                        : <Redirect to={"/ezio"} />
                }
                <div>
                    {viewMode === "list" &&
                        <Suspense fallback={<div></div>}>
                            <ExcelDownloadButton
                                category={category}
                                vehicleClasses={vehicleClasses}
                                groups={groups}
                                electrification={electrification}
                                beginDate={beginDate}
                                endDate={endDate}
                                db={dbName}
                                csvType={'locations'}
                                data={rowsToData(LocationsTableColumns, locationsWithSelected, excelDataSort)}
                                columns={columnsToExcelHeaders(LocationsTableColumns)}
                                dbDisplayName = {dbDisplayName}
                                userSettings={userSettings}
                            />
                        </Suspense>
                    }
                </div>


            </S.DisplayLayout>
        </>



    )
}
