import React from 'react';
import { formatDate } from './DataFormatter';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
export default function DateSelector(props) {
  if (
    props.dateStart === '' ||
    props.dateEnd === '' ||
    props.dateStart === typeof Object ||
    props.dateEnd === typeof Object
  )
    return null;

  let start = parseISO(props.dateStart);
  let end = parseISO(props.dateEnd);
  let fleetStart = parseISO(props.fleetStart);
  let fleetEnd = parseISO(props.fleetStop);

  return (
    <div className={props.className}>
      <h3 className="sftd-date"> to </h3>
      <DatePicker
        className="sftd-datePicker-start"
        placeholderText={formatDate(props.dateStart)}
        selected={start}
        format="MM/dd/yy"
        selectsStart
        startDate={fleetStart}
        minDate={fleetStart}
        maxDate={fleetEnd}
        endDate={fleetEnd}
        onChange={e => props.handleStartDateChange(e)}
      />

      <DatePicker
        className="sftd-datePicker-end"
        placeholderText={formatDate(props.dateEnd)}
        selected={end}
        format="MM/dd/yy"
        selectsEnd
        startDate={fleetStart}
        minDate={fleetStart}
        maxDate={fleetEnd}
        endDate={fleetEnd}
        onChange={e => props.handleEndDateChange(e)}
      />
    </div>
  );
}
