import React, {useEffect, useState} from 'react';
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import SingleSelectDropdown from "../admin-components/SingleSelectDropdown"

const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

export default function UserSettings (props) {
    const [isDefaultSettings, setIsDefaultSettings] = useState(false);
    const [settings, setSettings] = useState(props.user.userSettings);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        fetch(`${props.apiURL}getCurrencies`, {
            headers: { Authorization: `Bearer ${props.user.token}` },
            }).then(res => res.json())
            .then((data) => {
                if (data.status === "error")
                    alert(SERVER_ERRORED_MESSAGE);
                else {
                    if(data && data['data'] && data['data'][0]){
                        setCurrencies(data['data']);
                    }
                }
           })
           .catch((err) => {console.error(err)});
    }, [props.apiURL, props.user.token])

    useEffect(() => {
        if (settings.email === 'default') {
            setIsDefaultSettings(true);
        }
    }, [settings])

    const updateUserSettings = () => {
        let data = settings;
        data.email = props.user.email;
        let url = props.apiURL + 'updateUserSettings';
        fetch(url, {
            method: "POST",
            headers: { 
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.user.token}` 
            },
            body: JSON.stringify(data)
        })
        .then((resp) => {
            if(resp.status === 200) {
                alert('User settings successfully updated.')
                setIsDefaultSettings(false);
                props.updateUserSettings(settings);
            }
            else {
                alert('Error updating user settings')
            }
        })
        
    }

    const mapCurrencyOptions = () => {
        return currencies.map((c) => {
            return { label: `${c.currency_code} (${String.fromCodePoint(c.currency_symbol)})`, value: c.currency_code }
        })
    }

    const settingsFields = [
        {label: 'Distance', settingType: 'select', category: 'use_kms', options: [
            {label: 'Kms', value: true},
            {label: 'Miles', value: false}
        ]
        },
        {label: 'Weight', settingType: 'select', category: 'use_kgs', options: [
            {label: 'Kgs', value: true},
            {label: 'Lbs', value: false}
        ]
        },
        {label: 'Temperature', settingType: 'select', category: 'use_celsius', options: [
            {label: 'Celsius', value: true},
            {label: 'Fahrenheit', value: false}
        ]
        },
        {label: 'Volume', settingType: 'select', category: 'use_liters', options: [
            {label: 'Liters', value: true},
            {label: 'Gallons', value: false}
        ]},
        {label: 'Currency', settingType: 'select', category: 'currency', options: mapCurrencyOptions()},
        {label: 'Liquid Fuel Economy Measurement', settingType: 'select', category: 'liquid_fuel_economy', options: [
            {label: 'km/L', value: 'km/L'},
            {label: 'L/100 km', value: 'L/100 km', isDisabled: true},
            {label: 'MPG (Imp)', value: 'MPG(Imp)', isDisabled: true},
            {label: 'MPG (US)', value: 'MPG'},
            {label: 'km/gallon', value: 'km/gallon'},
            {label: 'gal/100km', value: 'km/100km', isDisabled: true}
        ]
        },
        {label: 'Electric Fuel Economy Measurement', settingType: 'select', category: 'elec_fuel_economy', options: [
            {label: 'Metric', options: [
                {label: 'km/kWh', value: 'km/kWh'},
                {label: 'L-e/100 km', value: 'L-e/100 km', isDisabled: true},
                {label: 'km/L-e', value: 'km/L-e', isDisabled: true},
                {label: 'kWh/100 km', value: 'kWh/100 km', isDisabled: true},
                {label: 'Wh/km', value: 'Wh/km', isDisabled: true},
                {label: 'kWh/km', value: 'kWh/km', isDisabled: true}

            ]},
            {label: 'US/Imperial', options: [
                {label: 'mi/kWh', value: 'mi/kWh'},
                {label: 'MPG-e (Imp.)', value: 'MPG-e (Imp.)', isDisabled: true},
                {label: 'MPG-e (US)', value: 'MPG-e (US)', isDisabled: true},
                {label: 'kWh/100 mi', value: 'kWh/100 mi',isDisabled: true},
                {label: 'Wh/mi', value: 'Wh/mi', isDisabled: true},
                {label: 'kWh/mi', value: 'kWh/mi', isDisabled: true}
            ]}
        ]
        },
        {label: 'Date Display', settingType: 'select', category: 'date_display', options: [
            {label: 'MM/DD/YY', value: 'MM/dd/yy'},
            {label: 'MM/DD/YYYY', value: 'MM/dd/yyyy'},
            {label: 'DD/MM/YY', value: 'dd/MM/yy'},
            {label: 'DD/MM/YYYY', value: 'dd/MM/yyyy'},
            {label: 'YY/MM/DD', value: 'yy/MM/dd'},
            {label: 'YYYY/MM/DD', value: 'yyyy/MM/dd'},
        ]
        },
        {label: 'Timestamp Display', settingType: 'select', category: 'ts_display', options: [
            {label: 'hh:mm:ss xm (12 hour)', value: 'hh:mm:ss xm'},
            {label: 'hh:mm:ss (24 hour)', value: 'hh:mm:ss'},
            {label: 'hh:mm xm (12 hour)', value: 'hh:mm xm'},
            {label: 'hh:mm (24 hour)', value: 'hh:mm'},
        ]
        },
        {label: 'Week Start', settingType: 'select', category: 'week_start', isDisabled: true, options: [
            {label: 'Sunday', value: 0},
            {label: 'Monday', value: 1},
            {label: 'Tuesday', value: 2},
            {label: 'Wednesday', value: 3},
            {label: 'Thursday', value: 4},
            {label: 'Friday', value: 5},
            {label: 'Saturday', value: 6},
        ]
        },
    ]

    function handleChange (e, category) {
        setSettings({
            ...settings,
            [category]: e.value
        })
    }

    function handleSubmit () {
        updateUserSettings()
    }

    function generateInputs() {
        return settingsFields.map((setting) => {
            if(setting.settingType === 'select') {
                let filteredOption = ''
                if(setting.category === 'elec_fuel_economy') {
                    //Since elec_fuel_economy options are split in 2 groups, we need to join the options before filtering.
                    filteredOption = setting.options[0].options.concat(setting.options[1].options).filter((o) => o.value === settings[setting.category])
                } else {
                    filteredOption = setting.options.filter((o) => o.value === settings[setting.category])
                }
                return (
                    <S.UserSettingsInputWrapper key={setting.label}>
                    <label>{setting.label}:</label>
                    <SingleSelectDropdown
                        isDisabled={setting.isDisabled}
                        options={setting.options}
                        value={filteredOption}
                        onChange={(e) => handleChange(e, setting.category)}
                    />
                    </S.UserSettingsInputWrapper> 
            )
            } else {
                return (
                    <S.UserSettingsInputWrapper key={setting.label}>
                    <label>{setting.label}:</label>
                    <S.SettingsInput 
                        type={setting.inputType}
                        value={settings[setting.category]}
                        onChange={(e) => handleChange(e.target, setting.category)}
                    />
                </S.UserSettingsInputWrapper>
                )
            }
        })
    }

    return (
        <S.UserSettingsPageWrapper>
            <h1>User Settings</h1>
            {isDefaultSettings &&
            <S.UserSettingsInfoWrapper>
                <span>You are currently using default settings options. Please configure your personal settings below.</span>
            </S.UserSettingsInfoWrapper>
            }
            <S.UserSettingsContainer>
                {generateInputs()}
            </S.UserSettingsContainer>
            <S.CtaButton onClick={() => handleSubmit()}>Save</S.CtaButton>
        </S.UserSettingsPageWrapper>
        
    )
}