import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import ezioLogo from '../../images/ezIO-logo.png'
import * as ColorScheme from './PdfColors.js'

const styles = StyleSheet.create({
    headerContainer: {
      position: "absolute",
      top: 38,
      left: 38,
      display: "flex",
      flexDirection: "row",
      alignItems:"center",
      gap: 10,
      marginBottom: 20
    },
    headerLogo: {
      width: 45,
      height: 50,
    },
    headerTitle: {
      color: ColorScheme.dark_gray,
      fontSize: 18,
      lineHeight: 1.2,
    },

    headerSubtitle: {
      color: ColorScheme.medium_gray,
      fontSize: 14
    }
})

export const PdfHeader = ({dbDisplayName,locationAddress}) => {

  return (
    <View style={styles.headerContainer} fixed>
      <Image style={styles.headerLogo} src={ezioLogo} alt="ezIO Logo"/>
      <View>
        <Text style={styles.headerTitle}>Projected Charging Assessment - {dbDisplayName}</Text>
        <Text style={styles.headerSubtitle}>{locationAddress}</Text>
      </View>
    </View>
  )
}