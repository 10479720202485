import React from "react";
function PasswordResetMsg() {
  return (
    <div>
      <p className="swt-reset-password-txt">
        If the given email exists in our system an email with a password reset
        link will be sent to it within one hour.
      </p>
    </div>
  );
}

export default PasswordResetMsg;
