export function numberWithCommas(x) {
  if (x === undefined) return;
  if (isNaN(x)) return "-";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function FormatCommas(value) {
  if (!value) return;
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatDate(i) {
  if (i === undefined || i === null) return "-";
  else if (typeof i === "string") {
    var year = i.slice(2, 4);
    var month = i.slice(5, 7);
    var day = i.slice(8, 10);
    return `${month}/${day}/${year}`;
  } else {
    var Day = i.getDate();
    var Month = i.getMonth() + 1;
    var Year = i.getFullYear();
    return `${Month}/${Day}/${Year}`;
  }
}

export function formatDateTime(value) {

  if (!value || value === null) return;
  var date = new Date(value);
  var day = new Date(date).getDate() - 1;
  var month;
  var year = new Date(date).getFullYear().toString();

  // Cleans up the pesky end of month issue.
  if(day === 0 ){
    date.setMonth(date.getMonth() - 1)
    month = new Date(date).toLocaleString("default", { month: "short" });
    day = new Date(year, date.getMonth(), 0).getDate() + 1;
  }
  else{
    month = new Date(date).toLocaleString("default", { month: "short" });
  }


  return `${month + " " + day + ", " + year} `;
}

export function findMostFrequent(arr) {
  let counts = arr.reduce((a, c) => {
    a[c] = (a[c] || 0) + 1;
    return a;
  }, {});
  let maxCount = Math.max(...Object.values(counts));
  let mostFrequent = Object.keys(counts).filter((k) => counts[k] === maxCount);
  return { maxCount: maxCount, mostFrequent: mostFrequent.join(", ") };
}

export function checkForDuplicateLocationNames(locations) {
  let checkedLocations = [];
  if (!locations) return;
  locations.forEach((loc) => {
    let dupLocs = checkedLocations.filter((l) => l === loc.address);
    checkedLocations.push(loc.address);
    if (dupLocs.length > 0) {
      loc.address = loc.address + ` (location ${dupLocs.length + 1})`;
    }
  });
  return locations;
}

export function humanFriendlyTime(timeIndex, tod) {
  if (timeIndex % 1 === 0) timeIndex = `${parseFloat(timeIndex)}`;
  let decimalTime = timeIndex.split(".");
  let hour = decimalTime[0];
  let minutes = decimalTime[1];
  if (parseInt(hour) > 12) {
    hour = hour - 12;
    tod = "PM";
  }
  switch (minutes) {
    case "75":
      minutes = "45";
      break;
    case "5":
      minutes = "30";
      break;
    case "25":
      minutes = "15";
      break;
    default:
      minutes = "00";
  }
  if (hour === "0" || hour === "undefined") hour = 12;
  return `${hour + ":" + minutes + tod}`;
}

export function reasonForFailure(v, compareInClass) {

  if (v.overall > 84 && compareInClass === false) return "N/A";
  if (v.bestInClassOverall > 84 && compareInClass === true) return "N/A";
  //NOTE - Will eventually need to Add in operational fit logic (once finalized) -  Composite of confidence, parking and energy (reference ezEV Analytics)
  let str = "";
  
  if (compareInClass === false) {
    if (v.economics < 85) str = "Economics";
    if (v.parking < 75)
      str !== "" ? (str = str + ", Parking") : (str = "Parking");
    if (v.energy < 90 && v.energy !== -1)
      str !== "" ? (str = str + ", Energy") : (str = "Energy");
    if (v.recommendation === "Optimization Candidate")
      str = str + ", Low Mileage Vehicle";
  }
  if (compareInClass === true) {
    if (v.bestInClassEconomics < 85) str = "Economics";
    if (v.bestInClassParking < 75)
      str !== "" ? (str = str + ", Parking") : (str = "Parking");
    if (v.bestInClassEnergy < 90 && v.bestInClassEnergy !== -1)
      str !== "" ? (str = str + ", Energy") : (str = "Energy");
    if (v.bestInClassRecommendation === "Optimization Candidate")
      str = str + ", Low Mileage Vehicle";
  }
  if (v.overall === 0) str = "Not Enough Trip Data";
  return str;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getMonthName(month, format) {
  switch (parseInt(month)) {
    case 1:
      if (format === "short") return "jan";
      else return "January";
    case 2:
      if (format === "short") return "feb";
      else return "February";
    case 3:
      if (format === "short") return "mar";
      else return "March";
    case 4:
      if (format === "short") return "apr";
      else return "April";
    case 5:
      if (format === "short") return "may";
      else return "May";
    case 6:
      if (format === "short") return "jun";
      else return "June";
    case 7:
      if (format === "short") return "jul";
      else return "July";
    case 8:
      if (format === "short") return "aug";
      else return "August";
    case 9:
      if (format === "short") return "sep";
      else return "September";
    case 10:
      if (format === "short") return "oct";
      else return "October";
    case 11:
      if (format === "short") return "nov";
      else return "November";
    case 12:
      if (format === "short") return "dec";
      else return "December";
    default:
      return null;
  }
}

export function getMonthlyBounds(start, stop) {
  // START DATE
  if (!start || !stop) return;
  let startYear = parseInt(start.slice(0, 4));
  let startMonth = parseInt(start.slice(5, 7));
  let startDay = parseInt(start.slice(8, 10));
  if (startDay < 10) startDay = "0" + startDay;
  // END DATE
  let endYear = stop.slice(0, 4);
  let endMonth = parseInt(stop.slice(5, 7));
  let endDay = parseInt(stop.slice(8, 10));

  let yearChange = (endYear - startYear) * 12;
  let duration = endMonth + yearChange - startMonth;
  let currentYear = startYear;
  let monthArray = [];
  for (var i = 0; i < duration + 1; i++) {
    let currentDay = "01";
    let currentMonth = startMonth + i;
    if (currentMonth > 12 && currentMonth < 25) {
      currentYear = startYear + 1;
      currentMonth = currentMonth - 12;
    }
    // This needs to be refactored to be more dynamic
    // 2 Years past start
    if (currentMonth >= 25 && currentMonth < 37) {
      currentYear = startYear + 2;
      currentMonth = currentMonth - 24;
    }
    // 3 years past start
    if (currentMonth >= 37 && currentMonth < 41) {
      currentYear = startYear + 3;
      currentMonth = currentMonth - 36;
    }
    if (currentMonth < 10) currentMonth = "0" + currentMonth;
    let daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    let currentEndDay = daysInMonth;
    if (i === 0) {
      currentDay = startDay;
      if (currentDay < 10) currentDay = "0" + currentDay;
    }
    if (i === duration) currentEndDay = endDay;
    let startTS = `${currentYear}-${currentMonth}-${currentDay}`;
    let endTS = `${currentYear}-${currentMonth}-${currentEndDay}`;
    let obj = {
      label: `${currentYear} ${getMonthName(currentMonth)}`,
      month: `${getMonthName(currentMonth, "short")}-${currentYear}`,
      startTS: startTS,
      endTS: endTS,
    };
    monthArray.push(obj);
  }
  return monthArray;
}

export function formatTelematicsProvider(TP) {
  switch (TP) {
    case "geotab":
      return "Geotab";
    case "nf":
      return "Network Fleet";
    case "Calamp" || "calamp":
      return "CalAmp";
    default:
      return TP;
  }
}

export function getLongMonth(month) {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "";
  }
}

export function standardizePrecision(numb) {
  // if(typeof numb !== typeof Number) return numb;
  return Number(numb.toPrecision(2));
}
