import React from 'react';
import Graphs from './Graphs';
import DataSelector from './DataSelector';
import selectGraphData from './GraphSelector';
import Loading from './loading';
import { convertKMtoMiles, numberWithCommas } from './DataFormatter';

export default function LandingPage(props) {
  const [selectedData, setSelectedData] = React.useState('Miles');
  const selectedVcls = props.vehicleUtil.filter(v => {return (v.selected === true && v.lastSeen !== null)});
  const { selectedLocIds } = props;
  const generateLeast5 = () => {
    var top5 = selectedVcls
      .sort((a, b) => a.avgUtilization - b.avgUtilization)
      .slice(0, 5);
    if (top5 !== null && top5 !== undefined) {
      return top5.map((v, i) => {
        let ds = v.lastSeen.split('T');
        return (
          <div key={i}>
            <div className="sftd-top5-item">
              <p className="sftd-top5-perc">
                {Math.round(v.avgUtilization * 100)}%
              </p>
              <p className="sftd-top5-ul">
                {v.year === null
                  ? ' Vin Decoding Error '
                  : v.year + ' ' + v.make + ' ' + v.model}
              </p>
              <p className="sftd-top5-ul-id">Asset ID {v.assetID}  | Last Seen {ds[0]} UTC</p>
              <p className="sftd-top5-address">{v.address}</p>
            </div>
            {i !== 4 ? <hr className="sftd-utl-line" /> : null}
          </div>
        );
      });
    }
  };

  const setData = i => {
    setSelectedData(i);
  };
  var filteredTotals = props.filteredTotals;
  var filteredVclTotals = filteredGraphData(
    props.filteredVclTotals,
    selectedData
  );

  return (
    <div className="sftd-landing-page">
      <DataSelector selectedData={selectedData} setData={setData} />
      <div className="sftd-landing-wrpr">
        <div className="sftd-vcl-utilization-wrpr">
          <p className="sftd-vcl-utilization-title">
            Vehicle Averages{' '}
            <span className="sftd-sub-title">
              ({selectedLocIds.length}{' '}
              {selectedLocIds.length === 1 ? 'Location' : 'Locations'})
            </span>
          </p>

          {selectedVcls.length > 0 ? (
            <>
              <div
                className="sftd-vcl-wrpr"
                id={selectedData === 'Miles' ? 'selected' : 'unselected'}
              >
                <p className="sftd-vcl-value">
                  {numberWithCommas(
                    Math.round(convertKMtoMiles(filteredTotals.avg_kms))
                  )}
                </p>
                <p className="sftd-vcl-text">Miles </p>
                <hr className="sftd-utl-line" />
              </div>
              <div
                className="sftd-vcl-wrpr"
                id={selectedData === 'Days' ? 'selected' : 'unselected'}
              >
                {' '}
                <p className="sftd-vcl-value">
                  {' '}
                  {numberWithCommas(Math.round(filteredTotals.avg_days))}
                </p>
                <p className="sftd-vcl-text">Days </p>
                <hr className="sftd-utl-line" />
              </div>
              <div
                className="sftd-vcl-wrpr"
                id={selectedData === 'Trips' ? 'selected' : 'unselected'}
              >
                {' '}
                <p className="sftd-vcl-value">
                  {numberWithCommas(Math.round(filteredTotals.avg_trips))}
                </p>
                <p className="sftd-vcl-text">Trips </p>
                <hr className="sftd-utl-line" />
              </div>
              <div
                className="sftd-vcl-wrpr"
                id={selectedData === 'Duty Cycles' ? 'selected' : 'unselected'}
              >
                <p className="sftd-vcl-value">
                  {numberWithCommas(Math.round(filteredTotals.avg_dcs))}
                </p>
                <p className="sftd-vcl-text">Duty Cycles </p>
                <hr className="sftd-utl-line" />
              </div>
              <div className="sftd-vcl-wrpr">
                <p className="sftd-vcl-value">
                  {numberWithCommas(Math.round(filteredTotals.avg_hrs))}
                </p>
                <p className="sftd-vcl-text">Hours of Operation </p>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
        <div className="sftd-vcl-lst-utilization-wrpr">
          <p className="sftd-vcl-utilization-title">
            Least Utilized Vehicles{' '}
            <span className="lst-util-vcls">
              ({selectedVcls.length > 5 ? 5 : selectedVcls.length}/
              {selectedVcls.length} vehicles)
            </span>
          </p>

          {selectedVcls.length > 0 ? (
            generateLeast5()
          ) : (
            <Loading />
          )}
        </div>
        <div className="sftd-landing-vsl-wrpr">
          <Graphs
            graphData={selectGraphData(
              selectedData,
              'landing',
              filteredVclTotals
            )}
          />
        </div>
      </div>
    </div>
  );
}

function filteredGraphData(data, selected) {
  if (!data) return;
  if (data.length < 1) return;
  if (selected === 'Miles') {
    var buckets = {
      '0-2': 0,
      '2-4': 0,
      '4-6': 0,
      '6-8': 0,
      '8-10': 0,
      '10+': 0
    };
    data.map(d => {
      var miles = convertKMtoMiles(d.kms);
      if (miles === null || miles === undefined) return null;
      if (miles < 2000) {
        buckets['0-2']++;
      } else if (miles < 4000) {
        buckets['2-4']++;
      } else if (miles < 6000) {
        buckets['4-6']++;
      } else if (miles < 8000) {
        buckets['6-8']++;
      } else if (miles < 10000) {
        buckets['8-10']++;
      } else {
        buckets['10+']++;
      }
      return d;
    });
    return buckets;
  }
  if (selected === 'Trips') {
    buckets = {
      '0-50': 0,
      '50-100': 0,
      '100-150': 0,
      '150-200': 0,
      '200-250': 0,
      '250-300': 0,
      '300-350': 0,
      '350+': 0
    };
    data.map(d => {
      var trips = d.trip_ct;
      if (trips === null || trips === undefined) return null;
      if (trips < 50) {
        buckets['0-50']++;
      } else if (trips < 100) {
        buckets['50-100']++;
      } else if (trips < 150) {
        buckets['100-150']++;
      } else if (trips < 200) {
        buckets['150-200']++;
      } else if (trips < 250) {
        buckets['200-250']++;
      } else if (trips < 300) {
        buckets['250-300']++;
      } else if (trips < 350) {
        buckets['300-350']++;
      } else {
        buckets['350+']++;
      }
      return d;
    });
    return buckets;
  }
  if (selected === 'Duty Cycles') {
    buckets = {
      '0-20': 0,
      '20-40': 0,
      '40-60': 0,
      '60-80': 0,
      '80-100': 0,
      '100-120': 0,
      '120-140': 0,
      '140-160': 0,
      '160-180': 0,
      '180-200': 0
    };
    data.map(d => {
      var dcs = d.dc_ct;
      if (dcs === null || dcs === undefined) return null;
      if (dcs < 20) {
        buckets['0-20']++;
      } else if (dcs < 40) {
        buckets['20-40']++;
      } else if (dcs < 60) {
        buckets['40-60']++;
      } else if (dcs < 80) {
        buckets['60-80']++;
      } else if (dcs < 100) {
        buckets['80-100']++;
      } else if (dcs < 120) {
        buckets['100-120']++;
      } else if (dcs < 140) {
        buckets['120-140']++;
      } else if (dcs < 160) {
        buckets['140-160']++;
      } else if (dcs < 180) {
        buckets['160-180']++;
      } else {
        buckets['180-200']++;
      }
      return d;
    });
    return buckets;
  }
  if (selected === 'Days') {
    buckets = {
      '0-10': 0,
      '10-20': 0,
      '20-30': 0,
      '30-40': 0,
      '40-50': 0,
      '50-60': 0,
      '60-70': 0,
      '70-80': 0,
      '80-90': 0,
      '90-100': 0,
      '100+': 0
    };
    data.map(d => {
      var days = d.days;
      if (days === null || days === undefined) return null;
      if (days < 10) {
        buckets['0-10']++;
      } else if (days < 20) {
        buckets['10-20']++;
      } else if (days < 30) {
        buckets['20-30']++;
      } else if (days < 40) {
        buckets['30-40']++;
      } else if (days < 50) {
        buckets['40-50']++;
      } else if (days < 60) {
        buckets['50-60']++;
      } else if (days < 70) {
        buckets['60-70']++;
      } else if (days < 80) {
        buckets['70-80']++;
      } else if (days < 90) {
        buckets['80-90']++;
      } else if (days < 100) {
        buckets['90-100']++;
      } else {
        buckets['100+']++;
      }
      return d;
    });
    return buckets;
  }
  return;
}
