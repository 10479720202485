import React, { useState, useEffect } from "react";

const UPDATE_MILLISECONDS = 1000;
const MAX_FAILURES_TO_CHANGE_ALLOWED = 10;

export default function ProcessingPage(props) {
  const [countdown, setCountdown] = useState(null);
  const [failureToChangeCount, setFailureToChangeCount] = useState(0);
  const [showApiError, setShowApiError] = useState(false);
  const { dbName, apiError, aggregateProcessing, apiURL, setProcessingCountdownFinished, bearerToken} = props;
  let { dbDisplayName } = props;
  if (!dbDisplayName && dbName) {
    dbDisplayName = dbName;
    dbDisplayName = dbDisplayName.charAt(0).toUpperCase() + dbDisplayName.slice(1);
  }

  useEffect(() => {
    if (showApiError || aggregateProcessing) return;

    const interval = setInterval(() => {
      fetch(`${apiURL}analyticsProcessingCountdown?dbName=${dbName}`, {
        headers: { Authorization: `Bearer ${bearerToken}` },
      })
        .then(response => response.json())
        .then(data => {
          const obj = data.data[0];
          const c = parseInt(obj.count);
          setCountdown(c);

          // Update failure count
          if (c === 0) {
            setProcessingCountdownFinished(true)
            clearInterval(interval);
          }
          else if (c === countdown) {
            setFailureToChangeCount(prevCount => prevCount + 1);
          } else {
            setFailureToChangeCount(0); // Reset failure count
          }

          // Show error if necessary
          if (failureToChangeCount >= MAX_FAILURES_TO_CHANGE_ALLOWED) {
            console.error('Count of vehicles remaining to process did not change in the last 10 seconds.');
            setShowApiError(true);
            clearInterval(interval);
          }
        })
        .catch(error => {
          console.error(`Error in analyticsProcessingCountdown call: ${error}`);
          setShowApiError(true);
          clearInterval(interval);
        });
    }, UPDATE_MILLISECONDS);

    return () => clearInterval(interval);

  }, [showApiError, aggregateProcessing, dbName, apiURL, bearerToken, countdown, failureToChangeCount, setProcessingCountdownFinished]);

  useEffect(() => {
    setShowApiError(apiError);
  }, [apiError]);


  if (aggregateProcessing) return AggregateProcessingView(dbDisplayName);
  else if (showApiError) return ApiErrorView(dbDisplayName);
  else return CountdownView(dbDisplayName, countdown)
}

const ApiErrorView = (dbName) => {
  return (
    <div className="swt-ionev-table-wrapper">
      <div className="swt-ionev-th-instructions">
        <div className="ionev-h2">
          There appears to be an issue loading ionEV for {dbName}.
        </div>
        <br/>
        Please contact us to resolve this issue.
        <br/>
        Email: <a href='mailto:info@sawatchlabs.com' target="about:blank" className="ionev-link">info@sawatchlabs.com</a>
        <br/>
        Phone: <a href='tel:3035782465' className="ionev-link">(303) 578-2465</a>
      </div>
    </div>
  );
}

const AggregateProcessingView = (dbName) => {
  return (
    <div className="swt-ionev-table-wrapper">
      <div className="swt-ionev-th-instructions">
        <div className="ionev-h2">
          Data is currently being processed for database {dbName}.
        </div>
        <br/>
        Please check back later.
      </div>
    </div>
  );
}

const CountdownView = (dbName, countdown) => {
  return (
    <div className="swt-ionev-table-wrapper">
      <div className="swt-ionev-th-instructions">
        <div className="ionev-h2">
          A user has triggered a re-run of results for database {dbName}.
        </div>
        <br/>
        This process will complete in approximately: {countdown ? countdown : "–"} seconds.
      </div>
    </div>
  );
}