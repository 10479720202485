import React from 'react';
import MapController from './MapController';
import TableController from './TableController';
import Toggle from './Toggle';
export default function MapPage(props) {
  const [showMapModal, setShowMapModal] = React.useState(true);
  const [showTableModal, setShowTableModal] = React.useState(true);
  const [showSelected, setShowSelected] = React.useState(false);
  const [activeMapCenter, setMapCenter] = React.useState([]);
  let selectedLocations = props.locations.filter(l => l.selected === true);
  let { vehicles } = props;

  let locations = !showSelected
    ? props.locations
    : props.locations.filter(l => l.selected === true);

  function handleLocTableToggleClick(input) {
    let locs = props.locations;
    if (!locs.some(e => e.selected === false)) {
      locs.map(loc => (loc.selected = false));
      props.handleSettingsChange([], 'selectedLocIds');
      props.handleSettingsChange(locs, 'allLocations');
      props.settingChangeTracker.deselectAll = true;
      props.settingChangeTracker.allLocations = false;
      props.settingChangeTracker.selectedLocIds = false;
    } else {
      let arr = [];
      if (locs.length < 25)
        locs.forEach(loc => {
          loc.selected = true;
          arr.push(loc.parking_location);
        });
      props.settingChangeTracker.deselectAll = false;
      props.handleSettingsChange(arr, 'selectedLocIds');
      props.handleSettingsChange(locs, 'allLocations');
    }
  }
  function checkVclCount() {
    // checks to make sure vehicle count is correct
    props.locations.forEach(l => {
      let vclCount = 0;
      let deselectedVcls = 0;
      vehicles.forEach(v => {
        if (l.parking_location === v.homebase) {
          vclCount++;
          if (l.selected && !v.selected) {
            deselectedVcls++;
          }
        }
      });
      if (l.count > vclCount && vclCount !== 0) {
        l.count = vclCount;
      }
      l['selectedLocCount'] = l.selected ? l.count - deselectedVcls : l.count;
      if (l.selectedLocCount < 0) l.selectedLocCount = 0;
      l.count = !l.selected && vclCount > 0 ? vclCount : l.count;
    });
  }
  checkVclCount();

  function setMapActiveCenter(row) {
    let coordArray = [row.obj.latitude, row.obj.longitude];
    setMapCenter(coordArray);
  }

  function handleLocationChange(locationID) {
    var updatedLocId;
    var locations = props.locations.map(loc => {
      if (loc.parking_location === locationID) {
        loc.selected = !loc.selected;
        updatedLocId = loc.parking_location;
      }
      return loc;
    });
    var selectedIds = props.selectedLocIds;
    var idx = selectedIds.indexOf(updatedLocId);
    if (idx < 0) selectedIds.push(updatedLocId);
    else selectedIds.splice(idx, 1);
    props.handleSettingsChange(selectedIds, 'selectedLocIds');
    props.handleSettingsChange(locations, 'allLocations');
  }
  return (
    <div
      className={
        showMapModal && showTableModal ? 'sftd-Map-Page-open' : 'sftd-Map-Page'
      }
    >
      <h1 className="sftd-page-title">Locations</h1>

      <div className="map-toggles">
        <Toggle
          className="map-toggle-selected"
          showSelected={showSelected}
          handleToggleChange={() => setShowSelected(!showSelected)}
          unselectedText={`Show All (${props.locations.length}) Locations`}
          selectedText={
            selectedLocations.length === 1
              ? 'Show The (1) Location'
              : `Show Filtered (${selectedLocations.length}) Locations`
          }
        />
      </div>
      <hr className="sftd-ttl-line" />
      <h3 className="sftd-updateMessage">
        {props.settingChangeTracker.allLocations === true
          ? "Location selections have been updated. Click the 'Update Results' button to view your updated results."
          : null}
      </h3>
      {showMapModal ? (
        <div className="sftd-map-container">
          <div
            className="sftd-expand-icon"
            onClick={() => setShowMapModal(false)}
          />
          <h1 className="sftd-MapCont-Title">Home Base Map</h1>
          <MapController
            showSelected={showSelected}
            locations={locations}
            handleLocationChange={handleLocationChange}
            activeMapCenter={activeMapCenter}
          />
        </div>
      ) : (
        <div className="sftd-map-container-clsd">
          <div
            className="sftd-expand-icon"
            onClick={() => setShowMapModal(true)}
          />
          <h3 className="sftd-MapCont-Title">Home Base Map</h3>
        </div>
      )}
      {showTableModal ? (
        <div className="sftd-map-tbl-container">
          <div
            className="sftd-expand-icon"
            onClick={() => setShowTableModal(false)}
          />
          <div className="sftd-map-table-wrapper">
            <h1 className="sftd-mapTbl-cont-ttl">Locations Table</h1>
            {showSelected && locations.length === 0 ? (
              <p className="sftd-noData-Msg">
                There are currently no locations selected. Please select a
                location from the filters above.
              </p>
            ) : (
              <div className="sftd-mapPg-table-wrapper">
                <div className="sftd-locTable-blinder" />
                <TableController
                  locations={
                    !showSelected
                      ? locations
                      : locations.filter(l => l.selected === true)
                  }
                  consumer={'location'}
                  handleLocationChange={handleLocationChange}
                  setMapCenter={setMapActiveCenter}
                  handleToggleClick={handleLocTableToggleClick}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="sftd-map-tbl-container-clsd">
          <div
            className="sftd-expand-icon"
            onClick={() => setShowTableModal(true)}
          />
          <h3 className="sftd-MapCont-Title">Locations Table</h3>
        </div>
      )}
    </div>
  );
}
