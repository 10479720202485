import React from "react";
import { Line, Bar } from "react-chartjs-2";
import { formatMonthlyGraphLabels } from "./UtilityFunctions";

// Graph Style Values
const GRAPH_HEIGHT = 350;
const GRAPH_WIDTH = 830;
const GRAPH_BOTTOM_PADDING = 15;
const BORDER_WIDTH = 1;
const POINT_RADIUS = 3;
const POINT_HIT_RADIUS = 5;
const BORDER_DASH = [10];
const GRAPH_FONT_SIZE = '20px';

// Graph Colors 
const AVOIDED_EMISSIONS_FILL_COLOR = "rgba(0, 0, 0, 0.12)";
const TOTAL_EMISSIONS_FILL_COLOR = 'rgb(53 110 119 / 80%)'
const GRAPH_BORDER_COLOR = "rgba(0, 0, 0, 1)";

//chart js doesn't support dashed bar chart borders, so this is a "plugin" workaround.
const dashPlugin = function(chart, args) {
    args.meta.data.forEach(function(element) {
        if(element.borderWidth > -1)return null;
        const borderWidth = BORDER_WIDTH/2;
        const half = element.width / 2;
        const left = element.x - half;
        const right = element.x + half;
        const top = element.y;
        const width = right - left;
        const height = chart.chartArea.bottom - top + (borderWidth / 2) - 1;
        if(height <= 0)return null;
        const ctx = chart.ctx;
        ctx.lineWidth = borderWidth;
        ctx.strokeStyle = element.borderColor;
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(left, top);
        ctx.lineTo(left, top + height);
        ctx.moveTo(left, top);
        ctx.lineTo(left + width, top);
        ctx.moveTo(left + width, top);
        ctx.lineTo(left + width, top + height);
        ctx.stroke();
        ctx.save();
     });
}

const options = (props) => {
    const opts = {
         animation: {
            duration: 0,
        },
        responsive: false,
        plugins: {
        title: {
            display: true,
            text: props.title,
            font: {
                size: GRAPH_FONT_SIZE
            },
            padding: {
                bottom: GRAPH_BOTTOM_PADDING
            }
        },
    },
        scales: {
            y: {
                title: {
                    display: true,
                    text: props.units
                },
                stacked: true
            },
            x: {
                title:{
                    display: true,
                    text: props.timePeriods
                },
                stacked: true
            }
        },
    };
    return opts;
}

export default function HomeLineGraph(props) {
    const {emissionData, showEmissionsByFuelType, displayInLbs, showBar, showMonths } = props;
    let units = displayInLbs ? 'GHG (Lbs)' : 'GHG (Tons)';
    let timePeriods = showMonths ? 'Month' : 'Week Starting';
    const useMonths = showMonths ? true: false;
    const dateStringOptions = {year: '2-digit', month: 'numeric', day: 'numeric'}

    const GAS_COLOR = 'rgba(47,185,233, .7)'
    const DIESEL_COLOR = 'rgba(46,75,124, .8)'
    const BEV_COLOR = 'rgba(81,188,164, .8)'
    const PHEV_COLOR = 'rgba(53,110,119, .8)'

    let labels = emissionData.weeklyStartDates.map((d) => {return new Date(d).toLocaleDateString('en-US', dateStringOptions)});
    let totalEmissionData = emissionData.weeklyTotalEmissions;
    let emissionReductionData = emissionData.totalWeeklyEmissionReductions;
    let phevEmissions = emissionData.weeklyTotalPhevGHG
    let phevGHGReductions = emissionData.weeklyTotalPhevGHGReduction
    let gasolineEmissions = emissionData.weeklyTotalGasolineGHG
    let bevEmissions = emissionData.weeklyTotalBevGHG
    let bevGHGReductions = emissionData.weeklyTotalBevGHGReduction
    let dieselEmissions = emissionData.weeklyTotalDieselGHG
    if(showBar){
        totalEmissionData = emissionData.weeklyGHG;
        emissionReductionData = emissionData.weeklyGHGReduction;
    }

    if(useMonths){
        labels = emissionData.monthlyStartDates.map((d) => {return formatMonthlyGraphLabels(d)});
        totalEmissionData = emissionData.monthlyTotalEmissions;
        emissionReductionData = emissionData.totalMonthlyEmissionReductions;
        phevEmissions = emissionData.monthlyTotalPhevGHG
        phevGHGReductions = emissionData.monthlyTotalPhevGHGReduction
        gasolineEmissions = emissionData.monthlyTotalGasolineGHG
        bevEmissions = emissionData.monthlyTotalBevGHG
        bevGHGReductions = emissionData.monthlyTotalBevGHGReduction
        dieselEmissions = emissionData.monthlyTotalDieselGHG
        if(showBar){
            totalEmissionData = emissionData.monthlyGHG;
            emissionReductionData = emissionData.monthlyGHGReduction;
        }
    }

    // Graph Strings(include in function body as some take a units arg)
    const GRAPH_TITLE = 'Summary of Fleet GHG Emissions (Cumulative)';
    const TOTAL_EMISSIONS_LABEL = `Total Emissions (${units})`;
    const AVOIDED_EMISSIONS_LABEL = `Avoided Emissions (${units})`;
    const PHEV_EMISSIONS_LABEL = `PHEV (${units})`;
    const PHEV_REDUCTIONS_LABEL = `PHEV Avoided Emissions (${units})`
    const BEV_EMISSIONS_LABEL = `BEV (${units})`;
    const BEV_REDUCTIONS_LABEL = `BEV Avoided Emissions (${units})`
    const GASOLINE_EMISSIONS_LABEL = `Gasoline (${units})`;
    const DIESEL_EMISSIONS_LABEL = `Diesel (${units})`;

    const graphData = () => {
        if(!showEmissionsByFuelType) {
        return ({
            labels: labels,
            datasets: [
                {
                    label: TOTAL_EMISSIONS_LABEL,
                    data: totalEmissionData,
                    borderColor: GRAPH_BORDER_COLOR,
                    backgroundColor: TOTAL_EMISSIONS_FILL_COLOR,
                    borderWidth: BORDER_WIDTH,
                    pointRadius: POINT_RADIUS,
                    pointHitRadius: POINT_HIT_RADIUS,
                    fill: true
                },
                {
                    label: AVOIDED_EMISSIONS_LABEL,
                    data: emissionReductionData,
                    backgroundColor: AVOIDED_EMISSIONS_FILL_COLOR,
                    borderColor: GRAPH_BORDER_COLOR,
                    borderWidth: -1,
                    pointRadius: 0,
                    pointHitRadius: POINT_HIT_RADIUS,
                    borderDash: BORDER_DASH,
                    fill: '-1'
                }
            ]
        })
    }
        if(showEmissionsByFuelType) {
            return ({
                labels: labels,
                datasets: [
                    {
                        label: GASOLINE_EMISSIONS_LABEL,
                        data: gasolineEmissions,
                        backgroundColor: GAS_COLOR,
                        borderColor: GAS_COLOR,
                        borderWidth: BORDER_WIDTH,
                        pointRadius: POINT_RADIUS,
                        pointHitRadius: POINT_HIT_RADIUS,
                        fill: true
                    },
                    {
                        label: DIESEL_EMISSIONS_LABEL,
                        data: dieselEmissions,
                        backgroundColor: DIESEL_COLOR,
                        borderColor: DIESEL_COLOR,
                        borderWidth: BORDER_WIDTH,
                        pointRadius: POINT_RADIUS,
                        pointHitRadius: POINT_HIT_RADIUS,
                        fill: true
                    },
                    {
                        label: PHEV_EMISSIONS_LABEL,
                        data: phevEmissions,
                        backgroundColor: PHEV_COLOR,
                        borderColor: PHEV_COLOR,
                        borderWidth: BORDER_WIDTH,
                        pointRadius: POINT_RADIUS,
                        pointHitRadius: POINT_HIT_RADIUS,
                        fill: '-1'
                    },
                    {
                        label: PHEV_REDUCTIONS_LABEL,
                        data: phevGHGReductions,
                        backgroundColor: AVOIDED_EMISSIONS_FILL_COLOR,
                        borderColor: GRAPH_BORDER_COLOR,
                        borderWidth: -1,
                        pointRadius: 0,
                        pointHitRadius: POINT_HIT_RADIUS,
                        borderDash: BORDER_DASH,
                        fill: '-1'
                    },
                    {
                        label: BEV_EMISSIONS_LABEL,
                        data: bevEmissions,
                        backgroundColor: BEV_COLOR,
                        borderColor: BEV_COLOR,
                        borderWidth: BORDER_WIDTH,
                        pointRadius: POINT_RADIUS,
                        pointHitRadius: POINT_HIT_RADIUS,
                        fill: '-1'
                    },
                    {
                        label: BEV_REDUCTIONS_LABEL,
                        data: bevGHGReductions,
                        backgroundColor: AVOIDED_EMISSIONS_FILL_COLOR,
                        borderColor: GRAPH_BORDER_COLOR,
                        borderWidth: -1,
                        pointRadius: 0,
                        pointHitRadius: POINT_HIT_RADIUS,
                        borderDash: BORDER_DASH,
                        fill: '-1'
                    },
                ]
            })
        }
    }

    if (!emissionData || emissionData.length < 1) {
        return (<div></div>)
    }

    return (
        <>
            {!showBar && <Line
                ref={props.graphRef ? props.graphRef : null}
                options={options({...props, title: GRAPH_TITLE, units: units, timePeriods: timePeriods})}
                data={graphData()}
                width={GRAPH_WIDTH}
                height={GRAPH_HEIGHT}
            />
            }
            {showBar &&
                <Bar
                ref={props.graphRef ? props.graphRef : null}
                options={options({...props, title: GRAPH_TITLE, units: units, timePeriods: timePeriods})}
                data={graphData()}
                plugins={[{afterDatasetDraw: dashPlugin}]}
                width={GRAPH_WIDTH}
                height={GRAPH_HEIGHT}
                />

            }
        </>
    )
}