import styled from "styled-components/macro"
import * as colors from "./ColorScheme";

export const TableEmptyCaption = styled.caption`
caption-side: bottom;
background: ${colors.ezio_light_grey};
min-height: 3rem;
`

export const TableEmptyText = styled.span`
font-family: "Arimo", "Roboto";
display: flex;
align-items: center;
justify-content: center;
height: 3rem;
text-align: center;
`

export const SearchBar = styled.div`
.swt-ezio-search-input {
    margin-top: 3px;
    width: 8rem;
    height: 19px;
    background: #FFFFFF;
    border-radius: 5px;
    border:none;
}
`

export const Pagination = styled.div`
font-family: "Arimo";
margin-top: 10px;
display: flex;
align-items: center;
justify-content: center;
color: ${colors.ezio_dark_grey};
font-size: 12px;
letter-spacing: .1px;
*{
  padding: 0 5px;
}
input{
  color: var(--ezev-battleship-grey);
  width: 50px !important; 
  font-size: 10px;
  
}
`

export const TableContainer = styled.div`
  width: 100%;
`