import React from 'react';
import OutsideAlerter from './OutsideClickNotifier';
export default function GroupSelector(props) {
  const [showModal, setShowModal] = React.useState(false);

  function handleChange(input) {
    if (input === undefined) {
      //
    }
    if (input === 'all') {
      this.handleAllSelector();
    }
    if (input === 'clear') {
      this.handleClear();
    }
    // let { groups } = this.props;
  }

  function createGroups() {
    if (props.groups === undefined || props.groups.length < 1) return null;
    props.groups.map(g => {
      return (
        <span>
          <input
            className="sftd-radio-custom"
            id="group"
            type="radio"
            checked={true}
            value={g.name}
            onClick={() => handleChange(g.name)}
          />
          <label id="label">{g.name}</label>
        </span>
      );
    });
  }

  function all() {
    if (props.groups === undefined) return false;
    const classes = ['sedan', 'suv', 'cargovan', 'pickup', 'minivan'];
    let vclClasses = props.groups;
    var cls = classes.map(c => {
      if (vclClasses[c] === false) return false;
      else return true;
    });
    if (cls.includes(false)) return false;
    else return true;
  }

  return (
    <OutsideAlerter handleClick={() => setShowModal(false)}>
      <div
        className="sftd-group-selector"
        id={showModal ? 'clicked' : 'unclicked'}
        // onClick={() => setShowModal(!showModal)}
      >
        <h3 className="sftd-group-title">Groups</h3>
        {showModal ? (
          <div className="sftd-group-modal">
            <form>
              {createGroups()}
              <input
                className="sftd-radio-custom"
                id="group"
                type="radio"
                checked={all()}
                value={'all'}
                onClick={() => handleChange('all')}
              />
              <label id="label">All</label>
              <br />
              {/* <input
                  className="sftd-radio-custom-clear"
                  id="group"
                  type="radio"
                  checked={this.all()}
                  value={'all'}
                  onClick={() => this.handleChange('clear')}
                />
                <label id="label">Clear</label> */}
            </form>
          </div>
        ) : null}
      </div>
    </OutsideAlerter>
  );
}
