
export function consistentElectrificationSort(vehicles){
    //Sort by overall score,  then economics score, then vin
    //(then energy score??) -> if we sort by energy all of the phevs fall to the bottom
    vehicles.sort(
        function(a, b) {          
           if (a.overall === b.overall) {
              // econ is only important when overall are the same
              return b.economics - a.economics;
           }
           if (a.overall === b.overall && a.economics === b.economics){
              // in the event of a total tie use vin
              return a.vin.localeCompare(b.vin)
           }
           return a.overall < b.overall ? 1 : -1;
        });
}