import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components/macro"
import Select from "react-select"


const DropdownContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    background-color: white;
`;

const ControlsWrapper = styled.div`
    border: 1px solid rgb(187, 187, 187);
    border-radius: .25rem;
    border-width: 2px;
    border-style: solid;
    margin: 10px;
    padding: 5px;
    width: 375px;
    height: 280px;
    background-color: white;
`;

const ControlsLabel = styled.div`
    font-weight: 500;
    white-space: nowrap;
    margin-left: 5px;
`;

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 5px;
    height: 28px;
    width: 100%;
    z-index: 10;
`;

const DatePickerLabel = styled.div `{
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 10px;
    font-weight: 500;
    width: 105px;
    white-space: nowrap;
  }`;

const styles = {
  container: base => ({
    ...base,
    flex: 1
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#5797B6",
    fontSize: "85%",
    overflow: "hidden",
    padding: "3px",
    paddingLeft: "6px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    borderRadius: "4px"
  })
};


export default function Controls({
  beginDate, endDate, setBeginDate, setEndDate,
  group, groups, handleGroupChange,
  vehicleClasses, selectedVehicleClasses, updateSelectedClasses,
  }) {
  let selectedGroupObject = groups.filter((g) => {return g.id === group});
  selectedGroupObject = selectedGroupObject[0];
  return (
    <ControlsWrapper>
      <DatePickerWrapper>
        <DatePickerLabel>Date Range:</DatePickerLabel>

        <DatePicker
          className="swt-fueln-datepicker"
          selected={beginDate}
          onChange={(e) => { setBeginDate(e) }}
          selectsStart
          startDate={beginDate}
          endDate={endDate}
        />

        <span>&nbsp;</span>

        <DatePicker
          className="swt-fueln-datepicker"
          selected={endDate}
          onChange={(date) => { setEndDate(date) }}
          selectsEnd
          endDate={endDate}
          minDate={beginDate}
        />

      </DatePickerWrapper>
      <DropdownContainer>
        <ControlsLabel>Group:&nbsp;</ControlsLabel>
        <Select
          styles={styles}
          autosize={true}
          options={groups.map((item) => { return ({ value: item, label: item.name }) })}
          isSearchable={false}
          isDisabled = {false}
          value={{ 
            value: (group === "GroupCompanyId") ? "All Vehicles" : selectedGroupObject.name,
            label: (group === "GroupCompanyId") ? "All Vehicles" : selectedGroupObject.name 
          }}
          onChange={change => {
            handleGroupChange(change.value.id);
          }}
        />

      </DropdownContainer>
      {/* For whatever reason, these Select components seem to trigger a rerender of the component containing them whenever the field is changed. 
This means that field values can't be stored within the state of EmitControls and have to be passed up to a parent and then passed back down. */}
      <DropdownContainer>
        <ControlsLabel>Vehicle Classes:&nbsp;</ControlsLabel>
        <Select
          styles={styles}
          isMulti={true}
          options={vehicleClasses.map((item) => {
            return ({
              value: item,
              label: item
            })
          })}
          value={selectedVehicleClasses.map((item) => {
            return ({
              value: item,
              label: item
            })
          })}
          onChange={change => { updateSelectedClasses(change.map(item => item.value)) }}
        />
      </DropdownContainer>
    </ControlsWrapper>
  );
}