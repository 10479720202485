import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {Chart} from 'chart.js';
import { formatMonthlyGraphLabels } from "./UtilityFunctions";

const BREAKPOINT_DAY_COUNT = 90000; //disabling via large number but not fully deprecating MH - 2023-01-23
const DEFAULT_GRAPH_WIDTH = 400;

const options = (props) =>{
    const opts = {
        type: props.type ? props.type : 'bar',
        indexAxis: props.options?.indexAxis ? props.options.indexAxis: 'x',
        animation: {
            duration: 0,
        },
        plugins: {
            active: {
                mode: "dataset",
                intersect: true,
            },        
            legend:{
            display: false,
            position: 'bottom',
            align: 'start',
            },
            title: {
                display: props.title ? true : false,
                text: props.title ? props.title : 'BAR CHART!',
                position: 'top',
                align: 'center',
                font: {
                    size: 17,
                }
            },
        },
            scales: {
                y: {
                    // suggestedMax: props.suggestedMax ? props.suggestedMax : 10,
                    suggestedMin: 0,

                    title: {
                        display: true,
                        text: `${props.data.barLabel}`,
                    }
                },
                x:{
                    suggestedMax: 100,
                    suggestedMin: 75,
                    title: {
                        display: true,
                        text: `${props.xAxisLabel}`,
                    }
                },
            },
        layout: {
            padding: 0
        },
        maintainAspectRatio: false,
        responsive: false,
    }
    return opts;
}

export default function EmissionsBarChart(props){

    const dateStringOptions = {year: '2-digit', month: 'numeric', day: 'numeric'}
    Chart.defaults.font.family = "'Arimo','Open Sans','HelveticaNeue','Helvetica Neue','Arial','sans-serif'";
    const COLOR = props.COLOR;
    //"rgba(250, 98, 28, 1)" - orange
    //rgba(19, 46, 87, 1) - dark blue
    //rgba(29, 132, 150, 1) - lighter blue
    let labels = props.data.weeklyBarLabels.map((d) => {return new Date(d).toLocaleDateString('en-US', dateStringOptions)});
    let data = props.data.weeklyBarData;
    let xAxisLabel = 'Week Starting';
    if(props.showMonths){
        labels = props.data.monthlyBarLabels.map((d) => {return formatMonthlyGraphLabels(d)});
        data = props.data.monthlyBarData;
        xAxisLabel = 'Month';
    }
    const graphData = function(){
        return({
          labels: labels,
          height: 450,
          datasets: [
              {
                label: props.data.weeklyBarLabel,
                data: data,
                backgroundColor: props.data.barColor ? props.data.barColor : COLOR,
                barPercentage: (data.length <= 2) ? .5 : .9,
                borderColor: props.data.barColor ? props.data.barColor : COLOR,
                lineTension: 0.2,
              },
            ],
        });
    }

    const id = props.id ? props.id : 'defaultBarChartId';

    if(!props.data){
        return(<div></div>)
    }

    return(
        <>
            {props.data.weeklyBarData.length <= BREAKPOINT_DAY_COUNT && 
                <Bar
                    ref={props.graphRef ? props.graphRef : null}
                    id={id}
                    data={graphData()}
                    width={props.width ? props.width : DEFAULT_GRAPH_WIDTH}
                    height={props.dataSet?.height ? props.dataSet.height : props.height ? props.height : 350}
                    options={options({...props, type: "bar", xAxisLabel: xAxisLabel})}
                />
            }
            {props.data.weeklyBarData.length > BREAKPOINT_DAY_COUNT && 
                <Line
                    ref={props.graphRef ? props.graphRef : null}
                    id={id}
                    data={graphData()}
                    width={props.width ? props.width : 400}
                    height={props.dataSet?.height ? props.dataSet.height : props.height ? props.height : 350}
                    options={options({...props, type: "line"})}
                />
            }
        </>
    )
}