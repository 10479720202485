import React from 'react';
import { Link } from "react-router-dom";
import { Roles } from "../core-components/utility-components/UserUtils";

class NavigationLinks extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showTableDropdown: false,
    };
    this.setTableDropdown = this.setTableDropdown.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDropdownRef = this.setDropdownRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setTableDropdown() {
    this.setState({showTableDropdown: !this.state.showTableDropdown});
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setDropdownRef(node) {
    this.dropdownRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({showTableDropdown: false});
    }
  }

  render() {
    const user = this.props.user;
    return (
      <div className="ionev-navigation-links">
        <Link className={"ionev-navigation" + (this.props.activeLink === 'dash' ? " selected" : "")} to="/ionev">Summary</Link>

        <div className="ionev-navigation-div" ref={this.setWrapperRef} onClick={this.setTableDropdown} data-testid="dropdownButton-testid">
          <div className={"ionev-navigation" + (this.props.activeLink === 'vcl-metrics' ? " selected" : "")}>Vehicle Metrics</div>
          <span className="swt-ionev-navigation-arrow"></span>
        </div>

        <Link className={"ionev-navigation" + (this.props.activeLink === 'loc-metrics' ? " selected" : "")} to="/ionev/events-by-location">Location Metrics</Link>

        <Link className={"ionev-navigation" + (this.props.activeLink === 'map' ? " selected" : "")} to={{pathname: "/ionev/map", state: { mapType: "", selectedVcl: "" }}}>Map</Link>

        <Link className={"ionev-navigation" + (this.props.activeLink === 'about' ? " selected" : "")} to="/ionev/about">About</Link>

        <div className="swt-ionev-dropdown-wrapper" ref={this.setDropdownRef}>
          {this.state.showTableDropdown ? (
            <div>
              <Link className="swt-ionev-dropdown swt-ionev-table-body-2" to="/ionev/driving-activity" onClick={() => this.setTableDropdown()}>Driving Activity</Link>
              <Link className="swt-ionev-dropdown swt-ionev-table-body-2" to="/ionev/charging-events" onClick={() => this.setTableDropdown()}>Charging Events</Link>
              <Link className="swt-ionev-dropdown swt-ionev-table-body-2" to="/ionev/missed-opportunities" onClick={() => this.setTableDropdown()}>Missed Charging Opportunities</Link>
              <Link className="swt-ionev-dropdown swt-ionev-table-body-2" to="/ionev/tco-comparison" onClick={() => this.setTableDropdown()}>TCO Comparison</Link>
              {user.role >= Roles.FleetAdmin &&
                <div data-testid="reimbursementLink-testid"><Link className="swt-ionev-dropdown swt-ionev-table-body-2" to="/ionev/reimbursement" onClick={() => this.setTableDropdown()}>Reimbursement</Link></div>
              }
            </div>
          )
          : (
            null
          )
          }
        </div>
      </div>
    );
  }
}

export default NavigationLinks;
