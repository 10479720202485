import React from "react";
import Controls from "./Controls.jsx";
import WeeklyEventsTable from "./WeeklyEventsTable";
import WeeklyBarGraph from "./WeeklyBarGraph";
import * as S from "../../styles/fueln-styles/Containers";

export default function WeeklyEventsView(props: any){
    const beginDate = props.beginDate ?? new Date();
    const endDate = props.endDate ?? new Date();
    const events: any[] = props.events ?? [];
    const setBeginDate = props.setBeginDate ?? function(e:any){};
    const setEndDate = props.setEndDate ?? function(e:any){};
    const group: {} = props.group ?? {};
    const groups: any[] = props.groups ?? []; 
    const handleGroupChange = props.handleGroupChange ?? function(e:any){};
    const vehicleClasses: any[] = props.vehicleClasses ?? [];
    const selectedVehicleClasses: any[] = props.selectedVehicleClasses ?? [];
    const setSelectedVehicleClasses = props.setSelectedVehicleClasses ?? function(d:any){};

    return(
        <>
            <S.FuelnTopContainer>
                <WeeklyBarGraph 
                    beginDate={beginDate}
                    endDate={endDate}
                    events={events}
                />
                <Controls 
                    beginDate={beginDate}
                    endDate={endDate}
                    setBeginDate={function(e: any){setBeginDate(e)}}
                    setEndDate={function(e: any){setEndDate(e)}}
                    group={group}
                    groups={groups}
                    handleGroupChange={function(e: any){handleGroupChange(e)}}
                    vehicleClasses={vehicleClasses}
                    selectedVehicleClasses={selectedVehicleClasses}
                    updateSelectedClasses={function (data: any) { setSelectedVehicleClasses(data) }}
                />
            </S.FuelnTopContainer>
            <S.FuelnTableContainer>
                <WeeklyEventsTable 
                    beginDate={beginDate}
                    endDate={endDate}
                    events={events}
                />
        </S.FuelnTableContainer>
      </>
    );

}