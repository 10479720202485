import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import NavMenu from "./NavMenu";

const UPDATE_MILLISECONDS = 1000;
const MAX_TRYS_WITHOUT_CHANGE = 60; 

export default function ActiveProcessingPage(props){
  const [apiError, setAPIError] = useState(false);
  const [update, setUpdate] = useState(false); 
  const [processState, setProcessState] = useState({isStuck: false, count: null, prevCount: null, tryCount: 0})
  const {db, user, apiURL, handleProcessingFinished} = props;
  let {dbDisplayName} = props
  if(!dbDisplayName && db){
    dbDisplayName = db;
    dbDisplayName = dbDisplayName.charAt(0).toUpperCase() + dbDisplayName.slice(1);
  }


  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(true);
    }, UPDATE_MILLISECONDS);
  
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const updateProcessState = (count) => {
    let tryCount = processState.tryCount += 1;
    let isStuck = MAX_TRYS_WITHOUT_CHANGE <= processState.tryCount + 1 ? true : false;
    if(count < processState.prevCount){
        tryCount = 0;
        isStuck = false;
        setAPIError(false);
    }
    setProcessState(
        {count: count, 
         prevCount: processState.count,
         tryCount: tryCount,
         isStuck: isStuck
        });
  }

  useEffect(() => {
    if(!update)return;
    setUpdate(false);
    getProcessingState(db, apiURL, user?.token, setAPIError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[db, apiURL, user, update]);

  useEffect(() => {
    if(processState.count === 0)handleProcessingFinished();
    if(processState.isStuck)setAPIError(true);
  }, [handleProcessingFinished, processState]);

  const getProcessingState = (db, apiURL, token, setAPIError) => {
    const url = `${apiURL}getDutyCycleProcessStatus?dbName=${db}`;
    fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.json())
    .then((data) => {
      const v = data.data[0]?.dc_vehicles_in_process;
      const c = parseInt(v);
      updateProcessState(c);
    })
    .catch((err) => {
      console.error(`Error in getProcessingState Call, ${err}`)
      setAPIError(true)
    });
};

  if(apiError){
    return(
      <ApiErrorView dbDisplayName={dbDisplayName ? dbDisplayName : db} />
    )
  }

  if(processState.count === null){
    return(
      <div className="sftd-application">
          <Router><NavMenu displayName={dbDisplayName} disabled={true}/></Router>
      </div>
    )
  }

  return(
    <div className="sftd-application">
        <Router><NavMenu displayName={dbDisplayName} disabled={true}/></Router>
        <RenderCountdownView countDown={processState.count*3} dbDisplayName={dbDisplayName}/>
    </div>

  )
}



const ApiErrorView = (props) =>{
  return(
        <div className="sftd-application">
            <Router>
            <NavMenu displayName={props.dbDisplayName} disabled={true}/>
                <div className="sftd-active-processing-wrapper">
                    <h2>
                    There appears to be an issue loading Shifted for {props.dbDisplayName}. <br />
                    <br />
                    Please contact us to resolve this issue.
                    </h2>
                    <h3>Email: info@sawatchlabs.com</h3>
                    <h3>Phone: (303) 578-2465</h3>
                </div>
            </Router>
        </div>
  )
}

const RenderCountdownView = (props) => {
  return(
    <div className="sftd-application">
        <div className="sftd-active-processing-wrapper">
            <h2>Updating Duty Cycle Results for {props.dbDisplayName}.</h2>
            <h2>
              This will complete in approximately:{" "}
              {props.countDown} seconds.
            </h2>
        </div>
    </div>
  )
}