import React from 'react';
import SawatchLogo from '../../images/sawatch-labs-logo-blk.png'


export default function Header (props) {


    return (
         <div className='speedn-header-container'>
            <a
              href="https://sawatchlabs.com/"
              target="_blank"
              rel="noopener noreferrer"
              >
            <img 
              className='speedn-header-sawatch-logo'
              src={SawatchLogo}
              alt="Sawatch Labs Logo"
              />
            </a>
            <div className='speedn-main-headers'>
              <div>Speeding Report</div>
              <div className="speedn-main-header-sub">{props.subHeader}</div>
            </div> 
          </div> 
    )
}