import React from "react";

export default function DropdownSelector(props) {
  let options = [];
  let defaultValue = '';
  let disabled = false;
  if(props.options)options = props.options;
  if(props.defaultValue)defaultValue = props.defaultValue;
  if(props.disabled)disabled = true;

  const findByValue = function(name){
    const idx = options.findIndex((e) => name === e.name);
    if(idx === -1)return null;
    return options[idx];
  }

  const handleGroupChange = function(opt){
    if(opt)props.handleChange(opt.id);
  }

  if(props.searchEnabled && options.length > 0 && typeof options[0] !== 'undefined'){
    return(
        <select 
          className={props.className}
          onChange={(e) => handleGroupChange(findByValue(e.target.value))}
          placeholder={defaultValue}
        >
          {options.length === 0 ? 
          <option>No Groups Available</option> : 
            options.map((o) => {
              return (
                <option key={o.id} value={o.name}>{o.name}</option>
                )
            })
            }
          </select>
    )
  }
  return (
      <select
        className={props.className}
        disabled={disabled}
        onChange={(e) => props.handleChange(e.target.value)}
        value={defaultValue}
      >
      {options.length === 0 ? (
        <option disabled defaultValue>
          Loading...
        </option>
      ) : (
        options.map((o) => (
          <option key={o.id} data-key={o.id} value={o.id}>
            {o.name}
          </option>
        ))
      )}
      </select>
  );
}