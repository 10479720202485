import { FormatCell } from './FormatTable';
import exceljs from 'exceljs';
import { logoBase64 } from './Logo';
import { formatTimestamp } from './utils/ConformUnits';
import { DateTime } from 'luxon';
const fs = require('file-saver');

async function saveFile(workbook, filename) {
  const buf = await workbook.xlsx.writeBuffer();
  fs.saveAs(new Blob([buf]), filename);
}

function updateVehicleNoData (data) {
  data.map((v) => {
    if (v.total_days === 0) {
      v.recommendation = 'No Data'
      return v
    } else {
      return v
    }
  })
  return data
}

function adjustColumnWidth(worksheet) {
  worksheet.columns.forEach(column => {
    const lengths = column.values.map(v => v.toString().length);
    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
    column.width = maxLength + 6;
  });
}

export function DownloadExcelData(data, headerCount, filename,userSettings = {},downloadType = "") {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('sheet1', {views: [{showGridLines:false}]});
    const imgId = workbook.addImage({
        base64: logoBase64,
        extension: 'png'
    });
    worksheet.addRows(data);

    if (downloadType === "superAdminExcel") {
      let timeStampCols = [21,22];
      timeStampCols.forEach((columnId) => {
        worksheet.getColumn(columnId).eachCell({ includeEmpty: true }, (cell) => {
            cell.numFmt = generateExcelDateFormat(userSettings);
        });
      });
    }

    worksheet.getCell('A1').font = {
        bold: true,
        size: 14
    };
    worksheet.getCell('A2').font = {
        bold: true
    };
    worksheet.getCell(`A${headerCount-5}`).value = {
        text: 'sawatchlabs.com',
        hyperlink: 'http://www.sawatchlabs.com'
    };
    worksheet.getCell(`A${headerCount-5}`).font = {
        underline: true,
        color: { argb: '0000EE' }
    }
    worksheet.getRow(headerCount + 1).font = { bold: true, color: { argb: 'FFFFFF' }};
    worksheet.getRow(headerCount + 1).eachCell(function(cell, colNum) {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: '12365B'} }
    });
    adjustColumnWidth(worksheet);
    // Add image to cell B2 and preserve size
    worksheet.addImage(imgId, {
      tl: { col: 1, row: 1 }, 
      ext: { width: 241, height: 132 }, 
      editAs: 'absolute'
    });
    worksheet.eachRow(function(row, rowNum) {
      if (rowNum > headerCount) {
          row.eachCell({ includeEmpty: true }, (cell) => {
              cell.border = {
                  top: {style:'thin'},
                  left: {style:'thin'},
                  bottom: {style:'thin'},
                  right: {style:'thin'}
                  };
              cell.alignment = { horizontal: 'left' };

          });
          row.commit();
      }
    })
    saveFile(workbook, filename + '.xlsx');
}

export function FormatData(data, dbDisplayName, userSettings) {
  const date = formatTimestamp(userSettings, DateTime.local()).date; // Download date, should be user TZ sensitive
  const filename = sanitizeDownloadString(`sawatch-labs-ezEV-results-${dbDisplayName}-${date}`);

  let filteredData = [];
  let emptyArr = [];
  filteredData.push([`Sawatch Labs - ezEV`]);
  filteredData.push([`Complete EVSA Report - ${dbDisplayName}`]);
  filteredData.push([`Download Date: ${date}`]);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);

  const headerCount = filteredData.length;

  let headers = [
    'VIN',
    'Asset ID',
    'Year',
    'Make',
    'Model',
    'Recommendation',
    'Overall Score',
    `Annual Est ${userSettings.use_kms ? 'VKMT' : 'VMT'}`,
    'Economics Score',
    'TCO (Lifetime)',
    'Energy Score',
    'Midday Charging Needs',
    'Parking Score',
    'Confidence Score',
    'Reason for Failure',
    `GHG Reduction (${userSettings.weight_labels.shortPlural})`,
    'GHG Reduction (%)',
    'Special Vehicle Configuration',
    'Fuel Type'
  ];
  filteredData.push(headers);

  if (!data || data.length < 1 || data === 'misformed request') {
    filteredData.push(['No data to display']);
  }
  else {
    data.forEach(d => {
      let row = [];

      const user_defined_vin = FormatCell('user_defined_vin', d['user_defined_vin']);
      if (user_defined_vin && user_defined_vin !== '-') {
        row.push(user_defined_vin);
      }
      else {
        row.push(d['vin']);
      }
      (d['asset_id'] === '') ? row.push('-') : row.push(d['asset_id']);
      row.push(FormatCell('year', d['year']));
      row.push(FormatCell('make', d['make']));
      row.push(FormatCell('model', d['model'])); 
      row.push(d['recommendation']);
      row.push(FormatCell('overall', d['overall']));
      row.push(FormatCell('est_yearly_km', d['est_yearly_km']));
      row.push(FormatCell('economics', d['economics']));
      row.push(FormatCell('rec_lt_net_savings', d['rec_lt_net_savings'], null, userSettings));
      row.push(FormatCell('energy', d['energy']));
      row.push(FormatCell('rec_midday_charge_str', d['rec_midday_charge_str']));
      row.push(FormatCell('parking', d['parking']));
      row.push(FormatCell('confidence', d['confidence']));
      row.push(FormatCell('failure_reason', d['failure_reason']));
      row.push(FormatCell('rec_lt_ghg_reduction_lbs', d['rec_lt_ghg_reduction_lbs']));
      row.push(FormatCell('rec_lt_ghg_reduction_pct', d['rec_lt_ghg_reduction_pct']));
      row.push(d['has_upfits']);
      row.push(d['fuel_type']);
      filteredData.push(row);
    });
  }

  DownloadExcelData(filteredData, headerCount, filename);
}

export function FormatCandidatesData(bevs, phevs, ices, candidateType, dbDisplayName, userSettings) {
  const date = formatTimestamp(userSettings, DateTime.local()).date; // Download date, should be user TZ sensitive
  const filename = sanitizeDownloadString(`sawatch-labs-ezEV-${candidateType}-vehicles-table-${dbDisplayName}-${date}`);
  let filteredData = [];
  let emptyArr = [];

  filteredData.push([`Sawatch Labs - ezEV`]);
  filteredData.push([`ezEV ${candidateType} Candidates Report - ${dbDisplayName}`]);
  filteredData.push([`Download Date: ${date}`]);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);

  const headerCount = filteredData.length;

  let headers = [
    'Type',
    'Model',
    'Class',
    'Price',
    'Total Battery Capacity (kWh)',
    'Special Vehicle Configurations'
  ];
  filteredData.push(headers);

  if (bevs.length < 1 && phevs.length < 1 && ices.length < 1) {
    filteredData.push(['No data to display']);
  }
  else {
    bevs.forEach(c => {
      let row = [];
      row.push('BEV');
      row.push(c['ymm']);
      row.push(c['vehicle_class']);
      row.push(FormatCell('net_price', c['net_price'], null, userSettings));
      row.push(FormatCell('battery_capacity', c['battery_capacity'], null, userSettings));
      row.push(FormatCell('upfits', c['upfits']));
      // obj['MPG City'] = '-';
      // obj['MPG Highway'] = '-';
      filteredData.push(row);
    });
    phevs.forEach(c => {
      let row = [];
      row.push('PHEV');
      row.push(c['ymm']);
      row.push(c['vehicle_class']);
      row.push(FormatCell('net_price', c['net_price'], null, userSettings));
      row.push(FormatCell('battery_capacity', c['battery_capacity'], null, userSettings));
      row.push(FormatCell('upfits', c['upfits']));
      // obj['MPG City'] = '-';
      // obj['MPG Highway'] = '-';
      filteredData.push(row);
    });
    ices.forEach(c => {
      let row = [];
      row.push('ICE');
      row.push(c['ymm']);
      row.push(c['vehicle_class']);
      row.push(FormatCell('net_price', c['net_price'], null, userSettings));
      row.push('-');
      row.push(FormatCell('upfits', c['upfits']));
      // obj['MPG City'] = c['mpg_c'];
      // obj['MPG Highway'] = c['mpg_h'];
      filteredData.push(row);
    });
  }
  
  DownloadExcelData(filteredData, headerCount, filename);
}

function mapParkingPct(parkingData, vin, parkingLoc) {
  // Find parking entry for the current vin
  const dat = parkingData[Object.keys(parkingData).find((d) => d === vin)];
  if (dat) { // If parking data for this VIN is available
    // Get total parking freq
    const freq = dat.reduce(function (prev, p) { return prev + parseInt(p.count) }, 0);
    // Get loc object based on the top parking addr
    const loc = dat.find((e) => e.address === parkingLoc);
    if(loc) { // If top parking location for this vehicle is available
      return Math.round((loc.count / freq) * 100);
    }
    else {
      return 0; // Top parking loc not available, so set parking freq to 0
    }
  }
  return 0;
}

export function FormatAdminData(data, parkingData, dbDisplayName, userSettings) {
  const date = formatTimestamp(userSettings, DateTime.local()).date; // Download date, should be user TZ sensitive
  const filename = sanitizeDownloadString(`sawatch-labs-ezEV-full-results-${dbDisplayName}-${date}`);
  let filteredData = [];
  let emptyArr = [];
  filteredData.push([`Sawatch Labs - ezEV`]);
  filteredData.push([`Complete EVSA Report - ${dbDisplayName}`]);
  filteredData.push([`Download Date: ${date}`]);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);

  const headerCount = filteredData.length;

  let headers = [
    'VIN',
    'Asset ID',
    'Year',
    'Make',
    'Model',
    'Fuel Type',
    'Recommendation',
    'Scored On',
    `Annual Est ${userSettings.use_kms ? 'VKMT' : 'VMT'}`,
    'Overall Score',
    'Economics Score',
    'TCO (Lifetime)',
    'GHG Reduction (%)',
    `GHG Reduction (${userSettings.weight_labels.shortPlural})`,
    'Energy Score',
    'Midday Charging Needs',
    'Avg Daily kWh',
    'Avg Daily Level 1 Charging (hrs)',
    'Avg Daily Level 2 Charging (hrs)',
    'Avg Daily DCFC (hrs)',
    'Parking Score',
    'Confidence Score',
    'Reason for Failure',
    'Primary Parking Location Address',
    'Primary Parking Location Nickname',
    'Primary Parking Location %',
    'Avg Daily Dwell Time (hrs)',
    'Avg Daily Idling (hrs)',
    'Required Vehicle Configurations', 
    'Optional Vehicle Configurations',
    'Provider'
  ]
  filteredData.push(headers);
  
  if (!data || data.length < 1 || data === 'misformed request') {
    filteredData.push(['No data to display']);
  }
  else {
    data = updateVehicleNoData(data);
    data.forEach(d => {
      let row = [];
      
      const user_defined_vin = FormatCell('user_defined_vin', d['user_defined_vin']);
      if (user_defined_vin && user_defined_vin !== '-') {
        row.push(user_defined_vin);
      }
      else {
        row.push(d['vin']);
      }
      (d['asset_id'] === '') ? row.push('-') : row.push(d['asset_id']);
      row.push(FormatCell('year', d['year']));
      row.push(FormatCell('make', d['make']));
      row.push(FormatCell('model', d['model']));
      row.push(d['fuel_type']);
      row.push(d['recommendation']);
      row.push(d['scored_on']);
      row.push(FormatCell('est_yearly_km', d['est_yearly_km']));
      row.push(FormatCell('overall', d['overall']));
      row.push(FormatCell('economics', d['economics']));
      row.push(FormatCell('rec_lt_net_savings', d['rec_lt_net_savings'], null, userSettings));
      row.push(FormatCell('rec_lt_ghg_reduction_pct', d['rec_lt_ghg_reduction_pct']));
      row.push(FormatCell('rec_lt_ghg_reduction_lbs', d['rec_lt_ghg_reduction_lbs']));
      row.push(FormatCell('energy', d['energy']));
      row.push(FormatCell('rec_midday_charge_str', d['rec_midday_charge_str']));
      row.push(FormatCell('avg_daily_kwh', d['avg_daily_kwh']));
      row.push(FormatCell('rec_ev_avg_level1_hrs', d['rec_ev_avg_level1_hrs']));
      row.push(FormatCell('rec_ev_avg_level2_hrs', d['rec_ev_avg_level2_hrs']));
      row.push(FormatCell('rec_ev_avg_dc_hrs', d['rec_ev_avg_dc_hrs']));
      row.push(FormatCell('parking', d['parking']));
      row.push(FormatCell('confidence', d['confidence']));
      row.push(FormatCell('failure_reason', d['failure_reason']));
      row.push(d['parking_loc']);
      row.push(d['parking_nickname']);
      row.push(FormatCell('parking_loc_pct', mapParkingPct(parkingData, d["vin"], d["parking_loc"])));
      row.push(FormatCell('obs_avg_dwell', d['obs_avg_dwell']));
      row.push(FormatCell('avg_daily_idling_hrs', d['avg_daily_idling_hrs']));
      row.push(FormatCell('required_upfits', d['required_upfits']));
      row.push(FormatCell('optional_upfits', d['optional_upfits']));
      row.push(FormatCell('telematics_provider', d['telematics_provider']));
  
      filteredData.push(row);
    });
  }

  DownloadExcelData(filteredData, headerCount, filename);
}

function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function FormatSuperAdminData(data, parkingData, dbDisplayName, userSettings) {
  // loop over all data returned from 'getVehicleResults' EP and present unformatted
  const date = formatTimestamp(userSettings, DateTime.local()).date; // Download date, should be user TZ sensitive
  const filename = sanitizeDownloadString(`sawatch-labs-ezEV-super-admin-results-${dbDisplayName}-${date}`);
  let filteredData = [];
  const headers = [
    "VIN",
    "Vehicle Class",
    "Year",
    "Make",
    "Model",
    "Asset ID",
    "Dept",
    "Location",
    "Scored On",
    "Recommendation",
    "Overall",
    "Confidence",
    "Energy",
    "Economics",
    "Parking",
    "Top EV",
    "Top PHEV",
    "Trips",
    "Total Days",
    "Active Days",
    "Min Timestamp",
    "Max Timestamp",
    `Estimated Yearly ${capitalizeString(userSettings.distance_labels.longPlural)}`,
    `Estimated Yearly Fuel ${capitalizeString(userSettings.liquid_volume_labels.longPlural)}`,
    "Estimated Yearly kWh",
    `Estimated Yearly Fuel Cost ${userSettings.currency_symbol}/${capitalizeString(userSettings.liquid_volume_labels.longSingular)}`,
    `Estimated Current Yearly TCO (${userSettings.currency_symbol})`,
    `Estimated Current Yearly Ops Cost (${userSettings.currency_symbol})`,
    `Observed Daily Max ${capitalizeString(userSettings.distance_labels.longPlural)}`,
    "Observed Daily Max EV kWh",
    `Observed PHEV ${capitalizeString(userSettings.distance_labels.longPlural)} Electric`,
    `Observed PHEV ${capitalizeString(userSettings.distance_labels.longPlural)} Fuel`,
    `Observed kWh Cost (${userSettings.currency_symbol})`,
    `Observed ${capitalizeString(userSettings.distance_labels.longPlural)} Per kWh`,
    `Rec Yearly TCO (${userSettings.currency_symbol})`,
    `Rec LT Net Savings (${userSettings.currency_symbol})`,
    `Rec LT Net Savings Range (${userSettings.currency_symbol})`,
    `Rec LT Ops Savings (${userSettings.currency_symbol})`,
    `Rec LT Ops Savings Range (${userSettings.currency_symbol})`,
    `Rec LT ${capitalizeString(userSettings.liquid_volume_labels.longPlural)} Saved`,
    `Rec LT GHG Reduction ${capitalizeString(userSettings.weight_labels.longPlural)}`,
    "Rec LT GHG Reduction Percent",
    `Rec Yearly TCO Reduction (${userSettings.currency_symbol})`,
    "Rec TCO Reduction Percent",
    "Parking Location Address (Entire Period)",
    "Parking Location Nickname (Entire Period)",
    "Parking Location Percent",
    "Rec Midday Charge Str",
    "Rec EV Avg Level 1 Hours",
    "Rec EV Avg Level 2 Hours",
    "Observed Avg Dwell",
    `Comp ICE Yearly Cost (${userSettings.currency_symbol})`,
    "Observed Min Timestamp",
    "Observed Max Timestamp",
    "Rec Midday Charge Stc",
    "Avg Daily kWh",
    "Rec Image URL",
    "Last Trip Timestamp",
    "Is EV Recommendation",
    `Observed ${capitalizeString(userSettings.distance_labels.longPlural)}`,
    "Failure Reason",
    `Min Temp °${userSettings.use_celsius ? 'C' : 'F'}`,
    `Max Temp °${userSettings.use_celsius ? 'C' : 'F'}`,
    "Avg Daily Idling Hours",
    "Rec EV Avg DC Hours",
    "Is Light Duty",
    "Life Cycle",
    `Maintenance Per ${capitalizeString(userSettings.distance_labels.longSingular)} (${userSettings.currency_symbol})`,
    `Insurance (${userSettings.currency_symbol})`,
    "Owned",
    `Fuel Cost (${userSettings.currency_symbol}/${capitalizeString(userSettings.liquid_volume_labels.longSingular)})`,
    `kWh Cost (${userSettings.currency_symbol})`,
    "GHG kWh Grams",
    `SCC (${userSettings.currency_symbol}/${capitalizeString(userSettings.ton_labels.longSingular)})`,
    "User Defined VIN",
    "Is BEV",
    "Is PHEV",
    "Is Diesel",
    "Is CNG",
    "Is Gasoline",
    "Homebase Parking Location ID",
    "Battery kWh",
    "Has Upfits",
    "Required Upfits",
    "Optional Upfits",
    "Fuel Type",
    "Provider",
    "Groups"
  ]
  let emptyArr = [];
  filteredData.push([`Sawatch Labs - ezEV`]);
  filteredData.push([`Complete EVSA Report (Super Admin) - ${dbDisplayName}`]);
  filteredData.push([`Download Date: ${date}`]);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);
  filteredData.push(emptyArr);

  const headerCount = filteredData.length;
  filteredData.push(headers);

  if (!data || data.length < 1 || data === 'misformed request') {
    filteredData.push(['No data to display']);
  }
  else {
    data.forEach(d => {
      var row = [];
      row.push(d["vin"]);
      row.push(d["vehicle_class"]);
      row.push(d["year"]); 
      row.push(d["make"]);
      row.push(d["model"]);
      row.push(d["asset_id"])
      row.push(d["dept"]);
      row.push(d["location"]);
      row.push(d["scored_on"]);
      row.push(d["recommendation"]);
      row.push(d["overall"]);
      row.push(d["confidence"]);
      row.push(d["energy"]);
      row.push(d["economics"]);
      row.push(d["parking"]);
      row.push(d["top_ev"]);
      row.push(d["top_phev"]);
      row.push(d["trips"]);
      row.push(d["total_days"]);
      row.push(d["active_days"]);
      row.push(d["excel_sortable_min_ts"]);
      row.push(d["excel_sortable_max_ts"]);
      row.push(d["est_yearly_km"]);
      row.push(d["est_yearly_fuel_liters"]);
      row.push(d["est_yearly_kwh"]);
      row.push(d["est_yearly_fuel_cost"]);
      row.push(d["est_current_yearly_tco"]);
      row.push(d["est_current_yearly_ops_cost"]);
      row.push(d["obs_daily_max_km"]);
      row.push(d["obs_daily_max_ev_kwh"]);
      row.push(d["obs_phev_km_elec"]);
      row.push(d["obs_phev_km_fuel"]);
      row.push(d["obs_kwh_cost"]);
      row.push(d["obs_kmpkwh"]);
      row.push(d["rec_yearly_tco"]);
      row.push(d["rec_lt_net_savings"]);
      row.push(FormatCell("rec_lt_net_savings_range", d["rec_lt_net_savings"], null, userSettings));
      row.push(d["rec_lt_ops_savings"]);
      row.push(FormatCell("rec_lt_ops_savings_range", d["rec_lt_ops_savings"], null, userSettings));
      row.push(d["rec_lt_gals_saved"]);
      row.push(d["rec_lt_ghg_reduction_lbs"]);
      row.push(d["rec_lt_ghg_reduction_pct"]);
      row.push(d["rec_yr_tco_reduction"]);
      row.push(d["rec_tco_reduction_pct"]);
      row.push(d["parking_loc"]);
      row.push(d["parking_nickname"]);
      row.push(mapParkingPct(parkingData, d["vin"], d["parking_loc"]));
      row.push(d["rec_midday_charge_str"]);
      row.push(d["rec_ev_avg_level1_hrs"]);
      row.push(d["rec_ev_avg_level2_hrs"]);
      row.push(d["obs_avg_dwell"]);
      row.push(d["comp_ice_yearly_cost"]);
      row.push(`${d["observed_min_ts"].date} ${d["observed_min_ts"].time}`);
      row.push(`${d["observed_max_ts"].date} ${d["observed_max_ts"].time}`);
      row.push(d["rec_midday_charge_stc"]);
      row.push(d["avg_daily_kwh"]);
      row.push(d["rec_img_url"]);
      row.push(`${d["last_trip_ts"].date} ${d["last_trip_ts"].time}`);
      row.push(d["is_ev_recommendation"]);
      row.push(d["obs_km"]);
      row.push(d["failure_reason"]);
      row.push(d["min_celsius"]);
      row.push(d["max_celsius"]);
      row.push(d["avg_daily_idling_hrs"]);
      row.push(d["rec_ev_avg_dc_hrs"]);
      row.push(d["is_ld"]);
      row.push(d["life_cycle"]);
      row.push(d["maint_per_km"]);
      row.push(d["insurance"]);
      row.push(d["owned"]);
      row.push(d["fuel_cost"]);
      row.push(d["kwh_cost"]);
      row.push(d["ghg_kwh_gm"]);
      row.push(d["scc"]);
      row.push(d["user_defined_vin"]);
      row.push(d["is_bev"]);
      row.push(d["is_phev"]);
      row.push(d["is_diesel"]);
      row.push(d["is_cng"]);
      row.push(d["is_gasoline"]);
      row.push(d["parking_id"]);
      row.push(d["battery_kwh"]);
      row.push(d["has_upfits"]);
      row.push(FormatCell("required_upfits", d["required_upfits"], null, userSettings));
      row.push(FormatCell("optional_upfits", d["optional_upfits"], null, userSettings));
      row.push(d["fuel_type"]);
      row.push(d["telematics_provider"]);
      row.push(FormatCell("group_memberships",d["group_memberships"],null,userSettings));
      filteredData.push(row);
    });
  }

  DownloadExcelData(filteredData, headerCount, filename,userSettings,"superAdminExcel");
}

export function convertISOToExcelSerial(timestamp) {
  const utcDate = DateTime.fromISO(timestamp).toUTC(); // Parse the ISO 8601 timestamp to a Luxon DateTime object
  const excelBaseDate = DateTime.utc(1899, 12, 30, 0, 0, 0); // Excel's date serial number starts on December 30, 1899, for compatibility reasons
  const excelSerialDate = (utcDate.ts - excelBaseDate.ts) / (24 * 60 * 60 * 1000); // Format original timestamp to Excel serial

  return excelSerialDate;
}

export function sanitizeDownloadString(str) {
  // Replace all forward slashes, underscores, and spaces with dashes
  const replaceWithDashes = str.replace(/[\s/_]/g, "-");
  // Remove non-alphanumeric characters, excluding dashes
  const sanitized = replaceWithDashes.replace(/[^a-zA-Z0-9-]/g, "");
  return sanitized;
}

function generateExcelDateFormat(settings) {
  let dayFormatString = settings.date_display;
  let timeFormatString = "";
  
  switch(settings.ts_display){
    case "hh:mm:ss xm":
      timeFormatString= "hh:mm:ss AM/PM"; 
      break;
    case "hh:mm:ss":
      timeFormatString= "HH:mm:ss";
      break;
    case "hh:mm xm":
      timeFormatString= "hh:mm AM/PM";
      break; 
    case "hh:mm":
      timeFormatString= "HH:mm";
      break;
    default:
      timeFormatString= "HH:mm:ss";
      break;
  }
  return `${dayFormatString} - ${timeFormatString}`
}