import { MapControl, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
class ShftdLegend extends MapControl {
  createLeafletElement(props) {}

  componentDidMount() {
    this.createMapLegend();
  }

  componentWillUnmount() {
    const { map } = this.props.leaflet;
    if (map.myLegend) delete map.myLegend;
  }

  createMapLegend = () => {
    const legend = L.control({ position: 'bottomleft' });
    legend.onAdd = function(map) {
      map.myLegend = this;
      var legendDiv = L.DomUtil.create('div', 'sftd-legend');
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv-lhs"> <p class="sftd-key-dot unselected"/><p class= "sftd-key-text">Unselected</p></span>';
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv"> <p class="sftd-key-dot zero"/><p class= "sftd-key-text">0 Vehicles</p></span>';
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv"> <p class="sftd-key-dot one"/><p class= "sftd-key-text">1 Vehicle</p></span>';
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv"> <p class="sftd-key-dot two"/><p class= "sftd-key-text">2-10 Vehicles</p></span>';
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv"> <p class="sftd-key-dot eleven"/><p class= "sftd-key-text">11-20 Vehicles</p></span>';
      legendDiv.innerHTML +=
        '<span class="sftd-parentdiv"> <p class="sftd-key-dot twentytwo"/><p class= "sftd-key-text">21+ Vehicles</p></span>';
      legendDiv.innerHTML +=
        '<br/><span class="sftd-parentdiv-note"> <p class= "sftd-key-note">*Locations are symbolized by the number of <span id = "underline">selected</span> vehicles.</p></span>';
      return legendDiv;
    };

    legend.onRemove = function(map) {
      delete map.myLegend;
    };
    const { map } = this.props.leaflet;
    legend.addTo(map);
  };
}

export default withLeaflet(ShftdLegend);
