
import Select from "react-select"
import React, { useEffect, useState } from "react"
import * as CS from "../../styles/ezio-styles/Controls-styles"
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"


type CategoryDropdownProps = {
    selectedCategory: string
    onChange: Function
}

export default function CategoryDropdown({ selectedCategory, onChange }: CategoryDropdownProps) {

    const [isOpen, setIsOpen] = useState(false)
    const options: { value: string, label: string }[] = (["All Categories", "Light Duty", "Medium and Heavy Duty"].map((item): { value: string, label: string } => ({ value: item, label: item })))



    function toggleOpen() {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        scrollToSelected()

    }, [isOpen])
    return (
        <Dropdown
            dropdownId="category-dropdown-testid"
            isOpen={isOpen}
            onClose={toggleOpen}
            target={

                <CS.CategoryMenuButton onClick={toggleOpen}>
                    <CS.CategoryMenuText>
                        {`${selectedCategory}`}
                    </CS.CategoryMenuText>
                    <CS.CustomDownChevron /></CS.CategoryMenuButton>
            }
        >
            <Select
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen={isOpen}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                value={
                    {
                        value: selectedCategory,
                        label: selectedCategory
                    }
                }
                onChange={(event) => {
                    setIsOpen(false)
                    onChange(event?.value)
                }}
                options={options}
                styles={
                    {
                        ...CS.SelectStyles,
                        container: (base: any) => {
                            return {
                                ...base,
                                width: "220px",
                                borderStyle: "none"
                            }
                        }
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CS.CustomDownChevron,
                    Control: () => null,
                }
                }
                formatOptionLabel={formatOptionLabel}

            />
        </Dropdown>
    )
}



