import React, { useCallback } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table7";
import { useHistory } from "react-router-dom";
import arrowUp from '../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../images/ic-arrow-unsort-4px-9px.gif';
import { execExcelDownload } from "./idling-csv-helper-functions";
import { DefaultColumnFilter } from "./idling-table-filter";
import { formatCellData } from "./idling-table-helper-functions";

export default function IdlingSummaryTable(props){
  const { columns, data, filter, beginDate, endDate, group, vehicleClasses, minDuration, tableType, csvFilename, dbDisplayName } = props;
  const history = useHistory();
  const handleOnClick = useCallback((vin) => history.push(`/idling/vehicledetail/${vin}`), [history]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const colType = (col) => {
    if(col === 'Weekly Incidents' || col === 'Model' || col === 'Make'){
      return "idling-table-header-long";
    }
    return "idling-table-header";
  }

  const el = document.getElementById('idling-table-explain');
  if(el)el.style.display = 'block';
  
  return(
    <>
      <div className='idling-table-info-wrapper'>
        <div className="idling-explain-text-wrapper">
          <span className='idling-explain-text-2'>Summary incident details per vehicle.</span>
          <br />
          <span className="idling-explain-text">Click a column name to sort the table. Click a row for details.</span>
      </div>
      <button onClick={()=>execExcelDownload({
        "tableType": tableType, 
        "beginDate": beginDate,
        "endDate": endDate,
        "group": group, 
        "vehicleClasses": vehicleClasses,
        "minDuration": minDuration,
        "columns": columns,
        "data": data,
        "filter": filter,
        "dbDisplayName": dbDisplayName,
        "filename": csvFilename + '.xlsx'})} className="idling-download-btn">Download</button>
      
      </div>
    <div className='table-container'>
      <table {...getTableProps()}
        onClick={function(e){props.handleSort(e.target.outerText, props.desc)}}
      >
        <thead className='table-header-container'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} className={colType(column.Header)}>
                 <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span className="idling-table-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <img src={ arrowDown } className="idling-table-sort-down-arrow" alt="Column sorted in descending order" />
                          : <img src={ arrowUp } className="idling-table-sort-up-arrow" alt="Column sorted in ascending order" />
                        : <img src={ arrowUnsel } className="idling-table-sort-both-arrow" alt="Unsorted column" />}
                    </span>
                    </div>
                    <div className="table-searchbar">{column.Header === 'Asset ID' ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr className='idling-table-row clickable-row' {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td 
                      className="idling-table-cell idling-link"
                      onClick={function(){handleOnClick(cell.row.original.vin)}}
                      {...cell.getCellProps()} id={cell.vin}>
                      {formatCellData(cell)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      {rows.length < 1 
      ? 
      <p className="idling-no-data-msg">No data to display. Try adjusting date range or filters.</p> 
      : 
      <div className='table-pagination-container'>
        <button
          className="pagination-button"
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </button>
        <span className='pagination-details'>Page {pageIndex + 1} of {pageOptions.length}</span>

        <button
        className="pagination-button"
        onClick={nextPage} 
        disabled={!canNextPage}>
        Next
      </button>
      </div>
}
    </>);
}
