import React, { useEffect, useCallback } from "react";
import ScenarioToolControls from "./ScenarioToolControls";
import RecBarChart from "./graphs";
import OverallStats from "../overallStats";
import Modal from "./modal";
import Loading from "../loading";
import { standardizePrecision } from "../dataFormatter";
import { updateEzevSettings, getSettingsConstraints } from "../fetchCalls";

export default function ScenarioTools(props) {
  // NOTE move 'stable' saResults and maintaining changes to saResults here
  const [wtpValue, setWtpValue] = React.useState(0);
  const [gasValue, setGasValue] = React.useState(0);
  const [ecValue, setEcValue] = React.useState(0);
  const [saResults, setSaResults] = React.useState([]);
  const [settingsConstraints, setsettingsConstraints] = React.useState({});
  const [chargingNeeds, setChargingNeeds] = React.useState({
    value: 0,
    sliderValue: 0,
    sliderText: "",
  });
  const [showUpdateModal, setShowUpdateModal] = React.useState(false); // why does this need to be state? Only happens on click
  const { saRecData, recData, saSettings } = props;

  const _getSettingsConstraints = useCallback(() => {
    return getSettingsConstraints(props.requestData, setsettingsConstraints);
  }, [props.requestData]);

  useEffect(() => {
    _getSettingsConstraints();
  }, [_getSettingsConstraints]);

  function getEnergyValues(input) {
    // given energy threshold, set slider values
    // ORDER: if your threshold is high, few or no vehicles will get
    // disqualified based on energy threshold (break point). If lower, vehicle
    // must have a good energy score (not need to charge) to pass
    // Note if energy_threshold bounds change this also needs to change

    // Reverted back to opposite on 12/23/20 after much discussion between RC and DA.
    // Left code commented out if we want to revert back in future.
    switch (true) {
      // case input === 100:
      case input < 70:
        return {
          value: input,
          sliderValue: 5,
          sliderText: "Very often",
        };
      // case input < 100 && input >= 95:
      case input < 85 && input >= 70:
        return {
          value: input,
          sliderValue: 4,
          sliderText: "Several times per week",
        };
      // case input < 95 && input >= 90:
      case input < 90 && input >= 85:
        return {
          value: input,
          sliderValue: 3,
          sliderText: "Approx once per week",
        };
      // case input < 90 && input >= 85:
      case input < 95 && input >= 90:
        return {
          value: input,
          sliderValue: 2,
          sliderText: "Several times per month",
        };
      // case input < 85 && input >= 70:
      case input < 100 && input >= 95:
        return {
          value: input,
          sliderValue: 1,
          sliderText: "Approx once a month",
        };
      // case input < 70:
      case input === 100:
        return { value: input, sliderValue: 0, sliderText: "Not needed" };
      default:
        return { value: input, sliderValue: null, sliderText: "" };
    }
  }

  function _handleChargingNeeds(value) {
    // called on change for slider
    value = parseInt(value);
    let obj;
    switch (value) {
      case 5: // Very Often
        obj = {
          // value: 100,
          value: 0,
          sliderValue: 5,
          sliderText: "Very often",
        };
        break;
      case 4: // Several times per week
        obj = {
          // value: 95,
          value: 70,
          sliderValue: 4,
          sliderText: "Several times per week",
        };
        break;
      case 3: // Approx oncer per week
        obj = {
          // value: 90,
          value: 85,
          sliderValue: 3,
          sliderText: "Approx once per week",
        };
        break;
      case 2: // Several times per month
        obj = {
          // value: 85,
          value: 90,
          sliderValue: 2,
          sliderText: "Several times per month",
        };
        break;
      case 1: // Once per month
        obj = {
          // value: 70,
          value: 95,
          sliderValue: 1,
          sliderText: "Approx once a month",
        };
        break;
      case 0: // Not needed
        obj = {
          // value: 0,
          value: 100,
          sliderValue: 0,
          sliderText: "Not needed",
        };
        break;
      default:
        return null;
    }
    setChargingNeeds(obj);
  }

  // calculates graph and updated metrics data once Sensitivity Analysis RecData response is returned
  // could change to global variable if we dont care about sensitivity analysis results mirroring ezEV on render.
  //  NOTE above. Impliment after sorting ref overallStats bug

  const _setFilterValues = useCallback(() => {
    setWtpValue(_getWtpValue(saSettings.economics_threshold));
    setGasValue(parseFloat(saSettings.fuel_cost_usd));
    setEcValue(parseFloat(saSettings.local_kwh_usd));
    setChargingNeeds(getEnergyValues(saSettings.energy_threshold));
  }, [
    saSettings.economics_threshold,
    saSettings.energy_threshold,
    saSettings.fuel_cost_usd,
    saSettings.local_kwh_usd,
  ]);

  const _resetFilterValues = useCallback(() => {
    setWtpValue(_getWtpValue(props.settings.economics_threshold));
    setGasValue(parseFloat(props.settings.fuel_cost_usd));
    setEcValue(parseFloat(props.settings.local_kwh_usd));
    setChargingNeeds(getEnergyValues(props.settings.energy_threshold));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setFilterValues();
  }, [saSettings, _setFilterValues]);

  const _updateFilters = () => {
    setShowUpdateModal(true);
    // should just show update modal
    // can be onclick vs state
  };

  const _defaultSettings = () => {
    // NOTE this and reset should be consolidated - resets to default/baseline
    return {
      wtp: _getWtpValue(props.settings.economics_threshold),
      gas: parseFloat(props.settings.fuel_cost_usd),
      elec: props.settings.local_kwh_usd,
      charge: getEnergyValues(props.settings.energy_threshold),
    };
  };

  // called when modal confirmed
  const _updateResults = () => {
    props.setIsLoading(true);
    setShowUpdateModal(false);
    _updateSettings();
  };

  const _updateSettings = () => {
    var nrgThreshold = chargingNeeds.value;
    var econThreshold = _getEconThreshold(wtpValue);
    let obj = { ...saSettings };
    // settings are for dbName not name retrieved from getSettings call
    obj.db = props.requestData.dbName;
    obj.fuel_cost_usd = gasValue;
    obj.local_kwh_usd = ecValue;
    obj.energy_threshold = nrgThreshold;
    obj.economics_threshold = econThreshold;
    obj.email = props.email;
    delete obj.wtp;
    obj.baseline = false; // need this for where no false settings
    updateEzevSettings(props.requestData, obj);
  };

  const _getWtpValue = (econThreshold) => {
    // 90 is cost parity - only showing cost parity or wtp more
    if (econThreshold <= 90) {
      // everything up to 100 counts proportionately
      var value = econThreshold - 90;
      value = -value;
    } else {
      console.error("Invalid economics threshold value. Out of bounds");
      value = 0;
    }
    return value;
  };

  const _getEconThreshold = (wtpValue) => {
    // Note if economics_threshold bounds change this also needs to change
    var value = 90 - wtpValue;
    return value;
  };

  const _sortData = useCallback(
    (vcls) => {
      // tally for totals too, then check rec
      let tcoReduction = 0;
      let ghgReduction = 0;
      let fuelReduction = 0;
      let recCount = 0;
      let results = {
        Sedan: [],
        SUV: [],
        Pickup: [],
        Minivan: [],
        "Cargo-van": [],
      };
      let recResults = {
        Sedan: [],
        SUV: [],
        Pickup: [],
        Minivan: [],
        "Cargo-van": [],
      };

      // class first
      if (vcls.length > 0) {
        vcls.forEach((v) => {
          if (results.hasOwnProperty(v.vehicle_class)) {
            results[`${v.vehicle_class}`].push(v);
            // moving this into this loop inner loop temporarily to exclude
            // other classes in totals
            if (
              v.recommendation.includes("BEV") ||
              v.recommendation.includes("PHEV")
            ) {
              tcoReduction +=
                standardizePrecision(v.rec_yr_tco_reduction) *
                saSettings.life_cycle;
              ghgReduction += standardizePrecision(v.rec_lt_ghg_reduction_lbs);
              fuelReduction += standardizePrecision(v.rec_lt_gals_saved);
              recCount++;
              recResults[`${v.vehicle_class}`].push(v);
            }
          }
        });
      }
      return {
        statsData: {
          tco: tcoReduction,
          ghg: ghgReduction,
          fuel: fuelReduction,
          numbOfEVRecs: recCount,
        },
        graphData: {
          sedan: results["Sedan"].length,
          suv: results["SUV"].length,
          pickup: results["Pickup"].length,
          minivan: results["Minivan"].length,
          cargovan: results["Cargo-van"].length,
          sedanRecs: recResults["Sedan"].length,
          suvRecs: recResults["SUV"].length,
          pickupRecs: recResults["Pickup"].length,
          minivanRecs: recResults["Minivan"].length,
          cargovanRecs: recResults["Cargo-van"].length,
        },
      };
    },
    [saSettings.life_cycle]
  );

  const _finalizeStableSettings = (settings) => {
    var stgs = settings.current;
    var wtp = _getWtpValue(stgs.economics_threshold);
    stgs.wtp = wtp;
    return stgs;
  };
  const _filterSaCandidates = useCallback(() => {
    // this and sort should handle no data gracefully
    let vcls = saRecData; // don't update based on changing wtpValue
    return _sortData(vcls);
  }, [_sortData, saRecData]);

  const _filterCandidates = useCallback(() => {
    let vcls = recData; // don't update based on changing wtpValue
    return _sortData(vcls);
  }, [_sortData, recData]);

  useEffect(() => {
    if (saRecData.length > 0) {
      setSaResults(_sortData(saRecData));
    } else {
      window.confirm(
        "There are no Scenario Tool results to display. Move the sliders below and click update results to see how changes in inputs impact the EV suitability for your fleet"
      );
    }
  }, [_sortData, saRecData]);

  let filteredData = _filterCandidates(); // filtered and sorted rec data
  let filteredSaData = _filterSaCandidates();
  const timeDuration = () => {
    var time = Math.round((props.recData.length * 0.06) / 60); // 0.03 processing
    if (time === 0) return "This process will take less than 1 minute";
    if (time === 1) return "This process will take approximately 1 minute";
    else return `This process will take approximately ${time} minutes`;
  };
  return (
    // NOTE if loading don't want side bars either
    // extra component for entire container?
    <div className="nrel-sensitivity-analysis-wrpr">
      {showUpdateModal && (
        <Modal
          showModalHandler={() => setShowUpdateModal(!showUpdateModal)}
          title={"Note updating results may take some time."}
          message={timeDuration()}
          confirm={true}
          confirmationHandler={_updateResults}
        />
      )}
      <div className="nrel-sensitivity-analysis-chart">
        {props.isLoading ? (
          <Loading
            totalVcls={props.recData.length}
            remainingVcls={props.remainingVcls}
          />
        ) : (
          <RecBarChart
            filteredData={filteredData.graphData}
            SAResults={filteredSaData.graphData}
            yMax={recData.length}
          />
        )}
      </div>
      <div className="nrel-sensitivity-analysis-controls">
        <div>
          <OverallStats
            title={"Modified Results For Recommended EVs:"}
            overallStats={saResults}
          />
        </div>
        <ScenarioToolControls
          wtp={wtpValue}
          gas={gasValue}
          ec={ecValue}
          chargingNeeds={chargingNeeds}
          setWilling={setWtpValue}
          setGas={setGasValue}
          setEc={setEcValue}
          setCharging={_handleChargingNeeds}
          updateFilters={_updateFilters}
          defaultSettings={_defaultSettings()}
          saSettingsStable={_finalizeStableSettings(props.saSettingsStable)}
          resetFilterValues={_resetFilterValues}
          settingsConstraints={settingsConstraints}
        />
      </div>
    </div>
  );
}
