export function getCustomerInfo(requestData, cb) {
  let url = requestData.apiURL + `getDisplayName?db=${requestData.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      return validateAPIResponse(data, true);
    })
    .then((result) => cb(result, requestData.db))
    .catch((error) => console.error("Error:" + error));
}

export function getTelematicsProvider(requestData, cb) {
  let url =
    requestData.apiURL + `getTelematicsProvider?client=${requestData.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => validateAPIResponse(data, true))
    .then((result) => cb(result))
    .catch((err) => console.error("Error in getTelematicsProvider" + err));
}

export function getVehicleResults(requestData, cb) {
  var url = `${requestData.apiURL}getVehicleResults`;
  var queries = `?ident=${requestData.dbName}`;
  // NOTE ep will also take &groupId=${id}
  fetch(`${url}${queries}`, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((res) => res.json())
    .then((data) => validateAPIResponse(data, false))
    .then((result) => {
      if (result) cb(result);
    })
    .catch((error) => console.error("Error: " + error));
}

export function getVehicleSensitivityResults(requestData, cb) {
  var url = `${requestData.apiURL}getVehicleSensitivityResults`;
  var queries = `?ident=${requestData.dbName}`;
  fetch(`${url}${queries}`, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((res) => res.json())
    .then((data) => validateAPIResponse(data, false))
    .then((data) => {
      if (data) cb(data);
    })
    .catch((error) => console.error("Error: " + error));
}

export function getSettings(requestData, cb, baselineBoolean) {
  if (typeof baselineBoolean === "undefined") baselineBoolean = true;
  var url = `${requestData.apiURL}getSettings`;
  var queries = `?ident=${requestData.dbName}&baseline=${baselineBoolean}`;
  fetch(`${url}${queries}`, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((res) => res.json())
    .then((data) => validateAPIResponse(data, true))
    .then((data) => {
      if (data) cb(data);
    })
    .catch((error) => console.error("Error: " + error));
}

export function getSettingsConstraints(requestData, cb) {
  var url = `${requestData.apiURL}getSettingsConstraints`;
  var queries = `?ident=${requestData.dbName}`; // need this for api check
  fetch(`${url}${queries}`, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((res) => res.json())
    .then((data) => validateAPIResponse(data, false))
    .then((data) => {
      if (data) cb(data);
      else cb({ Error: "Error retrieving settings constraint." });
    })
    .catch((error) => console.error("Error: " + error));
}

export function getGroupEzevMetrics(requestData, cb, group) {
  if (typeof group === "undefined" || group === null) group = "GroupCompanyId";
  let url =
    requestData.apiURL +
    `getGroupEzevMetrics?ident=${requestData.dbName}&group=${group}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => validateAPIResponse(data, false))
    .then((data) => {
      if (data) cb(data);
    })
    .catch((error) => console.error("Error:", error));
}

export function getGroupMetrics(requestData, cb, group) {
  if (typeof group === "undefined" || group === null) group = "GroupCompanyId";
  let url =
    requestData.apiURL +
    `getGroupMetrics?ident=${requestData.dbName}&group=${group}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => validateAPIResponse(data, false))
    .then((d) => cb(d))
    .catch((error) => console.error("Error:", error));
}

export function getCandidates(requestData, cb) {
  var url = `${requestData.apiURL}getCandidates?ident=${requestData.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((res) => res.json())
    .then((data) => cb(data.data))
    .catch((error) => console.error("Error: " + error));
}

export function getBulkEzevResults(requestData, cb) {
  //if(!options || !options.vin) return(cb('Invalid Parameters'));
  var url = `${requestData.apiURL}getBulkEzevResults?ident=${requestData.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => cb(data.data));
}

export function getEzevCandidateResults(requestData, cb, options) {
  if (!options || !options.vin) return cb("Invalid Parameters");
  var url = `${requestData.apiURL}getEzevCandidateResults?ident=${requestData.dbName}&vin=${options.vin}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${requestData.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => cb(data));
}

export function getRenderingProgress(requestData, cb) {
  const url = `${requestData.apiURL}isRendering?ident=${requestData.dbName}&stats=sensitivity_stats`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => {
      return validateAPIResponse(data, true);
    })
    .then((data) => {
      cb(data); // count = how many vcls left to update
    })
    .catch((error) => {
      console.error("Error:" + error);
      window.alert(
        "An error has occured. Please contact us to resolve the issue."
      );
    });
}

export function getTopLocations(requestData, cb, paramObject) {
  let percent = 100;
  let limit = 1000;
  if (paramObject && paramObject.percent) percent = paramObject.percent;
  if (paramObject && paramObject.limit) limit = paramObject.limit;
  const url = `${requestData.apiURL}getTopKWHLocations?clientId=${requestData.dbName}&limit=${limit}&percent=${percent}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => cb(data.data))
    .catch((error) => console.error("Error:" + error));
}

export function getKWDemandByLocation(requestData, cb, opts) {
  if (!requestData || !cb || !opts) return;
  var b = opts.bounds;
  if (opts.bounds && typeof opts.bounds === "string")
    b = opts.bounds.split(",");
  const start = b[0];
  const stop = b[1];
  const url = `${requestData.apiURL}getKWDemandByLocation?clientId=${requestData.dbName}&start=${start}&stop=${stop}&loc=[${opts.loc}]&percent=${opts.percent}&showezev=${opts.showEzevResults}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => {
      if (data) cb(data, requestData);
    })
    .catch((error) => console.error("Error:" + error));
}

export function getPeakMonthlyKwhDemandByLocation(requestData, cb, opts) {
  if (!requestData || !cb || !opts) return;
  var b = opts.bounds;
  if (opts.bounds && typeof opts.bounds === "string")
    b = opts.bounds.split(",");
  const start = b[0];
  const stop = b[1];
  var locs = opts.locs;
  if (typeof locs !== Array) locs = [locs];
  const url = `${requestData.apiURL}getPeakMonthlyKwhDemandByLocation?clientId=${requestData.dbName}&start=${start}&stop=${stop}&loc=[${locs}]&percent=${opts.percent}&showezev=${opts.showEzevResults}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => validateAPIResponse(data, false))
    .then((data) => {
      if (data) cb(data);
    })
    .catch((error) => console.error("Error:" + error));
}

export function getPeakDemand(requestData, parameters, cb) {
  const url = `${requestData.apiURL}getPeakDemand?clientId=${requestData.dbName}&start=${parameters.start}&stop=${parameters.stop}&loc=${parameters.locId}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => {
      cb(data);
    })
    .catch((error) => console.error("Error:" + error));
}

export function updateEzevSettings(requestData, obj) {
  var url = `${requestData.apiURL}updateSettings`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${requestData.token}`,
    },
    body: JSON.stringify(obj),
  })
    .then((resp) => {
      if (resp.status === 200) {
        // render ezev
        renderEzev(requestData);
      } else {
        console.error("Updating Ezev Settings Error");
        window.alert(
          "An error has occured. Please contact us to resolve the issue."
        );
      }
    })
    .catch((err) => {
      // catch does NOT fire if non-200 status returned
      console.error("Updating Ezev Settings Error " + err);
      window.alert(
        "An error has occured. Please contact us to resolve the issue."
      );
    });
}

function renderEzev(requestData) {
  var url = `${requestData.apiURL}renderezev`;
  var obj = { company_ident: requestData.dbName, baseline: "false" };
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${requestData.token}`,
    },
    body: JSON.stringify(obj),
  }).catch((err) => {
    console.error("Error rendering Ezev" + err);
    window.alert(
      "An error has occured. Please contact us to resolve the issue."
    );
  });
}

export function getPeakKWHlocationsByPercent(requestData, parameters, cb) {
  const url = `${requestData.apiURL}getPeakKWHlocationsByPercent?clientId=${requestData.dbName}&start=${parameters.start}&stop=${parameters.stop}&percent=${parameters.pct}&limit=${parameters.lmt}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => {
      cb(data.data);
    })
    .catch((error) => console.error("Error:" + error));
}

export function getLocationVehiclesByPerc(requestData, parameters, cb) {
  const url = `${requestData.apiURL}getLocationVehiclesByPerc?clientId=${requestData.dbName}&loc=${parameters.locId}&percent=${parameters.percent}&showezev=${parameters.showEzevResults}&start=${parameters.start}&stop=${parameters.stop}`;
  fetch(url, { headers: { Authorization: `Bearer ${requestData.token}` } })
    .then((res) => res.json())
    .then((data) => {
      cb(data.data);
    })
    .catch((error) => console.error("Error:" + error));
}

function validateAPIResponse(res, singular) {
  if (res && res["data"]) {
    if (singular && res["data"][0]) {
      return res["data"][0];
    } else if (!singular) {
      return res["data"];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
