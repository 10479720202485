import React, { useState, useEffect, useCallback, useMemo, Suspense } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import VehicleDropdown from "../dashboard-components/swt-vehicle-dropdown";
import MapControls from "./MapControls";
import DevToolsTable from "./DevToolsTable";
import "react-datepicker/dist/react-datepicker.css";
import * as S from '../../../styles/core-styles/DevTools-styles';

//code-split imports
const DevToolMap = React.lazy(() => import("./DevToolMap"));

const EV_MECHANICAL_EFFICIENCY = 0.9
const DIESEL_MECHANICAL_EFFICIENCY = 0.4
const GASOLINE_MECHANICAL_EFFICIENCY = 0.2
//const CNG_MECHANICAL_EFFICIENCY = 0.35

const DIESEL_MJ_LITER = 36;
const GASOLINE_MJ_LITER = 34;
const KWH_MJ = 3.6;

const ALL_VEHICLES = {pkid: -1, vin: "all-vehicles-key", asset_id: "All Vehicles", year:"", make:"", model:""}

const LAYER_OPTIONS_PROTOTYPE = {
  id: 'tripSegments',  
  type: 'line',
  source: 'tripSegments',
  paint: {
    "line-width": 5,
    'line-color': ['get', 'color']
  }
}

const TRIP_TABLE_COLUMNS = [
  { Header: "Local Start", accessor: "local_start", sortType: "basic" },
  { Header: "Local Stop", accessor: "local_stop", sortType: "basic" },
  { Header: "KM", accessor: "km", sortType: "basic" }
]

export type GeoJsonFeature = {
  type?: string,
  properties?: any,
  geometry?: any,
  segment?: any
}

const SEGMENT_POPUP_HTML = (segment: any) =>{
    return (`
        <span className="speedn-popup-text">Duty Cycle Cumulative KM: ${segment.cumulativeKM.toFixed(2)}</span><br />
        <span className="speedn-popup-text">Duty Cycle Cumulative Syn kWh: ${segment.cumulativeKWH.toFixed(2)}</span><br />
        <span className="speedn-popup-text">Segment KM: ${segment.km_delta.toFixed(3)}</span><br />
        <span className="speedn-popup-text">Segment kWh: ${segment.syn_kwh_delta.toFixed(3)}</span><br />
        <span className="speedn-popup-text">Segment KMPKWH: ${segment.kmpkwh.toFixed(2)}</span><br />
        <span className="speedn-popup-text">Segment KMPL: ${segment.kmpl.toFixed(2)}</span><br />
    `)
}

export default function DutyCycleMap(props: any) {

  const { selectedVIN, beginDate, endDate, energyFilter, tripStyle, user, apiURL, db } = props;
  //const { id } = useParams<{id?: string}>();
  const history = useHistory();
  const [dutyCycles, setDutyCycles] = useState([]);
  const [mapCenter, setMapCenter] = useState(Array<number>);
  const [dutyCycleTelemetry, setDutyCycleTelemetry] = useState(Array<any>);
  const [eventLayers, setEventLayers] = useState<any>(null);
  const [events, setEvents] = useState<any>(null);
  const [showSatellite, setShowSatellite] = useState(false);
  const [selectedDutyCycle, setSelectedDutyCycle] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);

  const handleVehicleChange = useCallback((e: any) => {
    props.setSelectedVIN(e.target.value);
    setDutyCycleTelemetry([]);
    setLoading(false);
    history.push(`/dev-tools/duty-cycle-map/null`);
  }, [props, history]);

  const vehicles = useMemo(() =>{
    const av = props.vehicles.find((v: any) => {return v.vin === ALL_VEHICLES.vin});
    if(typeof av === 'undefined'){
      const vcls = [ALL_VEHICLES, ...props.vehicles];
      vcls.forEach((v) => {v.key = v.vin;});
      return vcls;
    }
    return props.vehicles;
  }, [props.vehicles]);

  useEffect(() => {
    let lat = 0, lon = 0, ct = 0;
    if(selectedVIN){
        const v = vehicles.find((v: any)=> v.vin === selectedVIN);
        if(v.homebase_latitude && v.homebase_longitude){
            setMapCenter([v.homebase_latitude, v.homebase_longitude]);
            return;
        }
    }
    vehicles.forEach((v: any)=>{
        if(v.homebase_latitude && v.homebase_longitude){
            lat += v.homebase_latitude;
            lon += v.homebase_longitude;
            ct++;
        }
    });
    lat = lat / ct;
    lon = lon / ct;
    setMapCenter([lat, lon]);
  }, [selectedVIN, vehicles]);

  useEffect(() => {
    const c = _getMapCenter(dutyCycleTelemetry);
    const layers = Array<any>();
    const accessor = _accessorForFilter(energyFilter)
    const gj = calcEventLayerGeoJSON(dutyCycleTelemetry, accessor, {});
    const j = JSON.parse(JSON.stringify(LAYER_OPTIONS_PROTOTYPE));
    layers.push({layer: j, geoJSON: gj});
    if(dutyCycleTelemetry)setEventLayers(layers);
    if(c[0] && c[1])setMapCenter(c);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dutyCycleTelemetry, energyFilter, tripStyle]);

  const handleDutyCyclesChange = ((dc: any) => {
    const data = dc.map((d: any) => {
      if (d.local_start) d.local_start = formatTimestamp(d.local_start);
      if (d.local_stop) d.local_stop = formatTimestamp(d.local_stop);
      return d;
    });
    data.sort(localStartSort);
    setDutyCycles(data);
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${apiURL}getDutyCyclesByVin?dbName=${db}&vin=${selectedVIN}&&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}`

    fetch(url, {
        signal,
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === "error")
            alert("Error getting duty cycles by vin.");
          else
            handleDutyCyclesChange(data.data);
        })
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVIN]);

  useEffect(() => {
    if (props.selectByDateRange || selectedDutyCycle < 0) {
      return
    }
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${apiURL}getDutyCycleTelemetry?dbName=${db}&dutyCycle=${selectedDutyCycle}`;
    fetch(url, {
      signal,
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error") {
          alert("Error in getting trip data from the API.");
          setLoading(false);
        }
        else {
          const t = segmentTripEnergy(data.data);
          const arr = dutyCycleTelemetry.filter((o) => o.duty_cycle !== t.duty_cycle);
          arr.push(t)
          setDutyCycleTelemetry(arr);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        window.alert('Error in getting trip data from the API.')
        setLoading(false);
      });
      return () => controller.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectByDateRange, db, apiURL, props.user.token, selectedDutyCycle]);

  function _accessorForFilter(f: string) {
    let a = {accessor: "", max: 0};
    switch (f) {
      case "gasolineEnergy":
        a = { accessor: "gasolineEnergyPerSecond", max: 0.05 };
        break;
      case "dieselEnergy":
        a = { accessor: "dieselEnergyPerSecond", max: 0.10 };
        break;
      case "electricalEnergy":
        a = { accessor: "electricalEnergyPerSecond", max: 0.05 };
        break;
      default:
        break;
    }
    return a;
  }

  const selectedVehicle = () => {
    const arr = vehicles.filter((v: any) => v.vin === selectedVIN);
    return arr[0]
  }

  const segmentTripEnergy = (dc: any) => {
    let gasoline_diesel_cof = 1;
    let diesel_model_cof = 1;
    let cumulativeKWH = 0;
    let cumulativeKM = 0;
    const vehicle = selectedVehicle();
    if (vehicle.is_diesel === true) gasoline_diesel_cof = 1.3;
    else diesel_model_cof = 0.75;
    const t = {
      duty_cycle: 0,
      trip_id: '',
      segs: Array<any>(),
      telemetry: dc,
      km: 0,
      kwh: 0,
      ltrs: 0,
      maxElectricMJ: 0,
      maxGasolineMJ: 0,
      dieselMJ: 0,
      gasolineMJ: 0,
      electricalMJ: 0
    };
    let bl:any = null;
    dc.forEach((p: any, idx: number) => {
      if (idx === 0) {
        bl = p;
      }
      else {
        const seg:any = { 
          coords: [[bl.latitude, bl.longitude], [p.latitude, p.longitude]],
          cumulativeKWH: cumulativeKWH += p.syn_kwh_delta,
          cumulativeKM: cumulativeKM += p.km_delta,
          ...p};
        t.trip_id = p.trip_id;
        t.duty_cycle = selectedDutyCycle;
        t.km += p.km_delta;
        t.kwh += p.syn_kwh_delta;
        t.ltrs += p.ltr_delta;
        seg.electricalEnergyPerSecond = (p.syn_kwh_delta / p.ts_delta) * KWH_MJ * EV_MECHANICAL_EFFICIENCY;
        t.electricalMJ += p.syn_kwh_delta * KWH_MJ * EV_MECHANICAL_EFFICIENCY;
        t.maxElectricMJ = Math.max(t.maxElectricMJ, seg.electricalEnergyPerSecond);
        seg.gasolineEnergyPerSecond = (p.ltr_delta / p.ts_delta) * GASOLINE_MJ_LITER * GASOLINE_MECHANICAL_EFFICIENCY * gasoline_diesel_cof;
        t.gasolineMJ += p.ltr_delta * GASOLINE_MJ_LITER * GASOLINE_MECHANICAL_EFFICIENCY * gasoline_diesel_cof;
        t.maxGasolineMJ = Math.max(t.maxGasolineMJ, seg.gasolineEnergyPerSecond);
        seg.dieselEnergyPerSecond = (p.ltr_delta / p.ts_delta) * DIESEL_MJ_LITER * DIESEL_MECHANICAL_EFFICIENCY * diesel_model_cof;
        t.dieselMJ += p.ltr_delta * DIESEL_MJ_LITER * DIESEL_MECHANICAL_EFFICIENCY * diesel_model_cof;
        seg.kmpl = (p.km_delta / p.ltr_delta);
        seg.kmpkwh = (p.km_delta / p.syn_kwh_delta);
        seg.key = p.provider_id;
        t.segs.push(seg);
        bl = p;
      }
    });
    return t;
  }

  const calcEventLayerGeoJSON = (telemetry: Array<any>, accessor: any, options: any) => {
    const geoJSON = {type: 'FeatureCollection', features: Array<GeoJsonFeature>()};
    if(!tripStyle || tripStyle === 'none')return null;
    if (!telemetry) return null;
    let color = "#000000";
    if (typeof options !== 'undefined') {
      if (options.hasOwnProperty('color')) color = options.color;
    }
    const segs = Array<any>();
    telemetry.forEach((trip: any) =>{
      if(!trip.segs)return;
      trip.segs.forEach((s: any) => {
        segs.push(s);
      })
    });
    setEvents(segs);
    const bounds = _getBounds(segs, accessor.accessor, accessor.max);

    telemetry.forEach((trip: any) => {
      if(!trip.segs)return;
      trip.segs.forEach((s: any)=>{
        if(accessor && accessor.accessor !== "none"){
          color = interpolateColor(
            '#FFFF00',
            '#FF0000',
            s[accessor.accessor] / bounds.max);
        }
        const coords = [[s.coords[0][1], s.coords[0][0]], [s.coords[1][1], s.coords[1][0]]];
        geoJSON.features.push({
            "type": "Feature",
            "properties": {
              "id": uuidv4(),
              "value": s['filter'],
              "color": `${color}`,
              "segment": s
            },
            "geometry": {
              "type": "LineString",
              "coordinates": coords
            },
          });
      });
    });
    return geoJSON;
  };

  function _getBounds(data:Array<any>, accessor:string, max?:any) {
    let b0 = 0;
    let b1 = 0;
    data.forEach((d: any) => {
      b0 = Math.min(b0, d[accessor]);
      b1 = Math.max(b1, d[accessor]); 
    })
    if (max) b1 = Math.min(max, b1);
    if (isNaN(b0)) b0 = 0;
    if (isNaN(b1)) b1 = max ? max : 100;
    return { min: b0, max: b1 };
  }

  function _getMapCenter(data: any) {
    if (!data || data.length < 1) return [0, 0];
    let lat = 0;
    let lon = 0;
    let ct = 0;
    data.forEach((trip: any) => {
      trip.telemetry.forEach((d: any) => {
        lat += d.latitude;
        lon += d.longitude;
        ct++;
      })
    });
    if(ct > 0)return [lat / ct, lon / ct];
    return [0, 0];
  }

  function localStartSort(a:any, b:any) {
    if(a.local_start > b.local_start)return 1;
    if(a.local_start < b.local_start)return -1;
    return 0;
}

  const handleTableOnClick = (e: any) => {
    let t = dutyCycleTelemetry.slice();
    const arr = t.map((o) => { return o.duty_cycle });
    const idx = arr.indexOf(e);
    //remove if dc exists in array
    if (idx > -1) {
        t = t.filter((a) => a.duty_cycle !== e);
        setDutyCycleTelemetry(t);
        setLoading(false);
    }
    //else add it
    else{
        t.push({ duty_cycle: e, telemetry: [] });
        setDutyCycleTelemetry(t);
        setLoading(false);
        setSelectedDutyCycle(e);
    } 
    if (t.length === 1) history.push(`/dev-tools/duty-cycle-map/${e}`);
  }
  ///newDate is used for setting 7 day date range without changing initial date range(beginDate/endDate)
  let newDateRange = new Date(beginDate);
  let currentDate = new Date()
  const minDate = currentDate.setDate(currentDate.getDate() - 365);

  return (
    <S.DevToolsContentContainer>
      <S.DevToolsResponsiveTopContainer>
        <S.DevToolsResponsiveControls>
          
          {!props.selectByDateRange &&
            <S.DevToolsInputWrapper>
              <S.DevToolsInputLabel>Select Vehicle:</S.DevToolsInputLabel>
                  <VehicleDropdown
                    handleChange={(e: any) => handleVehicleChange(e)}
                    vehicles={vehicles}
                    selectedVIN={selectedVIN}
                  />
            </S.DevToolsInputWrapper>
          }
          <S.DevToolsInputWrapper>
            <S.DevToolsInputLabel>Select Dates:</S.DevToolsInputLabel>
            <S.DevToolsDatepickerContainer>
              <S.DevToolsDatepickers
                selected={beginDate}
                onChange={(date: Date) => props.setBeginDate(date)}
                selectsStart
                minDate={new Date(minDate)}
                maxDate={endDate}
                startDate={beginDate}
                endDate={props.selectByDateRange ? newDateRange.setDate(beginDate.getDate() + 7) : endDate}
                showMonthDropdown
                useShortMonthInDropdown
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, -7],
                    },
                  },
                ]}
              />
              <S.DevToolsDatepickers
                selected={props.selectByDateRange ? newDateRange : endDate}
                onChange={(date: Date) => props.setEndDate(date)}
                minDate={beginDate}
                maxDate={props.selectByDateRange ? newDateRange : endDate}
                selectsEnd
                startDate={beginDate}
                endDate={props.selectByDateRange ? newDateRange : endDate}
                showMonthDropdown
                useShortMonthInDropdown
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, -7],
                    },
                  },
                ]}
              />
            </S.DevToolsDatepickerContainer>
          </S.DevToolsInputWrapper>
          {!props.selectByDateRange &&
          <MapControls
              tripStyle={tripStyle}
              filter={energyFilter}
              setTripStyle={props.setTripStyle}
              setFilter={props.setFilter}
              disabled={props.selectByDateRange}
            /> }
          <S.DevToolsInputWrapper>
              <S.DevToolsInputLabel>
                <S.DevToolsInputCheckbox 
                  onChange={() => {setShowSatellite(showSatellite ? false : true)}}
                  checked={showSatellite}
                  type='checkbox' />
                Satellite View
              </S.DevToolsInputLabel>
          </S.DevToolsInputWrapper>
        </S.DevToolsResponsiveControls>
        
        {mapCenter &&
            <Suspense fallback={<></>}> 
                <DevToolMap tripStyle={tripStyle}
                    eventLayers={tripStyle === 'path' ? eventLayers : null}
                    popupHTML={SEGMENT_POPUP_HTML}
                    events={tripStyle === 'trace' ? events : null}
                    showMarkers={true}
                    center={mapCenter} 
                    showSatellite={showSatellite}
                    opacity={loading ? 0.6 : 1}
                    mapboxKey={props.secrets.mapboxKey}/>
            </Suspense>

        }

        </S.DevToolsResponsiveTopContainer>
        <DevToolsTable
            columns={TRIP_TABLE_COLUMNS}
            data={dutyCycles}
            handleSort={()=>{}}
            handleOnClick={handleTableOnClick}
            idAccessor={"duty_cycle"}
            selectedIds={dutyCycleTelemetry.map((t) => { return t.duty_cycle })}
          />
      </S.DevToolsContentContainer>
    );

}

function interpolateColor(c0: any, c1: any, f: number) {
  c0 = c0.replace('#', '');
  c1 = c1.replace('#', '');
  c0 = c0.match(/.{1,2}/g).map((oct: any) => parseInt(oct, 16) * (1 - f))
  c1 = c1.match(/.{1,2}/g).map((oct: any) => parseInt(oct, 16) * f)
  const ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255))
  const cn = ci.reduce((a, v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
  return (`#${cn}`)
}

function formatTimestamp(ts: string) {
  const r = ts.split('.');
  return `${r[0]}`;
}

function formatAPIDate(d: Date) {
  return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
}