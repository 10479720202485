import React, { useEffect, useState } from "react";
import Table from "../table";
import MapController from "./mapController";
import { CSVLink } from "react-csv";
import StandardizeTableData, {
  csvPercentHeaders,
  tablePercentHeaders,
} from "../standardizeTableData";
import PercentDropDown from "./percentDropDown";
import { getPeakKWHlocationsByPercent } from "../fetchCalls";
import NoResultsMessage from "../noResultsMessage";
import OnHoverExplanation from "../onHoverExplanation";
import Loading from "../loading";
export default function PercentView(props) {
  const [updatedLocations, setUpdatedLocations] = React.useState([]);
  const [hoverItems, setHoverItems] = useState({ id: null, x: null, y: null });
  const {
    handlePercentChange,
    locations,
    selectedPercent,
    allLocations,
    requestData,
    start,
    stop,
    mapCenter,
    setMapCenter,
    vclCount,
  } = props;

  useEffect(() => {
    if (vclCount >= 1000)
      window.alert(
        "Due to the size of your fleet, your results will be limited to the top 50 locations within this tab."
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setUpdatedLocations(["loading"]);
    if (
      locations &&
      locations.length > 0 &&
      start !== undefined &&
      stop !== undefined &&
      selectedPercent !== undefined
    ) {
      var limit = 1000;
      if (vclCount >= 1000) limit = 50;

      getPeakKWHlocationsByPercent(
        requestData,
        { start: start, stop: stop, pct: selectedPercent, lmt: limit },
        (resp) => setUpdatedLocations(resp)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPercent]);

  return (
    <div className="nrel-percentView">
      <OnHoverExplanation
        id={hoverItems.id}
        mouseCoords={[hoverItems.x, hoverItems.y]}
      />
      <div className="nrel-percView-wrapper">
        <h3 className="percView-headers">
          Projected EV Charging Locations at{" "}
          {
            <PercentDropDown
              handleChange={handlePercentChange}
              selectedPercent={selectedPercent}
            />
          }{" "}
          Fleet Electrification{" "}
          <span
            className="nrel-hover-icon"
            onMouseEnter={(e) =>
              setHoverItems({
                id: "selectPercent",
                x: e.pageX,
                y: e.pageY,
              })
            }
            onMouseLeave={(e) => setHoverItems({ id: null, x: null, y: null })}
          >
            {" "}
            ⓘ{" "}
          </span>
        </h3>
        <p className="nrel-mapTab-description">
          This map shows the locations where EVs are expected to charge
          overnight based on the driving patterns of the existing fleet
          vehicles. Select the fleetwide EV adoption target in the dropdown to
          see where charging will be needed to support the electrification of
          the top X% of vehicles in your fleet, based on their ezEV score. Light
          duty passenger vehicles up to ¾ ton are included in the map (i.e.
          sedans, SUVs, pickups, minivans, and cargo-vans). Select a location on
          the map for additional information.
        </p>
        <CSVLink
          className="nrel-download-btn"
          id="perc"
          data={StandardizeTableData(updatedLocations, "percent")}
          headers={csvPercentHeaders()}
          target="_blank"
          filename={`sawatch-labs-NREL-Fleet-Electrification (${selectedPercent}%)-table.csv`}
        >
          <p>Download CSV</p>
        </CSVLink>
      </div>
      {locations.length > 0 ? (
        <div className="nrel-percent-table-wrapper">
          {updatedLocations.length > 0 && updatedLocations[0] !== "loading" ? (
            <Table
              columns={tablePercentHeaders()}
              selectLocation={props.selectLocation}
              data={StandardizeTableData(updatedLocations, "percent")}
            />
          ) : (
            <Loading height={"10rem"} width={"10rem"} />
          )}
        </div>
      ) : (
        <NoResultsMessage selectedPercent={selectedPercent} />
      )}
      <MapController
        mapCenter={mapCenter}
        setMapCenter={setMapCenter}
        zoom={props.zoom}
        setMapZoom={props.setMapZoom}
        locations={updatedLocations[0] === "loading" ? [] : updatedLocations}
        allLocations={allLocations}
        target={props.target}
        handleLocationChange={props.handleLocationChange}
      />
    </div>
  );
}
