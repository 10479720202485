import React, { useEffect, useState, useMemo } from "react";
import "@sawatchlabs/swt-components/dist/index.css";
import { DoublePaneMultiSelectDropdown } from "@sawatchlabs/swt-components";
import * as S from '../../../../styles/core-styles/AdminTools-styles';

export default function UpdateDatabaseModal(props) {

  const [removalDBs, setRemovalDBs] = useState([]);
  const [addingDBs, setAddingDBs] = useState([]);
  const [items, setItems] = useState();
  const [selectedItems, setSelectedItems] = useState();
  const [newDbList, setNewDbList] = useState()
  const { selectedUser, databases } = props;


  useMemo(() => {
    const newList = []
    selectedUser.databases.forEach((d) => {
      if (!databases.find((db) => db.name === d)) {
        return;
      } else {
        newList.push(databases.find((db) => db.name === d));
      }
    })
    const formattedList = newList.map((i) => {return {id: i.name, label: i.name, disabled: false, display_name: i.display_name, dashboard_version: i.dashboard_version}})
    setSelectedItems(formattedList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedUser]) 
  
  useEffect(() => {
    let array = [];
      databases.map((d) => {
        let obj = {
          id: d.name,
          label: d.name,
          display_name: d.display_name,
          disabled: false,
          dashboard_version: d.dashboard_version
        };
       return array.push(obj);
      });
      return setItems(array);
  },[databases])

  function handleSubmit(e) {
    e.preventDefault();
    if (removalDBs.length > 0) {
      const formattedData = removalDBs.map((db) => {
        return { email: selectedUser.email, database: db.id, account: 'sawatch' };
      });
      props.removeDBs(formattedData);
    }
    if (addingDBs.length > 0) {
      const formattedData =  addingDBs.map((db) => {
        return { email: selectedUser.email, database: db.id, account: 'sawatch' };
      });
      props.updateDBs(formattedData)
    }
    //If user is updating their own dbs then we need to update the db dropdown in the header. 
    //If user is adding another user to a db then the db will appear in the dropdown when they log in.
    if(props.user.email === props.selectedUser.email) {
      props.updateDatabases(newDbList)
    }
    props.handleClose();
  }

  function checkForChanges(selectedDbs) {
    // Checks for changes of whats currently on the server
    const initialDbs = selectedItems.map((db) => {return db})
    const updatedDbs = selectedDbs.map((d) => {return d})
    if (updatedDbs.length > 0) {
      setNewDbList(updatedDbs)
    }

    let addedDbs = updatedDbs.filter((db) => {
      return !initialDbs.some((i) => {
        return db.id === i.id;
      })
    })
    let removedDbs = initialDbs.filter((db) => {
      return !updatedDbs.some((i) => {
        return db.id === i.id;
      })
    })
    if (removedDbs.length > 0) {
      setRemovalDBs(removedDbs);
    }
    if (addedDbs.length > 0) {
      setAddingDBs(addedDbs);
    }  
  }
  
  if (databases.length === 0) return null;
  return (
     <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={props.handleClose}>Close</S.ModalCloseButton>
        <S.ModalHeader> Update <b>{props.selectedUser.email}</b> Database Connections</S.ModalHeader>
        <S.DatabaseModalForm onSubmit={handleSubmit}>
          <S.DatabaseModalFormInnerWrapper>
            {selectedItems === 'undefined' ? null :
            <DoublePaneMultiSelectDropdown
              showSelectAll={false}
              items={items}
              selectedItems={selectedItems}
              onChange={checkForChanges}

            />
            }
          </S.DatabaseModalFormInnerWrapper>
          <button type="submit" className="swt-submit-button">Submit</button>
        </S.DatabaseModalForm>
      </S.ModalContainer>
      </S.ModalDisplay>
  );
}
