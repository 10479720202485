export function processApiResponse(userSettings, resp) {
    if(!resp)return;
    const timestamps = ['local_start', 'local_stop', 'utc_start', 'utc_stop']

    Object.keys(resp).forEach((k) => {
        if(timestamps.includes(k)){
            let ts = formatTimestamp(userSettings, resp[k])
            resp[k] = ts
        }
    })
    return resp;
  }

function formatTimestamp(settings, value){

    // TODO: build out this function when handle timestamp issues
    // Remember to remove tableDateFormat function to prevent double formatting
    if(!value)return value;
    return value;
}