import React from "react";
import Controls from "./Controls";
import DetailPanel from "./DetailPanel";
import TableView from "./TableView";
import { CSVLink } from "react-csv";
import styled from "styled-components/macro";
import * as S from "../../styles/fueln-styles/Containers";

const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
`;

const TableNote = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
`;

const DownloadButton = styled.div`
    width: 146px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 44px;
    border: 1px solid rgb(187, 187, 187);
    background-color: #13908b;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    text-decoration-thickness: 0;
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .56px;
    text-align: center;
    color: white;
    text-decoration-color: white;
    &:hover{
      box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 18%);
    }
  `;

export default function DetailTableView(props){
    const beginDate = props.beginDate ?? new Date();
    const endDate = props.endDate ?? new Date();
    const events = props.events ?? [];
    const stats = props.stats ?? [];
    const setBeginDate = props.setBeginDate ?? function(e){};
    const setEndDate = props.setEndDate ?? function(e){};
    const group = props.group ?? {};
    const groups = props.groups ?? [];    
    const handleGroupChange = props.handleGroupChange ?? function(e){};
    const vehicleClasses = props.vehicleClasses ?? [];
    const selectedVehicleClasses = props.selectedVehicleClasses ?? [];
    const setSelectedVehicleClasses = props.setSelectedVehicleClasses ?? function(d){};
    stats.forEach((s) => {
      s.gallons_can = (s.snr_liters*.26).toFixed(1);
      s.gallons_pct = (s.liters_by_pct*.26).toFixed(1);    
      if(typeof s.start_level === "number")s.start_level = s.start_level.toFixed(1);
      if(typeof s.stop_level === "number")s.stop_level = s.stop_level.toFixed(1);
    })
    const tableColumns = [
        {Header: "UTC", accessor: "utc_start", sortType: "basic"},
        {Header: "Address", accessor: "address", sortType: "basic"},
        {Header: "Asset Id", accessor: "asset_id", sortType: "basic"},
        {Header: "Year", accessor: "year", sortType: "basic"},
        {Header: "Make", accessor: "make", sortType: "basic"},
        {Header: "Model", accessor: "model", sortType: "basic"},
        {Header: "Est Gallons (CAN bus)", accessor: "gallons_can", sortType: "basic"},
        {Header: "Est Gallons (Tank Level)", accessor: "gallons_pct", sortType: "basic"},
        {Header: "Start %", accessor: "start_level", sortType: "basic"},
        {Header: "Stop %", accessor: "stop_level", sortType: "basic"},
      ];

      const colType = (col) => {
        if(col === 'Asset Id' || col === 'Model'){
          return "fueln-table-header-long";
        }
        return "fueln-table-header";
      }
  
      const highlight = (cell) =>{
        if(!isNaN(parseInt(cell.column.Header))){
          //if(cell.value > 2)return "speedn-table-cell-highlight";
        }
        return "";
      }
    const csvHeaders = tableColumns.slice();
    csvHeaders.forEach((h) => {h.key = h.accessor; h.label = h.Header});

    return(
        <>
            <S.FuelnTopContainer>
            <   S.TopInfoContainer>
                    <DetailPanel 
                        beginDate={beginDate}
                        endDate={endDate}
                        events={events}
                        stats={stats}
                    />
                </S.TopInfoContainer>
                <Controls 
                    beginDate={beginDate}
                    endDate={endDate}
                    setBeginDate={function(e){setBeginDate(e)}}
                    setEndDate={function(e){setEndDate(e)}}
                    group={group}
                    groups={groups}
                    handleGroupChange={function(e){handleGroupChange(e)}}
                    vehicleClasses={vehicleClasses}
                    selectedVehicleClasses={selectedVehicleClasses}
                    updateSelectedClasses={function (data) { setSelectedVehicleClasses(data) }}
                />
            </S.FuelnTopContainer>
            <S.FuelnTableContainer>
                <TableWrapper>
                    <TableNote>Fueling Event</TableNote>
                    <TableView 
                      columns={tableColumns}
                      data={stats}
                      colType={colType}
                      handleOnClick={function(e){console.log('click')}}
                      highlight={highlight}
                      nolink={true}
                      handleSort={function(){}}
                    />
                    <CSVLink
                      id="emit-rpt"
                      data={stats}
                      headers={tableColumns}
                      target="_blank"
                      filename={"sawatch-labs-fuel-detail-rpt.csv"}
                      style={{ textDecoration: "none" }}
                    > 
                      <DownloadButton>
                          Download CSV
                      </DownloadButton>
                    </CSVLink>
                </TableWrapper>
            </S.FuelnTableContainer>
        </>
    );
}