import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => (
  <ReactLoading
    className={'swt-emit-loading'}
    type={'bars'}
    color={'rgb(163, 165, 168)'}
    viewBox="0 0 8 8"
    width="8"
    height="8"
  />
);

export default Loading;