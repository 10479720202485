import React from "react";
import { numberWithCommas } from "./install-data-fomatter";

export default function InstallSummary(props) {
  const { targetTotals } = props;

  const setInstallStyling = () => {
    if (targetTotals) {
      if (
        targetTotals.targetInstallsComplete - targetTotals.installed > 1 &&
        targetTotals.installed < targetTotals.targetInstallsComplete
      )
        return "behind";
      if (targetTotals.installed > targetTotals.targetInstallsComplete)
        return "ahead";
    } else return "neutral";
  };

  const neededInstallBlurb = () => {
    const pendingInstalls =
      targetTotals.targetInstalls - targetTotals.installed;
    const installsPerDay = targetTotals.installsToCatchUp;
    if (
      pendingInstalls > 1 &&
      targetTotals.installed < targetTotals.targetInstallsComplete &&
      targetTotals.remainingDOW > 30
    ) {
      return (
        <p>
          In order to meet your target, you need to install approximately{" "}
          <span id="install-bold">{Math.round(installsPerDay)}</span> devices
          per weekday.
        </p>
      );
    }
    if (
      pendingInstalls > 1 &&
      targetTotals.installed < targetTotals.targetInstallsComplete
    ) {
      var remainingDays = `in the next ${targetTotals.remainingDOW} workdays.`;
      if (targetTotals.remainingDOW === 1) remainingDays = "today.";
      if (targetTotals.remainingDOW < 1) remainingDays = "as soon as possible.";
      return (
        <p>
          In order to meet your target, you need to install{" "}
          <span id="install-bold">{pendingInstalls}</span> devices{" "}
          {remainingDays}
        </p>
      );
    }
  };
  return (
    <div className="summary-wrapper">
      <h2 id="sum-title">
        Install Summary: <span>{targetTotals.groupName}</span>
      </h2>
      <div className="sum-pct-complete" id={setInstallStyling()}>
        <h3>Current Install Status:</h3>
        <div className="install-pct-wrpr">
          <span id="sum-value-pct">
            {targetTotals.actualInstallsPercComplete}
          </span>{" "}
          <span id="sum-unit">%</span>
          <p>Complete</p>
        </div>
        <p id="sum-descriptor">
          Average Installs per Weekday:{" "}
          <span id="sum-value">
            {numberWithCommas(targetTotals.averageInstalls)}
          </span>{" "}
          <span id="sum-unit">vcls</span>
        </p>
        <p id="sum-descriptor">
          Devices Installed:{" "}
          <span id="sum-value">
            {numberWithCommas(targetTotals.installed)} /{" "}
            {numberWithCommas(targetTotals.targetInstalls)}
          </span>{" "}
          <span id="sum-unit">vcls</span>
        </p>
        <p id="sum-descriptor">
          No Shows:{" "}
          <span id="sum-value">{numberWithCommas(targetTotals.noShows)}</span>{" "}
          <span id="sum-unit">vcls</span>
        </p>
      </div>
      <div className="install-summary-text">
        <h3>Target Install Status:</h3>
        <div className="install-pct-wrpr">
          <span id="sum-value-pct">
            {targetTotals.targetInstallsPercComplete}
          </span>{" "}
          <span id="sum-unit">%</span>
          <p>Complete</p>
        </div>
        <p id="sum-descriptor">
          Average Target Installs per Weekday:{" "}
          <span id="sum-value">
            {numberWithCommas(targetTotals.averageTargetPerDay)}
          </span>{" "}
          <span id="sum-unit">vcls</span>
        </p>
        <p id="sum-descriptor">
          Device Install Target:{" "}
          <span id="sum-value">
            {numberWithCommas(targetTotals.targetInstallsComplete)} /{" "}
            {numberWithCommas(targetTotals.targetInstalls)}
          </span>{" "}
          <span id="sum-unit">vcls</span>
        </p>
      </div>
      <div className="install-catchup-blurb">{neededInstallBlurb()}</div>
    </div>
  );
}
