import React from "react";
import StatBox from "./StatBox";
import styled from "styled-components/macro";

const DetailTitle = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 35px;
`;

const SubTitle = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
`;

const StatBoxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 25px;
`;

export default function SummaryPanel(props: any){
    const beginDate = props.beginDate ?? new Date();
    const endDate = props.endDate ?? new Date();

    const vinSet = new Set(props.stats.map((r: any) => {return r.vin}));

    let gallons = 0;
    props.stats.forEach((s: any) => {
        gallons += Math.round((s.snr_liters * 0.26));
    })
    return(
        <>
            <DetailTitle>
                {`Fueling Events Report: ${(beginDate) ? beginDate.toLocaleDateString("en-US"): '--'} - ${(endDate) ? endDate.toLocaleDateString("en-US"): '--'}`}
            </DetailTitle>
            <SubTitle>
                For all events. Inclusive of depot fueling.
            </SubTitle>
            <StatBoxContainer>
                <StatBox 
                    value = {props.stats.length}
                    subtitle = "Events"
                />
                <StatBox 
                    value = {`${vinSet.size}/8`}
                    subtitle = "Vehicles In Range/Total Vehicles"
                />
                <StatBox 
                    value = {gallons}
                    subtitle = "Est. Gallons of Fuel"
                />
            </StatBoxContainer>
        </>
    );
}