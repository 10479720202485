import React from 'react';
import Table from './Table';
import { numberWithCommas } from './DataFormatter';

export default function TableController(props) {
  const checkifSelected = (type, input) => {
    if (type === undefined) return;
    if (type === 'vehicles') {
      let selectedVehicles = props.vehicleUtil.filter(v => v.selected === true);
      if (selectedVehicles.some(vehicle => vehicle.vin === input)) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
    if (type === 'locations') {
      let selectedLocations = props.locations.filter(l => l.selected === true);
      var location = selectedLocations.map(l => {
        return l.parking_location;
      });
      if (selectedLocations.some(location => location === input)) {
        return 'selected';
      }
      if (location.some(location => location === input)) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
  };

  const standardizeData = (input, type) => {
    if (type === 'vehicles') {
      const tableData = [];
      input.map(v => {
        const tObj = {
          type: 'vehicle',
          uid: v.vin,
          km: v.km,
          miles: numberWithCommas(Math.round(v.ttlMiles)),
          class: v.vehicle_class,
          model: v.model,
          make: v.make,
          year: v.year,
          assetID: v.assetID,
          vin: v.vin,
          days: v.days,
          trips: v.trips,
          dutyCycles: v.dutyCycles,
          hours: `${Math.round(v.operationalHrs)}`,
          homebase: v.parking_loc,
          address: v.address,
          avg_util: `${Math.round(v.avgUtilization * 100)}%`,
          selected: checkifSelected(type, v.vin),
          btn: '',
          graphData: v.graphData,
          annual_tco: v.annual_tco,
          operationalHrs: v.operationalHrs,
          obj: v,
          odometer: numberWithCommas(Math.round(v.odometer)),
          activeDcs: v.activeDcs,
          idle_hours: v.idle_hours
        };
        return tableData.push(tObj);
      });
      return tableData;
    }
    if (type === 'locations') {
      const tableData = [];
      input.map(l => {
        const obj = {
          type: 'location',
          uid: l.parking_location,
          selectedCount: l.selectedLocCount,
          count: l.count,
          address: l.address,
          latitiude: l.latitude,
          longitude: l.longitude,
          selected: checkifSelected(type, l.parking_location),
          obj: l
        };
        return tableData.push(obj);
      });
      return tableData;
    } else return null;
  };

  if (props.consumer === 'vehicles') {
    const columns = [
      {
        Header: 'vcl',
        accessor: 'selected',
        sortType: 'basic'
      },
      {
        Header: 'Asset ID',
        accessor: 'assetID'
      },
      {
        Header: 'Year',
        accessor: 'year'
      },
      {
        Header: 'Make',
        accessor: 'make'
      },
      {
        Header: 'Model',
        accessor: 'model'
      },
      {
        Header: 'Class',
        accessor: 'class'
      },
      {
        Header: 'Active',
        accessor: 'avg_util',
        sortType: 'alphanumeric'
      },
      {
        Header: 'Miles',
        accessor: 'miles',
        sortType: 'basic'
      },
      {
        Header: 'Days',
        accessor: 'days',
        sortType: 'basic'
      },
      {
        Header: 'Trips',
        accessor: 'trips',
        sortType: 'basic'
      },
      {
        Header: 'Duty Cycles',
        accessor: 'dutyCycles',
        sortType: 'alphanumeric'
      },
      {
        Header: 'Hours',
        accessor: 'hours',
        sortType: 'basic'
      },
      {
        Header: 'Odometer',
        accessor: 'odometer',
        sortType: 'alphanumeric'
      },
      {
        Header: 'Homebase',
        accessor: 'address'
      },
      {
        Header: ' ',
        accessor: 'btn'
      }
    ];
    return (
      <Table
        columns={columns}
        handleChange={props.handleVehicleSelect}
        data={standardizeData(props.vehicleUtil, 'vehicles')}
        handleToggleClick={props.handleToggleClick}
        setMapCenter={props.setMapCenter}
        sortType={{ id: 'assetID', desc: false }}
      />
    );
  }

  if (props.consumer === 'location') {
    const columns = [
      {
        Header: 'loc',
        accessor: 'selected'
      },
      {
        Header: 'Location',
        accessor: 'address'
      },
      {
        Header: 'Selected Vehicles',
        accessor: 'selectedCount'
      },

      {
        Header: 'Total Vehicles',
        accessor: 'count'
      }
    ];
    return (
      <Table
        columns={columns}
        setMapCenter={props.setMapCenter}
        handleChange={props.handleLocationChange}
        handleToggleClick={props.handleToggleClick}
        sortType={{ id: 'address', desc: false }}
        data={
          props.consumer === 'location'
            ? standardizeData(props.locations, 'locations')
            : standardizeData(props.vclUtil, 'vehicles')
        }
      />
    );
  }
}
