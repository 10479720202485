import React from 'react';
import TableController from './TableController';
import Toggle from './Toggle';
import Loading from './loading';
export default function VehiclesPage(props) {
  const [showSelected, setShowSelected] = React.useState(false);

  const selectedVcls = () => {
    const selected = props.updatedVcls.filter(v => v.selected === true);
    return selected;
  };

  function handleVclTableToggleClick() {
    let vcls = props.vehicleUtil;
    if (vcls.some(e => e.selected === false)) {
      vcls.map(l => (l.selected = true));
    } else {
      vcls.map(l => (l.selected = false));
    }
    props.updateState(vcls, 'vehicleUtil');
  }

  const loadingLogic = () => {
    return props.loading ? (
      <Loading />
    ) : (
      <p className="sftd-noData-Msg">
        There are currently no vehicles selected. Please select a location or
        vehicles from the filters above.
      </p>
    );
  };
  return (
    <div className="sftd-vcl-table-page">
      <h1 className="sftd-page-title">Summary Vehicle Metrics</h1>
      <Toggle
        className="sftd-vcl-toggle"
        showSelected={showSelected}
        handleToggleChange={() => setShowSelected(!showSelected)}
        unselectedText={
          props.updatedVcls.length !== 0
            ? `Show All (${props.updatedVcls.length}) Vehicles`
            : 'Show All Vehicles'
        }
        selectedText={` Show Selected (${selectedVcls().length}) Vehicles`}
      />
      <hr className="sftd-ttl-line" />
      {props.avgTotalUtil !== undefined ? (
        <p className="sftd-vcl-tble-util-txt">
          Based on the current vehicle selection, the current utilization is:{' '}
          <span id="perc">
            {' '}
            {isNaN(Math.round(props.avgTotalUtil.avgSelectedUtil * 100))
              ? 0
              : Math.round(props.avgTotalUtil.avgSelectedUtil * 100)}
          </span>
          %
        </p>
      ) : null}
      <div className="sftd-vcl-table-wrapper">
        {false && <div className="sftd-table-blinder-left" />}
        {false && <div className="sftd-table-blinder-right" />}
        {props.updatedVcls.length === 0 ? (
          loadingLogic()
        ) : (
          <>
            <TableController
              vehicleUtil={showSelected ? selectedVcls() : props.updatedVcls}
              consumer={'vehicles'}
              handleVehicleSelect={props.handleVehicleSelect}
              handleToggleClick={handleVclTableToggleClick}
            />
          </>
        )}
      </div>
    </div>
  );
}
