import React from "react";
import Chart from "react-google-charts";
import Loading from "./install-loading";
export default function GanttChart(props) {
  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];


  const ganttData = () => {
    if (props.group === "GroupCompanyId") return props.vclData;
    else {
      return props.vclData.filter((g) => g.groupId === props.group);
    }
  };
  const cgIndex = props.vclData.findIndex(x => x.groupId ==="GroupCompanyId");
  const rows2 = ganttData().map((g, i) => {
    const start = g.targetStart === null ? props.vclData[cgIndex].targetStart : g.targetStart;
    const end = g.targetEnd === null ? props.vclData[cgIndex].targetEnd : g.targetEnd;
    const targetInstalls =
      g.targetInstalls === 0 ? g.installedApi : g.targetInstalls;
    return [
      i,
      g.groupNameUser,
      i,
      new Date(start),
      new Date(end),
      null,
      Math.round((g.installedApi / targetInstalls) * 100),
      g.dependency,
    ];
  });

  if (props.vclData.length === 0) return <Loading />;
  return (
    <div className="install-gantt">
      <Chart
        chartType="Gantt"
        loader={<Loading />}
        data={[columns, ...rows2]}
        options={{
          height: 650,
          gantt: {
            backgroundColor: {
              fill: "transparent",
            },
            trackHeight: 28,
            criticalPathEnabled: false,
            shadowEnabled: false,
            shadowColor: "white",
            shadowOffset: 2,
            labelStyle: {
              fontName: "Open Sans",
              fontSize: 14,
              fontColor: "black",
            },
            palette: [
              {
                dark: "#4e6985",
                color: "#5797b6",
                light: "#d9d4cc",
              },
            ],
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}
