import React from "react";
import { numberWithCommas, standardizePrecision } from "./dataFormatter";

export default function OverallStats(props) {
  const { overallStats, assumptions } = props;

  // NOTE this component shows recs only for modified, all for overall!
  let ttl_GHG = 0;
  let ttl_fuelUsg = 0;
  let ttl_tco = 0;
  let ttl_numbOfEVRecs = 0;

  // Makes this component reusable for both State and Uni Dash
  let ttl = !overallStats.statsData ? _sumTotals() : overallStats.statsData;
  function _sumTotals() {
    ttl_GHG = 0;
    ttl_fuelUsg = 0;
    ttl_tco = 0;
    ttl_numbOfEVRecs = 0;
    if (overallStats && overallStats.length > 0) {
      overallStats.forEach((v) => {
        
        if (
          v.overall > 84 &&
          (v.recommendation.includes("BEV") ||
            v.recommendation.includes("PHEV"))
        ) {
          ttl_numbOfEVRecs++;
          ttl_GHG += standardizePrecision( v.rec_lt_ghg_reduction_lbs);
          ttl_fuelUsg += standardizePrecision(v.rec_lt_gals_saved);
          // Multiply times LT
          ttl_tco += standardizePrecision(v.rec_yr_tco_reduction) * assumptions.life_cycle;
        }
      });
    }
    return {
      ghg: ttl_GHG,
      fuel: ttl_fuelUsg,
      tco: ttl_tco,
      numbOfEVRecs: ttl_numbOfEVRecs,
    };
  }

  function _arrowDecider(input, type) {
    input = Math.round(input);
    if (input === 0) return undefined;
    if (type === "tco" ? input > 0 : input < 0) {
      return (
        <img
          className="nrel-indicator-arrow"
          src="https://images.sawatchlabs.com/ic-arrow-asc-red-30px-17px.png"
          alt="Incresed Indicator arrow"
        />
      );
    } else if (type === "tco" ? input < 0 : input > 0) {
      return (
        <img
          className="nrel-indicator-arrow"
          src="https://images.sawatchlabs.com/ic-arrow-desc-green-30px-19px.png"
          alt="Decreased Indicator arrow"
        />
      );
    } else return;
  }

  return (
    <div className="nrel-overallStats-wrpr">
      <h4 className="nrel-overallStats">{props.title}</h4>
      <div className="nrel-stats-wrapper">
        <p className="nrel-tco">
          TCO*
          <br />
          <span className="nrel-value" id={ttl.tco > 0 ? "inc" : "dec"}>
            {_arrowDecider(Math.round(ttl.tco), "tco")}$
            {numberWithCommas(Math.round(ttl.tco)) === "0"
              ? "-"
              : numberWithCommas(Math.round(ttl.tco))}
            <span className="nrel-unit"> USD</span>
          </span>
        </p>
        <p className="nrel-ghg">
          GHG <br />
          <span className="nrel-value" id={ttl.ghg < 0 ? "inc" : "dec"}>
            {_arrowDecider(Math.round(ttl.ghg), "ghg")}
            {numberWithCommas(Math.round(ttl.ghg)) === "0"
              ? "-"
              : numberWithCommas(Math.round(ttl.ghg))}{" "}
            <span className="nrel-unit">lbs</span>
          </span>
        </p>
        <p className="nrel-fuel">
          Fuel <br />
          <span className="nrel-value" id={ttl.fuel < 0 ? "inc" : "dec"}>
            {_arrowDecider(Math.round(ttl.fuel), "fuel")}
            {numberWithCommas(Math.round(ttl.fuel)) === "0"
              ? "-"
              : numberWithCommas(Math.round(ttl.fuel))}{" "}
            <span className="nrel-unit">gals</span>
          </span>
        </p>
        <p className="nrel-vcls">
          # of Recs
          <br />
          <span className="nrel-value" id="vcl">
            {numberWithCommas(ttl.numbOfEVRecs) === "0"
              ? "-"
              : numberWithCommas(ttl.numbOfEVRecs)}{" "}
            <span className="nrel-unit">vcls</span>
          </span>
        </p>
      </div>
      <span className = 'evse-note'>*The TCO does not include the cost to install EVSE because these costs are not one-to-one for each vehicle and they can vary greatly based on the specifics of each site.</span>
    </div>
  );
}
