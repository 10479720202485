import { tableDateFormat } from "./speedn-csv-helper-functions"

export function vehicleIdDisplay (vcl) {
  if(vcl) {
  if (vcl.asset_id) return vcl.asset_id
  if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
  if (vcl.vin) return vcl.vin
  }
}

export const formatCellData = (cell, rowValues) => {
    if (cell.column.Header === 'Date/Time') {
      return tableDateFormat(cell.value)
    }
    if(cell.column.Header === 'Asset Id') {
      return vehicleIdDisplay(rowValues);
    }
    if (cell.value === null || cell.value === undefined) {
      return <div>-</div>
    }
    return cell.render('Cell')
  }