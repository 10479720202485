import React from 'react';
import OutsideAlerter from './OutsideClickNotifier';
import { formatTime } from './DataFormatter';

export default function TimeSelector(props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <OutsideAlerter handleClick={() => setShowModal(false)}>
      <div
        className="sftd-time-selector"
        id={showModal ? 'clicked' : 'uncliked'}
      >
        <h3
          className="sftd-time-btn-ttl"
          // onClick={() => setShowModal(!showModal)}
        >
          {formatTime(props.startTime)} - {formatTime(props.stopTime)}
        </h3>
        {showModal ? (
          <div className="sftd-time-modal">
            <form className="sftd-start-time">
              <label className="sftd-time-modal-title" id="start">
                START
              </label>
              <br />
              <input
                className="sftd-time-input"
                step={3600}
                type="time"
                onChange={e =>
                  props.handleTimeChange(e.target.value, 'startTime')
                }
                value={props.startTime}
              />
            </form>

            <form className="sftd-stop-time">
              <label className="sftd-time-modal-title" id="stop">
                FINISH
              </label>
              <br />
              <input
                className="sftd-time-input"
                type="time"
                step={3600}
                onChange={e =>
                  props.handleTimeChange(e.target.value, 'stopTime')
                }
                value={props.stopTime}
              />
            </form>
          </div>
        ) : null}
      </div>
    </OutsideAlerter>
  );
}
