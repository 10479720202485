import React from 'react';
import { DatePicker } from "@sawatchlabs/swt-components";
import * as ColorScheme from "../../styles/ionev-styles/ColorScheme";

const DATE_BOUND_BUFFER = 6;

export default function DateSelectors(props){
  const {startDate, endDate, minDate, maxDate, disabled, userSettings} = props;

  const getMinForMaxSelector = () => {
    if (startDate?.isValid) {
      return startDate.plus({days: DATE_BOUND_BUFFER});
    }
  }
      
  const getMaxForMinSelector = () => {
    if (endDate?.isValid) {
      return endDate.minus({days: DATE_BOUND_BUFFER});
    }
  }

  return (
    <div className="ionev-header-date-selector-wrapper">
      <p className="ionev-header-selector-label">Select Period</p>
      <div className="ionev-header-date-picker-wrapper">
        <DatePicker
          inputDisabled={disabled}
          selected={startDate}
          minDate={minDate?.toUTC()} // MUI DatePicker needs its dates in UTC or else there will be weird side effects, but the fleet start date is in ISO with an offset, so convert to UTC.
          maxDate={getMaxForMinSelector()}
          placeholder={"N/A"}
          textFieldBackgroundColor={"white"} 
          onAccept={(e) => props.handleChange('startDate', e)}
          popperPlacement={"bottom"}
          dateFormat={userSettings.date_display}
          highlightColor={`${ColorScheme.bluish}d0 !important`}
          highlightHoverColor={`${ColorScheme.bluish} !important`}
          textFieldMarginRight={"10px"}
        />
        <DatePicker
          inputDisabled={disabled}
          selected={endDate}
          minDate={getMinForMaxSelector()}
          maxDate={maxDate?.toUTC()} // MUI DatePicker needs its dates in UTC or else there will be weird side effects, but the fleet start date is in ISO with an offset, so convert to UTC.
          placeholder={"N/A"}
          textFieldBackgroundColor={"white"}
          onAccept={(e) => props.handleChange('endDate', e)}
          popperPlacement={"bottom"}
          dateFormat={userSettings.date_display}
          highlightColor={`${ColorScheme.ionev_blue} !important`}
          highlightHoverColor={`${ColorScheme.ionev_blue} !important`}
          />
      </div>
    </div>
  );
}