import React, {useState, Suspense} from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';
import {SearchSelect} from '@sawatchlabs/swt-components';

const CreateLocationGroupMap = React.lazy(() => import('./CreateLocationGroupMap'));

const ALPHANUMERIC_REGEX = "^[\\w\\- ]*$"
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters '_' , '-' are also allowed.";

export default function CreateLocationGroupModal(props){
    const {setShowCreateGroupLocationModal, user, selectedLocations, createNewGroupLocation, selectedLocationsIds} = props;

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [landuse, setLanduse] = useState('');
    const [newLongitude, setNewLongitude]= useState('');
    const [newLatitude, setNewLatitude] = useState('');
    const [nickname, setNickname] = useState('');
    const [region, setRegion] = useState('');
    const [newLocError, setNewLocError] = useState(false);
    const [existingLocation, setExistingLocation] = useState(null);


    function handleSubmit (e){
        e.preventDefault();
        let groupData = {
            address: address,
            landuse: landuse === '' ? 'Unclassified' : landuse,
            longitude: newLongitude === '' ? selectedLocations[0].longitude : Number(newLongitude),
            latitude: newLatitude === '' ? selectedLocations[0].latitude : Number(newLatitude),
            nickname: nickname,
            city: city,
            region: region,
            location_ids: selectedLocationsIds
        }
        createNewGroupLocation(groupData)
    }

    function handleOptionClick(id) {
      let loc = selectedLocations.find((l) => {return l.uuid === id});
      if(loc) {
        setNickname(loc.nickname ? loc.nickname : '');
        setLanduse(loc.landuse ? loc.landuse : 'Unclassified');
        setAddress(loc.address);
        setCity(loc.city);
        setRegion(loc.region);
        setExistingLocation(loc);
      }
    }

    return (
        <S.ModalDisplay>
        <S.ModalContainer>
            <S.ModalCloseButton onClick={() => setShowCreateGroupLocationModal(false)}>Close</S.ModalCloseButton>
            <S.ModalHeader>Create New Group</S.ModalHeader>
            <S.ModalForm onSubmit={(e) => handleSubmit(e)}>

            <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Use Selected Location Info (optional):</S.ModalFormInputLabel>
            <SearchSelect 
              width='278px'
              borderColor='rgb(187, 187, 187)'
              borderRadius='5px'
              arrowPositionFromRight='-6px'
              height='40px'
              optionHeight="75px"
              color='rgb(71, 72, 74)'
              valueTextIndent={'-10px'}
              onChange={(e) => handleOptionClick(e.value)}
              menuShouldScrollIntoView={false}
              value={existingLocation ? {value: existingLocation.uuid, label: `${existingLocation.uuid} - ${existingLocation.address}`} : {label: 'Select a Location'}}
              options={selectedLocations.map((loc) => {
                return ({value: loc.uuid, label: `${loc.uuid} - ${loc.address}`})
              })}
            />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Nickname:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    />
            </S.ModalFormInputWrapper>
            
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Location Type:</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  onChange={(e) => setLanduse(e.target.value)}
                  value={landuse}
                >
                <option>Unclassified</option>
                <option>Fleet</option>
                <option>Public</option>
                <option>Residential</option>
                <option>Workplace</option>
                </S.ModalFormSelect>
              </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*Address:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
            </S.ModalFormInputWrapper>
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*City:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
                </S.ModalFormInputWrapper>
                <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*State/Region:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
            
            </S.ModalFormInputWrapper>
            <S.UpdateLocationMapContainer>
            <S.UpdateLocationMapText>Select Group Location</S.UpdateLocationMapText>
            <S.UpdateLocationMapSubText>Click on the map to select the lat/lon coordinates for this group.</S.UpdateLocationMapSubText>
            <S.UpdateLocationMapSubText>The group's lat/lon coordinates will be {selectedLocations[0].latitude}, {selectedLocations[0].longitude} if none are selected on the map.</S.UpdateLocationMapSubText>
            {newLocError === true &&
              <div style={{color: 'red'}}>Error: New location position needs to be within 300 meters of locations being grouped.</div>
            }
                <Suspense fallback={<></>}>
                <CreateLocationGroupMap
                    user={user}
                    selectedLocations={selectedLocations}
                    setNewLatitude={setNewLatitude}
                    setNewLongitude={setNewLongitude}
                    setNewLocError={setNewLocError}
                />
                </Suspense>
            </S.UpdateLocationMapContainer>
            <S.ModalFormButtonWrapper>
              <S.ModalSaveButton disabled={newLocError} type='submit'>Save</S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
            </S.ModalForm>
        </S.ModalContainer>
        </S.ModalDisplay>
    )

}
