import React, { Component } from 'react';
import Graphs from './Graphs';
import selectGraphData from './GraphSelector';
import DataSelector from './DataSelector';
import {
  getFormattedAPIDate,
  numberWithCommas,
  convertKMtoMiles
} from './DataFormatter';
import { withRouter } from 'react-router-dom';

class SingleVehiclePage extends Component {
  _isMounted = false;
  state = {
    selectedData: 'Miles',
    graphData: [],
    dutyCycles: [],
    DCsAtCapacity: 0,
    DCsOverCapacity: 0,
    ttlActiveDCs: 0
  };
  setData = i => {
    this.setState({ selectedData: i });
  };
  vclInfo =
    this.props.vclInfo.length !== 0
      ? this.props.vclInfo[0]
      : this.props.vclInfo;

  componentDidMount() {
    this._isMounted = true;
    this.getSnglVclPageData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vclInfo !== this.props.vclInfo)
      this.vclInfo =
        this.props.vclInfo.length !== 0
          ? this.props.vclInfo[0]
          : this.props.vclInfo;
    
    if(typeof this.vclInfo.annual_tco !== 'undefined'){

      }

    if (
      prevProps.start !== this.props.start &&
      !this.props.settingChangeTracker.selectedDays
    )
      this.getSnglVclPageData();
    if (
      prevProps.stop !== this.props.stop &&
      !this.props.settingChangeTracker.selectedDays
    )
      this.getSnglVclPageData();
    if (prevState.dutyCycles !== this.state.dutyCycles) this.capacityTotals();
    if (
      prevProps.dow !== this.props.dow &&
      !this.props.settingChangeTracker.selectedDays
    )
      this.getSnglVclPageData();
  }

  getSnglVclPageData = () => {
    let start = getFormattedAPIDate(this.props.start);
    let stop = getFormattedAPIDate(this.props.stop);
    try {
      Promise.all([
        fetch(
          `${this.props.apiHost}getSingleVclDCTripTotals?clientId=${this.props.dbName}&vin=${this.props.vin}&stop=${this.props.stop}&start=${this.props.start}&dow=[${this.props.dow}]`,
          {
            headers: { Authorization: `Bearer ${this.props.user.token}` }
          }
        ),
        fetch(
          `${this.props.apiHost}getMonthlySingleVclSummary?clientId=${this.props.dbName}&vin=${this.props.vin}&stop=${stop}&start=${start}&dow=[${this.props.dow}]`,
          {
            headers: { Authorization: `Bearer ${this.props.user.token}` }
          }
        ),
        fetch(
          `${this.props.apiHost}getDutyCyclesByVin?clientId=${this.props.dbName}&vin=${this.props.vin}&stop=${stop}&start=${start}&dow=[${this.props.dow}]`,
          {
            headers: { Authorization: `Bearer ${this.props.user.token}` }
          }
        ),
        fetch(
          `${this.props.apiHost}getMonthlyFilteredAverages?clientId=${this.props.dbName}&locations=[${this.props.selectedLocIds}]&stop=${stop}&start=${start}&dow=[${this.props.dow}]&class=${this.vclInfo.vehicle_class}`,
          {
            headers: { Authorization: `Bearer ${this.props.user.token}` }
          }
        ),
        fetch(
          `${this.props.apiHost}getMonthlyFilteredTrips?clientId=${this.props.dbName}&locations=[${this.props.selectedLocIds}]&stop=${stop}&start=${start}&dow=[${this.props.dow}]&class=${this.vclInfo.vehicle_class}`,
          {
            headers: { Authorization: `Bearer ${this.props.user.token}` }
          }
        )
      ])
        .then(([res1, res2, res3, res4, res5]) =>
          Promise.all([
            res1.json(),
            res2.json(),
            res3.json(),
            res4.json(),
            res5.json()
          ])
        )
        .then(([data1, data2, data3, data4, data5]) => {
          if (this._isMounted) {
            this.setState({
              ttlActiveDCs: 0,
              DCsAtCapacity: 0,
              DCsOverCapacity: 0
            });
            let dcTripttls = data1.data;
            let graphD = data2.data;
            let fleetAvg = data4.data;
            let tripAvgs = data5.data;
            let graphData = [];
            graphD.map((e, i) => {
              // Tallies up the active DC Count
              this.setState(prevState => {
                return {
                  ttlActiveDCs:
                    prevState.ttlActiveDCs +
                    parseInt(
                      dcTripttls[i] === undefined ? 0 : dcTripttls[i].dc_ct
                    )
                };
              });
              // Creates objects to be consumed by graph
              let obj = {
                mnth: e.mnth + ' ' + e.year,
                dc_count: dcTripttls[i] === undefined ? 0 : dcTripttls[i].dc_ct,
                avgDc_count:
                  fleetAvg[i] === undefined ? 0 : fleetAvg[i].avg_dcs,
                trp_count:
                  dcTripttls[i] === undefined ? 0 : dcTripttls[i].trip_ct,
                avgTrp_count:
                  tripAvgs[i] === undefined ? 0 : tripAvgs[i].avg_trips,
                dys_count: e.active_days,
                avgDys_count:
                  fleetAvg[i] === undefined ? 0 : fleetAvg[i].avg_days,
                mls_count: convertKMtoMiles(e.ttl_km),
                avgMls_count: convertKMtoMiles(
                  fleetAvg[i] === undefined ? 0 : fleetAvg[i].avg_kms
                )
              };
              return graphData.push(obj);
            });
            this.setState({ graphData: graphData, dutyCycles: data3.data });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  capacityTotals = () => {
    let atCapacity = [];
    let overCapacity = [];
    this.props.fullCapacity.map(f => {
      // Creates an array that is the timestamps of segments at 100%
      if (f.capacity === 1) atCapacity.push(f.segment_ts);
      // Creates an array that is the timestamps of segments > 100%
      if (f.capacity > 1) overCapacity.push(f.segment_ts);
      return f;
    });
    this.state.dutyCycles.map(d => {
      let epochS = Date.parse(d.utc_start);
      let epochE = Date.parse(d.utc_stop);
      if (atCapacity.some(ts => ts > epochS && ts < epochE))
        this.setState(prevState => {
          return { DCsAtCapacity: prevState.DCsAtCapacity + 1 };
        });
      if (overCapacity.some(ts => ts > epochS && ts < epochE))
        this.setState(prevState => {
          return { DCsOverCapacity: prevState.DCsOverCapacity + 1 };
        });
      return d;
    });
  };

  render() {
    return (
      <div className="sftd-singleVehiclePage">
        <DataSelector
          selectedData={this.state.selectedData}
          setData={i => this.setState({ selectedData: i })}
        />
        <div
          className="sftd-singleVcl-backBtn"
          onClick={this.props.history.goBack}
        />
        <h3 className="sftd-singleVcl-ttl">{this.vclInfo.assetID}</h3>
        <h4 className="sftd-singleVcl-ttl2">
          {this.vclInfo.year +
            ' ' +
            this.vclInfo.make +
            ' ' +
            this.vclInfo.model}
        </h4>

        <div className="sftd-landing-wrpr">
          <div className="sftd-vcl-utilization-wrpr">
            <p className="sftd-vcl-value">
              {this.vclInfo.annual_tco === undefined
                ? ' - '
                : `$ ${numberWithCommas(this.vclInfo.annual_tco)}`}
            </p>
            <p className="sftd-vcl-text">Yearly Est. Cost </p>
            <hr className="sftd-utl-line" />
            <div
              className="sftd-vcl-wrpr"
              id={
                this.state.selectedData === 'Days' ? 'selected' : 'unselected'
              }
            >
              <p className="sftd-vcl-value">
                {this.vclInfo.days}
                <span className="sftd-metric-ttls">
                  {' '}
                  /{numberWithCommas(this.vclInfo.totalDays)}
                </span>
              </p>
              <p className="sftd-vcl-text">Days Used </p>
              <hr className="sftd-utl-line" />
            </div>
            <div
              className="sftd-vcl-wrpr"
              id={
                this.state.selectedData === 'Trips' ? 'selected' : 'unselected'
              }
            >
              <p className="sftd-vcl-value">
                {numberWithCommas(this.vclInfo.trips)}
              </p>
              <p className="sftd-vcl-text">Trips</p>
              <hr className="sftd-utl-line" />
            </div>
            <div
              className="sftd-vcl-wrpr"
              id={
                this.state.selectedData === 'Miles' ? 'selected' : 'unselected'
              }
            >
              <p className="sftd-vcl-value">
                {numberWithCommas(Math.round(this.vclInfo.ttlMiles))}
              </p>
              <p className="sftd-vcl-text">Miles Driven</p>
              <hr className="sftd-utl-line" />
            </div>
            <p className="sftd-vcl-value">
              {numberWithCommas(Math.round(this.vclInfo.operationalHrs))}
              <span className="sftd-metric-ttls">
                {' '}
                /{numberWithCommas(this.vclInfo.totalDays * 24)}
              </span>
            </p>
            <p className="sftd-vcl-text">Hours of Operation</p>
          </div>
          <div className="sftd-vcl-lst-utilization-wrpr">
            <p className="sftd-vcl-value">
              {Math.round(this.vclInfo.avgUtilization * 100) / 1}%{' '}
            </p>
            <p className="sftd-vcl-text">Current Utilization </p>
            <hr className="sftd-utl-line" />
            <div
              className="sftd-vcl-wrpr"
              id={
                this.state.selectedData === 'Duty Cycles'
                  ? 'selected'
                  : 'unselected'
              }
            >
              {/* WHEN WE NEED TO ADD DUTY CYCLES THAT RESPECT TIME DELTA NEED TO ADD vclInfo.totalDCs/ segment.timeDelta.length */}
              <p className="sftd-vcl-value">
                {this.vclInfo.dutyCycles}
                {/* <span className="sftd-metric-ttls">
                / X
              </span> */}
              </p>
              <p className="sftd-vcl-text">Active Duty Cycles</p>
              <hr className="sftd-utl-line" />
            </div>
            <p className="sftd-vcl-value">{this.state.DCsAtCapacity}</p>
            <p className="sftd-vcl-text">
              Duty Cycles During Fleet 100% Utilization
            </p>
            <hr className="sftd-utl-line" />
            <p className="sftd-vcl-value">{this.state.DCsOverCapacity}</p>
            <p className="sftd-vcl-text">
              Duty Cycles During Fleet {'>'}100% Utilization
            </p>
            <hr className="sftd-utl-line" />

            <p className="sftd-vcl-value">
              {numberWithCommas(Math.round(this.vclInfo.idle_hours))}
              <span className="sftd-metric-ttls">
                {' '}
                /{numberWithCommas(this.vclInfo.totalDays * 24)}
              </span>
            </p>
            <p className="sftd-vcl-text">Idle Hours</p>
          </div>
          <div className="sftd-landing-vsl-wrpr">
            <Graphs
              graphData={selectGraphData(
                this.state.selectedData,
                'singleVcl',
                this.state.graphData
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SingleVehiclePage);
