import React, { useMemo } from "react";
import * as S from "../../styles/fueln-styles/Containers";
import Controls from "./Controls";
import SummaryPanel from "./SummaryPanel";


export default function Landing(props){

    const beginDate = useMemo(() => {
      return props.beginDate ?? new Date();
    }, [props.beginDate]);

    const endDate = useMemo(() => {
      return props.endDate ?? new Date();
    }, [props.endDate]);

    const events = props.events ?? [];
    const stats = props.stats ?? [];
    const setBeginDate = props.setBeginDate ?? function(e){};
    const setEndDate = props.setEndDate ?? function(e){};
    const group = props.group ?? {};
    const groups = props.groups ?? []; 
    const handleGroupChange = props.handleGroupChange ?? function(e){};
    const vehicleClasses = props.vehicleClasses ?? [];
    const selectedVehicleClasses = props.selectedVehicleClasses ?? [];
    const setSelectedVehicleClasses = props.setSelectedVehicleClasses ?? function(d){};


    const vinSet = new Set(events.map((r) => {return r.vin}));
    const vins = [...vinSet];

    const getWeekNumber = function(date){
      let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };

    const sortByDate = function( a, b ) {
      if ( a.ts < b.ts )return -1;
      if ( a.ts > b.ts )return 1;
      return 0;
    }
/*
    const weeksInRange = useCallback((begin, end) => {
      const woys = [];
      if(begin === null || end === null)return [];
      let d = new Date(begin.getTime());
      while(d <= end){
        const wn = getWeekNumber(d);
        woys.push(wn);
        d.setDate(d.getDate() + 1);
      }
      const woySet = new Set(woys);
      return [...woySet];
    }, []);
    
    const wir = useMemo(() => {
      if(beginDate === null || endDate === null)return [];
      else{
        const w = weeksInRange(beginDate, endDate);
        return w;
      }
    }, [beginDate, endDate, weeksInRange]);
*/
    const compileIntoWeeks = function (evts) {
        function getWeek(ts){
          if(typeof ts === "undefined")return 0;
          let d = Date.parse(ts)
          d = new Date(d);
          return getWeekNumber(d);
        }

        const maxWeek = Math.max(0, getWeek(endDate));
        const minWeek = Math.max(0, getWeek(beginDate));
        const we = []
        let i = minWeek;
        while(i <= maxWeek){
          we.push({woy: i, incidents: 0, durations: 0, offRoute: 0})
          i++;
        }
        let arr = evts.sort(sortByDate);
        arr.forEach((a) => {
          const woy = getWeek(a.utc_start);
          we.forEach((w) => {if(w.woy === woy){
              if(a.location === 11)a.route_duration.minutes = 3;//handling rotten robbie loc 
              w.incidents++;
              w.durations += parseInt(Math.min(a.duration, 1200)/60);
              w.offRoute += parseInt(Math.min(10, a.route_duration.minutes))
            }
          });
        });
        return we;
    }

    let fuelingDuration = 0;

    let travelToDuration = 0;


    const weeklyEvents = [];
    vins.forEach((v) => {
      const f = events.filter((e) => v === e.vin);
      const weeklyEvents = compileIntoWeeks(f);
      let total = 0;
      const o = {
        vin: v,
        asset_id: f[0].asset_id,
        year: f[0].year,
        make: f[0].make,
        model: f[0].model,
        vehicle_class: f[0].vehicle_class};
      weeklyEvents.forEach((e, i) =>{
        o[`${e.woy}`] = e.incidents;
        total += e.incidents;
        fuelingDuration += parseInt(e.durations);
        if(!isNaN(e.offRoute))travelToDuration += parseInt(e.offRoute);
      });
      o.total = total;
      weeklyEvents.push(o);

    });

    return(
        <>
            <S.FuelnTopContainer>
                <S.TopInfoContainer>
                    <SummaryPanel 
                        beginDate={beginDate}
                        endDate={endDate}
                        events={events}
                        stats={stats}
                        weeklyEvents={weeklyEvents}
                        vins={vins}
                        fuelingDuration={fuelingDuration}
                        travelToDuration={travelToDuration}
                    />
                </S.TopInfoContainer>
                <Controls 
                    beginDate={beginDate}
                    endDate={endDate}
                    setBeginDate={function(e){setBeginDate(e)}}
                    setEndDate={function(e){setEndDate(e)}}
                    group={group}
                    groups={groups}
                    handleGroupChange={function(e){handleGroupChange(e)}}
                    vehicleClasses={vehicleClasses}
                    selectedVehicleClasses={selectedVehicleClasses}
                    updateSelectedClasses={function (data) { setSelectedVehicleClasses(data) }}
                />
            </S.FuelnTopContainer>
        </>
    )
}