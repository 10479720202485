import styled from "styled-components/macro";

/*
fueln pallete
#8ECAE6 - light blue 142, 202, 230
#219EBC - medium blue
#023047 - dark blue
#FFB703 - yellow
#FB8500 - orange
*/

export const NavContainer = styled.nav`
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 90%;
    margin-top: 45px;
    margin-left: 90px;
    margin-right: 20px;
    margin-bottom: 0px;
    padding-left: 60px;
    height: 35px;
    text-align: center;
    border-left: 2px;
`;

export const AppContainer = styled.div`
  border: 2px solid rgba(187, 187, 187, .5);
  border-radius: 3px;
  margin-left: 90px;
  margin-right: 90px;
  padding: 10px;
`;

export const FuelnTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-top: 0px;
`;

export const TopInfoContainer = styled.div`
    width: 60%;
    border: 2px solid rgb(187, 187, 187);
    border-radius: 2px;
    padding: 15px;
    margin: 10px;
`;

export const FuelnTableContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 300px;
    border: 2px solid rgba(187, 187, 187, .5);
    border-radius: 2px;
    background-color: rgba(142, 202, 230, .02);
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

export const LoadingContainer = styled.div`
    height: 15vh;
    width: 15vw;
    margin: 10rem auto;
`