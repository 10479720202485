import React, { useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import NoResultsMessage from "./noResultsMessage";
import { humanFriendlyTime, getLongMonth } from "./dataFormatter";
import { getKWDemandByLocation } from "./fetchCalls";
import GraphLegend from "./graphLegend";
import { GraphExplanation } from "./graphExplanation";
import LocationVehicleTable from "./locationVehicleTable";

export default function GraphDailyCharging(props) {
  const [dailyChargeData, setDailyChargeData] = React.useState([]);
  const {
    selectedMonth,
    selectedLocation,
    selectedPercent,
    requestData,
    showEzevResults,
    // setLoading,
  } = props;

  let labels = [];
  let data = {};

  const date = (day) => {
    if (selectedMonth === "") return;
    let d = selectedMonth.split(",");
    let month = d[0].slice(5, -3);
    let year = d[0].slice(0, -6);
    return getLongMonth(parseInt(month)) + " " + day + ", " + year;
  };

  function calcPeakDuration(peakIndex) {
    var peakDay = dailyChargeData.data[0];
    for (var i = peakIndex; i < peakDay.activity.length; i++) {
      if (peakDay.activity[i] !== peakDay.activity[peakIndex]) {
        return (i - 1) / 4;
      }
    }
  }

  const peakDateAndTime = () => {
    if (!dailyChargeData || !dailyChargeData.data) return;
    let peakDay = dailyChargeData.data[0];
    let tod = "AM";
    let peakStartIndex = peakDay.activity.indexOf(peakDay.peak);
    let peakTimeEnd = `${calcPeakDuration(peakStartIndex)}`;
    let peakTimeStart = `${peakStartIndex / 4}`;
    let multipleFlag = "";
    let peakDays = dailyChargeData.data.filter((d) => d.isPeak === true);
    // if (peakDays.length > 1) return '++';
    if (peakDays.length > 1) return "Multiple Peaks";
    let peakStartTime = humanFriendlyTime(peakTimeStart, tod);
    let peakEndTime = humanFriendlyTime(peakTimeEnd, tod);
    if (peakStartTime.includes("-0")) return "No Peak";
    if (peakTimeStart !== peakTimeEnd)
      return `${date(peakDay.day)} - ${
        peakStartTime + "-" + peakEndTime + multipleFlag
      }`;
    if (peakTimeStart === peakTimeEnd)
      return `${date(peakDay.day)} - ${peakStartTime + multipleFlag}`;
  };

  const kWPeaks = () => {
    if (!dailyChargeData || !dailyChargeData.data) return;
    return isNaN(dailyChargeData.data[0].peak)
      ? 0
      : dailyChargeData.data[0].peak;
  };

  const peakKwVclCount = () => {
    if (!dailyChargeData || !dailyChargeData.data) return;
    let count = Math.round(dailyChargeData.data[0].peak / 6.9);
    return !isNaN(count) ? count : 0;
  };

  const chargingSentence = () => {
    return (
      <p className="nrel-charging-text">
        During the period of peak demand for this month, there were{" "}
        <span className="nrel-text-bold">{peakKwVclCount()}</span> vehicles
        charging at the same time at this location.
      </p>
    );
  };

  const sortTopDays = useCallback((resp) => {
    if (resp.data) {
      resp.data.forEach((a) => {
        a["peak"] = Math.max.apply(null, a.activity);
        a["totalKwConsumption"] = a.activity.reduce((a, b) => a + b);
        a["isPeak"] = false;
      });
      resp.data.sort((a, b) => {
        if (a.peak > b.peak) return -1;
        if (b.peak < a.peak) return 1;
        if (a.peak === b.peak && a.totalKwConsumption > b.totalKwConsumption)
          return -1;
        if (a.peak === b.peak && a.totalKwConsumption < b.totalKwConsumption)
          return 1;
        return 0;
      });
      // Checks to see if there are multiple peaks
      for (var i = 0; i < resp.data.length; i++) {
        if (resp.data[i].peak === resp.data[0].peak) {
          // filter if we want to show the highest kwConsumption as well as the peak
          //  &&resp.data[i].totalKwConsumption === //   resp.data[0].totalKwConsumption
          resp.data[i].isPeak = true;
        }
      }
      setDailyChargeData(resp);
    } else {
      setDailyChargeData([]);
    }
  }, []);

  const getChargingData = useCallback(() => {
    getKWDemandByLocation(requestData, sortTopDays, {
      loc: selectedLocation,
      bounds: selectedMonth,
      percent: selectedPercent,
      showEzevResults: showEzevResults,
    });
  }, [
    requestData,
    selectedLocation,
    selectedMonth,
    selectedPercent,
    showEzevResults,
    sortTopDays,
  ]);

  useEffect(() => {
    setDailyChargeData([]);
    getChargingData();
  }, [getChargingData, selectedLocation, selectedMonth, selectedPercent]);

  if (
    typeof dailyChargeData !== "undefined" &&
    typeof dailyChargeData.data !== "undefined"
  ) {
    labels = dailyChargeData.data[0].activity.map((d, idx) => {
      if (idx % 4 === 0) return `${idx / 4}:00`;
      else return "";
    });
    const datasets = dailyChargeData.data.map((d) => {
      return {
        data: d.activity,
        label: date(d.day),
        pointRadius: 1,
        pointHitRadius: 3,
        pointBackgroundColor: d.isPeak
          ? "rgba(255,63,51, .2)"
          : "rgba(178, 177, 175,.8)",
        borderJoinStyle: "miter",
        fill: true,
        lineTension: 0.3,
        borderWidth: d.isPeak ? 1.5 : 1,
        backgroundColor: d.isPeak
          ? "rgba(255,63,51, .2)"
          : `rgba(167, 166, 164, .04)`,
        borderColor: d.isPeak ? "red" : `rgba(167, 166, 164, .5)`,
        hoverBackgroundColor: d.isPeak
          ? "rgba(255,63,51, .7)"
          : "rgba(178, 177, 175, 1)",
      };
    });
    data = { labels: labels, datasets: datasets, orig: dailyChargeData.data };
  }

  return (
    <>
      <div className="nrel-chargeGraph-summarizer">
        <p className="nrel-summarizer-text">
          Date and Time of Monthly Peak:{" "}
          <span className="nrel-summarizer-value">{peakDateAndTime()}</span>
        </p>
        <p className="nrel-summarizer-text">
          Peak kW Demand:{" "}
          <span className="nrel-summarizer-value">{kWPeaks()}</span> kW
        </p>
        <p className="nrel-summarizer-text" id = 'vcl-ct'>
        # of EVs Charging During Peak:{" "}
          <span className="nrel-summarizer-value">{peakKwVclCount()}</span> vcls
        </p>
      </div>

      <div className="nrel-charge-text-wrapper">{chargingSentence()}</div>
      <div className="nrel-graph-table-wrapper" id="daily">
        <div className="nrel-daily-graph-wrapper">
          <div className="nrel-graph-container" id="daily">
            <NoResultsMessage
              data={data}
              selectedPercent={selectedPercent}
              selectedMonth={selectedMonth}
              loading={props.loading}
              showEzevResults={props.showEzevResults}
            />
            <Line
              id="charge-graph"
              data={data}
              width={300}
              height={90}
              options={{
                plugins:{
                  legend: { display: false },
                },
                tooltips: {
                  callbacks: {
                    title: function () {
                      return "";
                    },
                    label: function (item, data) {
                      var datasetLabel =
                        data.datasets[item.datasetIndex].label || "";
                      var dataPoint = item.yLabel;
                      return datasetLabel + ": " + dataPoint + " kW";
                    },
                    afterLabel: function (item, data) {
                      return (
                        "Vehicles Charging: " +
                        Math.round(item.yLabel / 6.9) +
                        " vcls "
                      );
                    },
                  },
                },
                hover: {
                  mode: "dataset",
                  intersect: true,
                },
                elements: {
                  point: {
                    hitRadius: 20,
                  },
                },

                maintainAspectRatio: false,
                responsive: true,
                title: { text: `kW Demand by Time of Day`, display: false },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        suggestedMax: 20,
                        suggestedMin: 0,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "Peak kW",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Time of Day",
                      },
                    },
                  ],
                },
              }}
            />
          </div>
          <GraphLegend selectedGraph={"daily"} />
          <GraphExplanation graphType={"Daily"} />
        </div>
        <LocationVehicleTable
          soruce={"Daily"}
          showEzevResults={props.showEzevResults}
          requestData={props.requestData}
          selectedPercent={props.selectedPercent}
          selectedLocation={props.selectedLocation}
          selectedMonth={selectedMonth}
          start={props.start}
          stop={props.stop}
        />
      </div>
    </>
  );
}
