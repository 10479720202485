
import React, { useReducer } from 'react'
import * as S from '../../../../styles/core-styles/LandingPage-styles'


export default function CredentialInput({ passCredentials, resetPassword }) {


  const [credentials, dispatchCredentials] = useReducer((state, newVal) => {
    //merge the old state with the new value instead of two useStates
    //Groups relevant states together 
    //also makes debugging easier because the debugger can't read what you call your state variables but can read object field names
    return ({
      ...state,
      ...newVal
    })
  }
    ,
    {
      username: "",
      password: ""
    })


  function handleNameChange(event) {
    dispatchCredentials({ username: event.target.value.trim() })
  }

  function handlePasswChange(event) {
    dispatchCredentials({ password: event.target.value })
  }

  function handleSubmit(event) {
    event.preventDefault();
    let d = { 'username': credentials.username, 'password': credentials.password }
    passCredentials(d);
  }

  //Not doing any validation so there's no real reason to start controlling the form
  return (
    <>
    <div className='swt-login'>
      <form className='swt-auth' onSubmit={handleSubmit}>
        <p className='swt-credential-input-label'>Username/Email Address</p>
        <input className='swt-credential-input' name='username' type='text' autoComplete="username" onChange={handleNameChange}></input>
        <p className='swt-credential-input-label'>Password</p>
        <input className='swt-credential-input' name='password' type='password' autoComplete="current-password" onChange={handlePasswChange}></input>
        <p className='swt-reset-password' onClick={resetPassword}>Forgot Password</p>
        <button className='swt-submit-button' type='submit'>Submit</button>
      </form>
    </div>

    <S.LandingFooter>
      <S.UnderlineLink href="https://www.wexinc.com/privacy-notice/" target="_blank" rel="noreferrer">Privacy Notice</S.UnderlineLink> | <S.UnderlineLink href="https://www.wexinc.com/cookie-notice/" target="_blank" rel="noreferrer">Cookie Notice</S.UnderlineLink>
    </S.LandingFooter>
    </>
  )
}


