import styled from "styled-components/macro"
import { Link } from "react-router-dom"


export const AboutLayout = styled.div`
font-family: "Arimo", "Roboto";
width: 1076px;

`

export const AboutTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: #4A4A4A;
`

export const AboutSubtitle = styled.h2`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 35px;
color: #4A4A4A;
`

export const AboutCopy = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #74787D;
padding-top: 10px;
padding-bottom: 10px;
text-decoration: none;
`

export const EmailLink = styled.a`
font-family: "Arimo", "Roboto";
text-decoration: none;
color: #035E5B;
&:hover {
    color: #035E5B;
    text-decoration: underline;
}
`

export const ProductLink = styled(Link)`
    font-family: "Arimo", "Roboto";
    text-decoration: none;
    color: #035E5B;
    &:hover {
        color: #035E5B;
        text-decoration: underline;
`

export const AboutTableTitle = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-size: 18px;
padding-bottom: 10px;
color: #74787D;
`

export const AboutTable = styled.table`
font-family: "Arimo", "Roboto";
text-align: left;
color: #4A4A4A;
border-spacing: 0;
border-collapse: collapse;
`

export const AboutTableHead = styled.thead`
font-family: "Arimo", "Roboto";
background: rgba(3,94,91,0.45);
`

export const AboutTableTr = styled.tr`
font-family: "Arimo", "Roboto";
height: 50px;
`

export const AboutTableTh = styled.th`
font-family: "Arimo", "Roboto";
padding: 0 20px;
border: solid 1px #74787D;
`

export const AboutTableBody = styled.tbody`
font-family: "Arimo", "Roboto";
color: #4A4A4A;
`

export const AboutTableTd = styled.td`
font-family: "Arimo", "Roboto";
padding: 0 20px;
border: solid 1px #74787D;
`