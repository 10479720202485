import React from "react";

export default function Glossary(props) {
  const glossary = [
    {
      term: "Reason",
      definition:
        "Identifies the metric(s) that did not meet the minimum requirements for an EV recommendation. It may be possible that minor operational changes would make a vehicle a good fit for replacement with an EV.",
    },
    {
      term: "ezEV Recommendation",
      definition:
        "The result of the ezEV analysis which compares the existing vehicle with available EV options. If an EV is identified that meets the operational and economic requirements of the vehicle, it will receive an ezEV Recommendation for an EV model.",
    },
    {
      term: "ezEV Score",
      definition:
        "The ezEV score indicates how good of a match an EV is for the vehicle. A score of 85 or higher indicates that the vehicle is a good operational and economic fit for replacement with an EV.",
    },
    // { term: "Greenhouse Gas Emissions (GHG)", definition: "pending" },
    {
      term: "Total Cost of Ownership (TCO)",
      definition:
        "The purchase price of a vehicle and the costs to operate the vehicle over its lifetime.",
    },
    {
      term: "Peak Demand",
      definition:
        "The highest average energy demand during any 15-minute period in a month, measured in kW in this analysis.",
    },
    {
      term: "Kilowatt (kW)",
      definition:
        "One thousand watts. A watt is a unit of power used to measure the rate of energy transfer.",
    },
    {
      term: "Kilowatt Hour (kWh)",
      definition:
        "A measurement of the amount of electricity used over an hour.",
    },
    {
      term: "Internal Combustion Engine (ICE)",
      definition:"A vehicle using an engine that combusts liquid fuel, commonly used to describe gas-powered vehicles.",
    },
    {
      term: "Electric Vehicle (EV)",
      definition:
        " Vehicles which use an electric motor. Other related terms include plug-in electric vehicle (PEV), battery electric vehicle (BEV), and plug-in hybrid electric vehicle (PHEV).",
    },
  ];

  function alphabetizeTerms(terms) {
    return terms.sort((a, b) =>
      a.term.toLowerCase() > b.term.toLowerCase() ? 1 : -1
    );
  }

  const terms = alphabetizeTerms(glossary);
  return (
    <div className="nrel-glossary-wrapper">
      <h3 className="nrel-glossary-header">Glossary</h3>
      <div className="nrel-glossary-list-wrapper">
        <ul className = 'nrel-glossary-list'>
          {terms.map(({ term, definition }) => {
            // Div with two columns
            return (
              <li className="nrel-glossary-item" key={term}>
                <span id="term">{term}</span>
                <span id="definition">{definition}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
