import React from "react";
import Table from "../nrel-dashboard-components/table";
import { CSVLink } from "react-csv";
import StandardizeTableData, {
  csvRecHeaders,
  csvClassRecHeaders,
  tableRecHeaders,
  tableBICHeaders,
} from "./standardizeTableData";
import { capitalizeFirstLetter } from "./dataFormatter";
import Loading from "./loading";
import OnHoverExplanation from "./onHoverExplanation";

export default function EzevRecs(props) {
  const { recData } = props;
  const [filterHover, setFilterHover] = React.useState({
    id: null,
    x: null,
    y: null,
  });
  const [selectedTable, setSelectedTable] = React.useState("all results");
  const selectedHeader =
    selectedTable === "Best in Class" ? tableBICHeaders() : tableRecHeaders();
  const selectedCSVHeader =
    selectedTable === "Best in Class" ? csvClassRecHeaders() : csvRecHeaders();

  function filterOnRadioButtons() {
    if (selectedTable === "recommendations")
      return recData.filter((vcl) => {
        return (
          (vcl.recommendation.includes("PHEV") ||
            vcl.recommendation.includes("BEV")) &&
          vcl.overall >= 85
        );
      });
    // Note - Do we want to filter on 'optimization candidate?
    if (selectedTable === "Best in Class") {
      return recData.filter((vcl) => vcl.bestInClassOverall > 84);
    }
    if (selectedTable === "failed")
      return recData.filter(
        (vcl) =>
          vcl.recommendation === "No Change" ||
          vcl.recommendation === "Optimization Candidate"
      );
    return recData;
  }
  const filteredData = filterOnRadioButtons();
  const selectedData =
    selectedTable === "Best in Class"
      ? StandardizeTableData(filteredData, "class")
      : StandardizeTableData(filteredData, "recs");

  return (
    <div className="nrel-ezevRecs">
      <OnHoverExplanation
        id={filterHover.id}
        mouseCoords={[filterHover.x, filterHover.y]}
      />
      <div className = 'title-container'>
        <h3 className="nrel-ezevRecs-headers">ezEV Scores</h3>
        <p className="ezev-description">
          This table provides the ezEV scores for each vehicle included in the
          analysis. You can filter to see 1) all vehicles, 2) all vehicles
          receiving an ezEV Recommendation (meaning vehicles that are a good
          operational and economic fit for an EV), 3) vehicles that have an EV
          match that is in the same vehicle class, and 4) vehicles for which no
          available EV was identified as a good fit. You can sort by the ezEV
          Score column to rank order all of your vehicles by ezEV Score to see
          which ones are the best fit for replacement with an EV.
        </p>
      </div>
      <div className="nrel-ezev-radio-btns">
        <h2 className="nrel-ezev-radio-btns-title">Filter By:</h2>
        {[
          { title: "All Vehicles", value: "all results" },
          { title: "ezEV Recommendations", value: "recommendations" },
          { title: "EV Matches within Vehicle Class", value: "Best in Class" },
          { title: "No Current Match", value: "failed" },
        ].map(({ title, value }) => {
          return (
            <label
              className="nrel-ezev-btn-label"
              id={value}
              key={value}
              onMouseEnter={(e) =>
                setFilterHover({ id: value, x: e.pageX, y: e.pageY })
              }
              onMouseLeave={(e) =>
                setFilterHover({ id: null, x: null, y: null })
              }
            >
              <input
                className="nrel-ezev-btn"
                id={value}
                checked={selectedTable === value}
                type="radio"
                onChange={() => setSelectedTable(value)}
              />
              {title}
            </label>
          );
        })}
      </div>
      <div className="nrel-recsTable-wrpr">
        {recData.length > 0 ? (
          <Table columns={selectedHeader} data={selectedData} />
        ) : (
          <Loading />
        )}
      </div>
      {/* Notes - Should the CSV be affected by the radio buttons? - currenrtly filters on radio buttons */}
      <CSVLink
        className="nrel-download-btn"
        id="ezevRecs"
        data={selectedData}
        headers={selectedCSVHeader}
        target="_blank"
        filename={`sawatch-labs-NREL-${capitalizeFirstLetter(
          selectedTable
        )}-table.csv`}
      >
        <p>Download CSV</p>
      </CSVLink>
    </div>
  );
}
