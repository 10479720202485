import React, { useEffect } from "react";
import TableController from "./TableController";
import StatsController from "./StatsController";
import PermissionError from "./PermissionError";
import { Roles } from "../core-components/utility-components/UserUtils";

export default function PageController(props) {
  useEffect(() => {
    var nav = 'vcl-metrics';
    if(props.match.path.includes('events-by-location'))nav='loc-metrics';
    if(props.match.path.includes('tco-comparison'))nav='tco-metrics';
    props.activeLinkChange('activeLink', nav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.path]);

  const user = props.user;
  return (
    <>
      {props.match.path.includes('reimbursement') && (!(user.role >= Roles.FleetAdmin)) ? <PermissionError/> :
        <div className="swt-ionev-page-wrapper">
          <div className="swt-ionev-page-content">
          <StatsController
            match={props.match}
            totalActivityStats={props.totalActivityStats}
            baseUrl={props.base_url}
            dbName = {props.dbName}
            startDate = {props.startDate}
            endDate = {props.endDate}
            user = {props.user}
            evCount={props.evCount}
            locKwhStats = {props.locKwhStats}
            formattedStartDate={props.formattedStartDate}
            formattedEndDate={props.formattedEndDate}
            tcoTrend = {props.tcoTrend}
            tcoStatsTotal={props.tcoStatsTotal}
            chargeSummaryByVcl={props.chargeSummaryByVcl}
            missedSummaryByVcl={props.missedSummaryByVcl}
            topLocs={props.topLocs}
            missedMiles={props.missedMiles}
            fuelCost={props.fuelCost}
            reimbursementSummary={props.reimbursementSummary}
            reimbursementLastMth={props.reimbursementLastMth}
          />
          < TableController {...props} />
          </div>
        </div>
      }
    </>
  );
}
