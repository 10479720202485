import React, { useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../../component-css/nrel-dashboard-stylesheets/nrel-StateDashboard.css";
import Header from "./header";
import OverallStats from "./overallStats";
import ClientSettings from "./clientSettings";
import Tabs from "./tabs";
import EzevRecs from "./ezevRecs";
import PercentView from "./Ste-Components/percentView";
// import MapTab from ".//Ste-Components/mapTab";
import About from "./about";
import InfoCard from "./infoCard";
import Glossary from "./glossary";
import Home from "./homeTab";
import {
  getGroupMetrics,
  getTopLocations,
  getSettings,
  getBulkEzevResults,
} from "./fetchCalls";
import GraphController from "./graphController";
import {
  checkForDuplicateLocationNames,
  reasonForFailure,
} from "./dataFormatter";
import Footnote from "./footnote";

function SteDashboard(props) {
  const { requestData } = props;
  const [activeTab, setActiveTab] = React.useState("Home");
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [selectedPercent, setPercent] = React.useState(40);
  const [selectedMonth, setMonth] = React.useState("2019-10-01,2019-10-31");
  const [locations, setLocations] = React.useState([]);
  const [assumptions, setAssumptions] = React.useState([]);
  const [infoCardData, setInfoCardData] = React.useState({});
  const [recData, setRecData] = React.useState([]);
  const [mapCenter, setMapCenter] = React.useState(null);
  const [zoom, setMapZoom] = React.useState(null);
  // const start = infoCardData.start;
  // const stop = infoCardData.end;
  const [allLocations, setAllLocations] = React.useState([]);
  //const delay = interval => new Promise(resolve => setTimeout(resolve, interval));

  const setInitialLocation = useCallback(
    (locations) => {
      if (selectedLocation === "" && locations.length > 0) {
        let vclSort = locations.sort((a, b) =>
          parseInt(a.anc_ct) + parseInt(a.vcl_ct) >
          parseInt(b.anc_ct) + parseInt(b.vcl_ct)
            ? -1
            : 1
        );
        setSelectedLocation(vclSort[0].pkid);
      }
    },
    [selectedLocation]
  );

  const _processGroupMetrics = (res) => {
    var obj = { start: new Date(), end: new Date(), ttlVcls: "-" };
    if (res)
      obj = { start: res.min_ts, end: res.max_ts, ttlVcls: res.vehicles };
    setInfoCardData(obj);
  };

  function selectLocation(obj) {
    setMapCenter([obj.lat, obj.lon]);
    setMapZoom(18);
  }

  function _handleCandidateResp(resp, rec, compResults, count) {
    if (typeof resp !== "undefined" && typeof resp.bic !== "undefined") {
      let bic = resp.bic;
      rec["bestInClassYMM"] = bic.ymm;
      rec["bestInClassOverall"] = bic.overall;
      rec["bestInClassEconomics"] = bic.economics;
      rec["bestInClassParking"] = bic.parking;
      rec["bestInClassEnergy"] = bic.energy;
      rec["bestInClassConfidence"] = bic.confidence;
      rec["lvl2ChargingHrs"] = resp.rec_ev_avg_level2_hrs;
      rec["bestInClassFailureReason"] = reasonForFailure(bic);
    }
    compResults.push(rec);
    if (compResults.length === count) {
      setRecData(compResults);
    }
  }

  function _handelBulkResults(resp) {
    let compResults = [];
    resp.forEach((r) => {
      let vcl = {
        vin: r.vin,
        overall: r.overall,
        year: r.year,
        make: r.make,
        model: r.model,
        asset_id: r.asset_id,
        economics: r.economics,
        energy: r.energy,
        parking: r.parking,
        vehicle_class: r.vehicle_class,
        recommendation: r.recommendation,
        rec_lt_ghg_reduction_lbs: r.rec_lt_ghg_reduction_lbs,
        rec_lt_gals_saved: r.rec_lt_gals_saved,
        rec_yr_tco_reduction: r.rec_yr_tco_reduction,
      };
      _handleCandidateResp(r, vcl, compResults, resp.length);
    });
  }

  useEffect(() => {
    getBulkEzevResults(requestData, _handelBulkResults);
    getGroupMetrics(requestData, (resp) => _processGroupMetrics(resp));
    getSettings(requestData, setAssumptions, true);
    getTopLocations(requestData, (locs) => setAllLocations(locs), {
      percent: 100,
      limit: 1000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  const getTopLocationsEP = useCallback(() => {
    let reqParams = { percent: selectedPercent, limit: 1000 };
    getTopLocations(
      requestData,
      function (resp) {
        const data = checkForDuplicateLocationNames(resp);
        setLocations(data);
        setInitialLocation(data);
        // if (allLocations.current < data) allLocations.current = data;
      },
      reqParams
    );
  }, [requestData, selectedPercent, setInitialLocation]);
  useEffect(() => {
    getTopLocationsEP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPercent]);

  return (
    <div className="nrel-SteDashboard">
      <Router>
        <div className="nrel-dash-landing">
          <Header
            dashType={props.dashType}
            user={props.user}
            handleAdminSelection={props.handleAdminSelection}
          />
          <InfoCard
            requestData={requestData}
            infoCardData={infoCardData}
            start={infoCardData.start}
            stop={infoCardData.end}
            vclAnalyzedCount={recData.length}
          />
          <div className="nrel-nav-stats-wrapper">
            <OverallStats
              overallStats={recData}
              assumptions={assumptions}
              title={"Overall Results For Recommended EVs:"}
            />
          </div>
          <div className="nrel-tab-wrapper">
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <Redirect to={{ pathname: "/nrel-ste" }} />}
              />
              <Route
                path="/nrel-ste"
                exact
                render={(props) => (
                  <>
                    <Tabs
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      deactive={locations.length === 0}
                    >
                      <div className="nrel-Started" label="Home">
                        <Home dashType={"ste"} />
                      </div>
                      <div className="nrel-Recs" label="ezEV Scores">
                        <EzevRecs recData={recData} requestData={requestData} />
                      </div>
                      {/* <div className={`nrel-map`} label={`Map`}>
                        <MapTab
                          zoom={zoom}
                          setMapZoom={setMapZoom}
                          mapCenter={mapCenter}
                          setMapCenter={setMapCenter}
                          allLocations={allLocations}
                          locData={locations}
                          selectedPercent={selectedPercent}
                          setPercent={setPercent}
                          handleLocationChange={(loc) => {
                            setSelectedLocation(loc);
                            setActiveTab("Charging Demand");
                          }}
                        />
                      </div> */}
                      <div className={`nrel-graph`} label={`Charging Demand`}>
                        <GraphController
                          requestData={requestData}
                          selectedMonth={selectedMonth}
                          start={infoCardData.start}
                          stop={infoCardData.end}
                          locations={locations}
                          selectedPercent={selectedPercent}
                          selectedLocation={selectedLocation}
                          setMonth={setMonth}
                          setPercent={setPercent}
                          setSelectedLocation={setSelectedLocation}
                        />
                      </div>
                      <div className={`nrel-20%`} label={`Locations`}>
                        <PercentView
                          vclCount={recData.length}
                          mapCenter={mapCenter}
                          setMapCenter={setMapCenter}
                          zoom={zoom}
                          setMapZoom={setMapZoom}
                          requestData={requestData}
                          locations={locations}
                          start={infoCardData.start}
                          stop={infoCardData.end}
                          allLocations={allLocations}
                          selectLocation={selectLocation}
                          selectedPercent={selectedPercent}
                          handlePercentChange={setPercent}
                          handleLocationChange={(loc) => {
                            setSelectedLocation(loc);
                            setActiveTab("Charging Demand");
                          }}
                        />
                      </div>

                      <div className={`nrel-assumptions`} label={`Settings`}>
                        <ClientSettings
                          requestData={requestData}
                          assumptions={assumptions}
                        />
                      </div>
                      <div className={`nrel-about`} label={`About`}>
                        <About dashType={"ste"} />
                      </div>
                      <div className={`nrel-glossary`} label={`Glossary`}>
                        <Glossary dashType={"ste"} />
                      </div>
                    </Tabs>
                    <Footnote activeTab={activeTab} />
                  </>
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default SteDashboard;
