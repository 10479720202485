import React, { useMemo, useCallback, useEffect } from "react";
import { humanFriendlyMonth } from "./swt-admin-seasonalRate-dropdowns";
import AdminTableView from "./AdminTableView";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { seasonalRatesTableColumns } from "./TableHelpers";
import { Roles } from "../../utility-components/UserUtils";

export default function SeasonalRateTable(props) {
  const { kwRates, editRate, deleteRate, setdownloadData, matchingRates, skipPageResetRef, dbSettings, user } = props;
  function _createRateDate(rateObj) {
    if (rateObj.startMonth == null || rateObj.stopMonth === null)
      return "All Year";
    if (rateObj.startDayOfMonth === null || rateObj.stopDayOfMonth === null) {
      return `${rateObj.startMonth + " - " + rateObj.stopMonth}`;
    } else {
      return `${
        humanFriendlyMonth(rateObj.startMonth) +
        " " +
        (rateObj.startDayOfMonth + 1) +
        " - " +
        humanFriendlyMonth(rateObj.stopMonth) +
        " " +
        (rateObj.stopDayOfMonth + 1)
      }`;
    }
  }

  useEffect(() => {
    let downloadData = []
    kwRates.map((r) => {
     return downloadData.push({
        nickname: r.nickname,
        kwhRate: r.kwhRate,
        duration: _createRateDate(r),
        rateDOW: _createRateDOW(r),
        rateTOU: _createRateTOU(r)
      })
    })
    setdownloadData(downloadData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[kwRates])

  function _createRateDOW(rateObj) {
    let selectedDays = [];
    let deselectedDays = [];
    props.dowArray.forEach((d) => {
      if (rateObj[d] === true) {
        d = d.charAt(0).toUpperCase() + d.slice(1, 3);
        selectedDays.push(d);
      }
      if (rateObj[d] === false) {
        deselectedDays.push(d);
      }
    });
    if (selectedDays.length === 7) {
      return "Every Day" // Every day explicitly checked
    }
    if (selectedDays.length > 0) {
      return selectedDays.join(', ');
    }
    else if (deselectedDays.length === 7) { // Edge case where active days of week is checked but no days are checked. Should not be possible with new error checking but leaving for redundancy.
      return "No Days";
    }
    else {
      return "Every Day (no days specified)"; // DOW disabled, every day enabled implicitly
    }
  }

  function _createRateTOU(rateObj) {
    if (rateObj.startHour === null || rateObj.stopHour === null)
      return "All Times";
    function standardizeHour(hr) {
      if (hr === 0) return 12;
      if (hr > 12) return hr - 12;
      else return hr;
    }
    function addLeadingZero(mnt) {
      if (mnt > 9) return mnt;
      else return `0${mnt}`;
    }
    return `${
      standardizeHour(rateObj.startHour) +
      ":" +
      addLeadingZero(rateObj.startMinute) +
      (rateObj.startHour < 12 ? " AM" : " PM") +
      " - " +
      standardizeHour(rateObj.stopHour) +
      ":" +
      addLeadingZero(rateObj.stopMinute) +
      (rateObj.stopHour < 12 ? " AM" : " PM")
    }`;
  }

const TableButton = useCallback((props) => 
  <S.InnerTableButton data-testid={props.dataTestId} disabled={props.disabled} color={props.color} onClick={props.handleClick}>
    {props.label}
  </S.InnerTableButton>,[]);

const tableColumns = seasonalRatesTableColumns(dbSettings);

const mappedKwRates = useMemo(()=> {
  if(!kwRates)return null;
  if(kwRates.length < 1)return [];

  const rates = JSON.parse(JSON.stringify(kwRates));
  const matchingData = matchingRates.map((c) => {return c.pkid});
  const buttonsDisabled = user.role <= Roles.FleetAdmin; // Edit/Delete restricted for fleet admins
  return rates.filter((r) => {
      if(matchingData.indexOf(r.pkid) < 0)return null;
    r.kwhRate = <p title={r.kwhRate} accessor={'kwhRate'}>{r.kwhRate}</p>
    r.duration = <p style={{whiteSpace: 'nowrap'}} accessor={'duration'} title={_createRateDate(r)}>{_createRateDate(r)}</p>
    r.rateDOW = <p accessor={'rateDOW'} title={_createRateDOW(r)}>{_createRateDOW(r)}</p>
    r.rateTOU = <p accessor={'rateTOU'} title={_createRateTOU(r)}>{_createRateTOU(r)}</p>
    r.edit = <TableButton dataTestId={"editRateBtn-testid"} disabled={buttonsDisabled} handleClick={buttonsDisabled ? null : () => editRate(r.pkid)} label={'Edit'} />
    r.delete = <TableButton dataTestId={"deleteRateBtn-testid"} disabled={buttonsDisabled} handleClick={buttonsDisabled ? null : () => deleteRate({pkid: r.pkid, dbname: r.dbname, rateName: r.nickname})} label={'Delete'} color={'#e01f1f'}/>
    return r;
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[kwRates, deleteRate, editRate]);

  return (
    <>
    <AdminTableView 
      columns={tableColumns}
      data={mappedKwRates}
      hiddenColumns={props.hiddenColumns}
      stickyCols={2}
      skipPageResetRef={skipPageResetRef}
      noDataMessage={'No rates to display. Click "Add" to create one.'}
    />
    </>
  );
}
