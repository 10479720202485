import React, { useMemo } from 'react';

export default function VehicleClassDropdown(props) {

  const _handleChange = (e) => {
    props.handleClassChange(e.target.value, props.id);
  }

  const options = useMemo(() => {
    let opts = props.vehicleClasses.map((o) => <option key={o} id={o}>{o}</option>)
    opts.push(<option key="none" value="none" disabled hidden>No Class Selected</option>)
    opts.push(<option key="invalid" value="invalid" disabled hidden>Invalid Class</option>)
    return opts
  }, [props.vehicleClasses])

    return (<select id={props.id} 
                  className="swt-class-selector"
                  style={props.styles}
                  defaultValue={props.defaultValue}
                  onChange={_handleChange}>
    {options} </select>);
}
