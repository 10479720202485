import React from 'react';
import PDFBrandedHeader from './PDFBrandedHeader';
import PDFReportFooter from './PDFReportFooter';
import PDFStatView from './PDFStatView';
import { FormatSummaryData } from './UtilityFunctions';
import { Page, Text, Image, View, Document, StyleSheet, Link, Font } from '@react-pdf/renderer';

Font.register({
  family: "Roboto",
  src: "http://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf"
});

// Create styles
const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      fontFamily: 'Helvetica'
    },
    settings: {
      flexDirection: 'row'
    },
    labelsColumn: {
      flexDirection: 'column',
      marginLeft: '10px',
      minWidth: '300px',
      maxWidth: '300px',
    },
    section: {
      flexDirection: 'column',
      width: '90%',
      margin: '0 auto',
      padding: '10px 0',
      marginBottom: '20px',
      marginTop: '20px',
      fontFamily: 'Helvetica'
    },
    sectionHeader: {
      fontSize: '17px',
      paddingBottom: '3px',
      color: 'rgb(74,74,74)',
      fontWeight: 500,
    },
    sectionSubHeaderText: {
      fontSize: '13px',
      color: '#979a9e',
      padding: '1px 0',
      lineHeight: '2%'
    },
    label: {
      fontSize: '12px',
      margin: '2px',
      color: '#4c4c4d',
    },
    bold: {
      fontFamily: "Helvetica-Bold"
    },
    homeLineGraph: {
      height: '250px'
    },
    singleGraphContainer: {
      margin: '50px 0'
    },  
    multiGraphContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '50px 0'
    },
    graphWrapper: {
      width: '250px',
      height: '230px'
    },
    statViewRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      margin: '0 auto 30px auto',
    },
    subHeaderLink: {
      color: 'blue'
    },
    noDataMessage: {
      fontSize: '13px',
      color: '#979a9e',
      lineHeight: '2%',
      padding: '20px 0 30px'
    }
  });


export default function PDFReport(props){

  const units = props.displayInLbs ? "Lbs" : "Tons";

  let emissions = props.emissionsSummary.ghgTons;
  let emissionReductions = props.emissionsSummary.ghgTonsSaved;
  let costSavings = props.emissionsSummary.fuelCostSaved;
  let ghg = props.emissionsSummary.ghgTons;
  let co2 = props.emissionsSummary.CO2Tons;
  let ghgSaved = props.emissionsSummary.ghgTonsSaved;
  let CO2Saved = props.emissionsSummary.CO2TonsSaved;
  if(units === 'Lbs'){
      emissions = props.emissionsSummary.ghgLbs;
      emissionReductions = props.emissionsSummary.ghgLbsSaved;
      ghg = props.emissionsSummary.ghgLbs;
      co2 = props.emissionsSummary.CO2Lbs;
      ghgSaved = props.emissionsSummary.ghgLbsSaved;
      CO2Saved = props.emissionsSummary.CO2LbsSaved;
  }

  const selectedClassesText = () => {
    if (props.selectedVehicleClasses.length === props.vehicleClasses.length) {
      return 'All Classes'
    } else {
      return props.selectedVehicleClasses.join(', ')
    }
  }

  const formatDateRangeStrings = () => {
    let { dateRange } = props.emissionsSummary
    const options = {year: 'numeric', month: 'long', day: 'numeric'}
    let dates = []
    if (dateRange) {
      let split = dateRange.split('-')
      let begin = new Date(split[0]).toLocaleDateString('en-US', options)
      let end = new Date(split[1]).toLocaleDateString('en-US', options)
      dates.push(begin)
      dates.push(end)
    }
    return dates
  }

  return(
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <PDFBrandedHeader dbName={props.emissionsSummary.displayName}/>
          <View style={styles.section}>
            <View style={styles.settings}>
              <View style={styles.labelsColumn}>
                <Text style={styles.label}><Text style={styles.bold}>Report For: </Text>{props.emissionsSummary.displayName}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Generated On: </Text>{new Date().toLocaleDateString()}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Group: </Text>{props.group}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Vehicle Classes: </Text>{selectedClassesText()}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Date Range: </Text>{props.emissionsSummary.dateRange}</Text>
              </View>
              <View style={styles.labelsColumn}>
                <Text style={styles.label}><Text style={styles.bold}>Total Vehicles: </Text>{props.emissionsSummary.vehicles}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Gasoline Vehicles: </Text>{props.emissionsSummary.ice}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Diesel Vehicles: </Text>{props.emissionsSummary.diesel}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Plug-In Hybrid EVs: </Text>{props.emissionsSummary.phev}</Text>
                <Text style={styles.label}><Text style={styles.bold}>Battery EVs: </Text>{props.emissionsSummary.bev}</Text>
              </View>
            </View>
            <View style={styles.singleGraphContainer}>
              {props.lineChartBase64 && <Image style={styles.homeLineGraph} src={props.lineChartBase64} />}
            </View>
            <View style={styles.statViewRow}>
              <PDFStatView values={{value: FormatSummaryData('emissions', emissions), caption: 'Total GHG Emissions', subCaption: `(${units})`}} />
              <PDFStatView values={{value: FormatSummaryData('emissionReductions', emissionReductions), caption: 'Emission Reductions', subCaption: `(${units})`}} />
              <PDFStatView values={{value: FormatSummaryData('fuelCostSaved', costSavings), caption: 'Savings'}} />
            </View>
            <Text style={styles.sectionSubHeaderText}>
              This report summarizes your fleet emissions from {formatDateRangeStrings()[0]} to {formatDateRangeStrings()[1]}.
              Emit tracks your emissions based on your daily driving and the fuel type for each vehicle.
              You can find additional details for each vehicle by logging into your 
              Sawatch dashboard at <Link style={styles.subHeaderLink} src='https://dashboard.sawatchlabs.com/'>https://dashboard.sawatchlabs.com</Link>. Contact 
              your administrator with any questions.
            </Text>
          </View>
          <PDFReportFooter />
        </Page>
      {/* ****Page 2 Begins***** */}
      <Page size="A4" orientation="portrait" style={styles.page}>
      <PDFBrandedHeader dbName={props.emissionsSummary.displayName}/>
        <View style={styles.section} >
          <View>
            <Text style={styles.sectionHeader}>Emissions Summary</Text>
            <Text style={styles.sectionSubHeaderText}>
            A summary of the emissions produced by the selected portion of your fleet from {formatDateRangeStrings()[0]} to {formatDateRangeStrings()[1]}.
            </Text>
          </View>
          {props.graphDisplayCheck('emissions-summary') === false
          ?
          <Text style={styles.noDataMessage}>{props.handleNoDataMessageText('emissions-summary', 'pdfReport')}</Text>
          :
          <View style={styles.multiGraphContainer}>
            {props.emissionsChartBase64 && <Image style={styles.graphWrapper} src={props.emissionsChartBase64} />}
            {props.emissionsPieChartBase64 && <Image style={styles.graphWrapper} src={props.emissionsPieChartBase64} />}
          </View>
          }

          <View>
            <View style={styles.statViewRow}>
              <PDFStatView values={{value: FormatSummaryData('ghg', ghg), caption:`GHG ${units}`, subCaption: "(Includes GHG from kWh gen.)"}}/>
              <PDFStatView values={{value: FormatSummaryData('co2', co2), caption: `Carbon Dioxide ${units}`, subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('coLbs', props.emissionsSummary.coLbs), caption: "Carbon Monoxide Lbs", subCaption: "(Tailpipe)"}}/>
            </View>

            <View style={styles.statViewRow}>
              <PDFStatView values={{value: FormatSummaryData('noxLbs', props.emissionsSummary.noxLbs), caption: "Nitrous Oxides Lbs", subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('pm10Grams', props.emissionsSummary.pm10Grams), caption: "Particulate Matter 10\u00B5m in Grams", subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('pm25Grams', props.emissionsSummary.pm25Grams), caption: "Particulate Matter 2.5\u00B5m in Grams", subCaption: "(Tailpipe)"}}/>
            </View>
          </View>
        </View>
        <PDFReportFooter />
      </Page>
      {/* *****Page 3 begins */}
      <Page size="A4" orientation="portrait" style={styles.page}>
      <PDFBrandedHeader dbName={props.emissionsSummary.displayName}/>
        <View style={styles.section}>
          <View>
            <Text style={styles.sectionHeader}>Emissions Reduction Summary</Text>
            <Text style={styles.sectionSubHeaderText}>
            A summary of your emissions reduction for the alternative fuel vehicles in the selected portion of your fleet from {formatDateRangeStrings()[0]} to {formatDateRangeStrings()[1]}.
            </Text>
          </View>
          {props.graphDisplayCheck('emissions-reduction-summary') === false
          ?
          <Text style={styles.noDataMessage}>{props.handleNoDataMessageText('emissions-reduction-detail', 'pdfReport')}</Text>
          :
          <View style={styles.multiGraphContainer}>
            {props.emissionsReductionBarChartBase64 && <Image style={styles.graphWrapper} src={props.emissionsReductionBarChartBase64} />}
            {props.emissionsReductionPieChartBase64 && <Image style={styles.graphWrapper} src={props.emissionsReductionPieChartBase64} />}
          </View>
          }

          <View>
            <View style={styles.statViewRow}>
              <PDFStatView values={{value: FormatSummaryData('ghgSaved', ghgSaved), caption:`GHG ${units} Saved`, subCaption: "(Includes GHG from kWh gen.)"}} />
              <PDFStatView values={{value: FormatSummaryData('co2Saved', CO2Saved), caption:`Carbon Dioxide ${units} Saved`, subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('coLbsSaved', props.emissionsSummary.coLbsSaved), caption:"Carbon Monoxide Lbs Saved", subCaption: "(Tailpipe)"}}/>
            </View>

            <View style={styles.statViewRow}>
              <PDFStatView values={{value: FormatSummaryData('noxLbsSaved', props.emissionsSummary.noxLbsSaved), caption:"Nitrous Oxides Lbs Saved", subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('pm10GramsSaved', props.emissionsSummary.pm10GramsSaved), caption:"Particulate Matter 10\u00B5m in Grams Saved", subCaption: "(Tailpipe)"}}/>
              <PDFStatView values={{value: FormatSummaryData('pm25GramsSaved', props.emissionsSummary.pm25GramsSaved), caption:"Particulate Matter 2.5\u00B5m in Grams Saved", subCaption: "(Tailpipe)"}}/>
            </View>
          </View>
        </View>
        <PDFReportFooter />
        </Page>
    </Document>
  );
}