import styled from 'styled-components';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { MapContainer } from 'react-leaflet3';

const SMALL_VIEW_BREAKPOINT = "880px";
const CHART_VIEW_BREAKPOINT = "1070px";

export const DevToolsWrapper = styled.div`
    width: 90%;
    // Adjust the margin-left to add 64px, while still centering the content in its container
    // @TODO: This is a less than ideal workaround so that we don't need to adjust all of the products' CSS to the new menu bar. 
    // Should be removed once all products' CSS is overhauled. -NK 4/1/24
    margin-left: calc(3% + 64px); 
    margin-right: auto;
    font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
    box-sizing: border-box;
    margin-bottom: 3%;
`;

export const DevToolsNav= styled.nav`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-template-rows: 100%;
    height: 38px;
`;

export const DevToolsNavLink = styled(Link)`
    display: flex;
    background-color: #f5f5f5;
    color: #000;
    text-align: center;
    border: 1px solid #2f2f30;
    border-radius: 5px 5px 0 0;
    text-decoration: none;
    font-weight: 500;
    max-height: 37px;
    justify-content: center;
    align-items: center;
`;

export const DevToolsContentContainer = styled.div`
    border: 1px solid;
    border-top: none;
    padding: 30px 0;
`;

export const DevToolsTopContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    min-width: 700px;
`;

export const DevToolsResponsiveTopContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    min-width: 700px;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
    }
`;

export const DevToolsResponsiveServerDemandContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    min-width: 700px;
    @media(max-width: ${CHART_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
    }
`;

export const DevToolsResponsiveChargeToolsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    min-width: 700px;
    @media(max-width: ${CHART_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
    }
`;

export const DevToolsControls = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 270px;
    height: 350px;
    width: 30%;
    border: 1px solid;
`;

export const DevToolsResponsiveControls = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 270px;
    height: 350px;
    width: 30%;
    border: 1px solid;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
        height: auto;
        width: 100%;
        padding-bottom: 5px;
        margin-top: 8px;
    }
`;

export const DevToolsResponsiveChartControls = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 270px;
    height: 350px;
    width: 30%;
    border: 1px solid;
    @media(max-width: ${CHART_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
        height: auto;
        width: 100%;
        padding-bottom: 5px;
        margin-top: 8px;
    }
`;

export const DevToolsDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const DevToolsInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-top: 10px;
`

export const DevToolsInputLabel = styled.label`
    font-size: 18px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
`;

export const DevToolsChartExplorerTextContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const DevToolsChartExplorerTextColumn = styled.div`
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
`;

export const DevToolsSelectInput = styled.select`
    width: 250px;
    height: 35px;
    font-size: 16px;
    color: #4a4a4a;
    border: 1px solid #bbb;
    border-radius: 5px;
`;

export const DevToolsResponsiveSelectInput = styled.select`
    width: 250px;
    height: 35px;
    font-size: 16px;
    color: #4a4a4a;
    border: 1px solid #bbb;
    border-radius: 5px;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: auto;
        height: auto;
    }
`;

export const DevToolsSelectInputSmall = styled.select`
    width: 100px;
    height: 35px;
    font-size: 16px;
    color: #4a4a4a;
    border: 1px solid #bbb;
    border-radius: 5px;
`

export const DevToolsDatepickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 250px;
`

export const DevToolsResponsiveDatepickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 250px;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: auto;
    }
`

export const DevToolsDatepickers = styled(DatePicker)`
    width: 105px;
    height: 35px;
    font-size: 16px;
    color: #4a4a4a;
    border: 1px solid #bbb;
    border-radius: 5px;
    letter-spacing: .15px;
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
`

export const DevToolTableContainer = styled.div`
    max-height: 500px;
    overflow: scroll;
    margin: 5px 5px;
`;

export const DevToolsTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
`

export const DevToolsTableHead = styled.thead`
    background-color: rgb(87, 151, 182);
    color: #fff;
    height: 80px;
    position: sticky;
    top: 0;
    text-align: left;
`

export const DevToolsTableHeaders = styled.th`
    padding: 25px 30px 10px 30px;
    font-size: 16px;
    margin: 0;
    min-width: 80px;
`

export const DevToolsTableData = styled.td`
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 30px;
`

export const DevToolsTableRow = styled.tr`
    height: 50px;
    font-size: 14px;

    &:nth-child(odd) {
        background-color: rgb(245, 245, 245);
    }
    &:hover {
        color: rgb(87, 151, 182);
        font-weight: bold;
    }
`

export const DevToolsPaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin: 0 30px; 
`

export const DevToolsPaginationButtons = styled.button`
    cursor: pointer;
    padding: 0 7px;
`

export const DevToolsPaginationDetails = styled.span`
    padding: 0 5px;
    font-size: 12px;
    color: #74787d;
    letter-spacing: .1px;
`

export const DevToolsChartContainer = styled.div`
    position: relative;
    height: 400px;
    width: 95%;
    border: 0px solid;
    @media(max-width: ${CHART_VIEW_BREAKPOINT}) {
        width: auto;
        height: auto;
    }
`;

export const DevToolsMapContainer = styled(MapContainer)`
    height: 350px;
    width: 60%;
    border: 1px solid;
`;

export const DevToolsResponsiveMapContainer = styled(MapContainer)`
    height: 350px;
    width: 60%;
    border: 1px solid;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: 100%;
    }
`;

export const DevToolsChartExplorerText = styled.p`
    font-size: 15px;
    color: #4a4a4a;
    white-space: nowrap;
    padding: 0px;
    margin: 5px;
    text-align: left;
`

export const DevToolsNoDataMsg = styled.p`
    font-size: 16px;
    color: #4a4a4a;
    white-space: nowrap;
    padding: 10px 0;
    text-align: center;
`

export const DevToolsInputCheckbox = styled.input`
   height: 18px;
   width: 35px;
   margin: 0;
   padding: 0;
`;

export const DevToolsActionButton = styled.button`
  width: 150px;
  height: 50px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'var(--ezev-bluish)'};
  margin-right: 15px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: white;
  text-decoration: none;
  transition: all ease-in .1s;
  &:hover {
    transition: all ease-in .2s;
    background-color: var(--ezev-deep-aqua);
    cursor: pointer;
  }

  @media (max-width: 920px) {
    width: 120px;
    font-size: 17px;
  }
`;