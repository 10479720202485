import React, { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl, { Marker } from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
//import MapControls from "./MapControls";
import styled from "styled-components/macro";


const SubTitle = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
`;

export default function MapView(props){
    mapboxgl.accessToken = 'pk.eyJ1IjoiaGVsbTMzIiwiYSI6ImNpc29xNnBrdjA2YjUydG8xOHVqamw5dTcifQ.DnHI3KTx1anMoA1qBhLQxA';
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-104.98);
    const [lat, setLat] = useState(39.746);
    const [zoom, setZoom] = useState(12);
    /*
    const beginDate = props.beginDate ?? new Date();
    const endDate = props.endDate ?? new Date();
    const setBeginDate = props.setBeginDate ?? function(e){};
    const setEndDate = props.setEndDate ?? function(e){};
    const group = props.group ?? {};
    const groups = props.groups ?? []; 
    const vehicleClasses = props.vehicleClasses ?? [];
    const selectedVehicleClasses = props.selectedVehicleClasses ?? [];
    const setSelectedVehicleClasses = props.setSelectedVehicleClasses ?? function(d){};
    */
    const events = useMemo(() => {
        return props.events ?? [];
    }, [props.events])
    useEffect(() => {
        let lat = 0;
        let lng = 0;
        events.forEach((e) => {
            lat += e.latitude;
            lng += e.longitude;
        });
        lat = lat / events.length;
        lng = lng / events.length;
        setLat(lat);
        setLng(lng);
    }, [events]);

    useEffect(()=>{
        if (map.current && lat && lng)map.current.setCenter([lng, lat]);
    }, [lat, lng]);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            attributionControl: false,
            customAttribution: '',
            center: [lng, lat],
            zoom: zoom,
            minzoom: 6,
            pitch: 75,
            bearing: 270
        });
        map.current.on('load', () => {
            map.current.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14,
                'minzoom': 6
            });
            // add the DEM source as a terrain layer with exaggerated height
            map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            // add a sky layer that will show when the map is highly pitched
            map.current.addLayer({
            'id': 'sky',
            'type': 'sky',
            'paint': {
                'sky-type': 'atmosphere',
                'sky-atmosphere-sun': [0.0, 0.0],
                'sky-atmosphere-sun-intensity': 5
                }
            });
            if(events){
                events.forEach((e)=>{
                    addMarker(map, e)
                })
            }
        });
    });

    const addMarker = (m, e) => {
        //putting a jitter for rotten robbie on the Booster demo
        //sunset in production
        let jitter_lat = 0;
        let jitter_lon = 0;
        if(e.location === 11)jitter_lat += (Math.random() / 10000)
        if(e.location === 11)jitter_lon += (Math.random() / 10000)
        if(Math.random() > .5)jitter_lat *= .1;
        if(Math.random() > .5)jitter_lon *= .1;
        const p = new mapboxgl.Popup({offset: 25}).setHTML(
            `
            <span>UTC: ${e.utc_start}</span><br>
            <span>Address: ${e.address}</span><br>
            <span>Asset: ${e.asset_id}</span><br>
            <span>YMM: ${e.year} ${e.make} ${e.model}</span><br>
            `
        );
        const mark = new Marker({color: "red"})
        .setLngLat([e.longitude + jitter_lon, e.latitude + jitter_lat])
        .setPopup(p)
        .addTo(m.current);
        mark.getElement().addEventListener('click', () => {
            map.current.setCenter([e.longitude, e.latitude]);
            map.current.setZoom(15);
            map.current.setPitch(0);
        });
    }

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            //setLng(map.current.getCenter().lng.toFixed(4));
            //setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return(
    <>
            <SubTitle>
                Map shows events away from vehicle's depot.
        </SubTitle>
        <div ref={mapContainer} className="fueln-map-container" />
      {/* <MapControls 
            beginDate={beginDate}
            endDate={endDate}
            setBeginDate={function(e){setBeginDate(e)}}
            setEndDate={function(e){setEndDate(e)}}
            group={group}
            groups={groups}
            handleGroupChange={function(g){console.warn('not implemented yet')}}
            vehicleClasses={vehicleClasses}
            selectedVehicleClasses={selectedVehicleClasses}
            updateSelectedClasses={function (data) { setSelectedVehicleClasses(data) }}
       /> */}
    </>
    );
}