import React from "react";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import SuperAdminCandidatesTable from "./SuperAdminCandidatesTable";
import SuperAdminDatabasesTable from "./SuperAdminDatabasesTable";
import SuperAdminVehicleClassesTable from "./SuperAdminVehicleClassesTable";
import ControllerStatusTable from "./ControllerStatusTable";
import NodeStatusTable from "./NodeStatusTable";
import SuperAdminDatabaseSettings from "./SuperAdminDatabaseSettings";
import SuperAdminUsers from "./SuperAdminUsers";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { NavigationTabs } from "@sawatchlabs/swt-components";
import SuperAdminUpfitsTable from "./SuperAdminUpfitsTable";
import { Roles } from "../../utility-components/UserUtils";

export default function CDRManagementMain(props) {
  const { user, db, apiURL, passwordRules, dbSettings } = props;
  const handleFetchErrors = (res) => {
    if (!res.ok) { throw Error(res.statusText); }
    return res;
  }

  const AdminRoute = ({ component: Component, user, db, apiURL, createNewUserPassword, dbSettings, updateDatabases, ...rest}) => {
      return (
        <Route exact {...rest}
          render={(props) =>
          <Component {...props} {...rest} handleFetchErrors={handleFetchErrors} createNewUserPassword={createNewUserPassword} passwordRules={passwordRules} user={user} db={db} apiURL={apiURL} dbSettings={dbSettings} updateDatabases={updateDatabases}/>} 
        />);
  };

  return (
    <S.AdminContainer>
      <h2 className="swt-admin-console-ttl">CDR Admin Console</h2>
        { user.role >= Roles.SuperAdmin && 
          <>
            <NavigationTabs>
              <NavLink to={`/cdr-admin/databases`}>Database Status</NavLink> 
              <NavLink to={`/cdr-admin/accounts`}>Database Settings</NavLink> 
              <NavLink to={`/cdr-admin/candidates`}>Candidates</NavLink> 
              <NavLink to={`/cdr-admin/users`}>Users</NavLink> 
              <NavLink to={`/cdr-admin/vehicle-classes`}>Vehicle Classes</NavLink> 
              <NavLink to={`/cdr-admin/controller-status`}>Controller Status</NavLink> 
              <NavLink to={`/cdr-admin/node-status`}>Node Status</NavLink> 
              <NavLink to={`/cdr-admin/upfits`}>Upfits</NavLink>
            </NavigationTabs>
            <S.AdminContentWrapper>
                  <Switch>
                    <AdminRoute exact path="/cdr-admin/candidates" component={SuperAdminCandidatesTable} user={user} db={db} dbSettings={dbSettings} apiURL={apiURL}/>
                    <AdminRoute exact path="/cdr-admin/databases" component={SuperAdminDatabasesTable} activeDb={db} user={user} apiURL={apiURL} getDbUiUpdateStatus={props.getDbUiUpdateStatus}/>
                    <AdminRoute exact path="/cdr-admin/vehicle-classes" component={SuperAdminVehicleClassesTable} user={user} apiURL={apiURL}/>
                    <AdminRoute exact path="/cdr-admin/controller-status" component={ControllerStatusTable} user={user} apiURL={apiURL} />
                    <AdminRoute exact path="/cdr-admin/node-status" component={NodeStatusTable} user={user} apiURL={apiURL} />
                    <AdminRoute exact path="/cdr-admin/accounts" component={SuperAdminDatabaseSettings} product={'accounts'} user={user} db={db} apiURL={apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} />
                    <AdminRoute exact path="/cdr-admin/users" component={SuperAdminUsers} user={user} db={db} apiURL={apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} updateDatabases={props.updateDatabases}/>
                    <AdminRoute exact path="/cdr-admin/upfits" component={SuperAdminUpfitsTable} user={user} apiURL={apiURL} dbSettings={dbSettings} />
                    <Redirect to="/cdr-admin/databases" />
                  </Switch>
            </S.AdminContentWrapper>
          </>
        }
      </S.AdminContainer>
  );
}
