import React from "react";
import { addCommas } from "./idling-table-helper-functions";

export default function IdlingSummaryPanels(props){

  const { events } = props;
  // RC fix map
  let vins = [];
  events.map((e) => {
    if(vins.indexOf(e.vin) === -1)vins.push(e.vin);
    return null;
  })
  return(
    <div className="idling-stat-box-wrapper">
      <div className='idling-stat-box'>
        <span className='idling-stat-box-metric'>
          {addCommas(vins.length)}
        </span>
        <span className='idling-stat-box-label'>
          Vehicles with Incidents
        </span>
      </div>

      <div className='idling-stat-box'>
        <span className='idling-stat-box-metric'>
          {addCommas(events.length)}
        </span>
        <span className='idling-stat-box-label'>
          Incidents Count
        </span>
      </div>
    </div>
  )
}