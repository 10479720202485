import styled from "styled-components/macro"
import { Link } from "react-router-dom";
import logo from "../../images/ezIO-logo.png"
import * as colors from "./ColorScheme";
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  margin-top: 38px;
  min-width: 600px;
`;

export const LogoContainer = styled.div`
  margin-right: 25px; 
  height: 77px;
  width: 68px;
  display: inline-block;
  background-image: url(${logo});
  background-size: cover;
`;

export const LHSContentWrapper = styled.div`

`;

export const HeaderAndFleetWrapper = styled.div`
  display: inline-block;
`;

export const FleetNameContainer = styled.span`
font-family: "Arimo", "Roboto";
font-size: 46px;
font-style: normal;
font-weight: 400;
line-height: 54px;
letter-spacing: 0em;
text-align: left;
color: ${colors.ezio_dark_grey};
`

export const StatusContainer = styled.div`
font-family: "Arimo", "Roboto";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 42px;
letter-spacing: 0em;
text-align: left;
color: ${colors.ezio_medium_grey};
`

export const NavLinkList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${colors.ezio_dark_grey};
  margin-top: 1em;
`;

export const OptionLink = styled(Link)`
display: inline-block;
text-decoration: none;
color: ${colors.ezio_dark_grey};
padding: 8px 15px 8px 15px;
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;


&:hover   {
  background-color: rgba(3, 94, 91, 0.2);
}

`

const boldOnHover = `
  &:hover   {
    text-shadow:0px 0px 0.1px black;
    cursor: pointer;
   text-decoration-skip-ink: all;
  }
`

export const NavLinkListItem = styled.div`
font-family: "Arimo", "Roboto";
margin-right: 1em;
white-space: nowrap;
`;

export const NavLink = styled(Link)<{ selected?: boolean }>`
  font-family: "Arimo", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: inherit;
  padding-bottom: 2px;
  ${(props) => props.selected && `
      border-bottom: 1.5px solid;
    `}
  ${boldOnHover}
`

export const LogoLink = styled(Link)`
display: block;
height: 100%;
width: 100%;
`

export const DropdownMenu = styled.span<{ selected?: boolean }>`
  font-family: "Arimo", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  padding-bottom: 2px;
  ${(props) => props.selected && `
    border-bottom: 1.5px solid;
  `}
  ${boldOnHover}
`

const DropdownOptionsCommon = `
position: absolute;
display: flex;
flex-direction: column;
background: #FFFFFF;
border: 2px solid ${colors.ezio_light_grey};

box-sizing: border-box;
z-index: 1500;
`
export const AdminDropdownOptions = styled.div`
${DropdownOptionsCommon}
min-width: 10rem;
`

export const LocationsDropdownOptions = styled.div`
${DropdownOptionsCommon}
min-width: 150px;
`

export const DownArrowWrapper = styled.div`
  display: inline;
  svg {
    margin-left: 3px;
    position: relative;
    top: -1px;
  }
`

export const AdditionalInfoContainer = styled.div`
  margin-top: 32px;
`

export const AdditionalInfoCopy = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 30px;
color: #74787D;
padding-top: 10px;
padding-bottom: 10px;
text-decoration: none;
`

export const AdditionalInfoEmailLink = styled.a`
font-family: "Arimo", "Roboto";
text-decoration: none;
color: #035E5B;
&:hover {
    color: #035E5B;
    text-decoration: underline;
}
`