import React from "react";
import { useMemo } from "react";

export default function PercentDropDown(props) {
  const { selectedPercent } = props;

  const percents = useMemo(() => {
    let i = 0;
    let arr = [];
    while (i < 100) {
      i += 5;
      if(i % 10 === 0 || i === 5)arr.push(i);
    }
    return arr;
  }, []);
  return (
    <select
      className="nrel-group-selector"
      id="percent"
      disabled ={props.disabled}
      onChange={(e) => props.handleChange(e.currentTarget.value)}
      value={props.disabled ? 'disabled' : parseInt(selectedPercent)}
    >
      {percents.map((p) => {
        return (
          <option key={`${p}-id`} value={p}>
            {`${p}%`}
          </option>
        );
      })}
      <option value={'disabled'} hidden unselectable='true'>NA</option>
    </select>
  );
}
