import React, { useMemo, useCallback } from "react";
import styled from "styled-components/macro";
import TableView from "./TableView";
import { CSVLink } from "react-csv";

const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
`;

const TableNote = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
`;

const NoDataText = styled.div`
  min-width: 250px;
  font-size: 18px;
  text-align: center;
`;

const DownloadButton = styled.div`
    width: 146px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 44px;
    border: 1px solid rgb(187, 187, 187);
    background-color: #13908b;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    text-decoration-thickness: 0;
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .56px;
    text-align: center;
    color: white;
    text-decoration-color: white;
    &:hover{
      box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 18%);
    }
  `;

export default function WeeklyEventsTable(props){

    const { events, beginDate, endDate } = props;

    const handleOnClick = () => {console.log('click');}
    
    const tableColumns = [
      {Header: "Asset Id", accessor: "asset_id", sortType: "basic"},
      {Header: "Year", accessor: "year", sortType: "basic"},
      {Header: "Make", accessor: "make", sortType: "basic"},
      {Header: "Model", accessor: "model", sortType: "basic"},
    ];

    const vinSet = new Set(events.map((r) => {return r.vin}));
    const vins = [...vinSet];

    const getWeekNumber = function(date){
      let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };

    const sortByDate = function( a, b ) {
      if ( a.ts < b.ts )return -1;
      if ( a.ts > b.ts )return 1;
      return 0;
    }

    const weeksInRange = useCallback((begin, end) => {
      const woys = [];
      if(begin === null || end === null)return [];
      let d = new Date(begin.getTime());
      while(d <= end){
        const wn = getWeekNumber(d);
        woys.push(wn);
        d.setDate(d.getDate() + 1);
      }
      const woySet = new Set(woys);
      return [...woySet];
    }, []);
    
    const wir = useMemo(() => {
      if(beginDate === null || endDate === null)return [];
      else{
        const w = weeksInRange(beginDate, endDate);
        return w;
      }
    }, [beginDate, endDate, weeksInRange]);

    const compileIntoWeeks = function (evts) {
        function getWeek(ts){
          if(typeof ts === "undefined")return 0;
          let d = Date.parse(ts)
          d = new Date(d);
          return getWeekNumber(d);
        }

        const maxWeek = Math.max(0, getWeek(endDate));
        const minWeek = Math.max(0, getWeek(beginDate));
        const we = []
        let i = minWeek;
        while(i <= maxWeek){
          we.push({woy: i, incidents: 0})
          i++;
        }
        let arr = evts.sort(sortByDate);
        arr.forEach((a) => {
          const woy = getWeek(a.utc_start);
          we.forEach((w) => {if(w.woy === woy)w.incidents++});
        });
        return we;
    }

    const weeklyObjects = [];
    vins.forEach((v) => {
      const f = events.filter((e) => v === e.vin);
      const weeklyEvents = compileIntoWeeks(f);
      let total = 0;
      const o = {
        vin: v,
        asset_id: f[0].asset_id,
        year: f[0].year,
        make: f[0].make,
        model: f[0].model,
        vehicle_class: f[0].vehicle_class};
      weeklyEvents.forEach((e) =>{
        o[`${e.woy}`] = e.incidents;
        total += e.incidents;
      });
      o.total = total;
      weeklyObjects.push(o);
    });

    wir.forEach((w) => {
      const l = {Header: `${w}`, accessor: `${w}`, sortType: "basic"};
      tableColumns.push(l);
    });
    tableColumns.push({Header: `Total`, accessor: `total`, sortType: "basic"});
    const csvHeaders = tableColumns.slice();
    csvHeaders.unshift({Header: "Stop", accessor: "endDate", sortType: "basic"});
    csvHeaders.unshift({Header: "Start", accessor: "beginDate", sortType: "basic"});
    csvHeaders.forEach((h) => {h.key = h.accessor; h.label = h.Header});

    const colType = (col) => {
      if(col === 'Asset Id' || col === 'Model'){
        return "fueln-table-header-long";
      }
      return "fueln-table-header";
    }

    const csvData = () => {
      return weeklyObjects.map((r) => {
        r.beginDate = beginDate;
        r.endDate = endDate;
        return r;
      })
    }

    const highlight = (cell) =>{
      if(!isNaN(parseInt(cell.column.Header))){
        //if(cell.value > 2)return "speedn-table-cell-highlight";
      }
      return "";
    }

    if(typeof events === "undefined" || events.length < 1 || events === null || typeof weeklyObjects === 'undefined'){
        return(<TableWrapper><br/><br/><br/><NoDataText>No Data</NoDataText></TableWrapper>)
    }

    return(
      <TableWrapper>
        <TableNote>Fueling Event Counts by Week of Year</TableNote>
        <TableView 
          columns={tableColumns}
          data={weeklyObjects}
          colType={colType}
          handleOnClick={handleOnClick}
          highlight={highlight}
          nolink={true}
          handleSort={function(){}}
        />
        <CSVLink
          id="emit-rpt"
          data={csvData()}
          headers={csvHeaders}
          target="_blank"
          filename={"sawatch-labs-fuel-weekly-rpt.csv"}
          style={{ textDecoration: "none" }}
        >
          <DownloadButton>
            Download CSV
          </DownloadButton>
        </CSVLink>
      </TableWrapper>
    )
}