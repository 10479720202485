import React, { Suspense } from 'react';
import { preventNonIntegers,preventPasteNonIntegers } from './UtilityFunctions';

//code split imports
const UpdateLocationMap = React.lazy(() => import('./UpdateLocationMap'));

class UpdateLocationForm extends React.Component {
  render(){
    var locationDetails = this.props.locationDetails;
    return (
      <div className="ionev-charge-loc-form-wrapper first">
        <div className="ionev-charge-loc-form-content">
          <p className="ionev-subheader-1">Details</p>
          <form className="ionev-charge-form" onSubmit={this.props.handleSubmit}>
            <div className="ionev-charge-loc-wrapper">
              {locationDetails.errors.length > 0 &&
                <div className="ionev-charge-form-error-wrapper">
                  <p className="ionev-charge-form-error header">There was an error submitting your form</p>
                  <ul className="ionev-charge-form-error-list">
                    {locationDetails.errors.map((e, i) => {
                      return <li key={i} className="ionev-charge-loc-form-error">{e}</li>
                    })}
                  </ul>
                </div>
              }
              <div className="ionev-loc-details split">
                <div className="ionev-loc-details-lhs">
                  <p className="ionev-charge-loc-label nickname">Location Nickname</p>
                  <input
                    className="ionev-charge-loc-input nickname"
                    type="text"
                    name="nickname"
                    value={locationDetails.nickname}
                    onChange={this.props.handleInputChange}
                  />
                  <p className="ionev-charge-loc-label level-1">Level 1 Chargers</p>
                  <input
                    className="ionev-charge-loc-input level-1"
                    type="number"
                    name="level1"
                    value={locationDetails.level1}
                    onChange={this.props.handleInputChange}
                    onKeyDown={(el) => preventNonIntegers(el)}
                    onPaste={(el) => preventPasteNonIntegers(el)}
                    onWheel={(e) => e.target.blur()}
                  />
                  <p className="ionev-charge-loc-label nickname">Location Type</p>
                  <select className="ionev-location-type-selector ionev-selector-1" onChange={this.props.handleSelectorChange} value={locationDetails.landuse}>
                    <option>Unclassified</option>
                    <option>Fleet</option>
                    <option>Public</option>
                    <option>Residential</option>
                    <option>Workplace</option>
                  </select>
                </div>
                <div className="ionev-loc-details-rhs">
                  <p className="ionev-charge-loc-label level-2">Level 2 Chargers</p>
                  <input
                    className="ionev-charge-loc-input level-2"
                    type="number"
                    name="level2"
                    value={locationDetails.level2}
                    onChange={this.props.handleInputChange}
                    onKeyDown={(el) => preventNonIntegers(el)}
                    onPaste={(el) => preventPasteNonIntegers(el)}
                    onWheel={(e) => e.target.blur()}
                  />
                  <p className="ionev-charge-loc-label dc-fast">DC Fast Chargers</p>
                  <input
                    className="ionev-charge-loc-input dc-fast"
                    type="number"
                    name="dcfast"
                    value={locationDetails.dcfast}
                    onChange={this.props.handleInputChange}
                    onKeyDown={(el) => preventNonIntegers(el)}
                    onPaste={(el) => preventPasteNonIntegers(el)}
                    onWheel={(e) => e.target.blur()}

                  />
                </div>
              </div>

              <div className="ionev-loc-site">
                <p className="ionev-subheader-1">Location</p>
                <p className="ionev-charge-loc-label address">Update Address</p>
                <input
                  className="ionev-charge-loc-input address"
                  type="text"
                  name="address"
                  value={locationDetails.address}
                  onChange={this.props.handleInputChange}
                /><br/><br/>
                <p className="ionev-charge-loc-label address">Update Map Location</p>
                <p className="ionev-charge-loc-sublabel">Click on the map to select a new marker location for this address</p>
                <Suspense fallback={<></>}>
                  <UpdateLocationMap
                    location={[locationDetails.og_latitude, locationDetails.og_longitude]}
                    newLoc={[locationDetails.new_latitude, locationDetails.new_longitude]}
                    handleClick={this.props.handleMapClick}
                    user={this.props.user}
                  />
                </Suspense>
              </div>

            </div>
            <input type="submit" value="Submit" className="ionev-charge-form-submit" />
            <button className="ionev-charge-loc-back" onClick={this.props.handleBack}>Back</button>
          </form>

        </div>
      </div>
    );
  }
}

export default UpdateLocationForm;
