import React from 'react';
import WeekdaySelector from './WeekdaySelector';
import DateSelector from './DateSelector';
import TimeSelector from './TimeSelector';
import LocationSelector from './LocationSelector';
import GroupSelector from './GroupSelector';
import ClassSelector from './ClassSelector';
import { DetailsSelector } from './Details';
import TimeDeltaSelector from './TimeDeltaSelector';
import { withRouter } from 'react-router-dom';
import { Details } from './Details';

function UserSettings(props) {
  const [hovered, setHover] = React.useState('');
  const [hoverText, setHoverText] = React.useState('');
  const [showDetails, setShowDetails] = React.useState(false);
  function getExplanation() {
    var type = hovered;
    switch (type) {
      case 'timeDelta':
        return 'Time segments can be modified and respected on the Duty Cycle Details page.';
      case 'pending-time':
        return 'Time filtering will be released in v2';
      case 'pending-groups':
        return 'Group filtering will be released in v2';
      default:
        return null;
    }
  }

  function clearFilters(location) {
    let r = window.confirm(
      `Are you sure you want to clear ${
        !location ? 'all filters' : 'all locations'
      }?\r\n${''}\r\n If you proceed, you will be redirected to the Summary Vehicle Metrics Page`
    );
    if (r === true) {
      window.location.href = '/shifted/vehicles';
    } else {
      return null;
    }
  }

  const settingUpdated = () => {
    if (Object.values(props.settingChangeTracker).indexOf(true) > -1)
      return true;
    else {
      return false;
    }
  };

  return (
    <>
      {hovered !== '' && (
        <div className={`sftd-onHoverMessage ${hovered}`}>
          {getExplanation()}
        </div>
      )}
      <div className="sftd-user-settings">
        <LocationSelector
          settingChangeTracker={props.settingChangeTracker}
          updateSettings={props.updateSettings}
          locations={props.locations}
          handleLocationChange={props.handleLocationChange}
          handleSettingsChange={props.handleSettingsChange}
          clearFilters={clearFilters}
          selectedLocIds={props.selectedLocIds}
          // singleVehicleLocationSelectHandler={
          //   singleVehicleLocationSelectHandler
          // }
        />

        <DateSelector
          className="sftd-date-selector"
          dateStart={props.dateStart}
          dateEnd={props.dateEnd}
          handleStartDateChange={e => props.handleDateChange(e, 'start')}
          handleEndDateChange={e => props.handleDateChange(e, 'stop')}
          fleetStart={props.fleetStart}
          fleetStop={props.fleetStop}
        />
        <WeekdaySelector
          selectedDays={props.selectedDays}
          handleWeekdayChange={e =>
            props.handleSettingsChange(e, 'selectedDays')
          }
        />
        {props.location.pathname !== '/shifted/singleVehicle' && (
          <div
            onMouseEnter={() => setHover('pending-time')}
            onMouseLeave={() => setHover('')}
          >
            <TimeSelector
              startTime={props.startTime}
              stopTime={props.stopTime}
              handleTimeChange={props.handleSettingsChange}
            />
          </div>
        )}

        <div
          onMouseEnter={
            props.location.pathname !== '/shifted/dataVisualization'
              ? () => setHover('timeDelta')
              : null
          }
          onMouseLeave={() => setHover('')}
        >
          <TimeDeltaSelector
            segmentTimeDelta={props.segmentTimeDelta}
            handleTimeSegmentChange={e =>
              props.handleSettingsChange(e, 'segmentTimeDelta')
            }
          />
        </div>

        <div
          onMouseEnter={() => setHover('pending-groups')}
          onMouseLeave={() => setHover('')}
        >
          <GroupSelector />
        </div>

        <ClassSelector
          vehicleClasses={props.vehicleClasses}
          updateSettings={props.updateSettings}
          handleVehicleClassesChange={props.handleVehicleClassesChange}
          handleClassRadioBtn={props.handleSettingsChange}
        />
        <DetailsSelector
          handleDetail={() => setShowDetails(!showDetails)}
          showDetails={showDetails}
        />
        <div
          className="sftd-setting-refresh"
          id={settingUpdated() ? 'active' : null}
          onMouseEnter={() => setHoverText('update')}
          onMouseLeave={() => setHoverText('unselected')}
          onClick={props.updateSettings}
        >
          {hoverText === 'update' && <p className="update">Update Results</p>}
        </div>
        <div
          onMouseEnter={() => setHoverText('clear')}
          onMouseLeave={() => setHoverText('unselected')}
          className="sftd-setting-reset"
          onClick={
            props.location.pathname !== '/shifted/singleVehicle'
              ? props.resetFilters
              : clearFilters
          }
        >
          {hoverText === 'clear' && <p className="clear">Clear Filters</p>}
        </div>
        <hr className="sftd-setting-line"></hr>
      </div>
      {showDetails && (
        <Details
          totalVehicleCount={props.totalVehicleCount}
          avgTotalUtil={props.avgTotalUtil}
          selectedVehicles={props.selectedVehicles}
          selectedLocations={props.selectedLocations}
          currentAverage={props.currentAverage}
          hoursAtCapacity={props.hoursAtCapacity}
          hoursAboveCapacity={props.hoursAboveCapacity}
          nonServicedCycles={props.nonServicedCycles}
          totalHours={props.totalHours}
          locations={props.locations}
          vehicleUtil={props.vehicleUtil}
        />
      )}
    </>
  );
}
export default withRouter(UserSettings);
