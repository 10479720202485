import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function GraphKwhByLoc(props) {
  // truncate long addresses/nicknames
  var addresses = props.data.address.map(v => {
    if(v.length < 18)return v;
    else return `${v.slice(0, 18)}...`;
  });
  const arbitraryStackKey = "stack1";

  const legendOpts = {
    display: true,
    position: 'bottom',
    labels: {
      font: {
        size: 11,
        weight: 'normal',
        },
    }
  };

  const options = { 
        plugins:{
          legend: legendOpts,
        },
       layout: {
         padding: {
           right: 15
         }
       },
       maintainAspectRatio: false,
       scales: {
        y: {
          beginAtZero: true,
        },
       }
     };
  const data = {
    labels: addresses,
    datasets: [
      {
        stack: arbitraryStackKey,
        label: 'Total kWh Uptake',
        data: props.data.kwh,
        backgroundColor: "rgba(87, 151, 182, 0.6)",
        borderColor: "rgb(87, 151, 182)",
        borderWidth: 2
      },
      {
        stack: arbitraryStackKey,
        label: 'Total kWh Missed',
        data: props.data.missedKwh,
        backgroundColor: "rgba(130, 140, 148, 0.6)",
        borderColor: "rgb(130, 140, 148)",
        borderWidth: 2
      }
    ]
  };


  return (
    <>
      <h4 className = 'ionev-graph-title'>Top Locations by kWh Consumed</h4>
      < Bar options={options} data={data} width={80} height={280}/>
    </>
  );
}
