import React, { useCallback, useState, useRef, Suspense, useEffect} from "react";
import SeasonalRateForm from "./swt-admin-seasonalRate-form";
import SeasonalRateTable from "./SeasonalRatesTable";
import Search from "./search";
import Loading from "../swt-loading";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { columnsToExcelHeaders, seasonalRatesTableColumns, rowsToData } from "./TableHelpers";
import { Roles } from "../../utility-components/UserUtils";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";

export default function SeasonalRatesController(props) { 
  const [kwRates, setKwRates] = useState(null);
  const [selectedRatePkid, setSelectedRatePkid] = useState(null);
  const [downloadData, setdownloadData] = useState();
  const [matchingRates, setMatchingRates] = useState();
  const [infoShown, setInfoShown] = useState(false)
  const skipPageResetRef = useRef(true);

  useEffect(() => {
    skipPageResetRef.current = true
  },[matchingRates])

  const dowArray = [
    "mondays",
    "tuesdays",
    "wednesdays",
    "thursdays",
    "fridays",
    "saturdays",
    "sundays",
  ];
  const touArray = ["startHour", "stopHour", "startMinute", "stopMinute"];
  const dateArray = [
    "startMonth",
    "stopMonth",
    "startDayOfMonth",
    "stopDayOfMonth",
  ];

  const { apiURL, db, user, dbDisplayName } = props;

  const tableColumns = seasonalRatesTableColumns(props.dbSettings);

  let newRateObj = {
    nickname: null,
    dbname: props.db,
    kwhRate: 0.11,
    startMonth: null,
    startDayOfMonth: null,
    stopMonth: null,
    stopDayOfMonth: null,
    mondays: null,
    tuesdays: null,
    wednesdays: null,
    thursdays: null,
    fridays: null,
    saturdays: null,
    sundays: null,
    startHour: null,
    startMinute: null,
    stopHour: null,
    stopMinute: null,
  };

  const _getKwhRates = useCallback(() => {
    let url = apiURL + "getKwhRates?dbname=";
    fetch(`${url}${db}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message !== "success")
          return window.alert(
            SERVER_ERRORED_MESSAGE
          );
        let kwRateResp = [];
        if (resp && resp.data.length > 0) {
          kwRateResp = resp.data;
        }
        setSelectedRatePkid(null);
        setKwRates(kwRateResp);
      });
  }, [apiURL, db, user.token]);

  useEffect(() => {
    _getKwhRates();
  }, [_getKwhRates]);

  function addKwhRate(obj) {
    let url = apiURL + "upsertKwhRate?dbname=";
    fetch(`${url}${db}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(obj),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message !== "success") {
          window.alert(
            SERVER_ERRORED_MESSAGE
          );
        } else {
          if (obj.pkid === undefined) {
            window.alert("New rate has been added.");
            _getKwhRates();
          } else {
            window.alert("Seasonal rate updated.");
          _getKwhRates();
          }}
      });
  }

  function _deleteRate(obj) {
    let url = apiURL + "deleteKwhRate?dbname=";
    fetch(`${url}${db}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(obj),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message !== "success") {
          window.alert(
            SERVER_ERRORED_MESSAGE
          );
        } else {
          alert('Rate has been deleted.')
          _getKwhRates();
        }
      });
  }

  function handleModalClose() {
    _getKwhRates();
    setSelectedRatePkid(null);
  }

  function confirmDelete(rateObj) {
    if (window.confirm(`Are you sure you want to delete ${rateObj.rateName} from ${dbDisplayName}?`)) {
      _deleteRate(rateObj);
    }
  }
 
  if (!kwRates) {return <Loading /> }
  const selectedRate = kwRates.filter((r) => r.pkid === selectedRatePkid);

  return (
    <>
    <S.TableHeaderContainer>
      <S.FlexWrapper>
        <S.AdminTableExplainTextPrimary>List of all variable electricity rates for {dbDisplayName}.</S.AdminTableExplainTextPrimary>
        <div style={{display: 'inline-block'}}>
        <S.InfoIcon src="https://images.sawatchlabs.com/info-icon.png" alt="Infomation Icon" onMouseOver={() => setInfoShown(true)} onMouseOut={() => setInfoShown(false)} />
        {infoShown &&
            <S.InfoTextContainer>
              <S.InfoText>
              If this table is empty, the system-wide electricity rate provided in the Settings tab is the only electricity rate provided.
              </S.InfoText>
            </S.InfoTextContainer>}
        </div>

      </S.FlexWrapper>
      <S.AdminTableExplainTextSub>This table lists all variable electricity rates. Seasonal, time-of-use, and other variable rates can be added and edited.</S.AdminTableExplainTextSub> 
      <S.SearchContainer>
        <Search allValues={kwRates} setMatchingValues={setMatchingRates} disabled={kwRates ? false : true} />
      </S.SearchContainer>

      <S.TableHeaderContainerRow>
        { user.role >= Roles.PartnerAdmin && 
          <S.CtaButton data-testid={"addRateBtn-testid"} onClick={() => setSelectedRatePkid("new")}>Add</S.CtaButton>
        }
        { user.role <= Roles.FleetAdmin && // Add button restricted for fleet admins
          <S.CtaButton data-testid={"addRateBtn-testid"} disabled={true}>Add</S.CtaButton>
        }
        <Suspense fallback={<div></div>}>
          <ExcelDownloadButton
            downloadType={"seasonalRatesDownload"}
            columns = {columnsToExcelHeaders(tableColumns)}
            data={rowsToData(tableColumns, downloadData, ((a, b) => a.nickname > b.nickname ? 1 : -1))}
            dbDisplayName={dbDisplayName}
            dbSettings={props.dbSettings}
            userSettings={props.user.userSettings}
          />
        </Suspense>
      </S.TableHeaderContainerRow>
    </S.TableHeaderContainer>

    {matchingRates && (
      <SeasonalRateTable
          skipPageResetRef={skipPageResetRef}
          kwRates={kwRates}
          editRate={(pkid) => setSelectedRatePkid(pkid)}
          dowArray={dowArray}
          deleteRate={confirmDelete}
          setdownloadData={setdownloadData}
          matchingRates={matchingRates}
          dbSettings={props.dbSettings}
          user={user}
        />
    )}
      {selectedRatePkid !== null && (
        <SeasonalRateForm
          touArray={touArray}
          dateArray={dateArray}
          dowArray={dowArray}
          selectedRate={
            selectedRatePkid === "new" ? newRateObj : selectedRate[0]
          }
          handleModalClose={handleModalClose}
          addKwhRate={addKwhRate}
          closeModal={() => setSelectedRatePkid(null)}
          dbSettings={props.dbSettings}
        />
      )}
   </>
  );
}
