import React from "react";
import { Doughnut } from "react-chartjs-2";

export default function InstallVisual(props) {
  if (props.totals === undefined) return null;
  const options = {
    title: {
      display: false,
      text: "Install Status",
      fontSize: 14,
      fontStyle: "bold",
      fontColor: "#4a4a4a",
    },
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: "#e8e9ea",
      },
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: ["Installed", "Pending", "No Show"],
    datasets: [
      {
        data: [
          props.totals.installed,
          (props.totals.targetInstalls - props.totals.installed),
          props.totals.noShows,
        ],
        backgroundColor: [
          "rgba(68, 92, 115, 0.9)",
          "rgba(87,151,182, 0.7)",
          "rgba(193, 196, 199, 0.5)",
        ],
        hoverBackgroundColor: [
          "rgba(87,151,182, 1)",
          "rgba(87,151,182, 0.8)",
          "rgba(87,151,182, 0.6)",
        ],
      },
    ],
  };
  const legendOpts = {
    display: true,
    position: "bottom",
    fullWidth: false,
    reverse: false,
    labels: {
      fontColor: "#4a4a4a",
    },
  };
  return (
    <div className="visual-wrapper">
      <Doughnut
        id="instl-visual"
        data={data}
        legend={legendOpts}
        options={options}
        width={10}
        height={140}
      />
    </div>
  );
}
