import React from 'react';
import styled from 'styled-components';
import { FormatData } from './UtilityFunctions';
import exceljs from 'exceljs';
import FileSaver  from 'file-saver';
import { logoBase64 } from './Logo';

const DOWNLOAD_BUTTON_COLOR = '#356E77';

const Button = styled.button`
    padding: 0;
    border: none;
    background: none;
`

const ExcelDownload = styled.div`
    width: 165px;
    height: 50px;
    background-color: ${DOWNLOAD_BUTTON_COLOR};
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .3px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      filter: drop-shadow(rgba(74, 74, 74, 0.18) 4px 2px 10px);
    }
  `;

const BLUE = '0000EE'
const WHITE = 'FFFFFF'
const PRUSSIAN_BLUE = '12365B'

export default function ExcelDownloadButton (props) {
    const formattedData = formatTableData(props);
    const filename = buildFilename(props);
    return (
      <Button
      onClick={()=>execExcelDownload(formattedData, filename)}
      target="_blank"
      style={{ textDecoration: "none" }}
      >
    <ExcelDownload>Download</ExcelDownload>
    </Button>
    )
}

function buildFilename(props) {
    let filename = "sawatch-labs-emit-emissions-";
    if (props.csvType === 'altFuel') {
        filename += "reduction-"
    }
    filename += `report-${props.dbDisplayName.replaceAll(" ", "-")}-${(new Date()).toLocaleDateString().replaceAll('/', '-')}.xlsx`;
    return filename;
}

async function saveFile(workbook, filename) {
    const buf = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename);
}

function adjustColumnWidth(worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    });
  }

function createExcelDoc(formattedData) {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('sheet1', {views: [{showGridLines:false}]});
    const imgId = workbook.addImage({
        base64: logoBase64,
        extension: 'png'
    });
    worksheet.addRows(formattedData.data);

    // Set style of title block headers
    worksheet.getCell('A1').font = {
        bold: true,
        size: 14
    };
    worksheet.getCell('A2').font = {
        bold: true
    };
    worksheet.getCell(`A${formattedData.headerCount-2}`).value = {
        text: 'sawatchlabs.com',
        hyperlink: 'http://www.sawatchlabs.com'
    };
    worksheet.getCell(`A${formattedData.headerCount-2}`).font = {
        underline: true,
        color: { argb: BLUE }
    }

    // Set font and fill for table headers
    worksheet.getRow(formattedData.headerCount).font = { bold: true, color: { argb: WHITE }};
    worksheet.getRow(formattedData.headerCount).eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: PRUSSIAN_BLUE} }
    });

    adjustColumnWidth(worksheet);

    // Add image to cell B2 and preserve size
    worksheet.addImage(imgId, {
        tl: { col: 1, row: 1 }, 
        ext: { width: 241, height: 132 }, 
        editAs: 'absolute'
    });

    // Add borders to and format cells
    let moneyCol = '';
    let yearCol = '';
    worksheet.eachRow((row, rowNum) => {
        if (rowNum >= formattedData.headerCount) {
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                    };
                cell.alignment = { horizontal: 'left' };
                if (cell.value === 'Fuel Cost Savings') {
                    moneyCol = cell.address.substring(0, 1);
                }
                if (cell.value === 'Year') {
                    yearCol = cell.address.substring(0, 1);
                }

                if (typeof(cell.value) === 'number') {
                    if (cell.address.substring(0, 1) === moneyCol) {
                        // Format Fuel Cost Savings as currency
                        cell.numFmt = '$#,##0.00';
                    }
                    else if (cell.value % 1 === 0) {
                        // Format any whole numbers
                        if (cell.address.substring(0, 1) !== yearCol) { 
                            // Don't add formatting to year
                            cell.numFmt = '#,##0';
                        }
                    }
                    else {
                        cell.numFmt = '#,##0.0';
                    }
                }
            });
            row.commit();
        }
    });

    return workbook;
}

function execExcelDownload(formattedData, filename) {
    const workbook = createExcelDoc(formattedData);
    saveFile(workbook, filename);
}

function formatTableData(props) {
    const {beginDate, endDate, csvType, group, vehicleClasses, selectedVehicleClasses, displayInLbs, dbDisplayName, displayUnits} = props;
    const emissionData = JSON.parse(JSON.stringify(props.emissionData))
    const csvHeaders = [
        { label: "Asset ID", key: "asset_id" },
        { label: "VIN", key: "user_defined_vin" },
        // { label: 'User Defined VIN', key: 'user_defined_vin'},
        { label: "Year", key: "year" },
        { label: "Make", key: "make" },
        { label: "Model", key: "model" },
        { label: "Vehicle Class", key: "vehicle_class" },
        { label: "Fuel Type", key: "fuelType"},
        { label: "Miles", key: "miles" },
        { label: "Emission Ratio", key: "emit" },
        { label: "Fuel Economy (MPG)", key: "fuelEcon" },
        { label: "Idle Hours", key: "idle" },
        { label: displayInLbs ? "GHG (Lbs)" : "GHG (Tons)", key: displayInLbs ? "ghgLbs" : "ghgTons" },
        { label: "NOX (Lbs)", key: "noxLbs" },
        { label: "Carbon Monoxide (Lbs)", key: "coLbs" },
        { label: displayInLbs ? "Carbon Dioxide (Lbs)" : "Carbon Dioxide (Tons)", key: displayInLbs ? "CO2Lbs" : "CO2Tons" },
        { label: "PM 10\u00B5m (Grams)", key: "pm10Grams" },
        { label: "PM 2.5\u00B5m (Grams)", key: "pm25Grams" },
      ]

      const altFuelCsvHeaders = [
        { label: "Asset ID", key: "asset_id" },
        { label: "VIN", key: "user_defined_vin" },
        // { label: 'User Defined VIN', key: 'user_defined_vin'},
        { label: "Year", key: "year" },
        { label: "Make", key: "make" },
        { label: "Model", key: "model" },
        { label: "Vehicle Class", key: "vehicle_class" }, 
        { label: "Fuel Type", key: "fuelType"},
        { label: "Miles", key: "miles" },
        { label: displayInLbs ? "GHG Reduction (Lbs)" : "GHG Reduction (Tons)", key: displayInLbs ? "ghgLbsSavings" : "ghgTonsSavings" },
        { label: "NOX Reduction (Lbs)", key: "noxLbsSavings" },
        { label: "Carbon Monoxide Reduction (Lbs)", key: "coLbsSavings" },
        { label: displayInLbs ? "Carbon Dioxide Reduction (Lbs)" : "Carbon Dioxide Reduction (Tons)", key: displayInLbs ? "CO2LbsSavings" : "CO2TonsSavings" },
        { label: 'PM 10\u00B5m Reduction (Grams)', key: "pm10GramsSavings" },
        { label: "PM 2.5\u00B5m Reduction (Grams)", key: "pm25GramsSavings" },
        //Both columns below need to be updated with proper key value when data from api is updated.
        { label: "Fuel Reduction (Gallons)", key: "fuelGallonsSavings"},
        { label: 'Fuel Cost Savings', key: "fuelCostSavings"}
      ]
        const dateStringOptions = {year: '2-digit', month: '2-digit', day: '2-digit'}
      
        let selectedClasses = (selectedVehicleClasses.length === vehicleClasses.length) ? 'All Classes' : selectedVehicleClasses.join(", ");

        let emptyArr = [''];
        let headers = [];
        let accessors = [];
        let reportName = []
        if (csvType === 'altFuel') {
            reportName.push('Emissions Reduction Report - ' + dbDisplayName);
        }
        else {
            reportName.push('Emissions Report - ' + dbDisplayName);
        }
        let formatArr = [
            ['Sawatch Labs - Emit'],
            reportName,
            ['Download Date: ' + (new Date()).toLocaleDateString('en-US', dateStringOptions)],
            [`Date Range: ${(beginDate) ? beginDate.toLocaleDateString('en-US', dateStringOptions): '--'} - ${(endDate) ? endDate.toLocaleDateString('en-US', dateStringOptions): '--'}`],
            [`Group: ${group}`],
            [`Vehicle Classes: ${selectedClasses}`],
            [`Display Units (Carbon Dioxide & GHG): ${displayUnits}`],
            emptyArr,
            emptyArr,
            headers,
        ]
        if (csvType === 'altFuel') {
            altFuelCsvHeaders.map(item => {
            headers.push(item.label)
            accessors.push(item.key)
            return null;
            })
        } else {
            csvHeaders.map(item => {
            headers.push(item.label)
            accessors.push(item.key)
            return null;
        })
        }
        const headerCount = formatArr.length;
        if(emissionData.length < 1) {
            formatArr.push(['No data to display'])
        } else {
        emissionData.map(data => {
            let row = []
            accessors.map(value => {
            // Kind of hacky but didn't see a better way. Remove when vin/user defined vin issue is resolved
            if (value === 'user_defined_vin' && data[value] === 'null') {
                value = 'vin'
            }
            // None check
            if(data[value] === null || data[value] === undefined || data[value] === 'null' || data[value] === 'VIN Decoding Error' || data[value] === '') {
                data[value] = '-'
            }            
            
            let val = FormatData(value, data[value], data);

            // Clean up number strings so they can be converted to number type
            if (typeof(val) === 'string') {
                if (val.includes(',')) {
                    val = val.replace(',', '');
                }
                if (val.includes('$')) {
                    val = val.replace('$', '');
                }
            }
            // If val can't be converted to number just push the string
            if (isNaN(Number(val))) {
                row.push(val);
            }
            // Otherwise convert string to number type
            else {
                row.push(Number(val));
            }
              
              return null;
            });
            formatArr.push(row);
            return null;
        })
        }
        return {"data": formatArr, "headerCount": headerCount};
}