import { roundValue } from "./UtilityFunctions";
import { DateTime } from "luxon";

export default function sortCandidateData(data, chargeData, missedData, settings){
  var vins = [...new Set(data.map(d => d.vin))];
  var results = [];
  vins.forEach(v => {
    // compare cost of EV (current) to potential replacement with ICE
    // ADD actual vehicle vehicle specific settings onto object here? repalce fleet level
    var ice = undefined;
    var ev = undefined;
    data.forEach(d => {
      if(d.vin === v && d.isIceCandidate){
        // set ICE candidate
        ice = d;
      } else if(d.vin === v && (d.isBevVehicle || d.isPhevVehicle)){
        // add charge and m.o event counts if available
        // find charge count for vin
        var chargeCt = chargeData.filter(e => {
          if(e.vin === d.vin)return e.events_count;
          return null;
        });
        if(chargeCt && chargeCt[0])d['chargeCt'] = chargeCt[0]['events_count'];
        else d['chargeCt'] = 0;
        // find missed count for vin
        var missedCt = missedData.filter(e => {
          if(e.vin === d.vin)return e.events_count;
          return null;
        });
        if(missedCt && missedCt[0])d['missedCt'] = missedCt[0]['events_count'];
        else d['missedCt'] = 0;
        ev = d;
      }
    });
    if(!ev || !ice){
      console.error("Error - did not find ICE and EV to compare.");
      return;
    }
    var result = compareCandidateData(ev, ice, settings);
    results.push(result);
  });
  return results;
}

export function calcTcoStats(tcoData) {
  var savings = 0;
  var fuelSavings = 0;
  var fuelCostSavings = 0;
  var ghgRedGms = 0;
  tcoData.forEach(t => {
    savings += t.opChangeToDate;
    fuelSavings += t.fuelSavings;
    fuelCostSavings += t.fuelCostSavings;
    ghgRedGms += t.ghgRedGms;
  });
  var avgSavings = savings/(tcoData.length);
  return {totalSavings: savings,
          avgSavings: avgSavings,
          fuelSavings: fuelSavings,
          fuelCostSavings: fuelCostSavings,
          ghgRedGms: ghgRedGms};
}

function compareCandidateData(ev, ice, settings) {
  let lifeCycle = ice['lifeCycle'] ? ice['lifeCycle'] : settings.life_cycle;
  var tcoChangeLt = calcLtChange(ev['totalCost'], ice['totalCost'], ev['days'], lifeCycle);
  var opChangeLt = calcLtChange(ev['opCost'], ice['opCost'], ev['days'], lifeCycle);
  var opChangeToDate = ice['opCost'] - ev['opCost'];
  // trends
  var trends = calcTrends(ev, ice);
  var ghgVals = calcGhgValues(ev, ice);
  // note fuLiters = total fuel if every km used gas
  // fuActual = fuel ev would have used (bev = 0, phev = fuLiters * pct_elec ratio)
  var fuelSavings = ice['fuLiters'] - ev['fuLitersActual'];
  var fuelCostSavings = ice['fuelCost'] - ev['fuelCost'];
  var recToSave = calcRecToSave(ev, settings);
  var breakEven = calcBreakEvenYear(ev, ice, settings);
  return {vin: ev['vin'],
          asset_id: ev['assetId'],
          user_defined_vin: ev['userDefinedVin'],
          trend6Mths: trends['monthOnSixMonths'],
          trend30: trends['monthOnMonth'],
          tcoChangeLt: tcoChangeLt,
          opChangeLt: opChangeLt,
          opChangeToDate: opChangeToDate,
          ghgRedGms: ghgVals['ghgRedGms'],
          ghgRedPct: ghgVals['ghgRedPct'],
          fuelSavings: fuelSavings,
          fuelCostSavings: fuelCostSavings,
          recToSave: recToSave,
          breakEvenYear: breakEven['year'],
          breakEvenAge: breakEven['age']};
}

function calcTrends(ev, ice){
  // op cost savings last 30 days
  var last30 = ice['lastMonth'] - ev['lastMonth'];
  // op cost savings 30 days before that
  var previous30 = ice['twoPrior'] - ev['twoPrior'];
  // op cost savings avg 6 months prior
  var previous180 = ice['avgSixPrior'] - ev['avgSixPrior'];
  var monthOnMonth = 'equal';
  if(last30 > previous30) monthOnMonth = 'improving';
  else if(last30 < previous30) monthOnMonth = 'worsening';
  var monthOnSixMonths = 'equal';
  if(last30 > previous180) monthOnSixMonths = 'improving';
  else if(last30 < previous180) monthOnSixMonths = 'worsening';
  return {'monthOnMonth': monthOnMonth, 'monthOnSixMonths': monthOnSixMonths};
}

function calcLtChange(evTotal, iceTotal, days, lifeCycle) {
  var evLt = (evTotal/days) * 365 * lifeCycle;
  var iceLt = (iceTotal/days) * 365 * lifeCycle;
  return iceLt - evLt;
}

function calcGhgValues(ev, ice){
  // not different to ezev - doesn't need to account for elec ratio for phevs
  // not necessary because ghg gms accounts for how phev used
  var ghgRedGms = ice['ghgGms'] - ev['ghgGms'];
  var ghgRedPct = (ghgRedGms / ice['ghgGms'])*100;
  return {'ghgRedGms': ghgRedGms, 'ghgRedPct': ghgRedPct};
}

function calcRecToSave(ev, settings){
  var totalEvents = parseInt(ev['missedCt']) + parseInt(ev['chargeCt']);
  if((((parseInt(ev['missedCt'])) / totalEvents)*100) > settings['max_pct_missed_opps_target']){
    return "Increase opportunity charging";
  } else if(((ev['km']/ev['days'])*365) <= settings['ev_yr_km_target']){
    return "Increase utilization";
  }
  return "-";
}

function calcBreakEvenYear(ev, ice, settings){
  // may want data to be based on last 6 months only
  var opSavings = ice['opCost'] - ev['opCost'];
  var evPrice = ev['netPriceVehicle'];
  if (evPrice == null){
    // get vehicle class EV purchase price (CDR)
    evPrice = getPurchasePrice(ev, settings)
  }
  var costDiff = evPrice - ice['netPriceCandidate'];
  if(isNaN(costDiff))return {'year': "-", 'age': "-"};
  if(costDiff <= 0)return {'year': ev['year'], 'age': 0}; // not greater, broken even at purchase
  var thisYear = DateTime.local().year;
  var twentyFrom = thisYear + 20;
  // if opSavings is negative, will never break even
  // NOTE: Decided June 2024 to replace "Never" breaking even with "20+" - BH
  if(opSavings <= 0)return {'year': `${twentyFrom}+`, 'age': "20+"};
  // calc amount remaining to pay off
  var toPay = costDiff - opSavings;
  var opSavingsYr = (opSavings/ev['days'])*365;
  // check if data starts in model year
  var startDate = ev['minDate'].ts;
  // ignoring timezone for now
  var targetDate = DateTime.utc(ev['year'], 12, 31, 23, 59, 59);
  if(startDate <= targetDate){
    // if data end of model year, proceed
    var startPaying = DateTime.fromMillis(startDate);
  } else {
    // assume 7/1 driving start date
    startPaying = DateTime.utc(ev['year'], 7, 1);
  }
  var yrs = toPay / opSavingsYr;
  // startPaying as decimal of year
  var startYear = startPaying.year;
  var remainder = startPaying.month-1 + (startPaying.day/30);
  remainder = remainder/12; // number of months to portion of year
  startYear = startYear + remainder;
  var endYear = startYear + yrs;
  endYear = endYear.toString();
  endYear = endYear.split('.')[0];
  var age = endYear - ev['year'];
  // consider this  more - older models didn't break even @ purchase but have
  // between now and then. Zero out here
  if(age < 0)age = 0;
  if(parseInt(endYear)>=twentyFrom){
    endYear = `${twentyFrom}+`;
  }
  if(parseInt(age)>20){
    age = "20+";
  }
  return {'year': endYear, 'age': age};
}

function getPurchasePrice(ev, settings){
  var details = settings['vehicle_class_defs'].filter(d => {
    if(d.vehicle_class === ev['vehicleClass']){
      return d
    } else {
    return null;
    }
  })
  if(details.length > 0){
    details = details[0];
    if(ev['isBevVehicle']) return details['bev_purchase_price'];
    if(ev['isPhevVehicle']) return details['phev_purchase_price'];
  }
  return
} 

export function processGraphTotals(evData, iceData) {
  // performs calc but also sets up graph labels
  // could move into graph
  var trends = {};
  Object.keys(evData).forEach(key => {
    // if no data for that period, do not include
    if(!evData[key])return;
    trends[key] = roundValue((iceData[key] - evData[key]), 0);
  });
  return trends;
}
