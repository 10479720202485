import React, { useState, useEffect, useRef } from "react";
import VehicleDropdown from "../../dashboard-components/swt-vehicle-dropdown";
import { TileLayer, CircleMarker, Popup, useMapEvents, useMap } from "react-leaflet3";
import DevToolsTable from "../DevToolsTable";
import DropdownSelector from "../DropdownSelector";
import * as S from '../../../../styles/core-styles/DevTools-styles';

const MINIMUM_DURATION_FILTER = 300; //In seconds to prevent an overflow of events

export default function IdlingMap(props){

    const { vehicles, beginDate, endDate } = props;

    let idleVehicles = vehicles.slice();
    idleVehicles.unshift({vin: '0', asset_id: 'All Vehicles', year: '', make: '', model: ''});

    const tableColumns = [
        {Header: "Asset ID", accessor: "asset_id", sortType: "basic"},
        {Header: "Duration", accessor: "duration", sortType: "basic"},
        {Header: "Start", accessor: "local_start", sortType: "basic"},
        {Header: "Stop", accessor: "local_stop", sortType: "basic"},
    ];

    const durationFilter = [
        {id: 5, name: 5}, 
        {id: 10, name: 10},
        {id: 15, name: 15},
        {id: 20, name: 20},
        {id: 30, name: 30},
        {id: 60, name: 60},
    ];

    let currentCenter = useRef([0, 0]);
    let canScroll = useRef(true);
    const [zoom, setZoom] = useState(10);
    const [events, setEvents] = useState([]);
    const [mapCenter, setMapCenter] = useState([39.8, -104.9]);
    const [minDurationSeconds, setMinDurationSeconds] = useState(600);
    const [selectedEvent, setSelectedEvent] = useState(-1);

    const handleVehicleChange = (v) => {
        props.setSelectedVIN(v.target.value);
    };

    const handleChangeSelectedEvent = (pkid) =>{
        let e = events.filter((o) => { return o.pkid === pkid});
        e = e[0]
        currentCenter.current =[e.latitude, e.longitude];
        setSelectedEvent(e);
    }

    useEffect(() => {
        canScroll.current = false;
        const req = {db: props.db,
            token: props.user.token,
            url: `${props.apiURL}getIdlingEvents?dbName=${props.db}&start=${formatAPIDate(beginDate)}&stop=${formatAPIDate(endDate)}&minDurationSeconds=${Math.max(minDurationSeconds, MINIMUM_DURATION_FILTER)}`
        };
        if(true)apiCall(req, (d)=>{handleEventsChange(d);})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beginDate, endDate, minDurationSeconds]);

    const handleEventsChange = (events) => {
        const c = _getMapBounds(events);
        currentCenter.current = c;
        setMapCenter(c);
        const e = events.map((r) => {
            r.local_start = formatDate(r.local_start);
            r.local_stop = formatDate(r.local_stop);
            r.id = r.pkid;
            r.duration = formatDuration(r.duration);
            return r;
        });
        setEvents(e);
        canScroll.current = true;
    }

    function formatDate(d){
        const i = d.split('.')
        return i[0];
    }

    function formatAPIDate(d){
        return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
    }

    function _getMapBounds(data){
        if(!data || data.length < 1)return [0, 0];
        let lat = 0;
        let lon = 0;
        data.forEach((d) => {
          lat += d.latitude;
          lon += d.longitude;
        });
        return [lat/data.length, lon/data.length]
      }

    function ListenerComponent(){
        const mapEvents = useMapEvents({
          zoomend: () => {
            setZoom(mapEvents.getZoom());
          },
          moveend: () => {
            if(canScroll.current)currentCenter.current = mapEvents.getCenter();
          },
          move: () => {
            if(canScroll.current)currentCenter.current = mapEvents.getCenter();
          },
          viewreset: () => {
            currentCenter.current = mapEvents.getCenter();
          },
        });
        return null;
    }

    function formatDuration(d){
        let hours = "00";
        if(d.hasOwnProperty('hours'))hours=String(d.hours).padStart(2, '0');
        if(d.hasOwnProperty('days'))return `${d.days} : ${hours}:${String(d.minutes).padStart(2, '0')}`;
        return `${hours}:${String(d.minutes).padStart(2, '0')}`;
    }

    function eventMarks(coords, options) {
        let color = "gray";
        let radius = 4;
        if(typeof options !== 'undefined'){
          //if(options.hasOwnProperty('color'))color=options.color;
          //if(options.hasOwnProperty('radius'))radius=options.radius;
        }

        return coords.map(pl => {
          return(
            <CircleMarker
              key={`${pl.vin}-${pl.local_start}`}
              center={[pl.latitude, pl.longitude]}
              color={color}
              radius={radius}
              opacity={.80}
              fill={true}
              fillColor={"yellow"}
              fillOpacity={.9}
            >
              <Popup id={`${pl.vin}-${pl.utc_start}`} key={`${pl.vin}-${pl.local_start}`}>
                  <span className="speedn-popup-text">Time: {pl.local_start}</span><br/>
                  <span className="speedn-popup-text">Trip Id: {pl.trip_id}</span><br/>
                  <span className="speedn-popup-text">Asset Id: {pl.asset_id}</span><br/>
                  <span className="speedn-popup-text">VIN: {pl.vin}</span><br/>
                  <span className="speedn-popup-text">Duration: {pl.duration}</span><br/>
              </Popup>
            </CircleMarker>
          );
        });
      };

      function ChangeView({ center, zoom }) {
          const map = useMap();
          map.setView(center, zoom);
          return null;
      }

      function Map(center, zoom) {
        return (
            <S.DevToolsMapContainer
              center={mapCenter} 
              zoom={zoom}>
    
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                maxZoom={20}
              />
              <ListenerComponent/>
              {<ChangeView center={currentCenter.current} zoom={zoom} />}
              {eventMarks(events, {color:"black", radius:2})};
            </S.DevToolsMapContainer>
        );
    }

    return(
       <S.DevToolsContentContainer>
         <S.DevToolsTopContainer>
            <S.DevToolsControls>
              <S.DevToolsInputWrapper>
                <S.DevToolsInputLabel>Select Vehicle:</S.DevToolsInputLabel>
                <VehicleDropdown
                    handleChange={(e) => handleVehicleChange(e)}
                    vehicles={idleVehicles}
                    selectedVIN={'0'}
                    disabled={true}
                />
                </S.DevToolsInputWrapper>

                <S.DevToolsInputWrapper>
                  <S.DevToolsInputLabel>Select Date Range:</S.DevToolsInputLabel>
                  <S.DevToolsDatepickerContainer>
                    <S.DevToolsDatepickers
                        selected={beginDate}
                        onChange={(date) => props.setBeginDate(date)}
                        selectsStart
                        startDate={beginDate}
                        endDate={endDate}
                        minDate={beginDate}
                        maxDate={endDate}
                        showMonthDropdown
                        useShortMonthInDropdown
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [0, -7],
                            },
                          },
                        ]}
                    />
                    <S.DevToolsDatepickers
                        selected={endDate}
                        onChange={(date) => props.setEndDate(date)}
                        selectsEnd
                        endDate={endDate}
                        minDate={beginDate}
                        maxDate={endDate}
                        showMonthDropdown
                        useShortMonthInDropdown
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [0, -7],
                            },
                          },
                        ]}
                    />
                  </S.DevToolsDatepickerContainer>
                  </S.DevToolsInputWrapper>

                  <S.DevToolsInputWrapper>
                    <S.DevToolsInputLabel>Idling Minutes Filter:</S.DevToolsInputLabel>
                    <DropdownSelector
                        disabled={false}
                        defaultValue={minDurationSeconds/60}
                        options={durationFilter}
                        handleChange={function(e){setMinDurationSeconds(e * 60)}}
                    />
                  </S.DevToolsInputWrapper>
              </S.DevToolsControls>
              {Map(currentCenter.current, zoom)}
            </S.DevToolsTopContainer>
            
                    <DevToolsTable
                        columns={tableColumns} 
                        data={events}
                        handleOnClick={(e)=>{handleChangeSelectedEvent(e)}}
                        handleSort={() => {}}
                        idAccessor={"pkid"}
                        selectedId={selectedEvent ? selectedEvent.pkid : undefined}
                    />
        </S.DevToolsContentContainer>
    )
}

function apiCall(req, cb){
    fetch(`${req.url}`, {
      headers: { Authorization: `Bearer ${req.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if(data.data && cb)cb(data.data);
      });
}