import React from "react";
import DbDropdown from "./swt-db-dropdown";
import * as S from '../../../styles/core-styles/Dashboard-styles';
import SawatchLogo from '../../../images/sawatch_labs_logo_white.png'
import UserMenu from "./swt-header-user-menu";

export default function Header(props) {

  const { user, db, databases, handleDBChange, onSelect, showDatabaseDropDown, showUserManagementMenu, cobrandingLogoUrl, cobrandingLogoUrlReceived } = props;
  
  return (
    <S.DashboardHeader>
      <S.HeaderLogo
        src={SawatchLogo}
        alt="Sawatch Logo"
        onClick={() => onSelect("landing")}
      />
      {showDatabaseDropDown &&
        <DbDropdown
          databases={databases}
          db={db}
          handleDBChange={handleDBChange}
        />
      }
      {user.authenticated && cobrandingLogoUrlReceived && 
      <UserMenu
        cobrandingLogoUrl={cobrandingLogoUrl}
        showUserManagementMenu={showUserManagementMenu}
        demo={false}
      />
      }
    </S.DashboardHeader>
  );
}
