import React from 'react';
import DutyCycleVisualization from './DutyCycleVisualization';

export default function DataVizPage(props) {
  return (
    <>
      <DutyCycleVisualization
        fullCapacity={props.fullCapacity}
        segmentTimeDelta={props.segmentTimeDelta}
        capacitySegments={props.capacitySegments}
        dutySegments={props.dutySegments}
        vehicleUtil={props.vehicleUtil}
        handleVehicleSelect={props.handleVehicleSelect}
        loading={props.loading}
        avgTotalUtil={props.avgTotalUtil}
        selectedVehicles={props.selectedVehicles}
        selectedLocations={props.selectedLocations}
        currentAverage={props.currentAverage}
        hoursAtCapacity={props.hoursAtCapacity}
        hoursAboveCapacity={props.hoursAboveCapacity}
        nonServicedCycles={props.nonServicedCycles}
        totalHours={props.totalHours}
        locations={props.locations}
      />
    </>
  );
}
