import React, {useEffect, useState} from "react";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import { MultiSelect } from "react-multi-select-component";

export default function CandidateImportModal(props) {
    const {setShowImportModal, cdrCandidates, handleCDRCandidateImports} = props
    const [years, setYears] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [makes, setMakes] = useState([]);
    const [makeOptions, setMakeOptions] = useState([]);
    const [models, setModels] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [vehicleClasses, setVehicleClasses] = useState([]);
    const [vehicleClassOptions, setVehicleClassOptions] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [markEzevSelected, setMarkEzevSelected] = useState('false');
    const [markIonevSelected, setMarkIonevSelected] = useState('false');
    const [useCdrDefaultSelected, setUseCdrDefaultSelected] = useState(true);

        useEffect(() => {
        let yearList = years.map((option) => {return option.value});
        let makeList = makes.map((option) => {return option.value});
        let modelList = models.map((option) => {return option.value});
        let classList = vehicleClasses.map((option) => {return option.value});
        let fuelTypeList = fuelTypes.map((option) => {return option.value});

        let newCandList = cdrCandidates.filter((c) => yearList.includes(c.year));
        newCandList = newCandList.filter((c) => makeList.includes(c.make.trim()));
        newCandList = newCandList.filter((c) => modelList.includes(c.model.trim()));
        newCandList = newCandList.filter((c) => classList.includes(c.vehicle_class.trim()));
        
        //Only way I was able to filter candidates on all the fuel types. 
        let newList = []
        for(let i = 0; i < fuelTypeList.length; i++) {
            newCandList.forEach((c) => {
                if (c[fuelTypeList[i]] === true) {
                    newList.push(c)
                }
            })
        }
        newCandList = newList;
    
        setFilteredCandidates(newCandList)
    //eslint-disable-next-line
    },[makes, models, vehicleClasses, fuelTypes])


    useEffect(() => {
        let yearList = [];
        let makeList = [];
        let modelList = [];
        let vehicleClassList = [];
        cdrCandidates.forEach((cand) => {
            if(!yearList.some((y) => y.value === cand.year)) {
                yearList.push({label: cand.year, value: cand.year})
            }
            if(!makeList.some((m) => m.value === cand.make.trim())) {
                makeList.push({label: cand.make.trim(), value: cand.make.trim()})
            }
            if(!modelList.some((m) => m.value === cand.model.trim())) {
                modelList.push({label: cand.model.trim(), value: cand.model.trim()})
            }
            if(!vehicleClassList.some((m) => m.value === cand.vehicle_class.trim())) {
                vehicleClassList.push({label: cand.vehicle_class.trim(), value: cand.vehicle_class.trim()})
            }
        })
        setYearOptions(yearList);
        setYears(yearList)
        setMakeOptions(makeList);
        setMakes(makeList)
        setModelOptions(modelList);
        setModels(modelList)
        setVehicleClassOptions(vehicleClassList);
        setVehicleClasses(vehicleClassList)
        let fuelTypeList = getFuelTypes(cdrCandidates)
        setFuelTypes(fuelTypeList)
        setFuelTypeOptions(fuelTypeList);

        setFilteredCandidates(cdrCandidates)
    //eslint-disable-next-line
    },[cdrCandidates])

 
    // When user selects years we want to update the makes, models, and vehicle class options to only display those available for those years
    function handleYearChange(e) {
        setYears(e)
        let yearList = e.map((option) => {return option.value})
        let filtered = cdrCandidates.filter((c) => yearList.includes(c.year));
        if(e.length === 0) {
            setMakes([])
            setModels([])
            setVehicleClasses([])
        }
        updateOptions(filtered);
        setFilteredCandidates(filtered);
    }


    const customValueRenderer = (selected, type, options) => {
        if (selected.length === 0) {
            return <p>None</p>
        }
        else if (selected.length === 1) {
            return <p>{selected[0].label}</p>
        } else if (selected.length === options.length) {
            return <p>All {type}</p>
        } else {
            return <p>{selected.length} {type}</p>
        }
    }


    function updateOptions(cands) {
            let makeList = [];
            let modelList = [];
            let vehicleClassList = [];
            cands.forEach((cand) => {
                if(!makeList.some((m) => m.value === cand.make.trim())) {
                    makeList.push({label: cand.make.trim(), value: cand.make.trim()})
                }
                if(!modelList.some((m) => m.value === cand.model.trim())) {
                    modelList.push({label: cand.model.trim(), value: cand.model.trim()})
                }
                if(!vehicleClassList.some((m) => m.value === cand.vehicle_class.trim())) {
                    vehicleClassList.push({label: cand.vehicle_class.trim(), value: cand.vehicle_class.trim()})
                }
            })
            let fuelTypes = getFuelTypes(cands)
            setFuelTypeOptions(fuelTypes);
            setFuelTypes(fuelTypes);
            setMakes(makeList)
            setMakeOptions(makeList);
            setModels(modelList)
            setModelOptions(modelList);
            setVehicleClasses(vehicleClassList)
            setVehicleClassOptions(vehicleClassList);
    }

    function handleFormSubmit (e) {
        e.preventDefault();
        handleCDRCandidateImports(filteredCandidates, useCdrDefaultSelected, markEzevSelected, markIonevSelected)
    }
    //This updates the models and vehicle class options when year or makes selection changes
    useEffect(() => {
        let yearList = years.map((y) => {return y.value})
        let makeList = makes.map((m) => {return m.value})
        let newModelOptions = [];
        let newClassList = [];
        let cands = [];

        cdrCandidates.forEach((c) => {
            if(yearList.includes(c.year) && makeList.includes(c.make) && !newModelOptions.some((item) => item.value === c.model.trim())) {
                cands.push(c);
                newModelOptions.push({label: c.model.trim(), value: c.model.trim()})
                if(!newClassList.some((item) => item.value === c.vehicle_class)) {
                    newClassList.push({label: c.vehicle_class, value: c.vehicle_class})
                }
            }
        })
        let fuelTypes = getFuelTypes(cands)
        setFuelTypeOptions(fuelTypes)
        setFuelTypes(fuelTypes)
        setModelOptions(newModelOptions)
        setModels(newModelOptions)
        setVehicleClassOptions(newClassList)
        setVehicleClasses(newClassList)
    
    },[years, makes, cdrCandidates])

        function getFuelTypes(cands) {
        let updatedFuelTypes = [];
        cands.forEach((cand) => {
            if(cand.is_bev === true && !updatedFuelTypes.some((ft) => ft.value === 'is_bev')) {
                updatedFuelTypes.push({label: 'BEV', value: 'is_bev'});
            }
            if(cand.is_phev === true && !updatedFuelTypes.some((ft) => ft.value === 'is_phev')) {
                updatedFuelTypes.push({label: 'PHEV', value: 'is_phev'});
            }
            if(cand.is_gasoline === true && !updatedFuelTypes.some((ft) => ft.value === 'is_gasoline')) {
                updatedFuelTypes.push({label: 'Gasoline', value: 'is_gasoline'})
            }
            if(cand.is_diesel === true && !updatedFuelTypes.some((ft) => ft.value === 'is_diesel')) {
                updatedFuelTypes.push({label: 'Diesel', value: 'is_diesel'})
            }
            if(cand.is_propane === true && !updatedFuelTypes.some((ft) => ft.value === 'is_propane')){
                updatedFuelTypes.push({label: 'Propane', value: 'is_propane'})
            }
        })
        return updatedFuelTypes
    }

    return(
        <S.ModalDisplay>
            <S.ModalContainer style={{height: '70vh'}}>
                <S.ModalCloseButton data-testid="modal-close-btn-testid" onClick={() => setShowImportModal(false)}>Close</S.ModalCloseButton>
                
                    <S.ModalHeader>Import Candidates</S.ModalHeader>
                    <S.ModalInfoTextWrapper>
                        <S.ModalInfoText>New candidates being imported: {filteredCandidates.length}</S.ModalInfoText>
                        <S.ModalInfoText style={{fontSize: '14px'}}>*Selecting different years or makes will affect the number of options for make, model, vehicle class, and fuel type fields.</S.ModalInfoText>
                    </S.ModalInfoTextWrapper>
                <S.ModalForm onSubmit={(e) => handleFormSubmit(e)} autoComplete="off" aria-label="form">

                <S.ModalFormInputWrapper data-testid='year-select-testid'>
                <S.ModalFormInputLabel>Years:</S.ModalFormInputLabel>
                    <div style={{width: "92%"}}>
                    <MultiSelect
                        autosize={true}
                        options={yearOptions.sort((yearA, yearB) => {
                            const a = yearA.label;
                            const b = yearB.label;
                            return a < b ? 1 : -1;
                        })}
                        value={years}
                        onChange={(e) => handleYearChange(e)}
                        valueRenderer={(s, options) => customValueRenderer(s, 'Years', options)}
                        labelledBy="Select"
                    />
            </div>
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper data-testid='make-select-testid'>
                <S.ModalFormInputLabel>Makes:</S.ModalFormInputLabel>
                    <div style={{width: "92%"}}>
                    <MultiSelect
                        autosize={true}
                        options={makeOptions.sort((makeA, makeB) => {
                            const a = makeA.label;
                            const b = makeB.label;
                            return a > b ? 1 : -1;
                        })}
                        value={makes}
                        onChange={(e) => setMakes(e)}
                        valueRenderer={(s, options) => customValueRenderer(s, 'Makes', options)}
                        labelledBy="Select"
                    />
            </div>      
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper data-testid='model-select-testid'>
                <S.ModalFormInputLabel>Models:</S.ModalFormInputLabel>
                    <div style={{width: "92%"}}>
                    <MultiSelect
                        autosize={true}
                        options={modelOptions.sort((modelA, modelB) => {
                            const a = modelA.label;
                            const b = modelB.label;
                            return a > b ? 1 : -1;
                        })}
                        value={models}
                        onChange={(e) => setModels(e)}
                        valueRenderer={(s, options) => customValueRenderer(s, 'Models', options)}
                        labelledBy="Select"
                    />
            </div>      
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper data-testid='vehicle-class-select-testid'>
                <S.ModalFormInputLabel>Vehicle Classes:</S.ModalFormInputLabel>
                    <div style={{width: "92%"}}>
                    <MultiSelect
                        autosize={true}
                        options={vehicleClassOptions.sort((A,B) => {
                            const a = A.label;
                            const b = B.label;
                            return a > b ? 1 : -1;
                        })}
                        value={vehicleClasses}
                        onChange={(e) => setVehicleClasses(e)}
                        valueRenderer={(s, options) => customValueRenderer(s, 'Classes', options)}
                        labelledBy="Select"
                    />
                    </div>      
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper data-testid='fuel-type-select-testid'>
                <S.ModalFormInputLabel>Fuel Type:</S.ModalFormInputLabel>
                    <div style={{width: "92%"}}>
                    <MultiSelect
                        autosize={true}
                        options={fuelTypeOptions.sort((A, B) => {
                            const a = A.label;
                            const b = B.label;
                            return a > b ? 1 : -1;
                        })}
                        value={fuelTypes}
                        onChange={(e) => setFuelTypes(e)}
                        valueRenderer={(s, options) => customValueRenderer(s, 'Fuel Types', options)}
                        labelledBy="Select"
                    />
                    </div>      
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Use CDR Defaults for Selected Flags:</S.ModalFormInputLabel>
                <input
                    data-testid='use-cdr-default-selected-testid'
                    className="swt-admin-table-input"
                    type="checkbox"
                    onChange={() => setUseCdrDefaultSelected(!useCdrDefaultSelected)}
                    checked={useCdrDefaultSelected}
                />    
            </S.ModalFormInputWrapper>

        {!useCdrDefaultSelected && 
        <S.ModalFormInputWrapper>

                <S.ModalFormInputWrapper data-testid='mark-evsa-selected-select-testid'>
                  <S.ModalFormInputLabel>*Mark All ezEV/ezIO Selected on Import:</S.ModalFormInputLabel>
                  <S.ModalFormSelect
                    value={markEzevSelected}
                    onChange={(e) => setMarkEzevSelected(e.target.value)}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                  </S.ModalFormSelect>
                </S.ModalFormInputWrapper>
      
                <S.ModalFormInputWrapper data-testid='mark-ionev-selected-select-testid'>
                  <S.ModalFormInputLabel>*Mark All ionEV Selected on Import:</S.ModalFormInputLabel>
                  <S.ModalFormSelect
                    value={markIonevSelected}
                    onChange={(e) => setMarkIonevSelected(e.target.value)}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                  </S.ModalFormSelect>
                </S.ModalFormInputWrapper>
                <S.ModalFormInputWrapper>
                    <S.ModalFormInputLabel>*These options will overwrite the default CDR selected values</S.ModalFormInputLabel>
                </S.ModalFormInputWrapper>
            </S.ModalFormInputWrapper>

        }
            
            <S.ModalFormButtonWrapper>
                <S.ModalSaveButton 
                    disabled={filteredCandidates.length === 0}
                    type='submit'
                    data-testid="candidate-modal-import-button-testid"
                    >
                    Import
                </S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
            </S.ModalForm>
            </S.ModalContainer>
        </S.ModalDisplay>
    )
}