export function getDisplayName(reqObject, cb) {
  fetch(`${reqObject.apiURL}getDisplayName?db=${reqObject.dbName}`, {
    headers: { Authorization: `Bearer ${reqObject.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.data && data.data[0].display_name) {
        return cb(data.data[0].display_name);
      }
    });
}

export function getDeviceInstallResultsReq(reqObject, cb) {
  fetch(`${reqObject.apiURL}getGroupInstalls?clientId=${reqObject.dbName}`, {
    headers: { Authorization: `Bearer ${reqObject.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.data) {
        return cb(data.data);
      }
    });
}

export function getGroupsReq(reqObject, groups, cb) {
  fetch(`${reqObject.apiURL}getGroups?ident=${reqObject.dbName}`, {
    headers: { Authorization: `Bearer ${reqObject.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.data) {
        return cb(groups, data.data);
      }
    });
}

export function getGroupDevices(reqObject, group, cb) {
  var grp = "";
  if (group) grp = `&groupId=${group}`;
  fetch(
    `${reqObject.apiURL}getGroupDevices?clientId=${reqObject.dbName}${grp}`,
    {
      headers: { Authorization: `Bearer ${reqObject.user.token}` },
    }
  )
    .then((resp) => resp.json())
    .then((data) => {
      if (data.data) {
        cb(data.data);
      }
    });
}


export function getInstallPercents(reqObject, group, cb){
  var grp = "";
  if (group) grp = `&groupId=${group}`;
  fetch(
    `${reqObject.apiURL}getInstallPercents?clientId=${reqObject.dbName}${grp}`,
    {
      headers: { Authorization: `Bearer ${reqObject.user.token}` },
    }
  )
    .then((resp) => resp.json())
    .then((data) => {
      if (data.data) {
        cb(data.data);
      }
    });
}

// export function getInstallTotals(reqObject, cb) {
//   fetch(`${reqObject.apiURL}getInstallTotals?clientId=${reqObject.dbName}`, {
//     headers: { Authorization: `Bearer ${reqObject.user.token}` },
//   })
//     .then((resp) => resp.json())
//     .then((data) => {
//       if (data.data) {
//         cb(data.data);
//       }
//     });
// }
