import React from "react";
import { Bar } from "react-chartjs-2";
import { formatDateTime, getLongMonth } from "./dataFormatter";
import { GraphExplanation } from "./graphExplanation";
import GraphLegend from "./graphLegend";
import NoResultsMessage from "./noResultsMessage";
import LocationVehicleTable from "./locationVehicleTable";
export default function GraphMonthlyCharging(props) {
  const { graphData, showEzevResults } = props;
  function standardizeData() {
    let values = [];
    let labels = [];
    let yearOfData = graphData;
    yearOfData.forEach((mnth) => {
      mnth.month = mnth.month.replace("-", "/");
      let splitMonth = mnth.month.split("/");
      let longMonth = `${
        getLongMonth(parseInt(splitMonth[0])) + " " + splitMonth[1]
      }`;
      mnth["longDate"] = longMonth;
      labels.push(longMonth);
      values.push(parseFloat(mnth.peakKW));
    });
    let data = {
      labels: labels,
      datasets: [
        {
          label: "peak kW demand",
          data: values,
          backgroundColor: "rgba(87, 151, 182, 0.6)",
          hoverBackgroundColor: "rgba(87, 151, 182, 0.8)",
          borderColor: "rgb(87, 151, 182)",
          borderWidth: 1,
        },
      ],
      // Need data in this object for the click through to daily peak demand graph
      orig: yearOfData,
    };
    return data;
  }

  const data = standardizeData();
  const orig = data.orig;
  return (
    <div className="nrel-graph-table-wrapper" id="monthly">
      <div className="nrel-monthly-graph-wrapper">
        <div className="nrel-graph-container" id="monthly">
          <NoResultsMessage
            data={graphData}
            loading={props.loading}
            showEzevResults={showEzevResults}
          />
          <Bar
            className="monthly-graph"
            id="overview-graph"
            data={data}
            width={300}
            height={115}
            options={{
              onClick: (evt, elem) => {
                props.handleGraphClick(elem, orig);
              },
              tooltips: {
                // The object for the on-hover in the graph
                callbacks: {
                  title: function (tooltipItem, data) {
                    if (data.orig) {
                      let target = data.orig.filter(
                        (m) => m.longDate === tooltipItem[0]["xLabel"]
                      );
                      if (target.length > 0 && target[0].month)
                        return `Month: ${target[0].longDate}`;
                    }
                  },
                  label: function (tooltipItem, data) {
                    let target = data.orig.filter(
                      (m) => m.longDate === tooltipItem.xLabel
                    );
                    if (target.length > 0)
                      return `Peak kW Demand: ${target[0]["peakKW"].toFixed(
                        1
                      )}kW`;
                  },
                  afterLabel: function (tooltipItem, data) {
                    let target = data.orig.filter(
                      (m) => m.longDate === tooltipItem.xLabel
                    );
                    if (target.length > 0)
                      return `Day of Highest Peak Demand: ${
                        target[0]["multiplePeak"] === true
                          ? "Multiple Peaks"
                          : formatDateTime(
                              target[0]["timeOfPeak"],
                              target[0]["utcOffset"]
                            )
                      }`;
                  },
                  afterBody: function (tooltipItem, data) {
                    let target = data.orig.filter(
                      (m) => m.longDate === tooltipItem[0].xLabel
                    );
                    if (target.length > 0)
                      return `Potential Vehicles Charging: ${target[0]["vclsCharging"]}`;
                  },
                },
                backgroundColor: "#FFF",
                titleFontSize: 14,
                titleFontColor: "#72afc4",
                bodyFontColor: "#000",
                bodyFontSize: 12,
                displayColors: false,
                borderColor: "grey",
                borderWidth: 1,
              },
              maintainAspectRatio: false,
              responsive: true,
              title: { text: `Peak kW Demand By Month`, display: false },
              scales: {
                legend: { display: false },
                yAxes: [
                  {
                    ticks: {
                      suggestedMax: 20,
                      suggestedMin: 0,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Peak kW",
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: false,
                      labelString: "Month",
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <GraphLegend selectedGraph={"monthly"} />
        <GraphExplanation graphType={"Monthly"} />
      </div>
      <LocationVehicleTable
        source={"Monthly"}
        showEzevResults={props.showEzevResults}
        requestData={props.requestData}
        selectedPercent={props.selectedPercent}
        selectedLocation={props.selectedLocation}
        start={props.start}
        stop={props.stop}
      />
    </div>
  );
}
