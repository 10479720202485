import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import IdlingDetailPanel from "./idling-detail-panel";
import IdlingDetailTable from "./idling-detail-table";
import DatePicker from "react-datepicker";
import DropdownSelector from "./idling-dropdown";
import Header from './idling-header';
import { vehicleIdDisplay } from "./idling-table-helper-functions";

export default function VehicleDetail(props){
  const { vin } = useParams();
  const [ymm, setYmm] = useState("");
  const [assetId, setAssetId] = useState("");
  const history = useHistory();
  const { beginDate, endDate, setBeginDate, setEndDate, dbName, dbDisplayName } = props;
  const events = props.events.filter((e)=> { if(e.vin === vin)return e;else return null; });


  useEffect(() => {
    if(events.length > 0) {
    let year = events[0].year;
    let make = events[0].make;
    let model = events[0].model;
    let ymm = `${year} ${make} ${model}`
    setYmm(ymm);
    setAssetId(events[0].asset_id);
    }
  },[events])

  const getWeekNumber = function(date){
    let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
  };

  const sortByDate = function( a, b ) {
    if ( a.local_start < b.local_start )return -1;
    if ( a.local_start > b.local_start )return 1;
    return 0;
  }

  const compileIntoWeeks = function (evts) {
    function getWeek(local_start){
      if(typeof local_start === "undefined")return 0;
      let d = Date.parse(local_start)
      d = new Date(d);
      return getWeekNumber(d);
    }

    let refDate = new Date(beginDate);
    let arr = [];
    while(refDate < endDate){
      const woy = getWeek(refDate);
      if(arr.indexOf(woy) < 0)arr.push(woy);
      refDate.setDate(refDate.getDate() + 1);
    }
    const we = [];
    arr.forEach((a) => {
      we.push({woy: a, incidents: 0})
    })

    arr = evts.sort(sortByDate);
    arr.forEach((a) => {
      const woy = getWeek(a.local_start);
      we.forEach((w) => {if(w.woy === woy)w.incidents++});
    });
    return we;
  }
  
  const weeklyEvents = compileIntoWeeks(events);

  const handleOnClick = () => {
    const url = `/idling/summarytable`;
    history.push(url);
  }
 
  let csvDetailFilename = `sawatch-labs-idling-incidents-${assetId.replace(/ /g, '-')}-${(new Date().toLocaleDateString().replaceAll('/', '-'))}`

  return(
    <>
      <Header subHeader={vehicleIdDisplay(events[0])} />
      <div className="idling-detail-top-container">
        <div className="idling-subHeader-wrapper">
          <button 
            className="idling-return-btn"
            onClick={handleOnClick}>
            Back
          </button>
          <h3 className="idling-subHeader">{ymm}</h3>
        </div>
        <div className="idling-detail-controls">
          <div className='idling-detail-input-group'>
            <label className="idling-controls-label">Date Range:</label>
            <div className="idling-datepickers-container">
              <DatePicker
                className="idling-datepicker"
                selected={beginDate}
                onChange={(date) =>{ setBeginDate(date) }}
                selectsStart
                startDate={beginDate}
                endDate={endDate}
                maxDate={endDate}
                showMonthDropdown
                useShortMonthInDropdown
              />
              <DatePicker
                className="idling-datepicker"
                selected={endDate}
                onChange={(date) => { setEndDate(date) }}
                selectsEnd
                endDate={endDate}
                minDate={beginDate}
                maxDate={new Date()}
                showMonthDropdown
                useShortMonthInDropdown
              />
            </div>
          </div>
          <div className='idling-detail-controller-row'>

            <div className='idling-detail-input-group'>
              <label className='idling-controls-label'>{`Minimum Duration (Minutes):`}</label>
                <DropdownSelector
                  className='idling-dropdown-small'
                  options={props.minDurations} 
                  handleChange={props.handleMinDurationChange}
                  defaultValue={props.minDuration}
                />
            </div>
          </div>
        </div>
      </div>
      <IdlingDetailPanel
        weeklyEvents={weeklyEvents}
        events={events}
        ymm={ymm}
        assetId={assetId}
        dbDisplayName={dbDisplayName}
      />
          <IdlingDetailTable
            parent={'vehicledetail'}
            setIncidentParent={props.setIncidentParent}
            columns={props.columns}
            data={events}
            sortCol={props.sortCol}
            desc={props.desc}
            handleSort={props.handleSort}
            beginDate={beginDate}
            endDate={endDate}
            group={props.group}
            vehicleClasses={props.vehicleClasses}
            minDuration={props.minDuration}
            tableType={'Vehicle'}
            dbName={dbName}
            csvFilename={csvDetailFilename}
            dbDisplayName={dbDisplayName}
          />
    </>
    )
}