import React from "react";
import IdlingMap from "./idling-map";
import IdlingDetailGraph from "./idling-detail-graph"

export default function IdlingDetailPanel(props){

  const { events, dbDisplayName } = props;
  if(events.length < 1) return (<div></div>)
  return(
      <div className="idling-top-panels">
        <div className="idling-detail-graph-wrapper">
          <IdlingDetailGraph weeklyEvents={props.weeklyEvents}/>
        </div>
        <div className="idling-detail-map-wrapper">
          <IdlingMap events={events} isDetail={true} dbDisplayName={dbDisplayName}/>
        </div>
      </div>
  )
}