import React, { useCallback, useState, useEffect} from "react";
import Loading from "../swt-loading";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { Roles } from "../../utility-components/UserUtils";

export default function CreateUserModal(props) {
  const [username, setUsername] = useState(null);
  const [groups, setGroups] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [db, setDb] = useState(props.db);
  const [domain, setDomain] = useState(null);
  const [fleetAdmin, setFleetAdmin] = useState(false);
  const [partnerAdmin, setPartnerAdmin] = useState(false);
  const [emailRegex, setEmailRegex] = useState(null);
  const { databases, apiURL, user } = props;

  useEffect(() => {
    setDomain(user.email.split("@")[1]);
  }, [setDomain, user])

  useEffect(() => {
    let regex = "^[\\w.\\-]{1,}@" + domain; // Allows alphanumeric chars and typical email chars (period, underscore, hyphen). Enforces @domain usage as well
    setEmailRegex(regex);
  }, [setEmailRegex, domain])

  function handleNameChange(e) {
    let name = e.target.value.trim();
    name = name.replace(";", "");
    setUsername(name);
  }

  function handleGroupChange(e) {
    let id = e.target.value.trim();
    id = id.replace(";", "");
    setGroupId(id);
  }

  const getGroups = useCallback((db) => {
    if(!apiURL || !db || !user)return;
    const url = `${apiURL}getGroups?dbName=${db}`;
    fetch(url, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error")
          alert("There was a server error during your request.");
        else {
          const obj = data.data;
          let sortedGroups = sortGroups(obj);
          setGroups(sortedGroups);
          setGroupId(sortedGroups[0].id);
        }
      })
      .catch((err) => {
        console.error(err)
        window.alert("There was database error on loading groups.")
      });
  },[apiURL, user]);

  function handleDbChange(e){
    setDb(e.target.value);
    getGroups(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let d = {
      username: username,
      password: Math.random().toString(36).slice(2),
      db: db,
      groupId: groupId,
      // String bool checks necessary to pass boolean type to API
      fleetAdmin: fleetAdmin === 'true',
      partnerAdmin: partnerAdmin === 'true',
      creatingUser: props.user.email,
    };
    props.createUser(d);
  }

  useEffect(() => {
    if(!apiURL || !user){
      let sortedGroups = sortGroups(props.groups)
      if(sortedGroups.length === 0) return
      setGroupId(sortedGroups[0].id);
      setGroups(sortedGroups);
    }
    else{
      getGroups(db);
    }
  }, [getGroups, db, apiURL, user, props.groups])

  function sortGroups (g) {
    let sortedGroups = g.sort((a,b) => a.name.localeCompare(b.name))
    let newGroupList = []
    sortedGroups.map((group) => {
      if (group.name === 'All Vehicles') {
        return newGroupList.unshift(group)
      } else {
        return newGroupList.push(group);
      }
    })
    return newGroupList;
  }

  if (!props.show) return null;
  return (
    <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={props.handleClose}>Close</S.ModalCloseButton>
        {(!groups || !groupId) && <Loading />}
        <S.ModalHeader>Add User to Database</S.ModalHeader>
          <S.ModalForm onSubmit={handleSubmit}>

            {databases &&
            <S.ModalFormInputWrapper>
            <S.ModalFormInputLabel>Primary Database</S.ModalFormInputLabel>
              
              <S.ModalFormSelect
                onChange={handleDbChange}
                value={db}
                >
                  {databases.map((d) => {
                    return <option key={d.name} value={d.name}>{d.display_name}</option>;
                  })}
                </S.ModalFormSelect>
       
            </S.ModalFormInputWrapper>
            }
            
            {user.role >= Roles.SuperAdmin && // Allow super admins to bypass domain constraints so they can add users outside of @sawatchlabs.com domain
              <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*Email:</S.ModalFormInputLabel>
                  <S.ModalFormInput 
                      data-testid="createUserEmailField-testid"
                      onChange={handleNameChange}
                      type='email'
                      required />
              </S.ModalFormInputWrapper>
            }
            {user.role <= Roles.PartnerAdmin && // Enforce domain restrictions for normal admin users
              <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*Email:</S.ModalFormInputLabel>
                <S.ModalFormInput 
                  data-testid="createUserEmailField-testid"
                  onChange={handleNameChange}
                  type='email'
                  pattern={emailRegex}
                  title={`Please use alphanumeric characters and match the domain: @${domain}`}
                  required />
              </S.ModalFormInputWrapper>
            }
            
            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Initial Group Assignment:</S.ModalFormInputLabel>
              {groups && <S.ModalFormSelect
                onChange={handleGroupChange}
                value={groupId}
                >
                {groups.map((g) => {return <option key={g.id} value={g.id}>{g.name}</option> })}
              </S.ModalFormSelect>
              }
            </S.ModalFormInputWrapper>
            {user.role >= Roles.SuperAdmin && 
            <>
              <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Fleet Admin Privileges:</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  onChange={(e) => setFleetAdmin(e.target.value)}
                  value={fleetAdmin}
                >
                  <option value={false}> No</option>
                  <option value={true}> Yes</option>
                </S.ModalFormSelect>
              </S.ModalFormInputWrapper>
            
              <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Partner Admin Privileges:</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  onChange={(e) => setPartnerAdmin(e.target.value)}
                  value={partnerAdmin}
                >
                  <option value={false}> No</option>
                  <option value={true}> Yes</option>
                </S.ModalFormSelect>
              </S.ModalFormInputWrapper>
            </>

            }
            <S.ModalFormButtonWrapper>
              <S.ModalSaveButton type='submit'>Save</S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
          </S.ModalForm>

        </S.ModalContainer>
    </S.ModalDisplay>
  );
}
