import React from 'react';
import { StatView } from '@sawatchlabs/swt-components';
import { FormatSummaryData } from './UtilityFunctions';
import styled from 'styled-components';

const StatViewWrapper = styled.div`
    padding-top: 25px;
    display: grid;
    grid-template-columns:${props => props.gridItemsPerRow ? `repeat(${props.gridItemsPerRow}, 200px)` : 'repeat(4, 200px)'};
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 50px;
`

export default function HomeStatViews({ emissionsSummary, displayInLbs, gridItemsPerRow }) {

    const units = displayInLbs ? "Lbs" : "Tons";
    let emissions = emissionsSummary.ghgTons;
    let emissionReductions = emissionsSummary.ghgTonsSaved;
    let costSavings = emissionsSummary.fuelCostSaved;
    let altFuelVehicles = emissionsSummary.phev + emissionsSummary.bev;
    let totalVehicles = emissionsSummary.vehicles
    if(units === 'Lbs'){
        emissions = emissionsSummary.ghgLbs;
        emissionReductions = emissionsSummary.ghgLbsSaved;
    }
    return (
        <StatViewWrapper gridItemsPerRow={gridItemsPerRow}>
            <StatView values={{value: FormatSummaryData('emissions', emissions), caption: `Total GHG Emissions`, subCaption: `(${units})`}}/>
            <StatView values={{value: FormatSummaryData('emissionReductions', emissionReductions), caption: 'Emission Reductions', subCaption: `(${units})`}}/>
            <StatView values={{value: FormatSummaryData('fuelCostSaved', costSavings), caption: 'Savings'}}/>
            <StatView values={{value: `${altFuelVehicles}/${totalVehicles}`, caption: 'Alternative Fuel Vehicles/Total Vehicles'}}/>
        </StatViewWrapper>
    )
}
