import React from 'react';


export const Filter = ({ column }) => {
    return (
      <div>
        {column.canFilter && column.render("Filter")}
      </div>
    )
  }

  export const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) => {
    return (
      <input 
        className='filter'
        type='text'
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search by Asset ID`}
      />
    )
  }