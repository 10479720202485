import React, {useState} from "react";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
export default  function CreateAccountModal(props) {


  // Note: lots of state - we could consolidate by creating like objects (i.e. conform, conformMsg, conformColor)
  const [integration, setIntegration] = useState('geotab');
  const [companyName, setCompanyName ] = useState(null);
  const [database, setDatabase]  = useState(null);
  const [apiIdent, setApiIdent] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [databaseColor, setDatabaseColor] = useState({color: 'black'})
  const [passwordColor, setPasswordColor] = useState({color: 'black'})

  const [conformField, setConformField] = useState(null);
  const [conform, setConform] =useState(false);
  const [conformMsg, setConformMsg] = useState('');
  const [conformColor, setConformColor] = useState({color: 'red'})

const ALPHANUMERIC_REGEX = "^[\\w\\-\\(\\) ]*$" //Prevents all special characters except for " - _ ( ) "
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters '_' , '-' , '()' are also allowed.";

  function handlePassw0Change(e){
    setPassword(e.target.value)
    const field = e.target.value
    if (field !== null && field.length < props.passwordRules.minLength - 1) {
      return(
        setPasswordColor({color: 'red'}),
        setConformMsg(`Minimum password length: ${props.passwordRules.minLength} characters`))
    } else {
      setPasswordColor({color: 'black'})
      setConformMsg('')
    }
    if (field === conformField) {
      return (setConformColor({color: 'green'}),
              setConform(true))
    } else {
      setConformColor({color: 'red'})
      setConform(false)
    }
  }

  function handlePassw1Change(e){
    //check password rules pass upon conform attempts
    const field = password;
    if (password.length < props.passwordRules.minLength) {
      return(
        setPasswordColor({color: 'red'}),
        setConformMsg(`Minimum password length: ${props.passwordRules.minLength} characters`)
      )} else {
        setConformMsg('')
        setPasswordColor({color: 'black'})
    }
    //check for conformance
    if (field === e.target.value.trim()) {
      setConformColor({color: 'green'})
      setConform(true);
      setConformField(e.target.value)
    } else {
      setConformColor({color: 'red'})
      setConform(false)
      setConformMsg('Passwords do not match')
      setConformField(e.target.value)
    }
  }

  function handleSubmit(e){
    e.preventDefault();
    let geotabIntegrated = false;
    let nfIntegrated = false;
    if (integration === "geotab") geotabIntegrated = true;
    let d = {
      integration: integration,
      apiIdent: apiIdent,
      username: username,
      password: password,
      dbName: database.toLowerCase(),
      companyName: companyName,
      geotabIntegrated: geotabIntegrated,
      nfIntegrated: nfIntegrated,
      holistic: false,
      host: "null",
      email: props.user.email
    };
    props.createAcct(d);
  };


  function handleDatabaseOnBlur(){
    if(database === null) return;
    const dbName = database.toLowerCase();
    const url = `${props.apiURL}checkUIAccountExists?database=${dbName}`;
    fetch(
        url, {
        headers: { Authorization: `Bearer ${props.user.token}` }
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.status === "error")
          alert("There was a server error during your request.");
        else {
          let dbaseColor = {color: 'green'}
          let cformMessage = '';
          if (data.response === true){
            dbaseColor = {color: 'red'}
            cformMessage = 'Database already exists';
          }
          setDatabaseColor(dbaseColor);
          setConformMsg(cformMessage); 
        }
      });
  };

    if (!props.show) return null;
    let arr = [];
    props.integrations.forEach(e => {
      if (e.available) arr.push(e.name);
    });
    arr.sort();
    return (
      <S.ModalDisplay>
        <S.ModalContainer>
          <S.ModalCloseButton onClick={props.handleClose}>Close</S.ModalCloseButton>
          <S.ModalHeader>Add Database</S.ModalHeader>
          <S.ModalForm onSubmit={handleSubmit}>
            <S.ModalFormErrorMsg>{conformMsg ? conformMsg : ''}</S.ModalFormErrorMsg>
            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Telematics Provider (Select None if unsure):</S.ModalFormInputLabel>
              <S.ModalFormSelect onChange={(e) => setIntegration(e.target.value)}>
                {props.createDatabaseOptions()}
                <option key='none' value='none' id='none'>None</option>
              </S.ModalFormSelect>
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>New Sawatch Database Name(Unique Id):</S.ModalFormInputLabel>
              <S.ModalFormInput
                required
                type='text'
                pattern={ALPHANUMERIC_REGEX}
                title={INPUT_ERROR_MESSAGE}
                style={databaseColor}
                onChange={(e) => setDatabase(e.target.value)}
                onBlur={handleDatabaseOnBlur}
                />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Company Name (Display Name):</S.ModalFormInputLabel>
              <S.ModalFormInput type='text' pattern={ALPHANUMERIC_REGEX} title={INPUT_ERROR_MESSAGE} onChange={e => setCompanyName(e.target.value)} />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Telematics Provider Database Name:</S.ModalFormInputLabel>
              <S.ModalFormInput type='text' onChange={e => setApiIdent(e.target.value)} />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Telematics Provider Username:</S.ModalFormInputLabel>
              <S.ModalFormInput type='text' autoComplete='off' onChange={e => setUsername(e.target.value)} />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Telematics Provider Password:</S.ModalFormInputLabel>
              <S.ModalFormInput
                required
                type='password' 
                autoComplete='off'
                style={passwordColor}
                onChange={handlePassw0Change} />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
              <S.ModalFormInputLabel>Confirm Telematics Provider Password:</S.ModalFormInputLabel>
              <S.ModalFormInput
                required
                type='password' 
                style={conformColor}
                onChange={handlePassw1Change} />
            </S.ModalFormInputWrapper>

            <S.ModalFormButtonWrapper>
              <S.ModalSaveButton 
                type='submit' 
                disabled={(conformMsg === '' && password !== null && username !== null && conformField !== null && conform === true) ? false : true}>
                Save
              </S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
          </S.ModalForm>
        </S.ModalContainer>
      </S.ModalDisplay>
    );
  }
