import React, { useMemo } from "react";
import ReactModal from 'react-modal';

const modalStyle = {
    content : {
      top                   : '45%',
      width                 : '50%',
      height                : '60%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      zIndex                : '1000'
    }
  };

//ReactModal.setAppElement("controls-root");

export default function AdditionalFieldsModal(props){

  const { groups, additionalFieldsGroup, selectedGroups, geotabIntegrated } = props;

  const _removeSelectedForCategory = (category, _copySelectedGroups) => {
    return _copySelectedGroups.filter((g) => g.category.id !== category.id);
  }

  const handleDropdownChange = (obj, e)=>{
    const sg = _removeSelectedForCategory(obj, selectedGroups.slice());
    if(e.target.value.length > 0)sg.push({category: obj, id: e.target.value});
    props.handleSelectedGroupsChange(sg);
  }

  const additionalFieldsGroups = useMemo(() => {
    const afGroup = groups.filter((g) => g.name === additionalFieldsGroup);
    if(afGroup.length < 1) {
      afGroup.push({"id": "0", "name": "Not Available", "children": [{"id": "-1", "name": "Not Available"}]});
      //window.alert('Unable to find matching groups. Please contact Sawatch support to get this error resolved.');
    }
    
    const dropdowns = () => {
      const dropdownItems = [];
      if(afGroup.length < 1)return [];
      if(!afGroup[0].children)return [];
      let groupIds = afGroup[0].children.map((c) => {
        return c.id;
      });

      const grps = geotabIntegrated ? groups : afGroup[0].children;

      grps.forEach((g) => {
        if (groupIds.includes(g.id) && g.children) {
          g["dropdownOptions"] = g.children;
          const sg = selectedGroups.map((g) => {return g.id});
          g.dropdownOptions.forEach((c) => {
            const dropdownOption = groups.find((o) => o.id === c.id);
            if(dropdownOption){
              c["name"] = dropdownOption.name;
              c["selected"] = sg.indexOf(c.id) < 0 ? false : true;
            }
          });
          dropdownItems.push(g);
        }
      });
      return dropdownItems;
    };

    if(afGroup)afGroup[0]["dropdowns"] = dropdowns();
    return afGroup[0];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, selectedGroups]);

  const dropdowns = useMemo(() => {

    if (!additionalFieldsGroups.dropdowns || additionalFieldsGroups.dropdowns.length < 1) return;
    return additionalFieldsGroups.dropdowns.map((dd) => {
      var selected = dd.dropdownOptions.filter((op) => op.selected === true);
      return (
        <div className="speedn-dropdown-container" key={dd.id}>
          <label key={dd.id}>
            {dd.name}
            {": "}
          </label>
          <select
            value={selected[0] ? selected[0].id : ""}
            onChange={(e) => handleDropdownChange(dd, e)}
          >
            <option value=""> None Selected</option>
            {dd.dropdownOptions.map((o) => {
              return (
                <option value={o.id} key={o.id}>
                  {o.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFieldsGroups.dropdowns, selectedGroups]);



  const closeModal = () => {
    props.handleChange(false);
  }

  return(    
    <ReactModal 
      isOpen={props.isOpen} 
      style={modalStyle}
      parentSelector={() => document.querySelector('#controls-root')}>
      <div className="speedn-update-modal"> 
          <button className="speedn-modal-close-button" onClick={closeModal}>
              Close
          </button>
          <div className="speedn-dropdown-items-wrapper">
            <>{dropdowns}</>
          </div>
          <button className="speedn-modal-clear-button" onClick={()=>{props.handleSelectedGroupsChange([])}}>
              Clear Filters
          </button>
      </div>
    </ReactModal>
  );
}