import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function Graphs(props) {

  // ADDED IN FOR STACKED GRAPHS

  const legendOpts = {
    display: false,
    position: 'bottom',
    fullWidth: false,
    reverse: false,
    labels: {
      fontColor: '#4a4a4a'
    }
  };

  const options = {
    plugins:{
      legend: legendOpts,
      title: {
        display: false,
        text: props.graphData === undefined ? ' - ' : props.graphData.title,
        fontSize: 36,
        fontStyle: 'bold',
        fontColor: '#4a4a4a',
        justifyContent: 'center'
      },
    },
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: '#e8e9ea'
      }
    },
    scales: {
      x: {
          stacked: true
        },
      y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: function(value) {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  function selectGraphData() {
    let data;
    if (props.graphData && props.graphData.avgData) {
      data = {
        labels: props.graphData.labels,
        datasets: [
          {
            label: props.graphData.label,
            data: props.graphData.data,
            backgroundColor: props.graphData.color,
            hoverBackgroundColor: props.graphData.hoverColor,
            stack: 1
          },
          {
            label: props.graphData.avgLabel,
            data: props.graphData.avgData,
            backgroundColor: props.graphData.avgColor,
            hoverBackgroundColor: props.graphData.avgHoverColor,
            stack: 2
          }
        ]
      };
    } else {
      data = {
        labels: props.graphData === undefined ? ' ' : props.graphData.labels,
        datasets: [
          {
            data: props.graphData === undefined ? [] : props.graphData.data,
            backgroundColor:
              props.graphData === undefined ? 'black' : props.graphData.color,
            hoverBackgroundColor:
              props.graphData === undefined
                ? 'black'
                : props.graphData.hoverColor
          }
        ]
      };
    }
    return data;
  }
  return (
    <div className="sftd-vsl-wrapper">
      <h3 className="sftd-vsl-ttl">
        {props.graphData === undefined ? ' ' : props.graphData.title}
      </h3>
      <p className="sftd-yAxis-label">
        {props.graphData === undefined ? '  ' : props.graphData.yAxis}
      </p>
      <Bar
        id="sftd-visual"
        data={selectGraphData()}
        legend={legendOpts}
        options={options}
        width={300}
        height={120}
      />
      <p className="sftd-xAxis-label">
        {props.graphData === undefined ? ' ' : props.graphData.xAxis}
      </p>
    </div>
  );
}
