import React from "react";
import SpeednMap from "./speedn-map";
import SpeednDetailGraph from "./speedn-detail-graph"

export default function SpeednDetailPanel(props){

  const { events, dbDisplayName } = props;
  if(events.length < 1) return (<div></div>)
  return(
      <div className="speedn-top-panels">
        <div className="speedn-detail-graph-wrapper">
          <SpeednDetailGraph weeklyEvents={props.weeklyEvents}/>
        </div>
        <div className="speedn-detail-map-wrapper">
          <SpeednMap events={events} isDetail={true} dbDisplayName={dbDisplayName}/>
        </div>
      </div>
  )
}