import { reasonForFailure, numberWithCommas } from "./dataFormatter";

export default function StandardizeTableData(data, type) {
  if (!type || !data || data.length < 1 || data === "misformed request")
    return [];

  function _classCheck(vcl) {
    const classWhiteList = ["SUV", "Sedan", "Minivan", "Cargo-van", "Pickup"];
    if (classWhiteList.includes(vcl.vehicle_class)) return true;
    else return false;
  }
  let standardizedData = [];
  if (type === "percent") {
    if (data.length && data[0] !== "loading")
      data.forEach((d) => {
        let obj = {};
        obj["Location"] = d.address;
        obj["NumbOfEVs"] = (d.maxKW / 6.9).toFixed(0);
        obj["maxKW"] = d.maxKW.toFixed(1);
        obj["lat"] = d.latitude;
        obj["lon"] = d.longitude;
        if (d.multiplePeaks === true) {
          obj["time"] = "Multiple Peaks";
        } else if (d.time !== "" && d.multiplePeaks === false) {
          var timeZoneOffset = new Date(d.time).getTimezoneOffset();
          var correctedTime = new Date(
            new Date(d.time).setMinutes(
              new Date(d.time).getMinutes() + timeZoneOffset
            )
          );

          var timeString = new Date(correctedTime).toString();

          if (timeString === "Invalid Date") timeString = "";
          else {
            var ts = timeString.split(" ");

            const formattedTime = `${
              ts[0] + " " + ts[1] + " " + ts[2] + ", " + ts[3] + " - " + ts[4]
            }`;
            obj["time"] = formattedTime;
          }
        }
        standardizedData.push(obj);
      });
    return standardizedData.sort((a, b) =>
      b.NumbOfEVs - a.NumbOfEVs ? -1 : 1
    );
  }
  if (type === "recs") {
    data.forEach((d) => {
      var inClassReasonForFailure = false;
      const inClass = _classCheck(d);
      let evScore = Math.round(d.overall);
      let ezEvRec = d.recommendation;
      let lvl2Charging = Math.round(10 * d.lvl2ChargingHrs) / 10;
      // Blanks out rec and score if there is not enough data (i.e. 0 overall score) - per SB request 12/23
      if (d.overall === 0) {
        ezEvRec = "-";
        evScore = "-";
        lvl2Charging = "-";
      }
      if (
        d.recommendation === "No Change" ||
        d.recommendation === "Optimization Candidate"
      )
        lvl2Charging = "-";
      // Compares vehicle to in class rec if passing score and no change - Per SB request 12/21
      if (d.overall > 84 && d.recommendation === "No Change") {
        inClassReasonForFailure = true;
        evScore = d.bestInClassOverall;
      }

      if (ezEvRec === "Optimization Candidate") {
        if (d.bestInClassOverall && d.bestInClassOverall < 85) {
          ezEvRec = "No Change";
        } else if (
          d.bestInClassYMM &&
          (!d.bestInClassYMM.includes("BEV") ||
            !d.bestInClassYMM.includes("PHEV"))
        ) {
          ezEvRec = "No Models Fit";
        }
      }

      var obj = {};
      obj["AssetID"] = d.asset_id;
      obj["Year"] = d.year;
      obj["Make"] = d.make;
      obj["Model"] = d.model;

      if (inClass === true) {
        obj["lvl2ChargingHrs"] = lvl2Charging;
        obj["ezEVScore"] = evScore;
        obj["ezEVRec"] = ezEvRec;
        obj["ReasonOfFailure"] = reasonForFailure(d, inClassReasonForFailure);
      }
      // Blanks out scores if there are no EV or PHEV options for the class based on whitelist above - per SB request 12/21
      else {
        obj["ezEVScore"] = "-";
        obj["lvl2ChargingHrs"] = "-";
        obj["ezEVRec"] = "-";
        obj["ReasonOfFailure"] = "No EV or PHEV Models Available";
      }
      standardizedData.push(obj);
    });
    return standardizedData;
  }
  if (type === "class") {
    data.forEach((d) => {
      var obj = {};
      obj["AssetID"] = d.asset_id;
      obj["Year"] = d.year;
      obj["Make"] = d.make;
      obj["Model"] = d.model;

      obj["BestInClassYMM"] = d.bestInClassYMM;
      obj["BestInClassOverall"] = !isNaN(Math.round(d.bestInClassOverall))
        ? Math.round(d.bestInClassOverall)
        : "N/A";
      obj["lvl2ChargingHrs"] = Math.round(10 * d.lvl2ChargingHrs) / 10;
      obj["BestInClassReasonFailure"] =
        d.bestInClassOverall >= 85 ? "N/A" : d.bestInClassFailureReason;
      standardizedData.push(obj);
    });
    return standardizedData;
  }
  if (type === "assumptions") {
    let vcls = data;
    if (data.combined) {
      vcls = data.combined;
    }
    vcls.forEach((d) => {
      var obj = {};
      obj["Type"] = d.type;
      obj["Model"] = d.ymm;
      obj["Class"] = d.vehicle_class;
      obj["Price"] = `$${numberWithCommas(Math.round(d.net_price))}`;
      obj["BatteryCap"] = d.battery_capacity > 0 ? d.battery_capacity : "N/A";
      obj["MPG"] =
        d.mpg_c === null || d.mpg_c === "" ? "N/A" : `${d.mpg_c}/${d.mpg_h}`;
      standardizedData.push(obj);
    });
    return standardizedData;
  }

  if (type === "vehicles") {
    let vcls = data;
    vcls.forEach((d) => {
      var obj = {};
      obj["AssetID"] = d.asset_id;
      obj["YMM"] = d.year + " " + d.make + " " + d.model;
      obj["lvl2ChargingHrs"] = Math.round(d.rec_ev_avg_level2_hrs);
      standardizedData.push(obj);
    });
    return standardizedData;
  }
}

export const csvRecHeaders = () => {
  const headers = [
    { label: "Asset ID", key: "AssetID" },
    { label: "Year", key: "Year" },
    { label: "Make", key: "Make" },
    { label: "Model", key: "Model" },
    { label: "ezEV Score", key: "ezEVScore" },
    { label: "ezEV Recommendation", key: "ezEVRec" },
    { label: "Avg. Level 2 Charge Time (hrs)", key: "lvl2ChargingHrs" },
    { label: "Reason", key: "ReasonOfFailure" },
  ];
  return headers;
};

export const csvClassRecHeaders = () => {
  const headers = [
    { label: "Asset ID", key: "AssetID" },
    { label: "Year", key: "Year" },
    { label: "Make", key: "Make" },
    { label: "Model", key: "Model" },
    { label: "Best In Class Score", key: "BestInClassOverall" },
    { label: "Best In Class Fit", key: "BestInClassYMM" },
    { label: "Avg. Level 2 Charge Time (hrs)", key: "lvl2ChargingHrs" },
    {
      label: "Best In Class Reason For Failure",
      key: "BestInClassReasonFailure",
    },
  ];
  return headers;
};
export const tableRecHeaders = () => {
  const columns = [
    { Header: "Asset ID", accessor: "AssetID" },
    { Header: "Year", accessor: "Year" },
    { Header: "Make", accessor: "Make" },
    { Header: "Model", accessor: "Model" },
    { Header: "ezEV Score", accessor: "ezEVScore" },
    { Header: "ezEV Recommendation", accessor: "ezEVRec" },
    { Header: "Avg. Level 2 Charge Time (hrs)", accessor: "lvl2ChargingHrs" },
    { Header: "Reason", accessor: "ReasonOfFailure" },
  ];
  return columns;
};

export const tableVehicleHeaders = () => {
  return [
    { Header: "Asset ID", accessor: "AssetID" },
    { Header: "YMM", accessor: "YMM" },
    // { Header: "ezEV Recommendation", accessor: "ezEVRec" },
    { Header: "Avg Lvl 2 Chrg hrs", accessor: "lvl2ChargingHrs" },
  ];
};
export const csvVehicleHeaders = () => {
  const headers = [
    { label: "Asset ID", key: "AssetID" },
    { label: "YMM", key: "YMM" },
    { label: "Average Level 2 Charging (hrs)", key: "lvl2ChargingHrs" },
  ];
  return headers;
};

export const tableBICHeaders = () => {
  const columns = [
    { Header: "Asset ID", accessor: "AssetID" },
    { Header: "Year", accessor: "Year" },
    { Header: "Make", accessor: "Make" },
    { Header: "Model", accessor: "Model" },
    { Header: "Best In Class Score", accessor: "BestInClassOverall" },
    { Header: "Best In Class Fit", accessor: "BestInClassYMM" },
    { Header: "Avg. Level 2 Charge Time (hrs)", accessor: "lvl2ChargingHrs" },
    { Header: "Best In Class Reason", accessor: "BestInClassReasonFailure" },
  ];
  return columns;
};

export const csvPercentHeaders = () => {
  const headers = [
    { label: "Location", key: "Location" },
    { label: "Max Vehicles Projected to Charge Concurrently", key: "NumbOfEVs" },
    { label: "Max kW Demand", key: "maxKW" },
    { label: "Time of Peak Demand", key: "time" },
  ];
  return headers;
};

export const tablePercentHeaders = () => {
  const columns = [
    { Header: "Location", accessor: "Location" },
    { Header: "Max Vehicles Projected to Charge Concurrently", accessor: "NumbOfEVs" },
    { Header: "Max kW Demand", accessor: "maxKW" },
    { Header: "Time of Peak Demand", accessor: "time" },
  ];
  return columns;
};

export const csvAssumptionHeaders = () => {
  const headers = [
    { label: "Type", key: "Type" },
    { label: "Model", key: "Model" },
    { label: "Class", key: "Class" },
    { label: "MPG (City/Hwy)", key: "MPG" },
    { label: "Battery Capacity (kWh)", key: "BatteryCap" },
  ];
  return headers;
};

export const tableAssumptionHeadersICE = () => {
  const columns = [
    { Header: "Model", accessor: "Model" },
    { Header: "Class", accessor: "Class" },
    { Header: "Price", accessor: "Price" },
    { Header: "MPG (Cty/Hwy)", accessor: "MPG" },
  ];
  return columns;
};

export const tableAssumptionHeadersBEV = () => {
  const columns = [
    { Header: "Model", accessor: "Model" },
    { Header: "Class", accessor: "Class" },
    { Header: "Price", accessor: "Price" },
    { Header: "Battery Capacity (kWh)", accessor: "BatteryCap" },
  ];
  return columns;
};
