import React from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';
import { Roles } from "../utility-components/UserUtils";

export default function UserManagementMenu(props) {

  const { user, changePassword, showUserManagementMenu, logout, versioningEnabled, showUserSettings } = props;
  

  function handlePasswordClick(){
    changePassword();
    showUserManagementMenu();
  };

  return (
    <S.UserManagementMenuContainer>
      {user.role >= Roles.SuperAdmin && 
        <>
          <S.ManagementMenuTitle>Dashboard Options</S.ManagementMenuTitle>
            <S.ManagementMenuList>
              <S.ManagementMenuItem>
                <input className='swt-admin-table-input' id="versionToggle" type="checkbox" defaultChecked={versioningEnabled} onClick={props.toggleVersioning}/>
                <label htmlFor="versionToggle">{`Versioning ${versioningEnabled ? 'Enabled' : 'Disabled'}`}</label>
              </S.ManagementMenuItem>
            </S.ManagementMenuList>
        </>
      }
      <S.ManagementMenuTitle>User Management</S.ManagementMenuTitle>

      <S.ManagementMenuList>
      <S.ManagementMenuItem onClick={showUserSettings}>
          User Settings
        </S.ManagementMenuItem>
        <S.ManagementMenuItem onClick={handlePasswordClick}>
          Change Password
        </S.ManagementMenuItem>
        <S.ManagementMenuItem onClick={logout}>
          Logout
        </S.ManagementMenuItem>
      </S.ManagementMenuList>
    </S.UserManagementMenuContainer>
  );
}
