export function numberWithCommas(x) {
  if (x === undefined) return;
  if (isNaN(x)) return '-';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertKMtoMiles(input) {
  return input * 0.621371;
}

export function vehicleIdDisplay (vcl) {
  if(vcl) {
  if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
  if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
  if (vcl.vin) return vcl.vin
  }
}

export function formatDate(i) {
  if (i === undefined) return '-';
  else if (typeof i === 'string') {
    var year = i.slice(2, 4);
    var month = i.slice(5, 7);
    var day = i.slice(8, 10);
    return `${month}/${day}/${year}`;
  } else {
    var Day = i.getDate();
    var Month = i.getMonth() + 1;
    var Year = i.getFullYear();
    return `${Month}/${Day}/${Year}`;
  }
}

export function formatTime(input) {
  switch (input) {
    case '01:00':
      return '1AM';
    case '02:00':
      return '2AM';
    case '03:00':
      return '3AM';
    case '04:00':
      return '4AM';
    case '05:00':
      return '5AM';
    case '06:00':
      return '6AM';
    case '07:00':
      return '7AM';
    case '08:00':
      return '8AM';
    case '09:00':
      return '9AM';
    case '10:00':
      return '10AM';
    case '11:00':
      return '11AM';
    case '12:00':
      return '12PM';
    case '13:00':
      return '1PM';
    case '14:00':
      return '2PM';
    case '15:00':
      return '3PM';
    case '16:00':
      return '4PM';
    case '17:00':
      return '5PM';
    case '18:00':
      return '6PM';
    case '19:00':
      return '7PM';
    case '20:00':
      return '8PM';
    case '21:00':
      return '9PM';
    case '22:00':
      return '10PM';
    case '23:00':
      return '11PM';
    case '24:00':
      return '12AM';

    default:
  }
}

export function humanFriendlyTime(ts, deselected) {
  if (ts === -1) return ' - ';
  var dt = new Date(ts);
  if (deselected === true) {
    dt.setDate(dt.getDate() + 1);
  }
  var date = dt.toDateString();
  var updatedText = date.substring(0, date.length - 12);
  var short = `${dt.toLocaleDateString('en-US')}`;
  var updatedShort = short.substring(0, short.length - 5);
  if (updatedShort === 'Invalid' || updatedText === 'Invalid') return null;
  return `${updatedShort + ' ' + updatedText}`;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFormattedAPIDate(date) {
  var d = new Date(date);
  d =
    d.getFullYear() +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + d.getDate()).slice(-2);
  return d;
}

export function normalizeString(input) {
  function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function lowerCaseAllWordsExceptFirstLetters(string) {
    return string.replace(/\w\S*/g, function(word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  }

  return upperCaseFirstLetter(
    lowerCaseAllWordsExceptFirstLetters(input.trim())
  );
}
