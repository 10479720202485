import React, {useState, useEffect, useMemo, Suspense} from 'react';
import { processApiResponse } from '../../utility-components/ConformUnits';
import { columnsToExcelHeaders, rowsToData, changeLogTableColumns } from './TableHelpers';
import { convertISOToExcelSerial, formatDecimal } from '../UtilityFunctions';
import AdminTableView from "./AdminTableView";
import * as S from '../../../../styles/core-styles/AdminTools-styles';

const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));


export default function SettingsChangeLog (props) {
    const [settingsData, setSettingsData] = useState([])
    const {apiURL, db, user, dbSettings} = props;

    useEffect(() => {
        getSettingsLog()
    // eslint-disable-next-line
    },[db, user, dbSettings])

    function getSettingsLog () {
        let url = `${apiURL}getDbSettingsLog?dbName=${db}`;
        fetch(url, {
            headers: { Authorization: `Bearer ${user.token}` },
          }).then((res) => res.json())
          .then((data) => {               
            let formattedSettings = data.data.map((d) => {
                const preservedTimestamp = convertISOToExcelSerial(d.ts,true);
                return {...processApiResponse(user.userSettings, d, false),sortable_timestamp:preservedTimestamp}
            })
            setSettingsData(formattedSettings)
          })
    }

    function roundValues(obj) {
        let keys = Object.keys(obj)
        keys.forEach((k) => {
            if(typeof obj[k] === 'number') {
                obj[k] = formatDecimal(obj[k].toString(), 4)
            }
        })
    }

    //Used to track column value changes between rows
    function changeInValuesCheck (data) {
        if(!data) return;
        let updatedData = data.map((d,idx) => {
            let keysToIgnore = ['email', 'dateTime'] //Dont want these columns to highlight changes
            let changedValues = []
            let keys = Object.keys(d)
            keys.forEach((k) => {
                if(!data[idx+1] || keysToIgnore.includes(k)) return;
                if(d[k] !== data[idx+1][k]) {
                    changedValues.push(k);
                } 
            })
            d.settings_changed = changedValues
            return d;
        })
        return updatedData
    }

    const mappedSettings = useMemo(() => {
        if(!settingsData) return null;
        if(settingsData.length < 1) return [];

        const settings = JSON.parse(JSON.stringify(settingsData))
        return settings.map((setting) => {
            setting.dateTime = setting.ts ? `${setting.ts.date} - ${setting.ts.time}` : '';
            //Need to convert boolean values to strings so table cell can display them
            setting.get_location = setting.get_location === null ? '' : setting.get_location.toString();
            setting.default_selected = setting.default_selected === null ? '' : setting.default_selected.toString();
            setting.get_diagnostic = setting.get_diagnostic === null ? '' : setting.get_diagnostic.toString();
            setting.operational_fit = setting.operational_fit === null ? '' : setting.operational_fit.toString();
            roundValues(setting)
            return setting;
        })
    },[settingsData])

    const tableColumns = changeLogTableColumns(dbSettings, user.userSettings)
    return (
        <>
        <S.TableHeaderContainer>
        <S.AdminTableExplainTextPrimary>List of database settings each time settings were updated and saved in {props.dbDisplayName}.</S.AdminTableExplainTextPrimary>
        </S.TableHeaderContainer>
        <S.TableHeaderContainer>
        <Suspense fallback={<div></div>}>
          <ExcelDownloadButton
            downloadType={"changeLogDownload"}
            columns={columnsToExcelHeaders(tableColumns)}
            data={rowsToData(tableColumns, mappedSettings)}
            dbDisplayName={props.dbDisplayName}
            dbSettings={dbSettings}
            userSettings={user.userSettings}
          /> 
        </Suspense>
        </S.TableHeaderContainer>
        <AdminTableView 
            columns={tableColumns.filter((column)=> column.showInInterface)}
            data={changeInValuesCheck(mappedSettings)}
            stickyCols={1}
            noDataMessage={'No settings data to display.'}
            sortBy={{id: 'dateTime', desc: true}}
        />
        </>
    )
}