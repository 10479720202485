import React, { useEffect, useState } from "react";
import Loading from "../dashboard-components/swt-loading";
import ServerGraph from "./ServerGraph";
import { DropdownSelector } from "@sawatchlabs/swt-components";
import {schemeTableau10, interpolateInferno } from "d3-scale-chromatic";
import * as S from '../../../styles/core-styles/DevTools-styles';

const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";
const INITIAL_DATE_WINDOW = 30; //days to show at start
const INITIAL_SEGEMENT = 15; //initial segment slice
const DATA_OPTIONS = [
  {id: "km", value: "Kilometers", color: schemeTableau10[0]},
  {id: "telemetryCount", value: "Telemetry Count", color: schemeTableau10[1]},
  {id: "activeVehicles", value: "Active Vehicles", color: schemeTableau10[2]},
  {id: "vehicles", value: "Vehicles Under Analysis (90s)", color: schemeTableau10[3]},
  {id: "ingest", value: "Data Ingest Times", color: "rgba(0,0,0,1)"},
  {id: "process", value: "Data Process Times", color: "rgba(0,0,0,1)"},
]

export default function ServerDemandGraphs(props) {
  const { user, apiUrl } = props;
  const [graphDataPoints, setGraphDataPoints] = useState(undefined);
  const [activeVehicles, setActiveVehicles] = useState(undefined);
  const [telemetryCount, setTelemeteryCount] = useState(undefined);
  const [telemetryLabels, setTelemetryLabels] = useState(undefined);
  const [ingestData, setIngestData] = useState(undefined);
  const [ingestLabels, setIngestLabels] = useState(undefined);
  const [ingestType, setIngestType] = useState(undefined);
  const [kmDelta, setKmDelta] = useState(undefined);
  const [vehiclesUnderAnalysis, setVehiclesUnderAnalysis] = useState(undefined);
  const [vehiclesUnderAnalysisLabels, setVehiclesUnderAnalysisLabels] = useState(undefined);
  const [graphLabels, setGraphLabels] = useState(undefined);
  const [graphStartDate, setGraphStartDate] = useState(undefined);
  const [graphStopDate, setGraphStopDate] = useState(undefined);
  const [graphType, setGraphType] = useState(undefined);
  const [datasetLabel, setDatasetLabel] = useState("undefined");

  //initial state setup
  useEffect(() => {
    _getTelemetryStats()
    setGraphStopDate(new Date());
    const d = new Date();
    d.setDate(d.getDate()-INITIAL_DATE_WINDOW);
    setGraphStartDate(d);  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    //handle date changes
    if(!graphStartDate || !graphStopDate)return;
    const tsd = graphStopDate.getTime() - graphStartDate.getTime();
    const window = (tsd / 1000 / 86400)+1;//add one to use zero index
    _getTelemetryStats(window);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphStartDate, graphStopDate]);

  useEffect(() => {
    //handle graphType change
    switch (graphType){
      case 'activeVehicles':
        setGraphLabels(telemetryLabels);
        setGraphDataPoints(activeVehicles);
        setDatasetLabel("Active Vehicles");
        break;
      case 'km':
        setGraphLabels(telemetryLabels);
        setGraphDataPoints(kmDelta);
        setDatasetLabel("KM");
        break;
      case 'ingest':
        setGraphLabels(ingestLabels);
        setGraphDataPoints(ingestData);
        setIngestType("ingest");
        setDatasetLabel("Data Ingest Times");
        break;
      case 'process':
        setGraphLabels(ingestLabels);
        setGraphDataPoints(ingestData);
        setIngestType("process");
        setDatasetLabel("Data Process Times");
        break;
      case 'vehicles':
        setGraphLabels(vehiclesUnderAnalysisLabels);
        setGraphDataPoints(vehiclesUnderAnalysis);
        setIngestType("vehicles");
        setDatasetLabel("Vehicles Under Analysis");
        break;
      default:
        setGraphLabels(telemetryLabels);
        setGraphDataPoints(telemetryCount);
        setDatasetLabel("Telemetry Count");
    };
  }, [graphType, activeVehicles, kmDelta, telemetryCount, telemetryLabels, ingestLabels, ingestData, vehiclesUnderAnalysis, vehiclesUnderAnalysisLabels]);

  const _getTelemetryStats = (window) => {
    if(!apiUrl || !user)return;
    if(!window)window = INITIAL_DATE_WINDOW
    const labels = [];
    const url = `${apiUrl}getTelemetryStats?period=${window}`;
    fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error")
          alert(SERVER_ERRORED_MESSAGE);
        else {
          const points = [];
          const vehicles = [];
          const km = [];
          //reverse so data runs left to right in graph and guarantee order correctnees
          data.data.reverse();
          data.data.forEach((d)=>{
            labels.push(d.utc_ts);
            vehicles.push(parseInt(d.active_vehicles));
            points.push(parseInt(d.location_telemetry) + parseInt(d.diagnostic_telemetry));
            if(d.km_delta > 0)km.push(parseFloat(d.km_delta));
          });
          setActiveVehicles(vehicles);
          setTelemeteryCount(points);
          setKmDelta(km);
          setTelemetryLabels(labels);
          setGraphType(graphType ? graphType : "km");
        }
      });
  }
  
  useEffect(() => {
    if(!ingestType || !user || !apiUrl || ingestType === "vehicles")return;
      const segment = INITIAL_SEGEMENT;
      //valid types = process, ingest //need to refactor this out
      const url = `${apiUrl}getDatalayerStats?segment=${segment}&start=${graphStartDate}&stop=${graphStopDate}&type=${ingestType}`;
      fetch(url, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === "error") {
            alert(SERVER_ERRORED_MESSAGE);
            return console.error("Error in getDatalayerStats")
          } 
          if (data.data) {
            data.data.forEach((d)=>{
              const ts = new Date(d.start);
              d.epoch = ts.getTime();
            })
            const labels = new Set();
            const datamap = {};
            const duration = (d) => {
              return ((d.duration.minutes ? parseInt(d.duration.minutes) : 0)+(d.duration.seconds ? parseFloat(d.duration.seconds/60) : 0)+(d.duration.hours ? parseInt(d.duration.hours * 60) : 0))
            }
            data.data.sort((a, b) => {return a.epoch > b.epoch ? 1 : -1;})
            data.data.forEach((d) => {
              labels.add(d.start);
            })
            data.data.forEach((d)=>{
              if(d.duration){
                if(datamap[d.dbc]){
                  const idx = Array.from(labels).indexOf(d.start);
                  datamap[d.dbc][idx] = duration(d);
                }
                else{
                  datamap[d.dbc] = Array.apply(null, Array(Array.from(labels).length)).fill(0);
                  const idx = Array.from(labels).indexOf(d.start);
                  datamap[d.dbc][idx] = duration(d);
                } 
              }
            });
            setIngestLabels(Array.from(labels));
            setIngestData(datamap);
          }
        });
    }, [graphStartDate, graphStopDate, ingestType, apiUrl, user]);

  const datasets = () => {
    if(Array.isArray(graphDataPoints)){
      const opt = DATA_OPTIONS.find((o) => o.id === graphType);
      return [
        {label: "Telemetry Stats", 
          data: graphDataPoints, 
          borderColor: opt.color,
          backgroundColor: opt.color,
          tension: 0.2,
          borderWidth: 4
        },
      ]
    }

    const datasets = [];
    for(const k in graphDataPoints){
      datasets.push({
        label: k,
        data: graphDataPoints[k],
      })
    }
    //sort by dbc name and format
    datasets.sort((a,b)=>{return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;});
    datasets.forEach((d, idx) => {
      d.borderColor = interpolateInferno((idx+.5)/datasets.length);
      d.borderWidth = 4;
      d.backgroundColor = interpolateInferno((idx+.5)/datasets.length);
    })
    return datasets;
  }
  
  useEffect(() => {
    const url = `${apiUrl}getVehiclesUnderAnalysis`;
    fetch(url, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error") {
          alert(SERVER_ERRORED_MESSAGE)
          return console.error("Error in _getVehiclesUnderAnalysis");
        }
        let dataArr = [];
        let labels = [];
        let vcls = data.data.reverse();
        vcls.forEach((i) => {
          dataArr.push(i.vehicles);
          let lbl = i.utc_ts.split("T");
          let l = lbl[0].split("-");
          labels.push(l[1] + "/" + l[2]);
        });

        setVehiclesUnderAnalysis(dataArr);
        setVehiclesUnderAnalysisLabels(labels)
      });
  }, [apiUrl, user]);

    const handleDataTypeChange = (val) => {
      setGraphType(val);
    }
    return(
      <S.DevToolsContentContainer>
          <S.DevToolsResponsiveTopContainer>
              <S.DevToolsResponsiveControls>
                  <S.DevToolsInputWrapper>
                      <S.DevToolsInputLabel>Graph Type</S.DevToolsInputLabel>
                      <DropdownSelector 
                        options={DATA_OPTIONS}
                        defaultValue={graphType}
                        handleChange={handleDataTypeChange}
                      />
                  </S.DevToolsInputWrapper>
                  <S.DevToolsInputWrapper>
                      <S.DevToolsInputLabel>Select Dates:</S.DevToolsInputLabel>
                      <S.DevToolsDatepickerContainer>
                          <S.DevToolsDatepickers
                              selected={graphStartDate}
                              onChange={(date) => setGraphStartDate(date)}
                              selectsStart
                              startDate={graphStartDate}
                              endDate={graphStopDate}
                              disabled={["vehicles","process","ingest"].includes(graphType) ? true : false}
                              showMonthDropdown
                              useShortMonthInDropdown
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ]}
                          />
                          <S.DevToolsDatepickers
                              selected={graphStopDate}
                              onChange={(date) => setGraphStopDate(date)}
                              selectsEnd
                              endDate={graphStopDate}
                              disabled={["vehicles","process","ingest"].includes(graphType) ? true : false}
                              showMonthDropdown
                              useShortMonthInDropdown
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ]}
                          />
                      </S.DevToolsDatepickerContainer>
                      {(graphType === "process" || graphType === "ingest") &&
                        <>
                          <p>SHOWING LAST 12 HOURS.</p>
                          <p>DATE SELECTION DISABLED FOR PROCESS AND INGEST STATS.</p>                        
                        </>

                      }
                  </S.DevToolsInputWrapper>
              </S.DevToolsResponsiveControls>
              <S.DevToolsChartContainer>
                  {!graphLabels || !graphDataPoints || graphDataPoints.length < 1 ?
                      <Loading /> : 
                      <ServerGraph 
                        graphData={{
                            labels: graphLabels, 
                            datasets: datasets()}}
                            datasetLabel={datasetLabel}
                        />
                  }
              </S.DevToolsChartContainer>
          </S.DevToolsResponsiveTopContainer>
      </S.DevToolsContentContainer>);
  }


