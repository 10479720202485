import React from 'react';
import OutsideAlerter from './OutsideClickNotifier';
import { withRouter } from 'react-router-dom';
function TimeDeltaSelector(props) {
  const [showModal, setShowModal] = React.useState(false);
  function formatTitle(input) {
    switch (input) {
      case 3600:
        return '1 hr';
      case 21600:
        return '6 hr';
      case 28800:
        return '8 hr';
      case 43200:
        return '12 hr';
      case 86400:
        return '24 hr';
      default:
    }
  }

  function handleClick(input) {
    props.handleTimeSegmentChange(input);
    setShowModal(false);
  }
  return (
    <OutsideAlerter handleClick={() => setShowModal(false)}>
      <div
        className={
          props.location.pathname === '/shifted/dataVisualization'
            ? 'sftd-timeDelta-selector'
            : 'sftd-timeDelta-selector-des'
        }
        id={showModal ? 'clicked' : 'uncliked'}
      >
        <h3
          className="sftd-timeDelta-title"
          onClick={() =>
            props.location.pathname === '/shifted/dataVisualization'
              ? setShowModal(!showModal)
              : null
          }
        >
          {formatTitle(props.segmentTimeDelta)}
        </h3>
        {showModal ? (
          <div className="sftd-timeDeltaSelector-options">
            <div
              onClick={() => handleClick(3600)}
              className="sftd-timeDelta-btn"
              id={props.segmentTimeDelta === 3600 ? 'clicked' : 'unclicked'}
            >
              <p className="timeDelta-ttl">1 Hour Intervals</p>
            </div>
            <div
              onClick={() => handleClick(21600)}
              className="sftd-timeDelta-btn"
              id={props.segmentTimeDelta === 21600 ? 'clicked' : 'unclicked'}
            >
              <p className="timeDelta-ttl">6 Hour Intervals</p>
            </div>
            <div
              onClick={() => handleClick(28800)}
              className="sftd-timeDelta-btn"
              id={props.segmentTimeDelta === 28800 ? 'clicked' : 'unclicked'}
            >
              <p className="timeDelta-ttl">8 Hour Intervals</p>
            </div>
            <div
              onClick={() => handleClick(43200)}
              className="sftd-timeDelta-btn"
              id={props.segmentTimeDelta === 43200 ? 'clicked' : 'unclicked'}
            >
              <p className="timeDelta-ttl">12 Hour Intervals</p>
            </div>
            <div
              onClick={() => handleClick(86400)}
              className="sftd-timeDelta-btn"
              id={props.segmentTimeDelta === 86400 ? 'clicked' : 'unclicked'}
            >
              <p className="timeDelta-ttl">24 Hour Intervals</p>
            </div>
          </div>
        ) : null}
      </div>
    </OutsideAlerter>
  );
}

export default withRouter(TimeDeltaSelector);
