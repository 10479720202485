import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  margin-top: 10px;
`;

const SearchBar = styled.div`
    background-color: white;
    color: grey;
`;

const SearchInput = styled.input`
    width: 245px;
    height: 33px;
    border: 1px solid;
    border-color: rgb(187, 187, 187);
    border-radius: 5px;
    margin: 0;
    padding: 0;
    text-indent: 5px;
    font-size: 16px;
`;


export default function Search(props){
    const[activeSearch, setActiveSearch] = useState(undefined)
    const {allValues, setMatchingValues} = props
    useEffect(()=>{
            let matching = allValues;
            if (activeSearch !== undefined) {
              let searchTerm = activeSearch.toString().toLowerCase();
                const matchingValues = matching.filter((entry) =>
                Object.values(entry).some((val) =>
                  `${val}`.toLowerCase().includes(searchTerm)
              ));
              setMatchingValues(matchingValues)
            } else {
              return setMatchingValues(allValues);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch, allValues]);

    return(
    <SearchContainer>
      <SearchBar>
        <SearchInput value={activeSearch ? activeSearch : ""} onChange={(e) => setActiveSearch(e.target.value)} placeholder = 'Search'/>
      </SearchBar>
    </SearchContainer>
    );

}