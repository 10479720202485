import React, {useEffect, useCallback, useRef} from "react";
import { formatDate, formatTelematicsProvider } from "./dataFormatter";
import {getCustomerInfo, getTelematicsProvider} from './fetchCalls';
export default function InfoCard(props) {
  const {start, stop, requestData} = props;

  const telematicsProvider = useRef("")
  const displayName = useRef("")
  const processTelematicsProvider = (res) => {
    var prov = "-";
    if (res && res.integration) {
      prov = formatTelematicsProvider(res.integration);
    }
    telematicsProvider.current = prov;
  };


  const formatDisplayName = useCallback(
    (res) => {
      if (res.display_name) {
        var name = res.display_name;
      } else {
        name = requestData.db;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        if (!isNaN(parseInt(name.charAt(0)))) name = `_${name}`;
      }
      displayName.current = name;
    },
    [requestData.db]
  );

  useEffect(() =>{
    getTelematicsProvider(requestData, (resp) => processTelematicsProvider(resp));
    getCustomerInfo(requestData, formatDisplayName);
  },[formatDisplayName, requestData])



  return (
    <div className="nrel-dash-user-info-wrpr">
      <p className="nrel-fleet">
        Fleet:{" "}
        <span className="nrel-info-value">{displayName.current}</span>
      </p>
      <div className="nrel-divider" />
      <p className="nrel-telematics">
        Telematics Data:
        <span className="nrel-info-value">
          {" "}
          {formatTelematicsProvider(telematicsProvider.current)}
        </span>
      </p>
      <div className="nrel-divider" />
      <p className="nrel-observation">
        Period of Observation:
        <span className="nrel-info-value">
          {" "}
          {formatDate(start)} -{" "}
          {formatDate(stop)}
        </span>
      </p>
      <div className="nrel-divider" />
      <p className="nrel-analyzed">
        {" "}
        Total Vehicles Analyzed:{" "}
        <span className="nrel-info-value">{props.vclAnalyzedCount}</span>
      </p>
      <div className="nrel-divider" />
    </div>
  );
}
