import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
export default function EzevResultsToggle(props) {
  return (
    <div className="nrel-toggle-wrapper">
      <p className="nrel-toggle-selector-title" id="all">
        ezEV Recommendations
        <span
          className="nrel-hover-icon"
          onMouseEnter={(e) =>
            props.setHoverItems({
              id: "ezEvRecommendations",
              x: e.pageX,
              y: e.pageY,
            })
          }
          onMouseLeave={(e) =>
            props.setHoverItems({ id: null, x: null, y: null })
          }
        >
          {" "}
          ⓘ{" "}
        </span>
      </p>
      <span className="nrel-toggle-container">
        <Toggle
          disabled={props.toggleDisabled}
          defaultChecked={props.showEzevResults}
          onChange={() => props.handleEzevToggleChange(!props.showEzevResults)}
          height={10}
          icons={false}
        />
      </span>
      <p className="nrel-toggle-selector-title" id="ezev">
        All Vehicles
        <span
          className="nrel-hover-icon"
          onMouseEnter={(e) =>
            props.setHoverItems({
              id: "allVehicles",
              x: e.pageX,
              y: e.pageY,
            })
          }
          onMouseLeave={(e) =>
            props.setHoverItems({ id: null, x: null, y: null })
          }
        >
          {" "}
          ⓘ{" "}
        </span>
      </p>
    </div>
  );
}
