import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { MultiSelectDropdown, SingleSelectDropdown } from '@sawatchlabs/swt-components';
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components/macro"


const DropdownContainer = styled.div`
    margin-top: 4px;
    background-color: white;
    width: 250px;
    color: #4a4a4a;
`;

const ControlsWrapper = styled.div`
    border: 1px solid rgb(187, 187, 187);
    border-radius: .25rem;
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: fit-content;
`;

const ControlsLabel = styled.div`
    font-weight: 500;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 2px;
`;

const DatePickerWrapper = styled.div`
    align-items: center;
`;

  const SelectDropdown = styled.select`
  margin-top: 2px;
  color: #4a4a4a;
  border: 1px solid #bbb;
  border-radius: 5px;
  height:38px;
  width:250px;
  font-size: 16px;
  `
  const DatePickerFlexContainer = styled.div`
  display: flex;
  `

  const StyledDatePicker = styled(DatePicker)`
    box-sizing: border-box;
    border: 1px solid #cccccc;
    height: 38px;
  `
  const CheckboxWrapper = styled.div`
    height: 17px;
    display: flex;
    align-items: center;
    font-family: font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue","Arial","sans-serif";
  `

  const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: -5px;

  &:checked:before {
    content: '•';
    color: #356E77;
    font-size: 25px;
    padding-right: 2px;
  }
  &:before {
    content: '•';
    color: transparent;
    font-size: 25px;
    padding-right: 2px;
  }
  `
  const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    color: #4a4a4a;
  `


export default function EmitControls({
  beginDate, endDate, setBeginDate, setEndDate,
  group, groups, handleGroupChange, vehicleClasses, 
  selectedVehicleClasses, updateSelectedClasses,
  calcByFuel, displayInLbs, handleModelChange, 
  handleUnitChange, minDate, maxDate, showEmissionsByFuelType, toggleShowEmissionsByFuelType }) {
  
    let location = useLocation();

    const [selectedGroup, setSelectedGroup] = useState([]);
    const [updatedVehicleClasses, setUpdatedVehicleClasses] = useState([]);

    useEffect(() => {
      let selected = groups.filter((g) => g.id === group);
      setSelectedGroup(selected)
    },[group, groups])

    useEffect(() => {
      setUpdatedVehicleClasses(['All Classes', ...vehicleClasses])
    },[vehicleClasses])

  const _handleModelChange = (change) => {
    if (change === 'Miles Traveled') handleModelChange(false);
    else handleModelChange(true);
  }
  const _handleUnitChange = (change) => {
    if(!handleUnitChange)return;
    if (change === 'Lbs') handleUnitChange(true);
    else handleUnitChange(false);
  }

  const customValueRenderer = (selected, _options) => {
    if (selected[0].value === 'All Classes' && selected.length === 1) {
      return <p>All Classes</p>
    } else if (selected.length === 1) {
      return <p>1 Class</p>
    } else {
      return <p>{selected.length} Classes</p>
    }
};

const formatSelectValues = (selectedClasses) => {

  let classArr = [];
  if (selectedClasses.length === vehicleClasses.length || selectedClasses[selectedClasses.length - 1] === 'All Classes') {
    classArr.push({value: 'All Classes', label: 'All Classes'})
  } else {
    selectedClasses.filter((word) => word !== 'All Classes').map((item) => {
      return classArr.push({label: item, value: item})
    })
  }
  return classArr;
}
const handleClassChange = (e) => {
  if (e.length === 0) {
    return;
  } else if (e[e.length -1].value === 'All Classes') {
    return updateSelectedClasses(vehicleClasses)
  } else {
    let filteredArr = e.filter((obj) => obj.value !== 'All Classes')
    return updateSelectedClasses(filteredArr.map((item) => {return item.value}))
  }
}

const customItemRenderer = ({option, checked, onClick}) => {
  return (
    <CheckboxWrapper>
      <StyledCheckbox type='checkbox' checked={checked} onChange={onClick}/>
      {option.label}
    </CheckboxWrapper>
  )
}

  return (
    <ControlsWrapper>
      <DatePickerWrapper>
        <ControlsLabel>Date Range:</ControlsLabel>
        <DatePickerFlexContainer>
        <StyledDatePicker
          className="swt-emit-datepicker"
          selected={beginDate}
          onChange={(date) => { setBeginDate(date) }}
          minDate={minDate}
          maxDate={endDate}
          selectsStart
          dateFormat="MM/dd/yy"
          startDate={beginDate}
          showMonthDropdown
          useShortMonthInDropdown
        />
        <span>&nbsp;</span>
        <StyledDatePicker
          className="swt-emit-datepicker"
          selected={endDate}
          onChange={(date) => { setEndDate(date) }}
          selectsEnd
          dateFormat="MM/dd/yy"
          endDate={endDate}
          minDate={beginDate}
          maxDate={maxDate}
          showMonthDropdown
          useShortMonthInDropdown
        />
        </DatePickerFlexContainer>
      </DatePickerWrapper>
      <DropdownContainer>
        <ControlsLabel>Group:</ControlsLabel>
        <SingleSelectDropdown
          bulletColor={'#356E77'}
          selectedOptionBgColor={'rgba(53, 110, 119, .4)'}
          color={'black'}
          onChange={(e) => handleGroupChange(e.value)}
          value={selectedGroup.map((g) => {
            return ({value: g.id, label: g.name})
            })}
          options={groups.map((g) => {
          return (
            {value: g.id, label: g.name}
          )
        })}
        />
      </DropdownContainer>
      <DropdownContainer>
        <ControlsLabel>Vehicle Classes:</ControlsLabel>
       <MultiSelectDropdown
        valueRenderer={customValueRenderer}
        itemRenderer={customItemRenderer}
        hoverColor={'#fff'}
        color={'black'}
        borderColor={'#cccccc'}
        height={'38px'}
        placeholder={'No Classes'}
        hasSelectAll={false}
        ClearSelectedIcon={null}
        selectedOptionBackground={'rgba(53, 110, 119, .4)'}
        onChange={(e) => {handleClassChange(e)}}
        value={formatSelectValues(selectedVehicleClasses)}
        options={updatedVehicleClasses.map((item) => {
          return {value: item, label: item}
        })}
      /> 
      </DropdownContainer>
      {/* turning the miles/fuel calc selection off til we get the messaging on it better */}
      {false && <DropdownContainer>
        <ControlsLabel>Calculate On: &nbsp;</ControlsLabel>
        <SelectDropdown
          value={calcByFuel ? 'Fuel Consumed' : 'Miles Traveled'}
          onChange={(e) => _handleModelChange(e.target.value)}
        >
          <option value='Fuel Consumed'>Fuel Consumed</option>
          <option value='Miles Traveled'>Miles Traveled</option>
        </SelectDropdown>
      </DropdownContainer>}
      <DropdownContainer>
        <ControlsLabel>Display Units<br />(Carbon Dioxide & GHG):</ControlsLabel>
        <SingleSelectDropdown
          width={'50%'}
          color={'black'}
          bulletColor={'#356E77'}
          selectedOptionBgColor={'rgba(53, 110, 119, .4)'}
          onChange={(e) => _handleUnitChange(e.value)}
          value={displayInLbs ? [{label: 'Lbs', value: 'Lbs'}] : [{label: 'Tons', value: 'Tons'}]}
          options={[
            {label: 'Tons', value: 'Tons'},
            {label: 'Lbs', value: 'Lbs'}
          ]}
        />
      </DropdownContainer>
      {location.pathname === '/emit/home'
      ?
      <InputWrapper>
      <ControlsLabel>Show Emissions by Fuel Type:</ControlsLabel>
      <input type='checkbox' checked={showEmissionsByFuelType} onChange={() => toggleShowEmissionsByFuelType()} />
      </InputWrapper>
      :
      null
    }
    </ControlsWrapper>
  );
}