import React from "react";
import * as S from '../../../styles/core-styles/Dashboard-styles';
import {ReactComponent as UserIcon} from '../../../images/User.svg';

export default function UserMenu(props) {

  const { cobrandingLogoUrl, showUserManagementMenu, demo } = props;
  const showCobrandingLogo = cobrandingLogoUrl ? true : false;

  return (
    <S.HeaderUserMenuWrapper
      data-testid="user-menu-testid"
      showCobrandingLogo={showCobrandingLogo}
    >
      <S.HeaderUserMenu
        showCobrandingLogo={showCobrandingLogo}
        onClick={showUserManagementMenu}
      >
        {showCobrandingLogo && 
          <S.HeaderCobrandingLogo
            data-testid={"cobranding-logo-testid"}
            src={cobrandingLogoUrl}
            demo={demo}
          />
        }
        <S.HeaderUserIcon showCobrandingLogo={showCobrandingLogo}>
          <UserIcon/>
        </S.HeaderUserIcon>
      </S.HeaderUserMenu>
    </S.HeaderUserMenuWrapper>
  );
}
