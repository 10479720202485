import React, {useState, useEffect} from "react";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { conformPostRequest, processSettingsConstraints } from "../../utility-components/ConformUnits";
import { formatDecimal, roundObjValues, preventNonIntegers, preventPasteNonIntegers } from "../UtilityFunctions";
import { Roles } from "../../utility-components/UserUtils";
import UserMenu from "../swt-header-user-menu";
import DemoCobrandingLogoUrl from '../../../../images/DemoCobrandingLogo.png';
import ImageUploading from 'react-images-uploading';
import * as ColorScheme from '../../../../styles/core-styles/ColorScheme';

let SETTINGS_STABLE = {}
let SETTINGS_CONSTRAINTS = {}
const MAX_LOGO_IMAGE_SIZE = 2000000;

export default function AdminSettings(props){
  const [errors, setErrors] = useState([]);
  const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });
  const [displayInfoPopup, setDisplayInfoPopup] = useState(false)
  const [infoText, setInfoText] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [SelectedCobrandingLogoUrl, setSelectedCobrandingLogoUrl] = useState(DemoCobrandingLogoUrl); // initial cobranding logo URL references the demo logo
  const [SelectedCobrandingLogoFile, setSelectedCobrandingLogoFile] = useState(null); // initial cobranding image _file_ is null.
  const { user, posthog, db, hasDbCobrandingUrl } = props;
  const userSettings = props.user.userSettings;
  const dbSettings = props.dbSettings;
  const updateDbSettings = props.updateDbSettings;
  const changeDbCobrandingLogo = props.changeDbCobrandingLogo;
  const baselineType = true;
  var fromProduct = false;
  if(props.location && props.location.state && props.location.state.redirectFrom)fromProduct = props.location.state.redirectFrom;
  const [settings, setSettings] = useState({
      db: props.dbSettings.db,
      currency: props.dbSettings.currency === null ? "" : props.dbSettings.currency,
      fuelCost: props.dbSettings.fuel_cost,
      localKwhCost: props.dbSettings.local_kwh_cost,
      ghgKwhGm: props.dbSettings.ghg_kwh_gm,
      scc: props.dbSettings.scc,
      lifeCycle: props.dbSettings.life_cycle,
      yearlyInsurance: props.dbSettings.yearly_insurance,
      iceRepairPer15k: props.dbSettings.ice_repair_per15k,
      evRepairPer15k: props.dbSettings.ev_repair_per15k,
      phevRepairPer15k: props.dbSettings.phev_repair_per15k,
      overallThreshold: props.dbSettings.overall_threshold,
      energyThreshold: props.dbSettings.energy_threshold,
      economicsThreshold: props.dbSettings.economics_threshold,
      parkingThreshold: props.dbSettings.parking_threshold,
      operationalFit: props.dbSettings.operational_fit,
      dwellHoursFloor: props.dbSettings.dwell_hours_floor,
      dwellHoursCeiling: props.dbSettings.dwell_hours_ceiling,
      maxSocMissedOpp: props.dbSettings.max_soc_missed_opp,
      maxPctMissedOpsTarget: props.dbSettings.max_pct_missed_opps_target,
      evYrKmTarget: props.dbSettings.ev_yr_km_target,
      maxSocMissedOppPhev: props.dbSettings.max_soc_missed_opp_phev,
      chargeToLD: props.dbSettings.charge_to_ld,
      dischargeToLD: props.dbSettings.discharge_to_ld,
      chargeToMDHD: props.dbSettings.charge_to_mdhd,
      dischargeToMDHD: props.dbSettings.discharge_to_mdhd,
      getLocation: props.dbSettings.get_location,
      getDiagnostic: props.dbSettings.get_diagnostic,
      baseline: props.dbSettings.baseline,
      defaultSelected: props.dbSettings.default_selected,
      electrificationPct: props.dbSettings.electrification_pct,
      //Map Center values aren't used in UI but need to be included for updateSettings EP.
      //Eventually we are going to update EP/settings table to properly remove these
      mapCenterLon: props.dbSettings.map_center_lon,
      mapCenterLat: props.dbSettings.map_center_lat,
  })
  //used to calculate the horizontal position of the info bubble popups
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {width,height}
  }

  useEffect(() => {
    try {
      let url = props.apiURL + `getSettingsConstraints?ident=${props.db}`;
      fetch(url, {
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if(data && data['data']){
            data.data = processSettingsConstraints(userSettings, data.data);
            SETTINGS_CONSTRAINTS = data.data;
          } else {
            window.alert("There was an error retrieving settings contraints.\nPlease contact the development team.");
          }
        });
    } catch (err) {
      console.error(err);
    }
  },[props.apiURL, props.db, props.user.token, userSettings])

  function updateSettings(body){
    let url = props.apiURL + `updateSettings`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(body),
      }).then((data) => {
        if(data.status === 200){
          // re-run applicable products
          runAnalytics()
          //Run updateDbSettings to refresh settings stored in state, passes POST body for testing purposes
          updateDbSettings(props.db, body);
        } else {
          window.alert("There was an error updating settings");
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  function runAnalytics(){
    let url = props.apiURL + 'runAnalytics';
    var obj = { company_ident: props.db, baseline: baselineType };
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`
        },
        body: JSON.stringify(obj),
      }).then((resp) => {return resp.json()})
      .then((data) => {
        if(data.status === "error")
          window.alert("There was an error updating product settings and results.");
        else {
          window.alert("Product settings and results updated");
          // redirect back to product directed from
          if(fromProduct)props.history.push(fromProduct);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  function handleValueChange(ste, val) {
    setSettings(prevSettings => ({...prevSettings, [ste]: val}));
  };

  function handleSubmit(event){
    event.preventDefault();
    var valid = validateInputs();
    if (valid) {
      var message =
        "This will update settings across all products.\n Are you sure you wish to continue?";
        if (window.confirm(message)) {
          // if valid and confirmed, tidy and submit
          var updatedSettings = tidyInputs();
          updateSettings(updatedSettings);
          if(posthog) { // If traffic analysis enabled, record settings change event
            posthog.capture('settings_update', {
              email: user.email,
              db_name: db
            })
          }
      };
    };
  };

  const handleImageSelect = (image) => {
    const file = image[0].file;
    const fileURL = URL.createObjectURL(file);
    setSelectedCobrandingLogoUrl(fileURL);
    setSelectedCobrandingLogoFile(file);
  };

  function handleImageSave() {
    const message = "This will update the co-branding logo for this database for all users.\n Are you sure you wish to continue?";
    if (window.confirm(message)) changeDbCobrandingLogo(props.db, SelectedCobrandingLogoFile);
  }

  function handleImageReset() {
    const message = "This will reset the co-branding logo for this database for all users.\n Are you sure you wish to continue?";
    if (window.confirm(message)) {
      changeDbCobrandingLogo(props.db, null);
      setSelectedCobrandingLogoFile(null);
    }
  }  

  function validateInputs(){
    // left out validation for controlled dropdowns
    var errs = [];
    var fuel = settings.fuelCost;
    var elec = settings.localKwhCost;
    var ghg = settings.ghgKwhGm;
    var scc = settings.scc;
    var lc = settings.lifeCycle;
    var yearlyInsurance = settings.yearlyInsurance;
    var iceRepair = settings.iceRepairPer15k;
    var evRepair = settings.evRepairPer15k;
    var phevRepair = settings.phevRepairPer15k;
    var overall = settings.overallThreshold;
    var energy = settings.energyThreshold;
    var econ = settings.economicsThreshold;
    var parking = settings.parkingThreshold;
    let dwellHoursFloor = settings.dwellHoursFloor;
    let dwellHoursCeiling = settings.dwellHoursCeiling;
    var maxSocEv = settings.maxSocMissedOpp;
    var maxSocPhev = settings.maxSocMissedOppPhev;
    var evYrKmTarget = settings.evYrKmTarget;
    var maxPctMissedOpsTarget = settings.maxPctMissedOpsTarget;
    var chargeToLD = settings.chargeToLD;
    var dischargeToLD = settings.dischargeToLD;
    var chargeToMDHD = settings.chargeToMDHD;
    var dischargeToMDHD = settings.dischargeToMDHD;
    var electrificationPct = settings.electrificationPct;

    var constr = SETTINGS_CONSTRAINTS;
    // NOTE want to change error msgs? Accessor: enter a value between x and y.
    // isNaN returns false for blank string, needs it's own check
    if(isNaN(fuel) || fuel === "" || fuel < constr.fuelCost.MIN || fuel > constr.fuelCost.MAX)errs.push(`Please enter a fuel cost between ${props.dbSettings.currency_symbol}${constr.fuelCost.MIN}-${constr.fuelCost.MAX} per ${userSettings.liquid_volume_labels.longSingular}`);
    if(isNaN(elec) || elec === "" || elec < constr.localKwhCost.MIN || elec > constr.localKwhCost.MAX)errs.push(`Please enter a local kWh price between ${props.dbSettings.currency_symbol}${constr.localKwhCost.MIN}-${constr.localKwhCost.MAX}`);
    if(isNaN(ghg) || ghg === "" || ghg < constr.ghgKwhGm.MIN || ghg > constr.ghgKwhGm.MAX)errs.push(`Please enter a electricity generation GHG value between ${constr.ghgKwhGm.MIN}-${constr.ghgKwhGm.MAX} grams/kWh`);
    if(isNaN(scc) || scc === "" || scc < constr.scc.MIN || scc > constr.scc.MAX)errs.push(`Please enter a social cost of carbon between ${props.dbSettings.currency_symbol}${constr.scc.MIN}-${constr.scc.MAX} per ${userSettings.ton_labels.longSingular}`);
    if(isNaN(lc) || lc === "" || lc < constr.lifeCycle.MIN || lc > constr.lifeCycle.MAX)errs.push(`Please enter a whole number vehicle lifecycle between ${constr.lifeCycle.MIN}-${constr.lifeCycle.MAX} years`);
    if(isNaN(yearlyInsurance) || yearlyInsurance === "" || yearlyInsurance < constr.yearlyInsurance.MIN || yearlyInsurance > constr.yearlyInsurance.MAX)errs.push(`Please enter a yearly vehicle insurance cost between ${props.dbSettings.currency_symbol}${constr.yearlyInsurance.MIN}-${constr.yearlyInsurance.MAX}`);
    if(isNaN(iceRepair) || iceRepair === "" || iceRepair < constr.iceRepairPer15k.MIN || iceRepair > constr.iceRepairPer15k.MAX)errs.push(`Please enter an ICE maintenance cost between ${props.dbSettings.currency_symbol}${constr.iceRepairPer15k.MIN}-${constr.iceRepairPer15k.MAX} (per 15,000 ${userSettings.distance_labels.longPlural})`);
    if(isNaN(evRepair) || evRepair === "" || evRepair < constr.bevRepairPer15k.MIN || evRepair > constr.bevRepairPer15k.MAX)errs.push(`Please enter an BEV maintenance cost between ${props.dbSettings.currency_symbol}${constr.bevRepairPer15k.MIN}-${constr.bevRepairPer15k.MAX} (per 15,000 ${userSettings.distance_labels.longPlural})`);
    if(isNaN(phevRepair) || phevRepair === "" || phevRepair < constr.phevRepairPer15k.MIN || phevRepair > constr.phevRepairPer15k.MAX)errs.push(`Please enter an PHEV maintenance cost between ${props.dbSettings.currency_symbol}${constr.phevRepairPer15k.MIN}-${constr.phevRepairPer15k.MAX} (per 15,000 ${userSettings.distance_labels.longPlural})`);
    if(isNaN(overall) || overall === "" || overall < constr.overallThreshold.MIN || overall > constr.overallThreshold.MAX)errs.push(`Please enter an overall score threshold between ${constr.overallThreshold.MIN}-${constr.overallThreshold.MAX}`);
    if(isNaN(energy) || energy === "" || energy < constr.energyThreshold.MIN || energy > constr.energyThreshold.MAX)errs.push(`Please enter an energy score threshold between ${constr.energyThreshold.MIN}-${constr.energyThreshold.MAX}`);
    if(isNaN(econ) || econ === "" || econ < constr.economicsThreshold.MIN || econ > constr.economicsThreshold.MAX)errs.push(`Please enter an economics score threshold between ${constr.economicsThreshold.MIN}-${constr.economicsThreshold.MAX}`);
    if(isNaN(parking) || parking === "" || parking < constr.parkingThreshold.MIN || parking > constr.parkingThreshold.MAX)errs.push(`Please enter a parking score threshold between ${constr.parkingThreshold.MIN}-${constr.parkingThreshold.MAX}`);
    
    if(isNaN(dwellHoursFloor) || dwellHoursFloor === "" || dwellHoursFloor < constr.dwellHoursFloor.MIN || dwellHoursFloor > constr.dwellHoursFloor.MAX)errs.push(`Please enter a dwell hours floor between ${constr.dwellHoursFloor.MIN}-${constr.dwellHoursFloor.MAX}`);
    if(isNaN(dwellHoursCeiling) || dwellHoursCeiling === "" || dwellHoursCeiling < constr.dwellHoursCeiling.MIN || dwellHoursCeiling > constr.dwellHoursCeiling.MAX)errs.push(`Please enter a dwell hours ceiling between ${constr.dwellHoursCeiling.MIN}-${constr.dwellHoursCeiling.MAX}`);
    
    if(isNaN(maxSocEv) || maxSocEv === "" || maxSocEv < constr.maxSocMissedOpp.MIN || maxSocEv > constr.maxSocMissedOpp.MAX)errs.push(`Please enter a maximum missed opportunity state of charge for BEVs between ${constr.maxSocMissedOpp.MIN}-${constr.maxSocMissedOpp.MAX}`);
    if(isNaN(maxSocPhev) || maxSocPhev === "" || maxSocPhev < constr.maxSocMissedOppPhev.MIN || maxSocPhev > constr.maxSocMissedOppPhev.MAX)errs.push(`Please enter a maximum missed opportunity state of charge for PHEVs between ${constr.maxSocMissedOppPhev.MIN}-${constr.maxSocMissedOppPhev.MAX}`);
    if(isNaN(evYrKmTarget) || evYrKmTarget === "" || evYrKmTarget < constr.evYrKmTarget.MIN || evYrKmTarget > constr.evYrKmTarget.MAX)errs.push(`Please enter a maximum EV yearly ${userSettings.distance_labels.longPlural} target between ${constr.evYrKmTarget.MIN}-${constr.evYrKmTarget.MAX}`);
    if(isNaN(maxPctMissedOpsTarget) || maxPctMissedOpsTarget === "" || maxPctMissedOpsTarget < constr.maxPctMissedOppsTarget.MIN || maxPctMissedOpsTarget > constr.maxPctMissedOppsTarget.MAX)errs.push(`Please enter a maximum percent of missed opportunities target between ${constr.maxPctMissedOppsTarget.MIN}-${constr.maxPctMissedOppsTarget.MAX}`);
    if(isNaN(chargeToLD) || chargeToLD === "" || chargeToLD < constr.chargeToLD.MIN || chargeToLD > constr.chargeToLD.MAX)errs.push(`Please enter a charge to % for light duty vehicles between ${constr.chargeToLD.MIN}-${constr.chargeToLD.MAX}`);
    if(isNaN(dischargeToLD) || dischargeToLD === "" || dischargeToLD < constr.dischargeToLD.MIN || dischargeToLD > constr.dischargeToLD.MAX)errs.push(`Please enter a discharge to % for light duty vehicles between ${constr.dischargeToLD.MIN}-${constr.dischargeToLD.MAX}`);
    if(isNaN(chargeToMDHD) || chargeToMDHD === "" || chargeToMDHD < constr.chargeToMDHD.MIN || chargeToMDHD > constr.chargeToMDHD.MAX)errs.push(`Please enter a charge to % for medium/heavy duty vehicles between ${constr.chargeToMDHD.MIN}-${constr.chargeToMDHD.MAX}`);
    if(isNaN(dischargeToMDHD) || dischargeToMDHD === "" || dischargeToMDHD < constr.dischargeToMDHD.MIN || dischargeToMDHD > constr.dischargeToMDHD.MAX)errs.push(`Please enter a discharge to % for medium/heavy duty vehicles between ${constr.dischargeToMDHD.MIN}-${constr.dischargeToMDHD.MAX}`);
    if(isNaN(electrificationPct) || electrificationPct === "" || electrificationPct < constr.electrificationPct.MIN || electrificationPct > constr.electrificationPct.MAX)errs.push(`Please enter an electrification percent between ${constr.electrificationPct.MIN}-${constr.electrificationPct.MAX}`);
    if(electrificationPct % 5 !== 0) errs.push("Please enter an electrification percent in increments of 5");
    
    setErrors(errs)
    if(errs.length > 0){
      return false;
    } else {
      return true;
    }
  }
  function tidyInputs(e){
    // currently gets all settings, updates applicable
    // add any post-user manipulation (escape characters etc)
    let obj = {...SETTINGS_STABLE}
    // explicitly set database (default settings may have been used)
    obj.db = props.db;
    obj.fuel_cost = settings.fuelCost;
    obj.local_kwh_cost = settings.localKwhCost;
    obj.ghg_kwh_gm = settings.ghgKwhGm;
    obj.life_cycle = settings.lifeCycle;
    obj.scc = settings.scc;
    obj.yearly_insurance = settings.yearlyInsurance;
    obj.ice_repair_per15k = settings.iceRepairPer15k;
    obj.ev_repair_per15k = settings.evRepairPer15k;
    obj.phev_repair_per15k = settings.phevRepairPer15k;
    obj.overall_threshold = settings.overallThreshold;
    obj.energy_threshold = settings.energyThreshold;
    obj.economics_threshold = settings.economicsThreshold;
    obj.parking_threshold = settings.parkingThreshold;
    obj.operational_fit = settings.operationalFit;
    obj.dwell_hours_floor = settings.dwellHoursFloor;
    obj.dwell_hours_ceiling = settings.dwellHoursCeiling;
    obj.get_location = settings.getLocation;
    obj.get_diagnostic = settings.getDiagnostic;
    //map center lon & lat values aren't in UI but need to be included here until updateSettings EP has been properly updated.
    obj.map_center_lat = settings.mapCenterLat;
    obj.map_center_lon = settings.mapCenterLon;
    obj.max_soc_missed_opp = settings.maxSocMissedOpp;
    obj.max_soc_missed_opp_phev = settings.maxSocMissedOppPhev;
    obj.ev_yr_km_target = settings.evYrKmTarget;
    obj.max_pct_missed_opps_target = settings.maxPctMissedOpsTarget;
    obj.charge_to_ld = settings.chargeToLD;
    obj.discharge_to_ld = settings.dischargeToLD;
    obj.charge_to_mdhd = settings.chargeToMDHD;
    obj.discharge_to_mdhd = settings.dischargeToMDHD;
    obj.currency = settings.currency;
    obj.baseline = baselineType; // VIP this is what user selected
    obj.email = props.user.email;
    obj.default_selected = settings.defaultSelected;
    obj.electrification_pct = settings.electrificationPct;
    obj = conformPostRequest(userSettings, [obj])[0]
    obj = roundObjValues(obj)
    return obj;
  };

  function _getInputData(group){
    // we should consolidate - type or formType, then remove options for booleans
    // decision: add input tag and valueType
    // currently all number 'type'
      if(group === 'general') {
        return (
        [
        { key: "currency", label: "Currency", type: "text", formType: "input", readOnly: true, infoText: 'To change your currency settings, click on your user profile in the top right corner and select "user settings." This change will apply only to your user.' },
        { key: 'fuelCost',label: `Fuel Cost (${dbSettings.currency_symbol}/${userSettings.liquid_volume_labels.shortSingular})`, type: "number", formType: "input" },
        { key: 'localKwhCost', label: `Electricity Rate (${dbSettings.currency_symbol}/kWh)`, type: "number", formType: "input", infoText: 'To input variable rates, please see the variable kWh rates tab.' },
        { key: 'ghgKwhGm', label: "Electricity Generation GHG Intensity grams/kWh", type: "number", formType: "input" },
        { key: 'lifeCycle', label: "Vehicle life cycle",type: "number",formType: "input", infoText: 'Input the number of years you expect to keep a vehicle in your fleet. If you have unique vehicle life cycles for different vehicle classes or departments, please contact your Sawatch Account Manager to have the vehicle specific life cycles added.'},
        { key: 'scc', label: `Social Cost of Carbon (${dbSettings.currency_symbol}/${userSettings.ton_labels.longSingular})`,type: "number",formType: "input", infoText: 'If your organization uses a Social Cost of Carbon to incorporate the financial impact of GHG emissions, please input that number here. Otherwise, leave as zero'},
        { key: 'yearlyInsurance', label: `Yearly Insurance Cost per Vehicle (${dbSettings.currency_symbol})`, type: "number", formType: "input"},
        { key: 'iceRepairPer15k', label: `ICE Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, type: "number", formType: "input"},
        { key: 'evRepairPer15k', label: `BEV Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, type: "number", formType: "input"},
        { key: 'phevRepairPer15k', label: `PHEV Maintenance Cost per 15,000 ${userSettings.distance_labels.longPlural} (${dbSettings.currency_symbol})`, type: "number", formType: "input"},
        ]
        )
      } if(group === 'scoreThresholds') {
        return([
        { key: 'overallThreshold', label: "Overall Threshold", type: "number", formType: "input" },
        { key: 'energyThreshold', label: "Energy Threshold", type: "number", formType: "input" },
        { key: 'economicsThreshold', label: "Economics Threshold", type: "number", formType: "input" },
        { key: 'parkingThreshold', label: "Parking Threshold", type: "number", formType: "input" }
      ])
    } if(group === 'ezev/ezio') {
      return ([
        { key: 'dwellHoursFloor', label: "Dwell Hours Floor", type: "number", formType: "input"},
        { key: 'dwellHoursCeiling', label: "Dwell Hours Ceiling", type: "number", formType: "input"},
        { key: 'operationalFit', label: "Operational Fit", type: "boolean", formType: "select", options: ['True', 'False'], readOnly: true},
        { key: 'chargeToLD', label: "Charge To % (Light Duty)", type: "number", formType: "input"},
        { key: 'chargeToMDHD', label: "Charge To % (Medium/Heavy Duty)", type: "number", formType: "input"},
        { key: 'dischargeToLD', label: "Discharge To % (Light Duty)", type: "number", formType: "input"},
        { key: 'dischargeToMDHD', label: "Discharge To % (Medium/Heavy Duty)", type: "number", formType: "input"},
        { key: 'electrificationPct', label: "Default Electrification Percent", type: "number", formType: "input", infoText: 'This applies to ezIO. Please select the percentage of fleet electrification you would like to be the default setting when you open ezIO.'},
      ])
    } if (group === 'ionev') {
      return ([
        { key: 'maxSocMissedOpp', label: "Max SOC for Missed Opportunity in BEVs",type: "number", formType: "input" },
        { key: 'maxSocMissedOppPhev', label: "Max SOC for Missed Opportunity in PHEVs",type: "number", formType: "input" },
        { key: 'evYrKmTarget', label: `EV Yearly Target (${userSettings.distance_labels.longPlural})`,type: "number", formType: "input" },
        { key: 'maxPctMissedOpsTarget', label: "Maximum Percent of Missed Opportunities",type: "number", formType: "input" },
        ])
    } if (group === 'internalSetup') {
      return ([
        { key: 'getLocation', label: "Get Location Data", type: "boolean", formType: "select", options: ['True', 'False'] },
        { key: 'getDiagnostic', label: "Get Diagnostic Data",type: "boolean", formType: "select", options: ['True', 'False'] },
        { key: 'defaultSelected', label: "Vehicles Default Selected",type: "boolean", formType: "select", options: ['True', 'False'] },
      ])
    } 
  };

  function generateForm(group){
    // NOTE select optimized for booleans (toLowerCase, toString)
    // When have other select options build out / differentiate
    let label = null
    const inputObjects = _getInputData(group);
    return inputObjects.map((obj, i) => {
      let value = settings[obj.key]
      label = <S.SettingsInputLabel data-testid={`${obj.key}-label-testid`}>{obj.label}:</S.SettingsInputLabel>
      if(obj.formType === 'input'){
        if(obj.type === 'number') {
          if(value) {
            value = formatDecimal(value.toString(), 4)
          }
      }
      return (
        <S.SettingsInputWrapper id={obj.key} key={i}>
          <div>
            {label}
            {iconImage(obj)}
          </div>
        <S.SettingsInput
          aria-label={'input'}
          data-testid={`${obj.key.toLowerCase()}-testid`}
          type={obj.type}
          value={value}
          readOnly={obj.readOnly}
          step="any"
          onChange={(e) => handleValueChange(obj.key, formatDecimal(e.target.value, 4))}
          onKeyDown={(e) => preventNonIntegers(e)}
          onPaste={(e) => preventPasteNonIntegers(e)}
          onWheel={(e) => e.target.blur()}
          />
        </S.SettingsInputWrapper>
      )
    } if(obj.formType === 'select') {
      return (
        <S.SettingsInputWrapper id={obj.key} key={i}>
          {label}
          {iconImage(obj)}
          <S.SettingsDropdown
            disabled={obj.readOnly}
            data-testid={`${obj.key.toLowerCase()}-testid`}
            type={obj.type}
            value={value}
            onChange={(e) => handleValueChange(obj.key, e.target.value)}
          >
          {obj.options.map((opt) => {
            return(
            <option 
              key={`${opt}-key`}
              data-testid={`${obj.key}-testid-${opt.toLowerCase()}`}
              value={opt.toLowerCase()}>
              {opt}
            </option>
            )
          })}
          </S.SettingsDropdown>
        </S.SettingsInputWrapper>
      )
    } else {
      return null;
    }
    })
  };

  function displayPopup (e, text) {
    setCursorCoords({x: e.pageX, y: e.pageY});
    setInfoText(text);
    setDisplayInfoPopup(true);
  }

  function removePopup () {
    setCursorCoords({x: 0, y: 0});
    setInfoText('');
    setDisplayInfoPopup(false);
  }

  function iconImage(props) {

    if(props.infoText) {
    return (
      <img
        style={{height: '12px', width: '12px'}}
        src="https://images.sawatchlabs.com/info-icon.png"
        alt="Infomation Icon" 
        onMouseOver={(e) => displayPopup(e, props.infoText)} 
        onMouseOut={() => removePopup()} 
      />
    )
    } else {
      return null;
    }
  }
  function calculatePosition(){
    let left = cursorCoords.x;
    let top = cursorCoords.y - 70;
    //318 is the width of the info text container. 
    if ((cursorCoords.x + 318) > windowDimensions.width) {
      let offset = (cursorCoords.x + 318) - windowDimensions.width
      left = cursorCoords.x - offset
    }
    return {leftPosition: left, topPosition: top}
  }

    // var blType = baselineType;
    return (
      <S.SettingsFormContainer>
        { errors.length > 0 &&
          <div className="ezev-settings-form-error-wrapper">
            <ul className="ezev-settings-form-error-list">
              { errors.map((e, i) => {
                return <li key={i} data-testid={`error-list-testid`}>{e}</li>
              })}
            </ul>
          </div>
        }
        <S.SettingsTitle>Fleet Settings</S.SettingsTitle>
        {settings.db === 'default' && 
          <S.FleetSettingsInfoWrapper>
            <span>You are currently using default fleet settings. Please configure fleet settings below.</span>
          </S.FleetSettingsInfoWrapper>
        }
        {displayInfoPopup &&
        <S.FleetSettingsInfoPopup position={calculatePosition()} coords={cursorCoords}>{infoText}</S.FleetSettingsInfoPopup>
        }
        <S.FleetSettingsInfoWrapper>
        Further customize your analysis by entering values in the fields below. Once complete, select "Save" and the analysis will rerun. Changes made to these settings will apply for all users.
        </S.FleetSettingsInfoWrapper>
        {/*aria-label prop required by testing-library/react*/}
        <form aria-label="form" onSubmit={handleSubmit}>
        <S.ModalFormInputGroupLabel>General</S.ModalFormInputGroupLabel>
        <S.SettingsFormWrapper>
          {generateForm('general')}
        </S.SettingsFormWrapper>

        {user.role >= Roles.SuperAdmin &&
        <>
        <S.ModalFormInputGroupLabel>Score Threshold</S.ModalFormInputGroupLabel>
        <S.SettingsFormWrapper>
          {generateForm('scoreThresholds')}
        </S.SettingsFormWrapper>
        </>
        }

        <S.ModalFormInputGroupLabel>ionEV</S.ModalFormInputGroupLabel>
        <S.SettingsFormWrapper>
          {generateForm('ionev')}
        </S.SettingsFormWrapper>

        <S.ModalFormInputGroupLabel>ezEV/ezIO</S.ModalFormInputGroupLabel>
        <S.SettingsFormWrapper>
          {generateForm('ezev/ezio')}
        </S.SettingsFormWrapper>

        {user.role >= Roles.PartnerAdmin &&
        <>
        <S.ModalFormInputGroupLabel>Internal Setup</S.ModalFormInputGroupLabel>
        <S.SettingsFormWrapper>
          {generateForm('internalSetup')}
        </S.SettingsFormWrapper>
        </>
        }
         
          <div style={{textAlign: 'center'}}>
          <S.CtaButton
            type='submit'
            data-testid="save-settings-button-testid"
          >
            Save
          </S.CtaButton>
          </div>
        </form> 
        {user.role >= Roles.SuperAdmin &&
          <S.CobrandingSectionWrapper data-testid="cobranding-section-testid">
            <S.CobrandingTextSection>
              <S.SettingsTitle>Co-Branding Logo</S.SettingsTitle>
              <S.CobrandingText>
                This logo will be presented in the top right corner, next to the user settings icon.
                <br/>
                <S.Bold>Tips for a successful logo placement:</S.Bold>
                <S.CobrandingTipsList>
                  <li>Logo design: Graphics and words used in the logo should be viewable at any scale. A rectangular logo including the client's name is recommended but not required.</li>
                  <li>Aspect ratio: Logos should ideally be at least 1:1 and at most 5:1.</li>
                  <li>Image type: Images must be either an SVG, PNG, or JPG file. An SVG or PNG with a transparent background is strongly recommended.
                    <S.CobrandingTipsList id="image-type-details">
                        <li>If uploading a JPG, it should have a pure white (#FFF) background for best results.</li>
                        <li>If uploading a PNG or JPG, it should be least 128px high and no more than 2000px wide.</li>
                    </S.CobrandingTipsList>
                  </li>
                <li>File size: Images shouldn't be larger than 2MB.</li>
                </S.CobrandingTipsList>
              </S.CobrandingText>
            </S.CobrandingTextSection>
            <S.CobrandingActionSection>
              <div>
                <S.HeaderDemo>
                  <UserMenu
                    cobrandingLogoUrl={SelectedCobrandingLogoUrl}
                    showUserManagementMenu={()=>{}}
                    demo={true}
                    />
                </S.HeaderDemo>
                <S.CobrandingDemoText>The image within the red box shows how the logo will appear in the header.</S.CobrandingDemoText>
              </div>
              <S.CtaButtonWrapper>
                <ImageUploading
                  maxFileSize={MAX_LOGO_IMAGE_SIZE}
                  acceptType={['jpg', 'jpeg', 'png', 'svg']}
                  onChange={function(il){ handleImageSelect(il) }}>
                  {({
                    onImageUpload,
                    isDragging,
                    dragProps,
                    errors
                  }) => (   
                    <S.CobrandingImageUploadButton
                      onClick={onImageUpload}
                      {...(isDragging && {color: ColorScheme.arrow_green})}
                      {...(errors && {color: ColorScheme.arrow_red})}
                      {...dragProps}
                    >
                      <>
                        {errors ?
                          <>
                            {errors.maxFileSize && <span>File Too Large! Click here to try again.</span>}
                            {errors.acceptType && <span>Wrong File Type! Click here to try again.</span>}
                          </>
                        :
                          <span>Click or Drag Here</span>
                        }
                      </>
                    </S.CobrandingImageUploadButton>
                  )}          
                </ImageUploading>
                <S.CtaButton
                  type='button'
                  onClick={handleImageSave}
                  disabled = {!SelectedCobrandingLogoFile}
                >
                  Save Image
                </S.CtaButton>
                <S.CtaButton
                  type='button'
                  onClick={handleImageReset}
                  disabled={(!hasDbCobrandingUrl)}
                >
                  Clear Logo
                </S.CtaButton>
              </S.CtaButtonWrapper>
            </S.CobrandingActionSection>
          </S.CobrandingSectionWrapper>
        }
      </S.SettingsFormContainer>
    );
  }