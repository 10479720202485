import React from "react";
import SteDashboard from "./nrel-dashboard-components/steDashboard";
import UniDashboard from "./nrel-dashboard-components/uniDashboard";

function NRELDashboard(props) {
  const requestData = {
    token: props.user.token,
    dbName: props.dbName,
    apiURL: props.apiURL,
  };

  const selectDashboard = () => {
    const {dashType} = props;
    if (dashType === "ste") {
      return (
        <SteDashboard
          requestData={requestData}
          user={props.user}
          dashType={dashType}
          dbName={props.dbName}
          account={props.account}
          devState={props.devState}
          geotabIntegrated={props.geotabIntegrated}
        />
      );
    }
    if (dashType === "uni") {
      return (
        <UniDashboard
          requestData={requestData}
          user={props.user}
        />
      );
    } else {
      return (
        <div>
          {" "}
          <h1>Error: Invalid 'dashtype' flag presented</h1>
          {console.error("Invalid dash flag provided:", props.dashType)}
        </div>
      );
    }
  };
  let appWrapper = "nrel-dashboard-app";
  if (props.geotabIntegrated) appWrapper = "ezev-app-geotab";
  return <div className={appWrapper}>{selectDashboard(props.dashType)}</div>;
}

export default NRELDashboard;
