import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderSingleVcl from './HeaderSingleVcl';
import { processApiResponse } from './utils/ConformUnits';
import { calcEffectiveBattery } from './HelperFunctions';
import Loading from './loading';

//code-split imports
const SingleVclPage = React.lazy(() => import("./SingleVclPage"));

class SingleVclController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vclResults: null,
      candidateResults: null,
      parkingData: null,
      vclSummaryData: null,
    }
  }

  componentDidMount() {
    this.props.getAnalyticsProcessingState(false);
    this.getData();
  }

  componentDidUpdate(prevProps) {
    // Check if activity has updated and is now available
    if (this.props.activity && prevProps.activity !== this.props.activity) {
      this.getData();  // Trigger data fetching again if activity has loaded
    }
  }


  getData() {
    let vinFromPath = this.props.match.params.vin;
  
    // Check if location.state exists; if not, use the VIN from the pathname
    if (!this.props.location.state && vinFromPath) {
      this.props.location.state = { vcl: vinFromPath };  // Set location.state manually
    }
  
    if (!this.props.location.state || !this.props.activity) return;  // If still no state, return
  
    if(this.setVclData()) {
      this.getCandidateData();
      this.getParkingData();
    }
  }

  setVclData() {
    // TODO: Update this to be in swt-ezev.js. Should just select correct vehicle and pass to SingleVclController.js
    let vin = this.props.location.state.vcl;
    let vcl = this.props.activity.find(v => v.vin === vin);

    if (vcl) {
      this.setState({ vclResults: vcl });
      // Fetch the vehicle's daily activity for the graph
      this.fetchVclDailyActivity(vcl);
      return true;
    } else {
      console.error("Vehicle not found. Redirecting to landing page...")
      this.props.history.push('/');
      return false;
    }
  }

  getCandidateData() {
    var vcl = this.props.location.state.vcl;
    var url = `${this.props.apiURL}getezevcandidateresults`;
    var queries = `?ident=${this.props.dbName}&vin=${vcl}`;
    fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${this.props.user.token}` } })
      .then(res => res.json())
      .then(data => {
        if (data['data'] !== undefined && data['data'][0] !== undefined && data['data'][0]['json'] !== undefined) {
          if (data['data'][0]['json'].bevs !== undefined) {
            data['data'][0]['json'].bevs.map((d) => {
              let cand = this.props.candidates.find((c) => {
                return c.id === d.id;
              })
              if (cand !== undefined && cand.upfits !== undefined) {
                d.upfits = cand.upfits
              }
              d = processApiResponse(this.props.user.userSettings, d);
              return null;
            })
          }
          if (data['data'][0]['json'].phevs !== undefined) {
            data['data'][0]['json'].phevs.map((d) => {
              let cand = this.props.candidates.find((c) => {
                return c.id === d.id;
              })
              if (cand !== undefined && cand.upfits !== undefined) {
                d.upfits = cand.upfits
              }
              d = processApiResponse(this.props.user.userSettings, d);
              return null;
            })
          }
        }
        this.setState({ candidateResults: data['data'][0]['json'] });
      })
      .catch(error => console.error("Error: " + error));
  }

  getParkingData() {
    if (!this.props.location.state) return;
    var vin = this.props.location.state.vcl;
    this.setState({ parkingData: this.props.otherParkingData[vin] ? this.props.otherParkingData[vin] : [] });
}


fetchVclDailyActivity(vcl) {
    let url = this.props.apiURL;
    let endDate = new Date(vcl.observed_max_ts.date).toISOString().split(["T"])[0]
    let startDate = new Date(vcl.observed_min_ts.date).toISOString().split(["T"])[0]
    var singleVclUrl = `${url}getEnergyGraphData?`;
    var queries = `clientId=${this.props.settings.db}&vin=${vcl.vin}&start=${startDate}&stop=${endDate}`;
    
    fetch(`${singleVclUrl}${queries}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        data.data.sort((a,b) => {return a.localized_date > b.localized_date ? 1 : -1})
        let monthlyGroupedData = {}
        data.data.map((d) => {
          let month = d.localized_date.split('T')[0].split('-')[1]
          let year = d.localized_date.split('T')[0].split('-')[0].slice(2)
          let monthYear = `${month}/${year}`
          //Removing Z from localized_date to prevent luxon timezone conversion
          d.localized_date = d.localized_date.replace("Z", "");
          //Group daily activity by month/year
          if (monthYear in monthlyGroupedData){
            return monthlyGroupedData[`${monthYear}`].push(d)
          } else {
            return monthlyGroupedData[`${monthYear}`] = [d];
          }
        })
        return this.setState({ vclSummaryData: monthlyGroupedData})
      })
      .catch((error) => console.error("Error: " + error));
  };

  getScoredOnExplanation(rec, scored_on, is_ld) {
    let vehicleCategory = is_ld === true ? 'Light Duty' : 'Medium and Heavy Duty'

    if (rec === "No Change") {
      return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic and operational fit. These scores are based on the EV that was the best fit: ${scored_on}.`
    } else if (rec === "Possible Sedan Fit") {
      return `This vehicle was compared to all available EVs in its class, none of which were a good economic and operational fit. It was then compared against electric sedans and was identified to be a good fit for an electric sedan. These scores are based on the electric sedan model that was the best fit: ${scored_on}.`
    } else if (rec === "Operational Fit") {
      return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic fit. However, at least one vehicle was a good operational fit. These scores are based on the EV that was the best operational fit: ${scored_on}.`
    } else if (rec === 'No Models Fit') {
      return `There are no EV candidates within this vehicle class. These scores are based on the EV that was the best fit: ${scored_on}.`
    } else {
      return ''
    }
  }

  render() {
    let explanation;
    let candResults;
    let component;
    let newIce;

    if (!this.state.vclResults || !this.state.candidateResults || !this.state.parkingData) {
      component = (
        <>
          <HeaderSingleVcl vcl={{asset_id: ''}}/>
          <div className="ezev-loading-wrapper">
            <Loading/>
          </div>
        </>
      );
    }
    else {
      explanation = this.getScoredOnExplanation(this.state.vclResults.recommendation, this.state.vclResults.scored_on, this.state.vclResults.is_ld);

      candResults = [...(this.state.candidateResults.phevs.map((item) => ({ ...item, is_phev: true, is_bev: false }))), ...(this.state.candidateResults.bevs.map((item) => ({ ...item, is_phev: false, is_bev: true })))]
      
      // Map effective battery kwh to cands gathered from temp json
      newIce = this.state.candidateResults.ice_comp;
      candResults.map((c) => {
        let cand = this.props.candidates.find(e => e.id === c.id);
        //legacy dbs don't have an id on the candidates table and use ymm for uniqueness.
        //so we fall back to ymm on falsy values for cand.
        if (!cand) cand = this.props.candidates.find(e => e.ymm === c.ymm);
        // check for the rare case where a candidate was updated but analytics was not yet rerun
        if (!cand) return c.effective_battery_kwh = '-';
        if (cand) c.price = cand.net_price;
        c.usable_kwh = (cand.usable_kwh !== undefined && cand.usable_kwh !== null) ? (Math.round(cand.usable_kwh*10))/10 : '-'
        if (cand.usable_kwh !== undefined && cand.usable_kwh !== null) {
          return c.effective_battery_kwh = calcEffectiveBattery(this.props.settings.charge_to_ld,this.props.settings.discharge_to_ld,this.props.settings.charge_to_mdhd,this.props.settings.discharge_to_mdhd,this.state.vclResults.is_ld,cand.usable_kwh);
        } else {
          return c.effective_battery_kwh = '-';
        }
      });
      component = < SingleVclPage vcl={this.state.vclResults} scoredOnText={explanation} localKwh={this.props.localKwh} parking={this.state.parkingData} cands={candResults} newIce={newIce} settings={this.props.settings} userSettings={this.props.user.userSettings} dbDisplayName={this.props.dbDisplayName} apiURL={this.props.apiURL} user={this.props.user} graphData={this.state.vclSummaryData} products={this.props.products}/>

    }

    return (
      <div>
        <Suspense fallback={<HeaderSingleVcl vcl={{asset_id: ''}}/>}>
          {component}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(SingleVclController);
