import React, { useState, useEffect } from "react";
import ChargeEventController from "./ChargeEventController";
import DashboardAnalytics from "./DashboardAnalytics";
import { Switch, Route, Redirect } from "react-router-dom";
import TripMap from "./TripMap";
import ParkingMap from "./ParkingMap";
import DutyCycleMap from "./DutyCycleMap";
import IdlingMap from "./idling-tool-components/IdlingMap";
import * as S from '../../../styles/core-styles/DevTools-styles';
import "mapbox-gl/dist/mapbox-gl.css";

const initialDateRange = 30;

export default function DevTools(props) {
  const { secrets } = props;
  const currentURL = window.location.pathname;

  let d = new Date();
  d.setDate(d.getDate() - initialDateRange);
  const [beginDate, setBeginDate] = useState(d);
  const [endDate, setEndDate] = useState(new Date());
  const [selectByDateRange, setselectByDateRange] = useState(false)
  const [selectedVIN, setSelectedVIN] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [tripStyle, setTripStyle] = useState("path");
  const [filter, setFilter] = useState("none");

  useEffect(() => {
    const req = { db: props.db, user: props.user, apiURL: props.apiURL }
    _getVehicles(req, function (d) {
      setVehicles(d);
      if (selectedVIN === null) setSelectedVIN(d[0].vin);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.db, props.user, props.apiURL]);

  const apiURL = () => {
    if (secrets.devState !== "production") return secrets.devApi;
    return secrets.productionApi;
  };
  return (
    <S.DevToolsWrapper>
      <h2 className="swt-admin-console-ttl">Developer Tools</h2>
      <S.DevToolsNav>

          <S.DevToolsNavLink
            id={currentURL.includes("/dev-tools/trip-map") ? "selected" : "unselected"}
            to={`/dev-tools/trip-map/null`}>
            Trip Map
          </S.DevToolsNavLink>

          <S.DevToolsNavLink 
            to={`/dev-tools/parking-map`}
            id={currentURL.includes("/dev-tools/parking-map") ? "selected" : "unselected"}>
            Parking Map
          </S.DevToolsNavLink>

          <S.DevToolsNavLink 
            to={`/dev-tools/duty-cycle-map/null`}
            id={currentURL.includes("/dev-tools/duty-cycle-map") ? "selected" : "unselected"}>
            Duty Cycles Map
          </S.DevToolsNavLink>

          <S.DevToolsNavLink
            to="/dev-tools/idling-map"
            id={currentURL.includes("/dev-tools/idling-map") ? "selected" : "unselected"}>
            Idle Events Map
          </S.DevToolsNavLink>

          <S.DevToolsNavLink 
            to="/dev-tools/environment-monitoring"
            id={currentURL.includes("/dev-tools/environment-monitoring") ? "selected" : "unselected"}>
            Environment Monitoring
          </S.DevToolsNavLink>

          <S.DevToolsNavLink 
            to="/dev-tools/charging-tools"
            id={currentURL.includes("/dev-tools/charging-tools") ? "selected" : "unselected"}>
            Charging Tools
          </S.DevToolsNavLink>

      </S.DevToolsNav>
       {currentURL === `${props.basename}/dev-tools` && <Redirect from="/dev-tools" to="/dev-tools/trip-map/null" />}
        <Switch>
          <Route
            key="dutycyclemap"
            path="/dev-tools/duty-cycle-map/:id"
            render={() => (
              <DutyCycleMap
                user={props.user}
                secrets={secrets}
                apiURL={apiURL()}
                db={props.db}
                vehicles={vehicles}
                beginDate={beginDate}
                endDate={endDate}
                selectedVIN={selectedVIN}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
                setSelectedVIN={setSelectedVIN}
                tripStyle={tripStyle}
                setTripStyle={setTripStyle}
                energyFilter={filter}
                setFilter={setFilter}
                selectByDateRange={selectByDateRange}
                toggleSelectByDateRange={() => setselectByDateRange(!selectByDateRange)}
              />
            )}
          />
          <Route
            key="parkingmap"
            path="/dev-tools/parking-map"
            render={() => (
              <ParkingMap
                user={props.user}
                secrets={secrets}
                apiURL={apiURL()}
                db={props.db}
                vehicles={vehicles}
                beginDate={beginDate}
                endDate={endDate}
                selectedVIN={selectedVIN}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
                setSelectedVIN={setSelectedVIN}
              />
            )}
          />
          <Route
            key="tripmap"
            path="/dev-tools/trip-map/:id"
            render={() => (
              <TripMap
                user={props.user}
                secrets={secrets}
                apiURL={apiURL()}
                db={props.db}
                selectedVIN={selectedVIN}
                vehicles={vehicles}
                beginDate={beginDate}
                endDate={endDate}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
                setSelectedVIN={setSelectedVIN}
                tripStyle={tripStyle}
                setTripStyle={setTripStyle}
                energyFilter={filter}
                setFilter={setFilter}
                selectByDateRange={selectByDateRange}
                toggleSelectByDateRange={() => setselectByDateRange(!selectByDateRange)}
              />
            )}
          />
          <Route
            key="tripmap"
            path="/dev-tools/idling-map"
            render={() => (
              <IdlingMap
                user={props.user}
                secrets={secrets}
                apiURL={apiURL()}
                db={props.db}
                selectedVIN={selectedVIN}
                vehicles={vehicles}
                beginDate={beginDate}
                endDate={endDate}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
                setSelectedVIN={setSelectedVIN}
              />
            )}
          />
          <Route
            path="/dev-tools/environment-monitoring"
            exact
            render={() => (
                <DashboardAnalytics
                  user={props.user}
                  secrets={secrets}
                  apiUrl={apiURL()}
                />
            )}
          />
          <Route
            path="/dev-tools/charging-tools"
            exact
            render={() => (
                <ChargeEventController
                  user={props.user}
                  secrets={secrets}
                  apiURL={apiURL()}
                  db={props.db}
                  beginDate={beginDate}
                  endDate={endDate}
                  selectedVIN={selectedVIN}
                  vehicles={vehicles}
                  setBeginDate={setBeginDate}
                  setEndDate={setEndDate}
                  setSelectedVIN={setSelectedVIN}
                />
            )}
          />
        </Switch>
    </S.DevToolsWrapper>
  );
}

function _getVehicles(req, cb) {
  const url = `${req.apiURL}getVehicles?ident=${req.db}`;
  fetch(`${url}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (!data || !data.data)
        return console.error("Error in the _getVehicles request");
      if (cb) cb(data.data);
    });
}

