import React, { useMemo, useCallback } from "react";
import TableView from "./idling-table-view";

export default function IdlingWeeklySummaryTable(props){
    const { events, beginDate, endDate, dbDisplayName } = props;
    
    const tableColumns = [
      {Header: "Asset ID", accessor: "asset_id", sortType: "basic"},
      {Header: "Year", accessor: "year", sortType: "basic"},
      {Header: "Make", accessor: "make", sortType: "basic"},
      {Header: "Model", accessor: "model", sortType: "basic"},
      {Header: "Total", accessor: "total", sortType: "basic"},
    ];

    const vinSet = new Set(events.map((r) => {return r.vin}));
    const vins = [...vinSet];

    const getWeekNumber = function(date){
      let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum); 
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };

    const getDatesFromWeek = function(w, y) {
      const s = new Date(y, 0, 1 + (w - 1) * 7);
      const e = new Date(y, 0, 1 + (w - 1) * 7);
      const dow = s.getDay();
      if (dow <= 4){
        s.setDate(s.getDate() - s.getDay());
        e.setDate(e.getDate() - e.getDate()+7);
      }
      else{
        s.setDate(s.getDate() + 7 - s.getDay());
        e.setDate(s.getDate() + 6);
      }
      return {start: s, end: e};
    }

    const sortByDate = function( a, b ) {
      if ( a.ts < b.ts )return -1;
      if ( a.ts > b.ts )return 1;
      return 0;
    }

    const weeksInRange = useCallback((begin, end) => {
      const weeksInRange = [];
      if(begin === null || end === null)return [];
      let d = new Date(begin.getTime());
      while(d <= end){
        const wn = getWeekNumber(d);
        const year = d.getUTCFullYear();
        const weeks = weeksInRange.map((w)=>{return w.weekId});
        if(weeks.indexOf(`${year}${wn}`) < 0)weeksInRange.push({week:wn, year: year, weekId: `${year}${wn}`});
        d.setDate(d.getDate() + 1);
      }
      return weeksInRange;
    }, []);
    
    const wir = useMemo(() => {
      if(beginDate === null || endDate === null)return [];
      else{
        const w = weeksInRange(beginDate, endDate);
        return w;
      }
    }, [beginDate, endDate, weeksInRange]);

  
    const compileIntoWeeks = function (evts) {
      function getWeek(local_start){
        if(typeof local_start === "undefined")return 0;
        let d = Date.parse(local_start)
        d = new Date(d);
        return getWeekNumber(d);
      }
  
      let refDate = new Date(beginDate);
      let arr = [];
      while(refDate < endDate){
        const woy = `${refDate.getUTCFullYear()}${getWeek(refDate)}`;
        if(arr.indexOf(woy) < 0)arr.push(woy);
        refDate.setDate(refDate.getDate() + 1);
      }
      const we = [];
      arr.forEach((a) => {
        we.push({woy: a, incidents: 0})
      })
  
      arr = evts.sort(sortByDate);
      arr.forEach((a) => {
        const d = new Date(a.local_start);
        const woy = `${d.getUTCFullYear()}${getWeek(a.local_start)}`;
        we.forEach((w) => {if(w.woy === woy)w.incidents++});
      });
      return we;
    }
  
    const weeklyObjects = [];

    vins.forEach((v) => {
      const f = events.filter((e) => v === e.vin);
      const weeklyEvents = compileIntoWeeks(f);
      let total = 0;
      const o = {
        vin: v,
        asset_id: f[0].asset_id,
        year: f[0].year,
        make: f[0].make,
        model: f[0].model,
        vehicle_class: f[0].vehicle_class};
      weeklyEvents.forEach((e) =>{
        o[`${e.woy}`] = e.incidents;
        total += e.incidents;
      });
      o.total = total;
      weeklyObjects.push(o);
    });    
    wir.forEach((w) => {
      const bounds = getDatesFromWeek(w.week, w.year);
      const start = `${bounds.start.getMonth()+1}/${bounds.start.getDate()}`;
      const end = `${bounds.end.getMonth()+1}/${bounds.end.getDate()}`;
      const s = `${start}-${end}`;
      const l = {Header: s, accessor: `${w.weekId}`, sortType: "basic"};
      tableColumns.push(l);
    });

    const colType = (col) => {
      if(col === 'Asset ID' || col === 'Model'){
        return "idling-table-header-long";
      }
      return "idling-table-header";
    }

    const highlight = (cell) =>{
      if(!isNaN(parseInt(cell.column.Header))){
        //if(cell.value > 2)return "idling-table-cell-highlight";
      }
      return "";
    }

    const csvWeeklySummaryFilename = `sawatch-labs-idling-incidents-by-week-${dbDisplayName.replaceAll(" ", "-")}-${(new Date()).toLocaleDateString().replaceAll('/', '-')}`

    return(

      <div>
        <TableView 
          columns={tableColumns}
          data={weeklyObjects}
          filter={props.filter}
          colType={colType}
          highlight={highlight}
          handleSort={props.handleSort}
          sortCol={props.sortCol}
          nolink={true}
          desc={props.desc}
          beginDate={beginDate}
          endDate={endDate}
          group={props.group}
          vehicleClasses={props.vehicleClasses}
          minDuration={props.minDuration}
          csvFilename={csvWeeklySummaryFilename}
          tableType={'Weekly Summary'}
          dbDisplayName={dbDisplayName}
        />
      </div>
    )
}