import React from "react";
import ReactModal from 'react-modal';

const updateStyle = {
  content : {
    top                   : '15%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '-1'
  }
};

export default function UpdateModal(props){
  //this is a hacky test to check if speedn-root exists to have a bit of
  //namespace control in a Geotab intgerated env, ie Geotab can own and protect
  //it's own root el, or other apps can. 
  if(document.getElementById("speedn-root"))ReactModal.setAppElement("#speedn-root");
  if(document.getElementById("swt-root"))ReactModal.setAppElement("#swt-root");
  else if(!document.getElementById("swt-root") && !document.getElementById("speedn-root"))ReactModal.setAppElement("#root");
  return(
    <ReactModal
      isOpen={props.isOpen}
      style={updateStyle}
    >
      <div className="speedn-update-modal"> 
        <p>Incident Updated.</p>
        <button className="speedn-modal-close-button"
          onClick={props.closeModal}
          >
            Close
        </button>
      </div>
    </ReactModal>
  )
}