import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import * as ColorScheme from './PdfColors.js'
import { PdfStatView } from './PdfStatView';

const styles = StyleSheet.create({
  statViewContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 30,
    marginVertical: 15
  },
  subText: {
    fontSize: 9,
    lineHeight: 1.25,
    color: ColorScheme.medium_gray,
  },
  asteriskContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    marginBottom: 10
  }
})

Font.registerHyphenationCallback(word => [word]);

export const PdfStatBoxesSection = ({statBoxValues}) => {
  return (
    <>
      <View style={styles.statViewContainer}>
        {Object.entries(statBoxValues).map(([key, value]) => {
          let captionWithAsterisks = value.caption;
          if (key === "vehiclesProjectedToCharge") captionWithAsterisks += "*";
          if (key === "totalLocationCharging") captionWithAsterisks += "**";
          return (
              <PdfStatView
                  key={key}
                  value={value.value}
                  subvalue={value.subValue}
                  caption={captionWithAsterisks}
                  subcaption={value.subCaption}
              />
          );
        })}
      </View>
      <View style={styles.asteriskContainer}>
        <Text style={styles.subText}>*This is the number of vehicles projected to charge at this location based on the selected filters, compared to the total number of vehicles in the fleet. Vehicles may be included in these figures that currently do not use this location as their primary parking location.</Text>
        <Text style={styles.subText}>**For the vehicles that park at this location, this is the percentage of their charging that will occur at this location. If this is below 100%, some of the vehicles are projected to charge at other locations, too.</Text>
      </View>
    </>
  )
}