import React, { useEffect, useCallback } from 'react';
import SftdMap from './MapWrapper';
export default function MapController(props) {
  const [mapCenter, setMapCenter] = React.useState([39.0, -104.3495]);

  const calcMapCenter = useCallback(data => {
    if (data.length < 1) return;
    var lat = 0;
    var lon = 0;
    var count = data.length;
    data.map(d => {
      // eslint-disable-next-line no-sequences
      return (lat += d.latitude), (lon += d.longitude);
    });
    lat = lat / count;
    lon = lon / count;
    var coordArray = [lat, lon];
    setMapCenter(coordArray);
  }, []);
  const createMapIcons = useCallback(
    data => {
      const parkingArray = [];
      if (data !== undefined)
        data.map(loc => {
          // Filters out all non-selected locations
          if (props.showSelected && !loc.selected) return null;
          //  ---------->
          if (loc.address === 'Combined Fleet') return null;
          if (loc.parking_location === undefined) loc.parking_location = loc.id;
          // CHANGE HERE TO SELECTED ONCE IT IS ADDED TO THE OBJECT!
          var locNum = parseInt(loc.selectedLocCount);
          if (locNum === 0) {
            loc['icon_radius'] = 10;
            loc['icon_color'] = loc.selected === false ? '#C0C0C0' : '#cc4d4d';
          } else if (locNum === 1) {
            loc['icon_radius'] = 10;
            loc['icon_color'] = loc.selected === false ? '#C0C0C0' : '#e3d25a';
          } else if (locNum >= 2 && locNum <= 10) {
            loc['icon_radius'] = 10;
            loc['icon_color'] = loc.selected === false ? '#C0C0C0' : '#7fcd91';
          } else if (locNum >= 11 && locNum <= 20) {
            loc['icon_radius'] = 10;
            loc['icon_color'] = loc.selected === false ? '#C0C0C0' : '#7bb6cd';
          } else if (locNum >= 22) {
            loc['icon_radius'] = 10;
            loc['icon_color'] = loc.selected === false ? '#C0C0C0' : '#3e668c';
          }
          return parkingArray.push(loc);
        });
      return parkingArray;
    },
    [props.showSelected]
  );

  useEffect(() => {
    calcMapCenter(props.locations);
    createMapIcons(props.locations.filter(l => l.selected === true));
  }, [calcMapCenter, createMapIcons, props.locations]);

  var filteredLocations = createMapIcons(props.locations);
  return (
    <div className="sftd-map-controller">
      <SftdMap
        mapCenter={
          props.activeMapCenter.length > 1 ? props.activeMapCenter : mapCenter
        }
        showSelected={props.showSelected}
        handleLocationChange={props.handleLocationChange}
        filteredGroup={filteredLocations}
        hoursAtCapacity={props.hoursAtCapacity}
      />
    </div>
  );
}
