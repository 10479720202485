import React from "react";
export default function NoResultsMessage(props) {
  const { selectedMonth, data, selectedPercent, source } = props;
  if (props.loading & (props.loading === true)) return null;
  if (data && data[0]) {
    if (data[0] === "loading" || data[0] === "all") return;
    let filteredMonths = data.filter((mnth) => mnth.peakKW > 0);
    if (filteredMonths.length === 0) {
      return (
        <div>
          <h3 className="nrel-noVcls-message">
            There are no results for this location based on the parameters
            selected.
          </h3>
        </div>
      );
    }
  }

    if (selectedPercent && !selectedMonth) {
    if (selectedPercent === 100) {
      return <></>
    } else
      return (
        <div className="no-results-message">
          {" "}
          <p>There are no results within the top {selectedPercent}%.</p>
        </div>
      );
  }
  if (data && data.datasets) {
    if (data.datasets.length === 1) {
      if (data.datasets[0].data && data.datasets[0].data[0]) {
        let arr = data.datasets[0].data[0].filter((num) => num > 0);
        if (arr.length === 0) {
          let mnth = selectedMonth.split(",");
          let month = mnth[0].slice(5, -3);
          let year = mnth[0].slice(0, -6);
          return (
            <div>
              {props.showEzevResults === false ? (
                <h3 className="nrel-noVcls-message">
                  {" "}
                  There are no vehicles at this location within the top{" "}
                  {selectedPercent}% for
                  {" " + month + "/" + year}
                </h3>
              ) : (
                <h3 className="nrel-noVcls-message">
                  {" "}
                  There are no vehicles at this location based on the ezEV
                  results for
                  {" " + month + "/" + year}
                </h3>
              )}
            </div>
          );
        }
      }
    }
  }
  if (source && source === "map") {
    return (
      <div className="nrel-NoResults-Wrapper">
        <h3 className="nrel-NoResults-Title">No Results</h3>
        <p className="nrel-NoResults-Text">
          There are no locations within{" "}
          <span className="nrel-bold">{this.props.selectedPercent}%</span> fleet
          electrification. <br />
          <br />
          Please increase the Total Fleet Electrification percentage to view
          results.
        </p>
      </div>
    );
  }

  return null;
}
