import React, { useState, useEffect } from "react";
import "../component-css/install-tracker-stylesheets/installTracker.css";
import GanttChart from "./install-tracker-components/Install-ganttChart";
import InstallTableView from "./install-tracker-components/Install-table-view";
import InstallAddGroupModal from "./install-tracker-components/install-add-group-modal";
import { useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import InstallNavHeader from "./install-tracker-components/install-navHeader";
import {
  getDeviceInstallResultsReq,
  getDisplayName,
  getGroupsReq,
  getGroupDevices,
} from "./install-tracker-components/install-api-requests";

export default function InstallLanding(props) {
  const [group, setGroup] = useState("GroupCompanyId");
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [groupInstalls, setGroupInstalls] = useState([]);
  const [showVehicleList, setShowVehicleList] = useState(false);
  const [vclData, setVclData] = useState([]);
  const [displayName, setDisplayName] = useState([]);

  const { user, apiURL, dbName } = props;
  const reqObject = { user: user, dbName: dbName, apiURL };

  const getClientName = useCallback(() => {
    getDisplayName(reqObject, setDisplayName);
  }, [reqObject]);

  const finalizeGroups = (groups, groupNames) => {
    if (groups === undefined || groups === 0) return;
    let obj = {
      installedVcls: 0,
      noShows: 0,
      pending: 0,
      totalVcls: 0,
      tableGroups: [],
    };
    // var installDelta = 0;
    let start = undefined;
    let end = undefined;
    let companyIndex = undefined;
    groups.forEach((v, i) => {
      if (start === undefined || v.startDate < start) start = v.startDate;
      if (end === undefined || v.lastInstall > end) end = v.lastInstall;
      if (v.groupId === "GroupCompanyId") companyIndex = i;
      // If the target hasn't been set/updated it resorts the the amount installed
      if (v.targetInstalls < v.installedApi) {
        v.targetInstalls = v.installedApi;
      }
      // Assigns group name from Geotab if a user defined is not present.
      if (v.groupNameUser === null || v.groupNameUser === "") {
        const matchingIdx = groupNames.findIndex((g) => g.id === v.groupId);
        v.groupNameUser = groupNames[matchingIdx].name;
      }
    });
    obj["pending"] = obj.totalVcls - obj.installedVcls;
    if (obj.pending < 0 || isNaN(obj.pending)) obj.pending = 0;
    if (companyIndex !== undefined) {
      obj.noShows = groups[companyIndex].noShow;
      obj.installedVcls = groups[companyIndex].installedApi;
    }
    setVclData(groups);

    return obj;
  };

  const getDeviceInstallResults = useCallback(() => {
    getDeviceInstallResultsReq(reqObject, (resp) =>
      getGroupsReq(reqObject, resp, finalizeGroups)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbName, apiURL, user.token]);

  const groupDevices = useCallback(() => {
    getGroupDevices(reqObject, group, setGroupInstalls);
  }, [group, reqObject]);



  useEffect(() => {
    if (showVehicleList === true) {
      groupDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, showVehicleList]);

  useEffect(() => {
    getDeviceInstallResults();
    getClientName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDeviceInstallResults]);

  const groupFilter = (input) => {
    if (group === "GroupCompanyId") return input;
    else {
      return input.filter((g) => g.groupId === group);
    }
  };
  return (
    <div className="install-tracker">
      <InstallNavHeader
        companyName={displayName}
        group={group}
        setGroup={setGroup}
        vclData={vclData}
        setSelectedGroup={setSelectedGroup}
      />
      <Redirect from="/" to="/install-tracker/gantt" />
      <Switch>
        <Route exact path="/install-tracker/gantt">
          <div className="install-ganttWrapper">
            <GanttChart vclData={vclData} group={group} />
          </div>
        </Route>
        <Route exact path="/install-tracker/table">
          <InstallTableView
            showVehicleList={showVehicleList}
            setShowVehicleList={setShowVehicleList}
            vclData={groupFilter(vclData)}
            setVclData={setVclData}
            updateModal={setSelectedGroup}
            group={group}
            groupInstalls={groupInstalls}
            reqObject={reqObject}
          />
        </Route>
      </Switch>
      {selectedGroup !== undefined && (
        <InstallAddGroupModal
          vclData={vclData}
          selectedGroup={selectedGroup}
          getDeviceInstallResults={getDeviceInstallResults}
          handleModalClick={() => setSelectedGroup(undefined)}
          dbName={dbName}
          user={user}
          apiURL={apiURL}
        />
      )}
    </div>
  );
}
