import React, { useState } from "react";
import OnHoverExplanation from "../onHoverExplanation";

export default function ScenarioToolControls(props) {
  const [hoverItems, setHoverItems] = useState({ id: null, x: null, y: null });
  var changes = checkForChanges(props);
  var constraints = props.settingsConstraints;
  // temporary checks: if constraints not loaded yet, wait
  if (Object.keys(constraints).length < 1) return <></>;
  // if settings contraints failed, show error
  if (Object.keys(constraints).length === 1)
    return (
      <div className="nrel-sliders-error">
        An error has occured preventing running of the Scenario Tool. Please
        contact Sawatch to resolve the issue.
      </div>
    );
  return (
    <>
      <OnHoverExplanation
        id={hoverItems.id}
        mouseCoords={[hoverItems.x, hoverItems.y]}
      />
      <div className="nrel-sliders">
        <div className="nrel-slider-wrapper">
          <div className="nrel-slide _step">
            <p className="nrel-slide-explan">
              Move the sliders below and click update results to see how changes
              in inputs impact the EV suitability for your fleet.
            </p>
            <label>
              <span
                className="nrel-hover-icon"
                onMouseEnter={(e) =>
                  setHoverItems({
                    id: "vehiclePricePremium",
                    x: e.pageX,
                    y: e.pageY,
                  })
                }
                onMouseLeave={(e) =>
                  setHoverItems({ id: null, x: null, y: null })
                }
              >
                {" "}
                ⓘ{" "}
              </span>
              Vehicle Price Premium Allowed :
              <span className="nrel-chargingNeeds">{props.wtp}%</span>
            </label>
            <input
              className="nrel-slider"
              type="range"
              min={0}
              max={50}
              list={"steplist-wtp"}
              value={props.wtp}
              step={5}
              onChange={(e) => props.setWilling(parseInt(e.target.value))}
            />
            <datalist id="steplist-wtp">
              <option>{props.defaultSettings.wtp}</option>
            </datalist>
          </div>
          <div className="nrel-slide _step">
            <label>
              Gas Prices (USD):{" "}
              <span className="nrel-chargingNeeds">${props.gas}</span>
            </label>
            <input
              className="nrel-slider"
              type="range"
              min={constraints.fuelCostUsd.MIN}
              max={constraints.fuelCostUsd.MAX}
              list={"steplist-gas"}
              value={parseFloat(props.gas).toFixed(2)}
              step={0.25}
              onChange={(e) => props.setGas(e.target.value)}
            />
            <datalist id="steplist-gas">
              <option>{props.defaultSettings.gas}</option>
            </datalist>
          </div>
          <div className="nrel-slide _step">
            <label>
              Electricity Prices (USD):{" "}
              <span className="nrel-chargingNeeds">${props.ec}</span>
            </label>
            <input
              className="nrel-slider"
              type="range"
              min={constraints.localKwhUsd.MIN}
              max={constraints.localKwhUsd.MAX}
              list={"steplist-elec"}
              value={props.ec}
              step={0.01}
              onChange={(e) => props.setEc(e.target.value)}
            />
            <datalist id="steplist-elec">
              <option>{props.defaultSettings.elec}</option>
            </datalist>
          </div>
          <div className="nrel-slide _step" id="chargingNeeds">
            <label>
              Midday Charging Needs:{" "}
              <span className="nrel-chargingNeeds">
                {props.chargingNeeds.sliderText}
              </span>
            </label>
            <input
              className="nrel-slider"
              id="chargingNeeds"
              type="range"
              list={"steplist-chrg"}
              value={props.chargingNeeds.sliderValue}
              step={1}
              min={0}
              max={5}
              onChange={(e) => props.setCharging(e.target.value)}
            />
            <datalist id="steplist-chrg">
              <option>{props.defaultSettings.charge.sliderValue}</option>
            </datalist>
          </div>
        </div>
        <div className="nrel-sa-button-wrpr">
          {changes && (
            <button
              className="nrel-update-btn changes-to-process"
              onClick={props.updateFilters}
            >
              Update Results
            </button>
          )}{" "}
          {!changes && (
            <button className="nrel-update-btn">Update Results</button>
          )}
          <button className="nrel-reset-btn" onClick={props.resetFilterValues}>
            Reset Sliders
          </button>
        </div>
      </div>
    </>
  );
}

function checkForChanges(props) {
  if (props.saSettingsStable) {
    var settings = props.saSettingsStable;
    if (
      parseFloat(props.wtp) === parseFloat(settings.wtp) &&
      parseFloat(props.gas) === parseFloat(settings.fuel_cost_usd) &&
      parseFloat(props.ec) === parseFloat(settings.local_kwh_usd) &&
      parseFloat(props.chargingNeeds.value) ===
        parseFloat(settings.energy_threshold)
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
