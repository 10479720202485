import React, { useState } from "react";
import SeasonalDropdown from "./swt-admin-seasonalRate-dropdowns";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { preventNonIntegers, preventPasteNonIntegers, formatDecimal } from "../UtilityFunctions";

const ALPHANUMERIC_REGEX = "^[\\w\\-\\(\\) ]*$"
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters '_' , '-' , '()' are also allowed.";

export default function SeasonalRateForm(props) {
  const { selectedRate, dowArray, dateArray, touArray } = props;

  const [showSelectedDaysError, setShowSelectedDaysError] = useState(false);
  const [activeRate, setActiveRate] = useState(props.selectedRate);
  const [dateDisabled, setDateDisabled] = useState(selectedRate.startMonth !== null ? false : true);
  const [touDisabled, setTouDisabled] = useState(selectedRate.startHour !== null ? false : true);
  const [dowDisabled, setDowDisabled] = useState(function () {
    let dowActive = false;
    dowArray.forEach((d) => {
      if (selectedRate[d] !== null) dowActive = true;
    });
    if (dowActive !== true) return true;
    else return false;
  });
  
  const defaultValues = {
    startDayOfMonth: 0,
    startMonth: 0,
    startHour: 0,
    startMinute: 0,
    stopDayOfMonth: 30,
    stopMonth: 11,
    stopHour: 23,
    stopMinute: 59,
  };

  let modalHeader = 'Add kW Utility Rate';
  if (selectedRate?.pkid !== undefined) modalHeader = 'Update kW Utility Rate';

  function selectMetric(metric) {
    let selRate = activeRate;
    if (metric === "dateDisabled") {
      if (dateDisabled === true) {
        dateArray.forEach((d) => {
          if (selRate[d] === null) selRate[d] = defaultValues[d];
        });
      } else {
        dateArray.forEach((d) => {
          selRate[d] = null;
        });
      }
      setDateDisabled(!dateDisabled);
    }
    if (metric === "touDisabled") {
      if (touDisabled === true) {
        touArray.forEach((d) => {
          if (selRate[d] === null) selRate[d] = defaultValues[d];
        });
      } else {
        touArray.forEach((d) => {
          selRate[d] = null;
        });
      }
      setTouDisabled(!touDisabled);
    } 
    if (metric === "dowDisabled") {
      setDowDisabled(!dowDisabled);
      setShowSelectedDaysError(false);
    }
  }

  function updateSelectedRate(key, value, dayOfWeek=false) {
    if(dayOfWeek) {
      setShowSelectedDaysError(false);
    }
    setActiveRate((prevRate) => {
      return { ...prevRate, [key]: value };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    let selectedRateObj = activeRate;
    let selectedDays = [];
    // sets all relevent values to null if field is disabled
    if (dateDisabled) {
      dateArray.forEach((d) => (selectedRateObj[d] = null));
    }
    if (dowDisabled) {
      dowArray.forEach((d) => (selectedRateObj[d] = null));
    }
    if (dowDisabled === false) {
      dowArray.forEach((d) => {
        if (selectedRateObj[d] === true) {
          selectedRateObj[d] = true;
          selectedDays.push(selectedRateObj[d]);
        }
        else {
          selectedRateObj[d] = false;
        }
      });
      if(selectedDays.length === 0) {
        setShowSelectedDaysError(true);
        return;
      }
    }
    if (touDisabled) {
      touArray.forEach((d) => (selectedRateObj[d] = null));
    }
    props.addKwhRate(selectedRateObj);
    props.closeModal();
  }

  return (
    <S.ModalDisplay>
      <S.ModalContainer>
        <S.ModalCloseButton onClick={props.handleModalClose}>Close</S.ModalCloseButton>
        <S.ModalHeader>{modalHeader}</S.ModalHeader>
        {showSelectedDaysError && 
        <div className="ezev-settings-form-error-wrapper">
            <ul className="ezev-settings-form-error-list">
              <p>Error. Please select at least one active day of use, or deselect the Acitve Days of Week checkbox.</p>
            </ul>
        </div>
        }
        <form onSubmit={handleSubmit}>
          <div className="swt-seasonality-required-inputs">
            <div className="swt-required-inputs-wrapper">
              <label className="swt-seasonal-label" id="name">
                *Rate Name
              </label>
              <br />
              <input
                aria-label="seasonal-name-input"
                className="swt-seasonal-name"
                required={true}
                value={activeRate.nickname === null ? "" : activeRate.nickname}
                onChange={(e) => updateSelectedRate("nickname", e.target.value)}
                pattern={ALPHANUMERIC_REGEX}
                title={INPUT_ERROR_MESSAGE}
                placeholder={"(Required) ex. Summer Rate"}
              />
              <label className="swt-seasonal-label" id="rate">
                *Rate ({props.dbSettings.currency_symbol}/kWh)
              </label>
              <br />
              <input
                className="swt-seasonal-rate"
                aria-label="seasonal-rate-input"
                type="number"
                required={true}
                step="any"
                min={0.0001}
                placeholder="Required"
                onKeyDown={function(el){preventNonIntegers(el)}}
                onPaste={function(el){preventPasteNonIntegers(el)}}
                onWheel={(e) => e.target.blur()}
                value={
                  activeRate.kwhRate !== null
                    ? `${activeRate.kwhRate}`
                    : null
                }
                onChange={(e) =>
                  updateSelectedRate("kwhRate", formatDecimal(e.target.value, 4))
                }
              />
            </div>
          </div>
          <div className="swt-seasonality-enable-options-wrapper">
            <div className="swt-seasonality-enable-sections">
              <label>Include:</label>
              <div className="swt-seasonality-toggle-wrapper">
                <label>
                  Active Date Range
                  <input
                    type="checkbox"
                    aria-label="seasonal-dateDisabled-input"
                    checked={dateDisabled === false}
                    onChange={() => selectMetric("dateDisabled")}
                  />
                </label>
                <label>
                  Active Days of Week
                  <input
                    type="checkbox"
                    aria-label="seasonal-dowDisabled-input"
                    checked={dowDisabled === false}
                    onChange={() => selectMetric("dowDisabled")}
                  />
                </label>
                <label>
                  Active Times
                  <input
                    type="checkbox"
                    aria-label="seasonal-touDisabled-input"
                    checked={touDisabled === false}
                    onChange={() => selectMetric("touDisabled")}
                  />
                </label>
              </div>
            </div>
            <div
              className="swt-seasonality-date-wrapper"
              id={dateDisabled ? "disabled" : "enabled"}
            >
              <p className="swt-seasonality-title">Active Date Range</p>
              <div className="swt-seasonality-date">
                <SeasonalDropdown
                  target={"startMonth"}
                  value={dateDisabled ? null : activeRate.startMonth}
                  disabled={dateDisabled}
                  handleChange={(e) =>
                    updateSelectedRate("startMonth", parseInt(e.target.value))
                  }
                />
                <SeasonalDropdown
                  target={"startDayOfMonth"}
                  value={dateDisabled ? null : activeRate.startDayOfMonth}
                  disabled={dateDisabled}
                  handleChange={(e) =>
                    updateSelectedRate("startDayOfMonth", e.target.value)
                  }
                  selectedMonth={activeRate.startMonth}
                />
              </div>
              <p className="swt-seasonality-text"> TO </p>
              <div className="swt-seasonality-date">
                <SeasonalDropdown
                  target={"stopMonth"}
                  disabled={dateDisabled}
                  value={dateDisabled ? null : activeRate.stopMonth}
                  handleChange={(e) =>
                    updateSelectedRate("stopMonth", parseInt(e.target.value))
                  }
                />
                <SeasonalDropdown
                  target={"stopDayOfMonth"}
                  disabled={dateDisabled}
                  value={dateDisabled ? null : activeRate.stopDayOfMonth}
                  handleChange={(e) =>
                    updateSelectedRate("stopDayOfMonth", e.target.value)
                  }
                  selectedMonth={activeRate.stopMonth}
                />
              </div>
            </div>
            <div
              className="swt-seasonal-dow-wrpr"
              id={dowDisabled ? "disabled" : "enabled"}
            >
              <label>Active Days of Use</label>
              <div className="swt-seasonality-dow-options">
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Sun</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    className="swt-seasonal-dow-input"
                    aria-label="seasonal-dow-sun-test"
                    type="checkbox"
                    defaultChecked={activeRate.sundays}
                    onChange={() =>
                      updateSelectedRate("sundays", !activeRate.sundays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Mon</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    className="swt-seasonal-dow-input"
                    aria-label="seasonal-dow-mon-test"
                    type="checkbox"
                    defaultChecked={activeRate.mondays}
                    onChange={() =>
                      updateSelectedRate("mondays", !activeRate.mondays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Tue</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    aria-label="seasonal-dow-tue-test"
                    className="swt-seasonal-dow-input"
                    type="checkbox"
                    defaultChecked={activeRate.tuesdays}
                    onChange={() =>
                      updateSelectedRate("tuesdays", !activeRate.tuesdays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Wed</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    className="swt-seasonal-dow-input"
                    aria-label="seasonal-dow-wed-test"
                    type="checkbox"
                    defaultChecked={activeRate.wednesdays}
                    onChange={() =>
                      updateSelectedRate("wednesdays", !activeRate.wednesdays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Thu</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    className="swt-seasonal-dow-input"
                    aria-label="seasonal-dow-thur-test"
                    type="checkbox"
                    defaultChecked={activeRate.thursdays}
                    onChange={() =>
                      updateSelectedRate("thursdays", !activeRate.thursdays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Fri</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    aria-label="seasonal-dow-fri-test"
                    className="swt-seasonal-dow-input"
                    type="checkbox"
                    defaultChecked={activeRate.fridays}
                    onChange={() =>
                      updateSelectedRate("fridays", !activeRate.fridays, true)
                    }
                  />
                </div>
                <div className="swt-seasonal-dow">
                  <label className="swt-seasonal-dow-label">Sat</label>
                  <br />
                  <input
                    disabled={dowDisabled}
                    aria-label="seasonal-dow-sat-test"
                    className="swt-seasonal-dow-input"
                    type="checkbox"
                    defaultChecked={activeRate.saturdays}
                    onChange={() =>
                      updateSelectedRate("saturdays", !activeRate.saturdays, true)
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="swt-seasonality-time-wrapper"
              id={touDisabled ? "disabled" : "enabled"}
            >
              <p className="swt-seasonality-title">Active Time</p>

              <div className="swt-seasonality-time">
                <SeasonalDropdown
                  target={"startHour"}
                  disabled={touDisabled}
                  value={touDisabled ? null : activeRate.startHour}
                  handleChange={(e) =>
                    updateSelectedRate("startHour", e.target.value)
                  }
                />
                :
                <SeasonalDropdown
                  target={"startMinute"}
                  disabled={touDisabled}
                  value={touDisabled ? null : activeRate.startMinute}
                  handleChange={(e) =>
                    updateSelectedRate("startMinute", e.target.value)
                  }
                />
              </div>
              <p className="swt-seasonality-text"> TO </p>
              <div className="swt-seasonality-time">
                <SeasonalDropdown
                  target={"stopHour"}
                  disabled={touDisabled}
                  value={touDisabled ? null : activeRate.stopHour}
                  handleChange={(e) =>
                    updateSelectedRate("stopHour", e.target.value)
                  }
                />
                :
                <SeasonalDropdown
                  target={"stopMinute"}
                  disabled={touDisabled}
                  value={touDisabled ? null : activeRate.stopMinute}
                  handleChange={(e) =>
                    updateSelectedRate("stopMinute", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <S.ModalFormButtonWrapper>
            <S.ModalSaveButton type="submit" disabled={activeRate.nickname === null || activeRate.rate === null ? true: false}>Save</S.ModalSaveButton>
          </S.ModalFormButtonWrapper>
        </form>
      </S.ModalContainer>
    </S.ModalDisplay>
  );
}
