import React, { useState, useEffect, useMemo, useCallback, useRef, Suspense } from "react";
import AdminTableView from "./AdminTableView";
import VehicleClassDropdown from "../../dev-tool-components/VehicleClassDropdown.jsx";
import CandidateCreationModal from "./CandidateCreationModal";
import CandidateImportModal from "./CandidateImportModal";
import Loading from "../swt-loading";
import Search from "./search";
import * as S from "../../../../styles/core-styles/AdminTools-styles"
import UpfitsDropdown from "../../dev-tool-components/UpfitsDropdown";
import { conformPostRequest, processApiResponse } from "../../utility-components/ConformUnits";
import { preventNonIntegers, preventPasteNonIntegers, roundObjValues, formatDecimal, convertISOToExcelSerial, formatDateTimeUTC, determineVehicleClassValue } from "../UtilityFunctions";
import ImageUploading from 'react-images-uploading';
import { columnsToExcelHeaders, candidatesTableColumns, rowsToData } from "./TableHelpers";
import { Roles } from "../../utility-components/UserUtils";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));



const MAX_CAND_IMAGE_SIZE = 700000; //max size in bytes allowed by image uploader
const MAX_SECONDS_TO_WAIT_ON_UPLOAD =  5;



const RUN_ANALYTICS_BUTTON_TOOL_TIP = "This will update analytic results with any new or changed candidate attributes."
const SERVER_ERRORED_MESSAGE = "There was a server error during your request."
const ANALYTICS_IN_PROCESS_MESSAGE = "Analytics is already reprocessing data.";

export default function CandidateManagementTable(props){

    const [candidates, setCandidates] = useState([]);
    const [matchingCandidates, setMatchingCandidates] = React.useState(null);
    const [immutableCandidates, setImmutableCandidates] = useState(null)
    const [vehicleClasses, setVehicleClasses] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [infoShown, setInfoShown] = useState(false);
    const [upfits, setUpfits] = useState([]);
    const [showRunAnalyticsHover, setShowRunAnalyticsHover] = useState(false);
    const [imageUploadCounter, setImageUploadCounter] = useState(0);
    const [incompleteVehicles, setIncompleteVehicles] = useState([])
    const [cdrCandidates, setCdrCandidates] = useState([]);
    const { apiURL, db, user, dbDisplayName } = props;
    const skipPageResetRef = useRef(false)
    const userSettings = props.user.userSettings;
    const dbSettings = props.dbSettings;

    const tableColumns = candidatesTableColumns(dbSettings,userSettings);

    useEffect(() => {
        if(!reload)return;
        const url = `${apiURL}getCandidates?ident=${db}`;
        fetch(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
            .then((resp) => {
                if (resp.status !== 200)
                window.alert(
                    "There was an error with your request. Please try again"
                );
                return resp.json();
            })
            .then((data) => {
                data.data.forEach((c) => {
                  const preservedTimestamp = c.last_updated && convertISOToExcelSerial(c.last_updated+"Z",false);
                  c.upfits = c.upfits === null ? [] : c.upfits
                  c = processApiResponse(userSettings, c, false);
                  c = roundObjValues(c);
                  c.last_updated_sortable_timestamp = preservedTimestamp;
                })
                setImmutableCandidates(JSON.parse(JSON.stringify(data.data)))
                setCandidates(data.data);
                setReload(false);
            })
            .catch((error) => console.error("Error: " + error));
    }, [apiURL, db, user.token, reload, userSettings]);

    useEffect(() => {
      const url = `${apiURL}getCDRCandidates`;
      fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
          .then((resp) => {
              return resp.json();
          })
          .then((data) => {
              if (data.status === "success") {
                data.data.map((d) => {
                  d.upfits = d.upfits === null ? [] : d.upfits
                  d = processApiResponse(userSettings, d, false)
                  d = roundObjValues(d)
                  return d
                })
                setCdrCandidates(data.data);
              }
              else {
                alert(SERVER_ERRORED_MESSAGE);
              }
          })
          .catch((error) => console.error("Error: " + error));
  }, [apiURL, db, user.token, reload, userSettings]);

    useEffect(() => {
      const url = `${apiURL}getVehicleClasses?dbName=${db}`;
      fetch(url, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          if (data.status === "success") {
            setVehicleClasses([...new Set(
              (data.data ?? []).map((item) => {let n = item.vehicle_class.replace("EV", "");n = item.vehicle_class.replace("ICE", "");return n.trim()})
            )]);
          }
          else {
            alert(SERVER_ERRORED_MESSAGE);
          }
        });
    }, [apiURL, db, user.token]);

    useEffect(() => {
      if(!reload)return;
      const url = `${apiURL}getUpfits?ident=${db}`;
      fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
          .then((resp) => {
              return resp.json();
          })
          .then((data) => {
              if (data.status === "success") {
                setUpfits(data.data);
                setReload(false);
              }
              else {
                alert(SERVER_ERRORED_MESSAGE);
              }
          })
          .catch((error) => console.error("Error: " + error));
      }, [apiURL, db, user.token, reload]);

      function updateCandidateImage(candidateId, image) {
        setImageUploadCounter(imageUploadCounter+1)
        let formData = new FormData();
        formData.append("id", candidateId);
        formData.append("image", image[0].file);
        formData.append("dbName", db);
        formData.append("editingUserEmail",user.email);
        fetch(`${apiURL}updateDbCandidateImage`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
            body: formData
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE);
          else
            setImageUploadCounter(imageUploadCounter-1);
        });
    }

      function updateCandidates() {
        let cands = candidates.filter((c) => c.mutated);
        if(cands.length < 1){
          window.alert("There are no candidates subject to update.");
          return;
        }
        let incompleteVehiclesList = []
        //Check that any EV's have battery capacity set before sending to API.
        cands.forEach((c) => {
          if(c.is_phev || c.is_bev) {
            if (!c.battery_capacity|| c.battery_capacity === "0"){
                incompleteVehiclesList.push(c.ymm)
            }
            }
        })
        if(incompleteVehiclesList.length > 0) {
          setIncompleteVehicles(incompleteVehiclesList)
          window.alert(`Update incomplete. Please update the vehicles listed below.`)
          return
        } else {
          setIncompleteVehicles([])
        }
        const imageUpdates = cands.filter((c) => c.imageMutated);
        imageUpdates.forEach((c) => {
            updateCandidateImage(c.id, c.image);
        });
        //reset the image counter after some time so we're not forever blocked on fails
        setTimeout(()=>{setImageUploadCounter(0)}, MAX_SECONDS_TO_WAIT_ON_UPLOAD*1000);

        cands.forEach((c) => {
          c.image = null;
          if(c.upfits !== undefined && c.upfits !== null && c.upfits.length > 0) {
            let normalizedUpfits = []
            c.upfits.forEach((u) => {
              normalizedUpfits.push(u.id)
            })
            c.upfits = normalizedUpfits;
          }
          else {
            c.upfits = null;
          }
        })
        cands = conformPostRequest(userSettings, cands);
        cands.forEach((c) => {
          return roundObjValues(c)
        })
        fetch(`${props.apiURL}updateDbCandidates`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            dbName: db,
            editingUserEmail: user.email,
            candidates: JSON.stringify(cands),
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === "success") {
              window.alert("Candidates were succesfully updated. Be sure to run analytics with updated attributes.");
              setReload(true);
            } else
              window.alert(SERVER_ERRORED_MESSAGE);
          });
      }

      function addCandidate(candidate) {
        //Data needs to be an array to conform to conformPostRequest data shape pattern.
        let cand = conformPostRequest(userSettings, [candidate])
        cand.forEach((c) => {
          return roundObjValues(c)
        })
        fetch(`${apiURL}createDbCandidate`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            dbName: db,
            editingUserEmail: user.email,
            candidate: JSON.stringify(cand[0]),
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === "success") {
              window.alert("Candidate was succesfully added");
              setReload(true);
            }else
              window.alert(SERVER_ERRORED_MESSAGE);
          });
      }

      function handleDeleteClick(candidate) {
        const answer = window.confirm(
          `Are you sure you wish to delete the candidate: ${candidate.ymm.props.label}?`
        );
        if (answer) {
          fetch(`${apiURL}deleteDbCandidate`, {
            method: "DELETE",
            body: JSON.stringify({ 
              dbName: db, 
              candidateId: candidate.id
            }),
            headers: {
                Accept: "application/json",
                    "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
            }
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === 'success') {
              alert("Candidate successfully deleted.");
              setReload(true);
            }
            else {
              alert(SERVER_ERRORED_MESSAGE)
            }
          });
        }
      }

      function runAnalytics() {
        const url = apiURL + `runAnalytics?dbName=${db}`;
        // this only re-runs regular ezEV, not sensitivity
        const obj = { company_ident: db, baseline: true };
        try {
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(obj),
          }).then((data) => {
            if (data.status === 200) {
              window.alert("Vehicles and product results updated");
              setReload(true);
            } else {
              window.alert(SERVER_ERRORED_MESSAGE);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }

      function filterCDRCandidates(cdrList, candidateList) {
        let newList = cdrList.filter((cand) => !candidateList.some((c) => c.id === cand.id))
        return newList
      }

      function handleRefresh() {
        const url = `${apiURL}isAnalyticsProcessing?dbName=${db}`;
        fetch(url, {
          headers: {Authorization: `Bearer ${user.token}`}
        }).then((resp) => resp.json())
          .then((data) => {
            let processing = data.data[0].analytics_processing;
            if(!processing){
              runAnalytics();
              window.alert("Updating Analytics.");
              return;
            }
            else{
              window.alert(ANALYTICS_IN_PROCESS_MESSAGE);
            }
          })
          .catch((err) => {
            window.alert(SERVER_ERRORED_MESSAGE);
            return;
          });
      }

      const RunAnalyticsToolTipView = () => {
        return (
          <S.AdminToolTipContainer displayTooltip={showRunAnalyticsHover}>
            {RUN_ANALYTICS_BUTTON_TOOL_TIP}
          </S.AdminToolTipContainer>)
      }
     
      const handleCDRCandidateImports = (cands, useCdrDefaultSelected, ezevSelected, ionevSelected) => {
        const answer = window.confirm("(Non-destructive)Update this database with new candidates?")
        if(answer) {
        let data = {
          dbName: db,
          candidates: JSON.stringify(cands),
          editingUserEmail: user.email,
          useCdrDefaultSelected: useCdrDefaultSelected,
          importEzevAllSelected: ezevSelected,
          importIonevAllSelected: ionevSelected
        }
        fetch(`${apiURL}importMultipleCDRCandidates`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(data)
        })
        .then(resp => resp.json())
        .then(data => {
          if(data.status === "success") {
            alert('Candidates imported.')
            setReload(true);
          } else {
            alert(SERVER_ERRORED_MESSAGE)
          }
        })
        setShowImportModal(false);
      }
      }

      const handleSave = () => {
        updateCandidates();
      };

      function sanitizeData (data) {
        if (data) {
          data.net_price = !data.net_price ? data.msrp : data.net_price;
          data.mpg_c = !data.mpg_c ? null : data.mpg_c;
          data.mpg_h = !data.mpg_h ? null : data.mpg_h;
          data.battery_kwh_usable = !data.battery_kwh_usable ? null : data.battery_kwh_usable;
          data.battery_kwh = !data.battery_kwh ? null : data.battery_kwh;
        }
        return data;
      }

      function _getTableIndex(id) {
        const idxArr = candidates.map((c) => {
          return c.id;
        });
        return idxArr.indexOf(id);
      }

      const formatSelectedUpfits = (selectedUpfits) => {
          let upfitsArr = [];
          if (selectedUpfits) {
            selectedUpfits.map(u => {
              return upfitsArr.push({value: u.id, label: u.name})
            })
          }
          return upfitsArr;
      }

      function handleClassChange(c, id) {
        skipPageResetRef.current = false
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let vcl = cands[row];
        vcl["vehicle_class"] = c;
        vcl["mutated"] = true;
        setCandidates(cands);
      }

      function handleUpfitsChange(e, id) {
        skipPageResetRef.current = false;
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let vcl = cands[row];
        let selectedUpfits = [];
        e.forEach((u) => {
          selectedUpfits.push(upfits.find(el => el.id === u.value));
        })
        vcl.upfits = selectedUpfits;
        vcl['upfits'] = selectedUpfits;
        vcl['mutated'] = true;
        setCandidates(cands)
      }

      const handleImageChange = (imageList, id) => {
        // data for submit
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let cand = cands[row];
        cand.image = imageList;
        cand.mutated = true;
        cand.imageMutated = true;
        cand.img_url = null;
        setCandidates(cands);
      };

      function handleBoolChange(col, id) {
        skipPageResetRef.current = false
        let cands = [...candidates];
        let row = _getTableIndex(id);
        let c = cands[row];
        c[col] ? (c[col] = false) : (c[col] = true);
        c["mutated"] = true;
        setCandidates(cands);
      }

      function handleInputChange(e) {
        const ALPHANUMERIC_REGEX = new RegExp("^[\\w\\-\\(\\) ]*$") //Prevents all special characters except for " - _ ( ) "
        if(e.target.getAttribute('type') === 'text' && ALPHANUMERIC_REGEX.test(e.target.value) === false) {
          e.preventDefault();
          return;
        }
        skipPageResetRef.current = false
        const cands = [...candidates];
        let val = e.target.value;
        const id = e.target.getAttribute("id");
        let col = e.target.getAttribute("accessor");
        let row = _getTableIndex(id);
        let vcl = cands[row];
        if(e.target.getAttribute('type') === 'number') {
         val = formatDecimal(val, 4)
        }
        vcl[col] = val;
        vcl["mutated"] = true;
        setCandidates(cands);
      }

      const DriveTrainDropdown = useCallback((prsop) => {
          return (
            <select
              value={''}
              onChange={(e) => {}}
              >
            {''}
          </select>)
      }, []);
      //applying styled components to the input element strips custom attrs, so vin and accessor become nulls
      const BoolTableCell = useCallback((props) =>
        <input
            className="swt-admin-table-input"
            type="checkbox"
            id={props.id}
            disabled={props.disabled}
            accessor={props.accessor} 
            style={{color: props.bool ? "black" : "transparent"}} 
            onChange={props.handleOnClick}
            checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
        />,[]);

      const TableButton = useCallback((props) => 
        <S.InnerTableButton color={props.color} onClick={props.handleClick}>
          {props.label}
        </S.InnerTableButton>
      ,[])

      const InputTableCell = useCallback((props) => 
        <input className="swt-admin-input" 
               style={{width: '100%'}}
               key={props.id}
               title={props.label}
               value={`${props.label}`}
               id={props.id}
               accessor={props.accessor}
               onKeyDown={props.onKeyDown}
               onPaste={props.onPaste}
               onChange={props.handleOnChange}
               onWheel={(e) => e.target.blur()}
               type={props.type}
        />,[]);

      const ImageUploadCell = (props) => 
      <ImageUploading
          style={props.styles}
          value={props.images}
          image={props.image}
          maxFileSize={MAX_CAND_IMAGE_SIZE}
          url={props.url}
          onChange={function(il){handleImageChange(il, props.id)}}>
          {({
              onImageUpload,
              onImageUpdate,
              isDragging,
              dragProps,
              errors
          }) => (            
              <div>
                {
                ((!props.url && !props.image) || props.url === "https://images.sawatchlabs.com/rt_vehicles/white_block.png")
                ?
                <S.InnerTableButton
                  height={'40px'}
                  width={'110px'}
                  style={isDragging ? {color:"green"} : undefined}
                  onClick={onImageUpload}
                  {...dragProps}>
                    Click or Drag Here
                </S.InnerTableButton>
                :
                <S.CentereredFlexContainer key={props.id} onClick={onImageUpdate}>
                  {!errors && !props.image && props.url && <S.AdminCandidateTarget src={props.url} alt=""/> }
                  {!errors && props.image && <S.AdminCandidateTarget src={props.image.dataURL} alt=""/> }
                  {errors && 
                    <S.AdminCandidateError>
                      {errors.acceptType && <span>Wrong File Type</span>}
                      {errors.maxFileSize && <span>File Too Large</span>}
                    </S.AdminCandidateError>
                  }
                </S.CentereredFlexContainer>
                }
              </div>
          )}          
      </ImageUploading>;

      const mappedCandidates = useMemo(()=> {
        if(!candidates || !matchingCandidates || imageUploadCounter > 0)return null;
        if(candidates.length < 1 || !vehicleClasses || vehicleClasses.length < 1)return [];

        //this deep clones our vehicles object, but cannot be extended 
        //to complex members of the object, ie: Dates, etc.
        //needed so that we're not swapping bools 
        //and other attrs with react elements on the canonical vehicles obj
        const cands = JSON.parse(JSON.stringify(candidates));
        const matchingCands = matchingCandidates.map((c) => {return c.id});
        return cands.filter((c) => {
            if(matchingCands.indexOf(c.id) < 0)return null;
                c.last_updated_dateTime = formatDateTimeUTC(c.last_updated);
                //bool toggles
                c.evsa_selected = <BoolTableCell id={c.id} accessor={"evsa_selected"} bool={c.evsa_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.ionev_selected = <BoolTableCell id={c.id} accessor={"ionev_selected"} bool={c.ionev_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_selected = <BoolTableCell id={c.id} disabled={true} accessor={"is_selected"} bool={c.is_selected} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;

                c.is_phev = <BoolTableCell id={c.id} accessor={"is_phev"} bool={c.is_phev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_bev = <BoolTableCell id={c.id} accessor={"is_bev"} bool={c.is_bev} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_ice = <BoolTableCell id={c.id} accessor={"is_ice"} bool={c.is_ice} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_ld = c.is_ld === null ? true : c.is_ld;
                c.is_ld = <BoolTableCell id={c.id} accessor={"is_ld"} bool={c.is_ld} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;

                //readonly bools
                c.is_cng = <BoolTableCell disabled={true} id={c.id} accessor={"is_cng"} bool={c.is_cng} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_diesel = <BoolTableCell disabled={true} id={c.id} accessor={"is_diesel"} bool={c.is_diesel} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_gasoline = <BoolTableCell disabled={true} id={c.id} accessor={"is_gasoline"} bool={c.is_gasoline} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                c.is_propane = <BoolTableCell disabled={true} id={c.id} accessor={"is_propane"} bool={c.is_propane} handleOnClick={function(el){handleBoolChange(el.target.getAttribute("accessor"), el.target.getAttribute("id"))}}/>;
                
                //images
                c.image = <ImageUploadCell styles={{width:"200px"}} url={c.img_url} id={c.id} label={c.img_url} accessor={"image"} image={c.image && c.image.length > 0 ? c.image[0] : undefined} handleOnChange={function(e){/*console.log(e)*/}}/>

                //inputs
                c.ymm = <InputTableCell styles={{width:"210px"}} label={c.ymm ? c.ymm : ""} id={c.id} accessor={"ymm"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
                c.battery_capacity = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.battery_capacity ?? ""} id={c.id} accessor={"battery_capacity"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.usable_kwh = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.usable_kwh ?? ""} id={c.id} accessor={"usable_kwh"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.msrp = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.msrp ?? ""} id={c.id} accessor={"msrp"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.net_price = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={c.net_price ?? ""} id={c.id} accessor={"net_price"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.mpg_c = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.mpg_c ?? ""} id={c.id} accessor={"mpg_c"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.mpg_h = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.mpg_h ?? ""} id={c.id} accessor={"mpg_h"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
                c.maint_per_km = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} styles={c.styles} label={c.maint_per_km ?? ""} id={c.id} accessor={"maint_per_km"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>

                //vehicle classes dropdown
                c.vehicle_class = <VehicleClassDropdown
                    id={c.id}
                    key={c.id}
                    class={c.vehicle_class}
                    defaultValue={determineVehicleClassValue(c.vehicle_class, vehicleClasses)}
                    handleClassChange={handleClassChange}
                    vehicleClasses={vehicleClasses}/>

                // Upfits dropdown
                c.upfits = <UpfitsDropdown
                    id={c.id}
                    upfits={upfits}
                    selectedUpfits={formatSelectedUpfits(c.upfits)}
                    handleChange={handleUpfitsChange}
                    />

                if (user.role >= Roles.SuperAdmin) {
                  // When the candidate management tab is opened to clients in the future, we can adjust this permission level if desired -NK June '24
                  c.delButton = <TableButton label={"Delete"} color={'#e01f1f'} handleClick={function(e) {handleDeleteClick(c)}} /> 
                }
            return c;
        });
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [candidates, matchingCandidates, vehicleClasses, upfits, imageUploadCounter])

      return(
          <>
            <S.CandidatesTableHeaderContainer>
                <div>
                  <S.FlexWrapper>
                    <S.AdminTableExplainTextPrimary>List of all candidates for {dbDisplayName}.</S.AdminTableExplainTextPrimary>
                    <div style={{display: 'inline-block'}}>
                      <S.InfoIcon src="https://images.sawatchlabs.com/info-icon.png" alt="Infomation Icon" onMouseOver={() => setInfoShown(true)} onMouseOut={() => setInfoShown(false)} />
                      {infoShown &&
                        <S.InfoTextContainer>
                          <S.InfoText>
                          Select the candidates that you would like to be considered as potential replacement options for your existing vehicles.
                           Some specs (e.g. battery capacity and pricing) can be updated directly in the cells.
                            Make sure to select at least one EV and one ICE for each vehicle class for which there are vehicles in the Vehicles Tab.
                          </S.InfoText>
                        </S.InfoTextContainer>}
                    </div>
                  </S.FlexWrapper>
                    
                    <S.AdminTableExplainTextSub>This table lists all potential BEV, PHEV, and ICE candidates used in the comparative analyses.</S.AdminTableExplainTextSub> 
                    <S.SearchContainer>
                      <Search allValues={candidates} setMatchingValues={setMatchingCandidates} skipPageResetRef={skipPageResetRef} disabled={(candidates && !reload && imageUploadCounter === 0) ? false : true}/>
                    </S.SearchContainer>
                    
                    {showModal && (
                      <CandidateCreationModal
                        userSettings={userSettings}
                        dbSettings={dbSettings}
                        setShowModal={setShowModal}
                        vehicleClasses={vehicleClasses}
                        selectDriveTrainType={DriveTrainDropdown}
                        sanitizeData={sanitizeData}
                        addCandidate={addCandidate}
                        upfits={upfits}
                        cdrModal={false}
                        existingCandidates={candidates}
                      />
                    )}
                    {showImportModal &&
                      <CandidateImportModal
                        setShowImportModal={setShowImportModal}
                        cdrCandidates={filterCDRCandidates(cdrCandidates, candidates)}
                        handleCDRCandidateImports={handleCDRCandidateImports}
                      />
                    }
                    <S.CtaButtonWrapper>
                      <S.CtaButton onClick={() => setShowModal(true)} id="add">Add</S.CtaButton>
                      <S.CtaButton type="submit" onClick={handleSave}>
                        Save
                      </S.CtaButton>
                      {user.role >= Roles.SuperAdmin && // This is redundant for now since this component is not rendered for non-super admins, but keeping for when we expose in future
                        <S.CtaButton onClick={() => setShowImportModal(true)}>Bring In New Candidates</S.CtaButton>
                      }
                      <S.CtaButton type="submit" onClick={handleRefresh} onMouseOver={()=>setShowRunAnalyticsHover(true)} onMouseOut={()=>{setShowRunAnalyticsHover(false)}}>
                        Run Analytics
                      </S.CtaButton>
                      <RunAnalyticsToolTipView />
                    </S.CtaButtonWrapper>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <ExcelDownloadButton
                      downloadType={"dbCandidatesDownload"}
                      columns = {columnsToExcelHeaders(tableColumns)}
                      data={rowsToData(tableColumns, immutableCandidates, ((a, b) => a.ymm < b.ymm ? 1 : -1))}
                      dbDisplayName={dbDisplayName}
                      dbSettings={dbSettings}
                      userSettings={userSettings}
                    />
                  </Suspense>
                </S.CandidatesTableHeaderContainer>
                {incompleteVehicles.length > 0 &&
                <div><b>Vehicles that need 'Total Battery (kWh)' values:</b>
                  {incompleteVehicles.map((v) => {
                    return (
                      <div key={`${v}-${Math.random()}`}>{v}</div>
                    )
                  })}</div>
                }
                {(!mappedCandidates || reload || imageUploadCounter > 0)&& <Loading />}
                {(mappedCandidates && !reload && imageUploadCounter === 0) && 
                <AdminTableView
                    stickyCols={3}
                    columns={tableColumns.filter((column)=> column.showInInterface)}
                    sortBy={{id: 'evsa_selected', desc: true}}
                    data={reload ? [] : mappedCandidates}
                    skipPageResetRef={skipPageResetRef}
                    noDataMessage={'No candidates to display. Click "Bring In New Candidates" to selectively import candidates from the CDR, or click "Add" to create a new candidate.'}
                />}
          </>
      )
}