import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export default function PieChart({ graphRef, valueArray, colorArray, labelArray, chartTitle}) {
    const data = {
        labels: valueArray.map((value, index) => `${value} ${labelArray[index]}`),
        datasets: [{
            data: valueArray,
            backgroundColor: colorArray,
            borderWidth: (valueArray.filter(val => val !== 0).length === 1) ? 0 : 2 //if the array contains only one value greater than 0, hide the borders to prevent a line down the center of the circle
        }]
    }
    const options = {
        animation: false,
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                position: 'top',
                align: 'center',
                font: {
                    size: 42,
                },
            },
            legend:{
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 28
                    },
                    usePointStyle: true,
                    padding: 25
                }
            }
        }
    }

    return (
        <Pie ref={graphRef} data={data} options={options}/>
    );
};