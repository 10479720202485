import React from "react";
import ReactLoading from "react-loading";
export default function Loading(props) {
  // if (props.remainingVcls === "") {
  //   var processed = 0;
  // } else {
  //   processed = props.totalVcls - props.remainingVcls;
  // }
  return (
    <div className="nrel-loading-background">
      <ReactLoading
        className={"nrel-loading"}
        type={"cylon"}
        color={"rgba(128, 128, 128, 0.61)"}
        viewBox="0 0 2 2"
        width={props.width ? props.width :"30rem"}
        height={props.height? props.height : "28rem"}
      />
    </div>
  );
}

// {props.remainingVcls && (
//   <p className="nrel-processingText">
//     Processing vehicle <span className="nrel-bold">{processed}</span> of{" "}
//     <span className="nrel-bold">{props.totalVcls}</span>
//   </p>
// )}
