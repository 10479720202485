import React, { Component } from "react";
import L from "leaflet";
import { Map, Popup, CircleMarker, TileLayer } from "react-leaflet";
import Legend from "./mapLegend";
import Loading from "../loading";
// import MarkerClusterGroup from "react-leaflet-markercluster";

export default class MapWrapper extends Component {
  getBounds = () => {
    if (this.props.filteredGroup.length < 1 || this.props.mapCenter !== null)
      return;
    var nwCorner = [0, 0];
    var seCorner = [0, 0];
    this.props.filteredGroup.forEach((l, i) => {
      if (i === 0) {
        nwCorner = [l.lat, l.lon];
        seCorner = [l.lat, l.lon];
      }
      if (l.selected && l.lat > nwCorner[0]) nwCorner[0] = l.lat;
      if (l.selected && l.lon < nwCorner[1]) nwCorner[1] = l.lon;
      if (l.selected && l.lat < seCorner[0]) seCorner[0] = l.lat;
      if (l.selected && l.lon > seCorner[1]) seCorner[1] = l.lon;
    });
    this.showZoom = false;
    return [nwCorner, seCorner];
  };

  formatAddress = (address) => {
    if (!address) return;
    let addressElements = address.split(",");
    return (
      <>
        {addressElements[0]}
        {addressElements[1] && addressElements[2] ? "," : ""} <br />
        {addressElements[1] && addressElements[2]
          ? addressElements[1] + "," + addressElements[2] + ","
          : ""}{" "}
        <br /> {addressElements[3] ? addressElements[3] : ""}
      </>
    );
  };

getMapClusterDetails = (freq) => {
    var freqClass;
    var size;
    if(freq > .7) {
      freqClass = "large";
      size = 35;
    } else if(freq > .3) {
      freqClass = "medium";
      size = 30;
    } else {
      freqClass = "small";
      size = 25;
    }
    return  [freqClass, size];
  }

  generateMapItems = () => {
    return this.props.filteredGroup.map((parking_loc) => {
      return (
        <CircleMarker
          key={`parking_loc.pkid${Math.random()}`}
          center={[parking_loc.lat, parking_loc.lon]}
          color={parking_loc.icon_color}
          radius={parking_loc.icon_radius}
        >
          <Popup id={parking_loc.pkid}>
            <h1 className={"nrel-map-popup-title"}>
              {this.formatAddress(parking_loc.address)}
              <br />
            </h1>
            <span className={"nrel-map-popup-bold"}>
              Primary parking location for{" "}
              <span className="nrel-bold-small">{parking_loc.vcl_ct}</span>{" "}
              {parseInt(parking_loc.vcl_ct) === 1
                ? "potential EV"
                : "potential EVs"}
              .
            </span>{" "}
            <p>
              {" "}
              <span className={"nrel-map-popup-bold"}>
                Occasional parking location for{" "}
                <span className="nrel-bold-small">{parking_loc.anc_ct}</span>{" "}
                {parseInt(parking_loc.anc_ct) === 1
                  ? "potential EV"
                  : "potential EVs"}
                .
              </span>{" "}
            </p>
            <br />
            <p
              className="nrel-map-graph-link"
              onClick={() => this.props.handleLocationChange(parking_loc.pkid)}
            >
              View Charging Graph
            </p>
          </Popup>
        </CircleMarker>
      );
    });
  };

  createCustomCluster = (cluster) =>{
    const count = cluster.getChildCount();
    var total = this.props.filteredGroup.length;
    var clusterDetails = this.getMapClusterDetails(count / total);
    const options = {
      cluster: `marker-cluster ${clusterDetails[0]}`,
      size: clusterDetails[1],
    };
    return L.divIcon({
      html: `<div><span>${count}</span></div>`,
      className: `${options.cluster}`,
      iconSize: L.point(options.size, options.size, true),
    });
  }

  render() {
    const mapBounds = this.getBounds();
    if (
      (this.props.mapCenter === null && mapBounds === undefined) ||
      this.props.filteredGroup.length === 0
    ) {
      return (
        <div className="nrel-map">
          <Loading />;
        </div>
      );
    }
    return (
      <>
        <Map
          className="nrel-map"
          bounds={mapBounds}
          boundsOptions={{ padding: [8, 8] }}
          center={this.props.mapCenter}
          zoom={this.props.zoom}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            maxZoom={20}
            zIndex={4}
          />
          {/* Comment back in if you want to add clustering. */}
          {/* <MarkerClusterGroup iconCreateFunction={this.createCustomCluster}> */}
          {this.generateMapItems()}
          {/* </MarkerClusterGroup> */}
          <Legend iconColors={this.props.iconColors} />
        </Map>
      </>
    );
  }
}
