import React, { useState } from "react";
import closeButton from "../../images/close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function InstallModal(props) {
  const { selectedGroup, user, apiURL } = props;

  const [startDate, setStartDate] = useState(
    !selectedGroup.targetStart ? new Date() : selectedGroup.targetStart
  );
  const [endDate, setEndDate] = useState(
    !selectedGroup.targetEnd ? new Date() : selectedGroup.targetEnd
  );
  const [deptName, setDeptName] = useState(
    !selectedGroup.groupNameUser
      ? selectedGroup.name
      : selectedGroup.groupNameUser
  );
  const [installed, setInstalled] = useState(
    !selectedGroup.targetInstalls ? 0 : selectedGroup.targetInstalls
  );
  const [noShow, setNoShow] = useState(
    !selectedGroup.noShow ? 0 : selectedGroup.noShow
  );
  // const [pending, setPending] = useState(!selectedGroup.pending ? 0 : selectedGroup.pending);

  const handleSubmit = (event) => {
    event.preventDefault();
    var start = new Date(startDate).toISOString();
    var end = new Date(endDate).toISOString();
    let obj = {
      pkid: selectedGroup.pkid,
      db: selectedGroup.db,
      groupName: deptName,
      groupid: selectedGroup.dptName,
      installed: parseInt(installed),
      noshow: parseInt(noShow),
      startDate: start,
      endDate: end,
    };
    updateInstallGroup(obj);
    props.handleModalClick();
  };

  function updateInstallGroup(groupObj) {
    const url = `${apiURL}updateInstallTotals/`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(groupObj),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.msg === "success") {
          // CALL get groups info
          props.getDeviceInstallResults();
        } else {
          window.alert(
            "There was an error with your request. Please try again."
          );
        }
      });
  }
  return (
    <div className="install-modal">
      <div className="install-modal-content">
        <img
          id="instl-clse"
          src={closeButton}
          alt="close button"
          onClick={props.handleModalClick}
        />
        <h3>Update Group / Department Info</h3>
        <form onSubmit={handleSubmit} className="install-addGroup-form">
          <label id="install-group-label">Group/Department Name:</label>
          <br />
          <input
            id="install-group-input-dpt"
            placeholder="Department Name"
            type="text"
            onChange={(e) => setDeptName(e.target.value)}
            value={deptName}
          ></input>
          <br />
          {/* {selectedGroup.groupId !== "GroupCompanyId" && ( */}
            <>
              <label id="install-group-label">
                Total Number of Vehicles Within Group:
              </label>
              <br />
              <input
                id="install-group-input-ttl"
                type="number"
                onChange={(e) => setInstalled(e.target.value)}
                value={installed}
              ></input>
              <br />
              <label id="install-group-label">Total Number of No Shows:</label>
              <br />
              <input
                id="install-group-input-noshw"
                type="number"
                disabled={
                  selectedGroup.groupId === "groupCompanyId" ? true : false
                }
                onChange={(e) => setNoShow(e.target.value)}
                value={noShow}
              ></input>
            </>
          {/* )} */}
          <br />

          <div className="install-datePicker-start">
            <label id="install-group-label">Target Start Date</label>
            <br />
            <DatePicker
              id="install-group-input-start"
              placeholderText={new Date(startDate)}
              selected={new Date(startDate)}
              onChange={setStartDate}
            />
          </div>
          <div className="install-datePicker-end">
            <label id="install-group-label">Target End Date</label>
            <br />
            <DatePicker
              id="install-group-input-end"
              placeholderText={new Date(endDate)}
              selected={new Date(endDate)}
              onChange={setEndDate}
            />
          </div>
          {/* <button
            onClick={() => window.alert("coming soon")}
            className="addPhase"
          >
            Add Phase
          </button> */}
          <input id="install-update-submit" type="submit"></input>
        </form>
      </div>
    </div>
  );
}
