// ** MACROS **

const POUNDS2TONS = function (lbs) { return lbs * 0.0005 };
const LITERS2GALS = function (liters) { return liters * 0.264172 };
const KM2MILES = function (km) { return km * 0.621371 };
const GRAMS2POUNDS = function (grams) { return grams * 0.00220462 };
const POUNDS2GRAMS = function (lbs) { return lbs * 453.59237 }

const CO2_LBSPERGALLON = 19.592;
const CO2_LBSPERGALLONDIESEL = 22.443;
//const CO2_GRAMSPERGGALLON = 8888;

let gramsGHGPerKwh = 500;


class EmitProcessor {
  constructor(model, options) {
    this.model = model;
    this.calcByFuel = true;
    if (options) {
      if (options.hasOwnProperty('calcByFuel')) this.calcByFuel = options.calcByFuel;
      if (options.hasOwnProperty('gramsGHGPerKwh')) gramsGHGPerKwh = options.gramsGHGPerKwh;
    }
  }

  calculateEmissions(vehicle, options) {
    let calcByMile = false;
    if (typeof options !== 'undefined' &&
      options.hasOwnProperty('calcByFuel') &&
      options.calcByFuel === false) {
      calcByMile = true;
    }
    vehicle.ghgLbsSavings = 0;
    vehicle.ghgTonsSavings = 0;
    vehicle.CO2LbsSavings = 0;
    vehicle.CO2TonsSavings = 0;
    vehicle.coLbsSavings = 0;
    vehicle.pm10LbsSavings = 0;
    vehicle.pm25LbsSavings = 0;
    vehicle.pm10GramsSavings = 0;
    vehicle.pm25GramsSavings = 0;
    vehicle.noxLbsSavings = 0;
    vehicle.fuelGallonsSavings = 0;
    vehicle.fuelCostSavings = 0;

    let emissionsIce = {};
    if (calcByMile === false) emissionsIce = this._emissionsByFuel(vehicle, this.model);
    else emissionsIce = this._emissionsByMile(vehicle, this.model);

    vehicle.CO2Lbs = emissionsIce.CO2Lbs;
    vehicle.ghgLbs = emissionsIce.CO2Lbs;
    vehicle.pm25Lbs = emissionsIce.pm25Lbs;
    vehicle.pm10Lbs = emissionsIce.pm10Lbs;
    vehicle.pm10Grams = POUNDS2GRAMS(emissionsIce.pm10Lbs);
    vehicle.pm25Grams = POUNDS2GRAMS(emissionsIce.pm25Lbs);
    vehicle.coLbs = emissionsIce.coLbs
    vehicle.noxLbs = emissionsIce.noxLbs

    vehicle.miles = parseInt(KM2MILES(vehicle.km));
    vehicle.fuelEcon = parseInt((vehicle.miles) / (LITERS2GALS(vehicle.fu_liters_actual)))

    vehicle.ghgTons = POUNDS2TONS(vehicle.ghgLbs);
    vehicle.CO2Tons = POUNDS2TONS(vehicle.CO2Lbs);

    if (vehicle.is_bev) {
      const ghg = GRAMS2POUNDS(vehicle.kwh * gramsGHGPerKwh);
      vehicle.ghgLbsSavings = vehicle.ghgLbs - ghg;
      vehicle.ghgTonsSavings = POUNDS2TONS(vehicle.ghgLbs - ghg);
      vehicle.ghgLbs = ghg;
      vehicle.CO2LbsSavings = vehicle.CO2Lbs;
      vehicle.CO2TonsSavings = POUNDS2TONS(vehicle.CO2Lbs);
      vehicle.coLbsSavings = vehicle.coLbs;
      vehicle.pm10LbsSavings = vehicle.pm10Lbs;
      vehicle.pm25LbsSavings = vehicle.pm25Lbs;
      vehicle.pm10GramsSavings = POUNDS2GRAMS(vehicle.pm10Lbs);
      vehicle.pm25GramsSavings = POUNDS2GRAMS(vehicle.pm25Lbs);
      vehicle.noxLbsSavings = vehicle.noxLbs;
      vehicle.CO2Lbs = 0;
      vehicle.coLbs = 0;
      vehicle.pm10Lbs = 0;
      vehicle.pm25Lbs = 0;
      vehicle.pm10Grams = 0;
      vehicle.pm25Grams = 0;
      vehicle.noxLbs = 0;
      vehicle.fuelCostSavings = (vehicle.fuel_cost && vehicle.fu_liters)? (vehicle.fuel_cost * LITERS2GALS(vehicle.fu_liters))  : 0;
      vehicle.fuelGallonsSavings = LITERS2GALS(vehicle.fu_liters)
    }
    if (vehicle.is_phev) {
      let emissionsPhev = {};
      if (calcByMile === false) emissionsPhev = this._emissionsByFuel(vehicle, this.model, { useActualFuel: true });
      else emissionsPhev = this._emissionsByMile(vehicle, this.model, { useActualFuel: true });
      //adding the kwh generation ghg to the fuel ghg for phevs
      vehicle.CO2Lbs = emissionsPhev.CO2Lbs;
      vehicle.ghgLbs = emissionsPhev.CO2Lbs;
      vehicle.ghgLbs += GRAMS2POUNDS(vehicle.kwh * gramsGHGPerKwh);
      vehicle.ghgTons = POUNDS2TONS(vehicle.ghgLbs);
      vehicle.ghgLbsSavings = emissionsIce.CO2Lbs - emissionsPhev.CO2Lbs - GRAMS2POUNDS(vehicle.kwh * gramsGHGPerKwh);
      vehicle.ghgTonsSavings = POUNDS2TONS(vehicle.ghgLbsSavings);
      vehicle.coLbsSavings = emissionsIce.coLbs - emissionsPhev.coLbs;
      vehicle.CO2LbsSavings = emissionsIce.CO2Lbs - emissionsPhev.CO2Lbs - GRAMS2POUNDS(vehicle.kwh * gramsGHGPerKwh);
      vehicle.CO2TonsSavings = POUNDS2TONS(vehicle.CO2LbsSavings);
      vehicle.pm10LbsSavings = emissionsIce.pm10Lbs - emissionsPhev.pm10Lbs;
      vehicle.pm25LbsSavings = emissionsIce.pm25Lbs - emissionsPhev.pm25Lbs;
      vehicle.pm10GramsSavings = POUNDS2GRAMS(emissionsIce.pm10Lbs - emissionsPhev.pm10Lbs);
      vehicle.pm25GramsSavings = POUNDS2GRAMS(emissionsIce.pm25Lbs - emissionsPhev.pm25Lbs);
      vehicle.noxLbsSavings = emissionsIce.noxLbs - emissionsPhev.noxLbs;

      vehicle.coLbs = emissionsPhev.coLbs;
      vehicle.pm10Lbs = emissionsPhev.pm10Lbs;
      vehicle.pm25Lbs = emissionsPhev.pm25Lbs;
      vehicle.pm10Grams = POUNDS2GRAMS(emissionsPhev.pm10Lbs);
      vehicle.pm25Grams = POUNDS2GRAMS(emissionsPhev.pm25Lbs);
      vehicle.noxLbs = emissionsPhev.noxLbs;
      vehicle.fuelCostSavings = (emissionsIce.fuel_cost && vehicle.fu_liters && vehicle.fu_liters_actual)? (vehicle.fuel_cost * LITERS2GALS(vehicle.fu_liters - vehicle.fu_liters_actual))  : 0;
      vehicle.fuelGallonsSavings = LITERS2GALS(vehicle.fu_liters - vehicle.fu_liters_actual)
    }

    if (isNaN(vehicle.fuelEcon) || vehicle.is_bev) vehicle.fuelEcon = 'NA';
    vehicle.emit = (((parseFloat(POUNDS2TONS(vehicle.ghgLbs))
      + parseFloat(vehicle.pm25Lbs)
      + parseFloat(vehicle.pm10Lbs)
      + parseFloat(POUNDS2TONS(vehicle.coLbs))) / vehicle.miles) * 1000);
    return vehicle;
  }

  summarizeEmissions(emissionsData, idleData, fleetInfo, dateRange){
    const obj = {
      dateRange: dateRange,
      displayName: fleetInfo.display_name ? fleetInfo.display_name : "Fleet",
      CO2Tons: 0,
      CO2Lbs: 0,
      CO2TonsSaved: 0,
      CO2LbsSaved: 0,
      ghgTons: 0,
      ghgLbs: 0,
      ghgTonsSaved: 0,
      ghgLbsSaved: 0,
      noxLbs: 0,
      noxLbsSaved: 0,
      coLbs: 0,
      coLbsSaved: 0,
      pm10Lbs: 0,
      pm10Grams: 0,
      pm10GramsSaved: 0,
      pm10LbsSaved: 0,
      pm25Lbs: 0,
      pm25LbsSaved: 0,
      pm25Grams: 0,
      pm25GramsSaved: 0,
      vehicles: 0,
      ice: 0,
      diesel: 0,
      phev: 0,
      bev: 0,
      miles: 0,
      idle: 0,
      gasolineGallons: 0,
      gasolineGHGLbs: 0,
      gasolineGHGTons: 0,
      gasolineGallonsSaved: 0,
      dieselGallons: 0,
      dieselGHGLbs: 0,
      dieselGHGTons: 0,
      dieselGallonsSaved: 0,
      evGHGLbs: 0,
      evGHGTons: 0,
      phevGHGLbs: 0,
      phevGHGTons: 0,
      phevGHGTonsSaved: 0,
      phevGHGLbsSaved: 0,
      bevGHGLbsSaved: 0,
      bevGHGTonsSaved: 0,
      phevGallons: 0,
      phevKwh: 0,
      emitRatio: 0,
      mpg: 0,
      altFuelMiles: 0,
      fuelCostSaved: 0,
      fuelGallonsSaved: 0,
    }
    if(emissionsData) {
    emissionsData.forEach((r) => {
      let idle = 0;
      if (idleData) {
        const i = idleData.filter(id => { return id.vin === r.vin });
        idle = (i[0] && i[0].hours) ? i[0].hours : 0;
      }
      r.idle = parseFloat(idle);
      obj.CO2Tons += parseFloat(POUNDS2TONS(r.CO2Lbs));
      obj.CO2Lbs += parseFloat(r.CO2Lbs);
      obj.CO2TonsSaved += parseFloat(POUNDS2TONS(r.CO2LbsSavings));
      obj.CO2LbsSaved += parseFloat(r.CO2LbsSavings);
      obj.ghgTons += parseFloat(POUNDS2TONS(r.ghgLbs));
      obj.ghgLbs += parseFloat(r.ghgLbs);
      obj.ghgTonsSaved += parseFloat(POUNDS2TONS(r.ghgLbsSavings));
      obj.ghgLbsSaved += parseFloat(r.ghgLbsSavings);
      obj.noxLbs += parseFloat(r.noxLbs);
      obj.noxLbsSaved += parseFloat(r.noxLbsSavings);
      obj.coLbs += parseFloat(r.coLbs);
      obj.coLbsSaved += parseFloat(r.coLbsSavings);
      obj.pm10Lbs += parseFloat(r.pm10Lbs);
      obj.pm10LbsSaved += parseFloat(r.pm10LbsSavings);
      obj.pm25Lbs += parseFloat(r.pm25Lbs);
      obj.pm25LbsSaved += parseFloat(r.pm25LbsSavings);
      obj.miles += parseFloat(r.miles);
      obj.pm10Grams = POUNDS2GRAMS(obj.pm10Lbs);
      obj.pm25Grams = POUNDS2GRAMS(obj.pm25Lbs)
      obj.pm10GramsSaved = POUNDS2GRAMS(obj.pm10LbsSaved);
      obj.pm25GramsSaved = POUNDS2GRAMS(obj.pm25LbsSaved);
  
      if (r.is_phev === true) {
        obj.phev++;
        // obj.gasolineGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        // obj.gasolineGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        // obj.gasolineGHGLbs += parseFloat(r.ghgLbs);
        obj.phevGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        obj.phevGHGLbs += parseFloat(r.ghgLbs);
        obj.phevGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        obj.phevKwh += parseFloat(r.kwh);
        obj.altFuelMiles += parseFloat(r.miles);
        obj.fuelCostSaved += parseFloat(r.fuelCostSavings);
        obj.fuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.phevGHGLbsSaved += parseFloat(r.ghgLbsSavings);
        obj.phevGHGTonsSaved = parseFloat(POUNDS2TONS(obj.phevGHGLbsSaved));
      }
      if (r.is_diesel === true) {
        obj.diesel++;
        obj.dieselGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        obj.dieselGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        obj.dieselGHGLbs += parseFloat(r.ghgLbs);
      }
      if (r.is_bev === true){ 
        obj.bev++;
        obj.evGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        obj.evGHGLbs += parseFloat(r.ghgLbs);
        obj.ghgGasolineLbs += parseFloat(r.ghgLbs);
        obj.altFuelMiles += parseFloat(r.miles)
        obj.fuelCostSaved += parseFloat(r.fuelCostSavings);
        obj.fuelGallonsSaved += parseFloat(r.fuelGallonsSavings);
        obj.bevGHGLbsSaved += parseFloat(r.ghgLbsSavings);
        obj.bevGHGTonsSaved = parseFloat(POUNDS2TONS(obj.bevGHGLbsSaved));
      }
      if ((r.is_gasoline === true) || (!r.is_bev && !r.is_phev && !r.is_diesel)) {
        r.is_gasoline = true;
        obj.ice++;
        obj.gasolineGallons += parseFloat(LITERS2GALS(r.fu_liters_actual));
        obj.gasolineGHGTons += parseFloat(POUNDS2TONS(r.ghgLbs));
        obj.gasolineGHGLbs += parseFloat(r.ghgLbs);
      }
    });
  }

    obj.emitRatio = (((parseFloat(obj.ghgTons) + parseFloat(obj.pm25Lbs) + parseFloat(obj.pm10Lbs) + parseFloat(POUNDS2TONS(obj.coLbs))) /
      obj.miles) * 1000).toPrecision(2);
    if (isNaN(obj.emitRatio)) obj.emitRatio = 0;
    if (!isFinite(obj.emitRatio)) obj.emitRatio = 0;
    if (typeof emitRatio === "string") obj.emitRatio = parseFloat(obj.emitRatio);
    obj.gallons = (obj.gasolineGallons + obj.dieselGallons + obj.phevGallons);
    obj.vehicles = emissionsData ? emissionsData.length : null;
    obj.mpg = obj.miles / obj.gallons;
    if(isNaN(obj.mpg)) obj.mpg = 0;
    return obj;
  }

  co2lbsPerLiter(liters){
    return (LITERS2GALS(liters) * CO2_LBSPERGALLON);
  }

  co2lbsPerLiterDiesel(liters){
    return (LITERS2GALS(liters) * CO2_LBSPERGALLONDIESEL);
  }

  kwhToGHGLbs(kwh) {
    return (GRAMS2POUNDS(kwh * gramsGHGPerKwh));
  }

  _ageCoefficient(model, vehicle, pollutant) {
    if (typeof model === 'undefined' || model.length < 1) return 1.10;
    const now = new Date();
    let age = 4;
    if (typeof vehicle.year === "number") age = Math.min(now.getFullYear() - vehicle.year, 30);
    let fuelType = "gasoline";
    if (vehicle.is_diesel) fuelType = 'diesel';
    const mc = model.filter((m) => m.vehicleClass === `${vehicle.vehicle_class.toLowerCase()} ${fuelType}`);
    const row = mc.findIndex(c => c.pollutant === pollutant);
    if (row < 0) return 1.1;
    age = `year${age}`;
    return mc[row][age] / 100;
  }

  _emissionsByFuel(vehicle, model, options) {
    let ac = 1;
    let litersFuel = vehicle.fu_liters;
    if (options && options.hasOwnProperty('useActualFuel') && options.useActualFuel === true) litersFuel = vehicle.fu_liters_actual;
    const res = {};

    res.CO2Lbs = this.co2lbsPerLiter(litersFuel);
    if (vehicle.is_diesel) res.CO2Lbs = this.co2lbsPerLiterDiesel(litersFuel);
    //res.CO2Lbs = (LITERS2GALS(litersFuel) * CO2_LBSPERGALLON);
    //if (vehicle.is_diesel) res.CO2Lbs = (LITERS2GALS(litersFuel) * CO2_LBSPERGALLONDIESEL);

    ac = this._ageCoefficient(model, vehicle, 'pm25');
    res.pm25Lbs = ac * GRAMS2POUNDS(LITERS2GALS(litersFuel) *
      this._pollutantGrams(model, vehicle, 'pm25'));

    ac = this._ageCoefficient(model, vehicle, 'pm10');
    res.pm10Lbs = ac * GRAMS2POUNDS(LITERS2GALS(litersFuel) *
      this._pollutantGrams(model, vehicle, 'pm10'));

    ac = this._ageCoefficient(model, vehicle, 'co');
    res.coLbs = ac * GRAMS2POUNDS(LITERS2GALS(litersFuel) *
      this._pollutantGrams(model, vehicle, 'co'));

    ac = this._ageCoefficient(model, vehicle, 'nox');
    res.noxLbs = ac * GRAMS2POUNDS(LITERS2GALS(litersFuel) *
      this._pollutantGrams(model, vehicle, 'nox'));
    return res;
  }

  _emissionsByMile(vehicle, model, options) {
    let ac = 1;
    const miles = parseInt(KM2MILES(vehicle.km));
    let pctFuel = 1;
    if (options && options.hasOwnProperty('useActualFuel') && options.useActualFuel === true) {
      pctFuel = vehicle.fu_liters_actual / vehicle.fu_liters;
    }
    const res = {};

    res.CO2Lbs = pctFuel * GRAMS2POUNDS(this._co2GramsByMile(model, vehicle) * miles);

    ac = this._ageCoefficient(model, vehicle, 'pm25');
    res.pm25Lbs = ac * GRAMS2POUNDS(miles *
      this._pollutantGrams(model, vehicle, 'pm25', true));

    ac = this._ageCoefficient(model, vehicle, 'pm10');
    res.pm10Lbs = ac * GRAMS2POUNDS(miles *
      this._pollutantGrams(model, vehicle, 'pm10', true));

    ac = this._ageCoefficient(model, vehicle, 'co');
    res.coLbs = ac * GRAMS2POUNDS(miles *
      this._pollutantGrams(model, vehicle, 'co', true));

    ac = this._ageCoefficient(model, vehicle, 'nox');
    res.noxLbs = ac * GRAMS2POUNDS(miles *
      this._pollutantGrams(model, vehicle, 'nox', true));
    return res;
  }

  _co2GramsByMile(model, vehicle) {
    let fuelType = "gasoline";
    let year = Math.min(2020, vehicle.year);
    if(year < 1976)year = 2010;
    let vc = vehicle.vehicle_class.toLowerCase();
    if (vc.includes('suv')) vc = 'crossover suv';
    if (vc.includes('pickup') || vc.includes('cargo-van')) vc = 'pickup';
    if (vehicle.is_diesel || vc.includes('class') || vc.includes('heavy') || vc.includes('medium')) {
      fuelType = 'diesel';
      vc = 'truck';
    }
    let mc = model.CO2.filter((m) => m.vehicle_class.toLowerCase() === `${vc}`);
    if (typeof mc === 'undefined' || mc.length < 1) {
      mc = model.CO2.filter((m) => m.vehicle_class.toLowerCase() === `crossover suv`);
    }
    const row = mc.filter((m) => m.year === `${year}`);
    if (fuelType === 'diesel') return parseFloat(row[0].co2_diesel);
    return parseFloat(row[0].co2);
  }

  _pollutantGrams(model, vehicle, pollutant, byMile) {
    let fuelType = "gasoline";
    let vc = vehicle.vehicle_class.toLowerCase();
    if (vc.includes('pickup')) vc = 'pickup';
    if (vc.includes('mini')) vc = 'suv';
    if (vc.includes('station wagon')) vc = 'suv';
    if (vehicle.is_diesel || vc.includes('class') || vc.includes('heavy') || vc.includes('medium')) {
      fuelType = 'diesel';
      vc = 'truck';
    }
    if (typeof vc === 'undefined' || vc === '' || vc === 'cargo-van') vc = 'pickup';
    const mc = model.filter((m) => m.vehicleClass === `${vc} ${fuelType}`);
    const row = mc.findIndex(c => c.pollutant.toLowerCase() === pollutant.toLowerCase());
    if (typeof byMile === 'undefined' || byMile === false) {
      const defaults = { co: 30.581, nox: 1.089, pm10: 0.064, pm25: 0.064 };
      if (typeof row === "undefined" || row < 0) {
        console.warn('Error getting pollutant values', pollutant, vc, fuelType);
        return (defaults[pollutant]);
      }
      return mc[row].gramsPerGallon;
    }
    return mc[row].gramsPerMile;
  }
}

export default EmitProcessor;