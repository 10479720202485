import { useLeaflet } from "react-leaflet";
import L from "leaflet";
import { useEffect, useRef } from "react";

const Legend = (props) => {
  const { iconColors } = props;
  const { map } = useLeaflet();
  const legendRef = useRef(undefined)

  useEffect(() => {
    //   doesn't recreate legend on every render if already exists.
    if(legendRef.current === 'created') return;
    // get color depending on primary Vcl value
    const getColor = (d) => {
      return d > 25
        ? iconColors[0]
        : d > 20
        ? iconColors[1]
        : d > 15
        ? iconColors[2]
        : d > 10
        ? iconColors[3]
        : d > 5
        ? iconColors[4]
        : d > 1
        ? iconColors[5]
        : iconColors[6];
    };

    const legend = L.control({ position: "bottomleft" });
    legend.onAdd = () => {
    legendRef.current = 'created'
      const div = L.DomUtil.create("div", "info legend");
      const vclCount = [0, 1, 5, 10, 15, 20, 25];
      let labels = ['<h3>Primary EVs at Location:</h3>'];
      let from;
      let to;

      for (let i = 0; i < vclCount.length; i++) {
        if(vclCount[i] === 0) labels.push('<i style="background:' + getColor(0) +'"></i> 0 Vcls')
        else{
        from = vclCount[i];
        to = vclCount[i + 1];
        labels.push(
          '<i style="background:' + getColor(from + 1) +'"></i> ' + from +(to ? " &ndash;" + to + ' Vcls' : "+ Vcls")
        );
        }
      }

      div.innerHTML = labels.join("<br>");
      return div;
    };

    legend.addTo(map);
  });
  return null;
};

export default Legend;
