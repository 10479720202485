import React from "react";

export default function SpeednSummaryPanels(props){

  const { vehicles, incidents, group, childVehicleCount } = props;
  let ct = Math.max((parseInt(group.vehicle_count) + parseInt(childVehicleCount)), vehicles.length);
  if(isNaN(ct))ct = "--";

  return(
    <div className="speedn-stat-box-wrapper">
      <div className='speedn-stat-box'>
        <span className='speedn-stat-box-metric'>
          {vehicles.length}
        </span>
        <span className='speedn-stat-box-label'>
          Vehicles with Incidents
        </span>
      </div>

      <div className='speedn-stat-box'>
        <span className='speedn-stat-box-metric'>
          {incidents.length}
        </span>
        <span className='speedn-stat-box-label'>
          Incidents Count
        </span>
      </div>
    </div>
  )
}