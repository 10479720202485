import { useMemo, useEffect, useRef, Component } from "react";
import { useTable, useSortBy, usePagination, useFlexLayout } from "react-table";
import styled from "styled-components/macro"
import arrowUp from '../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../images/ic-arrow-unsort-4px-9px.gif';
import { FormatData } from "./UtilityFunctions";


//This isn't a great way of doing styles but it's fully supported as an escape hatch for a situation like this
// It's only really acceptable if there's something you've got in your page where you can't/shouldn't modify the internal components of something you're importing but do know the classnames assigned to them
const TableContainer = styled.div`
width: 90%;
max-width: 1500px;
min-width: 900px;
`
const TableBody = styled.div`
max-height: 500px;
margin-top: 20px;
overflow: scroll;
white-space: nowrap;
text-align: left;

thead {
  position: sticky;
  top: 0;
  z-index: 5;
  
  & th {
    background-color: #2E4B7C;
    color: white;
    font-size: 16px;
    border-spacing: 0;
    height: 72px;
    padding: 0 30px;
    text-align: left;
    margin: 0;
    white-space: pre;
    cursor: pointer;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 5;
  }
}
}
table {
  border-collapse: collapse;
}
.swt-emit-table-row {
    height: 50px;
    font-size: 14px;
    background-color: #fff;
    & td {
      padding: 0 30px;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 3;
        background-color: inherit;
      }
    }
  // CSS pseudo class for alternate row colors
  &:nth-child(odd) {
    background-color: rgb(245, 245, 245);
  }
}
.emit-table-search {
  height: 30px;
  margin-top: 5px;  
}
`

const NoDataMessage = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  white-space: nowrap;
  margin: 0;
  padding: 10px 0;
  text-align: center;
`
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`
const PaginationButtons = styled.button`
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;
  &:disabled {
    cursor:default;
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(16, 16, 16, 0.2);
  }
  &:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out .2s;
  }
`;
const PaginationDetails = styled.span`
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
`

const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent:'flex-start',
      alignItems: 'center',
      display: 'flex',
      height: '100%'
    },
  },
]

function Table(props) {
  const { columns, data, noDataMessage } = props;

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout
      // This is the default widths for every column
      // width is used for both the flex-basis and flex-grow
      width: 100,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
        sortBy: [
          {
            id: "asset_id",
          },
        ],
      },
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.scrollTo({top:0});
  }, [pageIndex]);

  return (
    <TableContainer >
      <TableBody ref={tableRef}>
        <table {...getTableProps()} className="vehicle-table">
          <thead id="emit-vehicle-table-headers">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id="emit-vehicle-table-header-row">
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                  <div 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...column.getHeaderProps(headerProps)}
                    id="emit-vehicle-table-header">
                    {column.render("Header")}{" "}
                    <span className="emit-table-sort-icons">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <img src={ arrowDown } className="emit-table-sort-down-arrow" alt="Column sorted in descending order" />
                            : <img src={ arrowUp } className="emit-table-sort-up-arrow" alt="Column sorted in ascending order" />
                          : <img src={ arrowUnsel } className="emit-table-sort-both-arrow" alt="Unsorted column" />}
                      </span>
                      </div>
                </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr className='swt-emit-table-row' {...row.getRowProps()} id="emit-vehicle-table-row">
                  {row.cells.map((cell, idx) => {
                    return (
                      <td key={`${cell.value}-${idx}`}>
                        <div
                          {...cell.getCellProps()}
                          {...cell.getCellProps(cellProps)}
                          id={cell.vin} 
                          className={`emit-table-cell-${cell.column.id}`}>
                          {FormatData(cell.column.id, cell.value, row.original)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableBody>
      {rows.length < 1 
      ?
      <NoDataMessage>{noDataMessage ? `${noDataMessage}` : 'No data to display. Try adjusting date or filters.'}</NoDataMessage>
      :
      <Pagination>
        <PaginationButtons onClick={previousPage} disabled={!canPreviousPage}>
            {'Previous'}
        </PaginationButtons>

        <PaginationDetails>
          Page {pageIndex + 1} of {pageOptions.length}
        </PaginationDetails>

        <PaginationButtons onClick={nextPage} disabled={!canNextPage}>
            {'Next'}
        </PaginationButtons>
      </Pagination>
      }
    </TableContainer>
  );
}

export default class VehicleTable extends Component {
  render() {
 
    return (
      <Table
        className="vehicle-table"
        data={this.props.data}
        columns={this.props.columns}
        noDataMessage={this.props.noDataMessage}
      />
    );
  }
}
