import React from "react";

export const GraphExplanation = (props) => {
  if (props.graphType === "Monthly")
    return (
      <div className="nrel-graph-explanation">
        <p>
          Peak demand is the highest average electricity demand over a 15-minute
          period. Utilities use peak demand to determine the demand charge each
          month. Demand charges can be a substantial component of commercial
          electricity bills. Understanding how EV charging may impact the demand
          at your facilities will allow you to identify opportunities to shift
          charging so that it is not coincident with your facility’s existing
          peak demand. For more information, please see page 20 of{" "}
          <a
            href="https://afdc.energy.gov/files/u/publication/evse_cost_report_2015.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            this report
          </a>
          .
        </p>
      </div>
    );

  if (props.graphType === "Daily")
    return (
      <div className="nrel-graph-explanation">
        <p>
          The charging analysis identifies vehicles that could charge at this
          location overnight. This primarily includes vehicles for which this is
          their primary parking location. However, when applicable, the daily
          charging demand curve also includes the charging for vehicles that are
          parked there for just that night.
        </p>
      </div>
    );
};
