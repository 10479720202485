import React, { useEffect, useCallback, useState, useMemo } from "react";
import InstallVisual from "./install-visual";
import InstallSummary from "./install-summary";
import InstallToggle from "./install-toggle";
import StandardizeTableData from "./install-standardizeData";
import InstallTable from "./install-table";
import { getInstallPercents } from "./install-api-requests";
import { CSVLink } from "react-csv";
import Search from "./search";
export default function InstallTableView(props) {
  const {
    showVehicleList,
    setShowVehicleList,
    vclData,
    reqObject,
    group,
    groupInstalls,
  } = props;

  const [filteredVclData, setFilteredVclData] = useState([]);
  const tableData = useMemo(() => {
    if (showVehicleList) {
      return StandardizeTableData(filteredVclData, "group");
    } else {
      return StandardizeTableData(props.vclData, "fleet");
    }
  }, [showVehicleList, filteredVclData, props.vclData]);

  const [targetTotals, setTargetTotals] = useState({
    groupName: "-",
    targetInstallsComplete: "-",
    targetInstallsPercComplete: "-",
    actualInstallsPercComplete: "-",
    averageTargetPerDay: "-",
    averageInstalls: "-",
    targetInstalls: "-",
    installed: "-",
  });

  const groupName = () => {
    if (vclData.length === 1) {
      if (vclData[0].groupNameUser === null) return vclData[0].groupId;
      else return vclData[0].groupNameUser;
    } else if (vclData.length > 1) {
      var groupCompany = vclData.filter((g) => g.groupId === "GroupCompanyId");
      if (
        groupCompany[0].groupNameUser !== null ||
        groupCompany[0].groupNameUser !== undefined
      ) {
        return groupCompany[0].groupNameUser;
      } else return groupCompany.groupId;
    } else return "-";
  };
  const getTargetTotals = useCallback(() => {
    getInstallPercents(reqObject, group, calculateTotals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqObject, group, setTargetTotals]);

  function calculateTotals(resp) {
    var targetInstalls = resp.targetInstalls;
    if (resp.installedAPI > targetInstalls) targetInstalls = resp.installedAPI;
    var remainingDOW = resp.totalDOW - resp.elapsedDOW;
    var installsToCatchUp = (targetInstalls - resp.installedAPI) / remainingDOW;
    var targetInstallsPerDay = Math.round(targetInstalls / resp.totalDOW);
    if (isNaN(targetInstallsPerDay) || targetInstallsPerDay === Infinity)
      targetInstallsPerDay = 0;
    var actualInstallsPerDay = Math.round(resp.installedAPI / resp.elapsedDOW);
    if (isNaN(actualInstallsPerDay) || actualInstallsPerDay === Infinity)
      actualInstallsPerDay = 0;
    var targetInstallsComplete = targetInstallsPerDay * resp.elapsedDOW;
    if (targetInstallsComplete > targetInstalls)
      targetInstallsComplete = targetInstalls;
    var targetPercent = Math.round(
      (targetInstallsComplete / targetInstalls) * 100
    );
    if (targetPercent > 100) targetPercent = 100;
    var actualPercent = Math.round((resp.installedAPI / targetInstalls) * 100);
    if (actualPercent > 100) actualPercent = 100;
    if (actualPercent === 100 && targetPercent < 100) {
      targetPercent = 100;
      targetInstallsComplete = resp.installedAPI;
    }

    setTargetTotals({
      groupName: groupName(),
      targetInstalls: targetInstalls,
      noShows: resp.noShow,
      installed: resp.installedAPI,
      targetInstallsComplete: targetInstallsComplete,
      targetInstallsPercComplete: targetPercent,
      actualInstallsPercComplete: actualPercent,
      averageTargetPerDay: targetInstallsPerDay,
      averageInstalls: actualInstallsPerDay,
      installsToCatchUp: installsToCatchUp,
      remainingDOW: remainingDOW,
      //
    });
    // });
  }
  useEffect(() => {
    getTargetTotals();
  }, [getTargetTotals]);

  const missingHeartbeats = useMemo(
    () =>
      filteredVclData.filter(
        (v) =>
          v.heartbeat === null || v.possibleIssues === "No Data from Device"
      ),
    [filteredVclData]
  );
  const sterilizedCSVData = useMemo(
    () => StandardizeTableData(missingHeartbeats, "heartbeatsCsv"),
    [missingHeartbeats]
  );
  return (
    <div>
      <div className="install-item-wrapper">
        <InstallVisual totals={targetTotals} />
        <InstallSummary targetTotals={targetTotals} />
        {missingHeartbeats.length > 0 && (
          <div className="install-download-btn">
            <CSVLink
              data={sterilizedCSVData.data}
              headers={sterilizedCSVData.columns}
              target="_blank"
              filename={`Sawatch-Labs-Install-Tracker-Missing-Heartbeats.csv`}
            >
              <p>Download Missing Heartbeat CSV</p>
            </CSVLink>
          </div>
        )}
        <div className="table-wrapper">
          <InstallToggle
            toggleValue={showVehicleList}
            toggleChange={setShowVehicleList}
          />

          {showVehicleList && (
            <Search
              allValues={groupInstalls}
              setMatchingValues={setFilteredVclData}
            />
          )}
          <div className="install-table-wrapper">
            <InstallTable
              updateModal={props.updateModal}
              columns={tableData.columns}
              data={tableData.data}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
