import React from "react";
import ezevLogo from "../../images/ezev-logo.png";
import nrelLogo from "../../images/nrel-logo.png";
import sawatchLogo from "../../images/sawatch-logo.png";

export default function Header(props) {
  return (
    <div className="nrel-title-container">
      {props.dashType === "ste" ? (
        <h1 className="nrel-dash-title">NREL State EV Charging Analyisis</h1>
      ) : (
        <h1 className="nrel-dash-title">
          NREL University Net-Zero Analysis – Fleet
        </h1>
      )}
      <h2 className="nrel-dash-title2">Powered by:</h2>
      <a
        className="nrel-link"
        href="https://sawatchlabs.com/ezev.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="nrel-pwrd-by-image" alt="ezev" src={ezevLogo}></img>
      </a>
      <a href="https://www.nrel.gov" target="_blank" rel="noopener noreferrer">
        <img className="nrel-nrel-logo" alt="NREL Logo" src={nrelLogo}></img>
      </a>
      <a
        href="https://www.sawatchlabs.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="nrel-sawatch-logo"
          alt="Sawatch Labs Logo"
          src={sawatchLogo}
        ></img>
      </a>
    </div>
  );
}
