import styled from "styled-components/macro"


export const LoadingContainer = styled.div`
width: 20vw;
height: 20vh;
`


export const AboutLayout = styled.div`
font-family: "Arimo", "Roboto";
width: 1076px;

`

export const AboutTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: #4A4A4A;
`

export const AboutSubtitle = styled.h2`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 35px;
color: #4A4A4A;
`

export const AboutCopy = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #74787D;
padding-top: 10px;
padding-bottom: 10px;
text-decoration: none;
`

export const EmailLink = styled.a`
font-family: "Arimo", "Roboto";
text-decoration: none;
color: var(--ezev-bluish);
&:hover {
    text-decoration: underline;
}
`

export const AboutTableTitle = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-size: 18px;
padding-bottom: 10px;
color: #74787D;
`

export const AboutTable = styled.table`
font-family: "Arimo", "Roboto";
text-align: left;
color: #4A4A4A;
border-spacing: 0;
border-collapse: collapse;
`

export const AboutTableHead = styled.thead`
font-family: "Arimo", "Roboto";
background-color: var(--ezev-light-sky-blue);
`

export const AboutTableTr = styled.tr`
font-family: "Arimo", "Roboto";
height: 50px;
`

export const AboutTableTh = styled.th`
font-family: "Arimo", "Roboto";
padding: 0 20px;
border: solid 1px #74787D;
`

export const AboutTableBody = styled.tbody`
font-family: "Arimo", "Roboto";
color: #4A4A4A;
`

export const AboutTableTd = styled.td`
font-family: "Arimo", "Roboto";
padding: 0 20px;
border: solid 1px #74787D;
`


export const TabRow = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 37px;
`


export const Tab = styled.span`
display: flex;
align-items: center;
box-sizing: border-box;
padding: 5px 1rem;
height: 38px;
border-radius: 10px 10px 0 0;
border-width: 1px 1px 0 1px;
border-color: var(--ezev-dark-grey);
cursor: pointer;
border-style: solid;
${({ selected }) => (
        selected ? `
        background-color: var(--white);
        color: var(--ezev-dark-grey);
       `
            : `background-color: var(--ezev-light-grey);
        color: var(-ezev-dark-grey);
        border-width: 1px;`
    )}
`


export const TabInfo = styled.div`
width: 100%;
padding: 15px;
display: inline-flex;
flex-direction: column;
align-items: flex-start;
border-style: solid;
border-width: 0 1px 1px 1px;
box-sizing: border-box;
overflow: auto;
border-color: var(--ezev-dark-grey);
flex-wrap: wrap;
`
export const RatesTabInfo = styled(TabInfo)`
padding: 15px;`


export const TabFill = styled.div`
flex: 1;
height: 37px;
border-bottom: 1px solid var(--ezev-dark-grey);`