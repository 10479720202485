import React, { useEffect, useState } from "react";
import { getLocationVehiclesByPerc } from "./fetchCalls";
import { CSVLink } from "react-csv";
import StandardizeTableData, {
  tableVehicleHeaders,
  csvVehicleHeaders,
} from "./standardizeTableData";
import Table from "./table";

export default function LocationVehicleTable(props) {
  const [locVcls, setLocVcls] = useState([]);
  useEffect(() => {
    var { start } = props;
    var { stop } = props;
    if (
      props.selectedMonth &&
      props.selectedMonth !== undefined &&
      typeof props.selectedMonth === "string"
    ) {
      const b = props.selectedMonth.split(",");
      start = b[0];
      stop = b[1];
    }
    getLocationVehiclesByPerc(
      props.requestData,
      {
        percent: props.selectedPercent,
        locId: props.selectedLocation,
        showEzevResults: props.showEzevResults,
        bounds: props.selectedMonth,
        start: start,
        stop: stop,
      },
      (vcls) => setLocVcls(StandardizeTableData(vcls, "vehicles"))
    );
  }, [
    props,
    props.requestData,
    props.selectedLocation,
    props.selectedMonth,
    props.selectedPercent,
    props.showEzevResults,
    props.start,
    props.stop,
  ]);

  const tableTitle = () => {
    if (props.showEzevResults)
      return (
        <h3 className="nrel-location-vehicle-title">ezEV Recommendations</h3>
      );
    else
      return (
        <h3 className="nrel-location-vehicle-title">
          EV Candidates at{" "}
          <span className="nrel-underline">{props.selectedPercent}%</span> Fleet
          Electrification
        </h3>
      );
  };

  return (
    <div className="nrel-location-vehicle-wrapper">
      {tableTitle()}
      <div className="nrel-vehicle-table-wrapper">
        <Table data={locVcls} columns={tableVehicleHeaders()} />
      </div>
      <CSVLink
        className="nrel-download-btn"
        id="ezevRecs"
        data={locVcls}
        headers={csvVehicleHeaders()}
        target="_blank"
        filename={`sawatch-labs-NREL-${props.selectedPercent}%-vcls-table.csv`}
      >
        <p>Download CSV</p>
      </CSVLink>
    </div>
  );
}
