import React, { Suspense, useEffect, useState } from "react";
import { decideVinDisplay, validateAPIResponse, vehicleIdDisplay, dateAsNonTZString } from "./UtilityFunctions";
import { processApiResponse } from "./utils/ConformUnits";
import Loading from "./Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// code split imports
const TableRenderer = React.lazy(() => import('./TableRenderer'));

const TableController = (props) => {
  const [vclData, setVclData] = useState([]);
  const [reimbursementData, setReimbursementData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  useEffect(() => {
    setLoading(true);
    setVclData([]); // remove old data when URL or date range changes, so the table doesn't flash with a screen of the incorrect data
    setReimbursementData([]);
    if (props.match.path === "/ionev/reimbursement/:locId") {
        const locFromUrl = props.match.params.locId;
        if (props.reimbursementSummary.some(l => l.pkid === parseInt(locFromUrl))) {
          fetchSingleLocReimbursement(locFromUrl);
        } else {
          console.error('An invalid location ID was provided to the table! Redirecting to landing page.')
          history.push("/ionev");
        }
    } else if (props.match.path === "/ionev/driving-activity/:vin") {
        const vinFromUrl = props.match.params.vin;
        if (props.totalActivitySummary.some(v => v.vin === vinFromUrl)) {
          fetchVclDailyActivity(vinFromUrl);
        } else {
          console.error('An invalid VIN was provided to the table! Redirecting to landing page.')
          history.push("/ionev");
        }
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match, props.startDate, props.endDate]);

  const fetchVclDailyActivity = (vin) => {
    const startDate = props.startDate;
    const endDate = props.endDate;
    const singleVclUrl = `${props.base_url}getTotalActivity?`;
    const queries = `clientId=${props.dbName}&vin=${vin}&start=${dateAsNonTZString(startDate)}&stop=${dateAsNonTZString(endDate)}`;

    fetch(`${singleVclUrl}${queries}`, {
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        let formattedData = data['data'].map((v) => {
          v.asset_id = vehicleIdDisplay(v);
          v.display_vin = decideVinDisplay(v);
          return v;
        });
        formattedData = formattedData.map((i) => processApiResponse(props.user.userSettings, i));
        setVclData(formattedData);
        setLoading(false);
      })
      .catch((error) => console.error("Error: " + error));
  };

  const fetchSingleLocReimbursement = (locId) => {
    const startDate = props.startDate;
    const endDate = props.endDate;
    const totalSummaryUrl = `${props.base_url}getReimbursementForLocation?`;
    const queries = `&clientId=${props.dbName}&start=${dateAsNonTZString(startDate)}&stop=${dateAsNonTZString(endDate)}&locId=${locId}`;

    fetch(`${totalSummaryUrl}${queries}`, {
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        const reimbursementData = validateAPIResponse(data, false, []);
        const formattedData = reimbursementData.map((i) => processApiResponse(props.user.userSettings, i));
        setReimbursementData(formattedData);
        setLoading(false);
      })
      .catch((error) => console.error("Error: " + error));
  };

  const sortEventsForVcl = (selectedVcl, data) => {
    return data?.filter((e) => e.vin === selectedVcl) || [];
  };

  const combineSummaryData = () => {
    const charge = props.chargeSummaryByLocation;
    const missed = props.missedSummaryByLocation;
    let locs = [];

    charge.forEach((c) => {
      // see if missed opp at charge loc
      const missedLoc = missed.find((m) => m.charge_loc_id === c.pkid);
      if (missedLoc) {
        // add to charge obj
        Object.assign(c, missedLoc);
      } else {
        Object.assign(c, {
          missed_distinct_vins: "-",
          missed_event_count: "-",
          missed_avg_duration: "-",
          missed_avg_kwh: "-",
          missed_avg_soc_start: "-",
          avg_missed_elec_km: "-",
          missed_avg_distance: "-",
        });
      }
      locs.push(c);
    });

    missed.forEach((m) => {
      // see if missed opp at charge loc
      if (!charge.some((c) => m.charge_loc_id === c.pkid)) {
        // add location if missed opp not added in above loop
        Object.assign(m, {
          pkid: m.charge_loc_id,
          charge_event_count: "-",
          charge_avg_kwh: "-",
          charge_distinct_vins: "-",
        });
        locs.push(m);
      }
    });

    return locs;
  };

  const selectTableData = () => {
    if (props.match.path === "/ionev/driving-activity/:vin") {
      return vclData;
    } else if (props.match.path === "/ionev/driving-activity") {
      return props.totalActivitySummary;
    } else if (props.match.path === "/ionev/charging-events/:vin") {
      return sortEventsForVcl(props.match.params.vin, props.chargeEvents);
    } else if (props.match.path === "/ionev/charging-events") {
      return props.chargeSummaryByVcl;
    } else if (props.match.path === "/ionev/missed-opportunities/:vin") {
      return sortEventsForVcl(props.match.params.vin, props.missedEvents);
    } else if (props.match.path === "/ionev/missed-opportunities") {
      return props.missedSummaryByVcl;
    } else if (props.match.path === "/ionev/events-by-location") {
      return combineSummaryData();
    } else if (props.match.path === "/ionev/tco-comparison") {
      return props.tcoDataTotal;
    } else if (props.match.path === "/ionev/reimbursement/:locId") {
      return reimbursementData;
    } else if (props.match.path === "/ionev/reimbursement") {
      return props.reimbursementSummary;
    }
  };

  const data = selectTableData();

  if (loading) return <Loading/>; // Or you can use a proper loading spinner component here
  return (
    <Suspense fallback={<></>}>
      <TableRenderer
        activity={data}
        path={props.match.path}
        period={[props.startDate, props.endDate]}
        fuelCost={props.fuelCost}
        dbName={props.dbName}
        dbDisplayName={props.dbDisplayName}
        user={props.user}
        formattedStartDate={props.formattedStartDate}
        formattedEndDate={props.formattedEndDate}
      />
    </Suspense>
  );
};

export default TableController;