import React from "react";
import DevTools from "./core-components/dev-tool-components/DevTools";
import "../component-css/core-stylesheets/dev-tools.css";
import "leaflet/dist/leaflet.css";

export default function DashboardCore(props){
  const secrets = require("../settings");
  return(
    <DevTools 
      {...props}
      secrets={secrets}
    />
  )
}
