import React from 'react';
import Loading from './loading';

import {
  numberWithCommas,
  convertKMtoMiles,
  humanFriendlyTime
} from './DataFormatter';
export default function DutyCycleVisualization(props) {
  const { segmentTimeDelta } = props;
  const { vehicleUtil } = props;
  function setDeselectedDayIndex(i) {
    switch (segmentTimeDelta) {
      case 86400:
        return 1;
      case 43200:
        return 2;
      case 28800:
        return 3;
      case 21600:
        return 4;
      case 3600:
        return 24;
      default:
        return null;
    }
  }

  const sortedVcls = vehicleUtil.sort((a, b) =>
    a.avgUtilization < b.avgUtilization ? 1 : -1
  );

  const loadingLogic = () => {
    if (segmentTimeDelta.length > 0) {
      return (
        <p className="sftd-noData-Msg">
          There are currently no results to be displayed. Please adjust your
          filters above to continue.
        </p>
      );
    }
  };
  return (
    <div className="sftd-table-wrapper">
      <div className="sftd-table-legend">
        <p className="cell-title">Duty Cycle Details</p>
        <div className="icon-wrapper">
          <div className="cellIcon-inactive">
            <span className="dot"></span>
            <p className="cell-text">Inactive</p>
          </div>
          <div className="cellIcon-active">
            <span className="dot"></span>
            <p className="cell-text">Active</p>
          </div>
          <div className="cellIcon-utilization">
            <span className="dot"></span>{' '}
            <p className="cell-text">100% Cap</p>
          </div>
          <div className="cellIcon-conflict">
            <span className="dot"></span>{' '}
            <p className="cell-text">&gt;100% Cap</p>
          </div>
          <div className="cellIcon-dead">
            <span className="dot"></span>{' '}
            <p className="cell-text">No Data</p>
          </div>
        </div>
      </div>
      {segmentTimeDelta.length < 1 ||
      vehicleUtil.length < 1 ||
      props.dutySegments.length < 1 ? (
        loadingLogic()
      ) : (
        <>
          <div className="sftd-duty-cycles-details-wrpr">
            <div className="sftd-dc-clm1">
              <h4 className="sftd-details-title">ShiftED Results</h4>
              <p className="sftd-details-item">
                Average utilization:{' '}
                <span className="sftd-value">
                  {props.loading ? '-' : props.currentAverage}%
                </span>
              </p>
              <p className="sftd-details-item">
                Average utilization (selected):{' '}
                <span className="sftd-value">
                  {props.loading
                    ? '-'
                    : Math.round(props.avgTotalUtil.avgSelectedUtil * 100)}
                  %
                </span>
              </p>
            </div>
            <div className="sftd-dc-clm2">
              <p className="sftd-details-item">
                Average utilization (filters):{' '}
                <span className="sftd-value">
                  {props.loading
                    ? '-'
                    : Math.round(props.avgTotalUtil.avgFilteredUtil * 100)}
                  %
                </span>
              </p>
              <p className="sftd-details-item">
                Unserviced Cycles:{' '}
                <span
                  className={
                    props.nonServicedCycles === 0
                      ? 'sftd-value'
                      : 'sftd-value-active'
                  }
                >
                  {props.loading ? ' - ' : props.nonServicedCycles}
                </span>
              </p>
            </div>
            <div className="sftd-dc-clm3">
              <p className="sftd-details-item">
                Time spent at 100% capacity:{' '}
                <span
                  className={
                    props.hoursAtCapacity === 0
                      ? 'sftd-value'
                      : 'sftd-value-capacity'
                  }
                >
                  {props.loading
                    ? ' - '
                    : Math.round(
                        (props.hoursAtCapacity / props.totalHours) * 100
                      )}
                  %
                </span>
              </p>
              <p className="sftd-details-item">
                Segments at 100% capacity:{' '}
                <span
                  className={
                    props.hoursAtCapacity === 0
                      ? 'sftd-value'
                      : 'sftd-value-capacity'
                  }
                >
                  {props.loading ? ' - ' : props.capacitySegments.atCapacity}
                </span>
              </p>
            </div>
            <div className="sftd-dc-clm4">
              <p className="sftd-details-item">
                Time spent at {'>'} 100% capacity:{' '}
                <span
                  className={
                    props.nonServicedCycles === 0
                      ? 'sftd-value'
                      : 'sftd-value-active'
                  }
                >
                  {props.loading
                    ? ' - '
                    : Math.round(
                        (props.hoursAboveCapacity / props.totalHours) * 100
                      )}
                  %
                </span>
              </p>
              <p className="sftd-details-item">
                Segments at {'>'} 100% capacity:{' '}
                <span
                  className={
                    props.hoursAboveCapacity === 0
                      ? 'sftd-value'
                      : 'sftd-value-active'
                  }
                >
                  {props.loading ? ' - ' : props.capacitySegments.aboveCapacity}
                </span>
              </p>
            </div>
          </div>
          {/* Add loading logic  */}
          {props.loading ? (
            <Loading background={false} />
          ) : (
            <div className="sftd-outerWrapper">
              <div className="sftd-label-blinder-left" />
              <div className="sftd-label-blinder-right" />
              <div className="sftd-label-blinder-top" />
              <hr className="sftd-dividing-line"></hr>
              <div className="sftd-dctable-wrapper">
                <table className="sftd-table">
                  <thead>
                    <tr className={`sftd-table-header-${segmentTimeDelta}`}>
                      <th className="sftd-table-toggle"></th>
                      <th className="headcol-text" id="vcl">
                        <span className="sftd-label">Vehicle</span>
                        {/* <span className="headcol-text-gry">Asset ID</span> */}
                      </th>
                      <th className="headcol-text" id="util">
                        <span className="sftd-label">Active</span>
                      </th>
                      <th className="headcol-text" id="miles">
                        <span className="sftd-label">Odometer</span>
                      </th>
                      {props.dutySegments.map((s, idx, arr) => {
                        return s !== -1 ? (
                          <th
                            className={`sftd-th-${segmentTimeDelta}`}
                            key={idx}
                          >
                            <span className="sftd-date-container">
                              {humanFriendlyTime(s)}
                            </span>
                          </th>
                        ) : (
                          <th
                            className={`sftd-deselected-${segmentTimeDelta}`}
                            key={idx}
                          >
                            <span className="sftd-deselected">
                              {humanFriendlyTime(
                                arr[idx - setDeselectedDayIndex(arr[idx - 1])],
                                true
                              )}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="sftd-body">
                    {sortedVcls.map(vu => {
                      return (
                        <tr
                          key={vu.vin}
                          className={`sftd-table-row-${segmentTimeDelta}`}
                        >
                          <td
                            className={
                              vu.selected
                                ? 'sftd-table-toggle'
                                : 'sftd-table-unselected-toggle'
                            }
                            onClick={() =>
                              props.handleVehicleSelect({
                                vin: vu.vin,
                                vehicle_class: vu.vehicle_class
                              })
                            }
                          >
                            <div className="sftd-dv-icon"></div>
                          </td>
                          <td
                            className={
                              vu.selected
                                ? 'sftd-ymm-cell'
                                : 'sftd-ymm-unselected-cell'
                            }
                            id="ymm"
                          >
                            {`${vu.year} ${vu.make} ${vu.model}`}
                            <br></br>
                            <span className="sftd-cell-assetId">{`${vu.assetID}`}</span>
                          </td>
                          <td
                            className={
                              vu.selected
                                ? 'sftd-ymm-cell'
                                : 'sftd-ymm-unselected-cell'
                            }
                            id="util"
                          >
                            {Math.round(vu.avgUtilization * 100) > 1
                              ? Math.round(vu.avgUtilization * 100)
                              : '<1'}
                            %
                          </td>
                          <td
                            className={
                              vu.selected
                                ? 'sftd-ymm-cell'
                                : 'sftd-ymm-unselected-cell'
                            }
                            id="miles"
                          >
                            {vu.odomKM === undefined
                              ? '-'
                              : numberWithCommas(
                                  Math.round(convertKMtoMiles(vu.odomKM))
                                )}
                          </td>
                          {vu.utilization.map((u, idx) => {
                            if(u.dc === 0)return(<td key={idx} className={`stfd-deadCell`}></td>);
                            const arr = props.fullCapacity.filter(
                              fc => fc.dc === idx
                            );
                            if (arr.length < 1) {
                              if (u.value === 1) {
                                return (
                                  <td
                                    className={
                                      vu.selected
                                        ? `sftd-activeCell`
                                        : `sftd-activeCell-unselected`
                                    }
                                    key={idx}
                                  ></td>
                                );
                              } else if (u.value === 0) {
                                if (u.ts !== -1)
                                  return (
                                    <td
                                      className={
                                        vu.selected
                                          ? `sftd-inactiveCell`
                                          : `sftd-inactiveCell-unselected`
                                      }
                                      key={idx}
                                    ></td>
                                  );
                                else
                                  return (
                                    <td
                                      className={`sftd-deselectedCell`}
                                      key={idx}
                                    ></td>
                                  );
                              }
                            } else {
                              var capObj = arr[0];
                              if (capObj.capacity === 1) {
                                return (
                                  <td
                                    className={
                                      vu.selected
                                        ? `sftd-utilizationCell`
                                        : `sftd-utilizationCell-unselected`
                                    }
                                    key={idx}
                                    // onMouseOver={event => openModal(event, vu.vin)}
                                    // onMouseOut={closeModal}
                                  ></td>
                                );
                              } else {
                                return (
                                  <td
                                    className={
                                      vu.selected
                                        ? `sftd-conflictCell`
                                        : `sftd-conflictCell-unselected`
                                    }
                                    key={idx}
                                    // onMouseOver={event => openModal(event, vu.vin)}
                                    // onMouseOut={closeModal}
                                  ></td>
                                );
                              }
                            }
                            return '';
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
