import React, { useCallback } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { useHistory } from "react-router-dom";
import arrowUp from '../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../images/ic-arrow-unsort-4px-9px.gif';
import { DefaultColumnFilter } from "./speedn-table-filter";
import { formatCellData } from "./speedn-table-helper-functions";
import { execExcelDownload } from "./speedn-csv-helper-functions";


export default function SpeednDetailTable(props){
  const { columns, data, filter, setIncidentParent, parent, beginDate, endDate, group, vehicleClasses, excessSpeed, minSpeed, minDuration, tableType, csvFilename, dbDisplayName } = props;
  const history = useHistory();

  const handleOnClick = useCallback((vin, ts) => {
    setIncidentParent(parent);
    history.push(`/speedn/incidentdetail/${vin}/${ts}`);
  }, [history, parent, setIncidentParent]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const colType = (col) => {
    if(col === 'Time/Date' || col === 'Model'){
      return "speedn-table-header-long";
    }
    return "speedn-table-header";
  }

  const el = document.getElementById('speedn-table-explain');
  if(el)el.style.display = 'block';

  return(
    <>
     <div className='speedn-table-info-wrapper'>
       <div className='speedn-explain-text-wrapper'>
         {tableType === 'Vehicle' && <span className='speedn-explain-text-2'>Vehicle speeding incident details.</span>}
         {tableType === 'Detail' && <span className='speedn-explain-text-2'>Details of each speeding incident.</span>}
         <br />
        <span className="speedn-explain-text">Click a column name to sort the table. Click a row for details.</span>
      </div>
      <div className="speedn-table-download-link-wrapper">
        <button onClick={()=>execExcelDownload({
          "tableType": tableType,
          "beginDate": beginDate,
          "endDate": endDate,
          "group": group,
          "vehicleClasses": vehicleClasses,
          "excessSpeed": excessSpeed,
          "minSpeed": minSpeed,
          "minDuration": minDuration,
          "columns": columns,
          "data": data,
          "filter": filter,
          "dbDisplayName": dbDisplayName,
          "filename": csvFilename + '.xlsx'})} className="speedn-download-btn">Download</button>
    </div>

    </div>

    <div className='table-container'>
      <table {...getTableProps()}
        // onClick={function(e){props.handleSort(e.target.outerText, props.desc)}}
      >
        
        <thead className='table-header-container'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} className={colType(column.Header)}>
                 <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span className="speedn-table-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <img src={ arrowDown } className="speedn-table-sort-down-arrow" alt="Column sorted in descending order" />
                          : <img src={ arrowUp } className="speedn-table-sort-up-arrow" alt="Column sorted in ascending order" />
                        : <img src={ arrowUnsel } className="speedn-table-sort-both-arrow" alt="Unsorted column" />}
                    </span>
                  </div>
                  <div className="table-searchbar">{column.Header === 'Asset Id' ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr className='speedn-table-row clickable-row' {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="speedn-table-cell speedn-link"
                      onClick={function(){handleOnClick(cell.row.original.vin, cell.row.original.ts)}}
                      {...cell.getCellProps()} id={cell.vin}>
                      {formatCellData(cell, row.original)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
        {rows.length < 1 
        ?
        <p className="speedn-no-data-msg">No data to display. Try adjusting date range or filters.</p> 
        :
      <div className='table-pagination-container'>
        <button
          className="pagination-button"
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </button>
        <span className='pagination-details'>Page {pageIndex + 1} of {pageOptions.length}</span>

        <button
        className="pagination-button"
        onClick={nextPage} 
        disabled={!canNextPage}>
        Next
      </button>
      </div>
}
    </>
    );
   
}

