import React from "react";

export default function About(props) {
  const steBlurb = {
    ph1:
      "As state fleets are increasing adoption of electric vehicles (EVs), many fleet and facility managers are looking to better understand the potential impact that the increased charging demand will have on their electricity demand. This project was undertaken to project what the charging demand could be at increasing levels of fleet electrification based on the actual driving of vehicles today. The participating state fleets provided the project team with access to telematics data from their vehicles. The team analyzed the detailed driving for each vehicle to determine when an EV could meet the operational and economic needs of each vehicle. Based on that analysis, the projected charging demand at each location and increasing levels of fleet electrification was determined. This analysis identifies peak demand at each location, seasonal demand differences, and how increasing levels of electrification will impact peak demand at each location. Fleet and facility managers can use this analysis to plan infrastructure to meet near- and long-term charging demand.",
    // ph2:
    //   "The analysis was completed by Sawatch Labs under contract to the National Renewable Energy Laboratory, operated by the Alliance for Sustainable Energy, LLC, for the U.S. Department of Energy (DOE). Funding for this project was provided by the U.S. DOE Office of Energy Efficiency and Renewable Energy’s Vehicle Technologies Office under contract No. XXXXXXX. The views expressed herein do not necessarily represent the views of the U.S. DOE or the U.S. Government.",
  };

  const uniBlurb = {
    ph1:
      "Universities around the country are adopting sustainability targets that include goals for fleet operations. This project was undertaken to help universities and other campus fleets determine what role electric vehicles (EVs) can play in meeting their net-zero energy and other sustainability goals. The participating universities provided the project team with access to telematics data from their vehicles. The team analyzed the detailed driving for each vehicle to determine when an EV could meet the operational and economic needs of each vehicle.",
    // ph2:
    //   "The analysis was completed by Sawatch Labs under contract to the National Renewable Energy Laboratory, operated by the Alliance for Sustainable Energy, LLC, for the U.S. Department of Energy (DOE). Funding for this project was provided by the U.S. DOE Office of Energy Efficiency and Renewable Energy’s Vehicle Technologies Office under contract No. XXXXXXX. The views expressed herein do not necessarily represent the views of the U.S. DOE or the U.S. Government. ",
  };

  const selected = props.dashType === "ste" ? steBlurb : uniBlurb;

  return (
    <div>
      <h3 className="nrel-about" id="title">
        Project Summary
      </h3>
      <p className="nrel-about" id="ph1">
        {selected.ph1}
      </p>
      <br />
      <p className="nrel-about" id="ph1">
        {selected.ph2}
      </p>
      <h4 className="nrel-about" id="contact-header">
        Contact information
      </h4>
      <p className="nrel-about" id="company">
        Sawatch Labs
      </p>
      <p className="nrel-about" id="name">
        Sarah Booth, COO
      </p>
      <a className="nrel-about" id="email" href="mailto:booth@sawatchlabs.com">
        booth@sawatchlabs.com
      </a>
      <p className="nrel-about" id="company">
        National Renewable Energy Laboratory
      </p>
      <p className="nrel-about" id="name">
        Jesse Bennett
      </p>
      <a className="nrel-about" id="email" href="mailto:booth@sawatchlabs.com">
        Jesse.Bennett@nrel.gov
      </a>
      <div className="additional-resources">
        <h4>Additional Resources:</h4>
        <p className="section-title">The U.S. Department of Energy’s </p>{" "}
        <a
          id="link"
          className="section-title"
          href="https://afdc.energy.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Alternative Fuels Data Center
        </a>
        <ul>
          <li>
            <a
              href="https://afdc.energy.gov/vehicles/electric.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Electric Vehicles
            </a>
          </li>
          <li>
            <a
              href="https://afdc.energy.gov/laws/search"
              target="_blank"
              rel="noopener noreferrer"
            >
              Financial incentives available by location
            </a>
          </li>
          <li>
            <a
              href="https://afdc.energy.gov/fuels/electricity_infrastructure.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              EV charging infrastructure
            </a>
          </li>
          <li>
            <a
              href="https://afdc.energy.gov/fuels/electricity_stations.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Public EV charging locations
            </a>
          </li>
        </ul>
        <p className="section-title">
          EV resources developed for the federal government
        </p>
        <ul>
          <li>
            <a
              href="https://youtu.be/UgUAfU3rCHU"
              target="_blank"
              rel="noopener noreferrer"
            >
              EV technology overview
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=Xdi7T_rz75Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              EV financial considerations
            </a>
          </li>
          <li>
            <a
              href="https://youtu.be/TjKwOhKBtNc"
              target="_blank"
              rel="noopener noreferrer"
            >
              EV charging infrastructure
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
