import React, { Component } from "react";
import { useTable, useSortBy } from "react-table7";

function Table(props) {
  const { columns, data } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "heartbeatsShow",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );
  return (
    <table {...getTableProps()} className="install-table">
      <thead id="install-headers">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} id="instl-header-row">
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                id="instl-header"
              >
                {column.render("Header")}{" "}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} id="instl-row">
              {row.cells.map((cell) => {
                return (
                  <td
                    onClick={
                      row.original.deviceId
                        ? null
                        : () => props.updateModal(row.original)
                    }
                    {...cell.getCellProps()}
                    id={
                      cell.column.id === "dptName" ||
                      cell.column.id === "ttlVcls"
                        ? "instl-cell-unselected"
                        : !row.original.deviceId
                        ? "instl-cell"
                        : "device-cell"
                    }
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default class InstallTable extends Component {
  render() {
    return (
      <Table
        data={this.props.data}
        columns={this.props.columns}
        updateModal={this.props.updateModal}
      />
    );
  }
}
