import styled from "styled-components/macro";
import * as colors from "./ColorScheme";
import { Link } from "react-router-dom";


export const DisplayLayout = styled.div`
  position: relative;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
`

export const MessageTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
margin-bottom: 10px;
color: ${colors.ezio_dark_grey};
`

export const Message = styled.span`
  font-family: "Arimo", "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.ezio_medium_grey};
`

export const HomeLink = styled(Link)`
  font-family: "Arimo", "Roboto";
  text-decoration: none;
  color: ${colors.ezio_dark_green};
  &:hover {
      color: ${colors.ezio_dark_green};
      text-decoration: underline;
  }
`