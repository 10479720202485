import React, { useCallback, useEffect } from "react";
import GraphDailyCharging from "./graphDailycharging";
import GraphMonthlyCharging from "./graphMonthlyCharging";

import { getMonthlyBounds } from "./dataFormatter";
import GraphControls from "./graphControls";
import { getPeakMonthlyKwhDemandByLocation } from "./fetchCalls";

export default function GraphController(props) {
  const [selectedGraph, setSelectedGraph] = React.useState("monthly");
  const [showEzevResults, setShowEzevResults] = React.useState(false);
  const [chargeData, setChargeData] = React.useState([]);

  const {
    setMonth,
    selectedMonth,
    requestData,
    selectedLocation,
    setSelectedLocation,
    start,
    stop,
    locations,
    selectedPercent,
    setPercent,
  } = props;

  const getMonthlyChargingData = useCallback(() => {
    if (
      selectedLocation === "" ||
      selectedLocation === null ||
      start === undefined ||
      stop === undefined
    )
      return;
    let options = {
      locs: selectedLocation,
      bounds: [start, stop],
      percent: selectedPercent,
      showEzevResults: showEzevResults,
    };
    getPeakMonthlyKwhDemandByLocation(
      requestData,
      (resp) => sortChargeData(resp),
      options
    );
  }, [
    selectedLocation,
    start,
    stop,
    selectedPercent,
    requestData,
    showEzevResults,
  ]);

  // Update graph based on graph controls
  useEffect(() => {
    getMonthlyChargingData();
  }, [getMonthlyChargingData, selectedPercent, selectedLocation]);

  const months = getMonthlyBounds(start, stop);
  function sortChargeData(data) {
    if (!data) return;
    let sorted = data.sort((a, b) => {
      let aDate = a.month.split("-");
      let bDate = b.month.split("-");
      if (parseInt(aDate[1]) > parseInt(bDate[1])) return 1;
      if (parseInt(aDate[1]) < parseInt(bDate[1])) return -1;
      else {
        if (parseInt(aDate[0]) > parseInt(bDate[0])) return 1;
        if (parseInt(aDate[0]) < parseInt(bDate[0])) return -1;
      }
      return null;
    });
    setChargeData(sorted);
    return data;
  }

  function handleGraphClick(element, orig) {
    if (element.length < 1 || element[0] === undefined) return;
    const e = element[0];
    let d = orig[e.index].month;
    let splitDate = d.split("/");
    let endDay = new Date(splitDate[1], splitDate[0], 0).getDate();
    var dateObj = new Date();
    var todayMonth = dateObj.getUTCMonth() + 1; //months from 1-12
    var todayDay = dateObj.getUTCDate();
    var todayYear = dateObj.getUTCFullYear();
    let month = splitDate[0];
    if (
      month &&
      parseInt(month) === todayMonth &&
      todayYear === parseInt(splitDate[1])
    )
      endDay = todayDay;
    if (month < 10) month = "0" + month;
    setMonth(`${splitDate[1]}-${month}-01,${splitDate[1]}-${month}-${endDay}`);
    setSelectedGraph("daily");
  }

  function handleEzevToggleChange(toggleState) {
    setShowEzevResults(toggleState);
  }

  return (
    <div className="nrel-graph-wrapper" id={selectedGraph}>
      <GraphControls
        showEzevResults={showEzevResults}
        handleEzevToggleChange={handleEzevToggleChange}
        selectedGraph={selectedGraph}
        locations={locations}
        setSelectedGraph={setSelectedGraph}
        setPercent={setPercent}
        setLocation={(loc) => setSelectedLocation(loc)}
        setMonth={(mnth) => setMonth(mnth)}
        selectedLocation={selectedLocation}
        selectedMonth={selectedMonth}
        selectedPercent={selectedPercent}
        months={months}
        start={start}
        stop={stop}
      />
      {selectedGraph === "monthly" ? (
        <GraphMonthlyCharging
          graphData={chargeData}
          graphSelector={setSelectedGraph}
          selectedMonth={selectedMonth}
          selectedPercent={selectedPercent}
          handleGraphClick={handleGraphClick}
          selectedLocation={selectedLocation}
          showEzevResults={showEzevResults}
          requestData={requestData}
          start={start}
          stop={stop}
        />
      ) : (
        <GraphDailyCharging
          showEzevResults={showEzevResults}
          selectedLocation={selectedLocation}
          locations={locations}
          selectedPercent={selectedPercent}
          requestData={requestData}
          selectedMonth={selectedMonth}
          start={start}
          stop={stop}
        />
      )}
    </div>
  );
}
