import { DateTime } from "luxon";

const INVALID_INPUTS = ['e', 'E', '+', '-']

export function validateAPIResponse(res, singular, noRes){
  if(res && res['data']) {
    if (singular && res['data'][0]){
      return res['data'][0];
    } else if (!singular) {
      return res['data'];
    } else {
      return noRes;
    }
  } else {
    return noRes;
  }
}

export function dateAsNonTZString(date) {
  // Format plain date without timezone
  if(date instanceof Date)return date.toISOString().split("T")[0];
  if(date instanceof DateTime)return date.toISO().split("T")[0];
}

export function decideVinDisplay (vcl) {
  if(vcl) {
  if(typeof vcl.user_defined_vin === "undefined" || vcl.user_defined_vin === null || vcl.user_defined_vin === 'null' || !vcl.user_defined_vin) {
    return vcl.vin
  } else {
    return vcl.user_defined_vin
  }
  }
}

export function vehicleIdDisplay (vcl) {
  if(vcl) {
    if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
    if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
    if (vcl.vin) return vcl.vin
  }
}


export function formatSimpleDateTime(value) {
  if(!value)return;
  var date = `${value.month}/${value.day}/${value.year}`;  // month day year
  return `${date}`;
}

export function lastFullMonth() {
  // last full month from current date
  var now = DateTime.utc();
  var mth = now.month;
  var yr = now.year;
  var targetMth;
  if (mth === 1) { // If Janurary, roll back to Dec of last year
    targetMth = 12;
    yr = yr-1;
  }
  else {
    targetMth = mth-1;
  }
  var targetStart = DateTime.utc(yr, targetMth, 1);
  var targetEnd = DateTime.utc(yr, mth, 1);
  return {utcStart: formatSimpleDateTime(targetStart), utcEnd: formatSimpleDateTime(targetEnd)};
}

export function getMonth(value) {
  var month;
  switch (value) {
    case "01":
    case "1":
      month = "Jan";
      break;
    case "02":
    case "2":
      month = "Feb";
      break;
    case "03":
    case "3":
      month = "Mar";
      break;
    case "04":
    case "4":
      month = "Apr";
      break;
    case "05":
    case "5":
      month = "May";
      break;
    case "06":
    case "6":
      month = "Jun";
      break;
    case "07":
    case "7":
      month = "Jul";
      break;
    case "08":
    case "8":
      month = "Aug";
      break;
    case "09":
    case "9":
      month = "Sep";
      break;
    case "10":
      month = "Oct";
      break;
    case "11":
      month = "Nov";
      break;
    case "12":
      month = "Dec";
      break;
    default:
      month = "";
  }
  return month;
}

export function roundValue(value, exp) {
  // beginings of new rounding function
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return NaN;
  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export function getMapClusterDetails(freq){
  var freqClass;
  var size;
  if(freq > .7) {
    freqClass = "large";
    size = 35;
  } else if(freq > .3) {
    freqClass = "medium";
    size = 30;
  } else {
    freqClass = "small";
    size = 25;
  }
  return  [freqClass, size];
}

export function addCommas(input){
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDateString(userSettings, month, year) {
  switch(userSettings.date_display){
    case 'dd/MM/yy':
      return `${month} ${year.slice(2)}`;
    case 'dd/MM/yyyy':
      return `${month} ${year}`;
    case 'MM/dd/yy':
      return `${month} ${year.slice(2)}`;
    case 'MM/dd/yyyy':
      return `${month} ${year}`;
    case 'yy/MM/dd':
      return `${year.slice(2)} ${month}`;
    case 'yyyy/MM/dd':
      return `${year} ${month}`;
    default:
      return `${month} ${year.slice(2)}`;
}
}

export function preventNonIntegers(e) {
  if(INVALID_INPUTS.includes(e.key)) {
    e.preventDefault()
  }
}

export function preventPasteNonIntegers(e) {
  const clipboardData = e.clipboardData;
  let data = clipboardData.getData('text').split('')
  data.forEach((l) => {
    if (INVALID_INPUTS.includes(l)) {
      e.preventDefault()
    }
  })
};