import React from 'react';

function DropdownSelector(props) {
  return (
    <select className={props.selectorName} onChange={props.handleSelectorChange} value={props.value} >
      {props.options.map((o) =>
        <option key={o.id} data-key={o.id} value={o.id}>{o.value}</option>
      )}
    </select>
  );
}

export default DropdownSelector;
