import React from "react";
import styled from "styled-components/macro";

const TextWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  width: 700px;
  text-align: left;
  margin-left: 100px;
`;

export default function Assumptions(props: any){
    return(
        <TextWrapper>
            <p>Sawatch Labs analyzed Geotab telematics data from the fleet to determine baseline fueling behavior prior to Booster service and the behavior shift post implementation.
                <br/> <br/>Five of the eight observed vehicles had available
fueling data through telemetry, while the fueling events for three HINO vehicles was derived from their GPS trace, activity, fuel usage, and Sawatch Lab’s fuel consumption model. <br/> <br/>Based on fueling data, Sawatch has identified the locations, duration, and intake volume of fueling events over the period of observation.</p>
        </TextWrapper>
    );
}