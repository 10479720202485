import React from "react";
import { useParams, useHistory } from "react-router-dom";
import IdlingMap from './idling-map';
import Header from "./idling-header";
import { tableDateFormat } from '../idling-components/idling-csv-helper-functions';
import { formatDuration, vehicleIdDisplay } from '../idling-components/idling-table-helper-functions';

export default function IncidentDetail(props){
  const { vin, ts } = useParams();
  const { parent, dbDisplayName } = props;
  const event = props.events.filter((e)=> { if(e.vin === vin && e.local_start === ts)return e; else return null; });
  const returnUrl = `/idling/${parent}/${vin}`;
  // const [event, _setEvent] = useState(events[0])
  const history = useHistory();

  if(typeof event === "undefined"){
    return(<div><br/><br/><br/><p className="idling-holdon">No Data</p></div>)
  }

  const handleOnClick = () => {
    history.push(returnUrl);
  }

  const returnHome = () => {
    const url = `/idling/summarytable`;
    history.push(url);
  }

  return(
    <>
      <Header subHeader={vehicleIdDisplay(event[0])} />
        <div className="idling-subHeader-wrapper">
          <div>
          <button 
            className="idling-return-btn"
            onClick={handleOnClick}>
            Back
          </button>

          <button 
            className="idling-return-btn"
            onClick={returnHome}>
            Home
          </button>
        </div>
          <h3 className="idling-subHeader">{`${event[0].year} ${event[0].make} ${event[0].model}`}</h3>
        </div>
          <p className="idling-explain-text">Map marker indicates where the idling event occured. Click on the marker for incident details.</p>

          <div className='idling-top-panels'>
            <div>
              <IdlingMap 
                events={[event[0]]}
                zoom={15}
                center={[event[0].latitude, event[0].longitude]}
                isIncident={true}
                isSummary={false}
                dbDisplayName={dbDisplayName}
              />
            </div>

            <div className='idling-incident-content-text'>
              <div>Incident Start: <span className='idling-incident-text-light'>{tableDateFormat(event[0].local_start)}</span></div>
              <div>Incident Stop: <span className='idling-incident-text-light'>{tableDateFormat(event[0].local_stop)}</span></div>
              <div>Incident Location: <span className='idling-incident-text-light'>{event[0].event_location}</span></div>
              <div>Duration: <span className='idling-incident-text-light'>{formatDuration(event[0].duration_minutes)} {Math.round(event[0].duration_minutes) > 1 ? 'minutes' : 'minute'}</span></div>
            </div>
          </div>
    </>
  )
}