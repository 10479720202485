import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import SpeednDetailPanel from "./speedn-detail-panel";
import SpeednDetailTable from "./speedn-detail-table";
import DatePicker from "react-datepicker";
import DropdownSelector from "./speedn-dropdown";
import Header from './speedn-header';
import { vehicleIdDisplay } from "./speedn-table-helper-functions";
import { useEffect } from "react";

export default function VehicleDetail(props){
  const { vin } = useParams();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [ymm, setYmm] = useState("");
  const [assetId, setAssetId] = useState("");
  const history = useHistory();
  const { beginDate, endDate, setBeginDate, setEndDate, speedRanges, showDismissed, dbName, dbDisplayName } = props;

  useEffect(() => {
    let filterEvents = props.events.filter((e) => {return (e.vin === vin) ? e : null;})
    if(filterEvents.length > 0) {
      let year = filterEvents[0].year;
      let make = filterEvents[0].make;
      let model = filterEvents[0].model;
      setYmm(`${year} ${make} ${model}`);
      setAssetId(filterEvents[0].asset_id);
    }
    setFilteredEvents(filterEvents)

  },[props.events, vin])

  const getWeekNumber = function(date){
    let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
  };

  const sortByDate = function( a, b ) {
    if ( a.ts < b.ts )return -1;
    if ( a.ts > b.ts )return 1;
    return 0;
  }

  const compileIntoWeeks = function (evts) {
    function getWeek(ts){
      if(typeof ts === "undefined")return 0;
      let d = Date.parse(ts)
      d = new Date(d);
      return getWeekNumber(d);
    }

    let refDate = new Date(beginDate);
    let arr = [];
    while(refDate < endDate){
      const woy = getWeek(refDate);
      if(arr.indexOf(woy) < 0)arr.push(woy);
      refDate.setDate(refDate.getDate() + 1);
    }
    const we = [];
    arr.forEach((a) => {
      we.push({woy: a, incidents: 0})
    })

    arr = evts.sort(sortByDate);
    arr.forEach((a) => {
      const woy = getWeek(a.ts);
      we.forEach((w) => {if(w.woy === woy)w.incidents++});
    });
    return we;
  }

  const handleShowDismissed = function(e){
    props.handleDismissedChange(e.target.checked)
  }
  
  const weeklyEvents = compileIntoWeeks(filteredEvents);


  const handleOnClick = () => {
    const url = `/speedn/summarytable`;
    history.push(url);
  }
 
  let csvDetailFilename = `sawatch-labs-speeding-vehicle-report-${assetId.replace(/ /g, '-')}-${(new Date()).toLocaleDateString().replaceAll('/', '-')}`

  return(
    <>
      <Header subHeader={vehicleIdDisplay(filteredEvents[0])} />
      <div className="speedn-detail-top-container">
        <div className="speedn-subHeader-wrapper">
          <button 
            className="speedn-return-btn"
            onClick={handleOnClick}>
            Back
          </button>
          <h3 className="speedn-subHeader">{ymm}</h3>
        </div>
        <div className="speedn-detail-controls">
          <div className='speedn-detail-input-group'>
            <label className="speedn-controls-label">Date Range:</label>
            <div className="speedn-datepickers-container">
              <DatePicker
                className="speedn-datepicker"
                selected={beginDate}
                onChange={(date) =>{ setBeginDate(date) }}
                selectsStart
                startDate={beginDate}
                endDate={endDate}
                maxDate={endDate}
                showMonthDropdown
                useShortMonthInDropdown
              />
              <DatePicker
                className="speedn-datepicker"
                selected={endDate}
                onChange={(date) => { setEndDate(date) }}
                selectsEnd
                endDate={endDate}
                minDate={beginDate}
                maxDate={new Date()}
                showMonthDropdown
                useShortMonthInDropdown
              />
            </div>
          </div>
          <div className='speedn-detail-controller-row'>
          <div className='speedn-detail-input-group'>
            <label className="speedn-controls-label">Excess Vehicle Speed:</label>
              <DropdownSelector 
                className="speedn-dropdown-small"
                options={speedRanges} 
                defaultValue={props.excessSpeed}
                handleChange={props.handleExcessSpeedChange}
              />
          </div>
          <div className='speedn-detail-input-group'>
            <label className="speedn-controls-label">Minimum Vehicle Speed:</label>
              <DropdownSelector
                className='speedn-dropdown-small'
                options={props.minSpeeds} 
                handleChange={props.handleMinSpeedChange}
                defaultValue={props.minSpeed}
              />
            </div>
            <div className='speedn-detail-input-group'>
            <label className='speedn-controls-label'>{`Minimum Duration (Seconds):`}</label>
              <DropdownSelector
                className='speedn-dropdown-small'
                options={props.minDurations} 
                handleChange={props.handleMinDurationChange}
                defaultValue={props.minDuration}
              />
            </div>
            </div>
            <div className='speedn-radio-group-wrapper'>
              <label>Show Invalidated:</label>
              <input className='speedn-dismissed-checkbox' type="checkbox" checked={showDismissed} onChange={handleShowDismissed}/>
          </div>
        </div>
      </div>
      <SpeednDetailPanel
        weeklyEvents={weeklyEvents}
        events={filteredEvents}
        ymm={ymm}
        assetId={assetId}
        dbDisplayName={dbDisplayName}
      />
          <SpeednDetailTable
            parent={'vehicledetail'}
            setIncidentParent={props.setIncidentParent}
            columns={props.columns}
            data={filteredEvents}
            sortCol={props.sortCol}
            desc={props.desc}
            // handleSort={props.handleSort}
            beginDate={beginDate}
            endDate={endDate}
            excessSpeed={props.excessSpeed}
            minSpeed={props.minSpeed}
            minDuration={props.minDuration}
            tableType={'Vehicle'}
            dbName={dbName}
            csvFilename={csvDetailFilename}
            dbDisplayName={dbDisplayName}
          />
    </>
    )
}