import React, { useEffect, useCallback } from "react";
import MapWrapper from "./mapWrapper";
export default function MapController(props) {
  const {
    allLocations,
    locations,
    mapCenter,
    zoom,
  } = props;
  // Great resource for colo ramps:
  // https://developers.arcgis.com/javascript/latest/esri-color-ramps/
  // #3b5d87,#3d6da2,#3e7cbe,#799a96,#b3a752,#ccbe6a,#ffec99
const iconColors = ["#3b5d87", "#3d6da2", "#3e7cbe", "#799a96", "#b3a752", "#ccbe6a", `#CACACA`];
  const updatedLocations = allLocations.map((loc) => {
    loc["selected"] = false;
    locations.map((sl) => {
      if (sl.pkid === loc.pkid) {
        loc.vcl_ct = sl.vcl_ct;
        loc.anc_ct = sl.anc_ct;
        loc.selected = true;
      }
      return sl;
    });
    return loc;
  });

  const createMapIcons = useCallback((data) => {
    const parkingArray = [];
    if (data !== undefined)
      data.map((loc) => {
        if (!loc.lat) {
          loc["lat"] = loc.latitude;
          loc["lon"] = loc.longitude;
        }
        if (loc.parking_location === undefined) loc.parking_location = loc.pkid;
        // !SelectedLocation to show percentage maps
        loc["icon_radius"] = 10;
        if (loc.selected) {
          var vclCount = parseInt(loc.vcl_ct)
          if (vclCount >= 1 && vclCount < 5) loc["icon_color"] = iconColors[5];
          if (vclCount >= 5 && vclCount < 10) loc["icon_color"] = iconColors[4];
          if (vclCount >= 10 && vclCount < 15) loc["icon_color"] = iconColors[3];
          if (vclCount >= 15 && vclCount < 20) loc["icon_color"] = iconColors[2];
          if (vclCount >= 20 && vclCount < 25) loc["icon_color"] = iconColors[1];
          if (vclCount >=25) loc["icon_color"] = iconColors[0];
        } else {
          loc["icon_color"] = iconColors[6];
        }
        return parkingArray.push(loc);
      });
    return parkingArray;
  }, [iconColors]);

  useEffect(() => {
    createMapIcons(locations);
  }, [ createMapIcons, locations]);
  return (
    <div className={"nrel-map-controller"}>
      <MapWrapper
        iconColors={iconColors}
        mapCenter={mapCenter}
        zoom={zoom}
        filteredGroup={createMapIcons(updatedLocations)}
        handleLocationChange={props.handleLocationChange}
      />
    </div>
  );
}
