import React, { useState, useEffect } from 'react';
import '../component-css/sftd-stylesheets/shifted.css';
import Shifted from './sftd-components/Shifted';
import NavMenu from './sftd-components/NavMenu';
import ActiveProcessingPage from './sftd-components/ActiveProcessingPage';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

const App = (props) => {
  const { basename, apiURL, db, secrets, user, dbDisplayName } = props;
  const [apiError, setApiError] = useState(null);
  const [vehiclesInProcess, setVehiclesInProcess] = useState(null);

  useEffect(() => {
      fetch(`${apiURL}getDutyCycleProcessStatus?dbName=${db}`, {
          headers: { Authorization: `Bearer ${user.token}` }
      }).then(res => res.json())
        .then((data) =>{
            setVehiclesInProcess(data.data[0]?.dc_vehicles_in_process);
        })
        .catch((err) => {
          console.error(`Error in getProcessingState Call, ${err}`)
          setApiError(true)
        });
    }, [apiURL, user.token, db]);

    const handleProcessingFinished = () => {
      setVehiclesInProcess(0);
    }

    if(!vehiclesInProcess && vehiclesInProcess !== 0){
      return(
          <div className="sftd-application">
              <Router><NavMenu displayName={dbDisplayName} disabled={true}/></Router>
          </div>
      )
    }

    if(vehiclesInProcess > 0){
        return(<ActiveProcessingPage
          {...props}
          db={db}
          dbDisplayName={dbDisplayName}
          handleProcessingFinished={handleProcessingFinished}
          apiError={apiError}
        />);
    }

    return(
        <Router basename={basename} className="swt-shifted">
            <Switch>
                <Route
                    exact
                    path="/"
                    render={props => <Redirect to={{ pathname: '/shifted' }} />}
                />
                <Route
                    path="/shifted"
                    render={props => (
                      <Shifted {...props} basename={basename} dbName={db} dbDisplayName={dbDisplayName} secrets={secrets} user={user} />
                    )}
                />
            </Switch>
        </Router>
    )
}

export default App;
