import React from 'react';
export default function DataSelector(props) {
  return (
    <div className="sftd-DataSelector">
      <div
        className="sftd-DataSelector-miles-btn"
        id={props.selectedData === 'Miles' ? 'clicked' : 'unclicked'}
        onClick={() => {
          props.setData('Miles');
        }}
      >
        <h3 className="sftd-DataSelector-ttl">Miles</h3>
      </div>
      <div
        className="sftd-DataSelector-btn"
        id={props.selectedData === 'Days' ? 'clicked' : 'unclicked'}
        onClick={() => {
          props.setData('Days');
        }}
      >
        <h3 className="sftd-DataSelector-ttl">Days</h3>
      </div>
      <div
        className="sftd-DataSelector-btn"
        id={props.selectedData === 'Trips' ? 'clicked' : 'unclicked'}
        onClick={() => {
          props.setData('Trips');
        }}
      >
        <h3 className="sftd-DataSelector-ttl">Trips</h3>
      </div>
      <div
        className="sftd-DataSelector-btn"
        id={props.selectedData === 'Duty Cycles' ? 'clicked' : 'unclicked'}
        onClick={() => {
          props.setData('Duty Cycles');
        }}
      >
        <h3 className="sftd-DataSelector-ttl">Duty Cycles</h3>
      </div>
    </div>
  );
}
