import React from "react";
import * as S from "../../../styles/core-styles/DevTools-styles";

export default function MapControls(props) {
 
  function handlePathButtons(v) {
    props.setTripStyle(v.target.value);
  }

  function handleFilterButtons(v) {
    props.setFilter(v.target.value);
  }

  return (
    <>
      <S.DevToolsInputWrapper>
        <S.DevToolsInputLabel>Trip Style:</S.DevToolsInputLabel>
        <S.DevToolsSelectInput
          disabled={props.disabled}
          onChange={(e) => handlePathButtons(e)}>
          <option disabled={props.disabled} value='path'>Path</option>
          <option disabled={props.disabled} value='trace'>GPS Trace</option>
          <option disabled={props.disabled} value='none'>No Path/Trace</option>
        </S.DevToolsSelectInput>
      </S.DevToolsInputWrapper>

      <S.DevToolsInputWrapper>
        <S.DevToolsInputLabel>Filters:</S.DevToolsInputLabel>
        <S.DevToolsSelectInput
          disabled={props.disabled}
          onChange={(e) => handleFilterButtons(e)}>
          <option disabled={props.disabled} value='none'>No Filter</option>
          <option disabled={props.disabled} value='gasolineEnergy'>Gasoline Effective MJ</option>
          <option disabled={props.disabled} value='dieselEnergy'>Diesel Effective MJ</option>
          <option disabled={props.disabled} value='electricalEnergy'>Electrical Effective MJ</option>
          <option disabled={props.disabled} value='cngEnergy'>CNG Effective MJ</option>
        </S.DevToolsSelectInput>
      </S.DevToolsInputWrapper>  
    </>
  )
}