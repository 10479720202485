import React, {useState, useEffect} from 'react';
// Add functionality to specify certain rows/values to search.
// RC this needs summarized / or only searches values we're exposing (ie not vin)
export default function Search(props){
    const[activeSearch, setActiveSearch] = useState('');
    const {allValues, setMatchingValues} = props;
    useEffect(()=>{
            let valuesToSearch = ['asset_id', 'year', 'make', 'model', 'fuel_type']; 
            let matching = allValues;
            if (activeSearch !== undefined) {
              let searchTerm = activeSearch.toString().toLowerCase();
                // eslint-disable-next-line array-callback-return
                const matchingValues = matching.filter((entry) => {
                  if(!valuesToSearch.every((value) => {
                    if (`${entry[value]}`.toLowerCase().includes(searchTerm)) {return false;}
                     return true;
                  })) return entry
                });
              setMatchingValues({ "values": matchingValues, "searchTerm": searchTerm });
            } else {
              return setMatchingValues(allValues);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch, allValues])
    return(
        <div className='idling-searchbar-wrapper'>
            <input className={props.className} value={activeSearch} onChange={(e) => setActiveSearch(e.target.value)} placeholder='Search by Year, Make, Model, etc.'/>
        </div>);
}