import React from "react";
import StatBox from "./StatBox";
import styled from "styled-components/macro";

const SummaryTitle = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 35px;
`;

const SubTitle = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
`;

const StatBoxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 25px;
`;

export default function SummaryPanel(props: any){
    const beginDate = props.beginDate ?? new Date();
    const endDate = props.endDate ?? new Date();
    let gallons = 0;
    props.stats.forEach((s: any) => {
        //cludge in support of the POC
        if(s.location !== 11)gallons += Math.round((s.snr_liters * 0.26));
    })
    return(
        <>
            <SummaryTitle>
                {`Fueling Events Report: ${(beginDate) ? beginDate.toLocaleDateString("en-US"): '--'} - ${(endDate) ? endDate.toLocaleDateString("en-US"): '--'}`}
            </SummaryTitle>
            <SubTitle>
                For events away from vehicle's depot.
            </SubTitle>
            <StatBoxContainer>
                <StatBox 
                    value = {props.events.length}
                    subtitle = "Events"
                />
                <StatBox 
                    value = {`${props.vins.length}/8`}
                    subtitle = "Vehicles In Range/Total Vehicles"
                />
                <StatBox 
                    value = {gallons}
                    subtitle = "Estimated Gallons of Fuel"
                />
                <StatBox 
                    value = {props.fuelingDuration + props.travelToDuration}
                    subtitle = "Minutes Spent On Fueling"
                />
                <StatBox 
                    value = {props.travelToDuration}
                    subtitle = "Travel Off Route Minutes"
                />
                <StatBox 
                    value = {props.fuelingDuration}
                    subtitle = "Minutes at Fueling Station"
                />
            </StatBoxContainer>
        </>
    );
}