import React from 'react';
export function DetailsSelector(props) {
  return (
    <div
      className="sftd-details-selector"
      onClick={props.handleDetail}
      id={props.showDetails ? 'clicked' : 'unclicked'}
    >
      <h3 className="sftd-details-title">Details</h3>
    </div>
  );
}

export function Details(props) {
  return (
    <>
      <div className="sftd-details-expanded">
        <div className="sftd-details">
          <div className="sftd-current-selection">
            <div className="sftd-selected">
              <h4 className="sftd-details-title">Currently Selected</h4>
              <div className="column1">
                <p className="sftd-details-item">
                  Vehicles: {props.selectedVehicles.length} /{' '}
                  {props.vehicleUtil.length}{' '}
                  <span className="sftd-smallTxt">(filtered)</span>
                </p>
                <p className="sftd-details-item">
                  Total Vehicles in Fleet: {props.totalVehicleCount}{' '}
                  <span className="sftd-smallTxt">(unfiltered)</span>
                </p>
              </div>
              <div className="column2">
                <p className="sftd-details-item">
                  {' '}
                  Groups / Departments: 1 / 1
                </p>
                <p className="sftd-details-item">
                  Home Bases: {props.selectedLocations.length} /{' '}
                  {props.locations.length}
                </p>
              </div>
            </div>
          </div>
          <hr className="sftd-dividing-line" />
          <div className="sftd-details-projected">
            <div className="sftd-projected">
              {/* <h4 className="sftd-details-title">Projected</h4> */}
              <h4 className="sftd-details-title">ShiftED Results</h4>
              <p className="sftd-details-item">
                Average utilization:{' '}
                {isNaN(props.currentAverage) ? 0 : props.currentAverage}%
              </p>

              <p className="sftd-details-item">
                Average utilization:{' '}
                {!props.avgTotalUtil ||
                isNaN(Math.round(props.avgTotalUtil.avgSelectedUtil * 100))
                  ? 0
                  : Math.round(props.avgTotalUtil.avgSelectedUtil * 100)}
                % <span className="sftd-smallTxt">(selected)</span>
              </p>

              <p className="sftd-details-item">
                Average utilization:{' '}
                {!props.avgTotalUtil ||
                isNaN(Math.round(props.avgTotalUtil.avgFilteredUtil * 100))
                  ? 0
                  : Math.round(props.avgTotalUtil.avgFilteredUtil * 100)}
                % <span className="sftd-smallTxt">(filtered)</span>
              </p>
            </div>
            <div className="sftd-current">
              <h4 className="sftd-details-title"> </h4>

              <p className="sftd-details-item">
                Time spent at 100% capacity:{' '}
                {isNaN(
                  Math.round((props.hoursAtCapacity / props.totalHours) * 100)
                )
                  ? 0
                  : Math.round(
                      (props.hoursAtCapacity / props.totalHours) * 100
                    )}
                %
              </p>
              <p className="sftd-details-item">
                Time spent at {'>'} 100% capacity:{' '}
                {isNaN(
                  Math.round(
                    (props.hoursAboveCapacity / props.totalHours) * 100
                  )
                )
                  ? 0
                  : Math.round(
                      (props.hoursAboveCapacity / props.totalHours) * 100
                    )}
                %
              </p>
              <p className="sftd-details-item">
                Unserviced Cycles: {props.nonServicedCycles}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
