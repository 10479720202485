import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components/macro';

const highlightColor = getComputedStyle(document.body).getPropertyValue('--ezev-info')
const disabledFontColor = getComputedStyle(document.body).getPropertyValue('--ezev-battleship-grey');
const fontColor = getComputedStyle(document.body).getPropertyValue('--ezev-dark-grey');

const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    height: ${(props) => props.optionHeight ? props.optionHeight : '35px'};
    width: ${(props) => props.optionWidth ? props.optionWidth : '100%'};
    cursor: pointer;
    &:hover {
        background-color:${(props) => props.selectedOptionBgColor ? props.selectedOptionBgColor : highlightColor};
    }
`;

const SelectedOptionWrapper = styled(OptionWrapper)`
    height: ${(props) => props.optionHeight ? props.optionHeight : '35px'};
    width: ${(props) => props.optionWidth ? props.optionWidth : '100%'};
    background-color: ${(props) => props.selectedOptionBgColor ? props.selectedOptionBgColor : highlightColor};
`;

const OptionText = styled.p`
    padding: ${(props) => props.optionPadding ? props.optionPadding : '10px 0'};
    margin: 0;
    font-size: ${(props) => props.optionFontSize ? props.optionFontSize : '16px'};
    color: ${(props) => props.color ? props.color : 'black'};
`;

const BulletPoint = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.bulletSize ? props.bulletSize : '16px'};
    height: 16px;
    width: 16px;
    color: ${(props) => props.bulletColor ? props.bulletColor : fontColor};
`;

const DropdownIndicator = props => {
    //this is overridden to match the multiselect down and up arrows exactly.
    return (
        <components.DropdownIndicator {...props}>
            <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">
                {props.selectProps.menuIsOpen && <path d="M18 15 12 9 6 15"></path>}
                {!props.selectProps.menuIsOpen && <path d="M6 9L12 15 18 9"></path>}
            </svg>
        </components.DropdownIndicator>
    );
};

function SingleSelectDropdown (props) {
    const customStyles = {
        menu: (provided, state) => {return {...provided, width: props.width ? props.width : "100%"}},
        option: (provided, state) => {return {...provided, width: props.width ? props.width : "100%", color: props.color ? props.color : fontColor}},
        control: (provided, state) => {return {...provided, height: props.height ? props.height : "48px", width: props.width ? props.width : "100%", textIndent: "40px", borderColor: "black", color: fontColor}},
        placeholder: (provided, state) => {return {...provided, color: props.color ? props.color : fontColor}},
        dropdownIndicator: (provided, state) => {return {marginRight: "10px", color: props.color ? props.color : fontColor}},
        indicatorsContainer: (provided, state) => {return {...provided}},
        singleValue: (provided) => {return {...provided, color: props.color ? props.color : fontColor}}
    }

    function scrollToSelected() {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName("selectedOption")[0];
            if (selectedEl && typeof selectedEl.scrollIntoView === "function") {
                selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            }
        }, 15);
    };

    const CustomOption = (props) => {
        const {data, isSelected, innerProps, isDisabled, selectProps } = props;
        selectProps.styleProps.selectedOptionBgColor = isDisabled ? 'white' : highlightColor
        selectProps.styleProps.color = isDisabled ? disabledFontColor : fontColor;
        return ((isSelected) ?
            <SelectedOptionWrapper className='selectedOption' {...selectProps.styleProps}>
                <BulletPoint {...selectProps.styleProps}>•</BulletPoint>
                <OptionText {...selectProps.styleProps}>{data.label}</OptionText>
            </SelectedOptionWrapper>
            :
            <OptionWrapper {...innerProps} {...selectProps.styleProps}>
                <BulletPoint {...selectProps.styleProps}> </BulletPoint>
                    <OptionText {...selectProps.styleProps}>{data.label}</OptionText>
            </OptionWrapper>
        )
    }

    return (
            <Select
                {...props}
                styles={customStyles}
                placeholder={props.placeholder ? props.placeholder : 'Select'}
                value={props.value ? props.value : ''}
                styleProps={{
                    selectedOptionBgColor: props.selectedOptionBgColor,
                    bulletColor: props.bulletColor,
                    width: props.width,
                    height: props.height,
                    bulletSize: props.bulletSize,
                    optionHeight: props.optionHeight,
                    optionWidth: props.optionWidth,
                    optionPadding: props.optionPadding,
                    optionFontSize: props.optionFontSize,
                    color: props.color,
                }}
                onChange={props.onChange ? props.onChange : null}
                options={props.options ? props.options : [{label: 'No Options', value: 'N/A'}]}
                onMenuOpen={() => scrollToSelected()}
                components={{
                    Option: CustomOption,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: DropdownIndicator
                }}
                isSearchable={false}
            />

    )
}

export default SingleSelectDropdown;