import { DateTime } from "luxon";

const KMS_TO_MILES = 0.621371;
const LITERS_TO_GALS = 0.264172;
const GALS_TO_LITERS = 3.78541;
const LBS_TO_KGS = 0.453592;
const GMZ_TO_OZS = 0.035274;

function trimTzDesignation (date) {
    if(typeof date === 'string')return date.replace("Z", "");
    else return date;
}

export function dateAsNonTZString(date) {
    // Format plain date without timezone
    if(date instanceof Date)return date.toISOString().split("T")[0];
    if(date instanceof DateTime)return date.toISO().split("T")[0];
  }

export function processApiResponse(userSettings, resp) {
    if(!resp || !userSettings)return resp;
    const dollarValues = ['insurance', 'scc', 'tco', 'rec_lt_net_savings',
                          'rec_lt_ops_savings', 'rec_yearly_tco', 'rec_yr_tco_reduction',
                          'comp_ice_yearly_cost', 'est_current_yearly_tco', 'est_yearly_fuel_cost',
                          'msrp', 'net_price', 'maint_per_km', 'expected_residual_value',
                          'est_current_yearly_ops_cost', 'yearly_cost_ev', 'kwh_cost', 'fuel_cost', 'kwhRate', 
                          'local_kwh_cost', 'peak_kwh_cost', 'offpeak_kwh_cost', 
                        ];
    const dates = ["min", "max", "min_ts", "max_ts", "local_start", "local_stop"];
    const nestedObjs = ['upfits', 'required_upfits', 'optional_upfits'];
    Object.keys(resp).forEach((k) => {
        if(k.includes('celsius')){
            let temp = formatTemperature(userSettings, resp[k])
            resp[k] = temp
        }
        if(k.includes('km')){
            let dist = formatDistance(userSettings, resp[k])
            resp[k] = dist
        }
        if(k.includes('liters')){
            let lq = formatLtrs(userSettings, resp[k])
            resp[k] = lq
        }
        if(k.includes('gal')){
            // eg rec_lt_gals_saved, rec_fuel_reduction_gal
            let lq = formatGals(userSettings, resp[k])
            resp[k] = lq
        }
        if(dates.includes(k)){
            if (resp[k]) {
                resp[k] = trimTzDesignation(resp[k])
            }
            let ts = DateTime.utc()
            if (typeof resp[k] === 'string') ts = formatTimestamp(userSettings, resp[k] ? DateTime.fromISO(resp[k]) : null);
            else if (resp[k] instanceof Date || resp[k] instanceof DateTime) ts = formatTimestamp(userSettings, resp[k]);
            resp[k] = ts
        }
        if(dollarValues.includes(k)){
            let cost = formatCurrency(userSettings, resp[k])
            resp[k] = cost
        }
        if(k.includes('lbs')){
            let weight = formatLbs(userSettings, resp[k])
            resp[k] = weight
        }
        if(k === 'ghg_kwh_gm'){
            let ghg_kwh = formatGms(userSettings, resp[k]);
            resp[k] = ghg_kwh;
        }
        if(nestedObjs.includes(k)){
            // TEMPORARILY deal with arrays of objects - only applies to upfits
            // DISCUSS pattern for this and refactor out
            resp[k].forEach((upfit) => {
                Object.keys(upfit).forEach((u) => {
                    if(u === "price"){
                        let cost = formatCurrency(userSettings, upfit[u])
                        upfit[u] = cost
                    }
                    if(u === "weight"){
                        let w = formatLbs(userSettings, upfit[u])
                        upfit[u] = w
                    }
                })
            })
        }
    })
    return resp;
  }

  export function processRateTime(settings, rateObj) {
    switch(settings.ts_display) {
        case "hh:mm:ss xm":
            rateObj.startTimestamp = formatRateStandardTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTimeSeconds('stop', rateObj);
            break;
        case "hh:mm:ss":
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
            break;
        case "hh:mm xm":
            rateObj.startTimestamp = formatRateStandardTime('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTime('stop', rateObj);
            break;
        case "hh:mm":
            rateObj.startTimestamp = formatRateMilitaryTime('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTime('stop', rateObj);
            break;
        default:
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
    }
    return rateObj;
}

function formatRateMilitaryTime(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}`;
}

function formatRateMilitaryTimeSeconds(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}:00`;
}

function formatRateStandardTime(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])} ${amPm(rateObj[`${key}Hour`])}`;
}

function formatRateStandardTimeSeconds(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])}:00 ${amPm(rateObj[`${key}Hour`])}`;
}

function militaryToStandardHours(value) {
    if (value === 0)
        return 12;
    if (value > 12) {
        return value - 12;
    }
    return value;
}

function amPm(hour) {
    return hour > 11 && hour < 24 ? 'pm' : 'am'
}

function addLeadingZero(value) {
    if (value > 9) return value;
    else return `0${value}`;
}

export function formatTimeString (settings, hr, min) {
    switch(settings.ts_display){
        case "hh:mm:ss xm":
            return `${addLeadingZero(militaryToStandardHours(hr))}:${min}:00 ${amPm(hr)}`;
        case "hh:mm:ss":
            return `${addLeadingZero(hr)}:${min}:00`;
        case "hh:mm xm":
            return `${addLeadingZero(militaryToStandardHours(hr))}:${min} ${amPm(hr)}`;
        case "hh:mm":
            return `${addLeadingZero(hr)}:${min}`;
        default:
            return `${addLeadingZero(hr)}:${min}`;
    }
  }

function formatTemperature(settings, value){
    if(value === null || value === undefined) return value;
    if(settings.use_celsius)return value;
    return celsiusToFahrenheit(value)
}

function formatDistance(settings, value){
    if(!value)return value;
    if(settings.use_kms)return value;
    return kmsToMiles(value)
}

function formatDate(settings, value) {
    switch(settings.date_display){
        case 'dd/MM/yy':
            if(value instanceof DateTime)return `${('0' + value.day).slice(-2)}/${('0' + value.month).slice(-2)}/${value.year.toString().substring(2, 4)}`;
            return null;
        case 'dd/MM/yyyy':
            if(value instanceof DateTime)return `${('0' + value.day).slice(-2)}/${('0' + value.month).slice(-2)}/${value.year}`;
            return null;
        case 'MM/dd/yy':
            if(value instanceof DateTime)return `${('0' + value.month).slice(-2)}/${('0' + value.day).slice(-2)}/${value.year.toString().substring(2, 4)}`;
            return null;
        case 'MM/dd/yyyy':
            if(value instanceof DateTime)return `${('0' + value.month).slice(-2)}/${('0' + value.day).slice(-2)}/${value.year}`;
            return null;
        case 'yy/MM/dd':
            if(value instanceof DateTime)return `${value.year.toString().substring(2, 4)}/${('0' + value.month).slice(-2)}/${('0' + value.day).slice(-2)}`;
            return null;
        case 'yyyy/MM/dd':
            if(value instanceof DateTime)return `${value.year}/${('0' + value.month).slice(-2)}/${('0' + value.day).slice(-2)}`;
            return null;
        default:
            if(value instanceof DateTime)return `${('0' + value.month).slice(-2)}/${('0' + value.day).slice(-2)}/${value.year.toString().substring(2, 4)}`;
            return null;
    }
}

function formatTime(settings, value) {
    switch(settings.ts_display){
        case "hh:mm:ss xm":
            if(value instanceof DateTime)return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)} ${amPm(value.hour)}`;
            return null;
        case "hh:mm:ss":
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
            return null;
        case "hh:mm xm":
            if(value instanceof DateTime)return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)} ${amPm(value.hour)}`;
            return null;
        case "hh:mm":
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}`;
            return null;
        default:
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
            return null;
    }
}

export function formatTimestamp(settings, value){
    if(!value)return value;
    const date = formatDate(settings, value);
    const time = formatTime(settings, value);

    return {"date": date, "time": time};
}

function formatLtrs(settings, value){
    if(!value)return value;
    if(settings.use_liters)return value;
    return ltrsToGals(value)
}

function formatGals(settings, value){
    if(!value)return value;
    if(!settings.use_liters)return value;
    return galsToLtrs(value)
}

function formatCurrency(settings, value){
    if(!value)return value;
    if(settings.db_currency === settings.currency)return value;
    // if user currency !== db currency, convert using applicable conversion rate
    let conversion_rate = 1;
    if(settings.db_currency === 'EUR' && settings.currency === 'USD'){
        conversion_rate = settings.to_usd;
    }
    if(settings.db_currency === 'USD' && settings.currency === 'EUR'){
        conversion_rate = settings.to_eur;
    }
    return (value*conversion_rate);
}

function formatLbs(settings, value){
    if(!value)return value;
    if(settings.use_kgs)return lbsToKgs(value);
    return value;
}

function formatGms(settings, value){
    if(!value)return value;
    if(settings.use_kgs)return gmsToOz(value);
    return value;
}

function celsiusToFahrenheit(value){
    return (value * 9/5) + 32
}

function kmsToMiles(value){
    return (value * KMS_TO_MILES)
}

function ltrsToGals(value){
    return (value * LITERS_TO_GALS)
}

function galsToLtrs(value){
    return (value * GALS_TO_LITERS)
}

function lbsToKgs(value){
    return (value * LBS_TO_KGS);
}

function gmsToOz(value){
    return (value * GMZ_TO_OZS);
}

export function sanitizeDownloadString(str) {
    // Replace all forward slashes, underscores, and spaces with dashes
    const replaceWithDashes = str.replace(/[\s/_]/g, "-");
    // Remove non-alphanumeric characters, excluding dashes
    const sanitized = replaceWithDashes.replace(/[^a-zA-Z0-9-]/g, "");
    return sanitized;
  }