import styled from "styled-components/macro"
import { Link } from "react-router-dom"

export const ShowVehiclesLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
text-color: var(--white);
margin: 0;
`