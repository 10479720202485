import React, { Component } from 'react';
import SingleVehiclePage from './SingleVehiclePage';
import VehiclesPage from './VehiclesPage';
import { withRouter } from 'react-router-dom';
import { getFormattedAPIDate, convertKMtoMiles } from './DataFormatter';

class VehiclePagesCntrlr extends Component {
  state = { updatedVcls: [], loading: true };

  componentDidMount() {
    this.fetchVehicleData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicleUtil !== this.props.vehicleUtil) {
      this.fetchVehicleData();
    }
    if (prevProps.start !== this.props.start) {
      this.fetchVehicleData();
    }
    if (prevProps.stop !== this.props.stop) {
      this.fetchVehicleData();
    }
  }

  convertDOW = () => {
    let { selectedDays } = this.props;
    let arr = [];
    if (selectedDays.sun) arr.push(0);
    if (selectedDays.mon) arr.push(1);
    if (selectedDays.tue) arr.push(2);
    if (selectedDays.wed) arr.push(3);
    if (selectedDays.thu) arr.push(4);
    if (selectedDays.fri) arr.push(5);
    if (selectedDays.sat) arr.push(6);
    return arr;
  };

  fetchVehicleData = () => {
    try {
      let start = getFormattedAPIDate(this.props.start);
      let stop = getFormattedAPIDate(this.props.stop);
      let dow = this.convertDOW();
      const arr = this.props.vehicleUtil.slice(0,25);
      Promise.all(

        arr.map(v =>
          fetch(
            `${this.props.apiHost}getSingleVclSummaryData?clientId=${this.props.dbName}&vin=${v.vin}&stop=${stop}&start=${start}&dow=[${dow}]`,
            {
              headers: { Authorization: `Bearer ${this.props.user.token}` }
            }
          )
        )
      )
        .then(responses => Promise.all(responses.map(res => res.json())))
        .then(vclResponse => {
          let vclUtil = this.props.vehicleUtil;
          vclUtil.map((e, i) => {
            if (vclResponse[i] === undefined) return null;
            let vclR = vclResponse[i].data[0];
            e['ttlMiles'] = !vclR ? '-' : convertKMtoMiles(vclR.ttl_km);
            e['days'] = !vclR ? '-' : vclR.active_days;
            e['dutyCycles'] = !vclR ? '-' : vclR.active_dc;
            e['totalDays'] = !vclR ? '-' : vclR.total_days;
            e['odometer'] = !vclR ? '-' : convertKMtoMiles(vclR.last_odom_km);
            e['operationalHrs'] = !vclR ? '-' : vclR.ttl_hrs;
            e['idle_hours'] = !vclR ? '-' : vclR.ttl_idl_hrs;
            e['trips'] = !vclR ? '-' : vclR.active_trips;
            e['annual_tco'] = !vclR ? '-' : vclR.est_current_yearly_tco;
            e['parking_loc'] = !vclR ? '-' : vclR.parking_loc;
            return e;
          });
          return this.setState({ updatedVcls: vclUtil, loading: false });
        });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  };
  render() {
    return (
      <>
        {this.props.location.pathname === '/shifted/singleVehicle' &&
          this.state.updatedVcls.length > 0 && (
            <SingleVehiclePage
              vclInfo={this.state.updatedVcls.filter(
                v => v.vin === this.props.location.state.vin
              )}
              showDetails={this.props.showDetails}
              handleDetail={this.props.handleDetail}
              apiHost={this.props.apiHost}
              start={this.props.start}
              stop={this.props.stop}
              dbName={this.props.dbName}
              fullCapacity={this.props.fullCapacity}
              vin={this.props.location.state.vin}
              user={this.props.user}
              dow={this.convertDOW()}
              ttldutyCycles={this.props.ttldutyCycles}
              selectedLocIds={this.props.selectedLocIds}
              settingChangeTracker={this.props.settingChangeTracker}
            />
          )}
        {this.props.location.pathname === '/shifted/vehicles' && (
          <VehiclesPage
            vehicleUtil={this.props.vehicleUtil}
            updateState={this.props.updateState}
            updatedVcls={this.state.updatedVcls}
            handleVehicleSelect={this.props.handleVehicleSelect}
            avgTotalUtil={this.props.avgTotalUtil}
            loading={this.state.loading}
            handleToggleClick={this.props.handleToggleClick}
            settingChangeTracker={this.props.settingChangeTracker}
          />
        )}
      </>
    );
  }
}
export default withRouter(VehiclePagesCntrlr);
