import React, { useMemo, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

export default function UpfitsDropdown(props) {
    const { upfits, requiredUpfits, handleChange, selectedUpfits, id } = props;
    const [selected, setSelected] = useState([]);  
    const [options, setOptions] = useState([]);

    useMemo(() => {
        setSelected(selectedUpfits);
        if (requiredUpfits === undefined) {
            setOptions(upfits.map((u) => {return {label: u.name, value: u.id}}));
        }
        else {
            let opts = []
            upfits.forEach((u) => {
                let disabled = false;
                if (requiredUpfits.find(e => e.id === u.id))
                    disabled = true;
                opts.push({label: u.name, value: u.id, disabled: disabled})
                
            })
            setOptions(opts);
        }

    }, [selectedUpfits, requiredUpfits, upfits])

    const handleSelectChange = (e) => {
        setSelected(e);
        handleChange(e, id)
    }

    const customValueRenderer = (selected) => {
        if (selected.length === 0) {
            return <p>None</p>
        }
        else if (selected.length === 1) {
            return <p>1 upfit</p>
        }
        else {
            return <p>{selected.length} upfits</p>
        }
    }

    return (
        <div style={{width: "450px"}}>
            <MultiSelect
            autosize={true}
            options={options}
            value={selected}
            onChange={handleSelectChange}
            valueRenderer={customValueRenderer}
            labelledBy="Select"
        />
        </div>

    )
}