import React, { useReducer } from 'react'

// import Header from "../swt-header";
function PasswordReset({ handleResetPassword }) {
  const [state, dispatch] = useReducer((state, action) => {
    return ({ ...state, ...action })
  }, { username: "" })


  function handleNameChange(event) {
    dispatch({ username: event.target.value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (state.username.length > 0) {
      handleResetPassword(state.username);
    }
  };


  return (
    <div className="swt-password-reset-wrpr">
      {/* <Header /> */}
      <div className="swt-password-reset-container">
        <p className="swt-reset-password-txt">
          We'll send an email with a link to reset the associated password.
        </p>
        <form className="swt-auth" onSubmit={handleSubmit}>
          <p className="swt-credential-input-label">Username/Email Address</p>
          <input
            className="swt-credential-input"
            name="username"
            type="text"
            onChange={handleNameChange}
          ></input>
          <button className="swt-submit-button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );

}

export default PasswordReset;
