import React from 'react';
import ReactLoading from 'react-loading';

const Loading = (props) => {
  const c = props.color ?? "rgba(182, 174, 174, 0.267)"
  const w = props.width ?? "";
  const h = props.height ?? "";
  return(
    <ReactLoading
      type={'bars'}
      color={c}
      width={w}
      height={h}
    />
  )
};

export default Loading;
