import React from "react";
import { FormatCommas } from "./dataFormatter";

export default function InfoCardAssumptions(props) {
  // NOTE needs error handling - decide API / here
  var repair = FormatCommas(props.assumptions.ice_repair_per15k);
  var ev_maint = FormatCommas(props.assumptions.ev_repair_per15k);
  var insurance = FormatCommas(props.assumptions.yearly_insurance);
  return (
    <div className="nrel-information-card single">
      <div className="nrel-card-content-l">
        <p className="nrel-assumption">
          Gas Price:{" "}
          <span id="office_id" className="nrel-table-text-right-aligned">
            {props.assumptions &&
              props.assumptions.fuel_cost_usd &&
              `$${props.assumptions.fuel_cost_usd.toFixed(2)} per gal`}
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          Local Electricity Price:{" "}
          <span className="nrel-table-text-right-aligned">
            ${props.assumptions.local_kwh_usd} per kWh
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          GHG Emissions from Electricity Generation:{" "}
          <span className="nrel-table-text-right-aligned">
            {props.assumptions.ghg_kwh_gm} g/kWh
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          Social Cost of Carbon:{" "}
          <span className="nrel-table-text-right-aligned">
            ${props.assumptions.scc} per ton
          </span>
        </p>
        <div className="nrel-divider" />
      </div>{" "}
      <div className="nrel-card-content-r">
        <p className="nrel-assumption">
          ICE Repair Cost:{" "}
          <span className="nrel-table-text-right-aligned">
            ${repair} per 15,000 miles
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          EV Maintenance Cost:{" "}
          <span className="nrel-table-text-right-aligned">
            ${ev_maint} per 15,000 miles
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          Insurance:{" "}
          <span className="nrel-table-text-right-aligned">
            ${insurance} per year
          </span>
        </p>
        <div className="nrel-divider" />
        <p className="nrel-assumption">
          Vehicle Lifecycle:{" "}
          <span className="nrel-table-text-right-aligned">
            {props.assumptions.life_cycle} years
          </span>
        </p>
        <div className="nrel-divider" />
      </div>
    </div>
  );
}
