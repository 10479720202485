import styled from 'styled-components';

const MEDIUM_VIEW_BREAKPOINT = "1100px";
const SMALL_VIEW_BREAKPOINT = "880px";


// Styles
export const ProductWrapper =  styled.div`
    font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
    box-sizing: border-box;
    width: 90%;
    margin-left: 110px;
    margin-bottom: 50px;
    max-width: 1300px;
    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
      margin-right: 15px;
      padding-right: 10px;
  }
`

export const ContentContainer = styled.div`
    border: 1px solid black;
    border-top: none;
    overflow: auto;
    min-height: 600px;
    min-width: 800px;
`
export const ContentInnerWrapper = styled.div`
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  border: none;
`

export const TopContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: max-content;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        flex-direction: column-reverse;
        min-width: auto;
    }
`

export const TopContentLeftSide = styled.div`
    min-width: 830px;
`

export const TopContentRightSide = styled.div`

`

export const BottomContentContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const NavContainer= styled.nav`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-template-rows: 100%;
    margin-top: 45px;
    text-align: center;
    width: 100%;
    min-width: 800px;
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        height: 40px;
    }
`;

export const EmptyTab = styled.span`
  border-bottom: 1px solid;
  width: 50%;
`;

export const ControlsColumn = styled.div`
  margin-top: 35px;
  margin-left: 25px;
  @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
    margin-left: 0;
    margin-bottom: 15px;
    margin-top: 20px;
}
`;

export const HiddenElement = styled.div`
    display: none;
`;

export const GraphContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PieChartWrapper = styled.div`
    margin-left: 30px;
`;

export const PDFDownloadButton = styled.a`
    width: 165px;
    height: 50px;
    background-color: ${(props) => props.bgColor ? props.bgColor : '#13908b'};
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .3px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 25px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      filter: drop-shadow(rgba(74, 74, 74, 0.18) 4px 2px 10px);
    }
  `;

  export const TableHeaderContainer = styled.div`
    width: 90%;
    max-width: 1500px;
    min-width: 900px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `

  export const DownloadButtonContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
  `

  export const TableHeaderTextPrimaryWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `

  export const TableHeaderTextPrimary = styled.p`
  opacity: .9;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: .1px;
  color: #47484a;
  margin: 0;
  `
export const TableHeaderTextSub = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: 0;
  color: #74787d;
  white-space: nowrap;
  margin: 0;
  padding: 0;
`

export const NoDataMessage = styled.p`
font-size: 17px;
color: #4a4a4a;
white-space: nowrap;
margin: 0;
padding: 0;
text-align: center;
margin-top: 10px;
margin-bottom: -20px;
padding-bottom: 20px;
`

export const NoDataMessageWrapper = styled.div`
    width: max-content;
`