import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"
import * as CS from "../../styles/ezio-styles/Controls-styles"



type GroupsDropdownProps = {
    groups: Array<any>,
    selectedGroups?: Array<any>
    onChange: Function
}




export default function GroupsDropdown({ groups, selectedGroups, onChange }: GroupsDropdownProps) {

    const [options, setOptions] = useState<Array<any>>([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        //Ensure that "All Vehicles" is displayed at the top of the dropdown if it is present
        const groupOptions = groups.map((g) => { g.value=g.id; g.label = g.name; return g });
        const allVehiclesIndex = groupOptions.findIndex(group => group.id === "swt-vehicles");
        if (allVehiclesIndex !== -1) {
            const [allVehicles] = groupOptions.splice(allVehiclesIndex, 1);
            groupOptions.unshift(allVehicles);
        }

        setOptions(groupOptions);
    }, [groups])


    //Unfortunately, adding pagination means stripping out the type checking specifically here. 
    //The data does come out of and into a variable that does check though, so this should be fine.
    const loadGroups = async (search: string, prevGroups: any) => {

        let filteredGroups
        if (!search) {
            filteredGroups = options
        } else {
            const searchLower = search.toLowerCase()
            filteredGroups = options.filter(({ label }) => label.toLowerCase().includes(searchLower))
        }
        const hasMore = filteredGroups.length > prevGroups.length + 10;
        const slicedGroups = filteredGroups.slice(
            prevGroups.length,
            prevGroups.length + 10
        );
        return {
            options: slicedGroups,
            hasMore
        }

    }

    function toggleOpen() {
        setIsOpen(!isOpen)
    }
    useEffect(() => {
        scrollToSelected()

    }, [isOpen])

    return (

        <Dropdown
            dropdownId="group-dropdown-testid"
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <CS.GroupsMenuButton onClick={toggleOpen}>
                    <CS.GroupsMenuText>
                        {selectedGroups.length === 0
                                ? "No Groups Available"
                                : selectedGroups.includes("All Groups")
                                    ? "All Groups"
                                    : selectedGroups.length === 1
                                        ? selectedGroups[0].label
                                        : `${selectedGroups.length} Groups`}
                    </CS.GroupsMenuText>
                    <CS.CustomDownChevron />
                </CS.GroupsMenuButton>
            }
        >

            <AsyncPaginate
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen
                isMulti={true}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={options.filter((item: any) => selectedGroups.some(group => group.id === item.id))}
                options={options}
                onMenuOpen={scrollToSelected}
                loadOptions={
                    loadGroups
                }
                placeholder={"Search Group Names"}
                onChange={(event) => {

                    // If All Vehicles is selected, then the user selects a specific group, deselect All Vehicles and select only that group
                    if (selectedGroups.some(group => group.id === "swt-vehicles") && event.length > 1) {
                        onChange(event.filter((item: any) => item.id !== "swt-vehicles").map((item: any) => item.value));
                    }
                    // If any specific groups are selected, then the user selects All Groups, deselect everything except All Groups
                    else if (event.some((item: any) => item.id === "swt-vehicles") && !selectedGroups.some(group => group.id === "swt-vehicles")) {
                        onChange(["swt-vehicles"]);
                    }
                    // If only one specific group is selected, then the user deselects it, prevent the action.
                    else if (event.length === 0) {
                        return;
                    }
                    //Otherwise, update normally
                    else {
                        onChange(event.map((item: any) => item.value))
                    }

                }}
                isSearchable={true}
                styles={
                    {
                        ...CS.SelectStyles,
                        container: (base: any) => {
                            return {
                                ...base,
                                width: "220px",
                                borderStyle: "none"
                            }
                        }
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    ClearIndicator: () => null
                }}
                formatOptionLabel={formatOptionLabel}
            />
        </Dropdown>



    )
}





