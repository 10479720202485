import React, { useMemo } from 'react';

function IntegrationsDropdown(props) {
  const _handleChange = (e) => {
    props.handleChange(e.target.value, props.id);
  }

  const options = useMemo(() => (
    [props.integrations.filter(i => i.available === true).map(o => <option value={o.id} key={o.id} id={o.id}>{o.name}</option>),
    <option key="none" value="none" id="none">None</option>
  ]),
    [props.integrations]);

    return (<select id={props.id}
                  key={props.id}
                  className="swt-class-selector"
                  style={props.styles}
                  defaultValue={props.defaultValue}
                  onChange={_handleChange}>
    {options} </select>);
};

export default IntegrationsDropdown;
