import React from 'react';
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import arrowUp from '../../images/ic-arrow-asc-4px-7px.gif';
import arrowDown from '../../images/ic-arrow-desc-4px-7px.gif';
import arrowUnsel from '../../images/ic-arrow-unsort-4px-9px.gif';
import { DefaultColumnFilter } from './speedn-table-filter';
import { formatCellData } from './speedn-table-helper-functions';
import { execExcelDownload } from './speedn-csv-helper-functions';


export default function TableView(props){
  const { columns, data, filter, colType, handleOnClick, handleSort, nolink, endDate, beginDate, group, vehicleClasses, excessSpeed, minSpeed, minDuration, csvFilename, tableType, dbDisplayName } = props;

  const cellStyle = (cell) =>{
    if(nolink === true)return "speedn-table-cell";
    return `speedn-table-cell speedn-link ${props.highlight(cell)}`;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  /*
  ///Used for Incident Count Table CSV
  const reverseData = (d) => {
    let reverseArr = []
    d.map((item) => {
      return reverseArr = [item, ...reverseArr]
    })
    return reverseArr;
  }
  */
  

  return(
    <>
    <div className='speedn-table-info-wrapper'>
      <div className='speedn-explain-text-wrapper'>
        {tableType === 'Speeding Incident Count' && <span className='speedn-explain-text-2'>Speeding incident count by speed in excess of the posted speed, grouped by the speed at which the vehicle was traveling.</span>}
        {tableType === 'Weekly Summary' && <span className='speedn-explain-text-2'>Number of speeding incidents that occured throughout each week.</span>}
        <br />
        <span className="speedn-explain-text">Click a column name to sort the table.</span>
      </div>
      <div className="speedn-table-download-link-wrapper">
        <button onClick={()=>execExcelDownload({
          "tableType": tableType,
          "beginDate": beginDate,
          "endDate": endDate,
          "group": group,
          "vehicleClasses": vehicleClasses,
          "excessSpeed": excessSpeed,
          "minSpeed": minSpeed,
          "minDuration": minDuration,
          "columns": columns,
          "data": data,
          "filter": filter,
          "dbDisplayName": dbDisplayName,
          "filename": csvFilename + '.xlsx'})} className="speedn-download-btn">Download</button>
      </div>
      </div>
    <div className='table-container'>
      <table {...getTableProps()}
        onClick={function(e){handleSort(columns, e.target.outerText, props.desc)}}
      >
        
        <thead className='table-header-container'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} className={colType(column.Header)}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span className="speedn-table-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <img src={ arrowDown } className="speedn-table-sort-down-arrow" alt="Column sorted in descending order" />
                          : <img src={ arrowUp } className="speedn-table-sort-up-arrow" alt="Column sorted in ascending order" />
                        : <img src={ arrowUnsel } className="speedn-table-sort-both-arrow" alt="Unsorted column" />}
                    </span>
                  </div>
                  <div className="table-searchbar">{column.Header === 'Asset Id' ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
  
        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr className='speedn-table-row' {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={cellStyle(cell)}
                      onClick={function(){handleOnClick(cell.row.original.vin, cell.row.original.ts)}}
                      {...cell.getCellProps()} id={cell.vin}>
                        {formatCellData(cell, row.original)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      {rows.length < 1 
      ?
      <p className="speedn-no-data-msg">No data to display. Try adjusting date range or filters.</p> 
      :
      <div className='table-pagination-container'>
        <button
          className="pagination-button"
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </button>
        <span className='pagination-details'>Page {pageIndex + 1} of {pageOptions.length}</span>

        <button
        className="pagination-button"
        onClick={nextPage} 
        disabled={!canNextPage}>
        Next
      </button>
      </div>
      }
    </>
  )    
}

