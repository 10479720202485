import React from "react";
import LocationDropDown from "./Ste-Components/locationDropDown";
import DateDropdown from "./Ste-Components/dateDropDown";
import PercentDropDown from "./Ste-Components/percentDropDown";
import EzevResultsToggle from "./ezevResultsToggle";
import { useState } from "react";
import OnHoverExplanation from "./onHoverExplanation";

export default function GraphControls(props) {
  const [hoverItems, setHoverItems] = useState({ id: null, x: null, y: null });
  const {
    selectedGraph,
    setSelectedGraph,
    setPercent,
    setLocation,
    selectedLocation,
    setMonth,
    selectedMonth,
    selectedPercent,
    months,
    start,
    stop,
    locations,
    showEzevResults,
    handleEzevToggleChange,
  } = props;

  if (selectedGraph === "monthly")
    return (
      <>
        <OnHoverExplanation
          id={hoverItems.id}
          mouseCoords={[hoverItems.x, hoverItems.y]}
        />
        <h3 className="nrel-charging-graph-ttl">
          EV Charging Peak Demand by Month
        </h3>
        <p className="nrel-charging-graph-instructions">
          The chart below shows the projected peak demand from EV charging at
          each location where your vehicles park. This is based on the time at
          which your vehicles return at the end of the day, the amount of
          charging they will need to do, and the location at which they will
          charge. Please select ezEV Recommendations to see the charging demand
          for vehicles that have a good EV fit based on current technology and
          costs. To see the charging demand based on target fleetwide
          electrification goals, please toggle to “All Vehicles” and select the
          appropriate percentage in the dropdown. Click on a month to see the
          daily charging demand curves.
          <span
            className="nrel-hover-icon"
            onMouseEnter={(e) =>
              setHoverItems({ id: "chargeDemandInfo", x: e.pageX, y: e.pageY })
            }
            onMouseLeave={(e) => setHoverItems({ id: null, x: null, y: null })}
          >
            {" "}
            ⓘ
          </span>
        </p>{" "}
        <EzevResultsToggle
          setHoverItems={setHoverItems}
          toggleDisabled={props.toggleDisabled}
          showEzevResults={showEzevResults}
          handleEzevToggleChange={props.handleEzevToggleChange}
        />
        <div
          className="nrel-graph-controls-monthly"
          id={showEzevResults ? "ezEvResults" : "totalFleet"}
        >
          <div className="nrel-graph-control-wrapper" id="locations">
            <p className="nrel-controls-text">
              Locations:
              <span
                className="nrel-hover-icon"
                onMouseEnter={(e) =>
                  setHoverItems({
                    id: "selectLocation",
                    x: e.pageX,
                    y: e.pageY,
                  })
                }
                onMouseLeave={(e) =>
                  setHoverItems({ id: null, x: null, y: null })
                }
              >
                {" "}
                ⓘ{" "}
              </span>
            </p>
            <LocationDropDown
              locations={locations}
              handleChange={setLocation}
              selectedLocation={selectedLocation}
            />
          </div>
          {!showEzevResults && (
            <div className="nrel-graph-control-wrapper" id="percent">
              <p className="nrel-controls-text">
                Total Fleet Electrification Target:
                <span
                  className="nrel-hover-icon"
                  onMouseEnter={(e) =>
                    setHoverItems({
                      id: "selectPercent",
                      x: e.pageX,
                      y: e.pageY,
                    })
                  }
                  onMouseLeave={(e) =>
                    setHoverItems({ id: null, x: null, y: null })
                  }
                >
                  {" "}
                  ⓘ{" "}
                </span>
              </p>
              <PercentDropDown
                // disabled={showEzevResults === true ? true : false}
                handleChange={setPercent}
                selectedPercent={selectedPercent}
              />
            </div>
          )}
        </div>
      </>
    );

  if (selectedGraph === "daily")
    return (
      <>
        <OnHoverExplanation
          id={hoverItems.id}
          mouseCoords={[hoverItems.x, hoverItems.y]}
        />
        <h3 className="nrel-charging-graph-ttl">kW Demand by Time of Day</h3>
        <p className="nrel-charging-graph-instructions">
          This table shows the projected demand curve from charging. Each line
          represents the demand curve for a single day. The day(s) with the
          highest peak demand in the month are highlighted in red.
        </p>
        <EzevResultsToggle
          setHoverItems={setHoverItems}
          toggleDisabled={props.toggleDisabled}
          showEzevResults={showEzevResults}
          handleEzevToggleChange={handleEzevToggleChange}
        />
        <div
          className="nrel-graph-controls-daily"
          id={showEzevResults ? "ezEvResults" : "totalFleet"}
        >
          <button
            onClick={() => setSelectedGraph("monthly")}
            className="nrel-graph-back-button"
          >
            Back to Month Overview
          </button>
          <div className="nrel-graph-control-wrapper-daily" id="locations">
            <p
              className="nrel-controls-text"
              onMouseEnter={(e) =>
                setHoverItems({ id: "selectLocation", x: e.pageX, y: e.pageY })
              }
              onMouseLeave={() => setHoverItems({ id: null, x: null, y: null })}
            >
              Locations:
            </p>
            <LocationDropDown
              locations={locations}
              handleChange={setLocation}
              selectedLocation={selectedLocation}
            />
          </div>
          <div className="nrel-graph-control-wrapper">
            <p className="nrel-controls-text">Month:</p>
            <DateDropdown
              months={months}
              handleChange={setMonth}
              start={start}
              stop={stop}
              selectedMonth={selectedMonth}
            />
          </div>
          {!showEzevResults && (
            <div className="nrel-graph-control-wrapper" id="percent">
              <p
                className="nrel-controls-text"
                onMouseEnter={(e) =>
                  setHoverItems({ id: "selectPercent", x: e.pageX, y: e.pageY })
                }
                onMouseLeave={() =>
                  setHoverItems({ id: null, x: null, y: null })
                }
              >
                Total Fleet Electrification Target:
              </p>
              <PercentDropDown
                // disabled={showEzevResults === true ? true : false}
                handleChange={setPercent}
                selectedPercent={selectedPercent}
              />
            </div>
          )}
        </div>
      </>
    );
}
