import React from 'react';
import { withRouter } from 'react-router-dom';
import TableRenderer from './TableRenderer';

class TableController extends React.Component {

  render() {
    return (
      < TableRenderer activity={this.props.activity} groupName={(this.props.group ?? { name: "" }).name} multipleGroups={this.props.multipleGroups} dbDisplayName={this.props.dbDisplayName} userSettings={this.props.userSettings}/>
    );
  }
}

export default withRouter(TableController);
