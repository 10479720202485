import React from "react";
export default function Footnote(props) {

    // const footNotePages = ['Map', 'Select by % (PH)', 'ezEV Scores', 'Charging Demand', 'Scenario Tool']
    const footNotePages = []
    const {activeTab} = props;

    function _selectNote(){
      switch(activeTab){
        case 'ezEV Scores' : return '*Too few miles driven' 
        default: return `This only includes vehicles in the following classes: sedans, SUVs,
        pickups, minivans, and cargo-vans.`
      }
    }
    if(footNotePages.includes(activeTab))
  return (
    <div className="nrel-footnote-wrpr">
      <h3 className = 'nrel-footnote-title'>Notes:</h3>      
      <p className="nrel-footnote">
        {_selectNote()}

      </p>
    </div>
  );
  else return <></>
}
