import React, { useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../../component-css/nrel-dashboard-stylesheets/nrel-UniDashboard.css";
import Header from "./header";
import OverallStats from "./overallStats";
import ClientSettings from "./clientSettings";
import Tabs from "./tabs";
import EzevRecs from "./ezevRecs";
import { getRenderingProgress } from "./fetchCalls";
import ScenarioTool from "./Uni-Components/ScenarioTool";
import InfoCard from "./infoCard";
import GraphController from "./graphController";
import Footnote from "./footnote";
import Glossary from "./glossary";
import HomeTab from "./homeTab";
import {
  getGroupMetrics,
  getVehicleSensitivityResults,
  getSettings,
  getTopLocations,
  getPeakMonthlyKwhDemandByLocation,
  getBulkEzevResults,
} from "./fetchCalls";
import About from "./about";
import {
  checkForDuplicateLocationNames,
  reasonForFailure,
} from "./dataFormatter";

function UniDashboard(props) {
  const infoCardData = useRef({});
  const [recData, setRecData] = React.useState([]);
  const [saRecData, setSaRecData] = React.useState([]);
  const [settings, setSettings] = React.useState([]);
  const [saSettings, setSaSettings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("Home");
  const [updateProgress, setUpdateProgress] = React.useState(""); // should be total vcls
  const { requestData } = props;
  const { user } = props;
  let renderCount = 0;
  let saSettingsStable = useRef({});

  // Graph Functions and state
  // Locations needs to be state -  needs rerender for dropdowns
  const [locations, setLocations] = React.useState([]);
  const [selectedPercent, setPercent] = React.useState(40);
  const [selectedMonth, setMonth] = React.useState("2019-10-01,2019-10-31");
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [chargeData, setChargeData] = React.useState([]);
  const start = infoCardData.current.start;
  const stop = infoCardData.current.end;

  const setInitialLocation = useCallback(
    (locations) => {
      if (selectedLocation === "" && locations.length > 0) {
        let vclSort = locations.sort((a, b) =>
          parseInt(a.anc_ct) + parseInt(a.vcl_ct) >
          parseInt(b.anc_ct) + parseInt(b.vcl_ct)
            ? -1
            : 1
        );
        setSelectedLocation(vclSort[0].pkid);
      }
    },
    [selectedLocation]
  );

  const getGraphData = useCallback(() => {
    getTopLocations(requestData, function (resp) {
      if (resp && resp.length > 0) {
        let locs = checkForDuplicateLocationNames(resp);
        setInitialLocation(locs);
        setLocations(locs);
      }
    });
  }, [requestData, setInitialLocation]);

  const getMonthlyChargingData = useCallback(() => {
    if (selectedLocation === "" || selectedLocation === null) return;
    getPeakMonthlyKwhDemandByLocation(
      requestData,
      (resp) => setChargeData(resp),
      {
        locs: selectedLocation,
        bounds: [start, stop],
        percent: selectedPercent,
      }
    );
  }, [selectedLocation, start, stop, selectedPercent, requestData]);

  function _handleCandidateResp(resp, rec, compResults, count) {
    if (typeof resp !== "undefined" && typeof resp.bic !== "undefined") {
      let bic = resp.bic;
      rec["bestInClassYMM"] = bic.ymm;
      rec["bestInClassOverall"] = bic.overall;
      // May not need this, but i suspect we will want to expose eventually
      rec["bestInClassEconomics"] = bic.economics;
      rec["bestInClassParking"] = bic.parking;
      rec["bestInClassEnergy"] = bic.energy;
      rec["bestInClassConfidence"] = bic.confidence;
      rec["lvl2ChargingHrs"] = resp.rec_ev_avg_level2_hrs;
      // END
      rec["bestInClassFailureReason"] = reasonForFailure(bic);
    }
    compResults.push(rec);
    if (compResults.length === count) {
      setRecData(compResults);
    }
  }

  function _handelBulkResults(resp) {
    let compResults = [];
    resp.forEach((r) => {
      let vcl = {
        vin: r.vin,
        overall: r.overall,
        year: r.year,
        make: r.make,
        model: r.model,
        asset_id: r.asset_id,
        economics: r.economics,
        energy: r.energy,
        parking: r.parking,
        vehicle_class: r.vehicle_class,
        recommendation: r.recommendation,
        rec_lt_ghg_reduction_lbs: r.rec_lt_ghg_reduction_lbs,
        rec_lt_gals_saved: r.rec_lt_gals_saved,
        rec_yr_tco_reduction: r.rec_yr_tco_reduction,
      };
      _handleCandidateResp(r, vcl, compResults, resp.length);
    });
  }

  useEffect(() => {
    getMonthlyChargingData();
  }, [getMonthlyChargingData, selectedPercent, selectedLocation]);

  const fetchData = useCallback(() => {
    // split up into can't change and can
    // getGroupEzevMetrics(requestData, (resp) => (overallStats.current = resp));
    getGroupMetrics(requestData, processGroupMetrics);
    //getVehicleResults(requestData, _getBestInClassResults);
    getBulkEzevResults(requestData, _handelBulkResults);
    getVehicleSensitivityResults(requestData, setSaRecData);
    getSettings(requestData, setSettings, true);
    getSettings(requestData, processSaSettings, false);
    getGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGraphData, requestData]);

  const processGroupMetrics = (res) => {
    var obj = { start: new Date(), end: new Date(), ttlVcls: "-" };
    if (res) {
      obj = { start: res.min_ts, end: res.max_ts, ttlVcls: res.vehicles };
    }
    infoCardData.current = obj;
  };

  useEffect(() => {
    // why don't we just render a diff component when diff url - router?
    // Will be removed with refactor -
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      saRecData.length > 0 &&
      recData.length > 0 &&
      saRecData.length < recData.length
    ) {
      setIsLoading(true);
    }
  }, [saRecData, recData]);

  useEffect(() => {
    if (isLoading)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      renderCount = setInterval(() => {
        getRenderingProgress(requestData, customUpdateProgress); // remaining vcls
      }, 1500);
  }, [isLoading]);

  const customUpdateProgress = (res) => {
    if (res && res["count"]) {
      res = res["count"];
      setUpdateProgress(res);
      if (parseInt(res) === 0) {
        setIsLoading(false);
        getVehicleSensitivityResults(requestData, setSaRecData);
        getSettings(requestData, processSaSettings, false);
        clearInterval(renderCount);
      }
    } else {
      console.error("Error: unexpected response from getRenderingProgress");
      window.alert(
        "An error has occured. Please contact us to resolve the issue."
      );
    }
  };

  const processSaSettings = (res) => {
    saSettingsStable.current = res;
    setSaSettings(res);
  };

  function handleTabClick(target) {
    setActiveTab(target);
  }

  return (
    <div className="nrel-UniDashboard">
      <Router>
        <div className="nrel-dash-landing">
          <Header dashType={"uni"} />
          <InfoCard
            requestData={requestData}
            infoCardData={infoCardData}
            start={infoCardData.current.start}
            stop={infoCardData.current.end}
            vclAnalyzedCount={recData.length}
          />
          <div className="nrel-nav-stats-wrapper">
            <OverallStats
              overallStats={recData}
              assumptions={settings}
              title={"Overall Results For Recommended EVs:"}
            />
          </div>
          <div className="nrel-tab-wrapper">
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <Redirect to={{ pathname: "/nrel-uni" }} />}
              />
              <Route
                path="/nrel-uni"
                exact
                render={(props) => (
                  <>
                    <Tabs activeTab={activeTab} setActiveTab={handleTabClick}>
                      <div className="nrel-Started" label="Home">
                        <HomeTab />
                      </div>
                      <div className="nrel-Recs" label="ezEV Scores">
                        <EzevRecs recData={recData} />
                      </div>
                      <div
                        className="nrel-sensitivity-analysis"
                        label="Scenario Tool"
                      >
                        <ScenarioTool
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          remainingVcls={updateProgress}
                          recData={recData}
                          requestData={requestData}
                          email={user.email}
                          saRecData={saRecData}
                          settings={settings}
                          saSettings={saSettings}
                          saSettingsStable={saSettingsStable}
                        />
                      </div>
                      <div className={`nrel-graph`} label={`Charging Demand`}>
                        <GraphController
                          requestData={requestData}
                          selectedMonth={selectedMonth}
                          start={infoCardData.current.start}
                          stop={infoCardData.current.end}
                          locations={locations}
                          selectedPercent={selectedPercent}
                          selectedLocation={selectedLocation}
                          chargeData={chargeData}
                          setMonth={setMonth}
                          setPercent={setPercent}
                          setSelectedLocation={setSelectedLocation}
                        />
                      </div>
                      <div className="nrel-Recs" label="Settings">
                        <ClientSettings
                          requestData={requestData}
                          assumptions={settings}
                        />
                      </div>
                      <div className="nrel-Recs" label="About">
                        <About />
                      </div>
                      <div className={`nrel-glossary`} label={`Glossary`}>
                        <Glossary />
                      </div>
                    </Tabs>
                    <Footnote activeTab={activeTab} />
                  </>
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default UniDashboard;
