import React, { Component } from 'react';
import { Map, Popup, CircleMarker, TileLayer } from 'react-leaflet';
import ShftdLegend from './MapLegend';

export default class SftdMap extends Component {
  createMapItems = () => {
    return this.props.filteredGroup.length > 0
      ? this.props.filteredGroup.map(parkingLoc =>
          this.generateMapItems(parkingLoc)
        )
      : null;
  };

  generateMapItems = (parking_loc, zoom) => {
    return (
      <CircleMarker
        key={`parking-event${parking_loc.parking_location}`}
        center={[parking_loc.latitude, parking_loc.longitude]}
        color={parking_loc.icon_color}
        radius={
          zoom === undefined
            ? parking_loc.icon_radius
            : parking_loc.icon_radius * zoom
        }
      >
        <Popup id={parking_loc.parking_location}>
          <p className="map-popup-title">
            {parking_loc.address}
            <br />
          </p>
          <p>
            <span className="map-popup-bold">Selected Vehicles:</span>{' '}
            {parking_loc.selectedLocCount}
          </p>
          <p>
            <span className="map-popup-bold">Total Vehicles:</span>{' '}
            {parking_loc.count}
          </p>
          <span className="sftd-map-slct-modal">
            <p
              className={'sftd-modal-slct-txt'}
              onClick={() =>
                this.props.handleLocationChange(parking_loc.parking_location)
              }
            >
              {parking_loc.selected
                ? 'Deselect this location'
                : 'Select this location'}
            </p>
          </span>
        </Popup>
      </CircleMarker>
    );
  };

  render() {
    return (
      <div className="sftd-map-wrapper">
        <Map
          className="sftd-map"
          ref="sftdtmap"
          center={this.props.mapCenter}
          zoom={11}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            maxZoom={20}
            zIndex={4}
          />
          {this.createMapItems()}
          <ShftdLegend />
        </Map>
      </div>
    );
  }
}
