import React from "react";
import { useTable, useSortBy, usePagination } from "react-table7";
import arrowDown from "../../images/arrow_desc_blk.png";
import arrowUp from "../../images/arrow_asc_blk.png";
import arrowUnsel from "../../images/arrow_unselected_gry.png";
import OnHoverExplanation from "./onHoverExplanation";

function Table({ columns, data, handleClick, setMapCenter }) {
  const [selectedID, setSelectedID] = React.useState("");
  const [mouseCoords, setMouseCoords] = React.useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 900,
        sortBy: [
          {
            id: "NumbOfEVs",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  function _handleMouseHover(e, id) {
    setSelectedID(id);
    setMouseCoords([e.pageX, e.pageY]);
  }

  function _informationIconSelector(id) {
    // ALSO NEED TO ADD CASE IN ONHOVER EXPLANATION
    const whiteList = ["ReasonOfFailure"];
    if (whiteList.includes(id)) {
      return (
        <span
          className="nrel-hover-icon"
          onMouseEnter={(e) => _handleMouseHover(e, id)}
          onMouseLeave={(e) => _handleMouseHover(e, "close")}
        >
          {" "}
          ⓘ
          {" "}
        </span>
      );
    } else return;
  }

  return (
    <>
      <OnHoverExplanation id={selectedID} mouseCoords={mouseCoords} />
      <table {...getTableProps()} className="nrel-table">
        <thead className="nrel-table-header">
          {headerGroups.map((headerGroup) => (
            <tr key="header" className="nrel-table-text">
              {headerGroup.headers.map((column) => (
                <th key={column.id} className={column.id}>
                  <div {...column.getSortByToggleProps()}>
                    {_informationIconSelector(column.id)}
                    {column.render("Header")}
                    <span className="swt-sort-arrows">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={arrowDown}
                            className="swt-sort-arrow-desc"
                            alt="Column sorted in descending order"
                          />
                        ) : (
                          <img
                            src={arrowUp}
                            className="swt-sort-arrow-asc"
                            alt="Column sorted in ascending order"
                          />
                        )
                      ) : (
                        <img
                          src={arrowUnsel}
                          className="swt-sort-arrow-both"
                          alt="Unsorted column"
                        />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="nrel-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="nrel-table-row"
                id={row.original.uid}
                {...row.getRowProps()}
                onClick={() =>
                  row.original.lat !== undefined && handleClick(row.original)
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={`nrel-table-cell-${cell.column.id}`}
                      id={cell.column.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default function NrelTable(props) {
  function handleClick(row) {
    props.selectLocation(row);
  }

  return (
    <Table
      columns={props.columns}
      data={props.data}
      handleClick={handleClick}
    />
  );
}
