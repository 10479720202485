import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as ColorScheme from './PdfColors.js'

const createStyles = (longLabel) => StyleSheet.create({
    filterLabel: {
      color: ColorScheme.dark_gray,
    },
    filterValue: {
      color: ColorScheme.medium_gray,
    },
    filterText: {
      lineHeight: 1.5,
      maxWidth: 250
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      fontSize: longLabel ? 10 : 12
    },
    textBlock: {
      width: "auto",
      paddingHorizontal: 20
    },
    line: {
      width: "100%",
      height: 1.2,
      marginVertical: 6,
      backgroundColor: ColorScheme.dark_gray
    },
    center: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10
    }
})



export const PdfFiltersSection = ({selectedCategory, groups, vehicleClasses, electrificationPercent, startDate, endDate}) => {
  const groupString = groups.map(group => group.name).join(', ');
  const classString = vehicleClasses.join(', ');
  const longLabel = groupString.length >= 45 || classString.length > 45;

  const styles = createStyles(longLabel);

  const FilterText = ({label, value}) => {
    return (
      <Text style={styles.filterText}>
          <Text style={styles.filterLabel}>{label}: </Text>
          <Text style={styles.filterValue}>{value}</Text>
      </Text>
    )
  }

  const groupText = (
    <FilterText
      label={groups.length === 1 ? "Group" : "Groups"}
      value={groupString}
    />
  );  

  const classText = (
    <FilterText
      label={vehicleClasses.length === 1 ? "Class" : "Classes"}
      value={classString}
    />
  );
  
  return (
    <View style={styles.center}>
      <View style={styles.line}/>
      <View style={styles.textContainer}>
        <View style={styles.textBlock}>
          <FilterText label={"Category"} value={selectedCategory}></FilterText>
          {groupText}
          {classText}
        </View>
        <View style={styles.textBlock}>
          <FilterText label={"Electrification Target"} value={electrificationPercent}></FilterText>
          <FilterText label={"Start Date"} value={startDate}></FilterText>
          <FilterText label={"End Date"} value={endDate}></FilterText>
        </View>
      </View>
      <View style={styles.line}/>
    </View>
  )
}