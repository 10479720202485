import styled from "styled-components/macro";
import * as colors from "./ColorScheme";

interface StatBoxOuterProps {
  highlight: boolean;
}

export const StatBoxOuter = styled.div<StatBoxOuterProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.18);

${(p: StatBoxOuterProps) => (
    p.highlight && `&:hover{
      filter: drop-shadow(2px 2px 15px rgba(0, 0, 0, 0.18));
    }`
  )
}
`
//filter:
export const StatBoxValue = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: ${colors.fueln_dark_grey};
`;

export const StatBoxSubvalue = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: ${colors.fueln_dark_grey};
`;

export const StatBoxCaption = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${colors.fueln_medium_grey};
`