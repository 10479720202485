import React from 'react';
import { Text, View, StyleSheet, Font, Image, Link } from '@react-pdf/renderer';
import * as ColorScheme from './PdfColors.js'

const styles = StyleSheet.create({
  graphsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  dashboardExplainer: {
    fontSize: 11,
    color: ColorScheme.darkest_gray,
    marginVertical: 5,
    lineHeight: 1.25,
    marginTop: 15
  },
  watermark: {
    width: 80,
    height: "auto",
    opacity: 0.3
  },
  watermarkWrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center",
    width: 270,
    height: "100%"
  },
  graphWrapper: {
    borderWidth: 1.5,
    borderStyle: "solid",
    borderColor: ColorScheme.lightest_gray,
    paddingVertical: 3,
    paddingHorizontal: 8,
    width: 270,
    height: "auto",
    maxHeight: 200,
  },
  link: {
    color: ColorScheme.dark_green
  }
})

Font.registerHyphenationCallback(word => [word]);

export const PdfGraphSection = ({monthlyDemandGraph, dailyDemandGraph}) => {
  return (
    <>
      <View style={styles.graphsContainer}>
        {monthlyDemandGraph && 
          <View style={styles.graphWrapper}>
            <Image src={monthlyDemandGraph}/>
          </View>
        }
        {dailyDemandGraph && 
          <View style={styles.graphWrapper}>
            <Image src={dailyDemandGraph}/>
          </View>
        }
      </View>
      <Text style={styles.dashboardExplainer}>
        To view more details about this location’s daily and monthly peak charging demand, as well as additional information about each vehicle that parks at this location, please visit your <Link style={styles.link} src={"https://dashboard.sawatchlabs.com/"}>Sawatch Labs dashboard</Link>.
      </Text>
    </>
  )
}