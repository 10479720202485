import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FormatCell from "./TableHelpers";
import descArrow from '../../images/ic-arrow-desc-green-30px-19px.png';
import ascArrow from '../../images/ic-arrow-asc-red-30px-17px.png';


export default function Landing(props) {
  const {userSettings} = props

  useEffect(() => {
    props.activeLinkChange('activeLink', 'dash');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var opSavings = FormatCell('totalSavings', props.tcoStats.totalSavings, null, null, null, userSettings);
  var avgOpSavings = FormatCell('avgSavings', props.tcoStats.avgSavings, null, null, null, userSettings);
  return (
    <div className="swt-ionev-landing-wrapper">
      <div className="swt-ionev-landing-box-wrapper">
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/driving-activity"}}>
          <div className="swt-ionev-landing-box-value">{props.evCount}</div>
          <div className="swt-ionev-landing-box-label">Number of EVs</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/tco-comparison"}}>
          <div className="swt-ionev-landing-box-value">
            {(!isNaN(props.tcoStats.avgSavings) && props.tcoStats.totalSavings !== 0) &&
              <img className={`ionev-change-arrow ${Math.round(props.tcoStats.totalSavings) > 0 ? "desc" : "asc"}`} src={Math.round(props.tcoStats.totalSavings) > 0 ? descArrow : ascArrow} alt="Cost difference arrow"/>
            }
            <div className="swt-ionev-landing-box-numeric-value" data-testid="op-savings-testid">
              {opSavings}
            </div>
          </div>
          <div className="swt-ionev-landing-box-label">Operational Savings</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/driving-activity"}}>
          <div className="swt-ionev-landing-box-value">{FormatCell('fuelSavings', props.tcoStats.fuelSavings, null, null, null)}
            <span className="swt-ionev-landing-box-value-unit" data-testid="fuel-reduc-label-testid"> {userSettings.liquid_volume_labels.shortPlural}</span>
          </div>
          <div className="swt-ionev-landing-box-label">Fuel Reduction</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/tco-comparison"}}>
          <div className="swt-ionev-landing-box-value" data-testid="fuel-cost-savings-testid">{FormatCell('fuelCostSavings', props.tcoStats.fuelCostSavings, null, null, null, userSettings)}</div>
          <div className="swt-ionev-landing-box-label">Fuel Cost Savings by Using Electric</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/driving-activity"}}>
          <div className="swt-ionev-landing-box-value">{FormatCell('avgDailyElecMiles', props.avgDailyElecMiles, null, null, null)}</div>
          <div className="swt-ionev-landing-box-label" data-testid="avg-daily-distance-label-testid">Avg Daily Electric {userSettings.distance_labels.longPlural}</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/tco-comparison"}}>
          <div className="swt-ionev-landing-box-value">
            {(!isNaN(props.tcoStats.avgSavings) && props.tcoStats.avgSavings !== 0) &&
              <img className={`ionev-change-arrow ${Math.round(props.tcoStats.avgSavings) > 0 ? "desc" : "asc"}`} src={Math.round(parseInt(props.tcoStats.avgSavings)) > 0 ? descArrow : ascArrow} alt="Cost difference arrow"/>
            }
            <div className="swt-ionev-landing-box-numeric-value" data-testid="avg-op-savings-testid">
              {avgOpSavings}
            </div>
          </div>
          <div className="swt-ionev-landing-box-label">Avg Operational Savings/EV</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/driving-activity"}}>
          <div className="swt-ionev-landing-box-value">{FormatCell('ghgRedGms', props.tcoStats.ghgRedGms, null, null, null)}
            <span className="swt-ionev-landing-box-value-unit" data-testid="ghg-reduc-label-testid"> {userSettings.weight_labels.shortPlural}</span>
          </div>
          <div className="swt-ionev-landing-box-label">GHG Emissions Reduction</div>
        </Link>
        <Link className="swt-ionev-landing-box" to={{pathname: "/ionev/missed-opportunities"}}>
          <div className="swt-ionev-landing-box-value">{FormatCell('missedMiles', props.missedMiles, null, props.fuelCost, null)}</div>
          <div className="swt-ionev-landing-box-label" data-testid="missed-elec-distance-label-testid">Missed Electric {userSettings.distance_labels.longPlural}</div>
        </Link>
      </div>
    </div>
  );
}

// styling already started
// <div className="swt-ionev-landing-overall">
// Are EVs Saving You?
// </div>
