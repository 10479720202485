import React from "react";

export default function DbDropdown(props) {
  return (
    <div className="swt-db-selector-wrapper">
      <select
        className="swt-db-selector"
        onChange={e => props.handleDBChange({database: e.target.value})}
        defaultValue={props.db}
      >
        {props.databases.map((d) => {
          let name = d.db_name;
          if (!name) {
            name = d.db;
            name = name.charAt(0).toUpperCase() + name.substring(1);
          }
          return (
            <option key={d.db} id={d.db} value={d.db} currentdb={props.db}>
              {name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
