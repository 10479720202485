import React from "react";

export default function DateDropDown(props) {
  const { months, selectedMonth } = props;
  return (
    <select
      className="nrel-group-selector"
      onChange={(e) => props.handleChange(e.currentTarget.value)}
      value = {selectedMonth}
    >
      {months.map((m) => {
        return (
          <option key={m.label} value={`${m.startTS},${m.endTS}`}>
            {m.label}
          </option>
        );
      })}
    </select>
  );
}
