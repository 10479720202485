import React from "react";
import * as S from '../../../../styles/core-styles/AdminTools-styles';

export default function TableExplainTextHover(props) {
  const {cursorCoords, explainText} = props;

  let explanationText = explainText === '' ? null : explainText;
  
  return explanationText === null ? null : (
    <S.AdminTableHeaderExplainTextPopup cursorCoords={cursorCoords}>
      <S.AdminTableHeaderExplainTextInnerWrapper>
        {explanationText}
      </S.AdminTableHeaderExplainTextInnerWrapper>
    </S.AdminTableHeaderExplainTextPopup>
  );
}