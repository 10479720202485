import React, { useState, useEffect } from "react";
import HeaderLanding from "./HeaderLanding";

const UPDATE_MILLISECONDS = 1000;
const MAX_FAILURES_TO_CHANGE_ALLOWED = 10;

export default function ActiveProcessingPage(props){
  const {dbName, user, apiURL, setProcessingCountdownFinished, aggregateProcessing, apiError} = props;
  const [countDown, setCountDown] = useState(null);
  const [failureToChangeCount, setFailureToChangeCount]= useState(0);
  const [showApiError, setShowApiError] = useState(false);
  let {dbDisplayName} = props;
  if(!dbDisplayName && dbName){
    dbDisplayName = dbName;
    dbDisplayName = dbDisplayName.charAt(0).toUpperCase() + dbDisplayName.slice(1);
  }

  useEffect(() => {
    if (showApiError || aggregateProcessing) return;

    const interval = setInterval(() => {
        fetch(`${apiURL}analyticsProcessingCountdown?dbName=${dbName}`, {
            headers: { Authorization: `Bearer ${user?.token}` },
        })
        .then(response => response.json())
        .then(data => {
            const obj = data.data[0];
            const c = parseInt(obj.count);
            setCountDown(c);

            // Update failure count
            if (c === 0 ) {
                setProcessingCountdownFinished(true);
            }
            else if (c === countDown) {
                setFailureToChangeCount(prevCount => prevCount + 1);
            } else {
                setFailureToChangeCount(0); // Reset failure count
            }

            // Show error if necessary
            if (failureToChangeCount >= MAX_FAILURES_TO_CHANGE_ALLOWED) {
                console.error('Count of vehicles remaining to process did not change in the last 10 seconds.');
                setShowApiError(true);
            }
        })
        .catch(error => {
            console.error(`Error in analyticsProcessingCountdown call: ${error}`);
            setShowApiError(true);
        });
    }, UPDATE_MILLISECONDS);
  
    return () => clearInterval(interval);

}, [showApiError, aggregateProcessing, dbName, apiURL, user, countDown, failureToChangeCount, setProcessingCountdownFinished]);
  
useEffect(() => {
  setShowApiError(apiError);
}, [apiError]);

let content = <></>;

  if(showApiError) content = <ApiErrorView dbDisplayName={dbDisplayName ? dbDisplayName : dbName}/>;
  else if (aggregateProcessing) content = <AggregateProcessingView dbDisplayName={dbDisplayName ? dbDisplayName : dbName}/>;
  else content = <ProcessingCountdownView countDown={countDown} dbDisplayName={dbDisplayName}/>;

  return (
    <div className="ezev-app">
      <div className="ezev-app-content">
        <div className="ezev-wrapper">
          <HeaderLanding groupName={apiError ? 'Error' : 'Processing'}/>
          <div className="ezev-processing-page">
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

const ApiErrorView = (props) =>{
  return(
    <>
      <h1 className="ezev-h2">
        There appears to be an issue loading ezEV for {props.dbDisplayName}.<br/>
        <br/>
        Please contact us to resolve this issue.
      </h1>
      <br/>
      <p className="ezev-subheading">
        Email: info@sawatchlabs.com
        <br/>
        Phone: (303) 578-2465
      </p>
    </>
  )
}

const AggregateProcessingView = (props) =>{
  return(
    <>
      <h1 className="ezev-h2">
        Data is currently being procesed for database {props.dbDisplayName}.<br/>
        <br />
        Please check back later.
      </h1>
    </>
  )
}

const ProcessingCountdownView = (props) => {
  return(
    <>
      <h1 className="ezev-h2">
        A user has triggered a re-run of results for database {props.dbDisplayName}.
      </h1>
      <br/>
      <h1 className="ezev-h2">
        This process will complete in approximately:{" "}
        {props.countDown} seconds.
      </h1>
    </>
  )
}