import React, { useState, useEffect } from "react";
import * as S from "../../styles/ezio-styles/Header-styles"

const UPDATE_MILLISECONDS = 1000;
const MAX_FAILURES_TO_CHANGE_ALLOWED = 10;

export default function ActiveProcessingPage(props){
    const [countDown, setCountDown] = useState(null);
    const [failureToChangeCount, setFailureToChangeCount]= useState(0);
    const {dbName, user, apiURL, setProcessingCountdownFinished, apiError, aggregateProcessing, groupError} = props;
    const [showApiError, setShowApiError] = useState(false);
    let {dbDisplayName} = props;
    if(!dbDisplayName && dbName){
        dbDisplayName = dbName;
        dbDisplayName = dbDisplayName.charAt(0).toUpperCase() + dbDisplayName.slice(1);
    }

    useEffect(() => {
        if (showApiError || aggregateProcessing || groupError) return;
    
        const interval = setInterval(() => {
            fetch(`${apiURL}analyticsProcessingCountdown?dbName=${dbName}`, {
                headers: { Authorization: `Bearer ${user?.token}` },
            })
            .then(response => response.json())
            .then(data => {
                const obj = data.data[0];
                const c = parseInt(obj.count);
                setCountDown(c);
    
                // Update failure count
                if (c === 0 ) {
                    setProcessingCountdownFinished(true)
                    clearInterval(interval);
                }
                else if (c === countDown) {
                    setFailureToChangeCount(prevCount => prevCount + 1);
                } else {
                    setFailureToChangeCount(0); // Reset failure count
                }
    
                // Show error if necessary
                if (failureToChangeCount >= MAX_FAILURES_TO_CHANGE_ALLOWED) {
                    console.error('Count of vehicles remaining to process did not change in the last 10 seconds.');
                    setShowApiError(true);
                    clearInterval(interval);
                }
            })
            .catch(error => {
                console.error(`Error in analyticsProcessingCountdown call: ${error}`);
                setShowApiError(true);
                clearInterval(interval);
            });
        }, UPDATE_MILLISECONDS);
    
        return () => clearInterval(interval);

    }, [showApiError, groupError, aggregateProcessing, dbName, apiURL, user, countDown, failureToChangeCount, setProcessingCountdownFinished]);

    useEffect(() => {
        setShowApiError(apiError);
    }, [apiError]);

    let infoContainer = <></>;
    if (aggregateProcessing) infoContainer = AggregateProcessingView(dbDisplayName);
    else if (showApiError) infoContainer = ApiErrorView(dbDisplayName);
    else if (groupError) infoContainer = NoGroupErrorView(dbDisplayName);
    else infoContainer = CountdownView(dbDisplayName,countDown);
    return (
        <S.HeaderContainer>
            <S.LHSContentWrapper>
                <S.LogoContainer>
                    <S.LogoLink to="/ezio" />
                </S.LogoContainer>
                <S.HeaderAndFleetWrapper>
                    <S.FleetNameContainer>
                        {dbDisplayName}
                    </S.FleetNameContainer>
                    <S.StatusContainer>
                        {(showApiError || groupError) ? "Error" : "Loading"}
                    </S.StatusContainer>
                </S.HeaderAndFleetWrapper>
                {infoContainer}
            </S.LHSContentWrapper>
        </S.HeaderContainer>
    )
}

const ApiErrorView = (dbName) => {
    return(
        <S.AdditionalInfoContainer>
            <S.AdditionalInfoCopy>
                There appears to be an issue loading ezIO for {dbName}. 
                <br/>
                Please contact us to resolve this issue.
                <br/> <br/>
                Email: <S.AdditionalInfoEmailLink href='mailto:info@sawatchlabs.com'>info@sawatchlabs.com</S.AdditionalInfoEmailLink>
                <br />
                Phone: (303) 578-2465
            </S.AdditionalInfoCopy>
        </S.AdditionalInfoContainer>
    );
}
const CountdownView = (dbName, countdown) => {
    return(
        <S.AdditionalInfoContainer>
            <S.AdditionalInfoCopy>
            A user has triggered a re-run of results for database {dbName}.
            <br/>
            This process will complete in approximately: {countdown} seconds.
            </S.AdditionalInfoCopy>
        </S.AdditionalInfoContainer>
    );
}

const AggregateProcessingView = (dbName) => {
    return(
        <S.AdditionalInfoContainer>
            <S.AdditionalInfoCopy>
            Data is currently being processed for database {dbName}.<br/>
            <br/>
            Please check back later.
            </S.AdditionalInfoCopy>
        </S.AdditionalInfoContainer>
    );
}

const NoGroupErrorView = (dbName) => {
    return (
        <S.AdditionalInfoContainer>
            <S.AdditionalInfoCopy>
                There are no groups assigned to your account for {dbName}.
                <br/>
                 Please reach out to your admin to be added to applicable groups.
                <br/> <br/>
            </S.AdditionalInfoCopy>
        </S.AdditionalInfoContainer>
    )
}