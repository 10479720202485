import React from "react"
import * as S from "../../styles/ezio-styles/Controls-styles"
import { ChangeElectrificationAction, ChangeClassAction, ChangeLocationAction, ChangeCategoryAction, ChangeGroupAction } from "../swt-ezio"
import CategoryDropdown from "./CategoryDropdown"
import GroupsDropdown from "./GroupsDropdown"
import LocationsDropdown from "./LocationsDropdown"
import ElectrificationDropdown from "./ElectrificationDropdown"
import ClassesDropdown from "./ClassesDropdown"
import { ChargeLocation } from "../swt-ezio";
import DateSelectors from "./DateSelectors";
import { DateTime } from "luxon";

//import grayArrow from "../../images/ic-refresh-gray-a100-504px-587px.png"
//import lightGreenArrow from "../../images/ic-refresh-lightgreen-a100-504px-587px.png"
//import greenArrow from "../../images/ic-refresh-green-a100-504px-587px.png"

type ControlsProps = {
    groups: Array<any>
    selectedGroups?: any
    selectedCategory: any
    chargeLocations: Map<string, ChargeLocation>
    selectedChargeLocation: string
    classes: Array<string>
    selectedClasses: Array<string>
    selectedElectrification: number
    onChangeControls: Function
    minDate?: DateTime
    maxDate?: DateTime
    selectedBeginDate?: DateTime
    selectedEndDate?: DateTime
    handleDateChange: Function
    userSettings: Object
    limitedDateRange: boolean,
    location?: string,
    ALL_LOCATIONS_ID: string
}


export default function Controls({
    groups,
    selectedGroups,
    selectedCategory,
    chargeLocations,
    selectedChargeLocation,
    classes,
    selectedClasses,
    selectedElectrification,
    onChangeControls,
    minDate,
    maxDate,
    selectedBeginDate,
    selectedEndDate,
    handleDateChange,
    userSettings,
    limitedDateRange,
    location,
    ALL_LOCATIONS_ID}: ControlsProps) {

    const disableDateSelectors = location?.includes('kw-demand') ? true : false;
    return (
        <S.ControlRow>
            <S.ControlSection>
                <S.ControlItem>
                    <LocationsDropdown chargeLocations={chargeLocations} selectedChargeLocation={selectedChargeLocation} ALL_LOCATIONS_ID={ALL_LOCATIONS_ID} onChange={(action: ChangeLocationAction) => onChangeControls({ location: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <CategoryDropdown selectedCategory={selectedCategory.label} onChange={(action: ChangeCategoryAction) => onChangeControls({ category: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <GroupsDropdown groups={groups} selectedGroups={selectedGroups} onChange={(action: ChangeGroupAction) => onChangeControls({ groups: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <ClassesDropdown classes={classes} selectedClasses={selectedClasses} onChange={(action: ChangeClassAction) => onChangeControls({ classes: action })} />
                </S.ControlItem>
            </S.ControlSection>
            <S.VerticalRule/>
            <S.ControlSection>
                <S.ControlItem>
                    <ElectrificationDropdown selectedElectrification={selectedElectrification} onChange={(action: ChangeElectrificationAction) => onChangeControls({ electrification: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <DateSelectors
                        selectorPosition="exterior"
                        dateBounds={{min: minDate, max: maxDate}}
                        selectedDates={{min:selectedBeginDate, max: selectedEndDate}}
                        handleChange={handleDateChange}
                        disabled={disableDateSelectors}
                        userSettings={userSettings}
                        limitedDateRange={limitedDateRange}
                    />
                </S.ControlItem>
            </S.ControlSection>
        </S.ControlRow>
    )
}
