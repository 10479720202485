import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import pattern from "patternomaly";

export default function RecBarChart(props) {
  const { filteredData } = props;
  const { SAResults } = props;

  let filteredGraphData = [
    filteredData.sedanRecs,
    filteredData.suvRecs,
    filteredData.pickupRecs,
    filteredData.minivanRecs,
    filteredData.cargovanRecs,
  ];

  let filteredSaGraphData = [
    SAResults.sedanRecs,
    SAResults.suvRecs,
    SAResults.pickupRecs,
    SAResults.minivanRecs,
    SAResults.cargovanRecs,
  ];

  const yMax = () => {
    if (!filteredData && !SAResults) return;
    let max;
    let filteredMax = Math.max.apply(Math, Object.values(filteredData));
    max = (filteredMax + filteredMax * 0.1);
    if (SAResults) {
      let SAResultsMax = Math.max.apply(Math, Object.values(SAResults));

      if (SAResultsMax > filteredMax)
        max = (SAResultsMax + SAResultsMax * 0.1);
    }
    return max;
  };

  let _standardizeData = () => {
    return {
      labels: [`Sedan (${filteredData.sedan})`, `SUV (${filteredData.suv})`, `Pickup (${filteredData.pickup})`, `Minivan (${filteredData.minivan})`, `Cargo-Van (${filteredData.cargovan})`],
      datasets: [
        {
          type: "bar",
          label: "ezEV Results",
          stack: "stack1",
          backgroundColor: "rgba(77,154,192,.8)",
          borderColor: "rgba(0,0,0,.8)",
          borderWidth: 1,
          data: filteredGraphData,
        },
        {
          type: "bar",
          label: "Scenario Tool Results",
          stack: "stack2",
          backgroundColor: pattern.draw("diagonal", "rgba(37, 81, 131, 0.56)"),
          borderColor: "rgba(0,0,0,.8)",
          borderWidth: 1,
          data: filteredSaGraphData,
        },
      ],
    };
  };

  const legendOpts = {
    display: true,
    position: "bottom",
    fullWidth: false,
    reverse: false,
    labels: {
      fontColor: "#4a4a4a",
    },
  };
  const options = {
    title: {
      display: true,
      text: "Scenario Tool",
      fontSize: 24,
      fontStyle: "bold",
      fontColor: "#4a4a4a",
      justifyContent: "center",
    },
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: "#e8e9ea",
      },
    },
    scales: {
      xAxes: [
        {
          //   stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Class (Total Vehicles in Class in Fleet)'
          }
        },
      ],

      yAxes: [
        {
          //   stacked: true,
          ticks: {
            beginAtZero: true,
            max: yMax(),

          },
          scaleLabel: {
            display: true,
            labelString: 'Recommended EVs'
          }
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <Bar
      id="sftd-visual"
      data={_standardizeData()}
      legend={legendOpts}
      options={options}
      width={300}
      height={160}
    />
  );
}
