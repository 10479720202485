import React from "react";
import { Line } from "react-chartjs-2";

export default function GraphTcoTrend(props) {
  const legendOpts = {
    display: true,
    position: "bottom",
    labels: {
      font: {
        size: 11,
        weight: 'normal',
        },
    }
  };
  const options = {
    plugins: {
      legend: legendOpts,
    },
    layout: {
      padding: {
        right: 15,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Days prior to last date observed"
        },
      },
    }
  };
  var labels = Object.keys(props.trendData);
  const data = {
    labels: labels,
    datasets: [
      {
        label: `Operational Cost Savings (${props.user.userSettings.currency})`,
        data: Object.values(props.trendData),
        backgroundColor: "rgba(0, 0, 0, 0.0)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.0)",
        borderColor: "rgb(87,151,182)",
        borderWidth: 2,
        fill: true
      },
    ],
  };

  return (
    <>
      <h4 className="ionev-graph-title">Operational Cost Savings</h4>
      <Line
        options={options}
        data={data}
        legend={legendOpts}
        width={80}
        height={210}
      />
    </>
  );
}
