import React, { useEffect, useCallback } from "react";
import InfoCardAssumptions from "./infoCardAssumptions";
import { getCandidates } from "./fetchCalls";
import Table from "./table";
import StandardizeTableData, {
  csvAssumptionHeaders,
  tableAssumptionHeadersBEV,
  tableAssumptionHeadersICE,
} from "./standardizeTableData";
import { CSVLink } from "react-csv";

export default function ClientSettings(props) {
  const [candidates, setCandidates] = React.useState({});
  const { requestData, assumptions } = props;

  const filterCandidates = (cands) => {
    var bevs = [];
    var phevs = [];
    var ices = [];
    if(cands) {
      cands.forEach((c) => {
        if (c.is_bev && c.is_selected) {
          c["type"] = "BEV";
          bevs.push(c);
        }
        if (c.is_phev && c.is_selected) {
          c["type"] = "PHEV";
          phevs.push(c);
        }
        if (c.is_ice && c.is_selected) {
          c["type"] = "ICE";
          if (!c.mpg_c) c.mpg_c = "--";
          if (!c.mpg_h) c.mpg_h = "--";
          ices.push(c);
        }
      });
    }
    setCandidates({
      selectedBevs: bevs,
      selectedPhevs: phevs,
      selectedIces: ices,
      combined: [...bevs, ...phevs, ...ices],
    });
  };

  const fetchData = useCallback(() => {
    getCandidates(requestData, filterCandidates);
  }, [requestData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="nrel-assumptions-page">
      <h3 className="nrel-assumptions-title">Client Defined Settings</h3>
      <p className = 'nrel-assumptions-description '>The fleet provided the following information to Sawatch Labs. This information was included to customize the analysis.</p>
      <CSVLink
        className="nrel-download-btn"
        id="assumptions"
        data={
          candidates.selectedBevs
            ? StandardizeTableData(candidates, "assumptions")
            : []
        }
        target="_blank"
        headers={csvAssumptionHeaders()}
        filename={`sawatch-labs-NREL-assumptions-table.csv`}
      >
        <p>Download CSV</p>
      </CSVLink>
      <InfoCardAssumptions assumptions={assumptions} />

      <div className="nrel-assumption-table-wrpr" id="ice">
        <p className="nrel-assumption-header">
          Internal Combustion Engine Vehicles
        </p>
        <Table
          columns={tableAssumptionHeadersICE()}
          data={StandardizeTableData(candidates.selectedIces, "assumptions")}
        />
      </div>
      <div className="nrel-assumption-table-wrpr" id="bev">
        <p className="nrel-assumption-header">Battery Electric Vehicles</p>
        <Table
          columns={tableAssumptionHeadersBEV()}
          data={StandardizeTableData(candidates.selectedBevs, "assumptions")}
        />
      </div>
      <div className="nrel-assumption-table-wrpr" id="phev">
      <p className="nrel-assumption-header">
          Plugin Hybrid Electric Vehicles
        </p>
        <Table
          columns={tableAssumptionHeadersBEV()}
          data={StandardizeTableData(candidates.selectedPhevs, "assumptions")}
        />
      </div>
    </div>
  );
}
