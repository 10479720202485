import React, {useState, useEffect} from 'react';
// Add functionality to specify certian rows/values to search.
export default function Search(props){
    const[activeSearch, setActiveSearch] = useState('')
    const {allValues, setMatchingValues} = props
    useEffect(()=>{
            let matching = allValues;
            if (activeSearch !== undefined) {
              let searchTerm = activeSearch.toString().toLowerCase();
                const matchingValues = matching.filter((entry) =>
                Object.values(entry).some((val) =>
                  `${val}`.toLowerCase().includes(searchTerm)
              ));
              setMatchingValues({ "values": matchingValues, "searchTerm": searchTerm })
            } else {
              return setMatchingValues(allValues);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch, allValues])
    return(
        <div className='speedn-searchbar-wrapper'>
            <input className={props.className} value={activeSearch} onChange={(e) => setActiveSearch(e.target.value)} placeholder='Search by Year, Make, Model, etc.'/>
        </div>);
}