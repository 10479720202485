import React, { useEffect, useState, useMemo } from "react";
import HeaderLanding from "./HeaderLanding";
import * as S from "../../styles/ezev-styles/LandingPage-styles";
import * as ColorScheme from '../../styles/ezev-styles/ColorScheme';
import Loading from "./loading";
import PieChart from "./PieChart";
import InfoCardLanding from "./InfoCardLanding";
import GroupDownloadPdf from "./GroupDownloadPdf";
import DropdownSelector from "./DropdownSelector";
import { LinkAssumptions, LinkEditAssumptions, LinkShowVehicles } from "./Links";
import { FormatAdminData, FormatSuperAdminData, sanitizeDownloadString } from "./FormatExcelData";
import { saveAs } from "file-saver";
import { processApiResponse, processRateTime, formatTimestamp } from "./utils/ConformUnits";
import { Roles } from "../core-components/utility-components/UserUtils";
import { initializeWorker } from "./pdfWorkerInitializer";
import { DateTime } from "luxon";

const LandingPage = ({ 
  group, 
  user, 
  groupMinDate, 
  groupMaxDate,
  hasReceivedDates, 
  groupRecommendedCount, 
  vehicleCount, 
  dbDisplayName, 
  groups, 
  handleGroupSelect, 
  selectedVehicleCategory, 
  handleVehicleCategorySelect, 
  activity,
  fleetEzevMetrics, 
  groupEzevMetrics, 
  settings, 
  apiURL,
  dbName,
  authorizedGroups,
  parkingData,
  candidates
}) => {

  // TODO: this useEffect call should only trigger if groups changes, not on render
  const [renderCallMade, setRenderCallMade] = useState(false);
  const [getPdf, setGetPdf] = useState(false);
  const [displayPdfTooltip, setDisplayPdfTooltip] = useState(false);
  const [evRecsChartRef, setEvRecsChartRef] = useState(null);
  const [categoryChartRef, setCategoryChartChartRef] = useState(null);
  const evRecsChart = (newRef) => {if(newRef) setEvRecsChartRef(newRef)};
  const [dataLoading, setDataLoading] = useState(true);
  const categoryChart = (newRef) => {if(newRef) setCategoryChartChartRef(newRef)};
  const includeCategoryChart = selectedVehicleCategory === "All Vehicles" ? true : false;
  const hasEvRecs = groupRecommendedCount > 0 ? true : false;

  useEffect(() => {
    // TODO: remove these duplicated pdf calls if possible
    if (!renderCallMade) {
      setRenderCallMade(true);
      return
    }
    if (groups?.length > 0 && groups[0] !== "No Groups") {
      handleGroupSelect(null, groups[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  // var url = `${this.props.apiURL}getCandidates?ident=${this.props.db}`;
  //   fetch(`${url}`, {headers: {'Authorization':`Bearer ${this.props.user.token}`}})
  //   .then(res => res.json())
  useEffect(() => {
    //get pdf data
    if(!getPdf)return;
    setGetPdf(false);
    fetch(`${apiURL}getKwhRates?dbname=${dbName}`, { headers: { 'Authorization': `Bearer ${user.token}` } })
    .then((resp => resp.json()))
    .then((kwhRatesResponse) => {
        setDisplayPdfTooltip(true)
        const allCandidates = candidates;
        const is_ld = selectedVehicleCategory === 'Light Duty' ? true : false

        // filter by category if the category is not All Vehicles, otherwise, just filter by ezEV selected
        const selectedCandidates = candidates.filter((v) => {
          if (selectedVehicleCategory === 'All Vehicles') {
            return v.evsa_selected === true;
          } else {
            return v.evsa_selected === true && v.is_ld === is_ld;
          }
        });
        kwhRatesResponse.data.forEach((r) => {
          r = processApiResponse(user.userSettings, r);
          r = processRateTime(user.userSettings, r);
        })
        _constructPDF(parkingData, allCandidates, selectedCandidates, kwhRatesResponse.data);
    })
    .catch((err) => console.error(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPdf]);

  useMemo(()=> {
    // we want to synchonize disabling the download buttons and displaying a loading bar in the info card when we are still waiting for any data
    if (!authorizedGroups) {
      setDataLoading(false);
    } else if (
      groupRecommendedCount === null ||
      fleetEzevMetrics === null ||
      activity === null ||
      groupRecommendedCount === null || 
      hasReceivedDates === false
    ) {
      setDataLoading(true);
      
    } else {
      setDataLoading(false);
    }
  },[activity, groupRecommendedCount, fleetEzevMetrics, authorizedGroups, hasReceivedDates]);

  const noGroupsAssigned = !!(groups?.length > 0 && groups[0] === "No Groups");
  
  const _constructPDF  = (parkingData, allCandidates, selectedCandidates, kwhRates) => {
    if(activity && parkingData && selectedCandidates){
      const downloadDate = formatTimestamp(user.userSettings, DateTime.local()).date;
      const worker = initializeWorker();
      worker.onmessage = function(e){
          saveAs(e.data, sanitizeDownloadString(`sawatch-labs-ezev-summary-${dbDisplayName}-${downloadDate}`));
          worker.terminate();//if you don't terminate, workers persist and multiple pdfs attempts fire off.
      }
      worker.onerror = function(err){
          console.error("worker error", err);
      }
      const evRecsChartBase64 = hasEvRecs ? evRecsChartRef.toBase64Image(): null;
      const categoryChartBase64 =  includeCategoryChart ? categoryChartRef.toBase64Image(): null;
      
      const obj = {
              parkingPDFData:parkingData, 
              vehiclePDFData: activity,
              dbDisplayName: dbDisplayName,
              group: group, 
              groupEzevMetrics: groupEzevMetrics, 
              groupRecommendedCount: groupRecommendedCount, 
              selectedVehicleCategory: selectedVehicleCategory, 
              activity: activity, 
              groupMinDate: groupMinDate, 
              groupMaxDate: groupMaxDate, 
              vehicleCount: vehicleCount, 
              settings: settings,
              userSettings: user.userSettings,
              selectedCandidates: selectedCandidates,
              allCandidates: allCandidates,
              kwhRates: kwhRates,
              evRecsChart: evRecsChartBase64,
              categoryChart: categoryChartBase64,
              downloadDate: downloadDate
          }
      if(vehicleCount > 0)worker.postMessage(obj);
      else worker.terminate();
    }
  }
  
  function calculateCategoryBreakdown(activity) {
    let lightDutyCount = 0;
    let mediumHeavyDutyCount = 0;
    activity.forEach((v) => {v.is_ld ? lightDutyCount +=1 : mediumHeavyDutyCount +=1})
    return [lightDutyCount, mediumHeavyDutyCount];
  }

  function createEvRecChart() {
    return(
      <S.ChartWrapper> 
        <PieChart
          graphRef={evRecsChart}
          valueArray={[groupRecommendedCount, vehicleCount-groupRecommendedCount]}
          colorArray={[ColorScheme.dark_aqua, ColorScheme.light_aqua]}
          labelArray={["Vehicles recommended for EV replacement", "Vehicles not recommended for EV replacement"]}
          chartTitle={"EV Recommendation Breakdown"}
        />
      </S.ChartWrapper>
    );
  }

  function createCategoryChart() {
    return (
      <S.ChartWrapper>
        <PieChart
          graphRef={categoryChart}
          valueArray={calculateCategoryBreakdown(activity)}
          colorArray={[ColorScheme.blue_purple, ColorScheme.purple]}
          labelArray={["Light Duty Vehicles", "Medium & Heavy Duty Vehicles"]}
          chartTitle={"Vehicle Category Breakdown"}
        />
      </S.ChartWrapper>
    )
  }

  return (
    <> 
      {activity && <div style={{visibility:"hidden"}}>
        {/* We need to actually draw the graph components in order to export them as base64 strings, so we draw the canvas, but hide it.
            The element will still appear in the DOM, but won't be visible to the user or actionable in any way. */}
        {createEvRecChart()}
        {includeCategoryChart && createCategoryChart()}
      </div>}
      <HeaderLanding groupName={group?.name} dbDisplayName={dbDisplayName} />
      {/* If the groups list is empty and the app hasn't been told it has no groups, display a loading screen */}
      {(!(groups?.length > 0) && !noGroupsAssigned) &&
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      }
      {(groups?.length > 0 || noGroupsAssigned ) && <S.LandingContainer>
        <S.LandingLhs>
          <S.DropdownWrapper id="dropdown-wrapper" data-testid="categoryDropdownForm-testid">
            <S.Heading>Select Category:</S.Heading>
            <DropdownSelector
              selectorName="ezev-group-selector"
              options={["All Vehicles", "Light Duty", "Medium and Heavy Duty"].map(item => ({ value: item, label: item }))}
              handleSelectorChange={(e) => { handleVehicleCategorySelect(e) }}
              value={{id: selectedVehicleCategory, name: selectedVehicleCategory}}
            />
          </S.DropdownWrapper>
          {/* Don't display the groups dropdown if there's no selected group or if the selected group passed down here is "no group"
          (aka it has no id) */}
          {(noGroupsAssigned) ? 
          <S.NoGroupsMessage data-testid="noGroupsMessage-testid">
              You do not have any groups assigned with {selectedVehicleCategory} vehicles.
              Try updating the Select Category selector or reach out to your admin to be added to applicable groups
          </S.NoGroupsMessage>
          :
          <S.DropdownWrapper id="dropdown-wrapper" data-testid="groupDropdownForm-testid">
            <S.Heading htmlFor="groupDropdown">Select Group:</S.Heading>
            <DropdownSelector
              name="groupDropdown"
              inputId="groupDropdown"
              selectorName="ezev-group-selector"
              options={groups.map(item => {return {value: item.id, label: item.name}})}
              handleSelectorChange={(e) => { handleGroupSelect(e, e.value) }}
              value={group}
            />
          </S.DropdownWrapper>}
          <LinkShowVehicles />
        </S.LandingLhs>
        <S.LandingRhs>
          <InfoCardLanding
            groupMinDate={groupMinDate}
            groupMaxDate={groupMaxDate}
            hasReceivedDates={hasReceivedDates}
            groupRecommendedCount={groupRecommendedCount}
            vehicleCount={vehicleCount}
            dbDisplayName={dbDisplayName}
            activity={activity}
            fleetEzevMetrics={fleetEzevMetrics}
            userSettings={user.userSettings}
            noGroupsAssigned={noGroupsAssigned}
            authorizedGroups={authorizedGroups}
            settings={settings}
            dataLoading={dataLoading}
          />
          <S.AssumptionsLinkRow>
            <LinkAssumptions />
            {user.role >= Roles.FleetAdmin && <LinkEditAssumptions/>}
          </S.AssumptionsLinkRow>
          {groups && !noGroupsAssigned && <div id="download-section">
            <S.Heading>Download your complete EVSA Report</S.Heading>
            <S.BodyText>Summary and single vehicle breakdowns</S.BodyText>
            <S.DownloadButtonsContainer>
              <GroupDownloadPdf 
                link={null}
                openModal={()=>setGetPdf(true)}
                displayPdfTooltip={displayPdfTooltip}
                setDisplayPdfTooltip={setDisplayPdfTooltip}
                dataLoading={dataLoading}
              />
              {user.role >= Roles.PartnerAdmin && <S.TableDownloadLink
                onClick={()=>FormatAdminData(
                  JSON.parse(JSON.stringify(activity)),    
                  parkingData, 
                  dbDisplayName, 
                  user.userSettings
                )}
                data-testid="adminExcelDownload-testid"
                disabled={dataLoading}
              >
                Download Excel
              </S.TableDownloadLink>}
              {user.role >= Roles.SuperAdmin && <S.TableDownloadLink 
                style={{fontSize:"18px"}}
                onClick={()=>FormatSuperAdminData(
                  JSON.parse(JSON.stringify(activity)),
                  parkingData,
                  dbDisplayName,
                  user.userSettings
                )}
                data-testid="superAdminExcelDownload-testid"
                disabled={dataLoading}
              >
                Super Admin Excel
              </S.TableDownloadLink>}
            </S.DownloadButtonsContainer>
          </div>}
        </S.LandingRhs>
      </S.LandingContainer>}
    </>
  );
}

export default LandingPage;