import React from 'react';

function GvwrDropdown(props) {
    const { handleChange, id } = props;
    let options = [];
    let defaultValue = 'None';
    if(props.defaultValue) defaultValue = props.defaultValue;
    if(props.options) options = props.options;
 

    return (<select 
                id={id} 
                className="swt-class-selector"
                style={props.styles}
                value={defaultValue}
                onChange={(e) => handleChange(e.target.value)}>
                {options.map((o) => {
                    return (
                        <option key={o.id} value={o.name}>{o.name}</option>
                    )
                })}
            </select>);
};

export default GvwrDropdown;
