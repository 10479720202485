import React from 'react';

function DatabaseStatusDropdown(props) {
  const _handleChange = (e) => {
    props.handleChange(e.target.value, props.id);
  }

  const options = [
      <option key="update" value="update">Analytics Updating, Data Potentially Updating</option>,
      <option key="noDataUpdate" value="noDataUpdate">Data and Analytics Not Updating</option>,
      <option key="noUiUpdate" value="noUiUpdate">UI, Data and Analytics Not Updating</option>,
      <option key="archived" value="archived" disabled>Archived</option>,
  ]

    return (<select id={props.id} 
                  className="swt-class-selector"
                  style={props.styles}
                  value={props.value}
                  onChange={_handleChange}
                  disabled={props.disabled}
                  title={props.disabled ? "This database has been archived. Its status cannot be changed." : null}>
    {options} </select>);
};

export default DatabaseStatusDropdown;
