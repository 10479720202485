import React from 'react';
import { StatView } from '@sawatchlabs/swt-components'
import {FormatSummaryData} from './UtilityFunctions';



export default function AltFuelDetail({vehicleData, displayInLbs}) {
    const { bev, phev, altFuelMiles, fuelCostSaved, fuelGallonsSaved, ghgLbsSaved, ghgTonsSaved } = vehicleData;
    let units = displayInLbs ? "Lbs" : "Tons";
    let ghgSaved = displayInLbs ? ghgLbsSaved : ghgTonsSaved;

    return (
        <div className="swt-emit-landing-boxes-wrapper">
            <StatView values={{value: FormatSummaryData('bev', bev), caption: 'Battery Electric Vehicles'}} />
            <StatView values={{value: FormatSummaryData('phev', phev), caption: 'Plug-In Hybrid Electric Vehicles'}} />
            <StatView values={{value: FormatSummaryData('miles', altFuelMiles), caption: 'Miles'}} />
            <StatView values={{value: FormatSummaryData('fuelCostSaved', fuelCostSaved), caption: 'Fuel Savings'}} />
            <StatView values={{value: FormatSummaryData('fuelGallonsSaved', fuelGallonsSaved), caption: 'Fuel Reduction', subCaption: '(Gallons)'}} />
            <StatView values={{value: FormatSummaryData('ghgSaved', ghgSaved), caption: `GHG ${units} Reduction`}} />
        </div>
    )
}