import React from 'react';
import { Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import SawatchLogo from '../../images/sawatch-labs-logo-black.png'
import * as ColorScheme from './PdfColors.js'

// React-pdf has some weird issues with the copyright symbol. The following code allows us to display the copyright symbol.
// Issue thread: https://github.com/diegomura/react-pdf/issues/2277
Font.registerEmojiSource({format: 'png', url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'});

const copyright = `\u00A9`;

const styles = StyleSheet.create({
    footerContainer: {
      position: "absolute",
      bottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems:"center",
      width:"100%",
      marginHorizontal: 45
    },
    footerText: {
      color: ColorScheme.dark_gray,
      fontSize: 8,
      lineHeight: 1.3,
      textAlign: "center"
    },
    footerLogoImage: {
      width: "auto",
      height: 30,
      marginBottom: 2
    },
    link: {
      color: ColorScheme.dark_green,
      textDecoration: "none"
    },
    copyright: {
      fontSize: 5.2
    }
})

export const PdfFooter = ({dbDisplayName}) => {

  return (
    <View style={styles.footerContainer} fixed>
      <Image style={styles.footerLogoImage} src={SawatchLogo} alt="Sawatch Logo"/>
      <Text style={styles.footerText}><Link style={styles.link} src={"mailto:info@sawatchlabs.com"}>info@sawatchlabs.com</Link> | <Link style={styles.link} src={"https://sawatchlabs.com/"}>sawatchlabs.com</Link></Text>
      <Text style={styles.footerText}>ezIO Projected Charging Assessment Suitability Assessment - {dbDisplayName}</Text>
      <Text style={styles.footerText}><Text style={styles.copyright}>{copyright}</Text> {new Date().getFullYear()} Sawatch Labs. All Rights Reserved.</Text>
    </View>
  )
}