import React from "react";
import GroupSelector from "./install-group-selector";
import { NavLink } from "react-router-dom";

export default function NavHeader(props) {
  const { group, setGroup, vclData } = props;
  return (
    <div className="install-title-wrapper">
      <div className = 'install-title'>
        <h1 className="install-client-name">{props.companyName}</h1>
        <GroupSelector
          selectedGroup={group}
          setGroup={setGroup}
          vclData={vclData}
        />
      </div>
      <div className="nav-wrapper">
        <NavLink className="install-nav-btn" to="/install-tracker/gantt">
          <button>Gantt Chart</button>
        </NavLink>
        <NavLink className="install-nav-btn" to="/install-tracker/table">
          <button>Table</button>
        </NavLink>
      </div>
    </div>
  );
}
