import React from "react";
import { Bar } from "react-chartjs-2";

export default function SpeednDetailGraph(props){

    const incidents = props.weeklyEvents.map((w) => {return w.incidents});
    const labels = props.weeklyEvents.map((w) => {return w.woy});
    const dataSet = 
      {
        labels: labels,
        datasets: [
          {
            label: "Incidents",
            data: incidents,
            backgroundColor: "#83AddB"
          }
        ],
      }

      return(
        <div>
          <Bar
            className="graph-container"
            id="graph"
            data={dataSet}
            height={300}
            options={{
              type: 'bar',
              plugins:{
                active: {
                  mode: "dataset",
                  intersect: true,
                  },
              }, 
              maintainAspectRatio: false,
              scales: {
              y: {
                    suggestedMax: 10,
                    suggestedMin: 0,
                  },
              x: {
                  suggestedMax: 10,
                  suggestedMin: 0,
                  title: {
                      text: "Week of Year",
                      fontColor: "#202020"
                    }
                  }
                },
            }}>
          </Bar>
      </div>);
}
