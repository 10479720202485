import React from "react";

export default function LocationDropDown(props) {
  const {locations} = props

locations.sort((a, b) => (parseInt(a.vcl_ct) > parseInt(b.vcl_ct) ? -1 : 1));

  return (
    <select
      className="nrel-group-selector"
      id= 'locations'
      onChange={(e) => {
        props.handleChange(e.currentTarget.value);
      }}
      value={props.selectedLocation}
    >
      {locations.map((l) => {
        return (
          <option key={l.pkid} name={l.name} value={l.pkid}>
            {l.address}
            {/* {l.address + " | "} {+ parseInt(l.vcl_ct) === 1? l.vcl_ct + " vcl" : l.vcl_ct + ' vcls'} */}
          </option>
        );
      })}
    </select>
  );
}
