import React from 'react';
import styled from "styled-components/macro";

const TabHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;

`

const PrimaryHeaderText = styled.h1`
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    line-height: 35px;
    color: rgb(74, 74, 74);
    margin: 0;
`;

const SubHeaderText = styled.div`
    color: #979a9e;
    font-size: 18px;
    margin: 0;
    padding: 0;
`


export default function TabHeader(props){
    const {group, beginDate, endDate } = props;
    let formattedBeginDate = beginDate ? beginDate.toLocaleDateString("en-US") : '';
    let formattedEndDate = endDate ? endDate.toLocaleDateString("en-US") : '';

    return(
        <TabHeaderWrapper>
            <PrimaryHeaderText>{group}</PrimaryHeaderText>
            <SubHeaderText>Selected Period: {formattedBeginDate} - {formattedEndDate}</SubHeaderText>
        </TabHeaderWrapper>
    )
}