import Select from "react-select"
import * as CS from "../../styles/ezio-styles/Controls-styles"
import React, { useEffect, useState } from "react"
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"

type ClassesDropdownProps = {
    classes: Array<string>
    selectedClasses: Array<any>
    onChange: Function
}




export default function ClassesDropdown({ classes, selectedClasses, onChange }: ClassesDropdownProps) {

    const [options, setOptions] = useState<Array<{ label: string, value: string }>>([])

    const [isOpen, setIsOpen] = useState(false)
    function toggleOpen() {
        setIsOpen(!isOpen)
    }
    //When the classes change, the best thing to do is probably just panic and return to default. 
    useEffect(() => {
        var noClassNeeded = false

        setOptions([{ value: "All Classes", label: "All Classes" }, ...classes.filter((item: string) => {
            if (item) {
                return true
            }
            else {
                noClassNeeded = true
                return false
            }
        }).map((item: string) => {

            return { label: item, value: item }
        }),
        ].concat((noClassNeeded ? [{ value: "No Class Assigned", label: "No Class Assigned" }] : []))

        )

    }, [classes])



    useEffect(() => {
        scrollToSelected()

    }, [isOpen])

    return (
        <Dropdown
            dropdownId="class-dropdown-testid"
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <CS.ClassesMenuButton onClick={toggleOpen}>

                    <CS.ClassesMenuText>
                        {selectedClasses.length === 0
                            ? ""
                            : selectedClasses.includes("All Classes")
                                ? "All Classes"
                                : selectedClasses.length === 1
                                    ? "1 Class"
                                    : `${selectedClasses.length} Classes`}
                    </CS.ClassesMenuText>
                    <CS.CustomDownChevron />
                </CS.ClassesMenuButton>
            }
        >
            <Select
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                isMulti
                hideSelectedOptions={false}
                value={options.filter((item: any) => selectedClasses.includes(item.value))}
                options={options}
                onMenuOpen={scrollToSelected}
                onChange={(event) => {
                    //If All Classes is selected, then the user selects a specific class, deselect All Classes and select only that class
                    if (selectedClasses.includes("All Classes") && event.length > 1) {
                        onChange(event.filter((item: any) => item.value !== "All Classes").map((item: any) => item.value))
                        return
                    }
                    //If any specific classses are selected, then the user selects All Classes, deselect everything except All Classes
                    else if (event.map((item: any) => item.value).includes("All Classes") && !selectedClasses.includes("All Classes")) {

                        onChange(["All Classes"])
                        return
                    }
                    //If only one specific class is selected, then the user deselects it, prevent the action.
                    else if (event.length === 0) {
                        return
                    }
                    //Otherwise, update normally
                    else {
                        onChange(event.map((item: any) => item.value))
                    }

                }}
                isSearchable={true}
                placeholder={
                    "Search Vehicle Classes"
                }
                styles={
                    {
                        ...CS.SelectStyles,
                        placeholder: (base: any) => {
                            return {
                                ...base,
                                textAlignLast: "center",
                                color: "inherit"
                            }
                        },
                        container: (base: any) => {
                            return {
                                ...base,
                                width: "170px"
                            }

                        }
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    ClearIndicator: () => null
                }}
                formatOptionLabel={formatOptionLabel}
            />
        </Dropdown>
    )
}


