import React from 'react';
import { Document, Page, Font, StyleSheet } from '@react-pdf/renderer';
import { PdfHeader } from './PdfHeader';
import { PdfFooter } from './PdfFooter';
import { PdfFiltersSection } from './PdfFiltersSection';
import { PdfStatBoxesSection } from './PdfStatBoxesSection';
import { PdfGraphSection } from './PdfGraphsSection';

Font.register({
  family: "Roboto",
  src: "http://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: "90 43 85 43",
    fontFamily: "Helvetica",
    fontSize: 12
  },
});

export const PdfSingleLocation = ({dbDisplayName,locAddress,selectedCategory,groups,vehicleClasses,electrificationPercent,startDate,endDate, statBoxList, dailyDemandBase64, monthlyDemandBase64}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <PdfHeader dbDisplayName={dbDisplayName} locationAddress={locAddress} />
        <PdfFiltersSection
          selectedCategory={selectedCategory}
          groups={groups}
          vehicleClasses={vehicleClasses}
          electrificationPercent={electrificationPercent}
          startDate={startDate}
          endDate={endDate}
        />
        <PdfStatBoxesSection statBoxValues={statBoxList} />
        <PdfGraphSection monthlyDemandGraph={monthlyDemandBase64} dailyDemandGraph={dailyDemandBase64}/>
        <PdfFooter dbDisplayName={dbDisplayName} />
      </Page>
    </Document>
  );
}