import styled from 'styled-components';
import * as ColorScheme from './ColorScheme';

export const MenuContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 80px);
  left: 0;
  background-color: ${ColorScheme.lightest_gray};
  // width: ${props => props.menuExpanded ? '215px' : '64px'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  justify-content: space-between;
  border-right: 2px solid ${ColorScheme.light_gray};
  transition: width 0.25s ease;
`

export const RowScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const Line = styled.div`
  height: 2px;
  background-color: ${ColorScheme.light_gray};
  width: 100%;
`

export const ProductIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  min-height: 100px; // ensure that the product container can always displauy at least one icon
`

export const ActionIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  overflow: auto;
  height: min-content;
  min-height: 64px;
  padding-top: 12px;
`

export const IconHoverContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconWrapper = styled.div`
  width: 37px;
  height: 37px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: width 0.1s ease, height 0.1s ease;
`

export const ActionIconWrapper = styled(IconWrapper)`
  padding: 4px;
  svg {
    fill: ${ColorScheme.dark_gray};
    transition: fill 0.2s ease;
    width: 92%;
    height: 92%;
  }
`

export const ProductIconWrapper = styled(IconWrapper)`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`

export const ProductIcon = styled.img`
  pointer-events: none;
  user-select: none;
`;

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  color: ${ColorScheme.dark_gray};
  /* cursor: pointer; */
  transition: color 0.2s ease;

  &:hover {
    color: ${ColorScheme.sawatch_blue};
    cursor: pointer;
    svg {
      fill: ${ColorScheme.sawatch_blue};
      width: 100%;
      height: 100%;
    }
    .icon-wrapper {
      width: 40px;
      height: 40px;
    }
  }
`

export const ProductDescription = styled.div`
  font-size: 16px;
  display: ${props => props.menuExpanded ? 'flex' : 'none'};
  white-space: nowrap; 
  overflow: hidden;
  user-select: none;
`

export const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  display: ${props => props.menuExpanded ? 'block' : 'none'};
  z-index: 13000;
`