import {useEffect,useRef} from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import * as S from "../../../styles/core-styles/DevTools-styles";

export default function DevToolsTable(props) {
  const { columns, data, idAccessor, selectedId, selectedIds } = props;
  const handleOnClick = props.handleOnClick;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
        sortBy: [
          {
            id: props.sortCol,
            desc: props.desc,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );
  
  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.scrollTo({top:0});
  }, [pageIndex]);

  return (
    <div>
      <S.DevToolTableContainer ref={tableRef}>
      <S.DevToolsTable {...getTableProps()}
        onClick={function (e) { props.handleSort(e.target.outerText, props.desc) }}
      >
        <S.DevToolsTableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <S.DevToolsTableHeaders
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}{" "}
                </S.DevToolsTableHeaders>
              ))}
            </tr>
          ))}
        </S.DevToolsTableHead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            let cn = "swt-devtools-row";
            if (row.original[idAccessor] === selectedId || (selectedIds && selectedIds.indexOf(row.original[idAccessor]) > -1)) cn += "-selected";
            return (
              <S.DevToolsTableRow className={cn} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <S.DevToolsTableData
                      onClick={function () { handleOnClick(cell.row.original[idAccessor]) }}
                      {...cell.getCellProps()} id={cell[idAccessor]}>
                      {cell.render("Cell")}
                    </S.DevToolsTableData>
                  );
                })}
              </S.DevToolsTableRow>
            );
          })}
        </tbody>
      </S.DevToolsTable>
        
      </S.DevToolTableContainer>
      {rows.length < 1 
        ?
        <S.DevToolsNoDataMsg>No data to display. Try adjusting date range or filters.</S.DevToolsNoDataMsg>
        :
      <S.DevToolsPaginationContainer>
        <S.DevToolsPaginationButtons
          onClick={previousPage}
          disabled={!canPreviousPage}>
          Previous
        </S.DevToolsPaginationButtons>
        <S.DevToolsPaginationDetails className='pagination-details'>Page {pageIndex + 1} of {pageOptions.length}</S.DevToolsPaginationDetails>

        <S.DevToolsPaginationButtons
        onClick={nextPage} 
        disabled={!canNextPage}>
        Next
      </S.DevToolsPaginationButtons>
      </S.DevToolsPaginationContainer>
}
    </div>);
}
